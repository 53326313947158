import React from "react";
import { Link } from "react-router-dom";

import Heading from "../common/Heading";
import Rooms from "../home/Rooms";

import Header from "../common/Header";
import Footer from "../common/Footer";

import { Button } from "../../../components/buttons/button";


export default function Room() {
  return (
    <>
      <Header />
      <Heading heading="Room" title="Home" subtitle="Room" />

      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-row items-center justify-center gap-3">
            <Link to="/hotel-interface/create-room">
                <Button small={false} gray className="mx-4 my-4">Create a Room</Button>
            </Link>
            <Link to="/hotel-interface/add-room-type">
                <Button small={false} gray className="mx-4 my-4">Create a Room Type</Button>
            </Link>
        </div>

        <Rooms />
      </div>

      <Footer />
    </>
  );
}
