import React, { useState } from 'react';
import { Search, Plus, ChevronRight, Receipt } from 'lucide-react';
import { Navigate } from 'react-router-dom';


import { useNavigate } from 'react-router-dom';

function CreditNoteList() {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const navigate = useNavigate();

  const creditNotes = [
    {
      id: 1,
      creditNoteNumber: "CN-00001",
      customerName: "Acme Corporation",
      date: "2024-03-15",
      reference: "REF-001",
      amount: 750.00,
      status: "Issued"
    },
    {
      id: 2,
      creditNoteNumber: "CN-00002",
      customerName: "TechStart Inc",
      date: "2024-03-14",
      reference: "REF-002",
      amount: 1250.50,
      status: "Draft"
    },
    {
      id: 3,
      creditNoteNumber: "CN-00003",
      customerName: "Global Solutions Ltd",
      date: "2024-03-13",
      reference: "REF-003",
      amount: 2100.75,
      status: "Issued"
    }
  ];

  const handleButtonClick = () => {
    navigate("/accounting/sales/credit-notes");
  }

  return (
    <div className="min-h-screen bg-seashell p-8">
      <div className="mb-8 flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-hazel-green">Credit Notes</h1>
        <button 
          onClick={handleButtonClick}
          className="inline-flex items-center px-4 py-2 bg-hazel-green hover:bg-sage text-white rounded-lg transition-colors duration-200"
        >
          <Plus className="h-4 w-4 mr-2" />
          New Credit Note
        </button>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-mercury">
        <div className="p-4 border-b border-mercury">
          <div className="relative w-64">
            <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
            <input
              type="text"
              placeholder="Search credit notes..."
              className="pl-10 pr-4 py-2 w-full border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
            />
          </div>
        </div>

        <div className="grid grid-cols-6 gap-4 p-4 bg-tealish-blue">
          <div className="text-sm font-medium text-bluish-grey">CREDIT NOTE #</div>
          <div className="text-sm font-medium text-bluish-grey">CUSTOMER</div>
          <div className="text-sm font-medium text-bluish-grey">DATE</div>
          <div className="text-sm font-medium text-bluish-grey">REFERENCE</div>
          <div className="text-sm font-medium text-bluish-grey">AMOUNT</div>
          <div className="text-sm font-medium text-bluish-grey">STATUS</div>
        </div>

        <div className="divide-y divide-mercury">
          {creditNotes.map((note) => (
            <div
              key={note.id}
              className="grid grid-cols-6 gap-4 p-4 hover:bg-seashell transition-colors duration-200 group cursor-pointer"
            >
              <div className="flex items-center">
                <Receipt className="h-4 w-4 mr-2 text-hazel-green" />
                <span className="text-hazel-green font-medium group-hover:text-sage">
                  {note.creditNoteNumber}
                </span>
                <ChevronRight className="h-4 w-4 ml-2 text-bluish-grey opacity-0 group-hover:opacity-100 transition-opacity" />
              </div>
              <div className="text-bluish-grey">{note.customerName}</div>
              <div className="text-bluish-grey">{new Date(note.date).toLocaleDateString()}</div>
              <div className="text-bluish-grey">{note.reference}</div>
              <div className="text-bluish-grey">
                € {note.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
              <div className="flex items-center">
                <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                  note.status === 'Issued' 
                    ? 'bg-blue-100 text-blue-800' 
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {note.status}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CreditNoteList;