import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  IconSearch,
  IconChevronDown,
  IconBellFilled,
  IconSettings,
  IconLogout
} from '@tabler/icons-react';
import SearchDropdown from './search-dropdown';
import store from '../../store';
import { setUser } from '../../store/slices/user-slice';

function Header() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [searchText, setSearchText] = useState('');
  const [showDropDown, setShowDropDown] = useState(false);

  const logout = () => {
    localStorage.clear();
    store.dispatch(setUser(null));
    navigate('/');
  };

  return (
    <header className='border-b border-gray-200 relative dark:bg-white'>
      <div className='flex flex-col md:flex-row'>
        <div className='w-full md:w-80 flex justify-center md:justify-start items-center bg-tealish-blue border-r border-gray-200 py-2 px-4'>
          <img
            src='/images/lake-side-logo.png'
            alt='logo'
            className='w-10 h-10 md:w-12 md:h-12'
          />
          <p className='text-hazel-green font-bold ml-2 hidden md:block'>LakeSide Hotel</p>
        </div>
        <div className='w-full'>
          <div className='px-4 md:px-10 py-3 md:py-4'>
            <div className='flex flex-col md:flex-row items-center justify-between text-sm md:text-base text-gray-700'>
              <div className='relative w-full md:w-1/2 mb-3 md:mb-0'>
                <label className='mb-2 text-xs md:text-sm font-medium text-gray-900 sr-only'>
                  Search
                </label>
                <div className='relative'>
                  <input
                    type='text'
                    className='block w-full p-2 ps-3 text-xs md:text-sm text-gray-900 rounded-lg bg-gray-50 focus:outline-none'
                    placeholder='Search...'
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <div className='absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none'>
                    <IconSearch className='w-3 h-3 md:w-4 md:h-4 text-gray-300' />
                  </div>
                </div>
                {
                  <SearchDropdown
                    showDropDown={searchText !== ''}
                    setShowDropDown={setShowDropDown}
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                }
              </div>
              <div className='flex justify-center items-center'>
                <img
                  src='/images/profile-placeholder.png'
                  alt='logo'
                  className='w-8 h-8 md:w-10 md:h-10'
                />
                <div className='flex'>
                  <p className='capitalize mx-2 hidden md:block text-xs md:text-base'>
                    {user.name} {user.surname}
                  </p>
                  <IconChevronDown
                    className='text-heather cursor-pointer'
                    onClick={() => setShowDropDown(!showDropDown)}
                  />
                </div>

                <Link to='/chat'>
                  <div className='relative inline-flex items-center ml-2 md:ml-3'>
                    <IconBellFilled className='text-heather' />
                    <div className='absolute inline-flex items-center justify-center w-3 h-3 md:w-4 md:h-4 bg-red-500 border-2 border-white rounded-full -top-1 -end-1 md:-top-1.5 md:-end-1'></div>
                  </div>
                </Link>
              </div>
              <div
                className={`${
                  showDropDown ? 'z-50 flex flex-col' : 'hidden'
                } z-50 absolute top-14 ml-auto right-10 md:right-20 justify-end p-3 md:p-4 w-48 md:w-60 bg-white border border-light-gray rounded-md shadow-lg`}
              >
                <Link
                  to='/account-settings'
                  className='flex p-2 md:p-3 border-b border-light-gray'
                  onClick={() => setShowDropDown(false)}
                >
                  <IconSettings className='mr-1 md:mr-2' size={20} />
                  Account Settings
                </Link>
                <p className='flex p-2 md:p-3 cursor-pointer' onClick={logout}>
                  <IconLogout className='mr-1 md:mr-2' size={20} />
                  Logout
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
