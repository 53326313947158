import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { createVenue, updateVenue } from '../../api/venue';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import { IconUpload, IconX } from '@tabler/icons-react';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required*'),
  location: Yup.string().required('Location is required*'),
  capacity: Yup.number().required('Capacity is required*').positive(),
  price: Yup.number().required('Price is required*').positive(),
  description: Yup.string().required('Description is required*')
});

function VenueForm({ isEdit, venue = {} }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(isEdit && venue.imagePath ? `http://localhost:3001/${venue.imagePath}` : null);

  const initialValues = {
    name: venue.name || '',
    location: venue.location || '',
    capacity: venue.capacity || '',
    price: venue.price || '',
    description: venue.description || '',
    availability: venue.availability !== undefined ? venue.availability : true
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('location', values.location);
      formData.append('capacity', values.capacity);
      formData.append('price', values.price);
      formData.append('description', values.description);
      formData.append('availability', values.availability);
      
      if (uploadedFile) {
        formData.append('image', uploadedFile);
      }

      if (isEdit) {
        await updateVenue(venue.id, formData);
        toast.success('Venue updated successfully');
      } else {
        await createVenue(formData);
        toast.success('Venue created successfully');
      }
      navigate('/venue');
    } catch (error) {
      console.error('Failed to save venue:', error);
      toast.error(isEdit ? 'Failed to update venue' : 'Failed to create venue');
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <h3 className='text-bluish-grey font-medium uppercase mt-3'>
              Venue Details
            </h3>
            <div className='border-b border-hazel-green py-1' />
            <div className='grid grid-cols-2 gap-x-6 gap-y-3 mt-4'>
              <div className='col-span-1'>
                <CustomInput
                  type='text'
                  name='name'
                  label='Venue Name'
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.name && touched.name}
                  errorMessage={errors.name}
                />
              </div>
              <div className='col-span-1'>
                <CustomInput
                  type='text'
                  name='location'
                  label='Location'
                  value={values.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.location && touched.location}
                  errorMessage={errors.location}
                />
              </div>
              <div className='col-span-1'>
                <CustomInput
                  type='number'
                  name='capacity'
                  label='Capacity'
                  value={values.capacity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.capacity && touched.capacity}
                  errorMessage={errors.capacity}
                />
              </div>
              <div className='col-span-1'>
                <CustomInput
                  type='number'
                  name='price'
                  label='Price'
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.price && touched.price}
                  errorMessage={errors.price}
                />
              </div>
              <div className='col-span-2'>
                <CustomInput
                  type='text'
                  name='description'
                  label='Description'
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiline={true}
                  showError={errors.description && touched.description}
                  errorMessage={errors.description}
                />
              </div>
              <div className='col-span-2'>
                <p className='text-bluish-grey my-3'>Image</p>
                <div>
                  <input
                    type='file'
                    name='image'
                    onChange={handleFileChange}
                    accept='image/*'
                    className='hidden'
                    id='image-upload'
                  />
                  <div className='flex flex-wrap gap-4'>
                    <label
                      htmlFor='image-upload'
                      className='flex items-center justify-center bg-mercury w-32 h-32 rounded-md cursor-pointer hover:bg-gray-200 transition-colors'
                    >
                      <IconUpload className='text-hazel-green' size={30} />
                    </label>
                    {previewUrl && (
                      <div className='relative'>
                        <img
                          src={previewUrl}
                          alt='Preview'
                          className='w-32 h-32 object-cover rounded-md'
                        />
                        <button
                          type='button'
                          onClick={() => {
                            setUploadedFile(null);
                            setPreviewUrl(null);
                          }}
                          className='absolute -top-2 -right-2 p-1 bg-white rounded-full shadow-md hover:bg-gray-100'
                        >
                          <IconX size={16} className='text-red-500' />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-span-2'>
                <label className='block text-sm font-medium text-gray-700'>
                  Availability
                </label>
                <div className='mt-1'>
                  <input
                    type='checkbox'
                    name='availability'
                    checked={values.availability}
                    onChange={handleChange}
                    className='h-4 w-4 text-sage focus:ring-sage border-gray-300 rounded'
                  />
                  <span className='ml-2 text-sm text-gray-600'>Available</span>
                </div>
              </div>
            </div>

            <div className='flex justify-end items-center w-full mt-7 text-sm'>
              <CancelButton onCancel={() => navigate('/venue')} />
              <SubmitButton
                isSubmitting={isSubmitting || loading}
                width='w-1/5'
                title={isEdit ? 'Update Venue' : 'Create Venue'}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default VenueForm;
