import axios from 'axios';

// const axiosInstance = axios.create({ baseURL: 'http://3.79.80.215' });

const axiosInstance = axios.create({ baseURL: 'http://localhost:3001' });
// const axiosInstance = axios.create({ baseURL: 'https://api-staff.lakesideks.com' });



export const fetcher = (url) =>
  axiosInstance.get(url).then((res) => {
    if (res) {
      return res.data;
    }
    return null;
  });


export default axiosInstance;
