import React, { useState, useEffect } from 'react';
import { RotateCw } from 'lucide-react';
import { getEntitiesByType } from '../../api/accountingArticle';
import { getAllConferenceRooms } from '../../api/conferenceRoom';
import { getAllVenues } from '../../api/venue';
import { getRoomTypes } from '../../api/rooms';
import { toast } from 'react-hot-toast';

export type EntityType = 'DishSection' | 'RoomType' | 'BarProduct' | 'VenueReservation' | 'ConferenceRoomReservation';

interface EntityDetails {
  id: string;
  code?: string;
  name?: string;
  title?: string;
  type: string;
  category?: string;
}

interface EntitySelectorProps {
  allowedTypes: EntityType[];
  onEntitySelect: (entityId: string, entityType: string, entityDetails: any) => void;
  selectedEntityId?: string;
  selectedEntityType?: string;
  typeLabels: Record<EntityType, string>;
}

const ENTITY_TYPE_LABELS: Record<EntityType, string> = {
  DishSection: 'Dish Section',
  RoomType: 'Hotel Room',
  BarProduct: 'Bar Product',
  VenueReservation: 'Wedding Venue',
  ConferenceRoomReservation: 'Conference Room'
};

export const EntitySelector: React.FC<EntitySelectorProps> = ({
  allowedTypes,
  onEntitySelect,
  selectedEntityId,
  selectedEntityType,
  typeLabels
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState<EntityType | ''>(selectedEntityType as EntityType || '');
  const [entities, setEntities] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<any>(null);

  useEffect(() => {
    if (selectedType) {
      fetchEntities(selectedType);
    }
  }, [selectedType]);

  useEffect(() => {
    if (selectedEntityType && selectedEntityId) {
      setSelectedType(selectedEntityType as EntityType);
      setSelectedEntity({ id: selectedEntityId, type: selectedEntityType });
      fetchEntities(selectedEntityType as EntityType, selectedEntityId);
    }
  }, [selectedEntityType, selectedEntityId]);

  const fetchEntities = async (type: EntityType, preSelectedId?: string) => {
    setLoading(true);
    try {
      let fetchedData: any[] = [];
      
      switch (type) {
        case 'VenueReservation':
          const venueResponse = await getAllVenues();
          if (Array.isArray(venueResponse.data)) {
            fetchedData = venueResponse.data.map((venue: any) => ({
              id: venue.id.toString(),
              name: venue.name,
              price: venue.pricePerDay,
              capacity: venue.capacity,
              type: 'VenueReservation'
            }));
          }
          break;
          
        case 'ConferenceRoomReservation':
          const conferenceResponse = await getAllConferenceRooms();
          if (Array.isArray(conferenceResponse.data)) {
            fetchedData = conferenceResponse.data.map((room: any) => ({
              id: room.id.toString(),
              name: room.name,
              price: room.pricePerHour,
              capacity: room.capacity,
              type: 'ConferenceRoomReservation'
            }));
          }
          break;
          
        case 'RoomType':
          const roomResponse = await getRoomTypes();
          if (Array.isArray(roomResponse.data)) {
            fetchedData = roomResponse.data.map((room: any) => ({
              id: room.id,
              name: room.title,
              price: room.price,
              capacity: room.capacity,
              description: room.description,
              type: 'RoomType'
            }));
          }
          break;
          
        default:
          const response = await getEntitiesByType(type);
          if (response?.data?.message && Array.isArray(response.data.message)) {
            fetchedData = response.data.message;
          }
      }
      
      setEntities(fetchedData);
      
      if (preSelectedId) {
        const selectedEntity = fetchedData.find(entity => entity.id === preSelectedId);
        if (selectedEntity) {
          setSelectedEntity(selectedEntity);
          onEntitySelect(selectedEntity.id, type, {
            id: selectedEntity.id,
            name: selectedEntity.name || selectedEntity.title,
            code: selectedEntity.code,
            type: type,
            category: selectedEntity.category
          });
        }
      }
    } catch (error) {
      console.error('Error fetching entities:', error);
      toast.error('Failed to fetch entities');
    } finally {
      setLoading(false);
    }
  };

  const handleEntityTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newType = e.target.value as EntityType;
    setSelectedType(newType);
    setSelectedEntity(null);
    setSearchTerm('');
    if (newType) {
      fetchEntities(newType);
    } else {
      setEntities([]);
    }
  };

  const renderEntityOption = (entity: any) => {
    return (
      <div className="flex flex-col py-2">
        <div className="font-medium text-gray-900 text-[15px]">
          {entity.name || entity.title}
        </div>
        {(entity.capacity || entity.price) && (
          <div className="text-sm text-bluish-grey flex items-center gap-2 mt-1">
            {entity.capacity && (
              <span className="flex items-center gap-1">
                <svg className="w-4 h-4 text-bluish-grey/70" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                {entity.type === 'VenueReservation'
                  ? `${entity.capacity} guests`
                  : `${entity.capacity} person${entity.capacity > 1 ? 's' : ''}`}
              </span>
            )}
            {entity.capacity && entity.price && <span>•</span>}
            {entity.price && (
              <span className="flex items-center gap-1 font-medium text-hazel-green">
                <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                {entity.type === 'VenueReservation'
                  ? `${entity.price}€/day`
                  : entity.type === 'ConferenceRoomReservation'
                  ? `${entity.price}€/hour`
                  : `${entity.price}€/night`}
              </span>
            )}
          </div>
        )}
        {entity.description && (
          <div className="text-sm text-bluish-grey/80 mt-1.5 line-clamp-2">
            {entity.description}
          </div>
        )}
      </div>
    );
  };

  const handleEntitySelect = (entity: any) => {
    setSelectedEntity(entity);
    const entityDetails: EntityDetails = {
      id: entity.id,
      code: entity.code || '',
      name: entity.name || entity.title || '',
      type: selectedType,
      category: entity.categoryOrderProduct?.title || entity.menu?.name || ''
    };
    onEntitySelect(entity.id, selectedType, entityDetails);
    setShowDropdown(false);
  };

  const clearSelection = () => {
    setSelectedEntity(null);
    setSelectedType('');
    onEntitySelect('', '', { id: '', type: '', code: '', name: '' });
  };

  return (
    <div className="relative">
      {/* Type selector */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-bluish-grey mb-2">
          Entity Type
        </label>
        <select
          value={selectedEntityType || ''}
          onChange={handleEntityTypeChange}
          className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50"
        >
          <option value="">Select Entity Type</option>
          {allowedTypes.map((type) => (
            <option key={type} value={type}>
              {typeLabels[type]}
            </option>
          ))}
        </select>
      </div>

      {/* Entity selector */}
      {selectedType && (
        <div className="relative">
          <button
            type="button"
            className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50 cursor-pointer"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {selectedEntity ? (
              <div className="text-bluish-grey truncate">
                {selectedEntity.name || selectedEntity.title}
              </div>
            ) : (
              <div className="text-grey-goose">Select {ENTITY_TYPE_LABELS[selectedType]}</div>
            )}
          </button>

          {/* Dropdown */}
          {showDropdown && (
            <div className="absolute z-50 w-full mt-2 bg-white border border-grey-goose/30 rounded-xl shadow-lg max-h-[300px] overflow-y-auto">
              {loading ? (
                <div className="flex items-center justify-center p-4">
                  <RotateCw className="w-5 h-5 animate-spin text-hazel-green" />
                </div>
              ) : entities.length > 0 ? (
                <div className="p-2">
                  {entities.map((entity) => (
                    <button
                      key={entity.id}
                      type="button"
                      className="w-full text-left px-3 rounded-lg hover:bg-hazel-green/5 transition-colors"
                      onClick={() => handleEntitySelect(entity)}
                    >
                      {renderEntityOption(entity)}
                    </button>
                  ))}
                </div>
              ) : (
                <div className="p-4 text-center text-grey-goose">
                  No {ENTITY_TYPE_LABELS[selectedType]} found
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
