import React from 'react';
import { Calendar } from 'lucide-react';

interface DateSelectorProps {
  date: Date;
  onDateChange: (date: Date) => void;
  timeframe: number;
  onTimeframeChange: (days: number) => void;
}

const DateSelector = ({ date, onDateChange, timeframe, onTimeframeChange }: DateSelectorProps) => {
  return (
    <div className="flex items-center space-x-4">
      <div className="relative">
        <input
          type="date"
          value={date.toISOString().split('T')[0]}
          onChange={(e) => onDateChange(new Date(e.target.value))}
          className="pl-10 pr-4 py-2 border border-grey-goose rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-red-500/20 focus:border-red-500"
        />
        <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" size={16} />
      </div>
      
      <select
        value={timeframe}
        onChange={(e) => onTimeframeChange(Number(e.target.value))}
        className="border border-grey-goose rounded-md px-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-red-500/20 focus:border-red-500"
      >
        <option value={15}>15 Days</option>
        <option value={30}>30 Days</option>
        <option value={90}>90 Days</option>
      </select>
    </div>
  );
};

export default DateSelector;