import React, { useState , useEffect} from "react";
import Heading from "../common/Heading";
import { about } from "../../../components/data/Data";

import { getMetrics } from "../../../api/hotelInterface";

function About() {
  const [metrics, setMetrics] = useState({ reservationCount: 0, roomCount: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await getMetrics();
        console.log(response.data);
        setMetrics(response.data);
      } catch (err) {
        setError('Failed to fetch metrics');
        console.error('Error fetching metrics:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
return (
  <div className="py-16 px-4 bg-gray-100">
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap -mx-4 items-center">
        <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
          <h6 className="text-[#FEA116] text-lg font-semibold uppercase mb-4">
            About Us
          </h6>
          <h1 className="text-4xl font-bold mb-4">
            Welcome to{' '}
            <span className="text-[#FEA116] uppercase">Lake Side Hotel</span>
          </h1>
          <p className="text-gray-700 mb-4">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
            Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit,
            sed stet lorem sit clita duo justo magna dolore erat amet.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
            {about.map((item, key) => (
              // <div
              //   key={key}
              //   className="border rounded-lg p-4 text-center bg-white shadow-md"
              // >
              //   <div className="text-3xl mb-2">{item.icon}</div>
              //   <h2 className="text-2xl font-semibold mb-2">
              //       {metrics.roomCount} Rooms
              //   </h2>
              //   <p className="text-gray-600">{metrics.reservationCount} Reservations</p>
              // </div>
              <div
                  key={key}
                  className="border rounded-lg p-4 text-center bg-white shadow-md"
                >
                  <div className="text-3xl mb-2">{item.icon}</div>
                  <h2 className="text-xl font-semibold my-2">
                    {item.text === "Rooms" ? metrics.roomCount : metrics.reservationCount} {item.text}
                  </h2>
                  {/* <p className="text-gray-600">{item.text === "Rooms" ? metrics.roomCount : metrics.reservationCount} {item.text}</p> */}
                </div>
            ))}
          </div>
          <a
            href="#"
            className="bg-[#FEA116] text-white my-4 py-3 px-6 rounded-md hover:bg-[#FEA116]-dark transition"
          >
            Explore More
          </a>
        </div>
        <div className="w-full lg:w-1/2 px-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1 flex justify-end">
              <img
                className="w-3/4 rounded-lg"
                src="/assets/img/about-1.jpg"
                alt="About 1"
              />
            </div>
            <div className="col-span-1 flex justify-start">
              <img
                className="w-full rounded-lg"
                src="/assets/img/about-2.jpg"
                alt="About 2"
              />
            </div>
            <div className="col-span-1 flex justify-end">
              <img
                className="w-1/2 rounded-lg"
                src="/assets/img/about-3.jpg"
                alt="About 3"
              />
            </div>
            <div className="col-span-1 flex justify-start">
              <img
                className="w-3/4 rounded-lg"
                src="/assets/img/about-4.jpg"
                alt="About 4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default About;