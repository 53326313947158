import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  IconSearch,
  IconChevronDown,
  IconBellFilled,
  IconSettings,
  IconLogout,
  IconMenu2
} from '@tabler/icons-react';
import store from '../../store';
import { setUser } from '../../store/slices/user-slice';

function Header() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [searchText, setSearchText] = useState('');
  const [showDropDown, setShowDropDown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const logout = () => {
    localStorage.clear();
    store.dispatch(setUser(null));
    navigate('/');
  };

  return (
    <header className='border-b border-gray-200 relative'>
      <div className='flex flex-col sm:flex-row justify-between items-center'>
        <div className='w-full sm:w-80 flex justify-between sm:justify-center items-center bg-[#E0EBF5] border-b sm:border-b-0 sm:border-r border-gray-200 py-3 gap-4'>
          <img
            src='/images/lake-side-logo.png'
            alt='logo'
            className='w-12 h-12'
          />
          <p className='text-2xl text-hazel-green font-bold ml-2'>POOL</p>
          <IconMenu2
            className='sm:hidden cursor-pointer'
            size={24}
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          />
        </div>

        <div className={`flex-grow flex justify-center ${showMobileMenu ? 'flex' : 'hidden'} sm:flex`}>
          <nav className='flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-12 font-semibold'>
            <Link to='/pool-interface' className='text-slate-500 transition-all duration-300 ease-in-out hover:text-hazel-green'>
              Main Page
            </Link>
            <Link to='/pool-interface/pools-list' className='text-slate-500 transition-all duration-300 ease-in-out hover:text-hazel-green'>
              Book Tickets
            </Link>
            <Link to='/pool-interface/pool-history' className='text-slate-500 transition-all duration-300 ease-in-out hover:text-hazel-green'>
              History
            </Link>
          </nav>
        </div>

        <div className='w-full sm:w-auto'>
          <div className='px-4 sm:px-10 py-4'>
            <div className='flex items-center justify-between text-base text-gray-700'>
              <div className='flex justify-center items-center'>
                <img
                  src='/images/profile-placeholder.png'
                  alt='logo'
                  className='w-10 h-10'
                />
                <div className='flex'>
                  <p className='capitalize mx-3'>
                    {user.name} {user.surname}
                  </p>
                  <IconChevronDown
                    className='text-heather cursor-pointer'
                    onClick={() => setShowDropDown(!showDropDown)}
                  />
                </div>
                <div className='relative inline-flex items-center ml-3'>
                  <IconBellFilled className='text-heather' />
                  <div className='absolute inline-flex items-center justify-center w-4 h-4 bg-red-500 border-2 border-white rounded-full -top-1.5 -end-1'></div>
                </div>
              </div>
              <div
                className={`${
                  showDropDown ? 'flex flex-col z-50' : 'hidden'
                } z-50 absolute top-14 ml-auto right-20 justify-end p-4 w-60 bg-white border border-light-gray rounded-md shadow-lg`}
              >
                <Link
                  to='/account-settings'
                  className='z-32 flex p-3 border-b border-light-gray'
                  onClick={() => setShowDropDown(false)}
                >
                  <IconSettings className='mr-2' size={22} />
                  Account Settings
                </Link>
                <p className='flex p-3 cursor-pointer' onClick={logout}>
                  <IconLogout className='mr-2' size={22} />
                  Logout
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
