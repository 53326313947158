// EditCategory.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IconBox } from '@tabler/icons-react'; // Adjust the icon as needed
import CategoryForm from './CategoryForm';
import PageTitle from '../../../components/page-title';
import Loading from '../../../components/loader';

function EditCategory() {
  const params = useParams();
  const categoryId = params.id;
  const [loading, setLoading] = useState(true);


  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconBox className='text-hazel-green mr-2' size={22} />}
        title='Edit Category'
      />
      <CategoryForm isEdit categoryId={categoryId} />
    </div>
  );
}

export default EditCategory;
