import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DateInput = ({
  label,
  value,
  onChange,
  onBlur,
  showError,
  errorMessage,
  disablePast
}) => {
  return (
    <div className='mb-3'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label}
          value={value}
          className='border boder-hazel-green rounded-md w-full'
          disablePast={disablePast}
          onChange={onChange}
          onBlur={onBlur}
        />
      </LocalizationProvider>
      {showError && <p className='text-sm text-error'>{errorMessage}</p>}
    </div>
  );
};

export default DateInput;
