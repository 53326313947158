import api from './api';

// Journal Entries
export const createJournalEntry = async (data) => {
  try {
    const response = await api.post('/api/journal', data);
    return response.data;
  } catch (error) {
    console.error('Error creating journal entry:', error);
    throw error;
  }
};

export const getJournalEntries = async (filters) => {
  try {
    const response = await api.get('/api/journal', {
      params: {
        ...filters,
        limit: filters.limit || 6, // Ensure limit is always 6 for pagination
        page: filters.page || 1
      }
    });
    return {
      message: {
        data: response.data.message.data,
        total: response.data.message.pagination.total,
        page: response.data.message.pagination.page,
        totalPages: response.data.message.pagination.totalPages
      }
    };
  } catch (error) {
    console.error('Error fetching journal entries:', error);
    throw error;
  }
};

export const getJournalEntryById = (id) => api.get(`/api/journal/${id}`);
export const updateJournalEntry = (id, data) => api.patch(`/api/journal/${id}`, data);
export const deleteJournalEntry = (id) => api.delete(`/api/journal/${id}`);

export const getNextJournalDocumentNumber = async () => {
  try {
    const response = await api.get('/api/journal/next-document-number');
    return response.data.message;
  } catch (error) {
    console.error('Error getting next journal document number:', error);
    throw error;
  }
};
