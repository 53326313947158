import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Package, Pencil, Settings, Plus, RotateCcw, AlignJustify, Trash2, Edit2 } from 'lucide-react';
import AddArticleModal from '../../../../components/articles/AddArticleModal.tsx';
import ArticleFormModal from '../../../../components/articles/ArticleFormModal.tsx';
import ServiceFormModal from '../../../../components/articles/ServiceFormModal.tsx';
import DeviceFormModal from '../../../../components/articles/DeviceFormModal.tsx';
import VatRateFormModal from '../../../../components/articles/VatRateFormModal.tsx';
import UnitOfMeasurementModal from '../../../../components/articles/UnitOfMeasurementModal.tsx';
import DeleteUnitConfirmationModal from '../../../../components/articles/DeleteUnitConfirmationModal.tsx';
import DeleteConfirmationModal from '../../../../components/articles/DeleteConfirmationModal.tsx';
import { getArticles, getVatRates, getUnits, deleteUnit, deleteArticle, updateArticle, getArticleById } from '../../../../api/accountingArticle';
import { toast } from 'react-hot-toast';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface Article {
  id: string;
  code: string;
  name: string;
  description?: string;
  type: string;
  status: string;
  purchasePrice?: number;
  sellingPrice?: number;
  isWholeNumber: boolean;
  vatRateId: string;
  unitId: string;
  vatRate: TVSHNorm;
  unit: NjesiaMatese;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

interface TVSHNorm {
  id: string;
  name: string;
  percentage: number;
  purchaseVatAccountId: string;
  salesVatAccountId: string;
  purchaseVatAccount: {
    id: string;
    code: string;
    name: string;
  };
  salesVatAccount: {
    id: string;
    code: string;
    name: string;
  };
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

interface NjesiaMatese {
  id: string;
  name: string;
  isActive: boolean;
  isWholeNumber: boolean;
  articles: any[];
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

const ITEMS_PER_PAGE = 5;

const ArticlesPage = () => {
  const [activeTab, setActiveTab] = useState('artikujt');
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showArticleFormModal, setShowArticleFormModal] = useState(false);
  const [showServiceFormModal, setShowServiceFormModal] = useState(false);
  const [showDeviceFormModal, setShowDeviceFormModal] = useState(false);
  const [showVatRateModal, setShowVatRateModal] = useState(false);
  const [isUnitModalOpen, setIsUnitModalOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<NjesiaMatese | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [unitToDelete, setUnitToDelete] = useState<NjesiaMatese | null>(null);
  const [articles, setArticles] = useState<Article[]>([]);
  const [vatRates, setVatRates] = useState<TVSHNorm[]>([]);
  const [units, setUnits] = useState<NjesiaMatese[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Pagination states
  const [articlesPage, setArticlesPage] = useState(1);
  const [unitsPage, setUnitsPage] = useState(1);
  const [vatRatesPage, setVatRatesPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (activeTab === 'artikujt') {
          const articlesResponse = await getArticles();
          setArticles(articlesResponse.data.message);
        } else if (activeTab === 'normat-tvsh') {
          const vatResponse = await getVatRates();
          setVatRates(vatResponse.data.message);
        } else if (activeTab === 'njesite-matese') {
          const unitsResponse = await getUnits();
          setUnits(unitsResponse.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeTab]);

  const handleArticleAdded = async () => {
    try {
      const response = await getArticles();
      setArticles(response.data.message);
    } catch (error) {
      console.error('Error refreshing articles:', error);
    }
  };

  const handleVatRateAdded = async () => {
    try {
      const response = await getVatRates();
      setVatRates(response.data.message);
    } catch (error) {
      console.error('Error refreshing VAT rates:', error);
    }
  };

  const handleUnitAdded = async () => {
    try {
      const response = await getUnits();
      setUnits(response.data.message);
    } catch (error) {
      console.error('Error refreshing units:', error);
    }
  };

  const handleEdit = async (article) => {
    try {
      // Get full article details including relations
      const response = await getArticleById(article.id);
      const articleData = response.data.message;
      setSelectedArticle(articleData);
      setIsEditing(true);
      
      // Open the appropriate modal based on article type
      switch (articleData.type) {
        case 'PRODUCT':
          setShowArticleFormModal(true);
          break;
        case 'SERVICE':
          setShowServiceFormModal(true);
          break;
        case 'FIXED_ASSET':
          setShowDeviceFormModal(true);
          break;
      }
    } catch (error) {
      console.error('Error fetching article details:', error);
      toast.error('Dështoi ngarkimi i të dhënave të artikullit');
    }
  };

  const handleSubmit = async (data) => {
    try {
      if (isEditing && selectedArticle) {
        await updateArticle(selectedArticle.id, data);
        toast.success('Artikulli u përditësua me sukses');
      } else {
        // await createArticle(data);
        toast.success('Artikulli u krijua me sukses');
      }
      
      // Close all modals
      setShowArticleFormModal(false);
      setShowServiceFormModal(false);
      setShowDeviceFormModal(false);
      setShowAddModal(false);
      setIsEditing(false);
      setSelectedArticle(null);
      
      // Refresh articles list
      const articlesResponse = await getArticles();
      setArticles(articlesResponse.data.message);
    } catch (error) {
      console.error('Error submitting article:', error);
      toast.error(isEditing ? 'Dështoi përditësimi i artikullit' : 'Dështoi krijimi i artikullit');
    }
  };

  const handleCloseModals = () => {
    setShowArticleFormModal(false);
    setShowServiceFormModal(false);
    setShowDeviceFormModal(false);
    setShowAddModal(false);
    setIsEditing(false);
    setSelectedArticle(null);
  };

  const handleEditUnit = (unit: NjesiaMatese) => {
    setSelectedUnit(unit);
    setIsUnitModalOpen(true);
  };

  const handleDeleteUnit = async () => {
    if (!unitToDelete) return;
    
    try {
      await deleteUnit(unitToDelete.id);
      toast.success('Njësia matëse u fshi me sukses');
      const unitsResponse = await getUnits();
      setUnits(unitsResponse.data.message);
      setIsDeleteModalOpen(false);
      setUnitToDelete(null);
    } catch (error) {
      console.error('Error deleting unit:', error);
      toast.error('Dështoi fshirja e njësisë matëse');
    }
  };

  const openDeleteModal = (unit: NjesiaMatese) => {
    setUnitToDelete(unit);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteArticle = async () => {
    if (!selectedArticle) return;
    
    try {
      await deleteArticle(selectedArticle.id);
      toast.success('Artikulli u fshi me sukses');
      const articlesResponse = await getArticles();
      setArticles(articlesResponse.data.message);
      setShowDeleteModal(false);
      setSelectedArticle(null);
    } catch (error) {
      console.error('Error deleting article:', error);
      toast.error('Dështoi fshirja e artikullit');
    }
  };

  const tabs = [
    { id: 'artikujt', label: 'Artikujt', icon: <Package className="w-5 h-5" /> },
    { id: 'njesite-matese', label: 'Njësitë Matëse', icon: <Pencil className="w-5 h-5" /> },
    { id: 'normat-tvsh', label: 'Normat e TVSH-së', icon: <Settings className="w-5 h-5" /> }
  ];

  const handleAddClick = () => {
    if (activeTab === 'artikujt') {
      setShowAddModal(true);
    } else if (activeTab === 'normat-tvsh') {
      setShowVatRateModal(true);
    } else if (activeTab === 'njesite-matese') {
      setIsUnitModalOpen(true);
    }
  };

  const getPaginatedData = <T extends any>(data: T[], page: number) => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    return data.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  const renderPagination = (
    currentPage: number,
    setPage: (page: number) => void,
    totalItems: number
  ) => {
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
    const startItem = (currentPage - 1) * ITEMS_PER_PAGE + 1;
    const endItem = Math.min(currentPage * ITEMS_PER_PAGE, totalItems);

    return (
      <div className="flex items-center justify-between border-t border-mercury px-4 py-3 mt-4 bg-white rounded-b-xl">
        <div className="flex items-center text-sm text-bluish-grey">
          <p>
            Showing <span className="font-medium text-gray-900">{startItem}</span>{" "}
            to <span className="font-medium text-gray-900">{endItem}</span>{" "}
            of <span className="font-medium text-gray-900">{totalItems}</span> entries
          </p>
        </div>
        <div className="flex items-center gap-6">
          <div className="text-sm text-bluish-grey">
            Page <span className="font-medium text-gray-900">{currentPage}</span> of{" "}
            <span className="font-medium text-gray-900">{totalPages}</span>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setPage(currentPage - 1)}
              disabled={currentPage === 1}
              className={`inline-flex items-center justify-center rounded-lg border ${
                currentPage === 1
                  ? 'border-grey-goose/30 text-grey-goose cursor-not-allowed'
                  : 'border-grey-goose/50 text-bluish-grey hover:border-hazel-green hover:text-hazel-green'
              } p-2 transition-colors duration-200`}
            >
              <ChevronLeft className="h-5 w-5" />
            </button>
            <button
              onClick={() => setPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`inline-flex items-center justify-center rounded-lg border ${
                currentPage === totalPages
                  ? 'border-grey-goose/30 text-grey-goose cursor-not-allowed'
                  : 'border-grey-goose/50 text-bluish-grey hover:border-hazel-green hover:text-hazel-green'
              } p-2 transition-colors duration-200`}
            >
              <ChevronRight className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-seashell p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-semibold text-bluish-grey">Artikujt</h1>
            <p className="text-grey-goose">Menaxhoni artikujt, njësitë matëse dhe normat e TVSh-së</p>
          </div>
          <div className="flex items-center gap-3">
            <button
              onClick={handleAddClick}
              className="flex items-center gap-2 px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-opacity-90 transition-colors"
            >
              <Plus className="w-5 h-5" />
              Shto
            </button>
            <button className="p-2 text-bluish-grey hover:bg-seashell rounded-lg">
              <RotateCcw className="w-4 h-4" />
            </button>
            <button className="p-2 text-bluish-grey hover:bg-seashell rounded-lg">
              <AlignJustify className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* Tabs */}
        <div className="bg-white rounded-lg shadow-sm mb-6">
          <div className="flex border-b border-mercury">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex items-center gap-2 px-6 py-4 text-sm font-medium transition-colors ${
                  activeTab === tab.id
                    ? 'text-hazel-green border-b-2 border-hazel-green'
                    : 'text-bluish-grey hover:text-hazel-green'
                }`}
              >
                {tab.icon}
                {tab.label}
              </button>
            ))}
          </div>
        </div>

        {/* Content Area */}
        <div className="bg-white rounded-lg shadow-sm">
          {/* Toolbar */}
          <div className="p-4 border-b border-mercury flex justify-between items-center">
            <div className="flex gap-2">
              <input
                type="text"
                placeholder="Kërko..."
                className="px-4 py-2 border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          {/* Table */}
          {activeTab === 'artikujt' && (
            <div className="overflow-x-auto">
              {loading ? (
                <div className="flex items-center justify-center p-8">
                  <RotateCcw className="w-6 h-6 animate-spin text-hazel-green" />
                </div>
              ) : articles.length === 0 ? (
                <div className="flex flex-col items-center justify-center p-8 text-bluish-grey">
                  <Package className="w-12 h-12 mb-2 text-grey-goose" />
                  <p>Nuk ka artikuj për të shfaqur</p>
                  <button
                    onClick={() => setShowAddModal(true)}
                    className="mt-4 px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-opacity-90 transition-colors"
                  >
                    <Plus className="w-5 h-5 inline-block mr-2" />
                    Shto Artikull
                  </button>
                </div>
              ) : (
                <table className="w-full">
                  <thead className="bg-seashell">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Kodi</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Emri</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Lloji</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Statusi</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Njësia</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">TVSH</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Krijuar më</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Krijuar nga</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Veprimet</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-mercury">
                    {getPaginatedData(articles, articlesPage).map((item) => (
                      <tr key={item.id} className="hover:bg-seashell transition-colors">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{item.code}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{item.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 py-1 text-xs rounded-full ${
                            item.type === 'PRODUCT' 
                              ? 'bg-hazel-green/10 text-hazel-green'
                              : item.type === 'SERVICE'
                              ? 'bg-tealish-blue/10 text-tealish-blue'
                              : 'bg-amber-100 text-amber-600'
                          }`}>
                            {item.type === 'PRODUCT' 
                              ? 'Produkt' 
                              : item.type === 'SERVICE' 
                              ? 'Shërbim'
                              : 'Aktiv i Fiksuar'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 py-1 text-xs rounded-full ${
                            item.status === 'ACTIVE' 
                              ? 'bg-hazel-green/10 text-hazel-green' 
                              : 'bg-red-100 text-red-600'
                          }`}>
                            {item.status === 'ACTIVE' ? 'Aktiv' : 'Jo-aktiv'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                          {item.unit.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                          {item.vatRate.percentage}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                          {new Date(item.createdAt).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <span className="w-8 h-8 rounded-full bg-dawn-pink text-salmon-pink flex items-center justify-center text-sm font-medium">
                              {item.createdBy.charAt(0).toUpperCase()}
                            </span>
                            <span className="ml-2 text-sm text-bluish-grey">{item.createdBy}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                          <div className="flex items-center gap-2">
                            <button 
                              onClick={() => handleEdit(item)}
                              className="hover:text-hazel-green"
                            >
                              <Edit2 className="w-4 h-4" />
                            </button>
                            <button 
                              onClick={() => {
                                setSelectedArticle(item);
                                setShowDeleteModal(true);
                              }}
                              className="hover:text-red-600"
                            >
                              <Trash2 className="w-4 h-4" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}

          {activeTab === 'njesite-matese' && (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-seashell">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Emri</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Aktiv</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Numër i plotë</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Artikujt</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Krijuar më</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Krijuar nga</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Veprimet</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-mercury">
                  {getPaginatedData(units, unitsPage).map((item) => (
                    <tr key={item.id} className="hover:bg-seashell transition-colors">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{item.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 py-1 text-xs rounded-full ${
                          item.isActive 
                            ? 'bg-hazel-green/10 text-hazel-green' 
                            : 'bg-red-100 text-red-600'
                        }`}>
                          {item.isActive ? 'Aktiv' : 'Jo-aktiv'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 py-1 text-xs rounded-full ${
                          item.isWholeNumber 
                            ? 'bg-hazel-green/10 text-hazel-green' 
                            : 'bg-orange-100 text-orange-600'
                        }`}>
                          {item.isWholeNumber ? 'Po' : 'Jo'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {item.articles.length} artikuj
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {new Date(item.createdAt).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className="w-8 h-8 rounded-full bg-dawn-pink text-salmon-pink flex items-center justify-center text-sm font-medium">
                            {item.createdBy.charAt(0).toUpperCase()}
                          </span>
                          <span className="ml-2 text-sm text-bluish-grey">{item.createdBy}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        <div className="flex items-center gap-2">
                          <button 
                            onClick={() => handleEditUnit(item)}
                            className="hover:text-hazel-green"
                          >
                            <Pencil className="w-4 h-4" />
                          </button>
                          <button 
                            onClick={() => openDeleteModal(item)}
                            className="hover:text-red-600"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {activeTab === 'normat-tvsh' && (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-seashell">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Emri</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Përqindja</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Kontoja - TVSh në Blerje</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Kontoja - TVSh në Shitje</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Krijuar më</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Krijuar nga</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Veprimet</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-mercury">
                  {getPaginatedData(vatRates, vatRatesPage).map((item, index) => (
                    <tr key={item.id} className="hover:bg-seashell transition-colors">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{item.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{item.percentage}%</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {item.purchaseVatAccount?.code} - {item.purchaseVatAccount?.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {item.salesVatAccount?.code} - {item.salesVatAccount?.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{new Date(item.createdAt).toLocaleDateString()}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className="w-8 h-8 rounded-full bg-dawn-pink text-salmon-pink flex items-center justify-center text-sm font-medium">
                            {item.createdBy.charAt(0).toUpperCase()}
                          </span>
                          <span className="ml-2 text-sm text-bluish-grey">{item.createdBy}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        <div className="flex items-center gap-2">
                          <button className="hover:text-hazel-green">
                            <Pencil className="w-4 h-4" />
                          </button>
                          <button className="hover:text-red-600">
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {activeTab === 'artikujt' && renderPagination(articlesPage, setArticlesPage, articles.length)}
      {activeTab === 'njesite-matese' && renderPagination(unitsPage, setUnitsPage, units.length)}
      {activeTab === 'normat-tvsh' && renderPagination(vatRatesPage, setVatRatesPage, vatRates.length)}
      {showAddModal && !isEditing && (
        <AddArticleModal
          isOpen={showAddModal}
          onClose={() => setShowAddModal(false)}
          onSuccess={handleArticleAdded}
        />
      )}

      {showArticleFormModal && (
        <ArticleFormModal
          onBack={handleCloseModals}
          onClose={handleCloseModals}
          onSubmit={handleSubmit}
          articleType="PRODUCT"
          editData={selectedArticle}
        />
      )}

      {showServiceFormModal && (
        <ServiceFormModal
          onBack={handleCloseModals}
          onClose={handleCloseModals}
          onSubmit={handleSubmit}
          articleType="SERVICE"
          editData={selectedArticle}
        />
      )}

      {showDeviceFormModal && (
        <DeviceFormModal
          onBack={handleCloseModals}
          onClose={handleCloseModals}
          onSubmit={handleSubmit}
          articleType="FIXED_ASSET"
          editData={selectedArticle}
        />
      )}

      <VatRateFormModal
        isOpen={showVatRateModal}
        onClose={() => setShowVatRateModal(false)}
        onSuccess={handleVatRateAdded}
      />
      <UnitOfMeasurementModal
        isOpen={isUnitModalOpen}
        onClose={() => {
          setIsUnitModalOpen(false);
          setSelectedUnit(null);
        }}
        onSuccess={async () => {
          const unitsResponse = await getUnits();
          setUnits(unitsResponse.data.message);
        }}
        unit={selectedUnit}
      />
      <DeleteUnitConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setUnitToDelete(null);
        }}
        onConfirm={handleDeleteUnit}
        unitName={unitToDelete?.name || ''}
      />
      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setSelectedArticle(null);
        }}
        onConfirm={handleDeleteArticle}
        title="Fshi Artikullin"
        message={`A jeni të sigurt që dëshironi të fshini artikullin "${selectedArticle?.name}"?`}
      />
    </div>
  );
};

export default ArticlesPage;
