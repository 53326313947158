import React from 'react';
import { X, Plus, HelpCircle } from 'lucide-react';
import { ItemRow } from '../../gobal';

interface ItemTableProps {
  items: ItemRow[];
  onItemChange: (items: ItemRow[]) => void;
}

export function ItemTable({ items, onItemChange }: ItemTableProps) {
  return (
    <div className="bg-seashell rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-sm font-medium text-bluish-grey">Item Table</h2>
        <button className="text-hazel-green text-sm flex items-center hover:text-sage transition-colors">
          <HelpCircle size={14} className="mr-1" />
          Bulk Actions
        </button>
      </div>

      <div className="overflow-x-auto rounded-lg bg-white">
        <table className="w-full">
          <thead className="bg-seashell border-b border-grey-goose">
            <tr>
              <th className="p-2 text-left text-xs font-medium text-bluish-grey">ITEM DETAILS</th>
              <th className="p-2 text-left text-xs font-medium text-bluish-grey">ACCOUNT</th>
              <th className="p-2 text-left text-xs font-medium text-bluish-grey w-24">QTY</th>
              <th className="p-2 text-left text-xs font-medium text-bluish-grey w-24">RATE</th>
              <th className="p-2 text-left text-xs font-medium text-bluish-grey w-24">DISC</th>
              <th className="p-2 text-left text-xs font-medium text-bluish-grey w-32">TAX</th>
              <th className="p-2 text-right text-xs font-medium text-bluish-grey w-24">AMOUNT</th>
              <th className="p-2 w-8"></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id} className="border-b border-grey-goose">
                <td className="p-2">
                  <input 
                    type="text"
                    placeholder="Select item"
                    className="w-full p-1.5 text-sm border border-grey-goose rounded focus:outline-none focus:border-sage"
                  />
                </td>
                <td className="p-2">
                  <select className="w-full p-1.5 text-sm border border-grey-goose rounded focus:outline-none focus:border-sage">
                    <option>Select account</option>
                  </select>
                </td>
                <td className="p-2">
                  <input 
                    type="number"
                    value={item.quantity}
                    className="w-full p-1.5 text-sm border border-grey-goose rounded focus:outline-none focus:border-sage"
                  />
                </td>
                <td className="p-2">
                  <input 
                    type="number"
                    value={item.rate}
                    className="w-full p-1.5 text-sm border border-grey-goose rounded focus:outline-none focus:border-sage"
                  />
                </td>
                <td className="p-2">
                  <div className="flex items-center">
                    <input 
                      type="number"
                      value={item.discount}
                      className="w-16 p-1.5 text-sm border border-grey-goose rounded focus:outline-none focus:border-sage"
                    />
                    <span className="ml-1 text-xs text-silver">%</span>
                  </div>
                </td>
                <td className="p-2">
                  <select className="w-full p-1.5 text-sm border border-grey-goose rounded focus:outline-none focus:border-sage">
                    <option>Select tax</option>
                  </select>
                </td>
                <td className="p-2 text-right text-sm">{item.amount.toFixed(2)}</td>
                <td className="p-2">
                  <button className="text-silver hover:text-watermelon transition-colors">
                    <X size={14} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-3 space-x-3">
        <button className="text-hazel-green text-sm flex items-center hover:text-sage transition-colors">
          <Plus size={14} className="mr-1" />
          Add Row
        </button>
        <button className="text-hazel-green text-sm flex items-center hover:text-sage transition-colors">
          <Plus size={14} className="mr-1" />
          Bulk Add
        </button>
      </div>
    </div>
  );
}