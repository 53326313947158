import React from "react";
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from "../../../../../components/shadCN/Table.tsx";
import { Calendar } from "lucide-react";

const FinancialStatement = ({ data }) => {
    // Use optional chaining and provide fallback values to avoid errors
    const longTermAssets = data?.assets?.longTerm || [];
    const shortTermAssets = data?.assets?.shortTerm || [];
    const longTermLiabilities = data?.liabilities?.longTerm || [];
    const shortTermLiabilities = data?.liabilities?.shortTerm || [];
    const totalAssets2021 = data?.totals?.assets?.value2021 || 0;
    const totalAssets2020 = data?.totals?.assets?.value2020 || 0;
    const totalLiabilities2021 = data?.totals?.liabilities?.value2021 || 0;
    const totalLiabilities2020 = data?.totals?.liabilities?.value2020 || 0;
  
    return (
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-5xl mx-auto my-4">
        <div className="flex flex-row items-center justify-between mb-6 bg-sage rounded-lg px-8 py-4 text-white font-bold">
          <div className="flex flex-col gap-4">
            <h2 className="text-2xl font-semibold">Pasqyra e Gjendjes Financiare, IAS/IFRS 1</h2>
            <div className="py-2 flex flex-row items-center justify-between text-lg bg-hazel-green text-white font-semibold mt-1 px-4 py-1 rounded-lg">
              <span>31.12.2021</span>
              <Calendar className="text-white" size={28} />
            </div>
          </div>
          <img src="/images/lake-side-logo.png" alt="Lake Side Logo" className="w-32" />
        </div>
  
        {/* Assets Section */}
        <Table className="min-w-full text-sm border border-gray-200">
          <TableHeader>
            <TableRow className="bg-gray-200">
              <TableHead className="text-left px-4 py-2">Asetet</TableHead>
              <TableHead className="text-right px-4 py-2">2021</TableHead>
              <TableHead className="text-right px-4 py-2">2020</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {/* Long-term assets */}
            {longTermAssets.map((item, index) => (
              <TableRow key={index} className={index % 2 === 0 ? "bg-gray-50" : ""}>
                <TableCell className="text-gray-700 px-4 py-2">{item?.name || "N/A"}</TableCell>
                <TableCell className="text-right text-gray-700 px-4 py-2">{item?.value2021 || 0}</TableCell>
                <TableCell className="text-right text-gray-700 px-4 py-2">{item?.value2020 || 0}</TableCell>
              </TableRow>
            ))}
            {/* Short-term assets */}
            {shortTermAssets.map((item, index) => (
              <TableRow key={index} className={index % 2 === 0 ? "bg-gray-50" : ""}>
                <TableCell className="text-gray-700 px-4 py-2">{item?.name || "N/A"}</TableCell>
                <TableCell className="text-right text-gray-700 px-4 py-2">{item?.value2021 || 0}</TableCell>
                <TableCell className="text-right text-gray-700 px-4 py-2">{item?.value2020 || 0}</TableCell>
              </TableRow>
            ))}
            {/* Total Assets */}
            <TableRow className="bg-hazel-green font-bold">
              <TableCell className="text-gray-100 px-4 py-2">Gjithsej Asetet</TableCell>
              <TableCell className="text-right bg-sage text-gray-800 px-4 py-2">{totalAssets2021}</TableCell>
              <TableCell className="text-right bg-sage text-gray-800 px-4 py-2">{totalAssets2020}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
  
        {/* Liabilities Section */}
        <Table className="min-w-full text-sm border border-gray-200 mt-8">
          <TableHeader>
            <TableRow className="bg-gray-200">
              <TableHead className="text-left px-4 py-2">Detyrimet dhe Kapitali</TableHead>
              <TableHead className="text-right px-4 py-2">2021</TableHead>
              <TableHead className="text-right px-4 py-2">2020</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {/* Long-term liabilities */}
            {longTermLiabilities.map((item, index) => (
              <TableRow key={index} className={index % 2 === 0 ? "bg-gray-50" : ""}>
                <TableCell className="text-gray-700 px-4 py-2">{item?.name || "N/A"}</TableCell>
                <TableCell className="text-right text-gray-700 px-4 py-2">{item?.value2021 || 0}</TableCell>
                <TableCell className="text-right text-gray-700 px-4 py-2">{item?.value2020 || 0}</TableCell>
              </TableRow>
            ))}
            {/* Short-term liabilities */}
            {shortTermLiabilities.map((item, index) => (
              <TableRow key={index} className={index % 2 === 0 ? "bg-gray-50" : ""}>
                <TableCell className="text-gray-700 px-4 py-2">{item?.name || "N/A"}</TableCell>
                <TableCell className="text-right text-gray-700 px-4 py-2">{item?.value2021 || 0}</TableCell>
                <TableCell className="text-right text-gray-700 px-4 py-2">{item?.value2020 || 0}</TableCell>
              </TableRow>
            ))}
            {/* Total Liabilities */}
            <TableRow className="bg-hazel-green font-bold">
              <TableCell className="text-gray-100 px-4 py-2">Gjithsej Detyrimet dhe Kapitali</TableCell>
              <TableCell className="text-right bg-sage text-gray-800 px-4 py-2">{totalLiabilities2021}</TableCell>
              <TableCell className="text-right bg-sage text-gray-800 px-4 py-2">{totalLiabilities2020}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  };
  

export default FinancialStatement;

