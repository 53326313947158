import React from 'react';

const MonthSelect = ({ value, onChange, showError, errorMessage }) => {
  const months = Array.from({ length: 12 }, (_, index) => ({
    value: (index + 1).toString(), // "1", "2", ..., "12"
    label: new Intl.DateTimeFormat('default', { month: 'long' }).format(new Date(0, index)), // "January", "February", ..., "December"
  }));

  return (
    <div>
      <label htmlFor="month" className="block text-sm font-medium text-bluish-grey mb-1">
        Select Month
      </label>
      <select
        id="month"
        value={value}
        onChange={onChange}
        className={`block w-full border ${showError ? 'border-red-500' : 'border-gray-300'} rounded-md p-2`}
      >
        <option value="" disabled>Select Month</option>
        {months.map((month) => (
          <option key={month.value} value={month.value}>
            {month.label}
          </option>
        ))}
      </select>
      {showError && <p className="mt-1 text-red-500">{errorMessage}</p>}
    </div>
  );
};

export default MonthSelect;
