import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, RotateCw, X, Save, ArrowLeft, ChevronDown } from 'lucide-react';
import { getAllAccounts } from '../../api/accountingPlan';
import { getVatRates, getUnits, getEntitiesByType } from '../../api/accountingArticle';
import { toast } from 'react-hot-toast';
import { EntitySelector, EntityType } from './EntitySelector.tsx';

interface ArticleFormModalProps {
  onBack: () => void;
  onClose: () => void;
  articleType: string;
  onSubmit: (data: any) => void;
  editData?: any;
  loading?: boolean;
  onEntityLink?: (link: { entityType: string; entityId: string; entityCode?: string; entityName?: string; entityCategory?: string; entityReference?: string; } | null) => void;
}

interface Account {
  id: string;
  code: string;
  name: string;
}

interface VatRate {
  id: string;
  code: string;
  name: string;
  percentage: number;
}

interface Unit {
  id: string;
  code: string;
  name: string;
}

interface Entity {
  id: string;
  name?: string;
  title?: string;
  price: number;
  type?: string;
  description?: string;
  capacity?: number;
  menu?: {
    name: string;
  };
  categoryOrderProduct?: {
    title: string;
  };
}

const ENTITY_TYPE_LABELS: Record<EntityType, string> = {
  'DishSection': 'Dish Section',
  'BarProduct': 'Bar Product',
  'InventoryProduct': 'Inventory Product'
};

const ArticleFormModal: React.FC<ArticleFormModalProps> = ({
  onBack,
  onClose,
  articleType,
  onSubmit,
  editData,
  loading = false,
  onEntityLink
}) => {
  const [formData, setFormData] = useState({
    emri: editData?.name || '',
    kodi: editData?.code || '',
    njesiaMatese: editData?.unitId || '',
    normaTvsh: editData?.vatRateId || '',
    status: editData?.status || 'ACTIVE',
    type: articleType,
    accounts: {
      blerje: editData?.purchaseAccount ? { 
        id: editData.purchaseAccount.id,
        code: editData.purchaseAccount.code, 
        name: editData.purchaseAccount.name 
      } : {
        id: '', // Will be set when accounts are loaded
        code: '1200',
        name: 'Malli'
      },
      shitje: editData?.salesAccount ? { 
        id: editData.salesAccount.id,
        code: editData.salesAccount.code, 
        name: editData.salesAccount.name 
      } : {
        id: '', // Will be set when accounts are loaded
        code: '4000',
        name: 'Te hyrat nga shitja e mallrave'
      },
      kmsh: editData?.kmshAccount ? { 
        id: editData.kmshAccount.id,
        code: editData.kmshAccount.code, 
        name: editData.kmshAccount.name 
      } : {
        id: '', // Will be set when accounts are loaded
        code: '5000',
        name: 'KMSH'
      }
    },
    entityId: editData?.articleLinks?.[0]?.entityId || '',
    entityType: editData?.articleLinks?.[0]?.entityType || '',
    entityCode: editData?.articleLinks?.[0]?.entityCode || '',
    entityName: editData?.articleLinks?.[0]?.entityName || '',
    entityCategory: editData?.articleLinks?.[0]?.entityCategory || '',
    entityReference: editData?.articleLinks?.[0]?.entityReference || '',
    name: editData?.name || '',
    price: editData?.price || 0,
    description: editData?.description || ''
  });

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [vatRates, setVatRates] = useState<VatRate[]>([]);
  const [units, setUnits] = useState<Unit[]>([]);
  const [entities, setEntities] = useState<Entity[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [error, setError] = useState('');
  const searchTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [accountsResponse, vatRatesResponse, unitsResponse] = await Promise.all([
          getAllAccounts(),
          getVatRates(),
          getUnits()
        ]);

        setAccounts(accountsResponse.data.message);
        setVatRates(vatRatesResponse.data.message);
        setUnits(unitsResponse.data.message);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Dështoi ngarkimi i të dhënave');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (accounts.length > 0) {
      setFormData(prev => ({
        ...prev,
        accounts: {
          blerje: prev.accounts.blerje.id ? prev.accounts.blerje : {
            ...prev.accounts.blerje,
            id: accounts.find(acc => acc.code === prev.accounts.blerje.code)?.id || ''
          },
          shitje: prev.accounts.shitje.id ? prev.accounts.shitje : {
            ...prev.accounts.shitje,
            id: accounts.find(acc => acc.code === prev.accounts.shitje.code)?.id || ''
          },
          kmsh: prev.accounts.kmsh.id ? prev.accounts.kmsh : {
            ...prev.accounts.kmsh,
            id: accounts.find(acc => acc.code === prev.accounts.kmsh.code)?.id || ''
          }
        }
      }));
    }
  }, [accounts]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      // Validate required fields
      const missingFields = [];
      if (!formData.emri) missingFields.push('Name');
      if (!formData.kodi) missingFields.push('Code');
      if (!formData.njesiaMatese) missingFields.push('Unit');
      if (!formData.normaTvsh) missingFields.push('VAT Rate');
      if (!formData.accounts.blerje?.id) missingFields.push('Purchase Account');
      if (!formData.accounts.shitje?.id) missingFields.push('Sales Account');

      if (missingFields.length > 0) {
        toast.error(`Please fill in required fields: ${missingFields.join(', ')}`);
        return;
      }

      // Structure the data properly for submission
      const submitData = {
        article: {
          name: formData.emri,
          code: formData.kodi,
          type: articleType,
          status: formData.status,
          unitId: formData.njesiaMatese,
          vatRateId: formData.normaTvsh,
          purchaseAccountId: formData.accounts.blerje.id,
          salesAccountId: formData.accounts.shitje.id,
          kmshAccountId: formData.accounts.kmsh?.id || null,
          isWholeNumber: true
        }
      };

      // Add link data if an entity is selected
      if (formData.entityId) {
        submitData.link = {
          entityType: formData.entityType,
          entityId: formData.entityId,
          entityCode: formData.entityCode,
          entityName: formData.entityName,
          entityCategory: formData.entityCategory,
          entityReference: formData.entityReference
        };
      }

      console.log('Submitting article data:', submitData);
      
      // Call the onSubmit prop with the properly structured data
      await onSubmit(submitData);

      // Show success message
      toast.success('Article created successfully');

      // Close the modal
      onClose();
    } catch (error: any) {
      console.error('Error submitting article:', error);
      toast.error(error.response?.data?.message || 'Error creating article');
    }
  };

  const fetchEntities = async (type: string, search?: string) => {
    try {
      console.log('Fetching entities for type:', type, 'search:', search);
      setIsSearching(true);

      const response = await getEntitiesByType(type, search);
      console.log('API Response:', response);

      if (response?.data?.message) {
        setEntities(response.data.message);
      } else {
        console.error('Invalid response format:', response);
        setEntities([]);
      }
    } catch (error) {
      console.error('Error fetching entities:', error);
      toast.error('Failed to fetch entities');
      setEntities([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleEntityTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value;
    console.log('Entity type changed to:', type);
    setFormData({ ...formData, entityType: type, entityId: '' });
    setSearchTerm('');
    if (type) {
      fetchEntities(type);
    } else {
      setEntities([]);
    }
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setIsSearching(true);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      if (formData.entityType) {
        console.log('Searching with term:', value);
        fetchEntities(formData.entityType, value);
      }
    }, 300);
  };

  const getEntityLabel = (entity: Entity) => {
    if (entity.type === 'InventoryProduct') {
      return `${entity.name || ''} ${
        entity.description ? `- ${entity.description}` : ''
      } ${entity.capacity ? `- Capacity: ${entity.capacity}` : ''}`;
    }

    const name = entity.name || entity.title || '';
    const type = entity.type ? ` (${entity.type})` : '';
    const menu = entity.menu?.name ? ` - Menu: ${entity.menu.name}` : '';
    const category = entity.categoryOrderProduct?.title ? ` - Category: ${entity.categoryOrderProduct.title}` : '';
    const price = entity.price ? ` - $${entity.price.toFixed(2)}` : '';
    
    return `${name}${type}${menu}${category}${price}`;
  };

  const ALLOWED_ENTITY_TYPES: EntityType[] = ['DishSection', 'BarProduct', 'InventoryProduct'];

  const handleEntitySelect = (entityId: string, entityType: string, entityDetails: any) => {
    const category = entityType === 'InventoryProduct' 
      ? entityDetails.description 
      : entityDetails.categoryOrderProduct?.title || entityDetails.category || '';

    setFormData(prev => ({
      ...prev,
      entityId: entityId,
      entityType: entityType,
      entityCode: entityDetails.code || '',
      entityName: entityDetails.name || entityDetails.title || '',
      entityCategory: category,
      entityReference: `${entityType}-${entityId}`
    }));

    if (onEntityLink) {
      onEntityLink({
        entityType,
        entityId,
        entityCode: entityDetails.code || '',
        entityName: entityDetails.name || entityDetails.title || '',
        entityCategory: category,
        entityReference: `${entityType}-${entityId}`
      });
    }
  };

  if (isLoading) {
    return <div className="flex items-center justify-center p-8">
      <RotateCw className="w-6 h-6 animate-spin text-hazel-green" />
    </div>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-bluish-grey/20 backdrop-blur-sm"
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        transition={{ type: "spring", duration: 0.5 }}
        className="bg-white rounded-2xl shadow-2xl w-full max-w-4xl max-h-[90vh] flex flex-col overflow-hidden border border-grey-goose/30"
      >
        {/* Fixed Header */}
        <div className="flex items-center justify-between px-8 py-6 bg-white border-b border-grey-goose/30">
          <div className="flex items-center gap-4">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={onBack}
              className="p-2 text-bluish-grey hover:bg-seashell rounded-xl transition-colors"
            >
              <ArrowLeft className="w-5 h-5" />
            </motion.button>
            <div>
              <h2 className="text-2xl font-semibold text-bluish-grey">Shto Artikull të ri</h2>
              <p className="text-sm text-grey-goose mt-1">Plotësoni të dhënat e artikullit të ri</p>
            </div>
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClose}
            className="p-2 text-grey-goose hover:text-bluish-grey hover:bg-seashell rounded-xl transition-colors"
          >
            <X className="w-5 h-5" />
          </motion.button>
        </div>

        {/* Scrollable Content */}
        <form id="articleForm" onSubmit={handleSubmit} className="flex-1 overflow-y-auto">
          <div className="p-8 space-y-8">
            {/* Main Form Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Left Column */}
              <div className="space-y-6">
                <motion.div
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.1 }}
                  className="space-y-2"
                >
                  <label className="block text-sm font-medium text-bluish-grey">Emri</label>
                  <input
                    type="text"
                    className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50"
                    placeholder="Emri i artikullit"
                    value={formData.emri}
                    onChange={(e) => setFormData({ ...formData, emri: e.target.value })}
                  />
                </motion.div>

                <motion.div
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="space-y-2"
                >
                  <label className="block text-sm font-medium text-bluish-grey">Tipi i artikullit</label>
                  <input
                    type="text"
                    className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl bg-seashell cursor-not-allowed text-bluish-grey"
                    value={articleType}
                    disabled
                  />
                </motion.div>

                <motion.div
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  className="space-y-2"
                >
                  <label className="block text-sm font-medium text-bluish-grey">Kodi</label>
                  <input
                    type="text"
                    className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50"
                    placeholder="Kodi i artikullit"
                    value={formData.kodi}
                    onChange={(e) => setFormData({ ...formData, kodi: e.target.value })}
                  />
                </motion.div>
              </div>

              {/* Right Column */}
              <div className="space-y-6">
                <motion.div
                  initial={{ x: 20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.1 }}
                  className="space-y-2"
                >
                  <label className="block text-sm font-medium text-bluish-grey">Njësia Matëse</label>
                  <select
                    value={formData.njesiaMatese}
                    onChange={(e) => setFormData({ ...formData, njesiaMatese: e.target.value })}
                    className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50"
                    required
                  >
                    <option value="">Zgjedh njësinë matëse</option>
                    {units.map((unit) => (
                      <option key={unit.id} value={unit.id}>
                        {unit.name}
                      </option>
                    ))}
                  </select>
                </motion.div>

                <motion.div
                  initial={{ x: 20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="space-y-2"
                >
                  <label className="block text-sm font-medium text-bluish-grey">Norma e TVSH</label>
                  <select
                    value={formData.normaTvsh}
                    onChange={(e) => setFormData({ ...formData, normaTvsh: e.target.value })}
                    className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50"
                    required
                  >
                    <option value="">Zgjedh normën e TVSH</option>
                    {vatRates.map((rate) => (
                      <option key={rate.id} value={rate.id}>
                        {rate.name} ({rate.percentage}%)
                      </option>
                    ))}
                  </select>
                </motion.div>

                <motion.div
                  initial={{ x: 20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  className="space-y-2"
                >
                  <label className="block text-sm font-medium text-bluish-grey">Statusi</label>
                  <div className="flex items-center space-x-2">
                    <div 
                      className={`relative inline-block w-12 h-6 transition duration-200 ease-in-out rounded-full cursor-pointer ${
                        formData.status === 'ACTIVE' ? 'bg-hazel-green' : 'bg-grey-goose/30'
                      }`}
                    >
                      <div
                        className={`absolute w-5 h-5 transition-all duration-200 transform bg-white rounded-full top-0.5 ${
                          formData.status === 'ACTIVE' ? 'right-0.5' : 'left-0.5'
                        }`}
                      />
                      <input
                        type="checkbox"
                        checked={formData.status === 'ACTIVE'}
                        onChange={(e) => setFormData({ ...formData, status: e.target.checked ? 'ACTIVE' : 'INACTIVE' })}
                        className="absolute w-full h-full opacity-0 cursor-pointer"
                      />
                    </div>
                    <span className="text-sm text-bluish-grey">
                      {formData.status === 'ACTIVE' ? 'Aktiv' : 'Joaktiv'}
                    </span>
                  </div>
                </motion.div>
              </div>
            </div>

            {/* Accounts Section */}
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
              className="bg-seashell rounded-2xl p-8 space-y-6"
            >
              <div className="flex items-center gap-3">
                <h3 className="text-lg font-semibold text-bluish-grey">Kontot</h3>
                <span className="px-3 py-1 text-xs font-medium bg-hazel-green/10 text-hazel-green rounded-full">
                  Rekomanduar
                </span>
              </div>

              <div className="grid grid-cols-1 gap-4 mb-6">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-1">
                    Konto e artikullit në Blerje
                  </label>
                  <select
                    value={formData.accounts.blerje?.code}
                    onChange={(e) => {
                      const selectedAccount = accounts.find(acc => acc.code === e.target.value);
                      setFormData(prev => ({
                        ...prev,
                        accounts: {
                          ...prev.accounts,
                          blerje: selectedAccount ? { id: selectedAccount.id, code: selectedAccount.code, name: selectedAccount.name } : null
                        }
                      }));
                    }}
                    className="w-full px-4 py-2 rounded-lg border border-grey-goose focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green"
                  >
                    <option value="">Zgjidhni kontën</option>
                    {accounts.map((account) => (
                      <option key={account.id} value={account.code}>
                        {account.code} - {account.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-1">
                    Konto e artikullit në Shitje
                  </label>
                  <select
                    value={formData.accounts.shitje?.code}
                    onChange={(e) => {
                      const selectedAccount = accounts.find(acc => acc.code === e.target.value);
                      setFormData(prev => ({
                        ...prev,
                        accounts: {
                          ...prev.accounts,
                          shitje: selectedAccount ? { id: selectedAccount.id, code: selectedAccount.code, name: selectedAccount.name } : null
                        }
                      }));
                    }}
                    className="w-full px-4 py-2 rounded-lg border border-grey-goose focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green"
                  >
                    <option value="">Zgjidhni kontën</option>
                    {accounts.map((account) => (
                      <option key={account.id} value={account.code}>
                        {account.code} - {account.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-1">
                    Konto KMSH
                  </label>
                  <select
                    value={formData.accounts.kmsh?.code}
                    onChange={(e) => {
                      const selectedAccount = accounts.find(acc => acc.code === e.target.value);
                      setFormData(prev => ({
                        ...prev,
                        accounts: {
                          ...prev.accounts,
                          kmsh: selectedAccount ? { id: selectedAccount.id, code: selectedAccount.code, name: selectedAccount.name } : null
                        }
                      }));
                    }}
                    className="w-full px-4 py-2 rounded-lg border border-grey-goose focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green"
                  >
                    <option value="">Zgjidhni kontën</option>
                    {accounts.map((account) => (
                      <option key={account.id} value={account.code}>
                        {account.code} - {account.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </motion.div>

            {/* Entity Linking Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="bg-seashell rounded-xl shadow-lg p-6 space-y-6 mb-6 border border-grey-goose/30 hover:border-hazel-green/30 transition-all duration-300"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className="w-10 h-10 rounded-full bg-hazel-green/10 flex items-center justify-center">
                    <Plus className="h-6 w-6 text-hazel-green" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900">Link to Entity</h3>
                    <p className="text-sm text-bluish-grey">
                      Connect this article to a specific hotel entity
                    </p>
                  </div>
                </div>
              </div>

              <EntitySelector
                allowedTypes={ALLOWED_ENTITY_TYPES}
                onEntitySelect={handleEntitySelect}
                selectedEntityId={formData.entityId}
                selectedEntityType={formData.entityType}
                typeLabels={ENTITY_TYPE_LABELS}
              />

              {formData.entityId && (
                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                  className="flex items-center p-4 mt-4 bg-tealish-blue rounded-lg border border-grey-goose/30"
                >
                  <div className="flex-shrink-0">
                    <div className="h-10 w-10 rounded-full bg-hazel-green/20 flex items-center justify-center">
                      <Plus className="h-5 w-5 text-hazel-green" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium text-gray-900">Selected {formData.entityType}</p>
                    <p className="text-sm text-bluish-grey">
                      {formData.entityName}
                      {formData.entityCategory && ` - ${formData.entityCategory}`}
                    </p>
                  </div>
                </motion.div>
              )}
            </motion.div>

          </div>
        </form>

        {/* Fixed Footer */}
        <div className="px-8 py-6 bg-white border-t border-grey-goose/30">
          <div className="flex justify-end gap-4">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="button"
              onClick={onClose}
              className="px-6 py-3 text-bluish-grey hover:bg-seashell rounded-xl transition-colors font-medium"
            >
              Anulo
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="submit"
              form="articleForm"
              disabled={loading}
              className={`px-6 py-3 bg-hazel-green text-white rounded-xl hover:bg-opacity-90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 font-medium shadow-lg shadow-hazel-green/20 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {loading ? (
                <>
                  <RotateCw className="w-5 h-5 animate-spin" />
                  Duke ruajtur...
                </>
              ) : (
                <>
                  <Save className="w-5 h-5" />
                  Ruaj Artikullin
                </>
              )}
            </motion.button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ArticleFormModal;
