import React, { useState, useEffect } from 'react';
import { Plus, Settings, RefreshCw, Filter, Search, ArrowLeft, Eye, Edit, Trash2, MoreVertical } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { getPurchases, deletePurchase } from '../../../api/purchase';
import { getSuppliers } from '../../../api/accounting';
import { getCompany } from '../../../api/company';
import PurchaseDetailsModal from '../../../components/purchases/PurchaseDetailsModal.tsx';
import DeleteConfirmationModal from '../../../components/articles/DeleteConfirmationModal.tsx';

interface TabButtonProps {
  isActive: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
}

interface Purchase {
  id: string;
  documentNumber: string;
  type: 'PURCHASE' | 'EXPENSE' | 'ASSET';
  status: 'DRAFT' | 'COMPLETED' | 'RETURNED' | 'CANCELLED';
  issueDate: string;
  dueDate: string;
  description?: string;
  supplier: {
    id: string;
    name: string;
  };
  location?: {
    id: string;
    name: string;
  };
  totalWithoutVAT: number;
  totalVAT: number;
  totalWithVAT: number;
  assetCode?: string;
  assetCategory?: string;
  expenseCategory?: string;
  assetLifespan?: number;
  depreciationRate?: number;
}

interface Supplier {
  id: string;
  name: string;
}

interface Location {
  id: string;
  name: string;
}

const TabButton: React.FC<TabButtonProps> = ({ isActive, onClick, icon, label }) => (
  <button
    className={`px-4 py-2 text-sm font-medium ${
      isActive
        ? 'text-hazel-green border-b-2 border-hazel-green'
        : 'text-bluish-grey hover:text-hazel-green'
    }`}
    onClick={onClick}
  >
    <div className="flex items-center gap-2">
      {icon}
      {label}
    </div>
  </button>
);

const PurchasesIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 4h16v16H4V4z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ExpensesIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const AssetsIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 11V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const PurchasesPage: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('purchases');
  const [showFilters, setShowFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [purchases, setPurchases] = useState<Purchase[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [filters, setFilters] = useState({
    search: '',
    status: '',
    supplierId: '',
    locationId: '',
    startDate: '',
    endDate: '',
    assetCategory: '',
    expenseCategory: ''
  });
  const [selectedPurchase, setSelectedPurchase] = useState<Purchase | null>(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openActionMenu, setOpenActionMenu] = useState<string | null>(null);

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    fetchPurchases();
  }, [activeTab, filters]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openActionMenu && !(event.target as Element).closest('.action-menu-container')) {
        setOpenActionMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openActionMenu]);

  const fetchInitialData = async () => {
    try {
      const [suppliersRes, companyRes] = await Promise.all([
        getSuppliers(),
        getCompany()
      ]);

      setSuppliers(suppliersRes.data || []);
      setLocations(companyRes?.data?.message?.locations || []);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      toast.error('Failed to load suppliers and locations');
    }
  };

  const fetchPurchases = async () => {
    try {
      setIsLoading(true);
      const filters = {
        type: activeTab === 'purchases' ? 'PURCHASE' : activeTab === 'expenses' ? 'EXPENSE' : 'ASSET'
      };
      
      console.log('Sending request with filters:', filters);
      const response = await getPurchases(filters);
      console.log('Full response:', response);
      
      // Log the response data structure
      console.log('Response data structure:', {
        hasData: !!response?.data,
        dataType: typeof response?.data,
        data: response?.data
      });

      // Get purchases from response
      const purchases = response?.data?.data;
      console.log('Extracted purchases:', purchases);

      if (!purchases || !Array.isArray(purchases)) {
        console.error('Invalid purchases data:', purchases);
        setPurchases([]);
        return;
      }

      console.log('Setting purchases:', purchases);
      setPurchases(purchases);

    } catch (error) {
      console.error('Error fetching purchases:', error);
      toast.error('Failed to load purchases');
      setPurchases([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this purchase?')) return;

    try {
      await deletePurchase(id);
      toast.success('Purchase deleted successfully');
      fetchPurchases();
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting purchase:', error);
      toast.error('Failed to delete purchase');
    }
  };

  const handleFilterChange = (key: string, value: string) => {
    setFilters(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'DRAFT': return 'bg-yellow-50 text-yellow-600';
      case 'COMPLETED': return 'bg-green-50 text-green-600';
      case 'RETURNED': return 'bg-red-50 text-red-600';
      case 'CANCELLED': return 'bg-gray-50 text-gray-600';
      default: return 'bg-gray-50 text-gray-600';
    }
  };

  const tabs = [
    { id: 'purchases', label: 'Blerje', icon: <PurchasesIcon /> },
    { id: 'expenses', label: 'Shpenzimet', icon: <ExpensesIcon /> },
    { id: 'assets', label: 'Asetet', icon: <AssetsIcon /> },
  ];

  const handleEdit = (purchase: Purchase) => {
    if (purchase.status === 'COMPLETED') {
      toast.error('Cannot edit completed purchases');
      return;
    }
    navigate(`/accounting/purchases/edit/${purchase.id}`);
  };

  const handleView = (purchase: Purchase) => {
    setSelectedPurchase(purchase);
    setIsDetailsModalOpen(true);
  };

  const toggleActionMenu = (purchaseId: string) => {
    setOpenActionMenu(openActionMenu === purchaseId ? null : purchaseId);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'expenses':
        return (
          <>
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center gap-3">
                <button
                  onClick={() => setShowFilters(!showFilters)}
                  className="px-4 py-2.5 bg-white/80 text-bluish-grey rounded-lg hover:bg-white 
                           flex items-center gap-2 border border-mercury/40 shadow-sm hover:shadow-md
                           backdrop-blur-sm transition-all duration-300"
                >
                  <Filter className="h-4 w-4" />
                  <span className="text-sm font-medium">Filtrat</span>
                </button>
                <button 
                  className="p-2.5 rounded-lg text-grey-goose hover:text-hazel-green bg-white/80 hover:bg-white 
                           transition-all duration-300 border border-mercury/40 shadow-sm hover:shadow-md backdrop-blur-sm"
                >
                  <RefreshCw className="h-4 w-4" />
                </button>
              </div>
              <div className="flex items-center gap-4">
                <button
                  onClick={() => navigate('/accounting/expenses/add')}
                  className="inline-flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                           hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-lg hover:shadow-xl
                           backdrop-blur-sm hover:scale-105 font-medium"
                >
                  <Plus className="h-5 w-5" />
                  <span className="font-medium">Shto Shpenzim</span>
                </button>
                <button
                  onClick={() => navigate('/accounting/expenses/return')}
                  className="inline-flex items-center gap-x-2 px-6 py-3 bg-watermelon text-white rounded-lg 
                           hover:bg-error transition-all duration-300 shadow-lg hover:shadow-xl
                           backdrop-blur-sm hover:scale-105 font-medium"
                >
                  <ArrowLeft className="h-5 w-5" />
                  <span className="font-medium">Kthim nga Shpenzimi</span>
                </button>
              </div>
            </div>

            {/* Filters Section */}
            <AnimatePresence>
              {showFilters && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-mercury/40 overflow-hidden mb-6"
                >
                  <div className="p-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
                      {/* Filter fields similar to Purchases */}
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Totali
                        </label>
                        <input
                          type="number"
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all"
                          placeholder="0.00"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Statusi
                        </label>
                        <select
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all appearance-none cursor-pointer"
                        >
                          <option>Të gjitha</option>
                          <option>Aktive</option>
                          <option>Të mbyllura</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Furnitori
                        </label>
                        <select
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all appearance-none cursor-pointer"
                        >
                          <option>Zgjidhni furnitorin</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Njësia Organizative
                        </label>
                        <select
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all appearance-none cursor-pointer"
                        >
                          <option>Zgjidhni njësinë</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Data e lëshimit
                        </label>
                        <input
                          type="date"
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Table */}
            <div className="bg-white/90 backdrop-blur-sm rounded-xl border border-mercury/40 shadow-lg overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-mercury/40">
                  <thead>
                    <tr className="bg-gradient-to-r from-sage/5 to-transparent">
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Emri</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Tipi</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Numri i faturës</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Totali</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Statusi</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Furnitori</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Njësia Organizative</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Data e lëshimit</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Data e skadimit</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Krijuar më</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Krijuar nga</th>
                      <th className="py-3 px-4 text-right text-xs font-medium text-hazel-green uppercase tracking-wider">Veprimet</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-mercury/40 bg-white/50">
                    {isLoading ? (
                      <tr>
                        <td colSpan={12} className="px-4 py-8 text-center text-sm text-bluish-grey">
                          <div className="flex flex-col items-center justify-center gap-2">
                            <div className="w-16 h-16 bg-mercury/20 rounded-lg flex items-center justify-center animate-pulse">
                              <RefreshCw className="w-8 h-8 text-mercury animate-spin" />
                            </div>
                            <p>Loading...</p>
                          </div>
                        </td>
                      </tr>
                    ) : purchases.length === 0 ? (
                      <tr>
                        <td colSpan={12} className="px-4 py-8 text-center text-sm text-bluish-grey">
                          <div className="flex flex-col items-center justify-center gap-2">
                            <div className="w-16 h-16 bg-mercury/20 rounded-lg flex items-center justify-center">
                              <Search className="w-8 h-8 text-mercury" />
                            </div>
                            <p>No data found</p>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      purchases.map((purchase) => (
                        <tr key={purchase.id} className="hover:bg-sage/5 transition-all duration-300">
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.description || '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.type}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.documentNumber}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm font-medium text-bluish-grey">${purchase.totalWithVAT.toFixed(2)}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(purchase.status)}`}>
                              {purchase.status}
                            </span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.supplier?.name || '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.location?.name || '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.issueDate}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.dueDate}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">-</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">-</span>
                          </td>
                          <td className="py-4 px-4">
                            <div className="flex items-center justify-end gap-2">
                              <button 
                                onClick={() => handleDelete(purchase.id)}
                                className="p-1.5 rounded-lg hover:bg-seashell/70 text-grey-goose hover:text-hazel-green transition-all"
                              >
                                <Settings className="h-4 w-4" />
                              </button>
                              <button
                                onClick={() => toggleActionMenu(purchase.id)}
                                className="p-1.5 rounded-lg hover:bg-seashell/70 text-grey-goose hover:text-hazel-green transition-all"
                              >
                                <MoreVertical className="h-4 w-4" />
                              </button>
                              {openActionMenu === purchase.id && (
                                <div 
                                  className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-lg border border-grey-goose/30 z-10 py-1 action-menu-container"
                                  style={{ minWidth: '160px' }}
                                >
                                  <button
                                    onClick={() => {
                                      setSelectedPurchase(purchase);
                                      setIsDetailsModalOpen(true);
                                      setOpenActionMenu(null);
                                    }}
                                    className="w-full px-4 py-2 text-left text-sm text-hazel-green hover:bg-seashell flex items-center"
                                  >
                                    <Eye className="w-4 h-4 mr-2" />
                                    View Details
                                  </button>
                                  
                                  <button
                                    onClick={() => {
                                      if (purchase.status !== 'COMPLETED') {
                                        navigate(`/accounting/purchases/edit/${purchase.id}`);
                                      }
                                      setOpenActionMenu(null);
                                    }}
                                    disabled={purchase.status === 'COMPLETED'}
                                    className={`w-full px-4 py-2 text-left text-sm flex items-center ${
                                      purchase.status === 'COMPLETED'
                                        ? 'text-grey-goose cursor-not-allowed'
                                        : 'text-hazel-green hover:bg-seashell'
                                    }`}
                                  >
                                    <Edit className="w-4 h-4 mr-2" />
                                    Edit
                                  </button>
                                  
                                  <button
                                    onClick={() => {
                                      if (purchase.status !== 'COMPLETED') {
                                        setSelectedPurchase(purchase);
                                        setIsDeleteModalOpen(true);
                                      }
                                      setOpenActionMenu(null);
                                    }}
                                    disabled={purchase.status === 'COMPLETED'}
                                    className={`w-full px-4 py-2 text-left text-sm flex items-center ${
                                      purchase.status === 'COMPLETED'
                                        ? 'text-grey-goose cursor-not-allowed'
                                        : 'text-red-500 hover:bg-red-50'
                                    }`}
                                  >
                                    <Trash2 className="w-4 h-4 mr-2" />
                                    Delete
                                  </button>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        );
      case 'assets':
        return (
          <>
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center gap-3">
                <button
                  onClick={() => setShowFilters(!showFilters)}
                  className="px-4 py-2.5 bg-white/80 text-bluish-grey rounded-lg hover:bg-white 
                           flex items-center gap-2 border border-mercury/40 shadow-sm hover:shadow-md
                           backdrop-blur-sm transition-all duration-300"
                >
                  <Filter className="h-4 w-4" />
                  <span className="text-sm font-medium">Filtrat</span>
                </button>
                <button 
                  className="p-2.5 rounded-lg text-grey-goose hover:text-hazel-green bg-white/80 hover:bg-white 
                           transition-all duration-300 border border-mercury/40 shadow-sm hover:shadow-md backdrop-blur-sm"
                >
                  <RefreshCw className="h-4 w-4" />
                </button>
              </div>
              <div className="flex items-center gap-4">
                <button
                  onClick={() => navigate('/accounting/assets/add')}
                  className="inline-flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                           hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-lg hover:shadow-xl
                           backdrop-blur-sm hover:scale-105 font-medium"
                >
                  <Plus className="h-5 w-5" />
                  <span className="font-medium">Shto Aset</span>
                </button>
                <button
                  onClick={() => navigate('/accounting/assets/return')}
                  className="inline-flex items-center gap-x-2 px-6 py-3 bg-watermelon text-white rounded-lg 
                           hover:bg-error transition-all duration-300 shadow-lg hover:shadow-xl
                           backdrop-blur-sm hover:scale-105 font-medium"
                >
                  <ArrowLeft className="h-5 w-5" />
                  <span className="font-medium">Kthim nga Aseti</span>
                </button>
              </div>
            </div>

            {/* Filters Section */}
            <AnimatePresence>
              {showFilters && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-mercury/40 overflow-hidden mb-6"
                >
                  <div className="p-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Totali
                        </label>
                        <input
                          type="number"
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all"
                          placeholder="0.00"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Statusi
                        </label>
                        <select
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all appearance-none cursor-pointer"
                        >
                          <option>Të gjitha</option>
                          <option>Aktive</option>
                          <option>Të mbyllura</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Furnitori
                        </label>
                        <select
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all appearance-none cursor-pointer"
                        >
                          <option>Zgjidhni furnitorin</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Njësia Organizative
                        </label>
                        <select
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all appearance-none cursor-pointer"
                        >
                          <option>Zgjidhni njësinë</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Data e lëshimit
                        </label>
                        <input
                          type="date"
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Table */}
            <div className="bg-white/90 backdrop-blur-sm rounded-xl border border-mercury/40 shadow-lg overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-mercury/40">
                  <thead>
                    <tr className="bg-gradient-to-r from-sage/5 to-transparent">
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Emri</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Kodi i Asetit</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Kategoria</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Numri i faturës</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Totali</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Statusi</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Furnitori</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Njësia Organizative</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Data e lëshimit</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Data e skadimit</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Jetëgjatësia</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Norma e Amortizimit</th>
                      <th className="py-3 px-4"></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-mercury/40">
                    {isLoading ? (
                      <tr>
                        <td colSpan={12} className="px-4 py-8 text-center text-sm text-bluish-grey">
                          <div className="flex flex-col items-center justify-center gap-2">
                            <div className="w-16 h-16 bg-mercury/20 rounded-lg flex items-center justify-center animate-pulse">
                              <RefreshCw className="w-8 h-8 text-mercury animate-spin" />
                            </div>
                            <p>Loading...</p>
                          </div>
                        </td>
                      </tr>
                    ) : purchases.length === 0 ? (
                      <tr>
                        <td colSpan={12} className="px-4 py-8 text-center text-sm text-bluish-grey">
                          <div className="flex flex-col items-center justify-center gap-2">
                            <div className="w-16 h-16 bg-mercury/20 rounded-lg flex items-center justify-center">
                              <Search className="w-8 h-8 text-mercury" />
                            </div>
                            <p>No data found</p>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      purchases.map((purchase) => (
                        <tr key={purchase.id} className="hover:bg-sage/5 transition-all duration-300">
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.description || '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.assetCode || '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.assetCategory || '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.documentNumber}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm font-medium text-bluish-grey">${purchase.totalWithVAT.toFixed(2)}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(purchase.status)}`}>
                              {purchase.status}
                            </span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.supplier?.name || '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.location?.name || '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.issueDate}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.dueDate}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.assetLifespan ? `${purchase.assetLifespan} months` : '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.depreciationRate ? `${purchase.depreciationRate}%` : '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <div className="flex items-center justify-end gap-2">
                              <button 
                                onClick={() => handleDelete(purchase.id)}
                                className="p-1.5 rounded-lg hover:bg-seashell/70 text-grey-goose hover:text-hazel-green transition-all"
                              >
                                <Settings className="h-4 w-4" />
                              </button>
                              <button
                                onClick={() => toggleActionMenu(purchase.id)}
                                className="p-1.5 rounded-lg hover:bg-seashell/70 text-grey-goose hover:text-hazel-green transition-all"
                              >
                                <MoreVertical className="h-4 w-4" />
                              </button>
                              {openActionMenu === purchase.id && (
                                <div 
                                  className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-lg border border-grey-goose/30 z-10 py-1 action-menu-container"
                                  style={{ minWidth: '160px' }}
                                >
                                  <button
                                    onClick={() => {
                                      setSelectedPurchase(purchase);
                                      setIsDetailsModalOpen(true);
                                      setOpenActionMenu(null);
                                    }}
                                    className="w-full px-4 py-2 text-left text-sm text-hazel-green hover:bg-seashell flex items-center"
                                  >
                                    <Eye className="w-4 h-4 mr-2" />
                                    View Details
                                  </button>
                                  
                                  <button
                                    onClick={() => {
                                      if (purchase.status !== 'COMPLETED') {
                                        navigate(`/accounting/purchases/edit/${purchase.id}`);
                                      }
                                      setOpenActionMenu(null);
                                    }}
                                    disabled={purchase.status === 'COMPLETED'}
                                    className={`w-full px-4 py-2 text-left text-sm flex items-center ${
                                      purchase.status === 'COMPLETED'
                                        ? 'text-grey-goose cursor-not-allowed'
                                        : 'text-hazel-green hover:bg-seashell'
                                    }`}
                                  >
                                    <Edit className="w-4 h-4 mr-2" />
                                    Edit
                                  </button>
                                  
                                  <button
                                    onClick={() => {
                                      if (purchase.status !== 'COMPLETED') {
                                        setSelectedPurchase(purchase);
                                        setIsDeleteModalOpen(true);
                                      }
                                      setOpenActionMenu(null);
                                    }}
                                    disabled={purchase.status === 'COMPLETED'}
                                    className={`w-full px-4 py-2 text-left text-sm flex items-center ${
                                      purchase.status === 'COMPLETED'
                                        ? 'text-grey-goose cursor-not-allowed'
                                        : 'text-red-500 hover:bg-red-50'
                                    }`}
                                  >
                                    <Trash2 className="w-4 h-4 mr-2" />
                                    Delete
                                  </button>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            {/* Original Purchases Content */}
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center gap-3">
                <button
                  onClick={() => setShowFilters(!showFilters)}
                  className="px-4 py-2.5 bg-white/80 text-bluish-grey rounded-lg hover:bg-white 
                           flex items-center gap-2 border border-mercury/40 shadow-sm hover:shadow-md
                           backdrop-blur-sm transition-all duration-300"
                >
                  <Filter className="h-4 w-4" />
                  <span className="text-sm font-medium">Filtrat</span>
                </button>
                <button 
                  className="p-2.5 rounded-lg text-grey-goose hover:text-hazel-green bg-white/80 hover:bg-white 
                           transition-all duration-300 border border-mercury/40 shadow-sm hover:shadow-md backdrop-blur-sm"
                >
                  <RefreshCw className="h-4 w-4" />
                </button>
              </div>
              <div className="flex items-center gap-4">
                <button
                  onClick={() => navigate('/accounting/purchases/add')}
                  className="inline-flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                           hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-lg hover:shadow-xl
                           backdrop-blur-sm hover:scale-105 font-medium relative overflow-hidden
                           before:absolute before:inset-0 before:bg-white/20 before:translate-x-[-100%] hover:before:translate-x-[100%] before:transition-transform before:duration-500"
                >
                  <Plus className="h-5 w-5" />
                  <span className="font-medium">Shto Blerje</span>
                </button>
                <button
                  onClick={() => navigate('/accounting/purchases/return')}
                  className="inline-flex items-center gap-x-2 px-6 py-3 bg-watermelon text-white rounded-lg 
                           hover:bg-error transition-all duration-300 shadow-lg hover:shadow-xl
                           backdrop-blur-sm hover:scale-105 font-medium"
                >
                  <ArrowLeft className="h-5 w-5" />
                  <span className="font-medium">Kthim nga Blerje</span>
                </button>
              </div>
            </div>

            {/* Filters */}
            <AnimatePresence>
              {showFilters && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-mercury/40 overflow-hidden mb-6"
                >
                  <div className="p-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Totali
                        </label>
                        <input
                          type="number"
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all"
                          placeholder="0.00"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Statusi
                        </label>
                        <select
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all appearance-none cursor-pointer"
                        >
                          <option>Të gjitha</option>
                          <option>Aktive</option>
                          <option>Të mbyllura</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Furnitori
                        </label>
                        <select
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all appearance-none cursor-pointer"
                        >
                          <option>Zgjidhni furnitorin</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Njësia Organizative
                        </label>
                        <select
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all appearance-none cursor-pointer"
                        >
                          <option>Zgjidhni njësinë</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                          Data e lëshimit
                        </label>
                        <input
                          type="date"
                          className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Table */}
            <div className="bg-white/90 backdrop-blur-sm rounded-xl border border-mercury/40 shadow-lg overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-mercury/40">
                  <thead>
                    <tr className="bg-gradient-to-r from-sage/5 to-transparent">
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Emri</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Tipi</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Numri i faturës</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Totali</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Statusi</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Furnitori</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Njësia Organizative</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Data e lëshimit</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Data e skadimit</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Krijuar më</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Krijuar nga</th>
                      <th className="py-3 px-4 text-right text-xs font-medium text-hazel-green uppercase tracking-wider">Veprimet</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-mercury/40 bg-white/50">
                    {isLoading ? (
                      <tr>
                        <td colSpan={12} className="px-4 py-8 text-center text-sm text-bluish-grey">
                          <div className="flex flex-col items-center justify-center gap-2">
                            <div className="w-16 h-16 bg-mercury/20 rounded-lg flex items-center justify-center animate-pulse">
                              <RefreshCw className="w-8 h-8 text-mercury animate-spin" />
                            </div>
                            <p>Loading...</p>
                          </div>
                        </td>
                      </tr>
                    ) : purchases.length === 0 ? (
                      <tr>
                        <td colSpan={12} className="px-4 py-8 text-center text-sm text-bluish-grey">
                          <div className="flex flex-col items-center justify-center gap-2">
                            <div className="w-16 h-16 bg-mercury/20 rounded-lg flex items-center justify-center">
                              <Search className="w-8 h-8 text-mercury" />
                            </div>
                            <p>No data found</p>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      purchases.map((purchase) => (
                        <tr key={purchase.id} className="hover:bg-sage/5 transition-all duration-300">
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.description || '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.type}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.documentNumber}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm font-medium text-bluish-grey">${purchase.totalWithVAT.toFixed(2)}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(purchase.status)}`}>
                              {purchase.status}
                            </span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.supplier?.name || '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.location?.name || '-'}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.issueDate}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">{purchase.dueDate}</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">-</span>
                          </td>
                          <td className="py-4 px-4">
                            <span className="text-sm text-grey-goose">-</span>
                          </td>
                          <td className="py-4 px-4">
                            <div className="flex items-center justify-end gap-2">
                              <button 
                                onClick={() => handleDelete(purchase.id)}
                                className="p-1.5 rounded-lg hover:bg-seashell/70 text-grey-goose hover:text-hazel-green transition-all"
                              >
                                <Settings className="h-4 w-4" />
                              </button>
                              <button
                                onClick={() => toggleActionMenu(purchase.id)}
                                className="p-1.5 rounded-lg hover:bg-seashell/70 text-grey-goose hover:text-hazel-green transition-all"
                              >
                                <MoreVertical className="h-4 w-4" />
                              </button>
                              {openActionMenu === purchase.id && (
                                <div 
                                  className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-lg border border-grey-goose/30 z-10 py-1 action-menu-container"
                                  style={{ minWidth: '160px' }}
                                >
                                  <button
                                    onClick={() => {
                                      setSelectedPurchase(purchase);
                                      setIsDetailsModalOpen(true);
                                      setOpenActionMenu(null);
                                    }}
                                    className="w-full px-4 py-2 text-left text-sm text-hazel-green hover:bg-seashell flex items-center"
                                  >
                                    <Eye className="w-4 h-4 mr-2" />
                                    View Details
                                  </button>
                                  
                                  <button
                                    onClick={() => {
                                      if (purchase.status !== 'COMPLETED') {
                                        navigate(`/accounting/purchases/edit/${purchase.id}`);
                                      }
                                      setOpenActionMenu(null);
                                    }}
                                    disabled={purchase.status === 'COMPLETED'}
                                    className={`w-full px-4 py-2 text-left text-sm flex items-center ${
                                      purchase.status === 'COMPLETED'
                                        ? 'text-grey-goose cursor-not-allowed'
                                        : 'text-hazel-green hover:bg-seashell'
                                    }`}
                                  >
                                    <Edit className="w-4 h-4 mr-2" />
                                    Edit
                                  </button>
                                  
                                  <button
                                    onClick={() => {
                                      if (purchase.status !== 'COMPLETED') {
                                        setSelectedPurchase(purchase);
                                        setIsDeleteModalOpen(true);
                                      }
                                      setOpenActionMenu(null);
                                    }}
                                    disabled={purchase.status === 'COMPLETED'}
                                    className={`w-full px-4 py-2 text-left text-sm flex items-center ${
                                      purchase.status === 'COMPLETED'
                                        ? 'text-grey-goose cursor-not-allowed'
                                        : 'text-red-500 hover:bg-red-50'
                                    }`}
                                  >
                                    <Trash2 className="w-4 h-4 mr-2" />
                                    Delete
                                  </button>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue to-seashell p-8">
      {/* Breadcrumb */}
      <div className="mb-6">
        <div className="flex items-center gap-2 text-sm">
          <span className="text-bluish-grey">Kontabiliteti</span>
          <span className="text-grey-goose">/</span>
          <span className="text-hazel-green">
            {activeTab === 'expenses' ? 'Shpenzimet' : activeTab === 'assets' ? 'Asetet' : 'Blerjet'}
          </span>
        </div>
      </div>

      {/* Header */}
      <div className="mb-8">
        <h1 className="text-2xl font-semibold text-bluish-grey mb-2">
          {activeTab === 'expenses' ? 'Shpenzimet' : activeTab === 'assets' ? 'Asetet' : 'Blerjet'}
        </h1>
        <p className="text-grey-goose">
          {activeTab === 'expenses' ? 'Menaxhoni shpenzimet tuaja' : 
           activeTab === 'assets' ? 'Menaxhoni asetet tuaja' : 
           'Menaxhoni blerjet tuaja'}
        </p>
      </div>

      {/* Tabs */}
      <div className="flex space-x-4 mb-6 border-b border-mercury">
        {tabs.map((tab) => (
          <TabButton
            key={tab.id}
            isActive={activeTab === tab.id}
            onClick={() => setActiveTab(tab.id)}
            icon={tab.icon}
            label={tab.label}
          />
        ))}
      </div>

      {/* Content */}
      {renderContent()}

      {/* Details Modal */}
      <PurchaseDetailsModal
        isOpen={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
        purchase={selectedPurchase}
      />

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() => selectedPurchase && handleDelete(selectedPurchase.id)}
        title="Delete Purchase"
        message="Are you sure you want to delete this purchase? This action cannot be undone."
      />
    </div>
  );
};

export default PurchasesPage;
