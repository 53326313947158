import React, { useState, useEffect } from 'react';
import { getItemsWithZeroDifference, getItemsWithZeroQuantity, getStockItemsBelowReorderLevel } from '../../../api/stockState';
import StockStateTable from './StockStateTable';
import ThresholdInput from './ThreshholdInput';
import { Package, AlertTriangle, XCircle } from 'lucide-react';

const StockStateDashboard = () => {
  const [zeroDifferenceItems, setZeroDifferenceItems] = useState([]);
  const [zeroQuantityItems, setZeroQuantityItems] = useState([]);
  const [lowStockItems, setLowStockItems] = useState([]);
  const [threshold, setThreshold] = useState(10);

  useEffect(() => {
    fetchStockData();
  }, [threshold]);

  const fetchStockData = async () => {
    try {
      const [zeroDifferenceResponse, zeroQuantityResponse, lowStockResponse] = await Promise.all([
        getItemsWithZeroDifference(),
        getItemsWithZeroQuantity(),
        getStockItemsBelowReorderLevel(threshold)
      ]);

      setZeroDifferenceItems(zeroDifferenceResponse.data);
      setZeroQuantityItems(zeroQuantityResponse.data);
      setLowStockItems(lowStockResponse.data);
    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
  };

  return (
    <div className="container bg-seashell mx-auto px-8 py-10">
      <h1 className="text-4xl font-bold text-hazel-green mb-8">Stock State Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <StatsCard
          title="Low Stock Items"
          value={lowStockItems.length}
          icon={<AlertTriangle className="w-8 h-8 text-yellow-500" />}
          color="bg-yellow-100"
        />
        <StatsCard
          title="Zero Difference Items"
          value={zeroDifferenceItems.length}
          icon={<Package className="w-8 h-8 text-blue-500" />}
          color="bg-blue-100"
        />
        <StatsCard
          title="Zero Stock Items"
          value={zeroQuantityItems.length}
          icon={<XCircle className="w-8 h-8 text-red-500" />}
          color="bg-red-100"
        />
      </div>

      <ThresholdInput threshold={threshold} setThreshold={setThreshold} />

      <div className="space-y-8">
        <StockSection
          title="Low Stock Items"
          items={lowStockItems}
          color="text-yellow-700"
          bgColor="bg-yellow-50"
        />
        <StockSection
          title="Items with Stock Matching Reorder Level"
          items={zeroDifferenceItems}
          color="text-blue-700"
          bgColor="bg-blue-50"
        />
        <StockSection
          title="Items with Zero Stock"
          items={zeroQuantityItems}
          color="text-red-700"
          bgColor="bg-red-50"
        />
      </div>
    </div>
  );
};

const StatsCard = ({ title, value, icon, color }) => (
  <div className={`${color} rounded-lg shadow-md p-6`}>
    <div className="flex items-center justify-between">
      <div>
        <p className="text-gray-600 text-sm font-medium">{title}</p>
        <p className="text-3xl font-bold text-gray-800 mt-2">{value}</p>
      </div>
      {icon}
    </div>
  </div>
);

const StockSection = ({ title, items, color, bgColor }) => (
  <section className={`${bgColor} rounded-lg shadow-md p-6`}>
    <h2 className={`text-2xl font-semibold ${color} mb-4`}>{title}</h2>
    <StockStateTable items={items} />
  </section>
);

export default StockStateDashboard;