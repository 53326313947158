import React from 'react';
import { X, Pencil, Settings } from 'lucide-react';

export default function ItemDetails({ onClose }: { onClose: () => void }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-4xl">
        <div className="flex justify-between items-center p-4 border-b border-mercury">
          <div className="flex items-center gap-4">
            <h2 className="text-xl font-medium text-bluish-grey">item1</h2>
            <button className="text-blue-500 hover:text-blue-600">
              <Pencil className="h-4 w-4" />
            </button>
          </div>
          <div className="flex items-center gap-4">
            <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              Adjust Stock
            </button>
            <button className="text-bluish-grey hover:text-gray-700">More</button>
            <button onClick={onClose} className="text-silver hover:text-bluish-grey">
              <X size={20} />
            </button>
          </div>
        </div>

        <div className="p-6">
          <div className="flex gap-8">
            <div className="flex-1 space-y-6">
              <div className="space-y-4">
                <h3 className="font-medium text-bluish-grey">Overview</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-silver">Item Type</p>
                    <p className="text-bluish-grey">Inventory Items</p>
                  </div>
                  <div>
                    <p className="text-silver">Unit</p>
                    <p className="text-bluish-grey">kg</p>
                  </div>
                  <div>
                    <p className="text-silver">Created Source</p>
                    <p className="text-bluish-grey">User</p>
                  </div>
                  <div>
                    <p className="text-silver">Inventory Account</p>
                    <p className="text-bluish-grey">Inventory Asset</p>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="font-medium text-bluish-grey">Purchase Information</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-silver">Cost Price</p>
                    <p className="text-bluish-grey">€7,00</p>
                  </div>
                  <div>
                    <p className="text-silver">Purchase Account</p>
                    <p className="text-bluish-grey">Cost of Goods Sold</p>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="font-medium text-bluish-grey">Sales Information</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-silver">Selling Price</p>
                    <p className="text-bluish-grey">€7,00</p>
                  </div>
                  <div>
                    <p className="text-silver">Sales Account</p>
                    <p className="text-bluish-grey">General Income</p>
                  </div>
                  <div>
                    <p className="text-silver">Description</p>
                    <p className="text-bluish-grey">nothing</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-72 space-y-6">
              <div className="space-y-4">
                <div>
                  <p className="text-silver">Opening Stock</p>
                  <p className="text-2xl font-medium">0,00</p>
                </div>
                <div>
                  <p className="text-silver">Stock on Hand</p>
                  <p className="text-2xl font-medium">0,00</p>
                </div>
              </div>

              <div className="flex justify-between">
                <div>
                  <p className="text-silver">To be Invoiced</p>
                  <p className="text-xl font-medium">0 <span className="text-silver text-sm">Qty</span></p>
                </div>
                <div>
                  <p className="text-silver">To be Billed</p>
                  <p className="text-xl font-medium">0 <span className="text-silver text-sm">Qty</span></p>
                </div>
              </div>

              <div className="space-y-2">
                <p className="text-silver">Reorder Point</p>
                <p className="text-sm text-bluish-grey">
                  You have to enable reorder notification before setting reorder point for items.
                  <a href="#" className="text-blue-500 hover:text-blue-600 ml-1">Click here</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}