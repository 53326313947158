import api  from './api';
import axios from 'axios'; // Add this line to import axios

// Articles
export const createArticle = (data) => api.post('/api/accounting-article', data);
export const getArticles = async () => {
  try {
    const response = await api.get('/api/accounting-article');
    return response;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
};
export const getArticleById = (id) => api.get(`/api/accounting-article/${id}`);
export const updateArticle = (id, data) => api.patch(`/api/accounting-article/${id}`, data);
export const deleteArticle = (id) => api.delete(`/api/accounting-article/${id}`);

// Articles with Links
export const createArticleWithLink = async (data) => {
  try {
    const response = await api.post('/api/accounting-article/with-link', {
      article: data.article,
      link: data.link ? {
        entityType: data.link.entityType,
        entityId: data.link.entityId,
        entityCode: data.link.entityCode,
        entityName: data.link.entityName,
        entityCategory: data.link.entityCategory,
        entityReference: data.link.entityReference
      } : undefined
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const getArticleLinks = (id) => api.get(`/api/accounting-article/${id}/links`);
export const linkArticleToEntity = (id, data) => api.post(`/api/accounting-article/${id}/link`, data);
export const unlinkArticleFromEntity = (id, data) => api.delete(`/api/accounting-article/${id}/link`, { data });
export const getArticlesByEntityType = (type) => api.get(`/api/accounting-article/by-entity-type/${type}`);

// Articles by Type
export const getArticlesByType = async (type) => {
  try {
    const response = await api.get(`/api/accounting-article/by-type/${type}`);
    return response;
  } catch (error) {
    console.error('Error fetching articles by type:', error);
    throw error;
  }
};

// Entities
export const getEntitiesByType = (type, search) => {
  const searchParam = search ? `?search=${encodeURIComponent(search)}` : '';
  return api.get(`/api/accounting-article/entities/${type}${searchParam}`);
};

// VAT Rates
export const createVatRate = (data) => api.post('/api/accounting-vat-rate', data);
export const getVatRates = () => api.get('/api/accounting-vat-rate');
export const getVatRateById = (id) => api.get(`/api/accounting-vat-rate/${id}`);
export const updateVatRate = (id, data) => api.put(`/api/accounting-vat-rate/${id}`, data);
export const deleteVatRate = (id) => api.delete(`/api/accounting-vat-rate/${id}`);

// Units
export const createUnit = (data) => api.post('/api/accounting-unit', data);
export const getUnits = () => api.get('/api/accounting-unit');
export const getUnitById = (id) => api.get(`/api/accounting-unit/${id}`);
export const updateUnit = (id, data) => api.patch(`/api/accounting-unit/${id}`, data);
export const deleteUnit = (id) => api.delete(`/api/accounting-unit/${id}`);
export const getUnitByCode = (code) => api.get(`/api/accounting-unit/code/${code}`);
export const getUnitByDescription = (description) => api.get(`/api/accounting-unit/description/${description}`);
