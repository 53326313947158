import React, { useEffect, useState } from 'react';
import { ArrowLeft, Plus, Upload, Trash2, AlertCircle, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '../../../components/shadCN/Select.tsx';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { getCompany } from '../../../api/company';
import { getSuppliers } from '../../../api/accounting';
import { getArticles } from '../../../api/accountingArticle';
import { getNextJournalDocumentNumber } from '../../../api/journal';
import { getAllAccounts } from '../../../api/accountingPlan';
import { createJournalEntry } from '../../../api/journal';
import { cn } from '../../../lib/utils.ts';

interface DebitCredit {
  id: string;
  analyticalAccount: string;
  accountCode: string;
  accountName: string;
  debit: number;
  credit: number;
  entity: string;
  description?: string;
  address?: string;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Account {
  id: string;
  code: string;
  name: string;
}

const AddJournalEntryPage = () => {
  const navigate = useNavigate();
  const [entries, setEntries] = useState<DebitCredit[]>([]);
  const [description, setDescription] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState<Location[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [difference, setDifference] = useState(0);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        const [companyRes, docNumber, accountsRes] = await Promise.all([
          getCompany(),
          getNextJournalDocumentNumber(),
          getAllAccounts()
        ]);
        
        // Handle document number
        setDocumentNumber(docNumber);

        // Handle locations
        const companyLocations = companyRes?.data?.message?.locations || [];
        setLocations(Array.isArray(companyLocations) ? companyLocations : []);

        // Handle accounts
        setAccounts(accountsRes.data.message || []);

      } catch (error: any) {
        console.error('Error fetching data:', error);
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch initial data';
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    // Calculate difference whenever debit or credit changes
    const diff = totalDebit - totalCredit;
    setDifference(diff);
  }, [totalDebit, totalCredit]);

  const handleAddRow = () => {
    setEntries([
      ...entries,
      {
        id: Math.random().toString(36).substr(2, 9),
        analyticalAccount: '',
        accountCode: '',
        accountName: '',
        debit: 0,
        credit: 0,
        entity: '',
        description: ''
      }
    ]);
  };

  const handleRemoveRow = (id: string) => {
    setEntries(entries.filter((entry) => entry.id !== id));
  };

  const handleAccountChange = (entryId: string, accountId: string) => {
    setEntries(entries.map(entry => {
      if (entry.id === entryId) {
        const selectedAccount = accounts.find(acc => acc.id === accountId);
        return { 
          ...entry, 
          analyticalAccount: accountId,
          accountCode: selectedAccount?.code || '',
          accountName: selectedAccount?.name || ''
        };
      }
      return entry;
    }));
  };

  const handleDebitCreditChange = (entryId: string, field: 'debit' | 'credit', value: number) => {
    const updatedEntries = entries.map(entry => {
      if (entry.id === entryId) {
        return {
          ...entry,
          [field]: value,
          [field === 'debit' ? 'credit' : 'debit']: 0
        };
      }
      return entry;
    });
    
    setEntries(updatedEntries);
    setTotalDebit(updatedEntries.reduce((sum, entry) => sum + (entry.debit || 0), 0));
    setTotalCredit(updatedEntries.reduce((sum, entry) => sum + (entry.credit || 0), 0));
  };

  const handleSubmit = async () => {
    try {
      // Validate entries
      if (entries.length === 0) {
        toast.error('Ju lutem shtoni të paktën një regjistrim');
        return;
      }

      if (totalDebit !== totalCredit) {
        toast.error('Totali i debit duhet të jetë i barabartë me totalin e kreditit');
        return;
      }

      if (!date) {
        toast.error('Ju lutem zgjidhni një datë');
        return;
      }

      // Prepare items
      const items = entries.map(entry => ({
        accountId: entry.analyticalAccount,
        description: entry.description,
        debitAmount: entry.debit || 0,
        creditAmount: entry.credit || 0
      }));

      // Prepare attachments if any
      const attachmentData = attachments.map(file => ({
        filename: file.name,
        url: 'temp-url' // TODO: Implement file upload
      }));

      const journalData = {
        documentNumber,
        postingDate: new Date(date),
        description,
        locationId: selectedLocation || undefined,
        totalDebit,
        totalCredit,
        items,
        attachments: attachmentData
      };

      setIsLoading(true);
      const response = await createJournalEntry(journalData);
      toast.success('Regjistrimi u krijua me sukses');
      navigate('/accounting/journal-entries');
    } catch (error: any) {
      console.error('Error creating journal entry:', error);
      toast.error(error.response?.data?.message || 'Dështoi krijimi i regjistrimit');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/accounting/journal-entries')}
                className="inline-flex items-center gap-x-2 text-bluish-grey hover:text-hazel-green transition-colors"
              >
                <ArrowLeft className="h-5 w-5" />
                Kthehu
              </button>
            </div>
            <h1 className="text-lg font-semibold text-hazel-green">Shto një Hyrje në Ditar</h1>
            <div className="flex items-center gap-x-4">
              <button
                onClick={handleSubmit}
                disabled={isLoading || totalDebit !== totalCredit}
                className={cn(
                  "px-4 py-2 rounded-lg font-medium inline-flex items-center gap-2",
                  "bg-hazel-green text-white hover:bg-hazel-green/90 transition-colors",
                  "disabled:opacity-50 disabled:cursor-not-allowed"
                )}
              >
                {isLoading ? (
                  <>
                    <span className="animate-spin">⏳</span>
                    <span>Duke ruajtur...</span>
                  </>
                ) : (
                  <>
                    <Check className="h-4 w-4" />
                    <span>Ruaj</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Document Info */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white/80 backdrop-blur-sm rounded-xl shadow-sm border border-mercury/40 p-6"
          >
            <h2 className="text-lg font-medium text-bluish-grey mb-4">Hyrjet në Ditar</h2>
            <p className="text-grey-goose text-sm mb-6">
              Këtu regjistrohen Hyrjet në Ditar, Hyrjet Manuale, Fletët Kontabël, Urdhëresat Financiare apo Hyrjet e Dyfishta (double entries).
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                  Emri i dokumentit
                </label>
                <input
                  type="text"
                  value={documentNumber}
                  disabled
                  className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey bg-mercury/20"
                />
                <p className="mt-1.5 text-xs text-grey-goose">
                  Emri i dokumentit është automatikisht i gjeneruar në bazë të formatit.
                </p>
              </div>
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                  Data e Hyrjes në Ditar
                </label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey
                           focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                           hover:border-hazel-green/50 transition-all"
                />
              </div>
              <div className="mb-4">
                  <label htmlFor="location" className="mb-2 block text-sm font-medium text-slate-600">
                    Lokacioni
                  </label>
                  <Select
                    value={selectedLocation}
                    onValueChange={setSelectedLocation}
                    disabled={isLoading}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                      disabled={isLoading}
                    >
                      <SelectValue 
                        placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni lokacionin"}
                        className="text-bluish-grey"
                      >
                        {selectedLocation && (
                          <span className="text-hazel-green">
                            {locations.find(l => l.id === selectedLocation)?.name}
                          </span>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                      sideOffset={5}
                      position="popper"
                      align="start"
                    >
                      {locations.map(location => (
                        <SelectItem
                          key={location.id}
                          value={location.id}
                          textValue={location.name}
                          className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm sm:text-base font-medium text-hazel-green">
                              {location.name}
                            </span>
                            {location.address && (
                              <span className="text-xs sm:text-sm text-bluish-grey">
                                {location.address}
                              </span>
                            )}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
            </div>
          </motion.div>

          {/* Header with Document Info and Difference Warning */}
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-2xl font-bold text-bluish-grey mb-2">
                Shto Regjistrim të Ri
              </h1>
              <p className="text-grey-goose">
                Dokumenti Nr: {documentNumber}
              </p>
            </div>
            
            {/* Debit/Credit Difference Warning */}
            <div className={cn(
              "px-4 py-2 rounded-lg font-medium flex items-center gap-2",
              difference === 0 
                ? "bg-green-50 text-green-700" 
                : "bg-red-50 text-red-700"
            )}>
              <div className="text-sm">
                <span className="mr-2">Diferenca:</span>
                <span className="font-bold">{Math.abs(difference).toFixed(2)}</span>
              </div>
              {difference === 0 ? (
                <Check className="h-4 w-4 text-green-500" />
              ) : (
                <AlertCircle className="h-4 w-4 text-red-500" />
              )}
            </div>
          </div>

          {/* Journal Entry Table */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="bg-white/80 backdrop-blur-sm rounded-xl shadow-sm border border-mercury/40 overflow-hidden"
          >
            <div className="p-6 border-b border-mercury/40">
              <h3 className="text-lg font-medium text-bluish-grey">Regjistrimet Debi / Kredi</h3>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-mercury">
                <thead className="bg-seashell/50">
                  <tr>
                    <th scope="col" className="py-3 px-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Llogaria
                    </th>
                    <th scope="col" className="py-3 px-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Debi
                    </th>
                    <th scope="col" className="py-3 px-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Kredi
                    </th>
                    <th scope="col" className="py-3 px-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Veprime
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-mercury">
                  {entries.map((entry) => (
                    <tr key={entry.id} className="hover:bg-sage/5 transition-all duration-300">
                      <td className="py-3 px-4">
                        <Select
                          value={entry.analyticalAccount}
                          onValueChange={(value) => handleAccountChange(entry.id, value)}
                        >
                          <SelectTrigger className="w-[300px] bg-white border-mercury hover:border-hazel-green/50 focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all">
                            <SelectValue placeholder="Zgjidhni llogarinë" className="text-bluish-grey">
                              {entry.accountCode && entry.accountName 
                                ? `${entry.accountCode} - ${entry.accountName}`
                                : "Zgjidhni llogarinë"
                              }
                            </SelectValue>
                          </SelectTrigger>
                          <SelectContent className="max-h-[300px] bg-white border border-mercury/50 shadow-lg">
                            {accounts.map((account) => (
                              <SelectItem 
                                key={account.id} 
                                value={account.id}
                                className="flex items-center gap-2 py-2.5 px-3 hover:bg-seashell focus:bg-seashell cursor-pointer text-bluish-grey hover:text-hazel-green transition-colors"
                              >
                                <div className="flex items-center gap-2">
                                  <span className="font-medium min-w-[80px] text-hazel-green">{account.code}</span>
                                  <span className="text-gray-400">-</span>
                                  <span className="text-bluish-grey">{account.name}</span>
                                </div>
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </td>
                      <td className="py-3 px-4">
                        <input
                          type="number"
                          value={entry.debit || ''}
                          onChange={(e) => handleDebitCreditChange(entry.id, 'debit', parseFloat(e.target.value) || 0)}
                          disabled={entry.credit > 0}
                          className={cn(
                            "w-full rounded-lg border border-mercury/50 py-2 px-3 text-sm text-bluish-grey",
                            "focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green",
                            "hover:border-hazel-green/50 transition-all",
                            entry.credit > 0 && "bg-gray-100 cursor-not-allowed"
                          )}
                          placeholder="0.00"
                        />
                      </td>
                      <td className="py-3 px-4">
                        <input
                          type="number"
                          value={entry.credit || ''}
                          onChange={(e) => handleDebitCreditChange(entry.id, 'credit', parseFloat(e.target.value) || 0)}
                          disabled={entry.debit > 0}
                          className={cn(
                            "w-full rounded-lg border border-mercury/50 py-2 px-3 text-sm text-bluish-grey",
                            "focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green",
                            "hover:border-hazel-green/50 transition-all",
                            entry.debit > 0 && "bg-gray-100 cursor-not-allowed"
                          )}
                          placeholder="0.00"
                        />
                      </td>
                      <td className="py-3 px-4">
                        <button
                          onClick={() => handleRemoveRow(entry.id)}
                          className="text-red-500 hover:text-red-700 transition-colors"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="bg-seashell/30">
                  <tr>
                    <td className="py-3 px-4 font-medium text-bluish-grey">Totali</td>
                    <td className="py-3 px-4 font-medium text-bluish-grey">{totalDebit.toFixed(2)}</td>
                    <td className="py-3 px-4 font-medium text-bluish-grey">{totalCredit.toFixed(2)}</td>
                    <td className="py-3 px-4"></td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="p-6">
              <button
                onClick={handleAddRow}
                className="inline-flex items-center gap-2 px-4 py-2 bg-white text-hazel-green rounded-lg
                         hover:bg-hazel-green/5 transition-all duration-300 border border-hazel-green/20"
              >
                <Plus className="h-4 w-4" />
                <span>Shto një rresht të ri</span>
              </button>
            </div>
          </motion.div>

          {/* Description */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-white/80 backdrop-blur-sm rounded-xl shadow-sm border border-mercury/40 p-6"
          >
            <h3 className="text-lg font-medium text-bluish-grey mb-4">Përshkrimi</h3>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={4}
              className="w-full rounded-lg border border-mercury/50 py-3 px-4 text-sm text-bluish-grey
                       focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                       hover:border-hazel-green/50 transition-all resize-none"
              placeholder="Shkruaj ndonjë koment me rëndësi (nëse aplikohet për këtë Hyrje në Ditar)."
            />
          </motion.div>

          {/* Attachments */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="bg-white/80 backdrop-blur-sm rounded-xl shadow-sm border border-mercury/40 p-6"
          >
            <h3 className="text-lg font-medium text-bluish-grey mb-4">Bashkëngjit dokument (attachment)</h3>
            <div className="border-2 border-dashed border-mercury/60 rounded-xl p-8 text-center">
              <div className="mx-auto w-12 h-12 mb-4 flex items-center justify-center rounded-full bg-sage/10">
                <Upload className="h-6 w-6 text-sage" />
              </div>
              <h4 className="text-base font-medium text-bluish-grey mb-2">
                Mund vendosni dokument këtu (drag and drop)
              </h4>
              <p className="text-sm text-grey-goose mb-4">
                Mund të ngarkoni dokument të vetëm, apo edhe grup dokumentesh.
              </p>
              <button className="inline-flex items-center gap-2 px-4 py-2 bg-white text-hazel-green rounded-lg
                               hover:bg-hazel-green/5 transition-all duration-300 border border-hazel-green/20">
                <Upload className="h-4 w-4" />
                <span>Shto një dokument (attachment)</span>
              </button>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AddJournalEntryPage;
