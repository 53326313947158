import React, { useState } from 'react';
import { 
  BookOpen, ListOrdered, FileText, FileQuestion, BookMarked,
  Play, ChevronRight, Clock, Star, Search, Filter, Grid3x3,
  List, BookOpenCheck, Bookmark, Share2, Download, Sparkles,
  TrendingUp, Award, BookmarkCheck, CheckCircle2, ChevronDown, X, FolderOpen, Signal
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../../components/shadCN/Button.tsx';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../components/shadCN/Tabs.tsx';
import { BookmarkPlus } from 'lucide-react';
import TutorialViewer from './TutorialViewer';


const TutorialPage = () => {

    const dummyTutorials = [
        {
          id: 1,
          title: "Getting Started with Hotel Management",
          description: "Learn the fundamentals of the hotel management system and get started with basic operations.",
          duration: "45 mins",
          difficulty: "Beginner",
          category: "Basics",
          thumbnail: "/images/tutorials/getting-started.jpg",
          progress: 30,
          completedSections: 2,
          totalSections: 8,
          isBookmarked: false,
          chapters: [
            {
              id: 'ch1',
              title: "System Overview",
              duration: "15 mins",
              sections: [
                {
                  id: 'sec1',
                  title: "Welcome to Hotel Management",
                  duration: "5 mins",
                  type: "video",
                  videoUrl: "https://example.com/videos/welcome",
                  preview: "/images/tutorials/welcome-preview.jpg",
                  completed: true,
                  content: `
                    <div class="space-y-4">
                      <p>Welcome to the Hotel Management System! In this introductory section, we'll cover:</p>
                      <ul class="list-disc pl-6 space-y-2">
                        <li>Overview of the hotel management dashboard</li>
                        <li>Key features and functionality</li>
                        <li>Basic navigation and interface elements</li>
                        <li>Getting started with your first tasks</li>
                      </ul>
                      <div class="bg-sage/10 p-4 rounded-lg mt-4">
                        <p class="font-medium text-sage">Pro Tip:</p>
                        <p>Keep this guide handy as you start exploring the system. You can always come back to review specific sections.</p>
                      </div>
                    </div>
                  `,
                  locked: false
                },
                {
                  id: 'sec2',
                  title: "Dashboard Navigation",
                  duration: "10 mins",
                  type: "video",
                  videoUrl: "https://example.com/videos/dashboard",
                  preview: "/images/tutorials/dashboard-preview.jpg",
                  completed: true,
                  content: `
                    <div class="space-y-4">
                      <p>The dashboard is your command center. Let's explore each section:</p>
                      <div class="grid grid-cols-2 gap-4">
                        <div class="p-4 border border-mercury rounded-lg">
                          <h4 class="font-medium text-hazel-green mb-2">Quick Actions</h4>
                          <p>Access frequently used features like check-in, check-out, and room status.</p>
                        </div>
                        <div class="p-4 border border-mercury rounded-lg">
                          <h4 class="font-medium text-hazel-green mb-2">Statistics</h4>
                          <p>View occupancy rates, revenue, and other key metrics at a glance.</p>
                        </div>
                      </div>
                    </div>
                  `,
                  locked: false
                }
              ]
            },
            {
              id: 'ch2',
              title: "Basic Operations",
              duration: "30 mins",
              sections: [
                {
                  id: 'sec3',
                  title: "Guest Check-in Process",
                  duration: "10 mins",
                  type: "video",
                  videoUrl: "https://example.com/videos/checkin",
                  preview: "/images/tutorials/checkin-preview.jpg",
                  completed: false,
                  content: `
                    <div class="space-y-4">
                      <p>Master the check-in process with these step-by-step instructions:</p>
                      <ol class="list-decimal pl-6 space-y-2">
                        <li>Verify guest reservation details</li>
                        <li>Collect necessary documentation</li>
                        <li>Assign room and generate key cards</li>
                        <li>Process initial payments and deposits</li>
                        <li>Provide guest information package</li>
                      </ol>
                      <div class="bg-sage/10 p-4 rounded-lg mt-4">
                        <p class="font-medium text-sage">Important Note:</p>
                        <p>Always verify guest ID and payment method before proceeding with check-in.</p>
                      </div>
                    </div>
                  `,
                  locked: false
                },
                {
                  id: 'sec4',
                  title: "Room Management",
                  duration: "10 mins",
                  type: "video",
                  videoUrl: "https://example.com/videos/rooms",
                  preview: "/images/tutorials/rooms-preview.jpg",
                  completed: false,
                  content: `
                    <div class="space-y-4">
                      <p>Efficient room management is crucial for smooth operations:</p>
                      <div class="grid grid-cols-2 gap-4 my-4">
                        <div class="p-4 border border-mercury rounded-lg">
                          <h4 class="font-medium text-hazel-green mb-2">Room Status</h4>
                          <ul class="list-disc pl-4">
                            <li>Available</li>
                            <li>Occupied</li>
                            <li>Maintenance</li>
                            <li>Cleaning</li>
                          </ul>
                        </div>
                        <div class="p-4 border border-mercury rounded-lg">
                          <h4 class="font-medium text-hazel-green mb-2">Quick Actions</h4>
                          <ul class="list-disc pl-4">
                            <li>Mark for cleaning</li>
                            <li>Report maintenance</li>
                            <li>Block room</li>
                            <li>View history</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  `,
                  locked: true
                }
              ]
            }
          ]
        },
        {
          id: 2,
          title: "Room Booking Management",
          description: "Master the room booking process and learn how to handle reservations efficiently.",
          duration: "25 mins",
          difficulty: "Intermediate",
          category: "Operations",
          thumbnail: "/images/tutorials/room-booking.jpg",
          progress: 30,
          completedSections: 2,
          totalSections: 6,
          isBookmarked: true
        },
        {
          id: 3,
          title: "Advanced Guest Services",
          description: "Learn advanced techniques for providing exceptional guest services and handling special requests.",
          duration: "20 mins",
          difficulty: "Advanced",
          category: "Guest Services",
          thumbnail: "/images/tutorials/guest-services.jpg",
          progress: 75,
          completedSections: 4,
          totalSections: 5,
          isBookmarked: false
        }
      ];
  // ... existing states ...
  const [activeTab, setActiveTab] = useState('all');
  const [bookmarkedTutorials, setBookmarkedTutorials] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedDifficulty, setSelectedDifficulty] = useState('all');
  const [activeTutorial, setActiveTutorial] = useState(null);

  const [tutorials, setTutorials] = useState(dummyTutorials);

  const filteredTutorials = tutorials.filter(tutorial => {
    const matchesSearch = tutorial.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         tutorial.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || tutorial.category === selectedCategory;
    const matchesDifficulty = selectedDifficulty === 'all' || tutorial.difficulty === selectedDifficulty;
    return matchesSearch && matchesCategory && matchesDifficulty;
  });

  const handleBookmark = (tutorialId) => {
    setTutorials(prevTutorials =>
      prevTutorials.map(tutorial =>
        tutorial.id === tutorialId
          ? { ...tutorial, isBookmarked: !tutorial.isBookmarked }
          : tutorial
      )
    );
  };

  return (
    <div className="min-h-screen bg-seashell/50">
      {/* Simplified Header Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 -z-10">
          <div className="absolute top-0 right-0 w-96 h-96 bg-sage/5 rounded-full blur-3xl transform translate-x-1/3 -translate-y-1/2" />
          <div className="absolute bottom-0 left-0 w-80 h-80 bg-hazel-green/5 rounded-full blur-2xl transform -translate-x-1/4" />
        </div>

        <div className="container mx-auto px-4 py-8">
          {/* Streamlined Header Content */}
          <div className="max-w-4xl mx-auto">
            <div className="flex flex-col space-y-6">
              {/* Badge */}
              <div className="inline-flex items-center self-start px-4 py-2 rounded-full bg-sage/10 text-sage">
                <BookOpen className="w-4 h-4 mr-2" />
                <span className="text-sm font-medium">Learning Center</span>
              </div>
              
              {/* Main Title */}
              <div className="space-y-3">
                <h1 className="text-3xl font-bold text-hazel-green">
                  Hotel Management Learning Hub
                </h1>
                <p className="text-bluish-grey max-w-2xl">
                  Enhance your skills with our comprehensive tutorials and guides.
                </p>
              </div>

              {/* Quick Stats Cards */}
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-4">
                <div className="bg-white/70 backdrop-blur-sm rounded-xl p-4 border border-mercury/30 hover:border-sage/30 transition-colors">
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-sage/10 rounded-lg">
                      <TrendingUp className="w-4 h-4 text-sage" />
                    </div>
                    <div>
                      <p className="text-xs text-bluish-grey">Progress</p>
                      <p className="text-sm font-semibold text-hazel-green">12 Tutorials</p>
                    </div>
                  </div>
                </div>

                <div className="bg-white/70 backdrop-blur-sm rounded-xl p-4 border border-mercury/30 hover:border-sage/30 transition-colors">
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-sage/10 rounded-lg">
                      <BookmarkCheck className="w-4 h-4 text-sage" />
                    </div>
                    <div>
                      <p className="text-xs text-bluish-grey">Completed</p>
                      <p className="text-sm font-semibold text-hazel-green">5 Tutorials</p>
                    </div>
                  </div>
                </div>

                <div className="bg-white/70 backdrop-blur-sm rounded-xl p-4 border border-mercury/30 hover:border-sage/30 transition-colors">
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-sage/10 rounded-lg">
                      <Clock className="w-4 h-4 text-sage" />
                    </div>
                    <div>
                      <p className="text-xs text-bluish-grey">In Progress</p>
                      <p className="text-sm font-semibold text-hazel-green">3 Tutorials</p>
                    </div>
                  </div>
                </div>

                <div className="bg-white/70 backdrop-blur-sm rounded-xl p-4 border border-mercury/30 hover:border-sage/30 transition-colors">
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-sage/10 rounded-lg">
                      <Bookmark className="w-4 h-4 text-sage" />
                    </div>
                    <div>
                      <p className="text-xs text-bluish-grey">Saved</p>
                      <p className="text-sm font-semibold text-hazel-green">2 Tutorials</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Enhanced Tabs Section */}
      <div className="mb-8 px-4">
        <Tabs defaultValue="all" className="w-full">
          <div className="border-b border-mercury mb-6">
            <TabsList className="flex space-x-2 -mb-px">
              <TabsTrigger 
                value="all" 
                className="px-6 py-3 text-bluish-grey hover:text-hazel-green transition-colors relative
                  data-[state=active]:text-hazel-green data-[state=active]:border-b-2 
                  data-[state=active]:border-hazel-green"
              >
                <div className="flex items-center space-x-2">
                  <BookOpen className="w-4 h-4" />
                  <span>All Tutorials</span>
                </div>
              </TabsTrigger>
              
              <TabsTrigger 
                value="inProgress"
                className="px-6 py-3 text-bluish-grey hover:text-hazel-green transition-colors relative
                  data-[state=active]:text-hazel-green data-[state=active]:border-b-2 
                  data-[state=active]:border-hazel-green"
              >
                <div className="flex items-center space-x-2">
                  <Clock className="w-4 h-4" />
                  <span>In Progress</span>
                  <span className="ml-2 px-2 py-0.5 text-xs rounded-full bg-sage/10 text-sage">
                    3
                  </span>
                </div>
              </TabsTrigger>
              
              <TabsTrigger 
                value="completed"
                className="px-6 py-3 text-bluish-grey hover:text-hazel-green transition-colors relative
                  data-[state=active]:text-hazel-green data-[state=active]:border-b-2 
                  data-[state=active]:border-hazel-green"
              >
                <div className="flex items-center space-x-2">
                  <CheckCircle2 className="w-4 h-4" />
                  <span>Completed</span>
                  <span className="ml-2 px-2 py-0.5 text-xs rounded-full bg-sage/10 text-sage">
                    5
                  </span>
                </div>
              </TabsTrigger>
              
              <TabsTrigger 
                value="bookmarked"
                className="px-6 py-3 text-bluish-grey hover:text-hazel-green transition-colors relative
                  data-[state=active]:text-hazel-green data-[state=active]:border-b-2 
                  data-[state=active]:border-hazel-green"
              >
                <div className="flex items-center space-x-2">
                  <Bookmark className="w-4 h-4" />
                  <span>Bookmarked</span>
                  <span className="ml-2 px-2 py-0.5 text-xs rounded-full bg-sage/10 text-sage">
                    2
                  </span>
                </div>
              </TabsTrigger>
            </TabsList>
          </div>

          {/* Enhanced Search and Filters Section */}
          <div className="relative overflow-x-hidden">
            {/* Background Decorative Elements */}
            <div className="absolute inset-0 -z-10">
              <div className="absolute top-0 right-0 w-72 h-72 bg-sage/5 rounded-full blur-3xl transform translate-x-1/3 -translate-y-1/2" />
              <div className="absolute bottom-0 left-0 w-64 h-64 bg-hazel-green/5 rounded-full blur-2xl transform -translate-x-1/4" />
            </div>

              <div className="bg-white/70 backdrop-blur-xl rounded-2xl shadow-lg border border-mercury/30 p-8 mb-8">
              <div className="flex flex-col lg:flex-row gap-6">
                {/* Search Bar */}
                <div className="lg:flex-1">
                  <div className="relative group">
                    <div className="absolute left-4 top-1/2 transform -translate-y-1/2 text-bluish-grey/70 
                      group-hover:text-sage transition-colors duration-200">
                      <Search className="w-5 h-5" />
                    </div>
                    <input
                      type="search"
                      placeholder="What would you like to learn?"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full pl-12 pr-4 h-12 rounded-xl border-2 border-mercury/50 
                        bg-white/80 hover:border-sage/50 focus:border-sage focus:ring-4 
                        focus:ring-sage/10 placeholder-bluish-grey/50 text-hazel-green 
                        transition-all duration-200"
                    />
                  </div>
                </div>

                {/* Filters Group - Compact Version */}
                <div className="flex gap-2">
                  {/* Category Filter */}
                  <div className="relative group">
                    <select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                      className="w-32 appearance-none pl-2 pr-7 h-9 rounded-lg border border-mercury/50 
                        bg-white/80 hover:border-sage/50 focus:border-sage focus:ring-1 
                        focus:ring-sage/10 text-xs text-hazel-green cursor-pointer 
                        transition-all duration-200"
                    >
                      <option value="all">Categories</option>
                      <option value="basics">Getting Started</option>
                      <option value="operations">Operations</option>
                      <option value="advanced">Advanced</option>
                    </select>
                    <ChevronDown className="absolute right-2 top-1/2 -translate-y-1/2 
                      w-3.5 h-3.5 pointer-events-none text-bluish-grey/70 group-hover:text-sage 
                      transition-colors duration-200" />
                  </div>

                  {/* Difficulty Filter */}
                  <div className="relative group">
                    <select
                      value={selectedDifficulty}
                      onChange={(e) => setSelectedDifficulty(e.target.value)}
                      className="w-28 appearance-none pl-2 pr-7 h-9 rounded-lg border border-mercury/50 
                        bg-white/80 hover:border-sage/50 focus:border-sage focus:ring-1 
                        focus:ring-sage/10 text-xs text-hazel-green cursor-pointer 
                        transition-all duration-200"
                    >
                      <option value="all">All Levels</option>
                      <option value="beginner">Beginner</option>
                      <option value="intermediate">Intermediate</option>
                      <option value="advanced">Advanced</option>
                    </select>
                    <ChevronDown className="absolute right-2 top-1/2 -translate-y-1/2 
                      w-3.5 h-3.5 pointer-events-none text-bluish-grey/70 group-hover:text-sage 
                      transition-colors duration-200" />
                  </div>
                </div>
              </div>

              {/* Active Filters */}
              {(selectedCategory !== 'all' || selectedDifficulty !== 'all' || searchQuery) && (
                <div className="flex flex-wrap items-center gap-3 mt-6 pt-6 border-t border-mercury/30">
                  <span className="text-sm text-bluish-grey">Active Filters:</span>
                  {searchQuery && (
                    <button
                      onClick={() => setSearchQuery('')}
                      className="group flex items-center gap-2 px-3 py-1.5 rounded-lg bg-sage/10 
                        hover:bg-sage/20 text-sage text-sm transition-colors duration-200"
                    >
                      <Search className="w-3.5 h-3.5" />
                      <span>{searchQuery}</span>
                      <X className="w-3.5 h-3.5 group-hover:rotate-90 transition-transform duration-200" />
                    </button>
                  )}
                  {selectedCategory !== 'all' && (
                    <button
                      onClick={() => setSelectedCategory('all')}
                      className="group flex items-center gap-2 px-3 py-1.5 rounded-lg bg-sage/10 
                        hover:bg-sage/20 text-sage text-sm transition-colors duration-200"
                    >
                      <FolderOpen className="w-3.5 h-3.5" />
                      <span>{selectedCategory}</span>
                      <X className="w-3.5 h-3.5 group-hover:rotate-90 transition-transform duration-200" />
                    </button>
                  )}
                  {selectedDifficulty !== 'all' && (
                    <button
                      onClick={() => setSelectedDifficulty('all')}
                      className="group flex items-center gap-2 px-3 py-1.5 rounded-lg bg-sage/10 
                        hover:bg-sage/20 text-sage text-sm transition-colors duration-200"
                    >
                      <Signal className="w-3.5 h-3.5" />
                      <span>{selectedDifficulty}</span>
                      <X className="w-3.5 h-3.5 group-hover:rotate-90 transition-transform duration-200" />
                    </button>
                  )}
                  {(selectedCategory !== 'all' || selectedDifficulty !== 'all' || searchQuery) && (
                    <button
                      onClick={() => {
                        setSearchQuery('');
                        setSelectedCategory('all');
                        setSelectedDifficulty('all');
                      }}
                      className="text-sm text-bluish-grey hover:text-sage transition-colors duration-200"
                    >
                      Clear all
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Featured Tutorials Section */}
          <div className="mb-12 px-4">
            <h2 className="text-2xl font-semibold text-hazel-green mb-6 flex items-center">
              <Sparkles className="w-6 h-6 mr-2 text-sage" />
              Featured Tutorials
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              {/* Featured tutorial cards */}
            </div>
          </div>

          {/* Main Tutorial Grid with Enhanced Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
            {filteredTutorials.map(tutorial => (
              <motion.div
                key={tutorial.id}
                whileHover={{ y: -5 }}
                className="group bg-white/80 backdrop-blur-sm rounded-2xl shadow-sm overflow-hidden hover:shadow-xl transition-all duration-300 border border-mercury/30"
              >
                <div className="relative aspect-video">
                <img
                  src={tutorial.thumbnail}
                  alt={tutorial.title}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
                <div className="absolute bottom-4 left-4 right-4">
                  <div className="flex items-center justify-between text-white">
                  <div className="flex items-center space-x-3">
                    <div className="flex items-center space-x-2 bg-black/30 backdrop-blur-sm px-3 py-1 rounded-full">
                    <Clock className="w-4 h-4" />
                    <span className="text-sm">{tutorial.duration}</span>
                    </div>
                    <div className={`px-3 py-1 rounded-full backdrop-blur-sm text-sm ${
                    tutorial.difficulty === 'Beginner' ? 'bg-sage/80' :
                    tutorial.difficulty === 'Intermediate' ? 'bg-tealish-blue/80' :
                    'bg-watermelon/80'
                    }`}>
                    {tutorial.difficulty}
                    </div>
                  </div>
                  </div>
                </div>
                </div>

                <div className="p-6">
                <div className="flex items-start justify-between mb-4">
                  <h3 className="font-semibold text-lg text-hazel-green group-hover:text-sage transition-colors duration-200">
                  {tutorial.title}
                  </h3>
                  <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleBookmark(tutorial.id)}
                  className={`text-bluish-grey hover:text-sage rounded-full p-2 h-auto ${
                    tutorial.isBookmarked ? 'bg-sage/10 text-sage' : ''
                  }`}
                  >
                  {tutorial.isBookmarked ? (
                    <BookmarkCheck className="w-5 h-5" />
                  ) : (
                    <Bookmark className="w-5 h-5" />
                  )}
                  </Button>
                </div>

                <p className="text-sm text-bluish-grey mb-4 line-clamp-2">
                  {tutorial.description}
                </p>

                {tutorial.progress > 0 && (
                  <div className="mb-4">
                  <div className="flex items-center justify-between text-xs text-bluish-grey mb-2">
                    <span className="bg-sage/10 text-sage px-3 py-1 rounded-full">
                    {tutorial.progress}% completed
                    </span>
                    <span>{tutorial.completedSections}/{tutorial.totalSections} sections</span>
                  </div>
                  <div className="h-2 bg-mercury/30 rounded-full overflow-hidden">
                    <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${tutorial.progress}%` }}
                    className="h-full bg-gradient-to-r from-sage to-hazel-green rounded-full"
                    />
                  </div>
                  </div>
                )}

                <div className="flex items-center justify-between mt-4 pt-4 border-t border-mercury/30">
                  <Button
                  variant="default"
                  onClick={() => setActiveTutorial(tutorial)}
                  className="w-full bg-gradient-to-r from-sage to-hazel-green hover:from-sage/90 hover:to-hazel-green/90 text-white shadow-md hover:shadow-lg transition-all duration-200"
                  >
                  {tutorial.progress > 0 ? 'Continue Learning' : 'Start Learning'}
                  <ChevronRight className="w-4 h-4 ml-2" />
                  </Button>
                </div>
                </div>
              </motion.div>
            ))}
          </div>
        </Tabs>
      </div>

      {/* Enhanced Tutorial Viewer Modal */}
      <AnimatePresence>
        {activeTutorial && (
          <TutorialViewer
            tutorial={activeTutorial}
            onClose={() => setActiveTutorial(null)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default TutorialPage; 

