import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import Loading from '../../../components/loader';
import CustomTable from '../../../components/table-components/table';

import { Link } from 'react-router-dom';
import { getAllReservationsHistory } from '../../../api/reservations'; 
import { IconArrowBack} from '@tabler/icons-react';

function RoomReservationList() {
  const [roomReservationList, setRoomReservationList] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getRoomReservationList();
  }, []);

  const getRoomReservationList = useCallback(async () => {
    setLoading(true); 
    try {
      const response = await getAllReservationsHistory(currentPage + 1, rowsPerPage);
      console.log("data for the reservation" , response.data); 

      // Access the correct path in the response
      const reservations = response.data.reservations.reservations || [];
      setRoomReservationList(reservations);
      setTotalCount(response.data.reservations.totalCount || 0);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getRoomReservationList();
  }, [currentPage, rowsPerPage]);

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
        <div className='flex justify-start mb-4'> {/* Add a wrapper for flex alignment */}
            <Link
                to='/rooms'
                className='flex justify-center items-center text-center items-center text-white rounded-md bg-sage px-4 py-2.5 my-4' // Set a fixed width for the button
            >
                <IconArrowBack className='mr-2' size={20} />
                Go Back to the Room Page
            </Link>
        </div>
      <CustomTable
        rows={roomReservationList}
        columns={[
          {
            title: 'Guest Name',
            renderValue: (item) => `${item.name || 'N/A'} ${item.surname || ''}`.trim() // Display guest's full name
          },
          {
            title: 'Email',
            renderValue: (item) => item.email || 'N/A'
          },
          {
            title: 'Phone Number',
            renderValue: (item) => item.phoneNumber || 'N/A'
          },
          {
            title: 'Start Date',
            renderValue: (item) => dayjs(item.fromDate).format('DD/MM/YYYY')
          },
          {
            title: 'End Date',
            renderValue: (item) => dayjs(item.toDate).format('DD/MM/YYYY')
          },
          {
            title: 'Number of Guests',
            renderValue: (item) => item.adults + item.children // Sum adults and children
          },
          {
            title: 'Room Number',
            renderValue: (item) => item.rooms[0]?.room?.number || 'N/A' // Access room number from rooms
          },
          {
            title: 'Status',
            renderValue: (item) => item.status || 'N/A'
          },
          {
            title: 'Created At',
            renderValue: (item) => dayjs(item.createdAt).format('DD/MM/YYYY')
          },
        ]}
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={false}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default RoomReservationList;