import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, Settings, Upload } from 'lucide-react';

import { getCompany } from '../../../api/company';
import { getSuppliers } from '../../../api/accounting';
import { getNextReturnDocumentNumber, getExpenseForReturn, createExpenseReturn } from '../../../api/purchase';
import { toast } from 'react-toastify';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '../../../components/shadCN/Select.tsx';
import { Button } from '../../../components/shadCN/Button.tsx';
import SupplierModal from '../../../components/SupplierModal';

interface ReturnItem {
  id: string;
  quantity: number;
  priceWithoutVAT: number;
  vatRate: number;
  vatAmount: number;
  totalWithVAT: number;
  reason: string;
  purchaseItemId: string;
  articleId: string;
  remainingQuantity: number;
  articleName: string;
  unit: { name: string };
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Supplier {
  id: string;
  name: string;
  fiscalNumber: string;
  address?: string;
  email?: string;
  phone?: string;
}

const ReturnExpensePage = () => {
  const navigate = useNavigate();
  const [documentNumber, setDocumentNumber] = useState('');
  const [returnDate, setReturnDate] = useState(new Date().toISOString().split('T')[0]);
  const [originalExpenseDocument, setOriginalExpenseDocument] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [description, setDescription] = useState('');
  const [returnItems, setReturnItems] = useState<ReturnItem[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExpense, setIsLoadingExpense] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [expense, setExpense] = useState<any>(null);
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch document number
        try {
          const response = await getNextReturnDocumentNumber();
          console.log('Document number response:', response);
          if (response?.data?.message) {
            setDocumentNumber(response.data.message);
          } else {
            console.warn('No document number in response');
            const currentYear = new Date().getFullYear();
            const defaultDocNumber = `RET-EXP-${currentYear}-000001`;
            console.log('Using default document number:', defaultDocNumber);
            setDocumentNumber(defaultDocNumber);
          }
        } catch (error) {
          console.error('Error fetching document number:', error);
          const currentYear = new Date().getFullYear();
          const defaultDocNumber = `RET-EXP-${currentYear}-000001`;
          console.log('Using default document number after error:', defaultDocNumber);
          setDocumentNumber(defaultDocNumber);
          toast.warning('Using default document number');
        }

        // Fetch suppliers
        const suppliersResponse = await getSuppliers();
        const suppliersList = suppliersResponse?.data || [];
        setSuppliers(suppliersList);

        // Fetch company locations
        const companyResponse = await getCompany();
        if (companyResponse?.data?.message?.locations) {
          setLocations(companyResponse.data.message.locations);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        toast.error('Failed to load initial data');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const resetForm = () => {
    setExpense(null);
    setSelectedSupplier('');
    setSelectedLocation('');
    setDescription('');
    setReturnItems([]);
  };

  const handleExpenseSearch = async () => {
    if (!originalExpenseDocument) {
      toast.error('Please enter an expense document number');
      return;
    }

    try {
      setIsLoadingExpense(true);
      const response = await getExpenseForReturn(originalExpenseDocument);
      console.log('Expense response:', response);
      
      const expenseData = response?.data?.message;
      
      if (expenseData) {
        console.log('Expense data:', expenseData);
        
        // Set the expense data
        setExpense(expenseData);
        
        // Set supplier
        if (expenseData.supplierId) {
          console.log('Setting supplier ID:', expenseData.supplierId);
          setSelectedSupplier(expenseData.supplierId);
        } else {
          console.warn('No supplier ID found in expense data');
          toast.warning('No supplier information found');
        }
        
        // Set location
        if (expenseData.locationId) {
          console.log('Setting location ID:', expenseData.locationId);
          setSelectedLocation(expenseData.locationId);
        } else {
          console.warn('No location ID in expense data');
          toast.warning('No location information found');
        }
        
        // Set description
        setDescription(expenseData.description || '');
        
        // Map items with proper calculations
        if (Array.isArray(expenseData.items) && expenseData.items.length > 0) {
          const initialReturnItems = expenseData.items
            .filter(item => (item.remainingQuantity || 0) > 0)
            .map((item) => ({
              id: crypto.randomUUID(),
              quantity: 0,
              priceWithoutVAT: item.priceWithoutVAT || 0,
              vatRate: item.vatRate || 0,
              vatAmount: 0,
              totalWithVAT: 0,
              reason: '',
              purchaseItemId: item.id,
              articleId: item.articleId,
              remainingQuantity: item.remainingQuantity || 0,
              articleName: item.article?.name || 'Unknown Article',
              unit: item.unit || { name: 'N/A' }
            }));

          if (initialReturnItems.length > 0) {
            setReturnItems(initialReturnItems);
            toast.success('Expense details loaded successfully');
          } else {
            toast.warning('No items available for return');
            setReturnItems([]);
          }
        } else {
          console.warn('No items found in expense data');
          toast.warning('No items found in expense');
          setReturnItems([]);
        }
      } else {
        console.error('Invalid response data:', response);
        toast.error('Invalid expense data received');
        resetForm();
      }
    } catch (error: any) {
      console.error('Error fetching expense:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch expense details';
      toast.error(errorMessage);
      resetForm();
    } finally {
      setIsLoadingExpense(false);
    }
  };

  const handleQuantityChange = (articleId: string, quantity: number) => {
    setReturnItems(returnItems.map(item => {
      if (item.id === articleId) {
        const newQuantity = Math.min(quantity, item.remainingQuantity);
        const itemTotalWithoutVAT = item.priceWithoutVAT * newQuantity;
        const itemVatAmount = (itemTotalWithoutVAT * item.vatRate) / 100;
        const itemTotalWithVAT = itemTotalWithoutVAT + itemVatAmount;

        return {
          ...item,
          quantity: newQuantity,
          vatAmount: itemVatAmount,
          totalWithVAT: itemTotalWithVAT
        };
      }
      return item;
    }));
  };

  const calculateTotals = () => {
    return returnItems.reduce((acc, item) => {
      const itemTotalWithoutVAT = item.priceWithoutVAT * (item.quantity || 0);
      const itemVatAmount = (itemTotalWithoutVAT * item.vatRate) / 100;
      const itemTotalWithVAT = itemTotalWithoutVAT + itemVatAmount;

      return {
        totalWithoutVAT: acc.totalWithoutVAT + itemTotalWithoutVAT,
        totalVAT: acc.totalVAT + itemVatAmount,
        totalWithVAT: acc.totalWithVAT + itemTotalWithVAT
      };
    }, {
      totalWithoutVAT: 0,
      totalVAT: 0,
      totalWithVAT: 0
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validation
    if (!selectedSupplier) {
      toast.error('Please select a supplier');
      return;
    }

    if (!returnDate) {
      toast.error('Please select a return date');
      return;
    }

    if (!expense?.id) {
      toast.error('Please search for an expense first');
      return;
    }

    if (returnItems.length === 0) {
      toast.error('Please add at least one item to return');
      return;
    }

    // Check if all items have quantities and reasons
    const invalidItems = returnItems.filter(
      item => item.quantity <= 0 || !item.reason.trim()
    );

    if (invalidItems.length > 0) {
      toast.error('All items must have a quantity greater than 0 and a reason for return');
      return;
    }

    try {
      setIsSubmitting(true);

      // Prepare data for API
      const returnData = {
        expenseId: expense.id,
        documentNumber,
        returnDate: new Date(returnDate),
        reason: description,
        items: returnItems.map(item => ({
          purchaseItemId: item.purchaseItemId,
          quantity: item.quantity
        })),
        createdBy: 'system' // Or get from user context if available
      };

      // Call API to create return
      const response = await createExpenseReturn(returnData);
      
      if (response?.data?.statusCode === 200) {
        toast.success('Expense return created successfully');
        navigate('/administration/accounting/returns'); // Adjust path as needed
      } else {
        throw new Error('Failed to create expense return');
      }
    } catch (error: any) {
      console.error('Error creating expense return:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Failed to create expense return';
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSupplierSuccess = async (newSupplier: any) => {
    setIsSupplierModalOpen(false);
    try {
      const suppliersRes = await getSuppliers();
      if (suppliersRes?.data) {
        setSuppliers(suppliersRes.data);
        setSelectedSupplier(newSupplier.id);
        toast.success('Furnitori u shtua me sukses');
      }
    } catch (error) {
      console.error('Error refreshing suppliers:', error);
      toast.error('Dështoi përditësimi i listës së furnitorëve');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate(-1)}
                className="inline-flex items-center gap-x-2 px-4 py-2 text-sm text-bluish-grey hover:text-hazel-green transition-colors"
              >
                <ArrowLeft className="h-4 w-4" />
                Kthehu Prapa
              </button>
            </div>
            <h1 className="text-xl font-semibold text-hazel-green">
              Kthim i Shpenzimit
            </h1>
            <div className="flex items-center gap-2">
              <button
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="inline-flex items-center gap-x-2 px-4 py-2 text-sm text-bluish-grey hover:text-hazel-green transition-colors"
              >
                {isSubmitting ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-sage/20 border-t-sage"></div>
                    Duke ruajtur...
                  </>
                ) : (
                  <>
                    <Settings className="h-4 w-4" />
                    Ruaj Kthimin
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Document Details */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Detajet për Kthim nga Shpenzimi</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i Dokumentit
                  </label>
                  <input
                    type="text"
                    value={documentNumber}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                  <p className="mt-1 text-sm text-grey-goose">
                    Emri i dokumentit është automatikisht i gjeneruar në bazë të formatit.
                  </p>
                </div>
                <div className="flex items-center gap-4 mb-6">
                  <div className="flex-1">
                    <label className="block text-sm font-medium text-bluish-grey mb-2">
                      Numri i Dokumentit të Shpenzimit
                    </label>
                    <div className="flex gap-2">
                      <input
                        type="text"
                        value={originalExpenseDocument}
                        onChange={(e) => setOriginalExpenseDocument(e.target.value)}
                        placeholder="Shkruani numrin e dokumentit"
                        className="block flex-1 rounded-lg border border-mercury px-3 py-2 text-sm"
                      />
                      <Button
                        onClick={handleExpenseSearch}
                        disabled={isLoadingExpense}
                        className="px-4 py-2 bg-sage text-white rounded-lg hover:bg-sage/90 transition-colors"
                      >
                        {isLoadingExpense ? (
                          <div className="flex items-center gap-2">
                            <div className="animate-spin rounded-full h-4 w-4 border-2 border-white/20 border-t-white"></div>
                            Duke kërkuar...
                          </div>
                        ) : (
                          <div className="flex items-center gap-2">
                            <Settings className="h-4 w-4" />
                            Kërko
                          </div>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Data e kthimit
                  </label>
                  <input
                    type="date"
                    value={returnDate}
                    onChange={(e) => setReturnDate(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                  />
                </div>
                {/* Supplier Select */}
                <div className="relative mb-4">
                  <label htmlFor="supplier" className="mb-2 block text-sm font-medium text-slate-600">
                    Furnitori
                  </label>
                  <div className="flex gap-2 items-start">
                    <div className="flex-1">
                      <Select
                        value={selectedSupplier}
                        onValueChange={setSelectedSupplier}
                        disabled={isLoading}
                      >
                        <SelectTrigger 
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                          disabled={isLoading}
                        >
                          <SelectValue 
                            placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni furnitorin"}
                            className="text-bluish-grey"
                          >
                            {selectedSupplier && (
                              <span className="text-hazel-green">
                                {suppliers.find(s => s.id === selectedSupplier)?.businessName || suppliers.find(s => s.id === selectedSupplier)?.name}
                              </span>
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent 
                          className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                          sideOffset={5}
                          position="popper"
                          align="start"
                        >
                          <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                            <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                              Zgjidhni Furnitorin
                            </h3>
                          </div>
                          {suppliers.map(supplier => (
                            <SelectItem
                              key={supplier.id}
                              value={supplier.id}
                              textValue={supplier.businessName || supplier.name}
                              className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                            >
                              {/* Hidden span for the select field display */}
                              <span className="hidden">
                                {supplier.businessName || supplier.name}
                              </span>
                              
                              {/* Detailed content for dropdown */}
                              <div className="flex flex-col gap-1">
                                <div className="flex items-center justify-between">
                                  <div className="flex flex-col">
                                    <span className="text-sm sm:text-base font-medium text-hazel-green">
                                      {supplier.businessName || supplier.name}
                                    </span>
                                    {supplier.tradeName && supplier.tradeName !== supplier.businessName && (
                                      <span className="text-xs sm:text-sm text-bluish-grey">
                                        Trading as: {supplier.tradeName}
                                      </span>
                                    )}
                                  </div>
                                  {supplier.businessType && (
                                    <span className="px-2 py-0.5 rounded-full text-xs sm:text-sm font-medium bg-sage/10 text-sage">
                                      {supplier.businessType}
                                    </span>
                                  )}
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 text-xs sm:text-sm">
                                  {supplier.fiscalNumber && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm3 1h6v4H7V5zm8 8v2h1v-2h-1zm-2-6H7v4h6V7z" clipRule="evenodd" />
                                      </svg>
                                      <span>Nr. Fiskal: {supplier.fiscalNumber}</span>
                                    </div>
                                  )}
                                  {supplier.address && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                      </svg>
                                      <span>{supplier.address}</span>
                                    </div>
                                  )}
                                  {supplier.phone && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                      </svg>
                                      <span>{supplier.phone}</span>
                                    </div>
                                  )}
                                  {supplier.email && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                      </svg>
                                      <span>{supplier.email}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <Button
                      type="button"
                      onClick={() => setIsSupplierModalOpen(true)}
                      className="px-4 py-2 bg-sage text-white rounded-lg hover:bg-sage/90 transition-colors"
                    >
                      <Plus className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
                {/* Location Select */}
                <div className="mb-4">
                  <label htmlFor="location" className="mb-2 block text-sm font-medium text-slate-600">
                    Lokacioni
                  </label>
                  <Select
                    value={selectedLocation}
                    onValueChange={setSelectedLocation}
                    disabled={isLoading}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                      disabled={isLoading}
                    >
                      <SelectValue 
                        placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni lokacionin"}
                        className="text-bluish-grey"
                      >
                        {selectedLocation && (
                          <span className="text-hazel-green">
                            {locations.find(l => l.id === selectedLocation)?.name}
                          </span>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                      sideOffset={5}
                      position="popper"
                      align="start"
                    >
                      {locations.map(location => (
                        <SelectItem
                          key={location.id}
                          value={location.id}
                          textValue={location.name}
                          className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm sm:text-base font-medium text-hazel-green">
                              {location.name}
                            </span>
                            {location.address && (
                              <span className="text-xs sm:text-sm text-bluish-grey">
                                {location.address}
                              </span>
                            )}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Përshkrimi
                  </label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Articles */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Artikujt</h2>
              <p className="text-sm text-grey-goose">Artikujt për kthim.</p>
            </div>
            <div className="p-6">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-mercury">
                  <thead>
                    <tr className="bg-gradient-to-r from-sage/5 to-transparent">
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Artikulli</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Njësi Matëse</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Sasia</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Çmimi për Njësi</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Norma e TVSH-së</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Shuma</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Shuma Totale</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Arsyeja</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Veprime</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-mercury/40">
                    {returnItems.length === 0 ? (
                      <tr>
                        <td colSpan={9} className="px-4 py-8 text-center text-sm text-bluish-grey">
                          Nuk ka të dhëna
                        </td>
                      </tr>
                    ) : (
                      returnItems.map((article) => (
                        <tr key={article.id}>
                          <td className="px-4 py-4">
                            <input
                              type="text"
                              value={article.articleName}
                              disabled
                              className="block w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="text"
                              value={article.unit.name}
                              disabled
                              className="block w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              min="0"
                              max={article.remainingQuantity}
                              value={article.quantity}
                              onChange={(e) => handleQuantityChange(article.id, Number(e.target.value))}
                              className="block w-full rounded-lg border border-mercury px-3 py-2 text-sm"
                            />
                            <div className="text-xs text-bluish-grey mt-1">
                              Max: {article.remainingQuantity}
                            </div>
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              value={article.priceWithoutVAT}
                              disabled
                              className="block w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              value={article.vatRate}
                              disabled
                              className="block w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="text"
                              value={article.vatAmount?.toFixed(2) || "0.00"}
                              disabled
                              className="block w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="text"
                              value={article.totalWithVAT?.toFixed(2) || "0.00"}
                              disabled
                              className="block w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <textarea
                              value={article.reason}
                              onChange={(e) => {
                                const updatedItems = returnItems.map(item => {
                                  if (item.id === article.id) {
                                    return { ...item, reason: e.target.value };
                                  }
                                  return item;
                                });
                                setReturnItems(updatedItems);
                              }}
                              className="block w-full rounded-lg border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <button 
                              onClick={() => {
                                setReturnItems(returnItems.filter(a => a.id !== article.id));
                              }}
                              className="text-red-500 hover:text-red-700 transition-colors"
                            >
                              Fshij
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Totals */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Totali</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-4 gap-4">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Lloji
                  </label>
                  <input
                    type="text"
                    disabled
                    value="0.00"
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Vlera pa TVSH
                  </label>
                  <input
                    type="text"
                    disabled
                    value={calculateTotals().totalWithoutVAT.toFixed(2)}
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Shuma Totale e TVSH-së
                  </label>
                  <input
                    type="text"
                    disabled
                    value={calculateTotals().totalVAT.toFixed(2)}
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Vlera me TVSH
                  </label>
                  <input
                    type="text"
                    disabled
                    value={calculateTotals().totalWithVAT.toFixed(2)}
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Attachments */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Bashkëngjit dokument (attachment)</h2>
            </div>
            <div className="p-6">
              <div className="border-2 border-dashed border-mercury rounded-lg p-8">
                <div className="flex flex-col items-center justify-center gap-2">
                  <Upload className="h-8 w-8 text-grey-goose" />
                  <p className="text-sm text-bluish-grey">Mund vendosni dokument këtu (drag and drop)</p>
                  <p className="text-xs text-grey-goose">
                    Shto një dokument (attachment), psh. faturën orgjinaie te kthimit, faturën e pranuar nga furnitori.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SupplierModal
        isOpen={isSupplierModalOpen}
        onClose={() => setIsSupplierModalOpen(false)}
        onSuccess={handleSupplierSuccess}
      />
    </div>
  );
};

export default ReturnExpensePage;
