import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import OrderForm from './OrderForm';
import { useNavigate, useParams } from 'react-router-dom';


function AddOrder() {
  const { tableId } = useParams();
  return (
    <div className='p-10'>
      <PageTitle
        icon={
        //   <FontAwesomeIcon
        //     icon={faBuilding}
        //     className='text-hazel-green mr-2'
        //   />
        <FontAwesomeIcon icon="fa-solid fa-burger" className='text-hazel-green mr-2' />
        // <FontAwesomeIcon icon="fa-solid fa-utensils" className='text-hazel-green mr-2'/>
        }
        title='Order'
      />
      <OrderForm
        isEdit={false}
        tableId={tableId}
        // environment={{
        //   name: '',
        //   restaurantId: ''
        // }}
      />
    </div>
  );
}

export default AddOrder;
