import api from './api';

// Function to create a new invoice

// export const createInvoice = (invoiceData) => 
//   api.post('/api/invoices', {
//     ...invoiceData,
//     createdById: invoiceData.createdById || invoiceData.adminUserId
//   });

export const createInvoice = (invoiceData) => 
  api.post('/api/invoiceSale', invoiceData);

// Function to get all invoices
export const getAllInvoices = (page = 1, limit = 10) => 
  api.get('/api/invoiceSale', {
    params: {
      page,
      limit
    }
  });

// Function to get all invoices without pagination
export const getAllInvoicesWithoutPagination = () => 
  api.get('/api/invoiceSale/all');

// Function to get an invoice by ID
export const getInvoiceById = (id) => 
  api.get(`/api/invoiceSale/${id}`);

// Function to update an invoice
export const updateInvoice = (id, invoiceData) => 
  api.put(`/api/invoiceSale/${id}`, invoiceData);

// Function to delete an invoice
export const deleteInvoice = (id) => 
  api.delete(`/api/invoiceSale/${id}`);

export const invoiceCount = () => api.get("/api/invoiceSale/count")
