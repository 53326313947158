import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IconBedFilled } from '@tabler/icons-react';
import RoomForm from './RoomForm';
import PageTitle from '../../../components/page-title';
import Loading from '../../../components/loader';
import { getRoom } from '../../../api/hotelInterface';

function EditRoomType() {
  const params = useParams();
  const roomId = params.id;
  const [room, setRoom] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRoomData();
  }, []);

  const getRoomData = async () => {
    try {
      const response = await getRoom(roomId);
      setRoom(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconBedFilled className='text-hazel-green mr-2' size={22} />}
        title='Edit Room'
      />
      <RoomForm
        isEdit
        room={{
          id: room.id,
          number: room.number,
          floor: room.floor,
          status: room.status,
          roomTypeId: room.roomType.title,
          minibar: room.minibar,
          // inventory: JSON.parse(room.roomType.inventory)
          inventory: typeof room.roomType.inventory === 'string' 
          ? JSON.parse(room.roomType.inventory) 
          : room.roomType.inventory
        }}
      />
    </div>
  );
}

export default EditRoomType;
