import React from "react";

import Header from "../common/Header";
import Footer from "../common/Footer";
import Heading from "../common/Heading";

import AddRoom from "../home/AddNewRoom";

function CreateRoomPage() {

    return (

        <>
            <Header />
            <Heading heading="Create a Room" title="Room" subtitle="Creation" />
            <div className="my-24">
                <AddRoom />
            </div>
            <Footer />
        </>

    );

}

export default CreateRoomPage;