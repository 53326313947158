import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import Loading from '../../components/loader';
import SelectInput from '../../components/select-input';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import { roomStatus } from '../../constants/entities';
import ConfirmMessage from '../../components/alerts/alert-message';
import Modal from '../../components/modal';
import MinibarForm from '../../components/products/minibarForm';
import useAuth from '../../hooks/useAuth';
import { createRoom, updateRoom } from '../../api/rooms';
import { updateInventory } from '../../api/inventory';
import { giveReservationFeedback } from '../../api/reservations';
import { getRoomTypes } from '../../api/rooms';

import { checkReservationExists } from '../../api/reservations';
import { X } from 'lucide-react';

function RoomForm({ isEdit, room, roomType, loading }) {
  const { userRole } = useAuth();
  console.log("USER ROLE", userRole)
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [showMinibar, setShowMinibar] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [roomTypes, setRoomTypes] = useState([]);

  const [isUpdatingInventory, setIsUpdatingInventory] = useState(false);
  const [inventoryToDelete, setInventoryToDelete] = useState(null);

  const fetchRoomTypes = useCallback(async () => {
    try {
      const response = await getRoomTypes();
      const formattedRoomTypes = response.data.map((type) => ({
        id: type.id,
        title: type.title,
      }));
      setRoomTypes(formattedRoomTypes);
    } catch (err) {
      console.error('Failed to fetch room types:', err);
    }
  }, []);

  useEffect(() => {
    fetchRoomTypes();
  }, [fetchRoomTypes]);

  const handleSelectAllChange = (event, values, setFieldValue) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);

    const updatedInventory = Array.isArray(values.inventory)
      ? values.inventory.map((item) => ({
          ...item,
          goodCondition: isChecked
        }))
      : [];

    setFieldValue('inventory', updatedInventory);
  };

  const deleteMinibarItem = async (values, setFieldValue) => {
    try {
      const response = await updateInventory(room.minibar.id, {
        increaseProducts: [],
        decreaseProducts: [{ productId: currentProduct.id }]
      });

      if (response.status === 201) {
        const updatedMinibarProducts = values.minibar.products.filter(
          (item) => item.id !== currentProduct.id
        );
        setFieldValue('minibar.products', updatedMinibarProducts);
      }

      setShowAlert(false);
    } catch (err) {
      console.error('Error deleting minibar item:', err);
    }
  };

  Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
    return this.test('unique', message, function (list) {
      return list.length === new Set(list.map(mapper)).size;
    });
  });

  if (loading) {
    return <Loading />;
  }

  const canEditInventory = ['pastruse', 'admin'].includes(
    userRole.toLowerCase()
  );

  console.log("inventpry edit",canEditInventory)
  

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={{
          number: room?.number || '',
          floor: room?.floor || '',
          roomTypeId: room?.roomTypeId || '',
          status: room?.status || 'AVAILABLE',
          inventory: Array.isArray(room?.inventory) ? room.inventory : [],
          minibar: room?.minibar ? { id: room.minibar.id, products: Array.isArray(room.minibar.products) ? room.minibar.products : [] } : { id: null, products: [] },
          comment: room?.comment || ''
        }}
        validationSchema={Yup.object().shape({
          number: Yup.number().min(1).required('Room Number is required*'),
          floor: Yup.number().min(0).required('Floor is required*'),
          status: Yup.string().required('Status is required*'),
          roomTypeId: Yup.string(),
          inventory: Yup.array().of(
            Yup.object().shape({
              item: Yup.string().required('Item name is required'),
              quantity: Yup.number().min(0, 'Quantity must be positive').required('Quantity is required'),
              goodCondition: Yup.boolean()
            })
          ).unique('Duplicate items are not allowed', item => item.item)
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            setIsUpdatingInventory(true);
            if (isEdit) {
              const dataToSend = {
                number: values.number,
                inventory: values.inventory,
                status: values.status,
                floor: values.floor,
                // Check for undefined or null values
              };

              for (const key in dataToSend) {
                if (dataToSend[key] === undefined || dataToSend[key] === null) {
                  console.error(`Missing required field: ${key}`);
                }
              }

              console.log('Data to send:', dataToSend); // Log the data being sent
              await updateRoom(room.id, dataToSend);
              if (canEditInventory) {
                // Check if the reservation exists
                const reservationExists = await checkReservationExists(room.id);

                if (reservationExists) {
                    const reservationFeedback = {
                        review: values.comment || '',
                        inventory: values.inventory ? JSON.stringify(values.inventory) : '[]'
                    };

                    console.log('Reservation Feedback:', reservationFeedback);
                    await giveReservationFeedback(room.id, reservationFeedback)
                        .then((res) =>
                            toast.success('Feedback updated successfully!')
                        )
                        .catch((err) =>
                            toast.error('An error occurred while updating feedback.')
                        );
                } else {
                    toast.info('No existing reservation found. Feedback will not be submitted.');
                }
            }
        } else {
            const { minibar, ...addValues } = values;
            await createRoom(addValues);
            navigate('/rooms');
        }

          } catch (err) {
            console.error('Error during submission:', err); // Log the error
            setErrors({
              submit: err?.response?.data || 'An error occurred during submission.'
            });
          } finally {
            setIsUpdatingInventory(false);
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <div>
            <form onSubmit={handleSubmit}>
              <div className='grid grid-cols-2 gap-x-6 gap-y-3'>
                <CustomInput
                  type='number'
                  name='number'
                  label='Room Number'
                  value={values.number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.number && touched.number}
                  errorMessage={errors.number}
                  disabled={!canEditInventory}
                />
                <SelectInput
                  name='roomTypeId'
                  label='Room Type'
                  value={values.roomTypeId}
                  options={roomTypes}
                  onChange={handleChange('roomTypeId')}
                  onBlur={handleBlur}
                  showError={errors.roomTypeId && touched.roomTypeId}
                  errorMessage={errors.roomTypeId}
                  className='border boder-hazel-green rounded-md w-full'
                />
                <CustomInput
                  type='number'
                  name='floor'
                  label='Floor'
                  value={values.floor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.floor && touched.floor}
                  errorMessage={errors.floor}
                />
                <SelectInput
                  value={values.status}
                  label='Status'
                  options={roomStatus}
                  onChange={handleChange('status')}
                  onBlur={handleBlur}
                  showError={errors.status && touched.status}
                  errorMessage={errors.status}
                  isEntity
                />
              </div>
              {(canEditInventory ||
              (isEdit &&
                values?.inventory?.some((item) => item.item !== ''))) && (
              <>
                <div className='py-3 border-b boder-hazel-green' />
                <div className="flex justify-between items-center my-3">
                  <h3 className='text-bluish-grey font-medium'>Inventory</h3>
                  {canEditInventory && (
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue('inventory', [
                          ...(values.inventory || []),
                          { item: '', quantity: 1, goodCondition: true }
                        ]);
                      }}
                      className="px-4 py-2 text-sm font-medium text-white bg-hazel-green rounded-md hover:bg-hazel-green/90"
                    >
                      Add Item
                    </button>
                  )}
                  </div>
                  {canEditInventory && (
                    <div className='flex justify-end items-center text-sage font-bold'>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectAllChecked}
                              onChange={(event) =>
                                handleSelectAllChange(
                                  event,
                                  values,
                                  setFieldValue
                                )
                              }
                              inputProps={{ 'aria-label': 'controlled' }}
                              color='success'
                            />
                          }
                          label='Select all'
                        />
                      </FormGroup>
                    </div>
                  )}
                  <FieldArray name='inventory'>
                    <div className='text-center mt-5'>
                      {values?.inventory?.map((option, index) => (
                        <div
                          key={index}
                          className='grid grid-cols-12 gap-x-4 mt-4 items-center'
                        >
                          <div className='col-span-6'>
                            <CustomInput
                              type='text'
                              name={`inventory[${index}].item`}
                              label='Item'
                              value={option.item}
                              onChange={async (event) => {
                                setFieldValue(
                                  `inventory[${index}].item`,
                                  event.target.value
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={!canEditInventory}
                            />
                          </div>
                          <div className='col-span-2'>
                            <CustomInput
                              type='number'
                              name={`inventory[${index}].quantity`}
                              label='Quantity'
                              value={option.quantity}
                              onChange={async (event) => {
                                setFieldValue(
                                  `inventory[${index}].quantity`,
                                  event.target.value
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={!canEditInventory}
                            />
                          </div>
                          {canEditInventory && (
                            <div className='col-span-2 flex items-center justify-center'>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={option.goodCondition}
                                      onChange={(event) => {
                                        setFieldValue(
                                          `inventory[${index}].goodCondition`,
                                          event.target.checked
                                        );
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      color='success'
                                    />
                                  }
                                />
                              </FormGroup>
                            </div>
                          )}
                          {canEditInventory && (
                            <div className='col-span-1 flex items-center justify-center'>
                              <button
                                type="button"
                                onClick={() => setInventoryToDelete(index)}
                                className="text-watermelon hover:text-watermelon/90 p-2 rounded-full hover:bg-gray-100"
                              >
                                <X size={20} />
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </FieldArray>
                </>
              )}
              {canEditInventory && (
                <div className='w-full'>
                  <CustomInput
                    type='text'
                    name='comment'
                    label='Comment'
                    value={values.comment}
                    onChange={handleChange('comment')}
                    onBlur={handleBlur}
                    multiline={true}
                    showError={errors.comment && touched.comment}
                    errorMessage={errors.comment}
                  />
                </div>
              )}
              {isEdit && (
                <>
                  <div className='py-3 border-b boder-hazel-green' />
                  <h3 className='text-bluish-grey font-medium my-3'>Minibar</h3>
                  <div className='grid grid-cols-4 gap-x-3 my-4'>
                    <div
                      className='flex items-center p-3.5 bg-seashell rounded-md cursor-pointer'
                      onClick={() => setShowMinibar(true)}
                    >
                      <p className='text-bluish-grey cursor-pointer'>
                        Add item
                      </p>
                    </div>
                    {values.minibar?.products.map((item, index) => (
                      <div
                        key={index}
                        className='grid grid-cols-3 items-center bg-seashell rounded-md'
                      >
                        <p className='col-span-2 text-bluish-grey border-r-4 p-3.5'>
                          {item.name}
                        </p>
                        <div className='relative py-3.5 text-center'>
                          <p className='text-bluish-grey'>{item.quantity}</p>
                          <div
                            className='absolute rounded-full bg-watermelon w-3 h-3 top-2 right-2 cursor-pointer'
                            onClick={() => {
                              setCurrentProduct(item);
                              setShowAlert(true);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
              <div className='flex justify-end items-center w-full mt-7 text-sm'>
                <CancelButton onCancel={() => navigate('/rooms')} />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width='w-1/5'
                  title='Save'
                  onClick={() => console.log("0cclicked")}
                />
              </div>
            </form>
            {showAlert && (
              <ConfirmMessage
                title='Are you sure you want to delete this minibar item?'
                onCancel={() => setShowAlert(!showAlert)}
                onConfirm={() => deleteMinibarItem(values, setFieldValue)}
              />
            )}
            {showMinibar && (
              <Modal
                title="Add item"
                titleSize="text-xl"
                onClose={() => setShowMinibar(false)}
              >
                <MinibarForm
                  roomMinibar={values.minibar}
                  setShowModal={() => setShowMinibar(false)}
                  setFieldValue={setFieldValue}
                />
              </Modal>
            )}
            {inventoryToDelete !== null && (
              <ConfirmMessage
                title="Are you sure you want to delete this inventory item?"
                onCancel={() => setInventoryToDelete(null)}
                onConfirm={() => {
                  const newInventory = values.inventory.filter((_, i) => i !== inventoryToDelete);
                  setFieldValue('inventory', newInventory);
                  setInventoryToDelete(null);
                  toast.success('Inventory item deleted successfully');
                }}
              />
            )}
          </div>
        )}
      </Formik>
    </div>
  );
}

export default RoomForm;
