
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../../../components/loader';
import { SubmitButton, CancelButton } from '../../../../../components/buttons';
import CustomInput from '../../../../../components/custom-input';
import { createSupplier, updateSupplier } from '../../../../../api/accounting';
import { scrapeBusinessData } from '../../../../../api/scraperBusinesses';

function SupplierForm({ isEdit, supplier }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showFullForm, setShowFullForm] = useState(false); // Toggle full form or UBIN field

  const validationSchema = Yup.object().shape({
    ubin: Yup.string().required('UBIN is required*'),
    businessName: Yup.string().when('showFullForm', {
      is: true,
      then: Yup.string().min(3).required('Business name is required*'),
    }),
    tradeName: Yup.string().when('showFullForm', {
      is: true,
      then: Yup.string().min(3).required('Trade name is required*'),
    }),
    businessType: Yup.string().when('showFullForm', {
      is: true,
      then: Yup.string().required('Business type is required*'),
    }),
    municipality: Yup.string().when('showFullForm', {
      is: true,
      then: Yup.string().required('Municipality is required*'),
    }),
    address: Yup.string().when('showFullForm', {
      is: true,
      then: Yup.string().required('Address is required*'),
    }),
    email: Yup.string().when('showFullForm', {
      is: true,
      then: Yup.string().email('Invalid email format').required('Email is required*'),
    }),
    phone: Yup.string().when('showFullForm', {
      is: true,
      then: Yup.string().required('Phone number is required*'),
    }),
    representative: Yup.string(), // Optional field
  });

  // Display loading spinner if form is in a loading state
  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
      <div className="flex justify-between rounded-t-xl items-center p-5 bg-seashell border-b">
        <h3 className="text-lg font-semibold text-hazel-green">
          {isEdit ? 'Edit Supplier' : 'Create Supplier'}
        </h3>
        <button
          className="text-sage hover:text-hazel-green focus:outline-none"
          onClick={() => setShowFullForm(!showFullForm)}
        >
          {showFullForm ? 'Switch to UBIN Only' : 'Switch to Full Form'}
        </button>
      </div>

      <Formik
        initialValues={supplier || { 
          ubin: '', 
          businessName: '', 
          tradeName: '', 
          businessType: '', 
          municipality: '', 
          address: '', 
          email: '', 
          phone: '', 
          representative: '' 
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting, setFieldValue }) => {
          setLoading(true);

          try {
            // Check if we're in UBIN-only mode
            if (!isEdit && !showFullForm) {
              const scrapedResult = await scrapeBusinessData(values.ubin);
              
              if (scrapedResult && scrapedResult.data && scrapedResult.data.length > 0) {
                const businessData = scrapedResult.data.reduce((acc, row) => {
                  const [label, value] = row;
                  acc[label] = value;
                  return acc;
                }, {});

                // Auto-fill form fields based on scraped data
                setFieldValue('businessName', businessData['Emri i biznesit'] || values.businessName);
                setFieldValue('tradeName', businessData['Emri tregtar'] || values.tradeName);
                setFieldValue('businessType', businessData['Lloji biznesit'] || values.businessType);
                setFieldValue('municipality', businessData['Komuna'] || values.municipality);
                setFieldValue('address', businessData['Adresa'] || values.address);
                setFieldValue('email', businessData['E-mail'] || values.email);
                setFieldValue('phone', businessData['Telefoni'] || values.phone);
                setFieldValue('representative', businessData['Përfaqësuesi'] || values.representative);

                // Automatically submit the form to create a new supplier
                await createSupplier({
                  ubin: values.ubin,
                  businessName: businessData['Emri i biznesit'],
                  tradeName: businessData['Emri tregtar'],
                  businessType: businessData['Lloji biznesit'],
                  municipality: businessData['Komuna'],
                  address: businessData['Adresa'],
                  email: businessData['E-mail'],
                  phone: businessData['Telefoni'],
                  representative: businessData['Përfaqësuesi'],
                });
                
                navigate('/accounting/suppliers');
              } else {
                setErrors({ ubin: 'No business data found for this UBIN' });
                setSubmitting(false);
                setLoading(false);
                return;
              }
            }

            if (isEdit || showFullForm) {
              if (isEdit) {
                await updateSupplier(supplier.id, values);
              } else {
                await createSupplier(values);
                navigate('/accounting/suppliers');
              }
            }

            setSubmitting(false);
          } catch (err) {
            setErrors({
              submit: err?.response?.data
            });
            setSubmitting(false);
          } finally {
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue // Added to programmatically set form fields
        }) => (
          <form onSubmit={handleSubmit} className='px-7 py-5'>
            <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
              {/* UBIN Field: Always visible */}
              <CustomInput
                type='text'
                name='ubin'
                label='UBIN'
                value={values.ubin}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.ubin && touched.ubin}
                errorMessage={errors.ubin}
              />

              {/* Conditionally render the full form based on the showFullForm state */}
              {showFullForm && (
                <>
                  <CustomInput
                    type='text'
                    name='businessName'
                    label='Business Name'
                    value={values.businessName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.businessName && touched.businessName}
                    errorMessage={errors.businessName}
                  />
                  <CustomInput
                    type='text'
                    name='tradeName'
                    label='Trade Name'
                    value={values.tradeName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.tradeName && touched.tradeName}
                    errorMessage={errors.tradeName}
                  />

                  {/* These were some hard times did you go through them I can remember this hard Display */}
                  <CustomInput
                    type='text'
                    name='businessType'
                    label='Business Type'
                    value={values.businessType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.businessType && touched.businessType}
                    errorMessage={errors.businessType}
                  />
                  <CustomInput
                    type='text'
                    name='municipality'
                    label='Municipality'
                    value={values.municipality}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.municipality && touched.municipality}
                    errorMessage={errors.municipality}
                  />
                  <CustomInput
                    type='text'
                    name='address'
                    label='Address'
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.address && touched.address}
                    errorMessage={errors.address}
                  />
                  <CustomInput
                    type='email'
                    name='email'
                    label='Email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.email && touched.email}
                    errorMessage={errors.email}
                  />
                  <CustomInput
                    type='text'
                    name='phone'
                    label='Phone'
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.phone && touched.phone}
                    errorMessage={errors.phone}
                  />
                  <CustomInput
                    type='text'
                    name='representative'
                    label='Representative'
                    value={values.representative}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.representative && touched.representative}
                    errorMessage={errors.representative}
                  />
                </>
              )}
            </div>

            {errors.submit && (
              <p className='text-red-500 text-sm mt-2'>{errors.submit}</p>
            )}

            <div className='flex justify-end gap-x-2'>
              <CancelButton
                type='button'
                title='Cancel'
                onClick={() => navigate('/accounting/suppliers')}
              />
              <SubmitButton
                type='submit'
                title={isEdit ? 'Update Supplier' : 'Create Supplier'}
                disabled={isSubmitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default SupplierForm;

//I dont know when this will end it doesnt seem that it will end anytime soon so how can we do this I dont have