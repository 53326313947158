import api from './api';


export const getCategoryOrderProducts = () => api.get('/api/categoryOrderProduct');

export const getCategoryOrderProductById = (id) => api.get(`/api/categoryOrderProduct/${id}`);

export const createCategoryOrderProduct = (values) => {
  return api.post('/api/categoryOrderProduct', values);
};

export const updateCategoryOrderProduct = (id, values) => {
  return api.put(`/api/categoryOrderProduct/${id}`, values);
};

export const deleteCategoryOrderProduct = (id) => {
  return api.delete(`/api/categoryOrderProduct/${id}`);
};
