// import {
//   Card,
//   CardHeader,
//   CardBody,
//   CardFooter,
//   Typography,
//   Button,
// } from "@material-tailwind/react";

// import { deleteEnvironment } from '../../api/restaurant';


// export function EnvironmentCard({environment, onDelete}) {


//   const handleDelete = async () => {
//     try {
//       await deleteEnvironment(environment.id);
//       onDelete(environment.id);
//     } catch (err) {
//       console.error('Failed to delete environment:', err);
//     }
//   };

//   const truncateDescription = (text, maxLength) => {
//     if (text.length > maxLength) {
//       return text.substring(0, maxLength) + '...';
//     }
//     return text;
//   };

//   return (
//     <Card className="mt-6 w-96">
//       <CardHeader color="blue-gray" className="relative h-56">
//         <img
//           src={environment.imageUrl}
//           alt="card-image"
//         />
//       </CardHeader>
//       <CardBody>
//         <Typography variant="h5" color="blue-gray" className="mb-2">
//           {environment.name}
//         </Typography>
//         <Typography>
//              {truncateDescription(environment.description, 100)}
//         </Typography>
//       </CardBody>
//       <CardFooter className="pt-0">
//         <Button onClick={handleDelete}>Remove environment</Button>
//       </CardFooter>
//     </Card>
//   );
// }


// export default EnvironmentCard;




import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { deleteEnvironment } from '../../api/restaurant';

export function EnvironmentCard({ environment, onDelete }) {
  const handleDelete = async () => {
    try {
      await deleteEnvironment(environment.id);
      onDelete(environment.id); // Call the onDelete function to update the parent state
    } catch (err) {
      console.error('Failed to delete environment:', err);
    }
  };

  const truncateDescription = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (

      <Card className="mt-6 w-96">
        <CardHeader color="blue-gray" className="relative h-56 overflow-hidden">
          <img
            src={`http://127.0.0.1:3001${environment.imageUrl}`}
            alt="environment"
            className="w-full h-full object-cover"
          />
        </CardHeader>
        <CardBody>
          <Typography variant="h5" color="blue-gray" className="mb-2">
            {environment.name}
          </Typography>
          <Typography>
            {truncateDescription(environment.description, 100)}
          </Typography>
        </CardBody>
        <CardFooter className="pt-0">
          <Button onClick={handleDelete} className="px-6 py-2 text-gray-800 text-md">Remove environment</Button>
        </CardFooter>
      </Card>

  );
}

export default EnvironmentCard;