import React, { useState, useEffect } from 'react';
import { Users, DollarSign, ClipboardList, Table2, RefreshCw, AlertCircle, Loader2 } from 'lucide-react';
import { getAllWaiters, reconcileWaiter, getDataSinceReconciliation } from '../../api/waiter';
import { adjustAccountValue } from '../../api/account';
import { createTransaction } from '../../api/accounting';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WaiterReconciliation = () => {
  const [waiters, setWaiters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [processingId, setProcessingId] = useState(null);
  const [waiterData, setWaiterData] = useState({});

  useEffect(() => {
    loadWaiters();
  }, []);

  const loadWaiters = async () => {
    try {
      setLoading(true);
      const response = await getAllWaiters();
      setWaiters(response.data);
      console.log(response.data)

      const dataPromises = response.data.map(waiter =>
        getDataSinceReconciliation(waiter.id).then(res => ({
          waiterId: waiter.id,
          ...res.data,
        }))
      );

      const dataResults = await Promise.all(dataPromises);
      const dataMap = dataResults.reduce((acc, data) => {
        acc[data.waiterId] = data;
        return acc;
      }, {});

      console.log("data map", dataMap);
      setWaiterData(dataMap);
    } catch (error) {
      setError('Failed to load waiters. Please try again later.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleReconcile = async (waiterId, totalEarnings) => {
    try {
      if (totalEarnings === 0 && waiterData[waiterId]?.totalTransactions === 0) {
        toast.error('No transactions or earnings to reconcile');
        return;
      }

      setProcessingId(waiterId);
      const cashAccountId = 1;
      const paymentType = 'CASH';

      await adjustAccountValue(cashAccountId, totalEarnings);
      await createTransaction({
        amount: totalEarnings,
        description: `Reconciliation for waiter ${waiterId}`,
        accountId: cashAccountId,
        transactionType: 'PAYMENT',
        paymentType,
      });
      await reconcileWaiter(waiterId);

      const updatedData = await getDataSinceReconciliation(waiterId);

      setWaiterData(prevData => ({
        ...prevData,
        [waiterId]: {
          ...updatedData.data,
          totalTransactions: 0,
          totalEarnings: 0,
          isReconciled: true,
        },
      }));

      toast.success(`Reconciliation successful for waiter ${waiterId}`);
    } catch (error) {
      console.error('Reconciliation error:', error);
      toast.error(`Reconciliation failed for waiter ${waiterId}`);
    } finally {
      setProcessingId(null);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-seashell">
        <div className="flex flex-col items-center gap-3">
          <Loader2 className="w-8 h-8 animate-spin text-hazel-green" />
          <p className="text-bluish-grey font-medium">Loading waiters data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-seashell">
        <div className="flex flex-col items-center gap-3 text-watermelon">
          <AlertCircle className="w-8 h-8" />
          <p className="font-medium">{error}</p>
          <button
            onClick={loadWaiters}
            className="px-4 py-2 text-sm font-medium text-white bg-hazel-green rounded-md hover:bg-sage focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sage"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-seashell min-h-screen">
      <ToastContainer />
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-hazel-green sm:text-3xl">Waiter Reconciliation</h1>
        <p className="mt-2 text-sm text-bluish-grey">Manage and reconcile waiter transactions and earnings</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <StatsCard
          icon={<Users className="w-6 h-6 text-hazel-green" />}
          title="Total Waiters"
          value={waiters.length}
        />
        <StatsCard
          icon={<DollarSign className="w-6 h-6 text-sage" />}
          title="Total Earnings"
          value={`$${Object.values(waiterData).reduce((sum, data) => sum + (data.totalEarnings || 0), 0).toFixed(2)}`}
        />
        <StatsCard
          icon={<ClipboardList className="w-6 h-6 text-bluish-grey" />}
          title="Total Transactions"
          value={Object.values(waiterData).reduce((sum, data) => sum + (data.totalTransactions || 0), 0)}
        />
        <StatsCard
          icon={<Table2 className="w-6 h-6 text-sage" />}
          title="Active Tables"
          value={waiters.reduce((sum, w) => sum + (w.WaiterTableAssignment?.length || 0), 0)}
        />
      </div>

      <div className="bg-white rounded-lg shadow-lg overflow-hidden border border-grey-goose">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-grey-goose">
            <thead className="bg-tealish-blue">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Waiter</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Transactions</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Earnings</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Tables</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-bluish-grey uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-grey-goose">
              {waiters.map((waiter) => (
                <tr key={waiter.id} className="hover:bg-seashell transition-colors duration-150">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <div className="h-10 w-10 rounded-full bg-grey-goose flex items-center justify-center">
                          <span className="text-hazel-green font-medium">
                            {waiter.name.charAt(0)}
                          </span>
                        </div>
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-hazel-green">{waiter.name}</div>
                        <div className="text-sm text-bluish-grey">ID: {waiter.id}</div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-bluish-grey">
                      {waiterData[waiter.id]?.totalTransactions || 0}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-bluish-grey">
                      ${waiterData[waiter.id]?.totalEarnings?.toFixed(2) || '0.00'}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-bluish-grey">
                      {waiter.WaiterTableAssignment?.map(assignment => assignment.table.number).join(', ') || 'None'}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      waiter.isReconciled
                        ? 'bg-grey-goose text-hazel-green'
                        : 'bg-dawn-pink text-salmon-pink'
                    }`}>
                      {waiter.isReconciled ? 'Reconciled' : 'Pending'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => handleReconcile(waiter.id, waiterData[waiter.id]?.totalEarnings || 0)}
                      disabled={waiter.isReconciled || processingId === waiter.id || (waiterData[waiter.id]?.totalTransactions === 0 && waiterData[waiter.id]?.totalEarnings === 0)}
                      className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                        ${waiter.isReconciled
                          ? 'bg-mercury cursor-not-allowed'
                          : processingId === waiter.id
                          ? 'bg-sage cursor-wait'
                          : 'bg-hazel-green hover:bg-sage focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sage'
                        }`}
                    >
                      {processingId === waiter.id ? (
                        <>
                          <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
                          Processing
                        </>
                      ) : (
                        <>
                          <RefreshCw className={`-ml-1 mr-2 h-4 w-4 ${processingId === waiter.id ? 'animate-spin' : ''}`} />
                          {waiter.isReconciled ? 'Reconciled' : 'Reconcile'}
                        </>
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const StatsCard = ({ icon, title, value }) => (
  <div className="bg-white p-6 rounded-lg shadow-md border border-grey-goose">
    <div className="flex items-center">
      <div className="flex-shrink-0">{icon}</div>
      <div className="ml-5 w-0 flex-1">
        <dl>
          <dt className="text-sm font-medium text-bluish-grey truncate">{title}</dt>
          <dd className="text-lg font-semibold text-hazel-green">{value}</dd>
        </dl>
      </div>
    </div>
  </div>
);

export default WaiterReconciliation;