import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Package, Wrench, Car } from 'lucide-react';
import ArticleFormModal from './ArticleFormModal.tsx';
import ServiceFormModal from './ServiceFormModal.tsx';
import DeviceFormModal from './DeviceFormModal.tsx';
import { createArticleWithLink } from '../../api/accountingArticle';
import { toast } from 'react-hot-toast';

interface AddArticleModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdded?: () => void;
}

interface ArticleType {
  id: string;
  title: string;
  description: string;
  icon: React.ReactNode;
}

interface EntityLink {
  entityType: string;
  entityId: string;
  entityCode?: string;
  entityName?: string;
  entityCategory?: string;
  entityReference?: string;
}

const AddArticleModal: React.FC<AddArticleModalProps> = ({ isOpen, onClose, onAdded }) => {
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [entityLink, setEntityLink] = useState<EntityLink | null>(null);

  const articleTypes: ArticleType[] = [
    {
      id: 'PRODUCT',
      title: 'Produkt (artikull i thjeshtë)',
      description: 'Ky zakonisht është produkt fizik që blini dhe shisni, mallra apo artikuj. Pra, produkte të shitjes me pakicë, mallra të prodhuara dhe produkte tjera për dyçan apo depo.',
      icon: <Package className="w-8 h-8 text-hazel-green" />
    },
    {
      id: 'SERVICE',
      title: 'Shërbim ose Shpenzim',
      description: 'Janë artikuj jofizikë (pa substancë fizike) që shisni ose blini. Kur shisni janë zakonisht shërbimet e konsulencës, shërbimet profesionale, abonime, etj. Kur blini janë zakonisht shpenzimet operative, si: rryma, uji, telefoni, interneti, etj.',
      icon: <Wrench className="w-8 h-8 text-hazel-green" />
    },
    {
      id: 'FIXED_ASSET',
      title: 'Mjet Themelor',
      description: 'Janë pasuritë afatgjata, si: pajisjet, ndërtesat apo automjetet. Këto pasuri kanë jetëgjatësi në kontabilitet zakonisht mbi një vit dhe zhvlerësohen apo amortizohen me ndonjë nga metodat kontabël.',
      icon: <Car className="w-8 h-8 text-hazel-green" />
    }
  ];

  const handleSubmit = async (formData: any) => {
    try {
      setLoading(true);
      
      // Log the incoming form data
      console.log('Creating article with data:', formData);
      
      // Make sure we have both article and link data
      if (!formData.article) {
        throw new Error('Article data is required');
      }
      
      // Add type from selected type
      formData.article.type = selectedType?.toUpperCase();
      
      // Add createdBy and updatedBy
      formData.article.createdBy = 'system';
      formData.article.updatedBy = 'system';
      
      // Send the request
      const response = await createArticleWithLink(formData);
      
      console.log('Article created successfully:', response.data);
      toast.success('Article created successfully');
      onAdded?.();
      onClose();
    } catch (error: any) {
      console.error('Error creating article:', error);
      const errorMessage = error.response?.data?.message 
        || error.message 
        || 'Failed to create article';
      toast.error(`Error: ${errorMessage}`);
      if (error.response?.data?.errors) {
        console.error('Validation errors:', error.response.data.errors);
        toast.error(`Validation errors: ${Object.values(error.response.data.errors).join(', ')}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEntityLink = (link: {
    entityType: string;
    entityId: string;
    entityCode?: string;
    entityName?: string;
    entityCategory?: string;
    entityReference?: string;
  } | null) => {
    setEntityLink(link);
  };

  const renderForm = () => {
    switch (selectedType) {
      case 'PRODUCT':
        return (
          <ArticleFormModal 
            onSubmit={handleSubmit} 
            onBack={() => setShowForm(false)} 
            loading={loading} 
            articleType={selectedType} 
            onClose={onClose}
            onEntityLink={handleEntityLink}
          />
        );
      case 'SERVICE':
        return (
          <ServiceFormModal 
            onSubmit={handleSubmit} 
            onBack={() => setShowForm(false)} 
            loading={loading} 
            articleType={selectedType} 
            onClose={onClose}
            onEntityLink={handleEntityLink}
          />
        );
      case 'FIXED_ASSET':
        return (
          <DeviceFormModal 
            onSubmit={handleSubmit} 
            onBack={() => setShowForm(false)} 
            loading={loading} 
            articleType={selectedType} 
            onClose={onClose}
            onEntityLink={handleEntityLink}
          />
        );
      default:
        return null;
    }
  };

  const handleContinue = () => {
    if (selectedType) {
      setShowForm(true);
    }
  };

  const handleBack = () => {
    setShowForm(false);
    setSelectedType(null);
  };

  const modalVariants = {
    hidden: {
      opacity: 0,
      scale: 0.95
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.2,
        ease: 'easeOut'
      }
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 0.2,
        ease: 'easeIn'
      }
    }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 }
  };

  if (showForm) {
    return renderForm();
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={onClose}
          />
          <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
            <motion.div
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="bg-white rounded-2xl shadow-xl max-w-2xl w-full"
            >
              <div className="p-8">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-semibold text-bluish-grey">Zgjedh llojin e artikullit</h2>
                  <button
                    onClick={onClose}
                    className="text-grey-goose hover:text-bluish-grey transition-colors"
                  >
                    <span className="text-2xl">×</span>
                  </button>
                </div>

                <div className="space-y-4">
                  {articleTypes.map((type) => (
                    <motion.div
                      key={type.id}
                      whileHover={{ scale: 1.01 }}
                      whileTap={{ scale: 0.99 }}
                      className={`p-4 rounded-xl border cursor-pointer transition-colors ${
                        selectedType === type.id
                          ? 'border-hazel-green bg-seashell'
                          : 'border-grey-goose/30 hover:border-hazel-green/50'
                      }`}
                      onClick={() => setSelectedType(type.id)}
                    >
                      <div className="flex gap-4">
                        <div className="flex-shrink-0">{type.icon}</div>
                        <div>
                          <h3 className="font-medium text-bluish-grey mb-1">{type.title}</h3>
                          <p className="text-sm text-grey-goose">{type.description}</p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>

                <div className="mt-8 flex justify-end">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleContinue}
                    disabled={!selectedType}
                    className={`px-6 py-3 rounded-xl font-medium transition-colors ${
                      selectedType
                        ? 'bg-hazel-green text-white hover:bg-opacity-90'
                        : 'bg-grey-goose/30 text-grey-goose cursor-not-allowed'
                    }`}
                  >
                    Vazhdo
                  </motion.button>
                </div>
              </div>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  );
};

export default AddArticleModal;
