import api from './api';

export const getInvoices = (rowsPerPage, currentPage, searchText) =>
  api.get(
    `/api/invoices?limit=${rowsPerPage}&page=${currentPage}&searchText=${searchText}`
  );

export const createInvoice = (values) => api.post(`/api/invoices`, values);

export const getInvoice = (id) => api.get(`/api/invoices/${id}`);

export const updateInvoice = (id, values) =>
  api.patch(`/api/invoices/${id}`, values);

export const deleteInvoice = (id) => api.delete(`/api/invoices/${id}`);
