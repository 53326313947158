
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { createAccount } from '../../api/account';
import { getAccountCategories, getSubCategories } from '../../api/account';

interface CreateAccountFormProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function CreateAccountForm({ isOpen, onClose }: CreateAccountFormProps) {
  const [categories, setCategories] = useState<string[]>([]);
  const [subcategories, setSubcategories] = useState<{ id: number; name: string }[]>([]);
  const [accountName, setAccountName] = useState('');
  const [accountCode, setAccountCode] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getAccountCategories();
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching account categories:', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubcategories = async () => {
      if (!selectedCategory) return;

      try {
        const response = await getSubCategories(selectedCategory);
        setSubcategories(response.data);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };

    fetchSubcategories();
  }, [selectedCategory]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await createAccount({
        name: accountName,
        code: accountCode,
        description,
        type: selectedSubcategory,
        locked: false,
        closingValue: 0,
        subCategoryId: parseInt(selectedSubcategory, 10),
      });
      setAccountName('');
      setAccountCode('');
      setDescription('');
      setSelectedCategory('');
      setSelectedSubcategory('');
      onClose();
    } catch (error) {
      console.error('Error creating account:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        transition={{ type: "spring", duration: 0.3 }}
        className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4 overflow-hidden" // Set to max-w-md for a medium size
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center p-4 border-b border-mercury">
          <h2 className="text-lg font-semibold text-hazel-green">Create Account</h2>
          <button
            onClick={onClose}
            className="text-bluish-grey hover:text-hazel-green transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4"> {/* Increased padding and spacing */}
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Account Category*
            </label>
            <select
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                setSubcategories([]);
                setSelectedSubcategory('');
              }}
              className="w-full border border-mercury rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-hazel-green/20" // Adjusted padding for comfort
              required
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Account Subcategory*
            </label>
            <select
              value={selectedSubcategory}
              onChange={(e) => setSelectedSubcategory(e.target.value)}
              className="w-full border border-mercury rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-hazel-green/20"
              required
            >
              <option value="">Select a subcategory</option>
              {subcategories.map((subcategory) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Account Name*
            </label>
            <input
              type="text"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
              className="w-full border border-mercury rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-hazel-green/20"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Account Code
            </label>
            <input
              type="text"
              value={accountCode}
              onChange={(e) => setAccountCode(e.target.value)}
              className="w-full border border-mercury rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-hazel-green/20"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Description
            </label>
            <textarea
              placeholder="Max. 500 characters"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full border border-mercury rounded-lg p-2 h-24 resize-none focus:outline-none focus:ring-2 focus:ring-hazel-green/20" // Adjust height for comfort
            />
          </div>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="watchlist"
              className="rounded border-mercury text-hazel-green focus:ring-hazel-green"
            />
            <label htmlFor="watchlist" className="text-sm text-bluish-grey">
              Add to watchlist
            </label>
          </div>

          <div className="flex justify-between mt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 border border-mercury rounded-lg text-bluish-grey hover:bg-seashell transition-colors"
            >
              Cancel
            </button>
            <button type="submit" className="px-4 py-2 bg-hazel-green hover:bg-sage text-white rounded-lg transition-colors">
              Save
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
}



