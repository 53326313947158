import { motion } from 'framer-motion';
import { StarIcon, UserIcon, ClockIcon, FolderIcon } from '@heroicons/react/24/outline';

export default function Sidebar({ categories, activeCategory, onCategoryChange, showFavorites, setShowFavorites }) {
  return (
    <div className="w-64 flex-shrink-0">
      <div className="bg-white rounded-lg shadow-sm p-4">
        <div className="space-y-2 mb-6">
          <button
            onClick={() => {
              setShowFavorites(true);
              onCategoryChange(null);
            }}
            className={`flex items-center space-x-2 ${
              showFavorites ? 'text-hazel-green' : 'text-bluish-grey'
            } hover:text-hazel-green w-full p-2 rounded-md`}
          >
            <StarIcon className="h-5 w-5" />
            <span>Favorites</span>
          </button>
          <button className="flex items-center space-x-2 text-bluish-grey hover:text-hazel-green w-full p-2 rounded-md">
            <UserIcon className="h-5 w-5" />
            <span>Custom Reports</span>
          </button>
          <button className="flex items-center space-x-2 text-bluish-grey hover:text-hazel-green w-full p-2 rounded-md">
            <ClockIcon className="h-5 w-5" />
            <span>Scheduled Reports</span>
          </button>
        </div>

        <div className="space-y-1">
          <h3 className="text-sm font-medium text-bluish-grey mb-2">REPORT CATEGORY</h3>
          {Object.entries(categories).map(([category, reports]) => (
            <motion.button
              key={category}
              className={`w-full text-left px-3 py-2 rounded-md text-sm flex items-center space-x-2 ${
                activeCategory === category && !showFavorites ? 'bg-seashell text-hazel-green' : 'text-bluish-grey hover:bg-mercury'
              }`}
              onClick={() => {
                onCategoryChange(category);
                setShowFavorites(false);
              }}
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
            >
              <FolderIcon className="h-4 w-4" />
              <span>{category}</span>
              <span className="ml-auto text-xs text-heather">{reports.length}</span>
            </motion.button>
          ))}
        </div>
      </div>
    </div>
  );
}
