import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLarge } from '@fortawesome/free-solid-svg-icons';
import { IconSearch, IconPropeller, IconBedFilled } from '@tabler/icons-react';
import Loading from '../loader';
import { getSearchItems } from '../../api/search';

const SearchDropdown = ({
  showDropDown,
  setShowDropDown,
  searchText,
  setSearchText
}) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchGlobalItems = async () => {
    if (searchText) {
      setLoading(true);
      try {
        const response = await getSearchItems(searchText);
        setItems(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return err;
      }
    }
  };

  useEffect(() => {
    searchGlobalItems();
  }, [searchText]);

  const getIcon = (entity) => {
    switch (entity) {
      case 'users':
        return (
          <FontAwesomeIcon
            icon={faUserLarge}
            className='text-hazel-green mx-0.5'
          />
        );
      case 'roles':
        return <IconPropeller className='text-hazel-green' size={22} />;
      case 'rooms':
        return <IconBedFilled className='text-hazel-green' size={22} />;
      default:
        break;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className={`${
        showDropDown ? 'flex flex-col' : 'hidden'
      } absolute top-12 left-0 justify-end py-4 w-full bg-white border border-light-gray rounded-md shadow-lg z-50`}
    >
      {items.map((entity) => (
        <div key={entity.entity} className='border-b border-gray-200'>
          {entity.data.map((item) => (
            <Link
              key={item.id}
              to={`/${entity.entity}/${item.entityId}`}
              onClick={() => {
                setSearchText('');
                setShowDropDown(false);
              }}
              className='flex items-center gap-x-3 px-4 py-2'
            >
              <div className='flex items-center justify-center rounded-full w-9 h-9 bg-gray-200'>
                {getIcon(entity.entity)}
              </div>
              <p className='-mt-0.5'>{item.title}</p>
            </Link>
          ))}
          <div className='flex px-4 gap-x-3 py-2'>
            <IconSearch className='w-5 h-5' />
            <Link
              to={`/${entity.entity}`}
              onClick={() => {
                setSearchText('');
                setShowDropDown(false);
              }}
            >
              See all {entity.entity}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SearchDropdown;
