import React, { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import BarCard from '../views/restaurantInterface/BarCard'; // Adjust import based on your file structure
import { getAllBarItems } from '../api/restaurantInterface'; // Ensure you have an API method for bar products
import { useDrag } from 'react-dnd';

const DraggableBarCard = ({ id, index, name, imageUrl, description, price }) => {
  const [, drag] = useDrag({
    type: 'barProduct',
    item: { id, index, name, imageUrl, description, price },
  });

  return (
    <div ref={drag}>
      <BarCard
        name={name}
        imageUrl={imageUrl}
        description={description}
        price={price}
      />
    </div>
  );
};

const BarProductSidebar = ({ show, onClose, onBarProductDrop }) => {
  const [barProducts, setBarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [, drop] = useDrop({
    accept: 'barProduct',
    drop: (item) => {
      if (onBarProductDrop && typeof onBarProductDrop === 'function') {
        onBarProductDrop(item);
      } else {
        console.error('onBarProductDrop is not a function');
      }
    },
});

  useEffect(() => {
    const fetchBarProducts = async () => {
      try {
        const response = await getAllBarItems();
        setBarProducts(response.data || []);
      } catch (error) {
        setError('Failed to fetch bar products');
      } finally {
        setLoading(false);
      }
    };

    fetchBarProducts();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className={`fixed top-0 right-0 w-64 h-full bg-white shadow-lg z-32 transform ${show ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}>
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-bold">Bar Products</h2>
        <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1={18} y1={6} x2={6} y2={18} />
            <line x1={6} y1={6} x2={18} y2={18} />
          </svg>
        </button>
      </div>
      <div ref={drop} className="z-32 overflow-y-auto h-[calc(100%-56px)] p-4">
        {barProducts.map((barProduct, index) => (
          <DraggableBarCard
            key={barProduct.id}
            id={barProduct.id}
            index={index}
            name={barProduct.name}
            imageUrl={barProduct.imageUrl}
            description={barProduct.description}
            price={barProduct.price}
          />
        ))}
      </div>
    </div>
  );
};

export default BarProductSidebar;
