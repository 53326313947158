import React, {useState} from 'react';

import Top from './Top.tsx';
import Screen from './Screen.tsx';
import Keypad from './Keypad.tsx';


const Calculator = ({ handleSubmitInvoice, handleReleaseForPayment, handleInvoicePush }) => {
    const [firstValue, setFirstValue] = useState('');
    const [secondValue, setSecondValue] = useState('');
    const [operator, setOperator] = useState('');
    const [result, setResult] = useState('');
    const [displayInput, setDisplayInput] = useState(''); // For showing operations

    const handleKeyPress = (label, action) => {
        if (!isNaN(label) || label === '.') {
            // If the button pressed is a number or decimal
            if (operator) {
                setSecondValue((prev) => prev + label);
                setDisplayInput(`${firstValue} ${operator} ${secondValue + label}`);
            } else {
                setFirstValue((prev) => prev + label);
                setDisplayInput(firstValue + label); // Update display input
            }
        } else if (action === 'add' || action === 'subtract' || action === 'multiply' || action === 'divide') {
            // If the button pressed is an operator
            if (firstValue) {
                setOperator(action);
                setDisplayInput(`${firstValue} ${getOperatorSymbol(action)} `); // Update display input with operator
            }
        } else if (action === 'calculate') {
            // Calculate the result
            const calculatedResult = calculate(firstValue, operator, secondValue);
            setResult(calculatedResult);
            setFirstValue(calculatedResult); // Allow chaining calculations
            setSecondValue('');
            setOperator('');
            setDisplayInput(''); // Clear display input after calculation
        } else if (action === 'reset') {
            // Reset the calculator
            setFirstValue('');
            setSecondValue('');
            setOperator('');
            setResult('');
            setDisplayInput(''); // Clear display input
        } else if (action === 'delete') {
            // Delete the last character
            if (operator) {
                setSecondValue((prev) => prev.slice(0, -1));
                setDisplayInput(`${firstValue} ${operator} ${secondValue.slice(0, -1)}`);
            } else {
                setFirstValue((prev) => prev.slice(0, -1));
                setDisplayInput(firstValue.slice(0, -1)); // Update display input
            }
        }
    };

    const getOperatorSymbol = (action) => {
        switch (action) {
            case 'add': return '+';
            case 'subtract': return '-';
            case 'multiply': return 'x';
            case 'divide': return '/';
            default: return '';
        }
    };

    return (
        <main className="w-full max-w-[320px] mx-auto">
            <div className="bg-white rounded-lg shadow-lg">
                <div className="p-4 space-y-2">
                    <Top />
                    {/* Display input and operation at the top */}
                    <div className="bg-gray-200 p-2 rounded-lg h-10 flex items-center justify-end overflow-hidden">
                        <span className="text-sm font-mono text-gray-700 tracking-wider truncate">
                            {displayInput || '0'}
                        </span>
                    </div>
                    <Screen screen={result || secondValue || firstValue} />
                    <Keypad
                            handleKeyPress={handleKeyPress}
                            handleSubmitInvoice={handleSubmitInvoice}
                            handleReleaseForPayment={handleReleaseForPayment}
                            handleInvoicePush={handleInvoicePush}
                        />
                </div>
            </div>
        </main>
    );
};

const calculate = (num1, operator, num2) => {
    let leftNum = parseFloat(num1);
    let rightNum = parseFloat(num2);

    if (isNaN(leftNum) || isNaN(rightNum)) return '';

    switch (operator) {
        case 'add':
            return (leftNum + rightNum).toString();
        case 'subtract':
            return (leftNum - rightNum).toString();
        case 'multiply':
            return (leftNum * rightNum).toString();
        case 'divide':
            return rightNum !== 0 ? (leftNum / rightNum).toString() : 'Error'; // Handle division by zero
        default:
            return leftNum.toString();
    }
};


export default Calculator;
