import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, MoreVertical, Plus, Edit2, Trash2 } from 'lucide-react';
import { deleteFiscalYear } from '../../api/company';
import { toast } from 'react-hot-toast';

interface FiscalYear {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
}

interface FiscalYearTableProps {
  data: FiscalYear[];
  onRefresh: () => void;
}

export default function FiscalYearTable({ data, onRefresh }: FiscalYearTableProps) {
  const handleDelete = async (id: string) => {
    try {
      await deleteFiscalYear(id);
      toast.success('Fiscal year deleted successfully');
      onRefresh();
    } catch (error) {
      console.error('Error deleting fiscal year:', error);
      toast.error('Failed to delete fiscal year');
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="border-b border-grey-goose/20">
            <th className="text-left py-3 px-4 text-sm font-medium text-bluish-grey">Viti Fiskal</th>
            <th className="text-left py-3 px-4 text-sm font-medium text-bluish-grey">Data e Fillimit</th>
            <th className="text-left py-3 px-4 text-sm font-medium text-bluish-grey">Data e Mbarimit</th>
            <th className="text-right py-3 px-4 text-sm font-medium text-bluish-grey">Veprime</th>
          </tr>
        </thead>
        <tbody>
          {data.map((year) => (
            <tr
              key={year.id}
              className="border-b border-grey-goose/10 hover:bg-seashell/50 transition-colors"
            >
              <td className="py-3 px-4">
                <div className="flex items-center gap-2">
                  <div className="w-8 h-8 rounded-lg bg-hazel-green/10 flex items-center justify-center">
                    <Calendar className="w-4 h-4 text-hazel-green" />
                  </div>
                  <span className="text-bluish-grey font-medium">{year.name}</span>
                </div>
              </td>
              <td className="py-3 px-4 text-grey-goose">{formatDate(year.startDate)}</td>
              <td className="py-3 px-4 text-grey-goose">{formatDate(year.endDate)}</td>
              <td className="py-3 px-4">
                <div className="flex items-center justify-end gap-2">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="p-2 hover:bg-red-50 rounded-lg transition-colors"
                    onClick={() => handleDelete(year.id)}
                  >
                    <Trash2 className="w-4 h-4 text-red-500" />
                  </motion.button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {data.length === 0 && (
        <div className="text-center py-8 text-grey-goose">
          Nuk ka vite fiskale
        </div>
      )}
    </div>
  );
}
