import React, { useState, useEffect, useRef } from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconPlus, IconPencil, IconTrash, IconRefresh, IconBuildingWarehouse, IconInfoCircle, IconHistory, IconX, IconAlertCircle } from '@tabler/icons-react'; // Import IconRefresh for the refresh button
import Modal from '../../components/modal';
import ConfirmMessage from '../../components/alerts/alert-message';
import Loading from '../../components/loader';
import { fetcher } from '../../api/api';
import { getStockItems, deleteStockItem } from '../../api/stock';
import { getCategories, addCategory, deleteCategory, updateCategory as editCategory } from '../../api/stockCategories'; // Import API function to create categories
import { getStockLossItems, deleteStockLossItem, addStockLossItem, updateStockLossQuantity } from '../../api/stockLoss'; // Import API functions for stock loss
import { updateStockItem } from '../../api/stock';
import { mutate } from 'swr';
import { getStockItemDistribution } from '../../api/stock';
import { format } from 'date-fns';

import { useNavigate } from 'react-router-dom';
import { getStockItemTransactions } from '../../api/stock';
import { IconArrowRight } from '@tabler/icons-react';
// Import toast for error handling
import toast from 'react-hot-toast';

// Transaction History Modal Component
const TransactionHistoryModal = ({ isOpen, onClose, stockItem }) => {
  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (isOpen && stockItem) {
        try {
          setLoading(true);
          const response = await getStockItemTransactions(stockItem.id);
          setTransactions(response.message);
        } catch (error) {
          console.error('Error fetching transactions:', error);
          toast.error('Failed to load transaction history');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchTransactions();
  }, [isOpen, stockItem]);

  if (!isOpen) return null;

  const getTransactionIcon = (type) => {
    switch (type) {
      case 'STOCK_UPDATE':
        return <IconRefresh className="text-hazel-green" size={20} />;
      case 'TRANSFER':
        return <IconArrowRight className="text-sage" size={20} />;
      default:
        return null;
    }
  };

  const getTransactionDescription = (transaction) => {
    if (transaction.type === 'STOCK_UPDATE') {
      return `Stock updated to ${transaction.quantity} ${transaction.unit}`;
    } else {
      return `Transferred ${transaction.quantity} ${transaction.unit} from ${transaction.fromSection || 'MAIN'} to ${transaction.toSection}`;
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-20 flex items-center justify-center z-50"
      style={{ backdropFilter: 'blur(2px)' }}
    >
      <div className="bg-white rounded-xl shadow-2xl p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto transform transition-all duration-300 ease-in-out">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-2xl font-bold text-bluish-grey">
              Transaction History
            </h2>
            <p className="text-grey-goose mt-1">
              {stockItem?.name}
            </p>
          </div>
          <button
            onClick={onClose}
            className="text-grey-goose hover:text-bluish-grey transition-colors"
          >
            <IconX size={24} />
          </button>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-40">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-sage"></div>
          </div>
        ) : transactions ? (
          <div className="space-y-6">
            <div className="bg-seashell rounded-lg p-6">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-lg font-semibold text-bluish-grey">Current Stock Level</h3>
                  <p className="text-hazel-green mt-1 text-2xl font-medium">
                    {transactions.stockItem.currentQuantity} {transactions.stockItem.unitOfMeasurement}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-grey-goose">Category</p>
                  <p className="font-medium text-sage">{transactions.stockItem.category}</p>
                </div>
              </div>
            </div>

            <div className="relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-mercury"></div>
              </div>
              <div className="relative flex justify-center">
                <span className="px-3 bg-white text-sm text-grey-goose">Transaction Timeline</span>
              </div>
            </div>

            <div className="flow-root">
              <ul className="-mb-8">
                {transactions.transactions.map((transaction, idx) => (
                  <li key={transaction.id}>
                    <div className="relative pb-8">
                      {idx !== transactions.transactions.length - 1 && (
                        <span
                          className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-mercury"
                          aria-hidden="true"
                        />
                      )}
                      <div className="relative flex items-center space-x-3">
                        <div className="relative">
                          <span className="h-8 w-8 rounded-full bg-seashell flex items-center justify-center ring-8 ring-white">
                            {getTransactionIcon(transaction.type)}
                          </span>
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div>
                            <p className="text-sm text-bluish-grey font-medium">
                              {getTransactionDescription(transaction)}
                            </p>
                            <p className="text-xs text-grey-goose mt-1">
                              Price: ${transaction.price.toFixed(2)}
                            </p>
                          </div>
                          <div className="text-right text-sm whitespace-nowrap text-grey-goose">
                            {format(new Date(transaction.timestamp), 'MMM d, yyyy HH:mm')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div className="text-center py-12">
            <IconAlertCircle className="mx-auto h-12 w-12 text-grey-goose" />
            <h3 className="mt-2 text-sm font-semibold text-bluish-grey">No transactions found</h3>
            <p className="mt-1 text-sm text-grey-goose">No transaction history is available for this item.</p>
          </div>
        )}
      </div>
    </div>
  );
};

// Distribution Modal Component
const DistributionModal = ({ isOpen, onClose, stockItem }) => {
  const [distribution, setDistribution] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showTransactions, setShowTransactions] = useState(false);

  useEffect(() => {
    const fetchDistribution = async () => {
      if (isOpen && stockItem) {
        try {
          setLoading(true);
          const response = await getStockItemDistribution(stockItem.id);
          setDistribution(response.message);
        } catch (error) {
          console.error('Error fetching distribution:', error);
          toast.error('Failed to load distribution data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDistribution();
  }, [isOpen, stockItem]);

  if (!isOpen) return null;

  return (
    <>
      <div 
        className="fixed inset-0 bg-black bg-opacity-20 flex items-center justify-center z-50"
        style={{ backdropFilter: 'blur(2px)' }}
      >
        <div className="bg-white rounded-xl shadow-2xl p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto transform transition-all duration-300 ease-in-out">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h2 className="text-2xl font-bold text-bluish-grey">
                Inventory Distribution
              </h2>
              <p className="text-grey-goose mt-1">
                {stockItem?.name}
              </p>
            </div>
            <button
              onClick={onClose}
              className="text-grey-goose hover:text-bluish-grey transition-colors"
            >
              <IconX size={24} />
            </button>
          </div>

          {loading ? (
            <div className="flex justify-center items-center h-40">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-sage"></div>
            </div>
          ) : distribution ? (
            <div className="space-y-6">
              <div className="bg-seashell rounded-lg p-6">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-grey-goose">Total Stock</p>
                    <p className="mt-1 text-3xl font-semibold text-hazel-green">
                      {distribution.stockItem.stockQuantity} {distribution.stockItem.unitOfMeasurement}
                    </p>
                  </div>
                  <button
                    onClick={() => setShowTransactions(true)}
                    className="px-4 py-2 bg-white text-sage hover:bg-seashell rounded-lg shadow-sm transition-colors flex items-center space-x-2"
                  >
                    <IconHistory size={20} />
                    <span>View History</span>
                  </button>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-sm border border-mercury">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-mercury">
                    <thead>
                      <tr className="bg-seashell">
                        <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Section</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Product Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Quantity</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Unit</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Last Updated</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-mercury">
                      {distribution.inventoryDistribution.map((item) => (
                        <tr
                          key={item.id}
                          className="hover:bg-seashell transition-colors"
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize
                              ${item.section === 'BAR' ? 'bg-dawn-pink text-watermelon' :
                                item.section === 'KITCHEN' ? 'bg-seashell text-hazel-green' :
                                item.section === 'POOL' ? 'bg-tealish-blue text-bluish-grey' : 'bg-mercury text-grey-goose'
                              }`}>
                              {item.section.toLowerCase()}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{item.name}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-hazel-green">{item.quantity}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-grey-goose">{item.unit}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-grey-goose">
                            {format(new Date(item.lastUpdated), 'MMM d, yyyy HH:mm')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center py-12">
              <IconAlertCircle className="mx-auto h-12 w-12 text-grey-goose" />
              <h3 className="mt-2 text-sm font-semibold text-bluish-grey">No distribution data</h3>
              <p className="mt-1 text-sm text-grey-goose">No distribution data is available for this item.</p>
            </div>
          )}
        </div>
      </div>

      <TransactionHistoryModal
        isOpen={showTransactions}
        onClose={() => setShowTransactions(false)}
        stockItem={stockItem}
      />
    </>
  );
};

// Modified Stock Card Component
const StockCard = ({ item, onDelete, onTransfer }) => {
  const [showDistribution, setShowDistribution] = useState(false);

  return (
    <div className='bg-seashell p-4 rounded-md shadow-sm'>
      <div className="flex justify-between items-start mb-2">
        <h3 className='text-xl md:text-2xl font-semibold text-bluish-grey'>{item.name}</h3>
        <button
          onClick={() => setShowDistribution(true)}
          className="text-sage hover:text-sage-dark"
          title="View Distribution"
        >
          <IconInfoCircle size={20} />
        </button>
      </div>
      
      <div className='space-y-2'>
        <p className='flex justify-between items-center text-gray-600 text-sm md:text-md'>
          <span className='font-medium'>Quantity:</span>
          <span className='font-bold'>{item.stockQuantity} units</span>
        </p>

        <p className='flex justify-between items-center text-gray-600 text-sm md:text-md'>
          <span className='font-medium'>Reorder Level:</span>
          <span className='font-bold'>{item.reorderLevel || 'Not set'} units</span>
        </p>

        <div className='flex justify-between items-center text-gray-600 text-sm md:text-md pt-2 border-t border-gray-300'>
          <span className='font-medium'>Stock After Reorder:</span>
          <span className='font-bold text-hazel-green'>
            {item.stockQuantity - (item.reorderLevel || 0)} units
          </span>
        </div>
      </div>

      <div className='flex flex-wrap gap-3 mt-3'>
        <Link to={`/stock/${item.id}`}>
          <IconPencil className='text-sage cursor-pointer' size={18} />
        </Link>
        <IconTrash
          className='text-sage cursor-pointer'
          size={18}
          onClick={() => onDelete(item.id)}
        />
        <button
          onClick={() => onTransfer(item)}
          className='text-sage text-sm'
        >
          Transfer to Loss
        </button>
      </div>

      <DistributionModal
        isOpen={showDistribution}
        onClose={() => setShowDistribution(false)}
        stockItem={item}
      />
    </div>
  );
};

function StockManagement() {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  const [selectedStockItemId, setSelectedStockItemId] = useState(''); // State for selected stock item
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showStockItemModal, setShowStockItemModal] = useState(false);
  const [newStockItem, setNewStockItem] = useState({ name: '', quantity: 0 });
  const [activeCategory, setActiveCategory] = useState(''); // New state for selected category
  const [newCategoryName, setNewCategoryName] = useState(''); // State for new category input
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [editCategoryId, setEditCategoryId] = useState(null); // State for editing category
  const [editCategoryName, setEditCategoryName] = useState(''); // State for edited category name

  // New state variables for stock losses
  const [activeLossCategory, setActiveLossCategory] = useState('');
  const [showStockLossModal, setShowStockLossModal] = useState(false);
  // const [newStockLossItem, setNewStockLossItem] = useState({ name: '', quantity: 0 });
  const [newStockLossItem, setNewStockLossItem] = useState({ quantity: 0, reason: '' });

  const [showTransferModal, setShowTransferModal] = useState(false);
  const [transferItem, setTransferItem] = useState(null);
  const [transferQuantity, setTransferQuantity] = useState(1);
  const [transferReason, setTransferReason] = useState('');

  const [stockItems, setStockItems] = useState([]); // State for stock items
  const [stockLossItems, setStockLossItems] = useState([]); // State for stock loss items
  const [filteredStockItems, setFilteredStockItems] = useState([]); // State for filtered stock items
  const [filteredStockLossItems, setFilteredStockLossItems] = useState([]); // State for filtered stock loss items

  const transferQuantityRef = useRef(1); // Use a ref for transfer quantity

  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  const [showDeleteStockLossModal, setShowDeleteStockLossModal] = useState(false);
  const [stockLossToDelete, setStockLossToDelete] = useState(null);

  const [showDistribution, setShowDistribution] = useState(false);
  const [selectedStockItemForDist, setSelectedStockItemForDist] = useState(null);

  const { data: stockCategories = [], error: categoryError, isValidating: isValidatingCategories, mutate: mutateCategories } = useSWR(
    '/api/categories',
    () => getCategories().then(res => res.data)
  );

  // Fetch stock items
  const { data: fetchedStockItems = [], error: stockError, isValidating: isValidatingStockItems, mutate: mutateStockItems } = useSWR(
    activeCategory ? `/api/stock?category=${activeCategory}` : null,
    fetcher
  );

  // Fetch stock loss items with category filter
  const { data: fetchedStockLossItems = [], error: stockLossError, isValidating: isValidatingStockLossItems, mutate: mutateStockLossItems } = useSWR(
    activeCategory ? `/api/stock-loss?category=${activeCategory}` : '/api/stock-loss',
    fetcher
  );

  // Update state when data is fetched
  useEffect(() => {
    if (fetchedStockItems) {
      setStockItems(fetchedStockItems);
    }
    if (fetchedStockLossItems) {
      setStockLossItems(fetchedStockLossItems);
      setFilteredStockLossItems(fetchedStockLossItems);
    }
  }, [fetchedStockItems, fetchedStockLossItems]);

  useEffect(() => {
    if (stockCategories.length) {
      setActiveCategory(stockCategories[0]?.id || ''); // Set the default active category
    }
  }, [stockCategories]);

  useEffect(() => {
    if (stockCategories.length) {
      setActiveLossCategory(stockCategories[0]?.id || '');
    }
  }, [stockCategories]);

  // Effect to filter stock items and stock loss items based on the selected category
  
  useEffect(() => {
    if (activeCategory) {
      const itemsInCategory = stockItems.filter(item => item.categoryId === activeCategory);
      setFilteredStockItems(itemsInCategory);

      const lossItemsInCategory = stockLossItems.filter(item => item.stockItem?.categoryId === activeCategory);
      setFilteredStockLossItems(lossItemsInCategory);
    } else {
      setFilteredStockItems(stockItems);
      setFilteredStockLossItems(stockLossItems);
    }
  }, [activeCategory, stockItems, stockLossItems]);

  const handleDeleteStockItem = async () => {
    try {
      await deleteStockItem(selectedStockItemId);
      setShowDeleteAlert(false);
      setSelectedStockItemId(null);
      mutateStockItems(); // Refresh stock items
    } catch (err) {
      console.error('Failed to delete stock item:', err);
    }
  };

  const handleAddStockItem = async () => {
    // Add logic to add a new stock item
    // Use the API for adding stock items
    setShowStockItemModal(false);
  };

  const handleEditCategory = async () => {
    try {
      if (editCategoryName.trim()) {
        await editCategory(editCategoryId, { name: editCategoryName }); // Use API to edit category
        setEditCategoryId(null); // Reset edit category id
        setEditCategoryName(''); // Reset edit category name
        mutateCategories(); // Re-fetch categories
      }
    } catch (err) {
      console.error('Failed to edit category:', err);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await deleteCategory(categoryId);
      setShowDeleteCategoryModal(false);
      setCategoryToDelete(null);
      mutateCategories(); // Re-fetch categories
      toast.success('Category deleted successfully');
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message || 'Failed to delete category';
      toast.error(errorMessage);
      console.error('Error deleting category:', error);
    }
  };

  const confirmDeleteCategory = (categoryId) => {
    setCategoryToDelete(categoryId);
    setShowDeleteCategoryModal(true);
  };

  const handleAddStockLossItem = async () => {
    try {
      if (!user || !user.id) {
        toast.error('Please log in to perform this action');
        return;
      }

      // Find an existing stock loss item with the same stockItemId and reason
      const existingStockLossItem = stockLossItems.find(
        lossItem => lossItem.stockItemId === selectedStockItemId && lossItem.reason === newStockLossItem.reason
      );

      if (existingStockLossItem) {
        // If the item with the same stockItemId and reason exists, update its quantity
        await updateStockLossQuantity(
          existingStockLossItem.id, 
          existingStockLossItem.quantity + newStockLossItem.quantity
        );
      } else {
        // If there's no existing item with the same stockItemId and reason, create a new one
        const stockLossData = {
          stockItemId: selectedStockItemId,
          quantity: parseInt(newStockLossItem.quantity),
          reason: newStockLossItem.reason,
          adminUserId: user.id
        };

        console.log('Sending stock loss data:', stockLossData);
        await addStockLossItem(stockLossData);
      }

      // Reset the modal and state after the operation
      setNewStockLossItem({ name: '', quantity: 0, reason: '' });
      setShowStockLossModal(false);
      mutateStockLossItems(); // Refresh stock loss items
      
      // Additional data refresh
      mutate('/api/stock');
      mutate('/api/stock-loss');
      
      toast.success('Stock loss recorded successfully');
    } catch (error) {
      console.error('Failed to add or update stock loss item:', error);
      console.error('Error details:', error.response?.data);
      toast.error(error.response?.data?.message || 'Failed to record stock loss');
    }
  };
  
  



  const handleTransferSubmit = async () => {
    if (!transferItem) {
        console.error('No item selected for transfer.');
        return;
    }

    if (transferQuantity > transferItem.stockQuantity) {
        toast.error('Not enough stock available for transfer.');
        return;
    }

    if (!user?.id) {
        toast.error('Please log in to perform this action');
        return;
    }

    try {
        console.log('Creating stock loss with user:', user);
        
        // Check for existing stock loss item with the same reason
        const existingItem = stockLossItems.find(
            item => item.stockItem.id === transferItem.id && item.reason === transferReason
        );

        if (existingItem) {
            // Update existing stock loss item
            await updateStockLossQuantity(existingItem.id, existingItem.quantity + transferQuantity);
        } else {
            // Create new stock loss item
            const stockLossData = {
                stockItemId: transferItem.id,
                quantity: transferQuantity,
                reason: transferReason,
                adminUserId: user.id
            };
            console.log('Sending stock loss data:', stockLossData);
            await addStockLossItem(stockLossData);
        }

        // Reset modal state
        setTransferQuantity(1);
        setTransferReason('');
        setShowTransferModal(false);

        // Refresh both stock items and stock loss items
        mutateStockLossItems();
        mutate(`/api/stock?category=${activeCategory}`);

        toast.success('Stock transferred successfully');
    } catch (error) {
        console.error('Error transferring stock to loss:', error);
        toast.error(error.response?.data?.message || 'Failed to transfer stock to loss');
    }
};

  const openTransferModal = (item) => {
    setTransferQuantity(1); // Reset quantity
    setTransferReason(''); // Reset reason
    setTransferItem(item); // Set the current item to transfer
    setShowTransferModal(true);
  };

  // Ensure transfer quantity is updated in the ref
  const handleTransferQuantityChange = (e) => {
    const value = Number(e.target.value);
    setTransferQuantity(value);
    transferQuantityRef.current = value; // Update the ref
  };

  const handleDeleteStockLoss = async () => {
    try {
      await deleteStockLossItem(stockLossToDelete.id);
      setShowDeleteStockLossModal(false);
      setStockLossToDelete(null);
      
      // Refresh the lists
      mutateStockLossItems();
      mutate(`/api/stock?category=${activeCategory}`);
      
      toast.success('Stock loss item deleted successfully');
    } catch (error) {
      console.error('Error deleting stock loss item:', error);
      toast.error(error.message || 'Error deleting stock loss item');
    }
  };

  const confirmDeleteStockLoss = (stockLossItem) => {
    setStockLossToDelete(stockLossItem);
    setShowDeleteStockLossModal(true);
  };

  if (categoryError || stockError) return <div>Error loading data...</div>;
  if (isValidatingCategories || isValidatingStockItems) return <Loading />;

  // Filter stock loss items based on the active category
  // const filteredStockLossItems = stockLossItems.filter(item => item.stockItem.categoryId === activeLossCategory);


  const handleClickStockStateButton = () => {
    navigate("/stock-state")
  }
  return (
    <div className='p-4 md:p-10'>
      {/* Title and Buttons Section */}
      <div className='flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8'>
        <h1 className='text-2xl font-bold text-hazel-green'>Stock Management</h1>
        <div className='flex flex-wrap gap-3 w-full sm:w-auto'>
          <button onClick={() => mutateStockItems()} className='flex items-center text-white rounded-md bg-sage px-4 py-2.5 text-sm md:text-base w-full sm:w-auto'>
            <IconRefresh className='mr-2' size={20} />
            Refresh
          </button>
          <button onClick={handleClickStockStateButton} className='flex items-center text-white rounded-md bg-sage px-4 py-2.5 text-sm md:text-base w-full sm:w-auto'>
            <IconBuildingWarehouse className='mr-2' size={20} />
            Stock State
          </button>
          <Link to='/stock/create-product' className='flex items-center text-white rounded-md bg-sage px-4 py-2.5 text-sm md:text-base w-full sm:w-auto'>
            <IconPlus className='mr-2' size={20} />
            Add Stock Item
          </Link>

        </div>
      </div>

      {/* Category Tabs Section */}
      <div className='border border-gray-200 rounded-xl shadow-lg px-2 md:px-3 mt-5'>
        <div className='flex flex-wrap md:flex-nowrap items-center justify-between w-full border-b border-gray-200 overflow-x-auto py-2'>
          <div className='flex flex-wrap md:flex-nowrap items-center gap-2 w-full md:w-auto'>
            {stockCategories.map(category => (
              <div
                key={category.id}
                className='relative flex-shrink-0'
                onMouseEnter={() => setHoveredCategory(category.id)}
                onMouseLeave={() => setHoveredCategory(null)}
              >
                <div
                  className={`relative flex items-center px-5 py-3.5 whitespace-nowrap ${category.id === activeCategory ? 'bg-seashell bg-opacity-30 rounded-md' : ''} cursor-pointer`}
                  onClick={() => setActiveCategory(category.id)}
                >
                  <p className='text-bluish-grey capitalize ml-2'>{category.name}</p>
                  {hoveredCategory === category.id && (
                    <div className='absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 rounded-md'>
                      <div className='flex gap-x-2'>
                        <button
                          onClick={() => {
                            setEditCategoryId(category.id);
                            setEditCategoryName(category.name);
                          }}
                          className='text-bluish-grey'
                        >
                          <IconPencil size={20} />
                        </button>
                        <button
                          onClick={() => confirmDeleteCategory(category.id)}
                          className='text-bluish-grey'
                        >
                          <IconTrash size={20} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <Link
            to='/stock/new-category'
            className='flex items-center text-white rounded-md bg-sage px-4 py-2.5 mt-2 md:mt-0 w-full md:w-auto justify-center'
          >
            <IconPlus className='mr-2' size={20} />
            Add Category
          </Link>
        </div>

        {/* Stock Items Grid */}
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-4 px-2 md:px-3'>
          {filteredStockItems.length ? (
            filteredStockItems.map((item) => (
              <div key={item.id} className='bg-seashell p-4 rounded-md shadow-sm'>
                <div className="flex justify-between items-start mb-2">
                  <h3 className='text-xl md:text-2xl font-semibold text-bluish-grey'>{item.name}</h3>
                  <button
                    onClick={() => {
                      setSelectedStockItemForDist(item);
                      setShowDistribution(true);
                    }}
                    className="text-sage hover:text-sage-dark"
                    title="View Distribution"
                  >
                    <IconInfoCircle size={20} />
                  </button>
                </div>
                
                <div className='space-y-2'>
                  <p className='flex justify-between items-center text-gray-600 text-sm md:text-md'>
                    <span className='font-medium'>Quantity:</span>
                    <span className='font-bold'>{item.stockQuantity} units</span>
                  </p>

                  <p className='flex justify-between items-center text-gray-600 text-sm md:text-md'>
                    <span className='font-medium'>Reorder Level:</span>
                    <span className='font-bold'>{item.reorderLevel || 'Not set'} units</span>
                  </p>

                  <div className='flex justify-between items-center text-gray-600 text-sm md:text-md pt-2 border-t border-gray-300'>
                    <span className='font-medium'>Stock After Reorder:</span>
                    <span className='font-bold text-hazel-green'>
                      {item.stockQuantity - (item.reorderLevel || 0)} units
                    </span>
                  </div>
                </div>

                <div className='flex flex-wrap gap-3 mt-3'>
                  <Link to={`/stock/${item.id}`}>
                    <IconPencil className='text-sage cursor-pointer' size={18} />
                  </Link>
                  <IconTrash
                    className='text-sage cursor-pointer'
                    size={18}
                    onClick={() => {
                      setSelectedStockItemId(item.id);
                      setShowDeleteAlert(true);
                    }}
                  />
                  <button
                    onClick={() => openTransferModal(item)}
                    className='text-sage text-sm'
                  >
                    Transfer to Loss
                  </button>
                </div>

                <DistributionModal
                  isOpen={showDistribution}
                  onClose={() => {
                    setShowDistribution(false);
                    setSelectedStockItemForDist(null);
                  }}
                  stockItem={selectedStockItemForDist}
                />
              </div>
            ))
          ) : (
            <p className='text-sage font-bold py-10 px-8 text-center col-span-full'>
              No stock items available for this category
            </p>
          )}
        </div>
      </div>

      {/* Stock Loss Section */}
      <div className='mt-8 md:mt-16'>
        <div className='flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8'>
          <h2 className='text-xl md:text-2xl font-bold text-hazel-green'>Stock Loss Management</h2>
          <button
            onClick={() => setShowStockLossModal(true)}
            className='flex items-center text-white rounded-md bg-sage px-4 py-2.5 w-full sm:w-auto justify-center'
          >
            <IconPlus className='mr-2' size={20} />
            Add Stock Loss Item
          </button>
        </div>

        {/* Stock Loss Items Grid */}
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-4 px-2 md:px-3'>
          {filteredStockLossItems.length ? (
            filteredStockLossItems.map((item) => (
              <div key={item.id} className='bg-dawn-pink p-4 md:p-6 rounded-lg shadow-md'>
                <div className='flex justify-between items-start mb-4'>
                  <h3 className='text-xl font-semibold text-bluish-grey'>{item.stockItem.name}</h3>
                  <button
                    onClick={() => confirmDeleteStockLoss(item)}
                    className='text-sage hover:text-red-600 transition-colors'
                    aria-label="Delete stock loss item"
                  >
                    <IconTrash size={20} />
                  </button>
                </div>
                <div className='space-y-2'>
                  <p className='flex justify-between items-center text-gray-600'>
                    <span className='font-medium'>Quantity Lost:</span>
                    <span className='font-bold'>{item.quantity} units</span>
                  </p>
                  <p className='flex justify-between items-center text-gray-600'>
                    <span className='font-medium'>Reason:</span>
                    <span className='font-bold'>{item.reason}</span>
                  </p>
                  <p className='flex justify-between items-center text-gray-600'>
                    <span className='font-medium'>Date:</span>
                    <span className='font-bold'>{new Date(item.createdAt).toLocaleDateString()}</span>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className='text-sage font-bold py-10 px-8 text-center col-span-full'>
              No stock loss items available
            </p>
          )}
        </div>
      </div>

      {/* Modal for Adding Stock Item */}
      {showStockItemModal && (
        <Modal onClose={() => setShowStockItemModal(false)}>
          <div>
            <h2 className='text-lg font-bold mb-4'>Create New Purchase Order</h2>
            <input
              type='text'
              placeholder='Stock Item Name'
              value={newStockItem.name}
              onChange={(e) => setNewStockItem({ ...newStockItem, name: e.target.value })}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <input
              type='number'
              placeholder='Quantity'
              value={newStockItem.quantity}
              onChange={(e) => setNewStockItem({ ...newStockItem, quantity: Number(e.target.value) })}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <button
              onClick={handleAddStockItem}
              className='bg-sage text-white py-2 px-4 rounded-md'
            >
              Add Stock Item
            </button>
          </div>
        </Modal>
      )}

      {/* Modal for Editing Category */}
      {editCategoryId && (
        <Modal onClose={() => setEditCategoryId(null)}>
          <div>
            <h2 className='text-lg font-bold mb-4'>Edit Category</h2>
            <input
              type='text'
              value={editCategoryName}
              onChange={(e) => setEditCategoryName(e.target.value)}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <button
              onClick={handleEditCategory}
              className='bg-sage text-white py-2 px-4 rounded-md'
            >
              Save Changes
            </button>
          </div>
        </Modal>
      )}

      {/* Modal for Adding Stock Loss Item */}
      {showStockLossModal && (
        <Modal onClose={() => setShowStockLossModal(false)}>
          <div>
            <h2 className='text-lg font-bold mb-4'>Add Stock Loss Item</h2>
            
            <select
              value={activeLossCategory}
              onChange={(e) => {
                setActiveLossCategory(e.target.value);
                setSelectedStockItemId(''); // Reset selected stock item when category changes
              }}
              className='border border-gray-300 p-2 mb-4 w-full'
            >
              <option value="">Select Category</option>
              {stockCategories.map(category => (
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>
            
            <select
              value={selectedStockItemId}
              onChange={(e) => setSelectedStockItemId(e.target.value)}
              className='border border-gray-300 p-2 mb-4 w-full'
            >
              <option value="">Select Stock Item</option>
              {/* Filter items based on the activeLossCategory */}
              {filteredStockItems
                .filter(item => item.categoryId === activeLossCategory) // Filter items by category
                .map(item => (
                  <option key={item.id} value={item.id}>{item.name}</option>
                ))}
            </select>
            
            <input
              type='number'
              placeholder='Quantity Lost'
              value={newStockLossItem.quantity}
              onChange={(e) => setNewStockLossItem({ ...newStockLossItem, quantity: Number(e.target.value) })}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            
            {/* New input field for reason */}
            <input
              type='text'
              placeholder='Reason for Loss'
              value={newStockLossItem.reason}
              onChange={(e) => setNewStockLossItem({ ...newStockLossItem, reason: e.target.value })}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            
            <button
              onClick={handleAddStockLossItem}
              className='bg-sage text-white py-2 px-4 rounded-md'
            >
              Add Stock Loss Item
            </button>
          </div>
        </Modal>
      )}


      {showDeleteAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this stock item?'
          onCancel={() => setShowDeleteAlert(false)}
          onConfirm={handleDeleteStockItem}
        />
      )}

      {showTransferModal && (
        <Modal onClose={() => setShowTransferModal(false)}>
          <div>
            <h2 className='text-lg font-bold mb-4'>Transfer to Stock Loss</h2>
            <input
              type='number'
              placeholder='Quantity'
              value={transferQuantity}
              onChange={handleTransferQuantityChange} // Use the new handler
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <input
              type='text'
              placeholder='Reason for Loss'
              value={transferReason}
              onChange={(e) => setTransferReason(e.target.value)}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <button
              onClick={handleTransferSubmit}
              className='bg-sage text-white py-2 px-4 rounded-md'
            >
              Submit Transfer
            </button>
          </div>
        </Modal>
      )}

      {/* Delete Category Modal */}
      {showDeleteCategoryModal && (
        <Modal
          title="Delete Category"
          onClose={() => {
            setShowDeleteCategoryModal(false);
            setCategoryToDelete(null);
          }}
        >
          <div className="p-4">
            <p className="text-bluish-grey text-sm mb-3">Delete this category and all its associated items?</p>
            <div className="flex justify-end space-x-2">
              <button
                className="px-3 py-1 text-sm bg-mercury text-bluish-grey rounded hover:bg-grey-goose transition-colors"
                onClick={() => {
                  setShowDeleteCategoryModal(false);
                  setCategoryToDelete(null);
                }}
              >
                Cancel
              </button>
              <button
                className="px-3 py-1 text-sm bg-watermelon text-white rounded hover:bg-salmon-pink transition-colors"
                onClick={() => handleDeleteCategory(categoryToDelete)}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* Delete Stock Loss Confirmation Modal */}
      {showDeleteStockLossModal && stockLossToDelete && (
        <Modal
          isOpen={showDeleteStockLossModal}
          onClose={() => {
            setShowDeleteStockLossModal(false);
            setStockLossToDelete(null);
          }}
          className="max-w-md mx-auto"
        >
          <div className='bg-white rounded-lg shadow-lg overflow-hidden'>
            <div className='p-4 bg-sage text-white'>
              <h2 className='text-lg font-semibold'>Confirm Delete</h2>
            </div>
            <div className='p-4'>
              <p className='text-gray-600 text-sm'>
                Delete stock loss record for <span className='font-semibold'>{stockLossToDelete.stockItem.name}</span>?
                <br />
                <span className='text-xs mt-1 block text-gray-500'>
                  This will restore {stockLossToDelete.quantity} units to stock.
                </span>
              </p>
              <div className='flex justify-end gap-2 mt-4'>
                <button
                  onClick={() => {
                    setShowDeleteStockLossModal(false);
                    setStockLossToDelete(null);
                  }}
                  className='px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800 transition-colors'
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeleteStockLoss}
                  className='px-3 py-1.5 text-sm bg-red-500 text-white rounded hover:bg-red-600 transition-colors'
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      
      {/* Add the Distribution Modal */}
      <DistributionModal
        isOpen={showDistribution}
        onClose={() => {
          setShowDistribution(false);
          setSelectedStockItemForDist(null);
        }}
        stockItem={selectedStockItemForDist}
      />
    </div>
  );
}

export default StockManagement;