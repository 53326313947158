import React from 'react';
import { IconCalendarMonth } from '@tabler/icons-react';
import PageTitle from '../../../components/page-title';

import VenueReservationForm from './VenueReservationForm';

function AddVenueReservation() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconCalendarMonth className='text-hazel-green mr-2' size={22} />}
        title='Add new Weeding Venue reservation'
      />
      <VenueReservationForm isEdit={false}/>
    </div>
  );
}

export default AddVenueReservation;
