// finances.js
import api from "./api";

// --- Budgets Endpoints ---

// Fetch all budgets
export const getBudgets = () => api.get('/api/finances/budgets');

// Fetch a single budget by ID
export const getBudgetById = (id) => api.get(`/api/finances/budgets/${id}`);

// Create a new budget
export const createBudget = (values) => api.post('/api/finances/budgets', values);

// Update a budget by ID
export const updateBudget = (id, values) => api.put(`/api/finances/budgets/${id}`, values);

// Delete a budget by ID
export const deleteBudget = (id) => api.delete(`/api/finances/budgets/${id}`);

// --- Transactions Endpoints ---

// Fetch all transactions
export const getTransactions = () => api.get('/api/finances/transactions');

// Fetch a single transaction by ID
export const getTransactionById = (id) => api.get(`/api/finances/transactions/${id}`);

// Create a new transaction
export const createTransaction = (values) => api.post('/api/finances/transactions', values);

// Update a transaction by ID
export const updateTransaction = (id, values) => api.put(`/api/finances/transactions/${id}`, values);

// Delete a transaction by ID
export const deleteTransaction = (id) => api.delete(`/api/finances/transactions/${id}`);

// --- Expenses Endpoints ---

// Fetch all expenses
export const getExpenses = () => api.get('/api/finances/expenses');

// Create a new expense
export const createExpense = (values) => api.post('/api/finances/expenses', values);

// Update an expense by ID
export const updateExpense = (id, values) => api.put(`/api/finances/expenses/${id}`, values);

// Delete an expense by ID
export const deleteExpense = (id) => api.delete(`/api/finances/expenses/${id}`);

// --- Payroll Endpoints ---

// Get all payrolls with pagination and search
export const getPayrolls = (rowsPerPage, currentPage, searchText) => {
  const params = new URLSearchParams();
  params.append('rowsPerPage', rowsPerPage);
  params.append('currentPage', currentPage);
  if (searchText) {
    params.append('searchText', searchText);
  }
  
  return api.get(`/api/finances/payroll?${params}`);
};

// Get payroll by ID
export const getPayrollById = (id) => api.get(`/api/finances/payroll/${id}`);

// export const createPayroll = ({ employeeId, bonus, deductions }) => 
//   api.post('/api/finances/payroll', { employeeId, bonus, deductions });


export const createPayroll = ({ employeeId, bonus, deductions, payPeriod }) => 
  api.post('/api/finances/payroll', { employeeId, bonus, deductions, payPeriod });

// Update payroll by ID
export const updatePayroll = (id, { bonus, deductions, payPeriod }) => 
  api.put(`/api/finances/payroll/${id}`, { bonus, deductions, payPeriod });


// Delete payroll by ID
export const deletePayroll = (id) => api.delete(`/api/finances/payroll/${id}`);

export const getPayPeriods = () => {
  return api.get('/api/finances/payperiods'); // This should work now
};

export const updatePayrollStatus = (id, status) => 
  api.put(`/api/finances/payroll/${id}/status`, { status });

export const processPayrollPayment = (id, { employeeId, amountPaid }) => 
  api.post(`/api/finances/payroll/${id}/pay`, { employeeId, amountPaid });

// Get all payroll tracking records with pagination
export const getPayrollTracking = (rowsPerPage, currentPage) => {
  const params = new URLSearchParams();
  params.append('rowsPerPage', rowsPerPage);
  params.append('currentPage', currentPage);

  return api.get(`/api/finances/payroll-tracking?${params}`);
};

// --- Profit and Loss Endpoints ---

// Fetch profit and loss report
export const getProfitLossReport = () => api.get('/api/finances/profit-loss');

// --- Cash Flow Endpoints ---

// Fetch cash flow statement
export const getCashFlowStatement = () => api.get('/api/finances/cash-flow');

// --- Income Statement Endpoints ---

// Fetch income statement report
export const getIncomeStatement = () => api.get('/api/finances/income-statement');

// --- Tax Management Endpoints ---

// Fetch all tax records
export const getTaxRecords = () => api.get('/api/finances/tax');

// Fetch a single tax record by ID
export const getTaxRecordById = (id) => api.get(`/api/finances/tax/${id}`);

// Create a new tax record
export const createTaxRecord = (values) => api.post('/api/finances/tax', values);

// Update a tax record by ID
export const updateTaxRecord = (id, values) => api.put(`/api/finances/tax/${id}`, values);

// Delete a tax record by ID
export const deleteTaxRecord = (id) => api.delete(`/api/finances/tax/${id}`);

// --- Loans Endpoints ---

// Fetch all loans
export const getLoans = () => api.get('/api/finances/loans');

// Fetch a single loan by ID
export const getLoanById = (id) => api.get(`/api/finances/loans/${id}`);

// Create a new loan
export const createLoan = (values) => api.post('/api/finances/loans', values);

// Update a loan by ID
export const updateLoan = (id, values) => api.put(`/api/finances/loans/${id}`, values);

// Delete a loan by ID
export const deleteLoan = (id) => api.delete(`/api/finances/loans/${id}`);

// --- Financial Report Endpoints ---

// Generate a financial report
export const generateFinancialReport = (startDate, endDate) => {
  return api.get(`/api/finances/financial-report?startDate=${startDate}&endDate=${endDate}`);
};
