import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Save, RotateCw, Percent, CreditCard, AlertCircle, ChevronDown } from 'lucide-react';
import { createVatRate } from '../../api/accountingArticle';
import { getAllAccounts } from '../../api/accountingPlan';
import { toast } from 'react-hot-toast';

interface VatRateFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

interface Account {
  id: string;
  code: string;
  name: string;
}

const VatRateFormModal: React.FC<VatRateFormModalProps> = ({ isOpen, onClose, onSuccess }) => {
  const [formData, setFormData] = useState({
    emri: '',
    perqindja: '',
    kontoTvshLlogaritur: '',
    kontoTvshZbritshme: ''
  });

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await getAllAccounts();
        setAccounts(response.data.message);
      } catch (err) {
        console.error('Failed to fetch accounts:', err);
        setError('Failed to load accounts');
      }
    };

    if (isOpen) {
      fetchAccounts();
    }
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const vatRateData = {
        name: formData.emri,
        percentage: parseFloat(formData.perqindja),
        purchaseVatAccountId: formData.kontoTvshZbritshme,  
        salesVatAccountId: formData.kontoTvshLlogaritur,        
      };

      await createVatRate(vatRateData);
      toast.success('Norma e TVSh-së u krijua me sukses');
      onSuccess?.();
      onClose();
    } catch (err: any) {
      console.error('Failed to create VAT rate:', err);
      setError(err.response?.data?.message || 'Dështoi krijimi i normës së TVSh-së');
      toast.error('Dështoi krijimi i normës së TVSh-së');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-bluish-grey/60 backdrop-blur-sm z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
          <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{ type: "spring", duration: 0.5 }}
              className="bg-white rounded-2xl shadow-2xl w-full max-w-4xl overflow-hidden border border-grey-goose/20"
            >
              <div className="relative px-8 py-6 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <h2 className="text-2xl font-semibold text-bluish-grey">Shto një Normë të TVSh-së</h2>
                    <span className="px-2 py-1 text-xs bg-hazel-green/10 text-hazel-green rounded-full">
                      Normë e re
                    </span>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.05, rotate: 90 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onClose}
                    className="p-2 text-grey-goose hover:text-bluish-grey hover:bg-seashell rounded-xl transition-all duration-200"
                  >
                    <X className="w-5 h-5" />
                  </motion.button>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="px-8 py-6 space-y-6">
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-seashell/50 border border-hazel-green/20 rounded-xl p-4 flex gap-3 items-center"
                  >
                    <AlertCircle className="w-5 h-5 text-hazel-green flex-shrink-0" />
                    <p className="text-sm text-bluish-grey">
                      Nëse jeni biznes i regjistruar për TVSh, i shtoni normat standarte.
                      Nëse nuk jeni biznes me TVSh, atëherë operoni me normën 0%.
                    </p>
                  </motion.div>

                  <div className="grid grid-cols-2 gap-6">
                    <motion.div
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.1 }}
                      className="space-y-2"
                    >
                      <label className="block text-sm font-medium text-bluish-grey">
                        Emri
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                          <Percent className="w-5 h-5 text-grey-goose" />
                        </div>
                        <input
                          type="text"
                          placeholder="Emri i normës së TVSh-së"
                          className="w-full pl-12 pr-4 py-3.5 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50 placeholder:text-grey-goose/60"
                          value={formData.emri}
                          onChange={(e) => setFormData({ ...formData, emri: e.target.value })}
                          required
                        />
                      </div>
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, x: 10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.2 }}
                      className="space-y-2"
                    >
                      <label className="block text-sm font-medium text-bluish-grey">
                        Përqindja
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                          <Percent className="w-5 h-5 text-grey-goose" />
                        </div>
                        <input
                          type="number"
                          min="0"
                          max="100"
                          step="0.01"
                          placeholder="Përqindja e normës së TVSh-së"
                          className="w-full pl-12 pr-4 py-3.5 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50 placeholder:text-grey-goose/60"
                          value={formData.perqindja}
                          onChange={(e) => setFormData({ ...formData, perqindja: e.target.value })}
                          required
                        />
                      </div>
                    </motion.div>
                  </div>

                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                    className="bg-gradient-to-br from-seashell to-white rounded-2xl p-6 border border-grey-goose/20 shadow-sm"
                  >
                    <div className="space-y-6">
                      <div className="flex items-center gap-2">
                        <h3 className="text-lg font-medium text-bluish-grey">Kontot e TVSh-së</h3>
                        <span className="px-2 py-1 text-xs font-medium bg-hazel-green/10 text-hazel-green rounded-full">
                          Rekomanduar
                        </span>
                      </div>

                      <div className="grid grid-cols-2 gap-6">
                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-bluish-grey">
                            TVSH e llogaritur (në shitje)
                          </label>
                          <div className="relative">
                            <select
                              className="w-full px-4 py-3.5 pl-12 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50 appearance-none cursor-pointer text-bluish-grey"
                              value={formData.kontoTvshLlogaritur}
                              onChange={(e) => setFormData({ ...formData, kontoTvshLlogaritur: e.target.value })}
                              required
                            >
                              <option value="">Zgjedhni konton për TVSh të llogaritur</option>
                              {accounts.map(account => (
                                <option key={account.id} value={account.id}>
                                  {account.code} - {account.name}
                                </option>
                              ))}
                            </select>
                            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                              <CreditCard className="w-5 h-5 text-grey-goose" />
                            </div>
                            <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                              <ChevronDown className="w-5 h-5 text-grey-goose" />
                            </div>
                          </div>
                          <p className="text-xs text-grey-goose">Detyrim afatshkurtër</p>
                        </div>

                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-bluish-grey">
                            TVSH e zbritshme (në blerje)
                          </label>
                          <div className="relative">
                            <select
                              className="w-full px-4 py-3.5 pl-12 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50 appearance-none cursor-pointer text-bluish-grey"
                              value={formData.kontoTvshZbritshme}
                              onChange={(e) => setFormData({ ...formData, kontoTvshZbritshme: e.target.value })}
                              required
                            >
                              <option value="">Zgjedhni konton për TVSh të zbritshme</option>
                              {accounts.map(account => (
                                <option key={account.id} value={account.id}>
                                  {account.code} - {account.name}
                                </option>
                              ))}
                            </select>
                            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                              <CreditCard className="w-5 h-5 text-grey-goose" />
                            </div>
                            <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                              <ChevronDown className="w-5 h-5 text-grey-goose" />
                            </div>
                          </div>
                          <p className="text-xs text-grey-goose">Pasuri afatshkurtër</p>
                        </div>
                      </div>
                    </div>
                  </motion.div>

                  {error && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="bg-red-50 border border-red-200 rounded-xl p-4 flex gap-3 items-center"
                    >
                      <AlertCircle className="w-5 h-5 text-red-500 flex-shrink-0" />
                      <p className="text-sm text-red-600">{error}</p>
                    </motion.div>
                  )}
                </div>

                <div className="px-8 py-6 bg-gradient-to-r from-white to-seashell border-t border-grey-goose/20">
                  <div className="flex justify-end gap-4">
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      type="button"
                      onClick={onClose}
                      className="px-6 py-3 text-bluish-grey hover:bg-seashell rounded-xl transition-all duration-200 font-medium"
                    >
                      Anulo
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      type="submit"
                      disabled={isLoading}
                      className="px-6 py-3 bg-hazel-green text-white rounded-xl hover:bg-hazel-green/90 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 font-medium shadow-lg shadow-hazel-green/20"
                    >
                      {isLoading ? (
                        <>
                          <RotateCw className="w-5 h-5 animate-spin" />
                          Duke ruajtur...
                        </>
                      ) : (
                        <>
                          <Save className="w-5 h-5" />
                          Ruaj Normën e TVSh-së
                        </>
                      )}
                    </motion.button>
                  </div>
                </div>
              </form>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  );
};

export default VatRateFormModal;
