//correct code

// import React from 'react';
// import DishCard from '../views/restaurantInterface/DishCard';
// import { useDrop } from 'react-dnd';

// const Sidebar = ({ show, onClose, dishes, onDishDrop }) => {
//   const [cards, setCards] = React.useState(dishes);

//   const [, drop] = useDrop({
//     accept: "dish",
//     drop: (item) => {
//       const newCards = [...cards, item];
//       setCards(newCards);
//       onDishDrop(newCards);
//     },
//   });

//   const handleDelete = (id) => {
//     const updatedCards = cards.filter((dish) => dish.id !== id);
//     setCards(updatedCards);
//     onDishDrop(updatedCards);
//   };

//   return (
//     <div className={`fixed top-0 right-0 w-64 h-full bg-white shadow-lg transform ${show ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}>
//       <div className="flex justify-between items-center p-4 border-b">
//         <h2 className="text-lg font-bold">Order Products</h2>
//         <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
//           <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
//             <path stroke="none" d="M0 0h24v24H0z" />
//             <line x1={18} y1={6} x2={6} y2={18} />
//             <line x1={6} y1={6} x2={18} y2={18} />
//           </svg>
//         </button>
//       </div>
//       <div ref={drop} className="overflow-y-auto h-[calc(100%-56px)] p-4">

//         {cards.map((dish, index) => (
//           <div key={dish.id}>
            
//             <button
//               onClick={() => handleDelete(dish.id)}
//               className="text-md font-semibold mt-1 mr-1 text-gray-600 hover:text-red-600"
//             >
//               Remove Dish
//             </button>
//             <DishCard
//               name={dish.name}
//               imageUrl={dish.imageUrl}
//               description={dish.description}
//               price={dish.price}
//             />

//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Sidebar;

// import React, { useEffect, useState } from 'react';
// import { useDrop } from 'react-dnd';
// import OrderAccordion from './OrderAccordion';
// import { getUnpaidOrders } from '../api/restaurantInterface';
// import { updateOrderStatus } from '../api/restaurantInterface';
// import { getAllDishes } from '../api/restaurantInterface';

// const Sidebar = ({ show, onClose }) => {
//   const [orders, setOrders] = useState([]);
//   const [allDishes, setAllDishes] = useState([]); // For all available dishes to drag

//   useEffect(() => {
//     // Fetch unpaid orders and dishes on mount
//     const fetchOrders = async () => {
//       const response = await getUnpaidOrders();
//       setOrders(response.data);
//     };

//     const fetchDishes = async () => {
//       const response = await getAllDishes(); // Assuming this API function exists
//       setAllDishes(response.data);
//     };

//     fetchOrders();
//     fetchDishes();
//   }, []);

//   const handleDishDrop = (orderId, dish, dishIdToRemove) => {
//     setOrders((prevOrders) =>
//       prevOrders.map((order) => {
//         if (order.id === orderId) {
//           if (dish) {
//             return { ...order, dishes: [...order.dishes, dish] };
//           } else {
//             return { ...order, dishes: order.dishes.filter((d) => d.uniqueId !== dishIdToRemove) };
//           }
//         }
//         return order;
//       })
//     );
//   };

//   const handlePay = async (orderId) => {
//     await updateOrderStatus(orderId, 'paid');
//     setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));
//   };

//   return (
//     <div className={`fixed top-0 right-0 w-64 h-full bg-white shadow-lg transform ${show ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}>
//       <div className="flex justify-between items-center p-4 border-b">
//         <h2 className="text-lg font-bold">Orders</h2>
//         <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
//           <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
//             <path stroke="none" d="M0 0h24v24H0z" />
//             <line x1={18} y1={6} x2={6} y2={18} />
//             <line x1={6} y1={6} x2={18} y2={18} />
//           </svg>
//         </button>
//       </div>
//       <div className="overflow-y-auto h-[calc(100%-56px)] p-4">
//         {orders.map((order) => (
//           <OrderAccordion
//             key={order.id}
//             order={order}
//             onDishDrop={handleDishDrop}
//             onPay={handlePay}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Sidebar;


import React, { useEffect, useState } from 'react';
import OrderAccordion from './OrderAccordion';
import { getUnpaidOrders, updateOrderStatus, getAllDishes } from '../api/restaurantInterface';

const Sidebar = ({ show, onClose }) => {
  const [orders, setOrders] = useState([]);
  const [allDishes, setAllDishes] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await getUnpaidOrders();
      const ordersWithDishes = response.data.map(order => ({
        ...order,
        dishes: order.dishes || [],
      }));
      setOrders(ordersWithDishes);
    };

    const fetchDishes = async () => {
      const response = await getAllDishes();
      setAllDishes(response.data);
    };

    fetchOrders();
    fetchDishes();
  }, []);

  const handleDishDrop = (orderId, dish, dishIdToRemove) => {
    setOrders(prevOrders =>
      prevOrders.map(order => {
        if (order.id === orderId) {
          if (dish) {
            return { ...order, dishes: [...order.dishes, dish] };
          } else {
            return { ...order, dishes: order.dishes.filter(d => d.uniqueId !== dishIdToRemove) };
          }
        }
        return order;
      })
    );
  };

  const handlePay = async orderId => {
    await updateOrderStatus(orderId, 'paid');
    setOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
  };

  return (
    <div className={`fixed top-0 right-0 w-64 h-full bg-white shadow-lg transform ${show ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}>
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-bold">Orders</h2>
        <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1={18} y1={6} x2={6} y2={18} />
            <line x1={6} y1={6} x2={18} y2={18} />
          </svg>
        </button>
      </div>
      <div className="overflow-y-auto h-[calc(100%-56px)] p-4">
        {orders.map(order => (
          <OrderAccordion
            key={order.id}
            order={order}
            onDishDrop={handleDishDrop}
            onPay={handlePay}
          />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;


