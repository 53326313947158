import api from './api';

export const getAllTutorials = () => 
  api.get('/api/tutorials');

export const getTutorialById = (id) =>
  api.get(`/api/tutorials/${id}`);

export const createTutorial = (data) =>
  api.post('/api/tutorials', data);

export const updateTutorial = (id, data) =>
  api.put(`/api/tutorials/${id}`, data);

export const deleteTutorial = (id) =>
  api.delete(`/api/tutorials/${id}`); 