import React, { useState, useEffect, useCallback } from 'react';
import { AddButton } from '../../../../../components/buttons';
import CustomTable from '../../../../../components/table-components/table';
import Loading from '../../../../../components/loader';
import { getSuppliers, deleteSupplier } from '../../../../../api/accounting';

import { useNavigate } from 'react-router-dom';

function SuppliersList() {
  const [suppliersList, setSuppliersList] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  // Fetch the list of suppliers from the API
  const getSuppliersList = useCallback(async () => {
    try {
      const response = await getSuppliers();
      setSuppliersList(response.data);  // Assuming the response contains the list of suppliers
      setTotalCount(response.data.length); // Assuming it returns total count
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, []);

  // Fetch suppliers on initial render and when dependencies change
  useEffect(() => {
    getSuppliersList();
  }, [getSuppliersList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  // Handle supplier deletion
  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await deleteSupplier(id);
      setSuppliersList((prevState) => prevState.filter((supplier) => supplier.id !== id));
      setTotalCount(totalCount - 1);
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  const handleNameClick = (id) => {
    navigate(`/accounting/details/supplier/${id}`); // Redirect to supplier details page
  };

  return (
    <div className='p-10'>
      {/* Add Supplier Button */}
      <AddButton entity='suppliers' link='/accounting/suppliers/add-supplier' title='New Supplier' />

      {/* Suppliers Table */}
      <CustomTable
        rows={suppliersList}
        columns={[
          {
            title: 'Business Name',
            renderValue: (item) => (
              <span onClick={() => handleNameClick(item.id)} className="text-hazel-green cursor-pointer">
                {item.businessName}
              </span>
            ),
          },
          {
            title: 'Trade Name',
            renderValue: (item) => item.tradeName,
          },
          {
            title: 'Business Type',
            renderValue: (item) => item.businessType,
          },
          {
            title: 'UBIN',
            renderValue: (item) => item.ubin,
          },
          {
            title: 'Municipality',
            renderValue: (item) => item.municipality,
          },
          {
            title: 'Address',
            renderValue: (item) => item.address,
          },
          {
            title: 'Email',
            renderValue: (item) => item.email,
          },
          {
            title: 'Phone',
            renderValue: (item) => item.phone,
          },
          {
            title: 'Representative',
            renderValue: (item) => item.representative,
          },
        ]}
        entity='suppliers'
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={true}
        editLink='/suppliers'
        onDelete={handleDelete}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default SuppliersList;
