import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IconDoor } from '@tabler/icons-react';
import PageTitle from '../../components/page-title';
import ConferenceRoomForm from './ConferenceRoomForm';
import {getConferenceRoom as getConferenceRoomById} from '../../api/conferenceRoom';

function AddConferenceRoomForm() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [conferenceRoomData, setConferenceRoomData] = useState(null);
  const isEdit = Boolean(id);

  useEffect(() => {
    if (isEdit) {
      const fetchConferenceRoomData = async () => {
        try {
          const response = await getConferenceRoomById(id);
          setConferenceRoomData(response.data);
        } catch (error) {
          console.error('Failed to fetch conference room data:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchConferenceRoomData();
    } else {
      setLoading(false);
    }
  }, [id, isEdit]);

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconDoor className='text-hazel-green mr-2' size={22} />}
        title={isEdit ? 'Edit Conference Room' : 'Add Conference Room'}
      />
      {!loading && (
        <ConferenceRoomForm
          isEdit={isEdit}
          conferenceRoomData={conferenceRoomData}
        />
      )}
    </div>
  );
}

export default AddConferenceRoomForm;