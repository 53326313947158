import React from 'react';
import { Tab } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function CustomTabs({ tabs, children }) {
  return (
    <Tab.Group>
      <Tab.List className="flex border-b border-grey-goose">
        {tabs.map((tab) => (
          <Tab
            key={tab}
            className={({ selected }) =>
              classNames(
                'py-3 px-6 text-sm font-medium leading-5 focus:outline-none',
                'border-b-2 -mb-[1px]',
                selected
                  ? 'text-hazel-green border-hazel-green'
                  : 'text-sage border-transparent hover:text-hazel-green hover:border-grey-goose'
              )
            }
          >
            {tab}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="mt-4">
        {children}
      </Tab.Panels>
    </Tab.Group>
  );
}

export function CustomTabPanel({ children }) {
  return (
    <Tab.Panel className="focus:outline-none">
      {children}
    </Tab.Panel>
  );
}
