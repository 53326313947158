import React from "react";

import ReportsCenter from "../../../../../components/reportsComponents/ReportsCenter";

export default function ReportsPage() {

    return (
        <div className="bg-gray-50 min-h-screen">
            <ReportsCenter />
        </div>
    );
}