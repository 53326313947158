import React from "react";

import Header from "../common/Header";
import Footer from "../common/Footer";

import EditRoomType from "../home/EditRoom";

function EditRoomPage() {
    return (
        <>
            <Header />
            <div className="my-24">
                <EditRoomType />
            </div>
            <Footer />
        </>
    );
}

export default EditRoomPage;