import React, { useState, useEffect, useCallback } from 'react';
import {
  getFolders,
  getDocuments,
  createFolder,
  createDocument,
  deleteFolder,
  deleteDocument,
  generateDocument,
  generateBulkDocuments,
  cleanupDuplicateDocuments,
  updateDocumentFolder,
  uploadDocument,
} from '../../../api/document';
import { Document, DocumentStatus, DocumentType } from '../../../types/document.ts';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../../../components/shadCN/Button.tsx';
import { 
  Folder, 
  FileText, 
  LayoutGrid, 
  List, 
  Plus, 
  Upload, 
  Table, 
  FileUp,
  X,
  Check 
} from 'lucide-react';
import { IconFolderFilled, IconFileText } from '@tabler/icons-react';
import Modal from '../../../components/modal';
import toast, { Toaster } from 'react-hot-toast';
import DocumentPreview from './DocumentPreview.tsx';
import { DocumentGenerateButtons } from '../../../components/DocumentGenerateButtons.tsx';
import useAuth from '../../../hooks/useAuth';

interface Document {
  id: string;
  name: string;
  url: string;
  type: DocumentType;
  fileSize: number;
  createdAt: string;
  referenceType?: string;
  referenceId?: string;
  status?: DocumentStatus;
}

const DocumentsPage = () => {
  const { user, isAuthenticated } = useAuth();
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [folders, setFolders] = useState<any[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [draggingDocument, setDraggingDocument] = useState<string | null>(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [dragActive, setDragActive] = useState(false);

  const fetchFolders = async () => {
    try {
      console.log('Fetching folders...');
      const response = await getFolders();
      console.log('Folders response:', response);
      setFolders(response.data || []);
    } catch (error) {
      console.error('Error fetching folders:', error);
      toast.error('Failed to fetch folders');
      setFolders([]);
    }
  };

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const params: any = {
        status: DocumentStatus.FINAL
      };
      
      if (selectedFolder && selectedFolder.trim() !== '') {
        params.folderId = selectedFolder;
      }

      console.log('Fetching documents with params:', params);
      const response = await getDocuments(params);
      console.log('Documents response:', response);
      
      // Handle the nested data structure from the API
      if (response?.data?.data && Array.isArray(response.data.data)) {
        setDocuments(response.data.data);
      } else {
        console.error('Unexpected response format:', response);
        toast.error('Failed to fetch documents: Unexpected response format');
        setDocuments([]);
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
      toast.error('Failed to fetch documents');
      setDocuments([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateFolder = async () => {
    try {
      if (!newFolderName.trim()) {
        toast.error('Please enter a folder name');
        return;
      }

      const response = await createFolder({ name: newFolderName });
      console.log('Create folder response:', response);
      
      if (response.data) {
        toast.success('Folder created successfully');
        setNewFolderName('');
        setIsCreateFolderModalOpen(false);
        fetchFolders(); // Refresh the folders list
      } else {
        toast.error('Failed to create folder');
      }
    } catch (error) {
      console.error('Error creating folder:', error);
      toast.error('Failed to create folder');
    }
  };

  const handleFileUpload = async () => {
    if (!uploadFile) {
      toast.error('Please select a file to upload');
      return;
    }

    try {
      setIsUploading(true);
      const data = {
        type: DocumentType.OTHER,
        status: DocumentStatus.FINAL,
        name: uploadFile.name,
        folderId: selectedFolder,
      };

      await uploadDocument(uploadFile, data);
      toast.success('Document uploaded successfully');
      setIsUploadModalOpen(false);
      setUploadFile(null);
      fetchDocuments();
    } catch (error) {
      console.error('Error uploading document:', error);
      toast.error('Failed to upload document');
    } finally {
      setIsUploading(false);
    }
  };

  const handleDocumentClick = (doc: Document) => {
    console.log('Opening document:', doc); // Debug log
    // Get base URL from the API configuration
    const baseUrl = 'http://localhost:3001';
    // Construct the document content URL
    const fullUrl = `${baseUrl}/api/documents/documents/${doc.id}/content`;
    
    setSelectedDocument({
      ...doc,
      fileUrl: fullUrl // Set the full URL for the preview
    });
  };

  const handleDocumentGenerated = async () => {
    await fetchDocuments();
    toast.success('Document generated successfully');
  };

  const handleGenerateDocument = async (referenceType: string, referenceId: string, format: 'pdf' | 'excel') => {
    try {
      setLoading(true);
      const response = await generateDocument(referenceType, referenceId, format);
      if (response.statusCode === 200) {
        toast.success('Document generated successfully');
        fetchDocuments();
      }
    } catch (error) {
      console.error('Error generating document:', error);
      toast.error('Failed to generate document');
    } finally {
      setLoading(false);
    }
  };

  const handleBulkGenerate = async (entityType: string, format: 'pdf' | 'excel') => {
    try {
      setLoading(true);
      console.log('Starting bulk generation for:', entityType, format);
      
      const response = await generateBulkDocuments({ entityType, format });
      console.log('Bulk generate response:', response); // Debug log
      
      if (response?.data?.statusCode === 200) {
        const { generated, skipped, errors } = response.data.message;
        console.log('Generation result:', { generated, skipped, errors }); // Debug log

        const summary = {
          generated: generated.length,
          skipped: skipped.length,
          errors: errors.length
        };
        console.log('Summary:', summary); // Debug log

        // Show appropriate toast messages based on the result
        if (summary.generated === 0 && summary.skipped > 0 && summary.errors === 0) {
          console.log('Showing no new documents toast'); // Debug log
          toast.success(`No new documents to generate - ${summary.skipped} document(s) already exist`, {
            duration: 4000
          });
        } else {
          // Show success message if any documents were generated
          if (summary.generated > 0) {
            toast.success(`Generated ${summary.generated} new document(s)`, {
              duration: 4000
            });
          }
          
          // Show info about skipped documents
          if (summary.skipped > 0) {
            toast.success(`Skipped ${summary.skipped} existing document(s)`, {
              duration: 4000
            });
          }
          
          // Show warning about errors if any
          if (summary.errors > 0) {
            toast.error(`Failed to generate ${summary.errors} document(s)`, {
              duration: 4000
            });
            console.error('Generation errors:', errors);
          }
        }

        // Only fetch documents if any were generated
        if (summary.generated > 0) {
          await fetchDocuments();
        }
      }
    } catch (error) {
      console.error('Error generating documents:', error);
      toast.error('Failed to generate documents');
    } finally {
      setLoading(false);
    }
  };

  const handleCleanupDuplicates = async (referenceType: string) => {
    try {
      setLoading(true);
      console.log('Starting cleanup for:', referenceType); // Debug log
      
      const response = await cleanupDuplicateDocuments(referenceType);
      console.log('Cleanup response:', response); // Debug log
      
      if (response?.statusCode === 200) {
        toast.success(response.message);
        await fetchDocuments();
      } else {
        toast.error('Unexpected response from server');
      }
    } catch (error: any) {
      console.error('Error cleaning up duplicates:', error);
      toast.error(error.response?.data?.message || 'Failed to cleanup duplicate documents');
    } finally {
      setLoading(false);
    }
  };

  const handleDragStart = (e: React.DragEvent, documentId: string) => {
    setDraggingDocument(documentId);
    e.dataTransfer.setData('text/plain', documentId);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e: React.DragEvent) => {
    if (draggingDocument) {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
    }
  };

  const handleDrop = async (e: React.DragEvent, folderId: string | null) => {
    e.preventDefault();
    if (!draggingDocument) return;

    try {
      setLoading(true);
      await updateDocumentFolder(draggingDocument, folderId);
      toast.success('Document moved successfully');
      
      // Refresh both the current folder's documents and the folders list
      await Promise.all([
        fetchDocuments(),
        fetchFolders()
      ]);
    } catch (error) {
      console.error('Error moving document:', error);
      toast.error('Failed to move document');
    } finally {
      setDraggingDocument(null);
      setLoading(false);
    }
  };

  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const handleDropFile = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      // Check file type
      if (!file.type.match('application/pdf|application/msword|application/vnd.openxmlformats-officedocument.*|application/vnd.ms-excel')) {
        toast.error('Please upload a PDF, Word, or Excel file');
        return;
      }
      setUploadFile(file);
    }
  }, []);

  const handleFileSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      // Check file type
      if (!file.type.match('application/pdf|application/msword|application/vnd.openxmlformats-officedocument.*|application/vnd.ms-excel')) {
        toast.error('Please upload a PDF, Word, or Excel file');
        return;
      }
      setUploadFile(file);
    }
  }, []);

  useEffect(() => {
    fetchFolders();
  }, []);

  useEffect(() => {
    fetchDocuments();
  }, [selectedFolder]);

  const renderFolderList = () => (
    <div 
      className="w-72 bg-white shadow-lg rounded-l-xl p-6 border-r border-mercury/30"
      onDragOver={handleDragOver}
      onDrop={(e) => handleDrop(e, null)} // Allow dropping to root (no folder)
    >
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-hazel-green">Folders</h2>
        <button
          onClick={() => setIsCreateFolderModalOpen(true)}
          className="p-2.5 text-hazel-green hover:bg-seashell rounded-lg transition-all duration-300 hover:shadow-sm"
          title="Create new folder"
        >
          <Plus className="h-5 w-5" />
        </button>
      </div>
      <div className="space-y-2">
        <button
          onClick={() => setSelectedFolder(null)}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, null)}
          className={`w-full text-left px-4 py-2.5 rounded-lg transition-all duration-300 flex items-center ${
            !selectedFolder 
              ? 'bg-seashell text-hazel-green shadow-sm' 
              : 'hover:bg-grey-goose/10 text-bluish-grey hover:text-hazel-green'
          }`}
        >
          <FileText className="h-4 w-4 mr-3" />
          All Documents
        </button>
        {folders?.map((folder) => (
          <button
            key={folder.id}
            onClick={() => setSelectedFolder(folder.id)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, folder.id)}
            className={`w-full text-left px-4 py-2.5 rounded-lg flex items-center transition-all duration-300 ${
              selectedFolder === folder.id 
                ? 'bg-seashell text-hazel-green shadow-sm' 
                : 'hover:bg-grey-goose/10 text-bluish-grey hover:text-hazel-green'
            }`}
          >
            <IconFolderFilled size={18} className="mr-3" />
            {folder.name}
          </button>
        ))}
      </div>
    </div>
  );

  const renderDocumentActions = (doc: Document) => {
    if (doc.referenceType && doc.referenceId) {
      return (
        <div className="flex items-center space-x-2">
          <DocumentGenerateButtons
            entityType={doc.referenceType as 'Sale' | 'Purchase' | 'Offer' | 'SaleReturn' | 'PurchaseReturn'}
            entityId={doc.referenceId}
            onSuccess={handleDocumentGenerated}
          />
        </div>
      );
    }
    return null;
  };

  const renderDocumentGrid = () => (
    <div className="flex-1 p-8 bg-tealish-blue overflow-y-auto">
      {/* Modern header section */}
      <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
        <div className="flex flex-col space-y-4">
          {/* Top section with title and view toggles */}
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold text-hazel-green">
              {selectedFolder ? folders.find(f => f.id === selectedFolder)?.name : 'All Documents'}
            </h2>
            <div className="flex items-center space-x-2 text-bluish-grey">
              <button
                onClick={() => setViewMode('grid')}
                className={`p-2 rounded-lg transition-all duration-300 ${
                  viewMode === 'grid' ? 'bg-seashell text-hazel-green' : 'hover:bg-seashell/50'
                }`}
                title="Grid View"
              >
                <LayoutGrid className="h-5 w-5" />
              </button>
              <button
                onClick={() => setViewMode('list')}
                className={`p-2 rounded-lg transition-all duration-300 ${
                  viewMode === 'list' ? 'bg-seashell text-hazel-green' : 'hover:bg-seashell/50'
                }`}
                title="List View"
              >
                <List className="h-5 w-5" />
              </button>
            </div>
          </div>

          {/* Action buttons section */}
          <div className="flex flex-wrap gap-2">
            <div className="flex space-x-2">
              <button
                onClick={() => handleBulkGenerate('Sale', 'pdf')}
                className="px-4 py-2 bg-hazel-green text-white rounded hover:bg-opacity-90 flex items-center"
                disabled={loading}
              >
                <FileText className="h-4 w-4 mr-2" />
                Generate Missing Sales
              </button>
              <button
                onClick={() => handleBulkGenerate('Purchase', 'pdf')}
                className="px-4 py-2 bg-hazel-green text-white rounded hover:bg-opacity-90 flex items-center"
                disabled={loading}
              >
                <FileText className="h-4 w-4 mr-2" />
                Generate Missing Purchases
              </button>
              <button
                onClick={() => handleBulkGenerate('Offer', 'pdf')}
                className="px-4 py-2 bg-hazel-green text-white rounded hover:bg-opacity-90 flex items-center"
                disabled={loading}
              >
                <FileText className="h-4 w-4 mr-2" />
                Generate Missing Offers
              </button>
            </div>
            {/* <div className="flex space-x-2 ml-2">
              <button
                className="px-3 py-1.5 bg-bluish-grey text-white rounded hover:bg-opacity-90 text-sm disabled:opacity-50"
                onClick={() => handleCleanupDuplicates('Sale')}
                disabled={loading}
                title="Remove duplicate sale documents"
              >
                {loading ? 'Cleaning...' : 'Cleanup Sale Documents'}
              </button>
              <button
                className="px-3 py-1.5 bg-bluish-grey text-white rounded hover:bg-opacity-90 text-sm disabled:opacity-50"
                onClick={() => handleCleanupDuplicates('Purchase')}
                disabled={loading}
                title="Remove duplicate purchase documents"
              >
                {loading ? 'Cleaning...' : 'Cleanup Purchase Documents'}
              </button>
              <button
                className="px-3 py-1.5 bg-bluish-grey text-white rounded hover:bg-opacity-90 text-sm disabled:opacity-50"
                onClick={() => handleCleanupDuplicates('Offer')}
                disabled={loading}
                title="Remove duplicate offer documents"
              >
                {loading ? 'Cleaning...' : 'Cleanup Offer Documents'}
              </button>
            </div> */}
          </div>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-hazel-green"></div>
        </div>
      ) : documents.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-64 text-bluish-grey">
          <FileText className="h-16 w-16 mb-4 opacity-50" />
          <p className="text-lg">No documents found</p>
          <p className="text-sm mt-2">Upload a document or generate one from a reference</p>
        </div>
      ) : viewMode === 'grid' ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 gap-8">
          {documents.map((doc) => (
            <div
              key={doc.id}
              draggable
              onDragStart={(e) => handleDragStart(e, doc.id)}
              className="group bg-white rounded-xl shadow-sm hover:shadow-lg transition-all duration-300 overflow-hidden border border-mercury/30 hover:border-hazel-green/30 cursor-move"
              onClick={() => handleDocumentClick(doc)}
            >
              {/* Document Preview Section */}
              <div className="h-40 bg-seashell/50 flex items-center justify-center border-b border-mercury/30 group-hover:bg-hazel-green/5 transition-all duration-300">
                <div className="text-center">
                  <IconFileText size={48} className="mx-auto text-hazel-green mb-2" />
                  <span className="text-sm font-medium text-hazel-green bg-white/80 px-3 py-1 rounded-full">
                    {doc.type}
                  </span>
                </div>
              </div>

              {/* Document Info Section */}
              <div className="p-6">
                <div className="flex items-start justify-between mb-4">
                  <div className="flex-1 min-w-0">
                    <h3 className="font-medium text-lg text-hazel-green group-hover:text-hazel-green/90 transition-colors duration-300 truncate">
                      {doc.name}
                    </h3>
                    <div className="flex items-center space-x-3 mt-2">
                      <span className="text-sm text-bluish-grey flex items-center">
                        <IconFileText size={16} className="mr-1" />
                        {formatFileSize(doc.fileSize)}
                      </span>
                      <span className="text-sm text-bluish-grey flex items-center">
                        <span className="h-1 w-1 rounded-full bg-mercury mx-2"></span>
                        {new Date(doc.createdAt).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </div>
                
                {/* Document Actions */}
                <div className="flex items-center justify-between pt-4 border-t border-mercury/30">
                  <div className="flex items-center space-x-2">
                    {doc.referenceType && (
                      <span className="text-xs font-medium text-bluish-grey bg-seashell px-2 py-1 rounded">
                        {doc.referenceType}
                      </span>
                    )}
                  </div>
                  <div className="flex items-center space-x-2">
                    {renderDocumentActions(doc)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-white shadow-sm rounded-xl border border-mercury/30 overflow-hidden">
          <table className="min-w-full divide-y divide-mercury/30">
            <thead className="bg-seashell/50">
              <tr>
                <th className="px-6 py-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">
                  Size
                </th>
                <th className="px-6 py-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-mercury/30">
              {documents.map((doc) => (
                <tr
                  key={doc.id}
                  draggable
                  onDragStart={(e) => handleDragStart(e, doc.id)}
                  className="hover:bg-seashell/50 cursor-move transition-all duration-300"
                  onClick={() => handleDocumentClick(doc)}
                >
                  <td className="px-6 py-4">
                    <div className="flex items-center space-x-3">
                      <IconFileText size={20} className="text-hazel-green" />
                      <span className="text-sm font-medium text-hazel-green">{doc.name}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <span className="text-xs font-medium text-hazel-green bg-seashell px-2 py-1 rounded">
                      {doc.type}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-bluish-grey">
                    {new Date(doc.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 text-sm text-bluish-grey">
                    {formatFileSize(doc.fileSize)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {renderDocumentActions(doc)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );

  const renderUploadModal = () => (
    <AnimatePresence>
      {isUploadModalOpen && (
        <div className="fixed inset-0 z-50">
          <div className="fixed inset-0 bg-black/50" onClick={() => setIsUploadModalOpen(false)} />
          <div className="fixed inset-0 flex items-center justify-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="bg-white rounded-xl w-[480px] shadow-2xl relative z-50"
            >
              {/* Header */}
              <div className="px-6 py-4 border-b border-mercury">
                <div className="flex justify-between items-center">
                  <div>
                    <h2 className="text-xl font-semibold text-hazel-green">Upload Document</h2>
                    <p className="mt-1 text-sm text-bluish-grey">
                      Add your documents to the system
                    </p>
                  </div>
                  <button
                    onClick={() => setIsUploadModalOpen(false)}
                    className="text-bluish-grey hover:text-hazel-green transition-colors p-1.5 hover:bg-seashell rounded-lg -mr-1"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              </div>

              {/* Content */}
              <div className="p-6">
                <div 
                  className={`
                    border-2 border-dashed rounded-xl transition-all duration-300 cursor-pointer
                    ${uploadFile 
                      ? 'border-hazel-green bg-seashell' 
                      : dragActive 
                        ? 'border-hazel-green bg-seashell scale-[1.02] shadow-lg' 
                        : 'border-grey-goose hover:border-hazel-green hover:bg-seashell'
                    }
                  `}
                  onClick={() => document.getElementById('fileInput')?.click()}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDropFile}
                >
                  <div className="px-4 py-8">
                    {uploadFile ? (
                      <div className="space-y-4">
                        <div className="w-14 h-14 bg-hazel-green/10 rounded-full flex items-center justify-center mx-auto">
                          <Check className="w-7 h-7 text-hazel-green" />
                        </div>
                        <div className="text-center">
                          <p className="text-base font-medium text-hazel-green mb-1">{uploadFile.name}</p>
                          <p className="text-sm text-bluish-grey">
                            {formatFileSize(uploadFile.size)}
                          </p>
                        </div>
                        <div className="flex justify-center">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setUploadFile(null);
                            }}
                            className="inline-flex items-center px-3 py-1.5 text-sm text-watermelon hover:text-watermelon/80 font-medium bg-dawn-pink rounded-full transition-colors"
                          >
                            <X className="w-3.5 h-3.5 mr-1" />
                            Remove
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="space-y-4">
                        <div className="w-16 h-16 bg-seashell rounded-full flex items-center justify-center mx-auto group-hover:bg-hazel-green/10 transition-colors">
                          <FileUp className={`w-8 h-8 ${dragActive ? 'text-hazel-green' : 'text-bluish-grey group-hover:text-hazel-green'} transition-colors`} />
                        </div>
                        <div className="text-center">
                          <p className="text-base font-medium text-hazel-green mb-1">
                            {dragActive ? 'Drop your file here' : 'Click to upload or drag and drop'}
                          </p>
                          <p className="text-sm text-bluish-grey">
                            Support for PDF, Word, and Excel files
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <input
                  id="fileInput"
                  type="file"
                  className="hidden"
                  accept=".pdf,.doc,.docx,.xls,.xlsx"
                  onChange={handleFileSelect}
                />
              </div>

              {/* Footer */}
              <div className="px-6 py-4 bg-seashell/50 border-t border-mercury rounded-b-xl">
                <div className="flex justify-end gap-3">
                  <Button
                    variant="outline"
                    onClick={() => {
                      setIsUploadModalOpen(false);
                      setUploadFile(null);
                    }}
                    className="min-w-[100px] text-bluish-grey hover:text-hazel-green hover:border-hazel-green"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleFileUpload}
                    disabled={!uploadFile || isUploading}
                    className="min-w-[100px] bg-hazel-green text-white hover:bg-hazel-green/90 disabled:opacity-50 disabled:cursor-not-allowed shadow-sm"
                  >
                    {isUploading ? (
                      <div className="flex items-center gap-2">
                        <div className="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                        <span>Uploading...</span>
                      </div>
                    ) : (
                      <>
                        <Upload className="w-4 h-4 mr-2" />
                        Upload
                      </>
                    )}
                  </Button>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );

  return (
    <div className="h-full flex flex-col">
      <Toaster position="top-right" /> {/* Add this line to ensure toast container is present */}
      
      {/* Top Bar */}
      <div className="flex justify-between items-center p-4 border-b bg-white">
        <div className="flex items-center gap-3">
          <Button
            variant="outline"
            onClick={() => setIsCreateFolderModalOpen(true)}
            className="text-gray-700 hover:text-gray-900"
          >
            <Plus className="w-4 h-4 mr-2" />
            New Folder
          </Button>

          <Button
            onClick={() => setIsUploadModalOpen(true)}
            className="bg-hazel-green text-white hover:bg-hazel-green/90"
          >
            <Upload className="w-4 h-4 mr-2" />
            Upload Document
          </Button>

          <DocumentGenerateButtons onGenerate={fetchDocuments} />
        </div>

        <div className="flex items-center gap-3">
          <div className="flex items-center gap-2 bg-seashell rounded-lg p-1">
            <button
              onClick={() => setViewMode('grid')}
              className={`p-2 rounded-md transition-colors ${
                viewMode === 'grid'
                  ? 'bg-white text-hazel-green shadow-sm'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <LayoutGrid className="w-4 h-4" />
            </button>
            <button
              onClick={() => setViewMode('list')}
              className={`p-2 rounded-md transition-colors ${
                viewMode === 'list'
                  ? 'bg-white text-hazel-green shadow-sm'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <Table className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>

      <div className="flex h-full bg-tealish-blue">
        {renderFolderList()}
        <div className="flex-1 p-6 overflow-auto">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <div className="w-10 h-10 border-3 border-hazel-green/20 border-t-hazel-green rounded-full animate-spin" />
            </div>
          ) : (
            viewMode === 'grid' ? renderDocumentGrid() : renderDocumentGrid()
          )}
        </div>
      </div>

      {/* Create Folder Modal */}
      <AnimatePresence>
        {isCreateFolderModalOpen && (
          <Modal onClose={() => setIsCreateFolderModalOpen(false)}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="bg-white rounded-xl p-6 max-w-md w-full"
            >
              <h2 className="text-xl font-semibold mb-4">Create New Folder</h2>
              <input
                type="text"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                placeholder="Enter folder name"
                className="w-full px-3 py-2 border rounded-lg mb-4"
              />
              <div className="flex justify-end gap-3">
                <Button
                  variant="outline"
                  onClick={() => setIsCreateFolderModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button onClick={handleCreateFolder}>Create</Button>
              </div>
            </motion.div>
          </Modal>
        )}
      </AnimatePresence>

      {/* Document Preview */}
      {selectedDocument && (
        <DocumentPreview
          document={selectedDocument}
          onClose={() => setSelectedDocument(null)}
        />
      )}

      {/* Upload Modal */}
      {renderUploadModal()}
    </div>
  );
};

function formatFileSize(bytes: number) {
  if (!bytes) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
}

export default DocumentsPage;
