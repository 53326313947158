import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Settings, RefreshCw, Filter, Search, ArrowLeft, TrendingUp, TrendingDown, ShoppingBag, CreditCard, LayoutGrid, Eye, Edit, Trash2, X, Package, Calendar, User, MapPin, FileText, DollarSign } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { getSales, deleteSale, getSaleById } from '../../../api/sale';
import { format } from 'date-fns';
import { toast } from 'react-hot-toast';
import DeleteSaleConfirmationModal from '../../../components/sales/DeleteSaleConfirmationModal.tsx';
import SaleDetailsModal from '../../../components/sales/SaleDetailsModal.tsx';  

interface TabButtonProps {
  isActive: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
}

const TabButton: React.FC<TabButtonProps> = ({ isActive, onClick, icon, label }) => (
  <button
    className={`flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all duration-300 relative
                ${isActive
                  ? 'text-hazel-green border-b-2 border-hazel-green'
                  : 'text-bluish-grey hover:text-hazel-green'}`}
    onClick={onClick}
  >
    {icon}
    {label}
  </button>
);

const AllSalesIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 4h16v16H4V4z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ProductSalesIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ServiceSalesIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 11V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const SalesPage: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('all');
  const [showFilters, setShowFilters] = useState(false);
  const [sales, setSales] = useState<any[]>([]);
  const [saleToDelete, setSaleToDelete] = useState<{ id: string; documentNumber: string } | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 6;

  const [selectedSale, setSelectedSale] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingSaleDetails, setLoadingSaleDetails] = useState(false);

  const openSaleDetails = async (sale) => {
    try {
      setLoadingSaleDetails(true);
      // Fetch complete sale details including items and returns
      const response = await getSaleById(sale.id);
      if (response.data?.statusCode === 200) {
        setSelectedSale(response.data.message);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error('Error fetching sale details:', error);
      toast.error('Failed to load sale details');
    } finally {
      setLoadingSaleDetails(false);
    }
  };

  const closeSaleDetails = () => {
    setIsModalOpen(false);
    setTimeout(() => setSelectedSale(null), 200); // Clear after animation
  };

  const fetchSales = async () => {
    try {
      setLoading(true);
      const filters = activeTab === 'products' ? { type: 'PRODUCT' } : activeTab === 'services' ? { type: 'SERVICE' } : {};
      const response = await getSales({ ...filters, page: currentPage, limit: itemsPerPage });
      if (response.data?.statusCode === 200) {
        setSales(response.data.message.items || []);
        setTotalPages(Math.ceil((response.data.message.total || 0) / itemsPerPage));
      }
    } catch (error: any) {
      console.error('Error fetching sales:', error);
      toast.error('Failed to fetch sales');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSales();
  }, [activeTab, currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleEditClick = (sale) => {
    if (sale.status === 'COMPLETED') {
      toast.warning('Completed sales cannot be edited');
      return;
    }
    // Convert type to lowercase and make it plural
    const type = sale.type.toLowerCase() === 'product' ? 'products' : 'services';
    navigate(`/accounting/sales/v2/${type}/edit/${sale.id}`);
  };

  const handleDeleteClick = (sale: any) => {
    if (sale.status !== 'DRAFT') return;
    setSaleToDelete({ id: sale.id, documentNumber: sale.documentNumber });
  };

  const handleDeleteConfirm = async () => {
    if (!saleToDelete) return;

    try {
      await deleteSale(saleToDelete.id);
      toast.success('Shitja u fshi me sukses');
      // Refresh sales list
      fetchSales();
    } catch (error) {
      console.error('Error deleting sale:', error);
      toast.error('Gabim gjatë fshirjes së shitjes');
    } finally {
      setSaleToDelete(null);
    }
  };

  const EmptyState = () => (
    <div className="bg-white/90 backdrop-blur-sm rounded-xl border border-mercury/40 shadow-lg p-8">
      <div className="flex flex-col items-center justify-center text-center">
        <div className="w-16 h-16 bg-mercury/20 rounded-lg flex items-center justify-center mb-4">
          <Search className="w-8 h-8 text-hazel-green" />
        </div>
        <h3 className="text-lg font-medium text-hazel-green mb-1">No Sales Found</h3>
        <p className="text-sm text-bluish-grey mb-4">There are no sales records to display at the moment.</p>
        <button
          onClick={() => navigate('/accounting/sales/v2/products/create')}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-hazel-green hover:bg-sage transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hazel-green"
        >
          <Plus className="h-4 w-4 mr-2" />
          Create New Sale
        </button>
      </div>
    </div>
  );

  const LoadingSpinner = () => (
    <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-8 w-8 border-2 border-hazel-green/20 border-t-hazel-green"></div>
    </div>
  );

  const PaginationControls = () => (
    <div className="flex justify-center items-center mt-4 space-x-2">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
          currentPage === 1
            ? 'bg-mercury text-bluish-grey cursor-not-allowed'
            : 'bg-hazel-green text-white hover:bg-sage'
        }`}
      >
        Previous
      </button>
      <div className="flex space-x-1">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
              currentPage === page
                ? 'bg-hazel-green text-white'
                : 'bg-mercury hover:bg-grey-goose text-bluish-grey hover:text-hazel-green'
            }`}
          >
            {page}
          </button>
        ))}
      </div>
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
          currentPage === totalPages
            ? 'bg-mercury text-bluish-grey cursor-not-allowed'
            : 'bg-hazel-green text-white hover:bg-sage'
        }`}
      >
        Next
      </button>
    </div>
  );

  const renderProductSalesTable = () => {
    if (loading) {
      return <LoadingSpinner />;
    }

    if (!sales || sales.length === 0) {
      return <EmptyState />;
    }

    return (
      <>
        <div className="bg-white/90 backdrop-blur-sm rounded-xl border border-mercury/40 shadow-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gradient-to-r from-sage/5 to-transparent">
                  <th className="px-6 py-3 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Document #</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Client</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Issue Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Due Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Total</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-mercury/40">
                {sales.map((sale) => (
                  <tr key={sale.id} className="hover:bg-sage/5 transition-colors duration-150">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{sale.documentNumber}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{sale.client?.name || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                      {format(new Date(sale.issueDate), 'dd/MM/yyyy')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                      {format(new Date(sale.dueDate), 'dd/MM/yyyy')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full 
                        ${sale.status === 'COMPLETED' ? 'bg-sage/20 text-hazel-green' : 
                          sale.status === 'DRAFT' ? 'bg-grey-goose/30 text-bluish-grey' :
                          sale.status === 'RETURNED' ? 'bg-watermelon/10 text-watermelon' :
                          'bg-mercury/30 text-bluish-grey'}`}>
                        {sale.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                      {sale.totalWithVAT?.toFixed(2) || '0.00'} €
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium space-x-3">
                      <button
                        onClick={() => openSaleDetails(sale)}
                        className="text-sage hover:text-hazel-green transition-colors duration-200"
                      >
                        <Eye className="h-4 w-4" />
                      </button>
                      {sale.status === 'DRAFT' ? (
                        <>
                          <button
                            onClick={() => handleEditClick(sale)}
                            className="text-sage hover:text-hazel-green transition-colors duration-200"
                          >
                            <Edit className="h-4 w-4" />
                          </button>
                          <button
                            onClick={() => handleDeleteClick(sale)}
                            className="text-watermelon/70 hover:text-watermelon transition-colors duration-200"
                          >
                            <Trash2 className="h-4 w-4" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="text-sage opacity-50 cursor-not-allowed"
                            disabled
                          >
                            <Edit className="h-4 w-4" />
                          </button>
                          <button
                            className="text-watermelon/70 opacity-50 cursor-not-allowed"
                            disabled
                          >
                            <Trash2 className="h-4 w-4" />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {sales.length > 0 && <PaginationControls />}
      </>
    );
  };

  const renderTable = () => {
    if (loading) {
      return <LoadingSpinner />;
    }

    if (!sales || sales.length === 0) {
      return <EmptyState />;
    }

    return (
      <>
        <div className="bg-white/90 backdrop-blur-sm rounded-xl border border-mercury/40 shadow-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-mercury/40">
              <thead>
                <tr className="bg-gradient-to-r from-sage/5 to-transparent">
                  <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Document #</th>
                  <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Client</th>
                  <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Issue Date</th>
                  <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Due Date</th>
                  <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Status</th>
                  <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Total</th>
                  <th className="py-3 px-4 text-right text-xs font-medium text-hazel-green uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-mercury/40">
                {sales.map((sale) => (
                  <tr key={sale.id}>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">{sale.documentNumber}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">{sale.client?.name || 'N/A'}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                      {format(new Date(sale.issueDate), 'dd/MM/yyyy')}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                      {format(new Date(sale.dueDate), 'dd/MM/yyyy')}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                        ${sale.status === 'COMPLETED' ? 'bg-green-100 text-green-800' : 
                          sale.status === 'DRAFT' ? 'bg-yellow-100 text-yellow-800' :
                          sale.status === 'RETURNED' ? 'bg-red-100 text-red-800' :
                          'bg-gray-100 text-gray-800'}`}>
                        {sale.status}
                      </span>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                      {sale.totalWithVAT?.toFixed(2) || '0.00'} €
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-right space-x-2">
                      <button
                        onClick={() => openSaleDetails(sale)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        <Eye className="h-4 w-4 inline" />
                      </button>
                      {sale.status === 'DRAFT' ? (
                        <>
                          <button
                            onClick={() => handleEditClick(sale)}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            <Edit className="h-4 w-4 inline" />
                          </button>
                          <button
                            onClick={() => handleDeleteClick(sale)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <Trash2 className="h-4 w-4 inline" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="text-indigo-600 opacity-50 cursor-not-allowed"
                            disabled
                          >
                            <Edit className="h-4 w-4 inline" />
                          </button>
                          <button
                            className="text-red-600 opacity-50 cursor-not-allowed"
                            disabled
                          >
                            <Trash2 className="h-4 w-4 inline" />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {sales.length > 0 && <PaginationControls />}
      </>
    );
  };

  const renderContent = () => {
    if (activeTab === 'all') {
      return (
        <div className="space-y-8">
          {/* Action Buttons and Filters */}
          {renderActions()}
          {renderFilters()}

          {/* Cards Sections */}
          <div className="space-y-8">
            {/* Shitjet Section */}
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <div className="h-6 w-1 bg-gradient-to-b from-hazel-green to-sage rounded-full"></div>
                <h2 className="text-lg font-semibold text-hazel-green">Shitjet</h2>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {/* Shitje Produkti Card */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  onClick={() => navigate('/accounting/sales/v2/products/create')}
                  className="group relative cursor-pointer bg-gradient-to-br from-white to-white/95 backdrop-blur-sm 
                            rounded-xl border border-mercury/40 shadow-sm hover:shadow-lg transition-all duration-300 
                            overflow-hidden hover:-translate-y-1"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-sage/5 to-transparent opacity-0 
                                group-hover:opacity-100 transition-opacity duration-300"></div>
                  <div className="relative p-6 flex items-center gap-5">
                    <div className="flex-shrink-0 w-14 h-14 bg-gradient-to-br from-sage/20 to-hazel-green/10 
                                rounded-lg flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                      <Plus className="h-7 w-7 text-hazel-green transform group-hover:rotate-180 transition-transform duration-500" />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-base font-semibold text-hazel-green mb-1 group-hover:translate-x-1 transition-transform duration-300">
                        Shitje Produkti
                      </h3>
                      <p className="text-sm text-bluish-grey/80 group-hover:translate-x-1 transition-transform duration-300 delay-75">
                        Krijo Shitje Produkti
                      </p>
                    </div>
                    <div className="flex-shrink-0 opacity-0 transform translate-x-4 group-hover:opacity-100 
                                group-hover:translate-x-0 transition-all duration-300">
                      <div className="w-8 h-8 rounded-full bg-sage/10 flex items-center justify-center">
                        <ArrowLeft className="h-4 w-4 text-hazel-green rotate-180" />
                      </div>
                    </div>
                  </div>
                </motion.div>

                {/* Shitje Shërbimi Card */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.1 }}
                  onClick={() => navigate('/accounting/sales/v2/services/create')}
                  className="group relative cursor-pointer bg-gradient-to-br from-white to-white/95 backdrop-blur-sm 
                            rounded-xl border border-mercury/40 shadow-sm hover:shadow-lg transition-all duration-300 
                            overflow-hidden hover:-translate-y-1"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-sage/5 to-transparent opacity-0 
                                group-hover:opacity-100 transition-opacity duration-300"></div>
                  <div className="relative p-6 flex items-center gap-5">
                    <div className="flex-shrink-0 w-14 h-14 bg-gradient-to-br from-sage/20 to-hazel-green/10 
                                rounded-lg flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                      <Plus className="h-7 w-7 text-hazel-green transform group-hover:rotate-180 transition-transform duration-500" />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-base font-semibold text-hazel-green mb-1 group-hover:translate-x-1 transition-transform duration-300">
                        Shitje Shërbimi
                      </h3>
                      <p className="text-sm text-bluish-grey/80 group-hover:translate-x-1 transition-transform duration-300 delay-75">
                        Krijo Shitje Shërbimi
                      </p>
                    </div>
                    <div className="flex-shrink-0 opacity-0 transform translate-x-4 group-hover:opacity-100 
                                group-hover:translate-x-0 transition-all duration-300">
                      <div className="w-8 h-8 rounded-full bg-sage/10 flex items-center justify-center">
                        <ArrowLeft className="h-4 w-4 text-hazel-green rotate-180" />
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>

            {/* Kthimet Section */}
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <div className="h-6 w-1 bg-gradient-to-b from-watermelon to-error rounded-full"></div>
                <h2 className="text-lg font-semibold text-watermelon">Kthimet</h2>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {/* Kthim Produkti Card */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  onClick={() => navigate('/accounting/sales/v2/returns/products/create')}
                  className="group relative cursor-pointer bg-gradient-to-br from-white to-white/95 backdrop-blur-sm 
                            rounded-xl border border-mercury/40 shadow-sm hover:shadow-lg transition-all duration-300 
                            overflow-hidden hover:-translate-y-1"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-watermelon/5 to-transparent opacity-0 
                                group-hover:opacity-100 transition-opacity duration-300"></div>
                  <div className="relative p-6 flex items-center gap-5">
                    <div className="flex-shrink-0 w-14 h-14 bg-gradient-to-br from-watermelon/20 to-error/10 
                                rounded-lg flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                      <ArrowLeft className="h-7 w-7 text-watermelon transform group-hover:-translate-x-1 transition-transform duration-500" />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-base font-semibold text-watermelon mb-1 group-hover:translate-x-1 transition-transform duration-300">
                        Kthim Produkti
                      </h3>
                      <p className="text-sm text-bluish-grey/80 group-hover:translate-x-1 transition-transform duration-300 delay-75">
                        Krijo Kthim Produkti
                      </p>
                    </div>
                    <div className="flex-shrink-0 opacity-0 transform translate-x-4 group-hover:opacity-100 
                                group-hover:translate-x-0 transition-all duration-300">
                      <div className="w-8 h-8 rounded-full bg-watermelon/10 flex items-center justify-center">
                        <ArrowLeft className="h-4 w-4 text-watermelon" />
                      </div>
                    </div>
                  </div>
                </motion.div>

                {/* Kthim Shërbimi Card */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.1 }}
                  onClick={() => navigate('/accounting/sales/v2/returns/services/create')}
                  className="group relative cursor-pointer bg-gradient-to-br from-white to-white/95 backdrop-blur-sm 
                            rounded-xl border border-mercury/40 shadow-sm hover:shadow-lg transition-all duration-300 
                            overflow-hidden hover:-translate-y-1"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-watermelon/5 to-transparent opacity-0 
                                group-hover:opacity-100 transition-opacity duration-300"></div>
                  <div className="relative p-6 flex items-center gap-5">
                    <div className="flex-shrink-0 w-14 h-14 bg-gradient-to-br from-watermelon/20 to-error/10 
                                rounded-lg flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                      <ArrowLeft className="h-7 w-7 text-watermelon transform group-hover:-translate-x-1 transition-transform duration-500" />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-base font-semibold text-watermelon mb-1 group-hover:translate-x-1 transition-transform duration-300">
                        Kthim Shërbimi
                      </h3>
                      <p className="text-sm text-bluish-grey/80 group-hover:translate-x-1 transition-transform duration-300 delay-75">
                        Krijo Kthim Shërbimi
                      </p>
                    </div>
                    <div className="flex-shrink-0 opacity-0 transform translate-x-4 group-hover:opacity-100 
                                group-hover:translate-x-0 transition-all duration-300">
                      <div className="w-8 h-8 rounded-full bg-watermelon/10 flex items-center justify-center">
                        <ArrowLeft className="h-4 w-4 text-watermelon" />
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>

          {/* Table Section */}
          <div className="mt-8">
            {renderTable()}
          </div>
        </div>
      );
    }

    // For products and services tabs, show the table view
    return (
      <>
        {renderActions()}
        {renderFilters()}
        {activeTab === 'products' ? renderProductSalesTable() : renderTable()}
      </>
    );
  };

  const renderActions = () => (
    <div className="flex justify-between items-center mb-6">
      <div className="flex items-center gap-3">
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="px-4 py-2.5 bg-white/80 text-bluish-grey rounded-lg hover:bg-white 
                   flex items-center gap-2 border border-mercury/40 shadow-sm hover:shadow-md
                   backdrop-blur-sm transition-all duration-300"
        >
          <Filter className="h-4 w-4" />
          <span className="text-sm font-medium">Filtrat</span>
        </button>
        <button 
          className="p-2.5 rounded-lg text-grey-goose hover:text-hazel-green bg-white/80 hover:bg-white 
                   transition-all duration-300 border border-mercury/40 shadow-sm hover:shadow-md backdrop-blur-sm"
        >
          <RefreshCw className="h-4 w-4" />
        </button>
      </div>

      <div className="flex items-center gap-4">
        {activeTab === 'products' && (
          <>
            <button
              onClick={() => navigate('/accounting/sales/v2/products/create')}
              className="inline-flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                       hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-lg hover:shadow-xl
                       backdrop-blur-sm hover:scale-105 font-medium relative overflow-hidden"
            >
              <Plus className="h-5 w-5" />
              <span className="font-medium">Shto Shitje Produkti</span>
            </button>
            <button
              onClick={() => navigate('/accounting/sales/v2/returns/products/create')}
              className="inline-flex items-center gap-x-2 px-6 py-3 bg-watermelon text-white rounded-lg 
                       hover:bg-error transition-all duration-300 shadow-lg hover:shadow-xl
                       backdrop-blur-sm hover:scale-105 font-medium"
            >
              <ArrowLeft className="h-5 w-5" />
              <span className="font-medium">Kthim Produkti</span>
            </button>
          </>
        )}
        {activeTab === 'services' && (
          <>
            <button
              onClick={() => navigate('/accounting/sales/v2/services/create')}
              className="inline-flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                       hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-lg hover:shadow-xl
                       backdrop-blur-sm hover:scale-105 font-medium relative overflow-hidden"
            >
              <Plus className="h-5 w-5" />
              <span className="font-medium">Shto Shitje Shërbimi</span>
            </button>
            <button
              onClick={() => navigate('/accounting/sales/v2/returns/services/create')}
              className="inline-flex items-center gap-x-2 px-6 py-3 bg-watermelon text-white rounded-lg 
                       hover:bg-error transition-all duration-300 shadow-lg hover:shadow-xl
                       backdrop-blur-sm hover:scale-105 font-medium"
            >
              <ArrowLeft className="h-5 w-5" />
              <span className="font-medium">Kthim Shërbimi</span>
            </button>
          </>
        )}
        {activeTab === 'all' && (
          <>
            <button
              onClick={() => navigate('/accounting/sales/v2/products/create')}
              className="inline-flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                       hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-lg hover:shadow-xl
                       backdrop-blur-sm hover:scale-105 font-medium relative overflow-hidden"
            >
              <Plus className="h-5 w-5" />
              <span className="font-medium">Shto Shitje Produkti</span>
            </button>
            <button
              onClick={() => navigate('/accounting/sales/v2/services/create')}
              className="inline-flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                       hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-lg hover:shadow-xl
                       backdrop-blur-sm hover:scale-105 font-medium relative overflow-hidden"
            >
              <Plus className="h-5 w-5" />
              <span className="font-medium">Shto Shitje Shërbimi</span>
            </button>
            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={() => navigate('/accounting/sales/v2/returns/products/create')}
                className="inline-flex items-center gap-x-2 px-6 py-3 bg-watermelon text-white rounded-lg 
                         hover:bg-error transition-all duration-300 shadow-lg hover:shadow-xl
                         backdrop-blur-sm hover:scale-105 font-medium"
              >
                <ArrowLeft className="h-5 w-5" />
                <span className="font-medium">Kthim Produkti</span>
              </button>
              <button
                onClick={() => navigate('/accounting/sales/v2/returns/services/create')}
                className="inline-flex items-center gap-x-2 px-6 py-3 bg-watermelon text-white rounded-lg 
                         hover:bg-error transition-all duration-300 shadow-lg hover:shadow-xl
                         backdrop-blur-sm hover:scale-105 font-medium"
              >
                <ArrowLeft className="h-5 w-5" />
                <span className="font-medium">Kthim Shërbimi</span>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );

  const renderFilters = () => (
    <AnimatePresence>
      {showFilters && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
          className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-mercury/40 overflow-hidden mb-6"
        >
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                  Totali
                </label>
                <input
                  type="number"
                  className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                           focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                           hover:border-hazel-green/50 transition-all"
                  placeholder="0.00"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                  Statusi
                </label>
                <select
                  className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                           focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                           hover:border-hazel-green/50 transition-all appearance-none cursor-pointer"
                >
                  <option>Të gjitha</option>
                  <option>Aktive</option>
                  <option>Të mbyllura</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                  Klienti
                </label>
                <select
                  className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                           focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                           hover:border-hazel-green/50 transition-all appearance-none cursor-pointer"
                >
                  <option>Zgjidhni klientin</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                  Lokacioni
                </label>
                <select
                  className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                           focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                           hover:border-hazel-green/50 transition-all appearance-none cursor-pointer"
                >
                  <option>Zgjidhni lokacionin</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                  Data e lëshimit
                </label>
                <input
                  type="date"
                  className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                           focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                           hover:border-hazel-green/50 transition-all cursor-pointer"
                />
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  const tabs = [
    { id: 'all', label: 'Të gjitha', icon: <LayoutGrid className="h-4 w-4" /> },
    { id: 'products', label: 'Shitje Produkti', icon: <ProductSalesIcon /> },
    { id: 'services', label: 'Shitje Shërbimi', icon: <ServiceSalesIcon /> },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30 px-4 py-6 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col gap-6">
          {/* Header */}
          <div>
            <h1 className="text-2xl font-semibold text-bluish-grey">Shitjet</h1>
            <p className="text-sm text-grey-goose">Menaxhoni shitjet tuaja</p>
          </div>

          {/* Tabs */}
          <div className="flex items-center gap-4 border-b border-mercury/40">
            {tabs.map((tab) => (
              <TabButton
                key={tab.id}
                isActive={activeTab === tab.id}
                onClick={() => setActiveTab(tab.id)}
                icon={tab.icon}
                label={tab.label}
              />
            ))}
          </div>

          {/* Content */}
          <div className="space-y-6">
            {renderContent()}
          </div>
        </div>
      </div>

      {/* Render the modal */}
      <SaleDetailsModal
        sale={selectedSale}
        isOpen={isModalOpen}
        onClose={closeSaleDetails}
      />
      <DeleteSaleConfirmationModal
        isOpen={saleToDelete !== null}
        onClose={() => setSaleToDelete(null)}
        onConfirm={handleDeleteConfirm}
        saleNumber={saleToDelete?.documentNumber || ''}
      />
    </div>
  );
};

export default SalesPage;
