import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IconUserEdit } from '@tabler/icons-react';
import EmployeeForm from './EmployeeForm';
import PageTitle from '../../components/page-title';
import Loading from '../../components/loader';
import { getEmployeeById } from '../../api/employee';

function EditEmployee() {
  const { id } = useParams();
  const [employee, setEmployee] = useState(null); // Initialize with null
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEmployeeData();
  }, [id]);

  const getEmployeeData = async () => {
    try {
      const response = await getEmployeeById(id);
      console.log("RESPONSE ID FOR THE EMPLOYEE", response.data)
      setEmployee(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Failed to fetch employee:", err);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconUserEdit className='text-hazel-green mr-2' size={22} />}
        title='Edit Employee'
      />
        <EmployeeForm isEdit={true} employee={employee || {}} />
    </div>
  );
}

export default EditEmployee;
