import React, { useState, useEffect } from 'react';
import { Plus, Search, Eye } from 'lucide-react';
import AccountFormModal from '../../../components/accounting/AccountFormModal.tsx';
import { motion, AnimatePresence } from 'framer-motion';
import { getAccounts, getAllAccounts } from '../../../api/accountingPlan';

const AccountingPlanPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [accounts, setAccounts] = useState<any[]>([]);

  // Add custom scrollbar styles
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .custom-scrollbar::-webkit-scrollbar {
        width: 8px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #F0F3EF;
        border-radius: 4px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #c9d3cc;
        border-radius: 4px;
        transition: background 0.2s ease;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #90a68c;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
      return undefined;
    };
  }, []);

  // Fetch accounts
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = searchQuery 
          ? await getAccounts({ search: searchQuery })
          : await getAllAccounts();

        if (response.data?.message) {
          const apiAccounts = response.data.message.map((account: any) => ({
            code: account.code,
            name: account.name,
            type: account.class?.name || 'N/A',
            description: account.description || '',
            _original: account
          }));
          setAccounts(apiAccounts);
        }
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchAccounts();
  }, [searchQuery]);

  const filteredAccounts = accounts.filter(account => 
    account.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
    account.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    account.type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="h-screen flex flex-col p-6 bg-seashell/50">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-bluish-grey">Plani Kontabël</h1>
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center gap-2 px-4 py-2.5 bg-hazel-green text-white rounded-lg hover:bg-forest transition-colors"
        >
          <Plus className="h-5 w-5" />
          <span>Shto Konto</span>
        </button>
      </div>

      {/* Search Bar */}
      <div className="relative mb-6">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-grey-goose" />
        <input
          type="text"
          placeholder="Kërko konto sipas kodit ose emrit..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full pl-10 pr-4 py-2.5 bg-white border border-mercury rounded-lg text-bluish-grey 
                   placeholder:text-grey-goose focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
        />
      </div>

      {/* Main Content - Accounts List */}
      <div className="flex-1 min-h-0 bg-white rounded-xl shadow-sm overflow-hidden">
        {/* Sticky Header */}
        <div className="grid grid-cols-12 gap-4 px-6 py-4 border-b border-mercury bg-seashell/50 sticky top-0 z-10">
          <div className="col-span-2 text-sm font-medium text-bluish-grey">Kodi</div>
          <div className="col-span-4 text-sm font-medium text-bluish-grey">Emri i Kontos</div>
          <div className="col-span-5 text-sm font-medium text-bluish-grey">Kategoria</div>
          <div className="col-span-1 text-sm font-medium text-bluish-grey text-center">Detajet</div>
        </div>

        {/* Scrollable Content */}
        <div className="divide-y divide-mercury overflow-y-auto custom-scrollbar" style={{ height: 'calc(100% - 49px)' }}>
          {filteredAccounts.map((account, index) => (
            <motion.div
              key={account.code}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.05 }}
              className="grid grid-cols-12 gap-4 px-6 py-4 hover:bg-seashell/50 transition-colors items-center"
            >
              <div className="col-span-2">
                <div className="flex items-center space-x-1">
                  <span className="inline-flex px-3 py-1.5 rounded-lg bg-gradient-to-br from-hazel-green/10 to-sage/5 
                                 text-hazel-green text-sm font-medium border border-hazel-green/10 shadow-sm">
                    {account.code}
                  </span>
                </div>
              </div>
              <div className="col-span-4 text-bluish-grey">{account.name}</div>
              <div className="col-span-5">
                <span className="inline-flex px-2.5 py-1 rounded-md bg-tealish-blue text-bluish-grey text-sm">
                  {account.type}
                </span>
              </div>
              <div className="col-span-1 flex justify-center">
                <button
                  onClick={() => setSelectedAccount(account)}
                  className="p-2 hover:bg-hazel-green/10 rounded-lg transition-colors group"
                >
                  <Eye className="h-5 w-5 text-grey-goose group-hover:text-hazel-green transition-colors" />
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Account Form Modal */}
      <AccountFormModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        account={null} 
      />

      {/* Account Details Modal */}
      <AccountFormModal 
        isOpen={!!selectedAccount} 
        onClose={() => setSelectedAccount(null)} 
        account={selectedAccount?._original}
        viewOnly
      />
    </div>
  );
};

export default AccountingPlanPage;
