import React, { useState, useEffect, useCallback } from 'react';
import { Search, ChevronDown, Filter, Settings, ArrowDownUp, Plus, Calendar, FileText } from 'lucide-react';
import { motion } from 'framer-motion';
import { getFixedAssets } from '../../../../api/fixedAsset';
import { format } from 'date-fns';

interface FixedAsset {
  id: string;
  assetCode: string;
  name: string;
  description: string;
  category: string;
  purchaseDate: string;
  purchaseValue: number;
  currentValue: number;
  depreciationRate: number;
  depreciationMethod: string;
  usefulLife: number;
  state: string;
  status: string;
  location?: {
    id: string;
    name: string;
  };
  purchase?: {
    documentNumber: string;
    supplier?: {
      businessName: string;
      tradeName: string;
    };
  };
  createdAt: string;
}

enum AssetState {
  DRAFT = 'DRAFT',
  DOREZUAR = 'DOREZUAR',
  PJESERISHT_E_AMORTIZUAR = 'PJESERISHT_E_AMORTIZUAR',
  PLOTESISHT_E_AMORTIZUAR = 'PLOTESISHT_E_AMORTIZUAR'
}

enum DepreciationMethod {
  MANUAL = 'MANUAL',
  ASNJE = 'ASNJE',
  LINEAR = 'LINEAR',
  AMORTIZIM_I_DYFISHTE = 'AMORTIZIM_I_DYFISHTE'
}

const FixedAssetsPage = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [assets, setAssets] = useState<FixedAsset[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [filters, setFilters] = useState({
    name: '',
    description: '',
    category: '',
    historicCost: '',
    state: '',
    depreciationMethod: '',
    createdAt: ''
  });

  const fetchAssets = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching assets with filters:', filters);
      const data = await getFixedAssets(filters);
      console.log('Received assets:', data);
      
      if (Array.isArray(data)) {
        console.log('Setting assets array:', data);
        setAssets(data);
      } else {
        console.error('Invalid assets format received:', data);
        setAssets([]);
        setError('Invalid data format received');
      }
    } catch (error: any) {
      console.error('Error fetching assets:', error);
      setError(error?.message || 'Error fetching assets');
      setAssets([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  const renderError = () => {
    if (error) {
      return (
        <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg">
          {error}
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    console.log('Running fetchAssets effect');
    fetchAssets();
  }, [fetchAssets]);

  const handleFilterChange = (field: string, value: string) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleReset = () => {
    setFilters({
      name: '',
      description: '',
      category: '',
      historicCost: '',
      state: '',
      depreciationMethod: '',
      createdAt: ''
    });
  };

  const getStateBadgeColor = (state: string) => {
    switch (state) {
      case AssetState.DRAFT:
        return 'bg-gray-100 text-gray-800';
      case AssetState.DOREZUAR:
        return 'bg-green-100 text-green-800';
      case AssetState.PJESERISHT_E_AMORTIZUAR:
        return 'bg-yellow-100 text-yellow-800';
      case AssetState.PLOTESISHT_E_AMORTIZUAR:
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-blue-100 text-blue-800';
    }
  };

  const getStatusBadgeColor = (status: string) => {
    switch (status) {
      case 'ACTIVE':
        return 'bg-green-100 text-green-800';
      case 'UNDER_MAINTENANCE':
        return 'bg-yellow-100 text-yellow-800';
      case 'DISPOSED':
        return 'bg-red-100 text-red-800';
      case 'SOLD':
        return 'bg-purple-100 text-purple-800';
      case 'FULLY_DEPRECIATED':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-blue-100 text-blue-800';
    }
  };

  const getDepreciationMethodBadgeColor = (method: string) => {
    switch (method) {
      case DepreciationMethod.MANUAL:
        return 'bg-blue-100 text-blue-800';
      case DepreciationMethod.ASNJE:
        return 'bg-gray-100 text-gray-800';
      case DepreciationMethod.LINEAR:
        return 'bg-green-100 text-green-800';
      case DepreciationMethod.AMORTIZIM_I_DYFISHTE:
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-blue-100 text-blue-800';
    }
  };

  const formatCurrency = useCallback((value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  }, []);

  const formatDate = useCallback((dateString: string) => {
    return format(new Date(dateString), 'dd/MM/yyyy');
  }, []);

  console.log('Current assets state:', assets);

  return (
    <div className="min-h-screen bg-tealish-blue/30 p-4">
      {renderError()}
      <div className="max-w-6xl mx-auto">
        {/* Breadcrumb */}
        <div className="mb-6">
          <div className="flex items-center gap-2 text-sm mb-2">
            <span className="text-bluish-grey hover:text-hazel-green cursor-pointer transition-colors">Kontabiliteti</span>
            <span className="text-grey-goose/60">/</span>
            <span className="text-hazel-green font-medium">Mjetet Themelore</span>
          </div>
        </div>

        {/* Title and Actions */}
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-semibold text-bluish-grey tracking-tight mb-1">
              Mjetet Themelore
            </h1>
            <p className="text-sm text-grey-goose">Manage and track your fixed assets inventory</p>
          </div>
        </div>

        {/* Search and Filters Section */}
        <div className="bg-white rounded-xl shadow-sm border border-mercury/30 overflow-hidden mb-6">
          {/* Header */}
          <div className="px-6 py-4 border-b border-mercury/30 bg-gradient-to-r from-seashell/40 to-white">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="p-2 rounded-lg bg-hazel-green/10">
                  <Search className="h-4 w-4 text-hazel-green" />
                </div>
                <div>
                  <h2 className="text-base font-semibold text-bluish-grey">Search & Filters</h2>
                  <p className="text-xs text-grey-goose mt-0.5">Find and filter fixed assets</p>
                </div>
              </div>
              <button
                onClick={() => setCollapsed(!collapsed)}
                className="flex items-center gap-2 px-3 py-1.5 rounded-lg hover:bg-seashell/60 text-sm text-bluish-grey hover:text-hazel-green transition-all duration-200 group"
              >
                <span className="font-medium">{collapsed ? 'Show Filters' : 'Hide Filters'}</span>
                <ChevronDown 
                  className={`h-4 w-4 transition-transform duration-300 group-hover:scale-110 ${
                    collapsed ? '' : 'rotate-180'
                  }`} 
                />
              </button>
            </div>
          </div>

          {/* Filter Content */}
          <div className="p-6 bg-gradient-to-b from-white to-seashell/20">
            {/* Always visible fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-6">
              <div className="space-y-1.5">
                <label className="block text-sm font-medium text-bluish-grey">Emri</label>
                <div className="relative">
                  <input
                    type="text"
                    className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey placeholder-grey-goose/70 
                    focus:border-hazel-green focus:ring-2 focus:ring-hazel-green/20 transition-all hover:border-hazel-green/50"
                    placeholder="Enter asset name..."
                    value={filters.name}
                    onChange={(e) => handleFilterChange('name', e.target.value)}
                  />
                </div>
              </div>
              <div className="space-y-1.5">
                <label className="block text-sm font-medium text-bluish-grey">Pershkrimi</label>
                <div className="relative">
                  <input
                    type="text"
                    className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey placeholder-grey-goose/70 
                    focus:border-hazel-green focus:ring-2 focus:ring-hazel-green/20 transition-all hover:border-hazel-green/50"
                    placeholder="Enter description..."
                    value={filters.description}
                    onChange={(e) => handleFilterChange('description', e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* Collapsible fields */}
            <div 
              className={`space-y-6 transition-all duration-300 ease-in-out ${
                collapsed ? 'opacity-0 h-0 overflow-hidden' : 'opacity-100'
              }`}
            >
              <div className="h-px bg-gradient-to-r from-transparent via-mercury/30 to-transparent" />
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div className="space-y-1.5">
                  <label className="block text-sm font-medium text-bluish-grey">Kategoria</label>
                  <div className="relative">
                    <input
                      type="text"
                      className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey placeholder-grey-goose/70 
                      focus:border-hazel-green focus:ring-2 focus:ring-hazel-green/20 transition-all hover:border-hazel-green/50"
                      placeholder="Enter category..."
                      value={filters.category}
                      onChange={(e) => handleFilterChange('category', e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1.5">
                  <label className="block text-sm font-medium text-bluish-grey">Kosto Historike</label>
                  <div className="relative">
                    <input
                      type="text"
                      className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey placeholder-grey-goose/70 
                      focus:border-hazel-green focus:ring-2 focus:ring-hazel-green/20 transition-all hover:border-hazel-green/50"
                      placeholder="Enter historic cost..."
                      value={filters.historicCost}
                      onChange={(e) => handleFilterChange('historicCost', e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1.5">
                  <label className="block text-sm font-medium text-bluish-grey">Gjendja</label>
                  <select
                    className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey appearance-none bg-white 
                    focus:border-hazel-green focus:ring-2 focus:ring-hazel-green/20 transition-all cursor-pointer hover:border-hazel-green/50
                    bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNCA2TDggMTBMMTIgNiIgc3Ryb2tlPSIjNjQ3NDhCIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+')]"
                    style={{ backgroundPosition: 'right 1rem center', backgroundRepeat: 'no-repeat' }}
                    value={filters.state}
                    onChange={(e) => handleFilterChange('state', e.target.value)}
                  >
                    <option value="">Select state...</option>
                    <option value={AssetState.DRAFT}>Draft</option>
                    <option value={AssetState.DOREZUAR}>Dorezuar</option>
                    <option value={AssetState.PJESERISHT_E_AMORTIZUAR}>Pjeserisht e amortizuar</option>
                    <option value={AssetState.PLOTESISHT_E_AMORTIZUAR}>Plotesisht e amortizuar</option>
                  </select>
                </div>
                <div className="space-y-1.5">
                  <label className="block text-sm font-medium text-bluish-grey">Metoda e Zhvleresimit</label>
                  <select
                    className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey appearance-none bg-white 
                    focus:border-hazel-green focus:ring-2 focus:ring-hazel-green/20 transition-all cursor-pointer hover:border-hazel-green/50
                    bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNCA2TDggMTBMMTIgNiIgc3Ryb2tlPSIjNjQ3NDhCIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+')]"
                    style={{ backgroundPosition: 'right 1rem center', backgroundRepeat: 'no-repeat' }}
                    value={filters.depreciationMethod}
                    onChange={(e) => handleFilterChange('depreciationMethod', e.target.value)}
                  >
                    <option value="">Select method...</option>
                    <option value={DepreciationMethod.MANUAL}>Manual</option>
                    <option value={DepreciationMethod.ASNJE}>Asnje</option>
                    <option value={DepreciationMethod.LINEAR}>Linear</option>
                    <option value={DepreciationMethod.AMORTIZIM_I_DYFISHTE}>Amortizim i dyfishte</option>
                  </select>
                </div>
                <div className="space-y-1.5">
                  <label className="block text-sm font-medium text-bluish-grey">Created At</label>
                  <input
                    type="date"
                    className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey 
                    focus:border-hazel-green focus:ring-2 focus:ring-hazel-green/20 transition-all cursor-pointer hover:border-hazel-green/50"
                    value={filters.createdAt}
                    onChange={(e) => handleFilterChange('createdAt', e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className={`flex justify-end gap-3 ${collapsed ? 'mt-0' : 'mt-6'}`}>
              <button
                onClick={handleReset}
                className="px-4 py-2 text-sm font-medium text-bluish-grey hover:text-hazel-green transition-colors rounded-lg 
                hover:bg-seashell/60 focus:ring-2 focus:ring-hazel-green/20"
              >
                Reset
              </button>
              <button
                onClick={fetchAssets}
                className="px-5 py-2 text-sm font-medium bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 
                transition-all duration-200 shadow-sm hover:shadow-md focus:ring-2 focus:ring-hazel-green/20"
              >
                Query
              </button>
            </div>
          </div>
        </div>

        {/* Assets Table */}
        <div className="mt-8 bg-white rounded-lg shadow-sm">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-mercury">
              <thead className="bg-seashell">
                <tr>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Asset Code</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Name</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Description</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Category</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Purchase Date</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Purchase Value</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Current Value</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Depreciation Rate</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">State</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Status</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Depreciation Method</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Supplier</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Location</th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Created At</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-mercury">
                {loading ? (
                  <tr>
                    <td colSpan={14} className="px-6 py-8 text-center">
                      <div className="flex items-center justify-center gap-3">
                        <div className="animate-spin rounded-full h-5 w-5 border-2 border-hazel-green/30 border-t-hazel-green"></div>
                        <span className="text-sm text-bluish-grey">Loading assets...</span>
                      </div>
                    </td>
                  </tr>
                ) : !Array.isArray(assets) || assets.length === 0 ? (
                  <tr>
                    <td colSpan={14} className="px-6 py-8 text-center">
                      <div className="flex flex-col items-center justify-center gap-2">
                        <Search className="h-6 w-6 text-grey-goose/60" />
                        <span className="text-sm text-bluish-grey">No assets found</span>
                      </div>
                    </td>
                  </tr>
                ) : (
                  assets.map((asset) => (
                    <tr key={asset.id} className="hover:bg-gray-50">
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-bluish-grey">{asset.assetCode}</span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-bluish-grey">{asset.name}</span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-bluish-grey">{asset.description || '-'}</span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-bluish-grey">{asset.category}</span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-bluish-grey">{formatDate(asset.purchaseDate)}</span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-bluish-grey">{formatCurrency(asset.purchaseValue)}</span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-bluish-grey">{formatCurrency(asset.currentValue)}</span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-bluish-grey">{asset.depreciationRate}%</span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${getStateBadgeColor(asset.state)}`}>
                          {asset.state?.replace(/_/g, ' ')}
                        </span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(asset.status)}`}>
                          {asset.status?.replace(/_/g, ' ')}
                        </span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${getDepreciationMethodBadgeColor(asset.depreciationMethod)}`}>
                          {asset.depreciationMethod?.replace(/_/g, ' ')}
                        </span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-bluish-grey">{asset.purchase?.supplier?.businessName || '-'}</span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-bluish-grey">{asset.location?.name || '-'}</span>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-bluish-grey">{formatDate(asset.createdAt)}</span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixedAssetsPage;
