import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../shadCN/Tabs.tsx';
import { Button } from '../../shadCN/Button.tsx';
import { Plus } from 'lucide-react';
import { TaskModal } from './TaskModal.tsx';
import { TasksTable } from './TaskTable.tsx';
import { tasks } from '../../data/taskData.ts';


export function TodoList() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="min-h-screen bg-tealish-blue p-8">
      <div className="max-w-[1400px] mx-auto space-y-8">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-semibold text-hazel-green">Todo List</h1>
            <div className="flex items-center gap-2 text-sage mt-2">
              <span>Home</span>
              <span>/</span>
              <span className="text-salmon-pink">Todo List</span>
            </div>
          </div>
          <Button 
            className="bg-salmon-pink hover:bg-watermelon text-white"
            onClick={() => setIsModalOpen(true)}
          >
            Back to PMS
          </Button>
        </div>

        <div className="bg-white rounded-lg p-4 flex justify-between items-center border border-grey-goose">
          <span className="text-lg font-medium text-sage">Add a Task</span>
          <Button
            onClick={() => setIsModalOpen(true)}
            className="bg-hazel-green hover:bg-sage text-white"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Task
          </Button>
        </div>

        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold text-sage">Tasks</h2>
            <Button
              variant="outline"
              className="border-sage text-sage hover:bg-sage hover:text-white"
            >
              <Plus className="h-4 w-4 mr-2" />
              Add Tag
            </Button>
          </div>

          <Tabs defaultValue="open" className="w-full">
            <TabsList className="flex space-x-4 bg-white border border-grey-goose rounded-lg p-2 shadow-md max-w-md">
              <TabsTrigger 
                value="open"
                className="flex-1 text-center py-2 rounded-lg transition-colors duration-200 ease-in-out 
                           data-[state=active]:bg-hazel-green data-[state=active]:text-white 
                           hover:bg-hazel-green hover:text-white"
              >
                Open Tasks
              </TabsTrigger>
              <TabsTrigger 
                value="closed"
                className="flex-1 text-center py-2 rounded-lg transition-colors duration-200 ease-in-out 
                           data-[state=active]:bg-hazel-green data-[state=active]:text-white 
                           hover:bg-hazel-green hover:text-white"
              >
                Closed Tasks
              </TabsTrigger>
            </TabsList>
            <TabsContent value="open">
              <TasksTable tasks={tasks} type="open" />
            </TabsContent>
            <TabsContent value="closed">
              <TasksTable tasks={tasks} type="closed" />
            </TabsContent>
          </Tabs>
        </div>
      </div>

      <TaskModal 
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}