import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; 
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../../../components/loader';
import CustomInput from '../../../../../components/custom-input';
import SelectInput from '../../../../../components/select-input';
import { SubmitButton, CancelButton } from '../../../../../components/buttons';
import { createPayroll, updatePayroll, getPayrollById } from '../../../../../api/finances';
import { getJobTitles } from '../../../../../api/jobTitles';
import { getEmployees, getEmployeeById } from '../../../../../api/employee';
import { getPayPeriods } from '../../../../../api/finances';

function PayrollForm({ isEdit }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false); 
  const [jobTitles, setJobTitles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [payPeriods, setPayPeriods] = useState([]);
  const [initialValues, setInitialValues] = useState({
    employeeId: '',
    salary: '',  
    bonus: 0, // Ensure this is a number
    deductions: 0, // Set initial value as a number
    payPeriod: '',
    jobTitleId: '',
  });
  

  const validationSchema = Yup.object().shape({
    employeeId: Yup.string().required('Employee is required*'),
    jobTitleId: Yup.string().required('Job Title is required*'),
    bonus: Yup.number().min(0, 'Bonus must be positive'),
    deductions: Yup.number().min(0, 'Deductions must be positive'),
    payPeriod: Yup.string().required('Pay Period is required*'),
  });

  useEffect(() => {
    const fetchJobTitles = async () => {
      try {
        const response = await getJobTitles();
        setJobTitles(response.data);
      } catch (error) {
        console.error('Error fetching job titles:', error);
      }
    };

    fetchJobTitles();
  }, []);

  useEffect(() => {
    const fetchPayPeriods = async () => {
      try {
        const response = await getPayPeriods();
        setPayPeriods(response.data);
      } catch (error) {
        console.error('Error fetching pay periods:', error);
      }
    };

    fetchPayPeriods();
  }, []);

  const fetchEmployeesByJobTitle = async (jobTitleId) => {
    try {
      const response = await getEmployees(jobTitleId);
      setEmployees(response.data);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  useEffect(() => {
    const fetchPayroll = async () => {
      if (isEdit) {
        setLoading(true); 
        try {
          const response = await getPayrollById(id);
          setInitialValues(response.data); 
          setLoading(false); 
          fetchEmployeesByJobTitle(response.data.jobTitleId);
        } catch (error) {
          console.error('Error fetching payroll:', error);
          setLoading(false); 
        }
      }
    };
    fetchPayroll();
  }, [isEdit, id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
                if (isEdit) {
                    await updatePayroll(id, { 
                        bonus: values.bonus || 0,  
                        deductions: values.deductions || 0, 
                        payPeriod: values.payPeriod 
                    });
                    navigate('/finances/payrolls');
                } else {
                    await createPayroll({ 
                        employeeId: values.employeeId, 
                        bonus: values.bonus, 
                        deductions: values.deductions, 
                        payPeriod: values.payPeriod 
                    });
                    navigate('/payrolls');
                }
                setSubmitting(false);
            } catch (err) {
                // Check if there's a response from the backend
                const errorMessage = err?.response?.data?.error || 'An error occurred'; // Adjust to suit your error format
                setErrors({ submit: errorMessage });
                setSubmitting(false);
            }
        }}
        enableReinitialize={true}
      >
        {({
          errors,
          values,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>

            <div className='mb-5'>
              <h3 className='text-lg text-hazel-green font-semibold mb-2'>Employee Information</h3>
              <div className='grid grid-cols-2 gap-x-6 gap-y-4'>
                <SelectInput
                  label="Job Title"
                  name="jobTitleId"
                  options={jobTitles.map(jobTitle => ({
                    id: jobTitle.id,
                    title: jobTitle.title
                  }))}
                  value={values.jobTitleId}
                  onChange={(e) => {
                    const jobTitleId = e.target.value;
                    setFieldValue('jobTitleId', jobTitleId);
                    fetchEmployeesByJobTitle(jobTitleId);
                    setFieldValue('employeeId', ''); // Reset employeeId when job title changes
                    setFieldValue('salary', ''); // Reset salary when job title changes
                  }}
                  onBlur={handleBlur}
                  showError={errors.jobTitleId && touched.jobTitleId}
                  errorMessage={errors.jobTitleId}
                />

                <SelectInput
                  label="Employee"
                  name="employeeId"
                  options={employees.map(employee => ({
                    id: employee.id,
                    title: `${employee.name} ${employee.surname}`
                  }))}
                  value={values.employeeId}
                  onChange={async (e) => {
                    const employeeId = e.target.value;
                    setFieldValue('employeeId', employeeId);
                    if (employeeId) {
                      try {
                        const response = await getEmployeeById(employeeId);
                        setFieldValue('salary', response.data.wage);
                      } catch (error) {
                        console.error('Error fetching employee details:', error);
                      }
                    } else {
                      setFieldValue('salary', '');
                    }
                  }}
                  onBlur={handleBlur}
                  showError={errors.employeeId && touched.employeeId}
                  errorMessage={errors.employeeId}
                />
              </div>
            </div>

            {/* Section: Salary Information */}
            <div className='mb-5'>
              <h3 className='text-lg text-hazel-green font-semibold mb-2'>Salary Information</h3>
              <div className='grid grid-cols-2 gap-x-6 gap-y-4'>
                <CustomInput
                  type='number'
                  name='salary'
                  label='Salary'
                  value={values.salary}
                  disabled
                  readOnly
                />

                <CustomInput
                  type='number'
                  name='bonus'
                  label='Bonus'
                  value={values.bonus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.bonus && touched.bonus}
                  errorMessage={errors.bonus}
                />

                <CustomInput
                    type='number'
                    name='deductions'
                    label='Deductions'
                    value={values.deductions || 0} // Fallback to 0 if it's null or undefined
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.deductions && touched.deductions}
                    errorMessage={errors.deductions}
                />

              </div>
            </div>

            {/* Section: Payment Details */}
            <div className='mb-5'>
              <h3 className='text-lg text-hazel-green font-semibold mb-2'>Payment Details</h3>
              <SelectInput
                label="Pay Period"
                name="payPeriod"
                options={payPeriods.map(period => ({
                  id: period,
                  title: period
                }))}
                value={values.payPeriod}
                onChange={(e) => {
                  setFieldValue('payPeriod', e.target.value);
                }}
                onBlur={handleBlur}
                showError={errors.payPeriod && touched.payPeriod}
                errorMessage={errors.payPeriod}
              />
            </div>

            {errors.submit && <p className='text-error'>{errors.submit}</p>}
            <div className='flex justify-end items-center w-full mt-7 text-sm'>
              <CancelButton onCancel={() => navigate('/finances/payrolls')} />
              <SubmitButton isSubmitting={isSubmitting} width='w-1/5' title='Save' />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default PayrollForm;