import api from '../api/api';

export const inventoryManagementService = {
  async updateInventoryForTransaction(lineItems, transactionType, adminUserId) {
    try {
      // Log the input data
      console.log('Updating inventory with:', { lineItems, transactionType, adminUserId });

      if (!adminUserId) {
        throw new Error('Admin user ID is required for inventory updates');
      }

      const inventoryUpdates = lineItems.map(item => {
        // Validate required fields
        if (!item.inventoryProductId) {
          console.error('Missing inventoryProductId for item:', item);
          throw new Error(`Missing productId for item: ${JSON.stringify(item)}`);
        }

        return {
          productId: item.inventoryProductId,
          quantity: transactionType === 'purchase' || transactionType === 'purchase_return' 
            ? Number(item.quantity)
            : -Number(item.quantity),
          section: item.section || 'MAIN',
          type: transactionType,
          unitPrice: Number(item.price),
          description: item.description || '',
          adminUserId: adminUserId,
        };
      });

      console.log('Sending inventory updates:', inventoryUpdates);

      const response = await api.post('/api/inventory/batch-update', {
        updates: inventoryUpdates
      });

      return response.data;
    } catch (error) {
      console.error('Error updating inventory:', error);
      throw error;
    }
  }
};