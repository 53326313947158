// import React, { useEffect, useState } from 'react';
// import { getPaginatedBarOrders } from '../../api/barProduct';
// import { Link } from 'react-router-dom';

// function BarOrdersList() {
//   const [barOrders, setBarOrders] = useState([]); // Initialize as an empty array
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [page, setPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [totalEntries, setTotalEntries] = useState(0);
//   const limit = 10;

//   useEffect(() => {
//     const fetchBarOrders = async () => {
//       try {
//         setLoading(true);
//         const response = await getPaginatedBarOrders(page, limit);
//         const data = response.data || {};
//         const fetchedBarOrders = Array.isArray(data.barOrders) ? data.barOrders : []; // Safeguard against undefined or incorrect types
//         console.log('Component - Fetched Paginated Bar Orders:', data);
//         setBarOrders(fetchedBarOrders);
//         setTotalPages(Math.ceil(data.totalCount / limit) || 1); // Handle undefined totalCount
//         setTotalEntries(data.totalCount || 0); // Handle undefined totalCount
//       } catch (error) {
//         setError('Failed to fetch bar orders.');
//         console.error('Component - Failed to fetch bar orders:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchBarOrders();
//   }, [page]);

//   const handleFirstPage = () => {
//     setPage(1);
//   };

//   const handlePreviousPage = () => {
//     setPage((prevPage) => Math.max(prevPage - 1, 1));
//   };

//   const handleNextPage = () => {
//     setPage((prevPage) => Math.min(prevPage + 1, totalPages));
//   };

//   const handleLastPage = () => {
//     setPage(totalPages);
//   };

//   return (
//     <div className="px-6 py-3 relative bg-slate-100 min-h-screen w-full flex flex-col items-center justify-center gap-6">
//       <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
//         <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
//           <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
//             <tr>
//               <th scope="col" className="p-4">ID</th>
//               <th scope="col" className="px-6 py-3">Bar</th>
//               <th scope="col" className="px-6 py-3">Drink Items</th>
//               <th scope="col" className="px-6 py-3">Total Price</th>
//               <th scope="col" className="px-6 py-3">Status</th>
//               <th scope="col" className="px-6 py-3">Environment</th>
//               <th scope="col" className="px-6 py-3">Created At</th>
//             </tr>
//           </thead>
//           <tbody>
//             {barOrders.length > 0 ? (
//               barOrders.map((order) => (
//                 <tr key={order.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
//                   <Link className="cursor-pointer" to={`/bar-interface/orders/${order.id}`}>
//                     <th scope="row" className="cursor-pointer px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:underline">
//                       {order.id}
//                     </th>
//                   </Link>
//                   <td className="px-6 py-4">{order.bar?.name || 'N/A'}</td>
//                   <td className="px-6 py-4">
//                     {order.BarOrderProduct?.map(item => (
//                       <div key={item.barProduct.id}>
//                         {item.barProduct.name} (Quantity: {item.quantity})
//                       </div>
//                     )) || 'No drink items'}
//                   </td>
//                   <td className="px-6 py-4">{order.totalPrice}</td>
//                   <td className="px-6 py-4">{order.status}</td>
//                   <td className="px-6 py-4">{order.environment?.name || 'N/A'}</td>
//                   <td className="px-6 py-4">{new Date(order.createdAt).toLocaleString()}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="7" className="py-2 px-4 border-b text-center text-gray-500">No bar orders found</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//       <div className="flex flex-col items-center z-10 mt-4">
//         <div className="inline-flex space-x-4 mt-2 xs:mt-0">
//           <button
//             onClick={handleFirstPage}
//             disabled={page === 1}
//             className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900"
//           >
//             First
//           </button>
//           <button
//             onClick={handlePreviousPage}
//             disabled={page === 1}
//             className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900"
//           >
//             Prev
//           </button>
//           <span className="flex items-center justify-center px-4 h-10 text-base font-medium text-gray-900">
//             Page {page} of {totalPages}
//           </span>
//           <button
//             onClick={handleNextPage}
//             disabled={page === totalPages}
//             className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900"
//           >
//             Next
//           </button>
//           <button
//             onClick={handleLastPage}
//             disabled={page === totalPages}
//             className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-e hover:bg-gray-900"
//           >
//             Last
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default BarOrdersList;
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loader';
import { getPaginatedBarOrders } from '../../api/barProduct';
// import { getProducts, deleteProduct } from '../api/products';
import CustomTable from '../../components/table-components/table';
function BarOrdersList() {
  const [barOrders, setBarOrders] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const fetchBarOrdersList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getPaginatedBarOrders(currentPage + 1, rowsPerPage, searchText);
      setBarOrders(response.data.barOrders || []);
      setTotalCount(response.data.totalCount || 0);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, searchText]);

  useEffect(() => {
    fetchBarOrdersList();
  }, [fetchBarOrdersList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  if (fetchingInitialData) {
    return <Loading />;
  }
  const handleComplete = (id, totalPrice) => {
    navigate(`/orders/complete-order/${id}`, { state: { totalPrice } });
  };

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
      <CustomTable
        rows={barOrders}
        columns={[
          {
            title: 'ID',
            renderValue: (item) => item.id
          },
          {
            title: 'Bar',
            renderValue: (item) => item.bar?.name || 'N/A'
          },
          {
            title: 'Drink Items',
            renderValue: (item) => (
              <div>
                {item.BarOrderProduct?.map((orderItem) => (
                  <div key={orderItem.barProduct.id}>
                    {orderItem.barProduct.name} (Quantity: {orderItem.quantity})
                  </div>
                )) || 'No items'}
              </div>
            )
          },
          {
            title: 'Total Price',
            renderValue: (item) => item.totalPrice
          },
          {
            title: 'Status',
            renderValue: (item) => item.status
          },
          {
            title: 'Environment',
            renderValue: (item) => item.environment?.name || 'N/A'
          },
          {
            title: 'Created At',
            renderValue: (item) => new Date(item.createdAt).toLocaleString()
          },
          {
            title: 'Finish Order',
            renderValue: (item) => (
              <button
                onClick={() => handleComplete(item.id)}
                className='whitespace-nowrap rounded-md bg-sage transition-all duration-200 ease-out py-2 px-4 text-sm text-white shadow-sm hover:bg-hazel-green'
              >
                Complete Order
              </button>
            )
          },
          {},
          {}
        ]}
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={false}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default BarOrdersList;
