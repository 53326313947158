import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import { getRestaurants, createEnvironment, updateEnvironment } from '../../api/restaurant';
import { uploadImage } from '../../api/api';
import { IconUpload, IconX } from '@tabler/icons-react';

function EnvironmentForm({ isEdit, environment }) {
  const navigate = useNavigate();
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const getRestaurantList = useCallback(async () => {
    try {
      const response = await getRestaurants();
      const formattedRestaurants = response.data.map((restaurant) => ({
        id: restaurant.id,
        name: restaurant.name
      }));
      setRestaurants(formattedRestaurants);
    } catch (err) {
      console.error('Failed to fetch restaurants:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getRestaurantList();
  }, [getRestaurantList]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Name is required*'),
    restaurantId: Yup.string().required('Restaurant is required*'),
    description: Yup.string().min(4).required('Description is required*'),
  });

  const initialValues = isEdit ? environment : {
    name: '',
    description: '',
    imageUrl: '',
    restaurantId: ''
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          console.log('Form submitted with values:', values);
          try {
            let imageUrl = values.imageUrl;
            if (uploadedFile) {
              const formData = new FormData();
              formData.append('file', uploadedFile);
              try {
                const uploadResponse = await uploadImage(formData);
                imageUrl = uploadResponse.url;
              } catch (error) {
                console.error('Image upload failed:', error);
              }
            }

            const payload = {
              ...values,
              imageUrl: imageUrl || null,
            };

            console.log('Sending payload:', payload);

            if (isEdit) {
              await updateEnvironment(environment.id, payload);
            } else {
              await createEnvironment(payload);
            }
            navigate('/environments');
          } catch (err) {
            console.error('Submission error:', err);
            setErrors({ submit: err?.response?.data });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} className='px-7 py-5'>
            <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
              <CustomInput
                type='text'
                name='name'
                label='Name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.name && touched.name}
                errorMessage={errors.name}
              />
              <SelectInput
                value={values.restaurantId}
                label='Restaurant'
                options={restaurants.map(restaurant => ({
                  id: restaurant.id,
                  title: restaurant.name
                }))}
                onChange={(e) => setFieldValue('restaurantId', e.target.value)}
                onBlur={handleBlur}
                showError={errors.restaurantId && touched.restaurantId}
                errorMessage={errors.restaurantId}
              />
              <CustomInput
                type='text'
                name='description'
                label='Description'
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.description && touched.description}
                errorMessage={errors.description}
              />
              <div className='col-span-1'>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Image (Optional)
                  </label>
                  {uploadedFile || values.imageUrl ? (
                    <div className='relative mr-2 mb-3'>
                      <img
                        src={uploadedFile ? URL.createObjectURL(uploadedFile) : values.imageUrl}
                        alt='environment'
                        className='w-full h-32 rounded-md object-cover'
                      />
                      <button
                        type='button'
                        className='absolute top-2 right-2 bg-white rounded-full p-1 shadow-md'
                        onClick={() => {
                          setUploadedFile(null);
                          setFieldValue('imageUrl', '');
                        }}
                      >
                        <IconX size={16} />
                      </button>
                    </div>
                  ) : (
                    <div
                      className='flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-md p-4 cursor-pointer hover:border-gray-400'
                      onClick={() => fileInputRef.current?.click()}
                    >
                      <input
                        type='file'
                        ref={fileInputRef}
                        className='hidden'
                        accept='image/*'
                        onChange={(event) => {
                          const file = event.target.files?.[0];
                          if (file) {
                            setUploadedFile(file);
                          }
                        }}
                      />
                      <IconUpload className='text-gray-400 mb-2' size={24} />
                      <p className='text-sm text-gray-500'>Click to upload an image</p>
                    </div>
                  )}
                </div>
              </div>
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
            </div>
            <div className='flex justify-end items-center w-full mt-4 text-sm'>
              <CancelButton onCancel={() => navigate('/environments')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/2 md:w-1/6'
                title={`${isEdit ? 'Edit environment' : 'Create environment'}`}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default EnvironmentForm;

