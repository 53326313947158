import React from 'react';
import { Search, User } from 'lucide-react';

interface BasicDetailsProps {
  clients: any[];
  selectedClient: string;
  onClientChange: (value: string) => void;
  invoiceDate: string;
  onDateChange: (value: string) => void;
  invoiceNumber: string;
  onNumberChange: (value: string) => void;
  reference: string;
  onReferenceChange: (value: string) => void;
  terms: string;
  onTermsChange: (value: string) => void;
}

export function BasicDetails({
  clients,
  selectedClient,
  onClientChange,
  invoiceDate,
  onDateChange,
  invoiceNumber,
  onNumberChange,
  reference,
  onReferenceChange,
  terms,
  onTermsChange,
}: BasicDetailsProps) {
  return (
    <div className="bg-seashell rounded-lg p-6 space-y-6">
      <h2 className="text-lg font-semibold text-bluish-grey flex items-center gap-2">
        <User size={20} className="text-hazel-green" />
        Basic Details
      </h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-bluish-grey">Customer</label>
          <div className="relative">
            <select
              value={selectedClient}
              onChange={(e) => onClientChange(e.target.value)}
              className="w-full p-2.5 pl-10 border border-grey-goose rounded-lg focus:outline-none focus:border-sage bg-white text-bluish-grey transition-colors hover:border-sage"
            >
              <option value="">Select Customer</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-silver" size={18} />
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-bluish-grey">Invoice Date</label>
          <input
            type="date"
            value={invoiceDate}
            onChange={(e) => onDateChange(e.target.value)}
            className="w-full p-2.5 border border-grey-goose rounded-lg focus:outline-none focus:border-sage bg-white text-bluish-grey transition-colors hover:border-sage"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-bluish-grey">Invoice Number</label>
          <input
            type="text"
            value={invoiceNumber}
            onChange={(e) => onNumberChange(e.target.value)}
            placeholder="INV-0001"
            className="w-full p-2.5 border border-grey-goose rounded-lg focus:outline-none focus:border-sage bg-white text-bluish-grey transition-colors hover:border-sage"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-bluish-grey">Terms</label>
          <select
            value={terms}
            onChange={(e) => onTermsChange(e.target.value)}
            className="w-full p-2.5 border border-grey-goose rounded-lg focus:outline-none focus:border-sage bg-white text-bluish-grey transition-colors hover:border-sage"
          >
            <option value="">Select Terms</option>
            <option value="end_of_next_month">Due End of Next Month</option>
            <option value="net_30">NET 30</option>
            <option value="net_45">NET 45</option>
            <option value="net_60">NET 60</option>
          </select>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-bluish-grey">Reference (Optional)</label>
          <input
            type="text"
            value={reference}
            onChange={(e) => onReferenceChange(e.target.value)}
            placeholder="Enter reference"
            className="w-full p-2.5 border border-grey-goose rounded-lg focus:outline-none focus:border-sage bg-white text-bluish-grey transition-colors hover:border-sage"
          />
        </div>
      </div>
    </div>
  );
}