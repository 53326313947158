import React from "react";
import Header from './poolsHeader';
import { Outlet } from 'react-router-dom';


function PoolsLayout({ children }) {
    return (
        <div>
          <Header />
          
          <div className='flex h-screen'>
            <main className='w-full'><Outlet /></main>
          </div>
        </div>
    );
}

export default PoolsLayout;