import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n/config';  

import { AuthProvider } from './contexts/auth-context';
import { LanguageProvider } from './contexts/language-context';  
import Layout from './components/layout';
import Login from './views/auth/Login';
import ForgotPassword from './views/auth/ForgotPassword';
import NewPassword from './views/auth/NewPassword';
import AuthorizationGuard from './utils/AuthorizationGuard';
import Dashboard from './views/Dashboard.jsx';
import Inventory from './views/Inventory';
import UsersList from './views/users/UsersList';
import AddUser from './views/users/AddUser';
import EditUser from './views/users/EditUser';
import RolesList from './views/roles/Roles';
import AddRole from './views/roles/AddRole';
import EditRole from './views/roles/EditRole';
import RoomsList from './views/rooms/RoomsList';
import AddRoomType from './views/rooms/room-types/AddRoomType';
import EditRoomType from './views/rooms/room-types/EditRoomType';
import AddRoom from './views/rooms/AddNewRoom';
import EditRoom from './views/rooms/EditRoom';
import InvoicesList from './views/invoices/InvoicesList';
import SaleInvoiceList from './views/administration/tables/accountingTables/sales/SaleInvoiceList.js';
import AddInvoice from './views/invoices/AddInvoice';
import EditInvoice from './views/invoices/EditInvoice';
import AddReservation from './views/rooms/reservation/AddReservation';
import EditReservation from './views/rooms/reservation/EditReservation';
import AccountSettings from './views/account/AccountSettings';
import DishesList from './views/dishes/DishesList';
import AddDish from './views/dishes/AddNewDish';
import EditDish from './views/dishes/EditDish';
import RestaurantsList from './views/restaurants/RestaurantsList';
import PoolsList from './views/pools/PoolsList';
import AddTicket from './views/pools/AddTicket';
import PoolHistory from './views/pools/PoolHistory';

import EditBarProduct from './views/barProduct/EditBarProduct.js';
import AddEnvironment from './views/restaurant/AddEnviroment';
import EnvironmentsPage from './views/restaurant/EnvironmentsPage';
import MainRestaurantPage from './views/restaurant/MainRestaurantPage';
import TableForm from './views/restaurant/TableForm';
import EnvironmentForm from './views/restaurant/EnvironmentForm';
import AddTable from './views/restaurant/AddTable';
import AddTableReservation from './views/restaurant/AddTableReservation';
import EntryPage from './views/entryPage/entryPage';
import PasscodePage from "./views/passcode/PasscodePage";
import AddOrder from './views/orders/AddOrder';
import MenuPage from './views/menu/MenuPage';
import MenuForm from './views/menu/MenuForm';
import MenuFormContainer from './views/menu/MenuFormContainer';
import AddDishSection from './views/dishSection/AddDishSection';
import OrderList from './views/orders/OrdersList';
import CompleteOrder from './views/orders/CompleteOrder';
import PoolsLayout from './components/layout/PoolsLayout';
import PasscodeForm from './views/passcode/PasscodeForm';
import Pool from './views/poolsInterface/Pool';
import PoolInterfaceHistory from './views/poolsInterface/PoolsHistory';
import PoolsInterfaceList from './views/poolsInterface/PoolsInterfaceList';
import MainChatPage from './views/chat/MainChatPage.js';
import GroupCreationForm from './views/chat/GroupCreationForm';
import UserInvitationForm from './views/chat/UserInvitationForm';
import AddUserInvitationForm from './views/chat/AddUserInvitationForm';
import AddGroupCreationForm from './views/chat/AddGroupCreationForm.js';
import RestaurantLayout from './components/layout/RestaurantLayout.js';
import Restaurant from './views/restaurantInterface/Restaurant'
import TableGrid from './views/restaurantInterface/TableGrid.js';
import TablePage from './views/restaurantInterface/TablePage.js';
import AddOrderForm from './views/restaurantInterface/AddOrderFormRestaurantInterface.js';
import RestaurantOrdersList from './views/restaurantInterface/RestaurantOrdersList.js';
import OrderDetails from './views/restaurantInterface/OrderDetails.js';
import BarProductsList from './views/barProduct/BarProductList.js';
import AddBarProductSection from './views/barProduct/AddBarProductSection.js';
import AddBarOrderForm from './views/barProduct/AddBarOrderForm.js';
import BarOrdersList from './views/barProduct/BarOrdersList.js';
import BarPage from './views/restaurantInterface/BarPage.js';
import RestaurantBarOrderForm from './views/restaurantInterface/RestaurantBarOrderForm.js';
import AddRestaurantBarOrderForm from './views/restaurantInterface/AddRestaurantBarOrderForm.js';
import RestaurantBarOrdersList from './views/restaurantInterface/RestaurantBarOrdersList.js';

import Header from './views/hotelInterface/common/Header.jsx';
import Footer from './views/hotelInterface/common/Footer.jsx';

import CreateRoomPage from './views/hotelInterface/pages/CreateRoomPage.js';
import AddRoomTypePage from './views/hotelInterface/pages/AddRoomTypePage.js';
import RoomHistoryPage from './views/hotelInterface/pages/RoomHistoryPage.js';
import AddReservationPage from './views/hotelInterface/pages/AddReservationPage.js';
import RoomDetailsPage from './views/hotelInterface/pages/RoomDetailsPage.js'

import EditRoomPage from './views/hotelInterface/pages/EditRoomPage.js'

import MainVenuePage from './views/weedingVenue/MainVenuePage.js';
import AddVenueForm from './views/weedingVenue/AddVenueForm.js';
import AddVenueReservation from './views/weedingVenue/reservation/AddVenueReservationForm.js'

import VenueMenuFormContainer from './views/weedingVenue/VenueMenuFormContainer.js';
import VenueReservationsList from './views/weedingVenue/reservation/VenueReservationsList.js'

import MainConferenceRoomPage from './views/conferenceRoom/MainConferenceRoomPage.js';
import AddConferenceRoomForm from './views/conferenceRoom/AddConferenceRoomForm.js';
import AddConferenceRoomReservationForm from './views/conferenceRoom/reservation/AddConferenceRoomReservationForm.js';
import ConferenceRoomReservationsList from './views/conferenceRoom/reservation/ConferenceRoomReservationsList.js'

import CategoryPage from './views/administration/category.js';
import AccountingTable from './views/administration/tables/accountingTables/AccountingTable.js'

// import AddClient from './views/administration/tables/accountingTables/clients/AddClient.js';
// import EditClient from './views/administration/tables/accountingTables/clients/EditClient.js';

import InTime from './views/inTime/InTime.js'

import EmployeeList from './views/employee/EmployeesList.js';
import AddEmployee from './views/employee/AddEmployee.js';
import EditEmployee from './views/employee/EditEmployee.js'

import ConferenceRoomMenuFormContainer from './views/conferenceRoom/ConferenceRoomMenuFormContainer.js'

import HotelHome from './views/hotelMangement/HotelHome.tsx'
import IndoorPoolReservation from './views/hotelInterface/IndoorPoolReservation';
import HotelPoolHistory from './views/hotelInterface/HotelPoolHistory';
import EditVenueMenuForm from './views/weedingVenue/EditVenueMenuForm.js';

import {
  Home,
  Booking,
  AboutUs,
  Contact,
  PageNotFound,
  Room,
  Services,
  Team,
  Testimonial,
} from "./views/hotelInterface/pages/index.js";
import ConferenceRoomForm from './views/conferenceRoom/ConferenceRoomForm.js';
import Administration from './views/administration/administration.js'

import StockManagement from './views/stock/StockManagement.js';
import AddStockItem from './views/stock/AddStockItem.js';
import EditStockItem from './views/stock/EditStockItem.js'

import AddStockLossItem from './views/stock/stockLoss/AddStockLossItem.js';
import EditStockLossItem from './views/stock/stockLoss/EditStockLossItem.js'

import AddCategory from './views/stock/categories/AddCategory.js';
import EditCategory from './views/stock/categories/EditCategory.js'

import OrderPage from './views/orders/orderRedesign/OrderPage.js';
import MainOrderPage from './views/restaurantInterface/order/MainOrderPage.js';
// import AddOrderForm from './views/orders/orderRedesign/AddOrderForm.js';
// import EditOrderForm from './views/orders/orderRedesign/EditOrderForm.js';

import ClientsList from './views/administration/tables/accountingTables/clients/ClientsList.js';
import AddClient from './views/administration/tables/accountingTables/clients/AddClient.js';
import EditClient from './views/administration/tables/accountingTables/clients/EditClient.js'

import SuppliersList from './views/administration/tables/accountingTables/suppliers/SuppliersList.js';
import AddSupplier from './views/administration/tables/accountingTables/suppliers/AddSupplier.js';
import EditSupplier from './views/administration/tables/accountingTables/suppliers/EditSupplier.js'


import AssetsList from './views/administration/tables/accountingTables/assets/AssetsList.js';
import AddAsset from './views/administration/tables/accountingTables/assets/AddAsset.js';
import EditAsset from './views/administration/tables/accountingTables/assets/EditAsset.js'

import DailyCashLog from './views/administration/tables/accountingTables/dailyCashRegister/DailyCashLog.js'

import LedgerGeneration from './views/administration/tables/accountingTables/ledgers/LedgerGeneration.js'

import InvoiceTable from './views/administration/tables/accountingTables/transactions/InvoiceTable.js';


import PayrollList from './views/administration/tables/financesTables/payroll/PayrollList.js';
import AddPayroll from './views/administration/tables/financesTables/payroll/AddPayroll.js';
import EditPayroll from './views/administration/tables/financesTables/payroll/EditPayroll.js';
import PayrollTrackingList from './views/administration/tables/financesTables/payroll/PayrollTrackingList.js';
import ExpenseList from './views/administration/tables/financesTables/expenses/ExpenseList.js';
import TransactionList from './views/administration/tables/financesTables/transactions/TransactionList.js'

import BalanceSheet from './views/administration/tables/accountingTables/bilanceSheet/BilanceSheet.js';
import BalanceContainer from './views/administration/tables/accountingTables/bilanceSheet/BalanceContainer.js'

import TransactionAccountingList from './views/administration/tables/accountingTables/transactions/TransactionAccountingList.js'

import NotFound from './components/NotFound.js';
import BackButton from './components/BackButton.js';
import CashFlowContainer from './views/administration/tables/financesTables/cashFlow/CashFlowContainer.js';
import FinancialStateContainer from './views/administration/tables/financesTables/financialStatement/FinancialStatementContainer.js';
import StockStateDashboard from './views/stock/stockState/StockStateDashboard.js'

import EntryOrderForm from './views/entryPage/EntryOrderForm.js';
import WaiterCashLog from './views/waiter/WaiterCashLog.js';
import WaitersTablesList from './views/waitersTablesList.js/WaitersTablesList.js';
import JournalEntriePage from './views/administration/tables/accountingTables/journalEntries/JournalEntriePage.js';
import AccountingDashboard from './views/administration/tables/accountingTables/AccountingDashboard.tsx';
import ReportsPage from './views/administration/tables/accountingTables/reports/ReportsPage.js';
import AccountsList from './views/administration/tables/accountingTables/accounts/AccountsList.js';
import AccountDetails from './views/administration/tables/accountingTables/accounts/AccountDetails.js';
import QuotesList from './views/administration/tables/accountingTables/sales/QuotesList.js';
import SalesLayout from './views/administration/tables/accountingTables/sales/SalesLayout.js';
import DefaultSalesPage from './views/administration/tables/accountingTables/sales/DefaultSalesPage.js';
import DefaultPurchasesPage from './views/administration/tables/accountingTables/purchases/DefaultPurchasesPage.js';
import ExpensesList from './views/administration/tables/accountingTables/purchases/ExpensesList.js';
import BillsList from './views/administration/tables/accountingTables/purchases/BillsList.js';
import PaymentsList from './views/administration/tables/accountingTables/purchases/PaymentsList.js';
import PurchasesLayout from './views/administration/tables/accountingTables/purchases/PurchasesLayout.js';
import ClientDetails from './views/administration/tables/accountingTables/ClientSupplierDetailsPage.js';
import DetailsPage from './views/administration/tables/accountingTables/ClientSupplierDetailsPage.js';
import BudgetDefaultPage from './views/administration/tables/accountingTables/budget/DefaultBudgetPage.tsx';
import BudgetDetails from './views/administration/tables/accountingTables/budget/BudgetDetails.tsx';
import BudgetList from './views/administration/tables/accountingTables/budget/BudgetList.tsx';
import BudgetsLayout from './views/administration/tables/accountingTables/budget/BudgetLayout.tsx';
import InvoiceDetails from './views/administration/tables/accountingTables/InvoiceDetails.tsx';
import CreditNote from './views/administration/tables/accountingTables/sales/CreditNote.tsx';
import SalesReceipt from './views/administration/tables/accountingTables/sales/SalesReceipt.tsx';
import SalesReceiptList from './views/administration/tables/accountingTables/sales/SalesReceiptList.tsx';
import CreditNoteList from './views/administration/tables/accountingTables/sales/CreditNoteList.tsx';
import QuotesForm from './views/administration/tables/accountingTables/sales/QuotesForm.tsx';
import ItemsLayout from './views/administration/tables/accountingTables/items/ItemsLayout.js';
import DefaultItemsPage from './views/administration/tables/accountingTables/items/DefaultItemsPage.js';
import ItemsList from './views/administration/tables/accountingTables/items/ItemsList.tsx';
import ItemDetails from './components/items/ItemsDetails.tsx';
import ItemForm from './components/items/ItemsForm.tsx';
import InvoiceForm from './views/administration/tables/accountingTables/sales/InvoiceForm.tsx';
import PMSmanagementPage from './views/hotelMangement/pmsPage.tsx';
import DamagesList from './views/hotelMangement/DamagesList.tsx';
import NewDamage from './views/hotelMangement/NewDamage.tsx';
import RoomsListPage from './views/hotelMangement/RoomsListPage.tsx';
import TodoListPage from './views/hotelMangement/TodoListPage.tsx'

import CheckInList from './views/hotelMangement/CheckedInList.tsx';
import CheckOutList from './views/hotelMangement/CheckedOutList.tsx';
import CancelledBookings from './views/hotelMangement/CancelledBookings.tsx';
import ReservationRoomsListPage from './views/hotelMangement/ReservationList.tsx';
import RoomReservationList from './views/rooms/reservation/RoomReservationList.js'

import HotelInterfaceFApage from './views/passcode/HotelInterfaceFApage.js';
import WaiterReconciliation from './views/waitersTablesList.js/WaiterReconciliation.js';
import EditConferenceRoomMenuForm from './views/conferenceRoom/EditConferenceRoomMenuForm.js'

import EditDishSection from './views/dishSection/EditDishSection.js';
import EditVenueForm from './views/weedingVenue/EditVenueForm.js';
import { TicketProvider } from './context/TicketContext.js';
import ArticlesPage from './views/administration/tables/accountingTables/ArticlesPage.tsx';
import CompanyPage from './views/administration/tables/accountingTables/CompanyPage.tsx';

import TaxesPage from './views/administration/tables/accountingTables/TaxesPage.tsx';
import PurchaseJournalPage from './views/administration/tables/accountingTables/PurchaseJournalPage.tsx';
import SalesJournalPage from './views/administration/tables/accountingTables/SalesJournalPage.tsx';
import VatFormPage from './views/administration/tables/accountingTables/VatFormPage.tsx';
import MainReportPage from './views/administration/reports/ReportsPage.tsx';
import MainBookPage from './views/administration/reports/MainBookPage.tsx';
import IncomeStatementPage from './views/administration/statements/IncomeStatementPage.tsx';
import BalanceSheetPage from './views/administration/statements/BalanceSheetPage.tsx';

import OffersPage from './views/administration/tables/accountingTables/OffersPage.tsx';
import AddOfferPage from './views/administration/tables/accountingTables/AddOfferPage.tsx';

import BankExtractPage from './views/administration/banking/BankExtractPage.tsx';

import PurchasesPage from './views/administration/accounting/PurchasesPage.tsx';
import AddPurchasePage from './views/administration/accounting/AddPurchasePage.tsx';
import ReturnPurchasePage from './views/administration/accounting/ReturnPurchasePage.tsx';
import AddExpensePage from './views/administration/accounting/AddExpensePage.tsx';
import ReturnExpensePage from './views/administration/accounting/ReturnExpensePage.tsx';
import AddAssetPage from './views/administration/accounting/AddAssetPage.tsx';
import ReturnAssetPage from './views/administration/accounting/ReturnAssetPage.tsx';

import SalesPage from './views/administration/accounting/SalesPage.tsx';
// import SalesPage from './views/administration/accounting/SalesPage.tsx';
// import PurchasesPage from './views/administration/accounting/PurchasesPage.tsx';
// import OffersPage from './views/administration/accounting/OffersPage.tsx';
// import BankExtractPage from './views/administration/accounting/BankExtractPage.tsx';
// import FixedAssetsPage from './views/administration/accounting/FixedAssetsPage.tsx';
// import JournalEntriesPage from './views/administration/accounting/JournalEntriesPage.tsx';

import FixedAssetsPage from "./views/administration/tables/accountingTables/FixedAssetsPage.tsx";
import JournalEntriesPage from './views/administration/accounting/JournalEntriesPage.tsx';
import AddJournalEntryPage from './views/administration/accounting/AddJournalEntryPage.tsx';
import EditJournalEntryPage from './views/administration/accounting/EditJournalEntryPage.tsx';

import AddProductSalePage from './views/administration/accounting/AddProductSalePage.tsx';
import AddServiceSalePage from './views/administration/accounting/AddServiceSalePage.tsx';
import ReturnProductSalePage from './views/administration/accounting/ReturnProductSalePage.tsx';
import ReturnServiceSalePage from './views/administration/accounting/ReturnServiceSalePage.tsx';

import AccountingPlanPage from './views/administration/accounting/AccountingPlanPage.tsx';
import EditSalePage from './views/administration/accounting/EditSalePage.tsx';
import EditPurchasePage from './views/administration/accounting/EditPurchasePage.tsx';

import DocumentsPage from './views/administration/documents/DocumentsPage.tsx';
// ... other imports ...
import TutorialPage from './components/tutorials/TutorialPage.jsx';
import TutorialManagement from './views/tutorials/TutorialManagement';

import InvoicesListPage from './views/administration/tables/accountingTables/InvoicesListPage.tsx';

function App() {

  const { user } = useSelector((state) => state.user);
    return (
      <AuthProvider>
        <LanguageProvider>
        <TicketProvider>
        <ToastContainer position='bottom-left' />
        <BrowserRouter>
          <BackButton />
          <Routes>
            {user ? (
              <Route path="/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="inventory" element={<AuthorizationGuard action='read' entity='products'><Inventory /></AuthorizationGuard>} />
                <Route path="users" element={<AuthorizationGuard action='read' entity='users'><UsersList /></AuthorizationGuard>} />
                <Route path="restaurant/add-environment" element={<AddEnvironment />} />
                <Route path="restaurant/environments" element={<EnvironmentsPage />} />
                <Route path="restaurant/edit-environment/:id" element={<EnvironmentForm isEdit />} />
                <Route path="restaurant/add-table" element={<AddTable />} />
                <Route path="restaurant/edit-table/:id" element={<TableForm isEdit />} />
                <Route path="restaurant/table-reservation" element={<AddTableReservation />} />
                <Route path="orders/new-order" element={<AddOrder />} />
                {/* <Route path="orders/new-order/:tableId" element={<AddOrder />} /> */}
                <Route path="orders/new-order/:tableId" element={<OrderPage />} />

                <Route path="orders" element={<OrderList />} />
                <Route path="orders/complete-order/:id" element={<CompleteOrder />} />
                <Route path="menus/menu-page" element={<MenuPage />} />
                <Route path="menus/create-menu" element={<MenuFormContainer />} />
                <Route path="dishes/dishes_list" element={<DishesList />} />
                <Route path="dishes" element={<DishesList />} />
                <Route path="dishSection/add-dish" element={<AddDishSection />} />
                <Route path="/dish-sections/edit/:id" element={<EditDishSection />} />
                <Route path="dishes/:id" element={<EditDish />} />
                <Route path="restaurant" element={<MainRestaurantPage />} />
                <Route path="users/add-user" element={<AuthorizationGuard action='edit' entity='users'><AddUser /></AuthorizationGuard>} />
                <Route path="users/:id" element={<AuthorizationGuard action='edit' entity='users'><EditUser /></AuthorizationGuard>} />
                <Route path="roles" element={<AuthorizationGuard action='read' entity='roles'><RolesList /></AuthorizationGuard>} />
                <Route path="roles/add-role" element={<AuthorizationGuard action='edit' entity='roles'><AddRole /></AuthorizationGuard>} />
                <Route path="roles/:id" element={<AuthorizationGuard action='edit' entity='roles'><EditRole /></AuthorizationGuard>} />
                <Route path="account-settings" element={<AccountSettings />} />
                <Route path="rooms" element={<AuthorizationGuard action='read' entity='room_types'><RoomsList /></AuthorizationGuard>} />
                <Route path="rooms/add-room-type" element={<AuthorizationGuard action='edit' entity='room_types'><AddRoomType /></AuthorizationGuard>} />
                <Route path="rooms/edit-type/:id" element={<AuthorizationGuard action='edit' entity='room_types'><EditRoomType /></AuthorizationGuard>} />
                <Route path="rooms/add-new-room" element={<AuthorizationGuard action='edit' entity='rooms'><AddRoom /></AuthorizationGuard>} />
                <Route path="rooms/:id" element={<AuthorizationGuard action='edit' entity='rooms'><EditRoom /></AuthorizationGuard>} />
                <Route path="rooms/reservation-list" element={<AuthorizationGuard action='edit' entity='rooms'><RoomReservationList /></AuthorizationGuard>} />
                <Route path="reservations/add-new-reservation" element={<AuthorizationGuard action='edit' entity='rooms'><AddReservation /></AuthorizationGuard>} />
                <Route path="reservations/:id" element={<AuthorizationGuard action='edit' entity='rooms'><EditReservation /></AuthorizationGuard>} />
                <Route path="invoices" element={<AuthorizationGuard action='read' entity='invoices'><InvoicesList /></AuthorizationGuard>} />
                <Route path="invoices/add-invoice" element={<AuthorizationGuard action='edit' entity='invoices'><AddInvoice /></AuthorizationGuard>} />
                <Route path="invoices/:id" element={<AuthorizationGuard action='edit' entity='invoices'><EditInvoice /></AuthorizationGuard>} />
                <Route path="restaurants" element={<AuthorizationGuard action='read' entity='users'><RestaurantsList /></AuthorizationGuard>} />
                <Route path="pools" element={<AuthorizationGuard action='read' entity='pools'><PoolsList /></AuthorizationGuard>} />
                <Route path="pools/add-ticket/:id/:type" element={<AuthorizationGuard action='read' entity='pools'><AddTicket /></AuthorizationGuard>} />
                <Route path="pools/history/:id" element={<AuthorizationGuard action='read' entity='pools'><PoolHistory /></AuthorizationGuard>} />
                <Route path="barProducts" element={<BarProductsList />} />
                <Route path="barProducts/add-bar-product" element={<AddBarProductSection />} />
                <Route path="barProducts/edit/:id" element={<EditBarProduct />} />
                <Route path='barProducts/order' element={<AddBarOrderForm />} />
                <Route path='barProducts/orders-list' element={<BarOrdersList />} />
                <Route path='chat/' element={<MainChatPage />} />
                <Route path='chat/group-creation-form/' element={<AddGroupCreationForm />} />
                <Route path='chat/user-invitation-form/' element={<AddUserInvitationForm />} />
                <Route path="barProducts/barProduct_list" element={<BarProductsList />} />
                <Route path='barProducts/add-bar-product' element={<AddBarProductSection />} />
                <Route path='barProducts/order' element={<AddBarOrderForm />} />
                <Route path='barProducts/orders-list' element={<BarOrdersList />} />
                <Route path='venue/' element={<MainVenuePage />} />
                <Route path='venue/add-venue' element={<AddVenueForm />} />
                <Route path='venue/edit/:id' element={<EditVenueForm />} />
                <Route path='/venue-reservations/add-new-reservation' element={<AddVenueReservation />} />
                <Route path='venue/menu' element={<VenueMenuFormContainer />} />
                <Route path='venue/menu/edit/:id' element={<EditVenueMenuForm />} />
                <Route path='venue/reservations-list' element={<VenueReservationsList />} />

                <Route path='conferenceRoom/' element={<MainConferenceRoomPage />} />
                <Route path='conferenceRoom/add-new-reservation' element={<AddConferenceRoomReservationForm />} />             
                <Route path='conferenceRoom/reservations-list' element={<ConferenceRoomReservationsList />} />
                <Route path='conferenceRoom/create-conference-room' element={<AddConferenceRoomForm />} />
                <Route path='conference-rooms/edit/:id' element={<AddConferenceRoomForm />} />

                <Route path='/employees' element={<EmployeeList /> } />
                <Route path='/employees/create-employee' element={<AddEmployee />} />
                <Route path='/employees/edit-employee/:id' element={<EditEmployee />} />

                <Route path='stock/' element={<StockManagement />} />
                <Route path='stock/create-product' element={<AddStockItem />} />
                <Route path='stock/:id' element={<EditStockItem />} />
                <Route path="stock/new-category" element={<AddCategory />} />
                <Route path="stock/category/:id" element={<EditCategory /> } />

                <Route path='stock-loss/create' element={<AddStockLossItem />} />
                <Route path='stock-loss/:id' element={<EditStockLossItem />} />

                <Route path='stock-state/' element={<StockStateDashboard />} />
                

                <Route path='waiter/waiter-cash-log' element={<WaiterCashLog />} />
                <Route path="waiter/assign-waiters" element={<WaitersTablesList />} />
                <Route path='waiter/waiterReconciliation' element={<WaiterReconciliation />} />
 
                <Route path='/in_time' element={<AuthorizationGuard action='edit' entity='users'><InTime /></AuthorizationGuard>} />

                <Route path="/administration" element={<Administration />} />
                <Route path="/:category" element={<CategoryPage />} />
                <Route path="/:category/tables/:tableName" element={<AccountingTable />} />

                <Route path="tutorials" element={
                    <AuthorizationGuard action='read' entity='users'>
                      <TutorialPage />
                    </AuthorizationGuard>
                  } />
                  
                  {/* Optional: Add route for tutorial management if needed */}
                  <Route path="tutorials/manage" element={
                    <AuthorizationGuard action='edit' entity='users'>
                      <TutorialManagement />
                    </AuthorizationGuard>
                  } />


                {/* accounting routes */}

                
                <Route path='accounting/' element={<AccountingDashboard/>}>
                  <Route index element={<DefaultSalesPage />} />
                  <Route path="invoices" element={<SaleInvoiceList />} />
                  <Route path="quotes" element={<QuotesList />} />
                  <Route path="credit-notes" element={<CreditNote />} />
                  <Route path='sale-receipt' element={<SalesReceipt />} />
                  <Route path='sales-receipt-list' element={<SalesReceiptList />} />
                  <Route path='credit-notes-list' element={<CreditNoteList />} />
                  <Route path='new-quote' element={<QuotesForm />} />
                  <Route path='new-invoice' element={<InvoiceForm />} />               
                  <Route path='sales/v2/:type/edit/:id' element={<EditSalePage />} />
                </Route>

                <Route path="accounting/purchases" element={<PurchasesLayout />}>
                  <Route index element={<DefaultPurchasesPage />} />
                  <Route path="expenses" element={<ExpensesList />} />
                  <Route path="bills" element={<BillsList />} /> {/* Assume you create a BillsList component similar to ExpensesList */}
                  <Route path="payments" element={<PaymentsList />} /> {/* Assume you create a PaymentsList component similar to ExpensesList */}
                  {/* Add other purchases routes as needed */}
                </Route>

                <Route path='accounting/budgets' element={<BudgetsLayout />} >
                  <Route index element={<BudgetDefaultPage />} />
                  <Route path="budget-list" element={<BudgetList />} />
                  <Route path=":id" element={<BudgetDetails />} />
                </Route>

                <Route path='accounting/items' element={<ItemsLayout/>} >
                  <Route index element={<DefaultItemsPage />} />
                  <Route path="items-list" element={<ItemsList />} />
                  <Route path=":id" element={<ItemDetails />} />
                  <Route path='create-item' element={<ItemForm />} />
                </Route>

                <Route path='accounting/details/:type/:id' element={<DetailsPage />} />

                {/* <Route path='accounting/client-supplier-details/:id' element={<ClientDetails />} /> */}

                <Route path='accounting/accounts' element={<AccountsList />} />
                <Route path='accounting/account/:id' element={<AccountDetails />} />

                <Route path="accounting/clients" element={<AuthorizationGuard action='view' entity='clients'><ClientsList /></AuthorizationGuard>} />
                <Route path="accounting/clients/add-client" element={<AuthorizationGuard action='edit' entity='clients'><AddClient /></AuthorizationGuard>} />
                <Route path="accounting/clients/details/:id" element={<AuthorizationGuard action='view' entity='clients'><DetailsPage /></AuthorizationGuard>} />
                <Route path="accounting/clients/:id" element={<AuthorizationGuard action='edit' entity='clients'><EditClient /></AuthorizationGuard>} />

                <Route path='accounting/reports' element={<ReportsPage />} />

                <Route path="accounting/suppliers" element={<AuthorizationGuard action='view' entity='suppliers'><SuppliersList /></AuthorizationGuard>} />
                <Route path="accounting/suppliers/add-supplier" element={<AuthorizationGuard action='edit' entity='suppliers'><AddSupplier /></AuthorizationGuard>} />
                <Route path="accounting/suppliers/details/:id" element={<AuthorizationGuard action='view' entity='suppliers'><DetailsPage /></AuthorizationGuard>} />
                <Route path="accounting/suppliers/:id" element={<AuthorizationGuard action='edit' entity='suppliers'><EditSupplier /></AuthorizationGuard>} />

                <Route path="accounting/assets" element={<AuthorizationGuard action='view' entity='assets'><AssetsList /></AuthorizationGuard>} />
                <Route path="accounting/assets/add-asset" element={<AuthorizationGuard action='edit' entity='assets'><AddAsset /></AuthorizationGuard>} />
                <Route path="accounting/assets/:id" element={<AuthorizationGuard action='edit' entity='assets'><EditAsset /></AuthorizationGuard>} />

                <Route path="accounting/daily-cash-registers" element={<AuthorizationGuard action='view' entity='cash'><DailyCashLog /></AuthorizationGuard>} />

                <Route path="accounting/ledgers" element={<AuthorizationGuard action='view' entity='accounting'><LedgerGeneration /></AuthorizationGuard>} />

                <Route path="accounting/transactions" element={<AuthorizationGuard action='view' entity='accounting'><TransactionAccountingList /></AuthorizationGuard>} />
                
                <Route path="accounting/balance-sheet" element={<AuthorizationGuard action='view' entity='accounting'><BalanceContainer /></AuthorizationGuard>} />
                <Route path="accounting/transaction-accounting-list" element={<AuthorizationGuard action='view' entity='accounting'><TransactionAccountingList /></AuthorizationGuard>} />
                
                <Route path='accounting/journal-entries' element={<JournalEntriesPage />} />
                <Route path='accounting/journal-entries/add' element={<AddJournalEntryPage />} />
                <Route path='accounting/journal-entries/:id/edit' element={<EditJournalEntryPage />} />
                <Route path="/accounting/purchases/v2" element={<PurchasesPage />} />
                <Route path="/accounting/purchases/add" element={<AddPurchasePage />} />
                <Route path="/accounting/purchases/return" element={<ReturnPurchasePage />} />
                <Route path="/accounting/purchases/edit/:id" element={<EditPurchasePage />} />
                <Route path="/accounting/expenses/add" element={<AddExpensePage />} />
                <Route path="/accounting/expenses/return" element={<ReturnExpensePage />} />
                <Route path="/accounting/assets/add" element={<AddAssetPage />} />
                <Route path="/accounting/assets/return" element={<ReturnAssetPage />} />
                <Route path="/accounting/sales/v2" element={<SalesPage />} />
                <Route path="/accounting/sales/v2/products/create" element={<AddProductSalePage />} />
                <Route path="/accounting/sales/v2/services/create" element={<AddServiceSalePage />} />
                <Route path="/accounting/sales/v2/products/edit/:id" element={<EditSalePage />} />
                <Route path="/accounting/sales/v2/services/edit/:id" element={<EditSalePage />} />
                <Route path="/accounting/sales/v2/returns/products/create" element={<ReturnProductSalePage />} />
                <Route path="/accounting/sales/v2/returns/services/create" element={<ReturnServiceSalePage />} />
                <Route path="/accounting/reports/main-page" element={<MainReportPage />} />
                <Route path="/accounting/reports/main-book" element={<MainBookPage />} />
                <Route path='/accounting/invoices/:invoiceId' element={<InvoiceDetails />} />
                <Route path="/accounting/config/articles" element={<AuthorizationGuard action='view' entity='accounting'><ArticlesPage /></AuthorizationGuard>} />
                <Route path='/accounting/config/company' element={<AuthorizationGuard action='view' entity='accounting'><CompanyPage /></AuthorizationGuard>} />
                <Route path='/accounting/config/accounting-plan' element={<AuthorizationGuard action='view' entity='accounting'><AccountingPlanPage /></AuthorizationGuard>} />
                <Route path="finances/cash-flow" element={<AuthorizationGuard action='view' entity='accounting'><CashFlowContainer /></AuthorizationGuard>} />
                <Route path="finances/financial-statement" element={<AuthorizationGuard action='view' entity='accounting'><FinancialStateContainer /></AuthorizationGuard>} />


                <Route path="finances/payrolls" element={<PayrollList />} />
                <Route path="finances/payrolls/add-payroll" element={<AddPayroll />} />
                <Route path="finances/payrolls/:id" element={<EditPayroll />} />
                <Route path='finances/payrolls/payroll-tracking' element={<PayrollTrackingList />} />

                <Route path="finances/expenses" element={<ExpenseList />} />

                <Route path="finances/transactions" element={<TransactionList />} />


                <Route path="/conferenceRoom/menu" element={<ConferenceRoomMenuFormContainer />} />
                <Route path="/conferenceRoom/menus/edit/:id" element={<EditConferenceRoomMenuForm />} />

                <Route path="*" element={<NotFound />} />

                <Route path="/accounting/statements/income" element={<IncomeStatementPage />} />
                <Route path="/accounting/statements/balance" element={<BalanceSheetPage />} />

                <Route path="/accounting/offers" element={<OffersPage />} />
                <Route path="/accounting/offers/new" element={<AddOfferPage />} />
                <Route path="/accounting/offers/:id" element={<AddOfferPage />} />
                <Route path="/accounting/offers/edit/:id" element={<AddOfferPage />} />
                <Route path="/accounting/fixed-assets" element={<FixedAssetsPage />} />
                <Route path="/accounting/bank-extract" element={<BankExtractPage />} />
                <Route path="/accounting/documents" element={<DocumentsPage />} />
                {/* <Route path="/accounting/sales" element={<SalesPage />} />
                <Route path="/accounting/purchases" element={<PurchasesPage />} />
                <Route path="/accounting/offers" element={<OffersPage />} />
                <Route path="/accounting/bank-extract" element={<BankExtractPage />} />
                <Route path="/accounting/fixed-assets" element={<FixedAssetsPage />} />
                <Route path="/accounting/journal-entries" element={<JournalEntriesPage />} /> */}

                <Route path="/accounting/taxes" element={<TaxesPage />} />
                <Route path="/accounting/taxes/purchases" element={<PurchaseJournalPage />} />
                <Route path="/accounting/taxes/sales" element={<SalesJournalPage />} />
                <Route path="/accounting/taxes/vat-form" element={<VatFormPage />} />

                <Route path="/accounting/sales/edit/:id" element={<EditSalePage />} />

                <Route path='accounting/invoices-list' element={
                <AuthorizationGuard action='view' entity='accounting'>
                  <InvoicesListPage />
                </AuthorizationGuard>
              } />

              </Route>
            ) : (
              <>
                <Route path="/" element={<EntryPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/passcode/:icon" element={<PasscodePage />} />
                <Route path="/passcode/create-passcode" element={<PasscodeForm />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/new-password" element={<NewPassword />} />
                <Route path='/entry-order' element={<EntryOrderForm />} />
              </>
            )}
            <Route path="/pool-interface" element={<PoolsLayout />}>
              <Route index element={<Pool />} />
              <Route path='/pool-interface/pool-history' element={<PoolInterfaceHistory />} />
              <Route path='/pool-interface/pools-list' element={<PoolsInterfaceList />} />
            </Route>

            <Route path='/restaurant-interface' element={<RestaurantLayout />}>
                <Route index element={<Restaurant />} />
                <Route path='tables' element={<TablePage />} />
                <Route path='create-order' element={<AddOrderForm />} />
                <Route path='create-bar-order' element={<AddRestaurantBarOrderForm />} />
                <Route path='orders' element={<RestaurantOrdersList />} />
                <Route path='bar-orders' element={<RestaurantBarOrdersList />} />
                <Route path="orders/:id" element={<OrderDetails />} />
                <Route path='bar' element={<BarPage />} />
                <Route path='order' element={<OrderPage />} />
                <Route path='order/:tableId' element={
                  <div data-drop-target="true">
                    <OrderPage />
                  </div>} />
            </Route>


              <Route path='/passcode/hotel-auth' element={<HotelInterfaceFApage />} />
              <Route path='/hotelInterface' element={<HotelHome />} />
              <Route path='/hotelInterface/pms-management' element={<PMSmanagementPage />}  />
              <Route path="/hotelInterface/damages" element={<DamagesList />} />
              <Route path="/hotelInterface/damages/new" element={<NewDamage />} />
              <Route path='/hotelInterface/roomsList' element={<RoomsListPage />} />
              <Route path='/hotelInterface/todoList' element={<TodoListPage />} />
              <Route path='/hotelInterface/indoor-pool' element={<IndoorPoolReservation />} />
              <Route path='/hotelInterface/indoor-pool/history' element={<HotelPoolHistory />} />
              <Route path='/hotelInterface/checkedIn' element={<CheckInList />} />
              <Route path='/hotelInterface/checkedOut' element={<CheckOutList />} />
              <Route path='/hotelInterface/reservationsList' element={<ReservationRoomsListPage />} />
              <Route path='/hotelInterface/cancelledBookings' element={<CancelledBookings />} />
          


              <Route path="/hotel-interface" element={<Home />} />
              <Route path="/hotel-interface/booking" element={<Booking />} />
              <Route path="/hotel-interface/team" element={<Team />} />
              <Route path="/hotel-interface/testimonial" element={<Testimonial />} />
              <Route path="/hotel-interface/about" element={<AboutUs />} />
              <Route path="/hotel-interface/contact" element={<Contact />} />
              <Route path="/*" element={<PageNotFound />} />
              <Route path="/hotel-interface/rooms" element={<Room />} />
              <Route path="/hotel-interface/services" element={<Services />} />
              <Route path='/hotel-interface/create-room' element={<CreateRoomPage />} />

              <Route path="/hotel-interface/add-room-type" element={<AddRoomTypePage />} />
              {/* <Route path="/hotel-interface/edit-type/:id" element={<EditRoomType />} />  */}
              <Route path='/hotel-interface/rooms/edit-room/:id' element={<EditRoomPage />} />

              <Route path='/hotel-interface/room-history' element={<RoomHistoryPage />} />

              {/* <Route path="/hotel-interface/add-new-reservation" element={<AddReservationPage />} /> */}
              <Route path="/hotel-interface/:id" element={<EditReservation />} />
              <Route path="/hotel-interface/rooms/:id" element={<RoomDetailsPage />} />
              <Route path='/hotel-interface/add-new-reservation/' element={<AddReservationPage />} />

              <Route path='/hotel-interface/add-new-reservation/:roomNumber' element={<AddReservationPage />} />


          </Routes>
        </BrowserRouter>
      </TicketProvider>
    </LanguageProvider>
  </AuthProvider>
  );
}

export default App;
