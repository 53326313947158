import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './OrderSidebar';
import OrderTable from './OrderTable';
import InvoiceTable from './InvoiceTable';
import Modal from '../../../components/modal';

import { useSelector } from 'react-redux';

import { getMenuById } from '../../../api/conferenceRoomMenu';

import { createRoomOrder } from '../../../api/roomOrders.js';

import Calculator from '../../../components/calculator/Calculator.tsx';

import { finalizeOrder } from '../../../api/waiter.js';

import { createBarOrder } from '../../../api/barProduct';
import { createDishOrder } from '../../../api/orders';
import { createConferenceRoomOrder } from '../../../api/conferenceRoomOrder';
import { getAllMenus as getConferenceRoomMenus } from '../../../api/conferenceRoomMenu';
import { getActiveReservations as getReservations } from '../../../api/conferenceRoomReservation';
import { verifyOwnerPasscode } from '../../../api/auth.api';

import { IconReceipt, IconCubeSend } from '@tabler/icons-react';

import { checkTableAssignment } from '../../../api/orders';

import { invoiceCount as invoiceCountAPI } from '../../../api/invoiceSale.js';

import { createInvoice } from '../../../api/invoiceSale.js';
import { createTransaction } from '../../../api/accounting.js';
import { adjustAccountValue } from '../../../api/account.js';

import api from '../../../api/api.js';

import { createRoomServiceOrder } from '../../../api/orders';

function OrderPage() {

  const user = useSelector((state) => state.user.user);
  const waiterId = user?.id;

  const [selectedRoom, setSelectedRoom] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [isCurrentWaiterAssigned, setIsCurrentWaiterAssigned] = useState(false);

  const [menu, setMenu] = useState(null);


  const [kitchenOrderItems, setKitchenOrderItems] = useState([]);
  const [barOrderItems, setBarOrderItems] = useState([]);
  const [conferenceRoomOrderItems, setConferenceRoomOrderItems] = useState([]);
  const [conferenceRoomBarItems, setConferenceRoomBarItems] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('kitchen');
  const { tableId } = useParams();

  const [activeReservations, setActiveReservations] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [ownerCode, setOwnerCode] = useState('');
  const [ownerPasscodeVerified, setOwnerPasscodeVerified] = useState(false);
  const [originalPrices, setOriginalPrices] = useState({});

  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);

  const [isTableAssigned, setIsTableAssigned] = useState(false);
  // useEffect(() => {
  //   if (selectedCategory === 'conferenceRoom') {
  //     fetchActiveReservations();
  //   }
  // }, [selectedCategory]);



  const [invoiceCount, setInvoiceCount] = useState(0);

  // Add this useEffect to fetch invoice count
  useEffect(() => {
    const fetchInvoiceCount = async () => {
      try {
        const response = await invoiceCountAPI();
        setInvoiceCount(response.data.count);
      } catch (error) {
        console.error('Error fetching invoice count:', error);
        toast.error('Failed to load invoice count.');
      }
    };
    fetchInvoiceCount();
  }, []);

  const generateInvoiceNumber = () => {
    const timestamp = new Date().getTime().toString().slice(-4);
    return `INV-${String(invoiceCount + 1).padStart(3, '0')}-${timestamp}`;
  };
  // const generateInvoiceNumber = () => {
  //   return `INV-Order-${String(invoiceCount + 1).padStart(3, '0')}`;
  // };

  useEffect(() => {
    const checkAssignment = async () => {
      try {
        const response = await checkTableAssignment(tableId);
        console.log("RESPONSE OF CHECK ASSIGNMENT", response);
        
        // Check if there are any active assignments
        if (response.data && response.data.length > 0) {
          // Get the first assignment (assuming only one active assignment per table at a time)
          const assignment = response.data[0];

          // Check if the current waiter is the assigned waiter
          const isCurrentWaiterAssigned = assignment.waiterId === waiterId;

          // Set table assignment status based on whether the current waiter is assigned
          setIsTableAssigned(!isCurrentWaiterAssigned);
        } else {
          setIsTableAssigned(false); // No assignments
        }
      } catch (error) {
        console.error('Error checking table assignment:', error);
        toast.error('Failed to check table assignment.');
      }
    };

    if (tableId && waiterId) {
      checkAssignment();
    }
  }, [tableId, waiterId]); // Add waiterId to dependency array
  


  useEffect(() => {
    const fetchActiveReservations = async () => {
      try {
        const response = await getReservations();
        const reservations = response.data;
        console.log("reservations",reservations)
        setActiveReservations(reservations);

        // Automatically select the first reservation if available
        if (reservations.length > 0) {
          handleReservationSelect(reservations[0]);
        }
      } catch (error) {
        console.error('Error fetching reservations:', error);
      }
    };

    fetchActiveReservations();
  }, []);

  const handleReservationSelect = async (reservation) => {
    setSelectedReservation(reservation);
    console.log("reservation" , reservation)

    // Fetch the menu by ID
    try {
      const menuResponse = await getMenuById(reservation.menuId);
      setMenu(menuResponse.data);
    } catch (error) {
      console.error('Error fetching menu:', error);
    }
  };


  const dropTargetRef = useRef(null);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    
    try {
      const data = e.dataTransfer.getData('application/json');
      const dragData = JSON.parse(data);
      console.log('Dropped item:', dragData);
  
      const newItem = {
        id: dragData.id,
        productName: dragData.name || dragData.productName,
        price: parseFloat(dragData.price),
        quantity: 1,
        type: dragData.type,
        category: dragData.category,
        tableId: selectedCategory === 'room' ? null : tableId,
        roomNumber: selectedCategory === 'room' ? selectedRoom : null
      };
  
      if (selectedCategory === 'conferenceRoom') {
        if (dragData.type === 'barItem') {
          setConferenceRoomBarItems(prevItems => {
            const existingItem = prevItems.find(item => item.id === newItem.id);
            if (existingItem) {
              return prevItems.map(item => 
                item.id === newItem.id 
                  ? { ...item, quantity: item.quantity + 1 }
                  : item
              );
            }
            return [...prevItems, newItem];
          });
        } else {
          setConferenceRoomOrderItems(prevItems => {
            const existingItem = prevItems.find(item => item.id === newItem.id);
            if (existingItem) {
              return prevItems.map(item => 
                item.id === newItem.id 
                  ? { ...item, quantity: item.quantity + 1 }
                  : item
              );
            }
            return [...prevItems, newItem];
          });
        }
      } else if (dragData.type === 'barItem') {
        setBarOrderItems(prevItems => {
          const existingItem = prevItems.find(item => item.id === newItem.id);
          if (existingItem) {
            return prevItems.map(item => 
              item.id === newItem.id 
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
          }
          return [...prevItems, newItem];
        });
      } else {
        setKitchenOrderItems(prevItems => {
          const existingItem = prevItems.find(item => item.id === newItem.id);
          if (existingItem) {
            return prevItems.map(item => 
              item.id === newItem.id 
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
          }
          return [...prevItems, newItem];
        });
      }
    } catch (error) {
      console.error('Error handling drop:', error);
    }
  }, [selectedCategory, selectedRoom, tableId, setBarOrderItems, setKitchenOrderItems, setConferenceRoomOrderItems, setConferenceRoomBarItems]);
  

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    const dropTarget = dropTargetRef.current;
    if (dropTarget) {
      dropTarget.addEventListener('drop', handleDrop);
      dropTarget.addEventListener('dragover', handleDragOver);
    }

    return () => {
      if (dropTarget) {
        dropTarget.removeEventListener('drop', handleDrop);
        dropTarget.removeEventListener('dragover', handleDragOver);
      }
    };
  }, [handleDrop, handleDragOver]);

  const [selectedTable, setSelectedTable] = useState(null);

  useEffect(() => {
    if (tableId) {
      setSelectedTable({ id: tableId });
    }
  }, [tableId]);

  const handleAddNewOrder = async () => {
    try {
      if (selectedCategory === 'room') {
        if (!selectedRoom) {
          toast.error('Please select a room');
          return;
        }

        if (!tableId) {
          toast.error('No table selected');
          return;
        }

        const roomServiceOrderDetails = {
          roomNumber: selectedRoom,
          waiterId: waiterId,
          tableId: tableId,
          totalPrice: kitchenOrderItems.reduce((total, item) => 
            total + parseFloat(item.price) * parseInt(item.quantity, 10), 0) +
            barOrderItems.reduce((total, item) => 
            total + parseFloat(item.price) * parseInt(item.quantity, 10), 0),
          orderItems: [
            ...kitchenOrderItems.map(item => ({
              ...item,
              category: 'KITCHEN',
              type: 'dish'
            })),
            ...barOrderItems.map(item => ({
              ...item,
              category: 'BAR',
              type: 'barItem'
            }))
          ]
        };

        console.log("Submitting room service order:", roomServiceOrderDetails);
        const response = await createRoomServiceOrder(roomServiceOrderDetails);
        console.log("Room service order response:", response);

        setInvoiceItems(prevItems => [...prevItems, ...kitchenOrderItems, ...barOrderItems]);
        setKitchenOrderItems([]);
        setBarOrderItems([]);
        setIsOrderSubmitted(true);
        toast.success('Room service order created successfully');
      }
    } catch (error) {
      console.error('Error creating order:', error);
      toast.error(error.response?.data?.message || 'Failed to create order');
    }
  };

  const handleSubmitInvoice = () => {
    // Implement the logic for submitting the invoice
    console.log('Submitting invoice...');
    toast.success('Invoice submitted successfully!');
  };

  const [showOwnerPasscodeModal, setShowOwnerPasscodeModal] = useState(false);

  const handleReleaseForPayment = () => {
    setShowOwnerPasscodeModal(true); // Open the owner passcode modal only when needed
  };





  const handleInvoicePush = async () => {
    try {

      const latestCountResponse = await invoiceCountAPI();
      setInvoiceCount(latestCountResponse.data.count);

      const totalAmount = invoiceItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
    


      const isReleasedPayment = totalAmount === 0 && ownerPasscodeVerified;

      const description = isReleasedPayment 
      ? `Invoice for table ${tableId} - RELEASED FROM PAYMENT BY OWNER`
      : `Invoice for table ${tableId}`;

      let clientId;
        try {
          const response = await api.post('/api/accounting/clients/check-or-create', {
            name: `${user.name} ${user.surname}`,
            phone: user.phoneNumber || '',
            email: user.email,
            address: user.address || ''
          });
          clientId = response.data.id;
        } catch (error) {
          console.error('Error checking or creating client:', error);
          toast.error('Failed to check or create client.');
          return;
        }

      const invoiceNumber = generateInvoiceNumber();
      const invoiceData = {
        invoiceNumber,
        clientId,
        invoiceDate: new Date(),
        reference: invoiceNumber,
        terms: 'Payment due upon receipt',
        notes: isReleasedPayment 
        ? 'Restaurant Order Invoice - Released from payment by owner authorization'
        : 'Restaurant Order Invoice',
        paymentMethod: 'CASH',
        status: 'Completed',
        totalAmount,
        items: invoiceItems.map(item => ({
          details: item.productName,
          quantity: item.quantity,
          rate: item.price,
          amount: item.price * item.quantity,
          discount: 0,
          tax: 0
        }))
      };

      const transactionData = {
        acceptedBy: "Lake Side Hotel",
        amount: totalAmount,
        paymentType: 'CASH',
        transactionType: "PAYMENT",
        clientId,
        accountId: 11, // Main Income account
        description:description,
        TransactionProduct: invoiceItems.map(item => ({
          item: item.productName,
          type: 'Food/Beverage',
          unit: 'Piece',
          quantity: item.quantity,
          unitPrice: item.price,
          soldPrice: item.price,
        }))
      };


      //test code for the change of the order status

      const updateOrderStatuses = async () => {
        try {
          // Instead of using orderId from invoiceItems, we'll update based on tableId
          await api.put(`/api/orders/table/${tableId}/status`, {
            status: 'paid',
            waiterId: waiterId,
            amount: totalAmount
          });
      
          // If it's a room service order
          if (selectedRoom) {
            await api.put(`/api/room-service-orders/${selectedRoom}`, {
              status: 'completed',
              paymentStatus: 'paid'
            });
          }
      
          // Update bar orders if any
          const barItems = invoiceItems.filter(item => item.type === 'barItem');
          if (barItems.length > 0) {
            await api.put(`/api/bar-orders/table/${tableId}`, {
              status: 'completed',
              paymentStatus: 'paid'
            });
          }
        } catch (error) {
          console.error('Error updating order statuses:', error);
          throw error;
        }
      };
      
      await Promise.all([
        createInvoice(invoiceData),
        createTransaction(transactionData),
        adjustAccountValue(2, totalAmount), // Adjust restaurant income account
        finalizeOrder(waiterId, totalAmount, description),
        updateOrderStatuses()
      ]);
  


      
      setInvoiceItems([]);
      setIsOrderSubmitted(false);
      // Call the finalizeOrder API to update waiter log
      // await finalizeOrder(waiterId, totalAmount, description);
      
      toast.success('Invoice finalized and waiter log updated!');
    } catch (error) {
      console.error('Error finalizing invoice:', error);
      toast.error('Failed to finalize invoice.');
    }
  };


  const handleOwnerCodeSubmit = async () => {
    try {
      const response = await verifyOwnerPasscode(ownerCode);
      if (response.data.verified) {
        setOwnerPasscodeVerified(true);
        setShowOwnerPasscodeModal(false); // Close the modal after successful verification
        
        const origPrices = {};
        const updatedItems = invoiceItems.map(item => {
          origPrices[item.id] = item.price;
          return { 
            ...item, 
            price: 0,
            releaseReason: 'Released from payment by owner' 
          };
        });
        setOriginalPrices(origPrices);
        setInvoiceItems(updatedItems);
        
        toast.success('Owner passcode verified. Items released from payment.');
      } else {
        toast.error('Invalid owner passcode');
      }
    } catch (error) {
      console.error('Error verifying owner passcode:', error);
      toast.error('Failed to verify owner passcode');
    }
    setOwnerCode('');
  };

  const handleCategoryChange = useCallback((category, room) => {
    setSelectedCategory(category);
    setSelectedRoom(room);
  }, []);

  useEffect(() => {
    // Verify table ID is available
    if (!tableId) {
      toast.error('No table selected');
      return;
    }
    
    // Log the table ID for debugging
    console.log('Current table ID:', tableId);
  }, [tableId]);

  const handleSubmitOrder = async () => {
    try {
      if (!tableId) {
        toast.error('No table selected');
        return;
      }

      let orderDetails;
      let response;

      switch (selectedCategory) {
        case 'kitchen':
          if (kitchenOrderItems.length > 0) {
            orderDetails = {
              tableId: parseInt(tableId, 10),
              dishOrderProducts: kitchenOrderItems.map(item => ({
                id: item.id,
                quantity: item.quantity,
                price: item.price
              })),
              totalPrice: kitchenOrderItems.reduce((total, item) => 
                total + item.price * item.quantity, 0),
              waiterId: waiterId
            };
            
            console.log('Submitting kitchen order:', orderDetails);
            response = await createDishOrder(orderDetails);
            setInvoiceItems(prevItems => {
              const newItems = [...prevItems];
              kitchenOrderItems.forEach(orderItem => {
                const existingItemIndex = newItems.findIndex(item => item.id === orderItem.id);
                if (existingItemIndex !== -1) {
                  newItems[existingItemIndex] = {
                    ...newItems[existingItemIndex],
                    quantity: newItems[existingItemIndex].quantity + orderItem.quantity
                  };
                } else {
                  newItems.push({
                    ...orderItem,
                    orderId: tableId
                  });
                }
              });
              return newItems;
            });

            setKitchenOrderItems([]);
            setShowPaymentModal(true);

            setIsOrderSubmitted(true);
            toast.success('Kitchen order submitted successfully');
          }
          break;

        case 'bar':
          if (barOrderItems.length > 0) {
            orderDetails = {
              tableId: parseInt(tableId, 10),
              barOrderProducts: barOrderItems.map(item => ({
                barProductId: item.id,
                quantity: item.quantity,
                price: item.price
              })),
              totalPrice: barOrderItems.reduce((total, item) => 
                total + item.price * item.quantity, 0),
              waiterId: waiterId
            };
            
            console.log('Submitting bar order:', orderDetails);
            response = await createBarOrder(orderDetails);
            setInvoiceItems(prevItems => {
              const newItems = [...prevItems];
              barOrderItems.forEach(orderItem => {
                const existingItemIndex = newItems.findIndex(item => item.id === orderItem.id);
                if (existingItemIndex !== -1) {
                  newItems[existingItemIndex] = {
                    ...newItems[existingItemIndex],
                    quantity: newItems[existingItemIndex].quantity + orderItem.quantity
                  };
                } else {
                  newItems.push({
                    ...orderItem,
                    orderId: tableId
                  });
                }
              });
              return newItems;
            });

            setBarOrderItems([]);
            setIsOrderSubmitted(true);
            toast.success('Bar order submitted successfully');
          }
          break;

        case 'conferenceRoom':
          if (conferenceRoomOrderItems.length > 0 || conferenceRoomBarItems.length > 0) {
            orderDetails = {
              tableId: parseInt(tableId, 10),
              conferenceRoomId: selectedReservation?.conferenceRoomId,
              reservationId: selectedReservation?.id,
              orderItems: [
                ...conferenceRoomOrderItems.map(item => ({
                  id: item.id,
                  quantity: item.quantity,
                  price: item.price,
                  type: 'dish'
                })),
                ...conferenceRoomBarItems.map(item => ({
                  id: item.id,
                  quantity: item.quantity,
                  price: item.price,
                  type: 'barItem'
                }))
              ],
              totalPrice: [...conferenceRoomOrderItems, ...conferenceRoomBarItems].reduce((total, item) => 
                total + item.price * item.quantity, 0),
              waiterId: waiterId
            };
            
            console.log('Submitting conference room order:', orderDetails);
            response = await createConferenceRoomOrder(orderDetails);
            setInvoiceItems(prevItems => [...prevItems, ...conferenceRoomOrderItems, ...conferenceRoomBarItems]);
            setConferenceRoomOrderItems([]);
            setConferenceRoomBarItems([]);
            setIsOrderSubmitted(true);
            toast.success('Conference room order submitted successfully');
          } else {
            toast.error('No items to submit');
          }
          break;

        case 'room':
          if (!selectedRoom) {
            toast.error('Please select a room');
            return;
          }

          orderDetails = {
            roomNumber: selectedRoom,
            waiterId: waiterId,
            tableId: tableId,
            totalPrice: kitchenOrderItems.reduce((total, item) => 
              total + parseFloat(item.price) * parseInt(item.quantity, 10), 0) +
              barOrderItems.reduce((total, item) => 
              total + parseFloat(item.price) * parseInt(item.quantity, 10), 0),
            orderItems: [
              ...kitchenOrderItems.map(item => ({
                ...item,
                category: 'KITCHEN',
                type: 'dish'
              })),
              ...barOrderItems.map(item => ({
                ...item,
                category: 'BAR',
                type: 'barItem'
              }))
            ]
          };

          console.log("Submitting room service order:", orderDetails);
          response = await createRoomServiceOrder(orderDetails);
          setInvoiceItems(prevItems => [...prevItems, ...kitchenOrderItems, ...barOrderItems]);
          setKitchenOrderItems([]);
          setBarOrderItems([]);
          setIsOrderSubmitted(true);
          toast.success('Room service order created successfully');

          break;

        default:
          toast.error('Invalid category selected');
          return;
      }

      if (response) {
        console.log(`${selectedCategory} order response:`, response);
        setShowPaymentModal(true); // Open the calculator modal
      }

    } catch (error) {
      console.error('Error submitting order:', error);
      toast.error(error.response?.data?.message || 'Failed to submit order');
    }
  };

  const addItemToOrder = useCallback((newItem) => {
    if (selectedCategory === 'room') {
      if (newItem.type === 'barItem') {
        setBarOrderItems(prevItems => {
          const existingItem = prevItems.find(item => item.id === newItem.id);
          if (existingItem) {
            return prevItems.map(item => 
              item.id === newItem.id 
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
          }
          return [...prevItems, newItem];
        });
      } else {
        setKitchenOrderItems(prevItems => {
          const existingItem = prevItems.find(item => item.id === newItem.id);
          if (existingItem) {
            return prevItems.map(item => 
              item.id === newItem.id 
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
          }
          return [...prevItems, newItem];
        });
      }
    } else if (selectedCategory === 'conferenceRoom') {
      if (newItem.type === 'barItem') {
        setConferenceRoomBarItems(prevItems => {
          const existingItem = prevItems.find(item => item.id === newItem.id);
          if (existingItem) {
            return prevItems.map(item => 
              item.id === newItem.id 
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
          }
          return [...prevItems, newItem];
        });
      } else {
        setConferenceRoomOrderItems(prevItems => {
          const existingItem = prevItems.find(item => item.id === newItem.id);
          if (existingItem) {
            return prevItems.map(item => 
              item.id === newItem.id 
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
          }
          return [...prevItems, newItem];
        });
      }
    } else if (newItem.type === 'barItem') {
      setBarOrderItems(prevItems => {
        const existingItem = prevItems.find(item => item.id === newItem.id);
        if (existingItem) {
          return prevItems.map(item => 
            item.id === newItem.id 
              ? { ...item, quantity: item.quantity + 1 }
              : item
          );
        }
        return [...prevItems, newItem];
      });
    } else {
      setKitchenOrderItems(prevItems => {
        const existingItem = prevItems.find(item => item.id === newItem.id);
        if (existingItem) {
          return prevItems.map(item => 
            item.id === newItem.id 
              ? { ...item, quantity: item.quantity + 1 }
              : item
          );
        }
        return [...prevItems, newItem];
      });
    }
  }, [selectedCategory, setBarOrderItems, setKitchenOrderItems, setConferenceRoomOrderItems, setConferenceRoomBarItems]);

  return (
    <div className="flex flex-col md:flex-row h-screen bg-tealish-blue">
      <Sidebar onCategoryChange={handleCategoryChange} menu={menu} addItemToOrder={addItemToOrder} />

      <main ref={dropTargetRef} className="flex-1 p-4 md:p-10 bg-mercury flex flex-col">
        <div className="mt-8 w-full flex flex-col md:flex-row justify-between items-center mb-4 md:mb-8">
          <h1 className="text-2xl md:text-3xl font-bold text-bluish-grey">
            Order Management ({selectedCategory})
          </h1>
          <button
            className="bg-hazel-green text-white px-4 py-2 rounded-md shadow-md hover:bg-sage transition duration-300 mt-4 md:mt-0"
            onClick={handleSubmitOrder}
            disabled={isTableAssigned && !isCurrentWaiterAssigned}
          >
            Submit Order
          </button>
        </div>

        <div className="flex flex-col lg:flex-row justify-between items-start gap-4 md:gap-8">
          {/* Left container for Order and Invoice Tables */}
          <div className="flex flex-col w-full lg:w-8/12 gap-4 md:gap-8">
            <div className="w-full bg-seashell shadow-lg rounded-lg p-4 md:p-5">
              <div className="flex flex-row items-center justify-between">
                <h2 className="text-xl md:text-2xl font-semibold text-bluish-grey mb-2 md:mb-4">
                  Order Items
                </h2>
                <IconCubeSend size={30} className="mb-2 md:mb-4 text-hazel-green" />
              </div>

              <OrderTable
                items={
                  selectedCategory === 'room'
                    ? [...kitchenOrderItems, ...barOrderItems]
                    : selectedCategory === 'bar'
                    ? barOrderItems
                    : selectedCategory === 'conferenceRoom'
                    ? [...conferenceRoomOrderItems, ...conferenceRoomBarItems]
                    : kitchenOrderItems
                }
                setItems={
                  selectedCategory === 'bar'
                    ? setBarOrderItems
                    : selectedCategory === 'conferenceRoom'
                    ? setConferenceRoomOrderItems
                    : setKitchenOrderItems
                }
                selectedCategory={selectedCategory}
                tableId={tableId}
                selectedRoom={selectedRoom}
                setBarOrderItems={setBarOrderItems}
                setKitchenOrderItems={setKitchenOrderItems}
                setConferenceRoomOrderItems={setConferenceRoomOrderItems}
                setConferenceRoomBarItems={setConferenceRoomBarItems}
              />
            </div>

            <div className="w-full bg-seashell shadow-lg rounded-lg p-4 md:p-5">
              <div className="flex flex-row items-center justify-between mb-2 md:mb-4">
                <div className="flex flex-row items-center">
                  <h2 className="text-xl md:text-2xl font-semibold text-bluish-grey mr-2 md:mr-4">
                    Invoice Items
                  </h2>
                  <IconReceipt size={25} className="text-hazel-green" />
                </div>
              </div>
              <InvoiceTable
                items={invoiceItems}
                setItems={setInvoiceItems}
                ownerPasscodeVerified={ownerPasscodeVerified}
                originalPrices={originalPrices}
              />
            </div>
          </div>

          {/* Right container for Calculator */}
          <div className={`w-full lg:w-4/12 bg-white rounded-lg shadow-lg p-4 ${isOrderSubmitted ? 'block' : 'hidden'}`}>
            <Calculator
              handleSubmitInvoice={handleSubmitInvoice}
              handleReleaseForPayment={handleReleaseForPayment}
              handleInvoicePush={handleInvoicePush}
            />
          </div>
          {/* {isOrderSubmitted && (
            <div className="w-full lg:w-4/12 flex justify-center calculator-transition">
              <Calculator
                handleSubmitInvoice={handleSubmitInvoice}
                handleReleaseForPayment={handleReleaseForPayment}
                handleInvoicePush={handleInvoicePush}
              />
            </div>
          )} */}
        </div>
      </main>
      <ToastContainer />

      {/* Modal for owner code */}
      {showOwnerPasscodeModal && (
        <Modal onClose={() => setShowOwnerPasscodeModal(false)}>
          <div>
            <h2 className="text-lg font-bold mb-4">Enter Owner Code</h2>
            <input
              type="password"
              placeholder="Owner Code"
              value={ownerCode}
              onChange={(e) => setOwnerCode(e.target.value)}
              className="border border-gray-300 p-2 mb-4 w-full"
            />
            <button
              onClick={handleOwnerCodeSubmit}
              className="bg-sage text-white py-2 px-4 rounded-md"
            >
              Submit
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default OrderPage;