// src/components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-tealish-blue text-center p-6">
        <div className="bg-white shadow-xl rounded-lg p-10 max-w-xl w-full transform transition-all duration-500 hover:scale-105">
          {/* Pulsating 404 animation */}
          <h1 className="text-9xl font-extrabold text-salmon-pink animate-pulse mb-6">
            404
          </h1>
  
          {/* Description text */}
          <p className="mt-4 text-xl text-bluish-grey font-medium">
            Oops! The page you are looking for does not exist.
          </p>
  
          {/* Call to action */}
          <Link
            to="/"
            className="mt-8 inline-block bg-hazel-green text-white font-semibold rounded-full px-6 py-3 hover:bg-hazel-green-600 transition-all duration-300 shadow-lg"
          >
            Go Back to Home
          </Link>
        </div>
      </div>
    );
  };

export default NotFound;
