import React, { useState, useEffect } from 'react';
import SelectInput from './select-input'; // Import the SelectInput component

function Food({ dishSections }) {
  const [foods, setFoods] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('all'); // State for selected type

  useEffect(() => {
    setFoods(dishSections);

    const types = [...new Set(dishSections.map(section => section.type))];
    setUniqueTypes(types);
  }, [dishSections]);

  const handleTypeChange = (event) => {
    const type = event.target.value;
    setSelectedType(type);
    if (type === 'all') {
      setFoods(dishSections);
    } else {
      const filtered = dishSections.filter(section => section.type === type);
      setFoods(filtered);
    }
  };

  const truncateDescription = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div className='m-auto px-6 py-12'>
      <h1 className='italic text-hazel-green font-bold text-4xl text-center'>
        Our Menu
      </h1>

      {/* Filter Row */}
      <div className='flex flex-col lg:flex-row justify-between'>
        {/* Filter Type */}
        <div>
          <p className='my-4 text-hazel-green font-bold'>Filter Type</p>
          <SelectInput
            value={selectedType}
            label="Select Type"
            options={[{ id: 'all', title: 'All' }, ...uniqueTypes.map(type => ({ id: type, title: type }))]}
            onChange={handleTypeChange}
            isEntity={false}
          />
        </div>
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 pt-4'>
        {foods.map((section) => (
          <div
            key={section.id}
            className='border shadow-lg rounded-lg hover:scale-105 duration-300'
          >
            <img
              src={
                section.imageUrl
                  ? `http://localhost:3001${section.imageUrl}`
                  : '/images/default-placeholder.png'
              }
              alt={section.name || 'Dish Section'}
              className='w-full h-[150px] sm:h-[200px] object-cover rounded-t-lg'
            />
            <div className='flex flex-col px-4 py-4 gap-2'>
              <p className='font-bold text-lg sm:text-xl'>{section.name}</p>
              <p className='text-gray-600 text-sm'>{truncateDescription(section.description, 70)}</p>
              <p className='text-gray-600 text-sm'><strong>Type:</strong> {section.type}</p>
              <p className='text-gray-600 text-sm'><strong>Available:</strong> {section.isAvailable ? 'Yes' : 'No'}</p>
              <p className='font-bold'>Menu: {section.menu.name}</p>
              <p>
                <span className='bg-hazel-green text-white p-1 px-4 rounded-full'>
                  {section.price}$
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Food;