import React, { useState } from 'react';
import { FieldArray } from 'formik';
import CustomInput from '../custom-input';
import SelectInput from '../select-input';
import ConfirmMessage from '../alerts/alert-message';
import { productTypes, units } from '../../constants/entities';
import { deleteProduct } from '../../api/products';

export default function ProductsList({
  values,
  setFieldValue,
  handleBlur,
  calculateVatPrice,
  calculateInvoiceVATPrice,
  calculateTotalPrice,
  isEdit,
  errors,
  touched
}) {
  const [showAlert, setShowAlert] = useState(false);
  const [rowToDelete, setRowToDelete] = useState('');

  const handleDeleteRow = async (id) => {
    setRowToDelete(id);
    setShowAlert(true);
  };

  const deleteRow = async () => {
    try {
      await deleteProduct(rowToDelete).then(() => {
        const updatedProducts = values.products.filter(
          (item) => item.id !== rowToDelete
        );
        setFieldValue('products', updatedProducts);
        calculateVatPrice(updatedProducts, setFieldValue);
        calculateInvoiceVATPrice(updatedProducts, setFieldValue);
        calculateTotalPrice(updatedProducts, setFieldValue);
        setShowAlert(false);
      });
    } catch (err) {
      return err;
    }
  };

  const formatToTwoDecimals = (number) => parseFloat(number.toFixed(2));

  return (
    <>
      <h3 className='text-bluish-grey font-medium uppercase mt-3'>Products</h3>
      <div className='border-b boder-hazel-green my-2' />
      <FieldArray name='products'>
        {({ push, remove }) => (
          <div>
            {values.products.map((product, index) => {
              return (
                <div
                  key={index}
                  className='border-b border-dashed  boder-hazel-green py-4'
                >
                  <div className='grid grid-cols-4 gap-x-3'>
                    <CustomInput
                      type='text'
                      name='name'
                      label='Product Name'
                      value={product.name}
                      onChange={(event) => {
                        setFieldValue(
                          `products[${index}].name`,
                          event.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      showError={errors[index]?.name && touched[index]?.name}
                      errorMessage={errors[index]?.name}
                    />
                    <SelectInput
                      value={product.type}
                      label='Type'
                      options={productTypes}
                      onChange={(event) => {
                        setFieldValue(
                          `products[${index}].type`,
                          event.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      showError={errors[index]?.type && touched[index]?.type}
                      errorMessage={errors[index]?.type}
                      isEntity={true}
                    />
                    <CustomInput
                      type='number'
                      name='quantity'
                      label='Quantity'
                      value={product.quantity}
                      onChange={(event) => {
                        const newValue = parseFloat(event.target.value);
                        setFieldValue(`products[${index}].quantity`, newValue);
                        const newTotalPrice = formatToTwoDecimals(
                          newValue * product.unitPrice
                        );
                        setFieldValue(
                          `products[${index}].totalPrice`,
                          newTotalPrice
                        );
                        calculateVatPrice(values.products, setFieldValue);
                        calculateInvoiceVATPrice(
                          values.products,
                          setFieldValue
                        );
                        calculateTotalPrice(values.products, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      showError={
                        errors[index]?.quantity && touched[index]?.quantity
                      }
                      errorMessage={errors[index]?.quantity}
                    />
                    <SelectInput
                      value={product.unit}
                      label='Unit'
                      options={units}
                      onChange={(event) => {
                        setFieldValue(
                          `products[${index}].unit`,
                          event.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      showError={errors[index]?.unit && touched[index]?.unit}
                      errorMessage={errors[index]?.unit}
                      isEntity={true}
                    />
                  </div>
                  <div className='grid grid-cols-6 gap-x-3'>
                    <CustomInput
                      type='number'
                      name='unitPrice'
                      label='Unit Price'
                      value={product.unitPrice}
                      onChange={(event) => {
                        const newValue = parseFloat(event.target.value);
                        setFieldValue(`products[${index}].unitPrice`, newValue);
                        const newTotalPrice = formatToTwoDecimals(
                          newValue * product.quantity
                        );
                        setFieldValue(
                          `products[${index}].totalPrice`,
                          newTotalPrice
                        );
                        calculateVatPrice(values.products, setFieldValue);
                        calculateInvoiceVATPrice(
                          values.products,
                          setFieldValue
                        );
                        calculateTotalPrice(values.products, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      showError={
                        errors[index]?.unitPrice && touched[index]?.unitPrice
                      }
                      errorMessage={errors[index]?.unitPrice}
                    />
                    <CustomInput
                      type='number'
                      name='soldPrice'
                      label='Sold Price'
                      value={product.soldPrice}
                      onChange={(event) => {
                        const newValue = parseFloat(event.target.value);
                        setFieldValue(`products[${index}].soldPrice`, newValue);
                      }}
                      onBlur={handleBlur}
                      showError={
                        errors[index]?.soldPrice && touched[index]?.soldPrice
                      }
                      errorMessage={errors[index]?.soldPrice}
                    />
                    <CustomInput
                      type='number'
                      name='discountPercentage'
                      label='Discount'
                      value={product.discountPercentage}
                      onChange={(event) => {
                        setFieldValue(
                          `products[${index}].discountPercentage`,
                          event.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      showError={
                        errors[index]?.discountPercentage &&
                        touched[index]?.discountPercentage
                      }
                      errorMessage={errors[index]?.discountPercentage}
                    />
                    <CustomInput
                      type='number'
                      name={`products[${index}].vat`}
                      label='VAT'
                      value={product.vat}
                      onChange={(event) => {
                        const newValue = parseFloat(event.target.value);
                        setFieldValue(`products[${index}].vat`, newValue);
                        calculateVatPrice(values.products, setFieldValue);
                        calculateInvoiceVATPrice(
                          values.products,
                          setFieldValue
                        );
                        calculateTotalPrice(values.products, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      showError={errors[index]?.vat && touched[index]?.vat}
                      errorMessage={errors[index]?.vat}
                    />
                    <CustomInput
                      type='number'
                      name='totalPrice'
                      label='Total Price'
                      value={formatToTwoDecimals(
                        product.discountPercentage
                          ? product.unitPrice *
                              (1 - product.discountPercentage / 100) *
                              (1 + (product.vat || 0) / 100) *
                              product.quantity
                          : product.unitPrice *
                              (1 + (product.vat || 0) / 100) *
                              product.quantity
                      )}
                      disabled
                    />
                    <div>
                      <button
                        type='button'
                        className='text-error bg-dawn-pink rounded-md py-3.5 mb-3 w-full'
                        onClick={() => {
                          if (isEdit) {
                            handleDeleteRow(product.id);
                          } else {
                            const updatedProducts = values.products.filter(
                              (_, i) => i !== index
                            );
                            setFieldValue('products', updatedProducts);
                            calculateVatPrice(updatedProducts, setFieldValue);
                            calculateInvoiceVATPrice(
                              updatedProducts,
                              setFieldValue
                            );
                            calculateTotalPrice(updatedProducts, setFieldValue);
                            remove(index);
                          }
                        }}
                      >
                        x
                      </button>
                    </div>
                  </div>
                  <div className='w-full'>
                    <CustomInput
                      type='text'
                      name='description'
                      label='Description'
                      value={product.description}
                      onChange={(event) => {
                        setFieldValue(
                          `products[${index}].description`,
                          event.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      multiline={true}
                      showError={
                        errors[index]?.description &&
                        touched[index]?.description
                      }
                      errorMessage={errors[index]?.description}
                    />
                  </div>
                </div>
              );
            })}
            <div className='flex justify-center items-center'>
              <button
                type='button'
                className='border-dashed border-2 border-sage w-1/2 text-sage rounded-md mt-4 py-2'
                onClick={() =>
                  push({
                    name: '',
                    type: '',
                    quantity: null,
                    unit: '',
                    unitPrice: null,
                    discountPercentage: 0,
                    vat: null,
                    totalPrice: null,
                    description: ''
                  })
                }
              >
                Add product
              </button>
            </div>
          </div>
        )}
      </FieldArray>
      {showAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this?'
          onCancel={() => setShowAlert(!showAlert)}
          onConfirm={() => deleteRow()}
        />
      )}
    </>
  );
}
