import React, { useState, useEffect } from "react";
export function FunctionButton({ small = false, green = false, isActive = false, className = "", ...props }) {
    const sizeClasses = small ? "px-3 py-1" : "px-4 py-2 font-bold";
  
    const colorClasses = green
      ? "bg-sage focus-visible:bg-[#61805b] hover:bg-[#61805b]"
      : "bg-blue-500 focus-visible:bg-blue-400 hover:bg-blue-500";
  
    const textColorClasses = isActive ? "text-white" : "text-[#61805b]";
  
    const buttonClasses = `rounded-full transition-colors duration-200 disabled:cursor-not-allowed disabled:opacity-50 ${sizeClasses} ${isActive ? colorClasses : "border border-sage"} ${textColorClasses} ${className}`;
  
    return (
      <button className={buttonClasses} {...props}></button>
    );
  }
  