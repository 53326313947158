

import React, { useRef } from 'react';
import { IconScale } from '@tabler/icons-react';
import BalanceSheet from './BilanceSheet';
import { IconPdf, IconFileSpreadsheet } from '@tabler/icons-react';
import PageTitleWithButtons from '../../../../../components/PageTitleWithButtons';
import html2pdf from 'html2pdf.js';  // Import html2pdf.js for PDF export
import * as XLSX from 'xlsx'; // Import xlsx for Excel export

function BalanceContainer() {

  // Create a reference to the balance sheet component
  const balanceSheetRef = useRef(null);

  // Function to handle the PDF export
  const handleExportPDF = () => {
    const element = balanceSheetRef.current; // Reference the BalanceSheet component
    const options = {
      margin: 0.5,
      filename: 'balance-sheet.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    
    // Generate the PDF from the HTML content
    html2pdf().from(element).set(options).save();
  };

  // Function to handle Excel export
  const handleExportExcel = () => {
    // The data you want to export to Excel
    const data = [
      ["Category", "Name", "Previous Quarter", "Current Quarter"],
      ...dynamicBalanceSheetData.flatMap(section => [
        [section.category, "", "", ""],
        ...section.items.map(item => [
          "", item.name, item.prev, item.current 
        ]),
        ["", section.total.name, section.total.prev, section.total.current] // Add totals
      ]),
      ["", "Gjithsej detyrimet dhe ekuititeti i aksionarëve", "737,655", "731,360"] // Final total
    ];

    // Create a new workbook and worksheet
    const ws = XLSX.utils.aoa_to_sheet(data); // Convert array to sheet
    const wb = XLSX.utils.book_new(); // Create new workbook
    XLSX.utils.book_append_sheet(wb, ws, "Balance Sheet"); // Append worksheet

    // Export to Excel file
    XLSX.writeFile(wb, "balance-sheet.xlsx");
  };

  // Example balance sheet data from the BalanceSheet component (for demonstration)
  const dynamicBalanceSheetData = [
    {
      category: "Pasuritë",
      items: [
        { name: "Paraja e gatshme dhe gjendja me BQK-në", prev: "75,463", current: "75,979" },
        { name: "Kërkesat ndaj bankave", prev: "92,656", current: "84,964" },
        { name: "Bonot e thesarit", prev: "25,148", current: "32,770" },
        { name: "Investimet në letra me vlerë", prev: "113,976", current: "110,036" },
        { name: "Kreditë dhe paradhëniet ndaj klientëve", prev: "418,361", current: "415,126" },
        { name: "Patundshmëritë dhe pajisjet", prev: "4,410", current: "4,678" },
        { name: "Pasuritë e paprekshme", prev: "1,982", current: "2,236" },
        { name: "Pasuritë tatimore të shtyra", prev: "170", current: "0" },
        { name: "Pasuritë tjera", prev: "5,489", current: "5,571" },
      ],
      total: { name: "Gjithsej pasuritë", prev: "737,655", current: "731,360" },
    },
    {
      category: "Detyrimet",
      items: [
        { name: "Depozitat e klientëve", prev: "611,692", current: "603,053" },
        { name: "Detyrimet ndaj bankave", prev: "2,186", current: "806" },
        { name: "Fondet tjera të huazuara", prev: "23,407", current: "24,908" },
        { name: "Detyrimet tatimore të shtyra", prev: "0", current: "113" },
        { name: "Detyrimet tjera", prev: "10,970", current: "9,277" },
      ],
      total: { name: "Gjithsej detyrimet", prev: "648,255", current: "638,157" },
    },
    {
      category: "Ekuiteti i aksionarëve",
      items: [
        { name: "Kapitali aksionar", prev: "63,000", current: "63,000" },
        { name: "Rezervat e kapitalit", prev: "0", current: "0" },
        { name: "Fitimi i mbajtur/(humbja) nga vitet paraprake", prev: "16,946", current: "16,946" },
        { name: "Fitimi/(humbja) e vitit aktual", prev: "9,454", current: "13,257" },
      ],
      total: { name: "Gjithsej ekuiteti i aksionarëve", prev: "89,400", current: "93,203" },
    },
  ];

  return (
    <div className='p-10 bg-seashell'>
      <PageTitleWithButtons
        title="Balance Sheet"
        icon={<IconScale className='text-hazel-green font-bold mx-4' size={24} />}
        buttons={[
          <div key="1" className='flex gap-x-3'>
            <button
              className='flex items-center text-white rounded-md bg-sage px-4 py-2.5'
              onClick={handleExportPDF}  // Call the PDF export function
            >
              <IconPdf className='mr-2' size={20} />
              Download PDF
            </button>
            <button
              onClick={handleExportExcel}  // Call the Excel export function
              className='flex items-center text-white rounded-md bg-sage px-4 py-2.5'
            >
              <IconFileSpreadsheet className='mr-2' size={20} />
              Excel
            </button>
          </div>
        ]}
      />

      <div ref={balanceSheetRef}>
        <BalanceSheet data={dynamicBalanceSheetData} />
      </div>
    </div>
  );
}

export default BalanceContainer;

