import api from './noAuthApi';

export const generateIncomeStatement = async (params) => {
  try {
    const response = await api.post('/api/income-statement', {
      startDate: params.startDate,
      endDate: params.endDate,
      locationId: params.locationId
    });
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const getIncomeStatement = async (id) => {
  try {
    const response = await api.get(`/api/income-statement/${id}`);
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const listIncomeStatements = async (locationId) => {
  try {
    const response = await api.get(`/api/income-statement/list/${locationId}`);
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const updateIncomeStatementStatus = async (id, status) => {
  try {
    const response = await api.put(`/api/income-statement/${id}/status`, { status });
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};
