import React from 'react';
import { Damage } from '../../../gobal';
import { ArrowUpDown } from 'lucide-react';

interface DamageTableProps {
  damages: Damage[];
  status: 'in_progress' | 'closed';
}

export default function DamageTable({ damages, status }: DamageTableProps) {
  const filteredDamages = damages.filter(damage => damage.status === status);

  return (
    <div className="overflow-x-auto bg-white rounded-lg shadow">
      <table className="min-w-full">
        <thead className="bg-seashell">
          <tr>
            <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">
              <div className="flex items-center gap-2">
                Inspection Id
                <ArrowUpDown className="h-4 w-4" />
              </div>
            </th>
            <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">Property Name</th>
            <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">Guest Name</th>
            <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">Guest Email</th>
            <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">Status</th>
            <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">Last Updated By</th>
            <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">Created By</th>
            <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">Created At</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-mercury">
          {filteredDamages.length === 0 ? (
            <tr>
              <td colSpan={8} className="px-6 py-4 text-center text-bluish-grey">
                No data available
              </td>
            </tr>
          ) : (
            filteredDamages.map((damage) => (
              <tr key={damage.id} className="hover:bg-tealish-blue transition-colors">
                <td className="px-6 py-4 text-sm text-hazel-green font-medium">{damage.id}</td>
                <td className="px-6 py-4 text-sm text-bluish-grey">{damage.propertyName}</td>
                <td className="px-6 py-4 text-sm text-bluish-grey">{damage.guestName}</td>
                <td className="px-6 py-4 text-sm text-bluish-grey">{damage.guestEmail}</td>
                <td className="px-6 py-4">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${damage.status === 'in_progress' ? 'bg-dawn-pink text-salmon-pink' : 'bg-seashell text-sage'}`}>
                    {damage.status === 'in_progress' ? 'In Progress' : 'Closed'}
                  </span>
                </td>
                <td className="px-6 py-4 text-sm text-bluish-grey">{damage.lastUpdatedBy}</td>
                <td className="px-6 py-4 text-sm text-bluish-grey">{damage.createdBy}</td>
                <td className="px-6 py-4 text-sm text-bluish-grey">
                  {new Date(damage.createdAt).toLocaleDateString()}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}