// DefaultItemsPage.js
import React from "react";
import { IconBox, IconList, IconChartBar } from '@tabler/icons-react';

export default function DefaultItemsPage() {
    return (
        <div className="flex flex-col items-center bg-seashell p-4 py-2">
            <div className="max-w-4xl w-full text-center mt-6">
                <h2 className="text-3xl font-bold text-hazel-green mb-4">Welcome to the Items & Services Section</h2>
                <p className="text-lg text-bluish-grey mb-8">
                    Select an option from the navigation to manage and view your items and services records.
                </p>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="bg-white rounded-lg shadow-lg p-6 border border-mercury transition-transform transform hover:scale-105 hover:shadow-xl">
                        <IconBox className="h-12 w-12 mx-auto text-hazel-green mb-4" />
                        <h3 className="text-xl font-semibold text-hazel-green">Manage Inventory</h3>
                        <p className="text-grey-goose mt-2">
                            View, add, and organize all your products and services in one place.
                        </p>
                    </div>
                    <div className="bg-white rounded-lg shadow-lg p-6 border border-mercury transition-transform transform hover:scale-105 hover:shadow-xl">
                        <IconList className="h-12 w-12 mx-auto text-hazel-green mb-4" />
                        <h3 className="text-xl font-semibold text-hazel-green">Catalog Overview</h3>
                        <p className="text-grey-goose mt-2">
                            Access a complete list of items and services for easy tracking.
                        </p>
                    </div>
                    <div className="bg-white rounded-lg shadow-lg p-6 border border-mercury transition-transform transform hover:scale-105 hover:shadow-xl">
                        <IconChartBar className="h-12 w-12 mx-auto text-hazel-green mb-4" />
                        <h3 className="text-xl font-semibold text-hazel-green">Analyze Sales</h3>
                        <p className="text-grey-goose mt-2">
                            Discover trends and performance insights for your items and services.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
