import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from '@mui/material';
import {
  IconDotsVertical,
  IconPencil,
  IconTrash,
  IconEye
} from '@tabler/icons-react';
import PermissionCheck from '../../utils/PermissionCheck';
import PdfDownload from '../../views/invoices/PdfDownload';
import InvoiceModal from '../../views/invoices/InvoiceModal';

export default function TableMoreMenu({
  rowId,
  entity,
  editLink,
  handleDelete
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showInvoice, setShowInvoice] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className='flex justify-center items-center'>
      <button type='button' aria-describedby={id} onClick={handleClick}>
        <IconDotsVertical className='text-sage cursor-pointer' size={20} />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        sx={{ p: 4 }}
      >
        <PermissionCheck action={`read_${entity}`}>
          <div
            className='flex justify-between items-center text-sage gap-x-2 px-4 py-3 cursor-pointer hover:bg-[#0000000a]'
            onClick={() => setShowInvoice(true)}
          >
            <p>View</p>
            <IconEye size={20} />
          </div>
        </PermissionCheck>
        <PermissionCheck action={`edit_${entity}`}>
          <Link
            className='flex justify-between items-center text-sage gap-x-2 px-3 py-3 cursor-pointer hover:bg-[#0000000a]'
            to={editLink}
          >
            <p>Update</p>
            <IconPencil size={20} />
          </Link>
        </PermissionCheck>
        <PermissionCheck action={`delete_${entity}`}>
          <div
            className='flex justify-between items-center text-sage gap-x-2 px-3 py-3 cursor-pointer hover:bg-[#0000000a]'
            onClick={() => {
              handleDelete();
              setAnchorEl(null);
            }}
          >
            <p>Delete</p>
            <IconTrash size={20} />
          </div>
        </PermissionCheck>
      </Popover>
      {showInvoice && (
        <InvoiceModal
          title='Invoice Info'
          titleSize='text-xl'
          setShowModal={() => setShowInvoice(!showInvoice)}
          children={<PdfDownload rowId={rowId}></PdfDownload>}
        />
      )}
    </div>
  );
}
