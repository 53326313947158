import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, FileText, Calendar, MapPin, Phone, Mail, User, Clock } from 'lucide-react';

interface ViewOfferModalProps {
  isOpen: boolean;
  onClose: () => void;
  offer: {
    id: string;
    documentNumber: string;
    description?: string;
    issueDate: string;
    expiryDate: string;
    status: string;
    client: {
      name: string;
      fiscalNumber?: string;
      address?: string;
      phone?: string;
      email?: string;
    };
    location?: {
      name: string;
      address?: string;
    };
    articles: Array<{
      article: {
        name: string;
        code: string;
      };
      quantity: number;
      unitPrice: number;
      vatRate: number;
      totalAmount: number;
    }>;
    createdAt: string;
    createdBy: string;
  };
}

const ViewOfferModal: React.FC<ViewOfferModalProps> = ({ isOpen, onClose, offer }) => {
  if (!isOpen) return null;

  const calculateTotals = () => {
    const totals = offer.articles.reduce(
      (acc, item) => {
        const subtotal = item.quantity * item.unitPrice;
        const vat = subtotal * (item.vatRate / 100);
        return {
          totalWithoutVAT: acc.totalWithoutVAT + subtotal,
          totalVAT: acc.totalVAT + vat,
          totalWithVAT: acc.totalWithVAT + subtotal + vat,
        };
      },
      { totalWithoutVAT: 0, totalVAT: 0, totalWithVAT: 0 }
    );
    return totals;
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/30 backdrop-blur-sm"
            onClick={onClose}
          />

          {/* Modal */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="relative min-h-screen flex items-center justify-center p-4"
          >
            <div className="relative bg-white/95 backdrop-blur-sm w-full max-w-4xl rounded-2xl shadow-xl overflow-hidden">
              {/* Header */}
              <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-6">
                <div className="flex justify-between items-start">
                  <div>
                    <div className="flex items-center gap-2 mb-2">
                      <FileText className="h-5 w-5 text-hazel-green" />
                      <h2 className="text-xl font-semibold text-hazel-green">
                        {offer.documentNumber}
                      </h2>
                    </div>
                    <p className="text-sm text-bluish-grey">{offer.description}</p>
                  </div>
                  <button
                    onClick={onClose}
                    className="p-2 rounded-lg hover:bg-mercury/20 transition-colors"
                  >
                    <X className="h-5 w-5 text-bluish-grey" />
                  </button>
                </div>
              </div>

              {/* Content */}
              <div className="p-6 space-y-6">
                {/* Client & Location Info */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Client Info */}
                  <div className="bg-seashell/50 rounded-lg p-4 border border-mercury/50">
                    <h3 className="text-sm font-medium text-hazel-green mb-3">Informata për Klientin</h3>
                    <div className="space-y-2">
                      <p className="text-base font-medium text-bluish-grey">{offer.client.name}</p>
                      {offer.client.fiscalNumber && (
                        <p className="text-sm text-bluish-grey">Nr. Fiskal: {offer.client.fiscalNumber}</p>
                      )}
                      {offer.client.address && (
                        <div className="flex items-center gap-2 text-sm text-bluish-grey">
                          <MapPin className="h-4 w-4 text-grey-goose" />
                          {offer.client.address}
                        </div>
                      )}
                      {offer.client.phone && (
                        <div className="flex items-center gap-2 text-sm text-bluish-grey">
                          <Phone className="h-4 w-4 text-grey-goose" />
                          {offer.client.phone}
                        </div>
                      )}
                      {offer.client.email && (
                        <div className="flex items-center gap-2 text-sm text-bluish-grey">
                          <Mail className="h-4 w-4 text-grey-goose" />
                          {offer.client.email}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Dates & Status */}
                  <div className="bg-seashell/50 rounded-lg p-4 border border-mercury/50">
                    <h3 className="text-sm font-medium text-hazel-green mb-3">Detajet e Ofertës</h3>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2 text-sm text-bluish-grey">
                        <Calendar className="h-4 w-4 text-grey-goose" />
                        <span>Data e lëshimit: {offer.issueDate}</span>
                      </div>
                      <div className="flex items-center gap-2 text-sm text-bluish-grey">
                        <Calendar className="h-4 w-4 text-grey-goose" />
                        <span>Data e skadimit: {offer.expiryDate}</span>
                      </div>
                      <div className="flex items-center gap-2 text-sm text-bluish-grey">
                        <User className="h-4 w-4 text-grey-goose" />
                        <span>Krijuar nga: {offer.createdBy}</span>
                      </div>
                      <div className="flex items-center gap-2 text-sm text-bluish-grey">
                        <Clock className="h-4 w-4 text-grey-goose" />
                        <span>Krijuar më: {offer.createdAt}</span>
                      </div>
                      <div className="mt-2">
                        <span className={`inline-flex items-center gap-1 rounded-full px-2.5 py-0.5 text-xs font-medium ${
                          offer.status === 'active'
                            ? 'bg-sage/10 text-sage'
                            : 'bg-grey-goose/10 text-grey-goose'
                        }`}>
                          <span className={`h-1.5 w-1.5 rounded-full ${
                            offer.status === 'active' ? 'bg-sage' : 'bg-grey-goose'
                          }`} />
                          {offer.status === 'active' ? 'Aktive' : 'Skaduar'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Articles Table */}
                <div className="overflow-hidden rounded-lg border border-mercury/50">
                  <table className="min-w-full divide-y divide-mercury/50">
                    <thead>
                      <tr className="bg-gradient-to-r from-hazel-green/5 to-transparent">
                        <th className="px-4 py-3 text-left text-xs font-medium text-hazel-green">Artikulli</th>
                        <th className="px-4 py-3 text-right text-xs font-medium text-hazel-green">Sasia</th>
                        <th className="px-4 py-3 text-right text-xs font-medium text-hazel-green">Çmimi</th>
                        <th className="px-4 py-3 text-right text-xs font-medium text-hazel-green">TVSH %</th>
                        <th className="px-4 py-3 text-right text-xs font-medium text-hazel-green">Totali</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-mercury/50">
                      {offer.articles.map((article, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-seashell/20'}>
                          <td className="px-4 py-3 text-sm text-bluish-grey">
                            <div>
                              <div className="font-medium">{article.article.name}</div>
                              <div className="text-xs text-grey-goose">{article.article.code}</div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-sm text-right text-bluish-grey">{article.quantity}</td>
                          <td className="px-4 py-3 text-sm text-right text-bluish-grey">{article.unitPrice.toFixed(2)} €</td>
                          <td className="px-4 py-3 text-sm text-right text-bluish-grey">{article.vatRate}%</td>
                          <td className="px-4 py-3 text-sm text-right font-medium text-hazel-green">{article.totalAmount.toFixed(2)} €</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Totals */}
                <div className="bg-seashell/50 rounded-lg p-4 border border-mercury/50">
                  <div className="space-y-2">
                    <div className="flex justify-between text-sm text-bluish-grey">
                      <span>Totali pa TVSH</span>
                      <span>{calculateTotals().totalWithoutVAT.toFixed(2)} €</span>
                    </div>
                    <div className="flex justify-between text-sm text-bluish-grey">
                      <span>TVSH</span>
                      <span>{calculateTotals().totalVAT.toFixed(2)} €</span>
                    </div>
                    <div className="flex justify-between text-base font-medium text-hazel-green pt-2 border-t border-mercury/50">
                      <span>Totali me TVSH</span>
                      <span>{calculateTotals().totalWithVAT.toFixed(2)} €</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ViewOfferModal; 