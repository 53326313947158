import api from './api'; // Adjust the import based on your axios setup

export const createDailyLog = (waiterId) => api.post(`/api/waiters/${waiterId}/logs`);

export const updateLosses = (logId, values) => api.put(`/api/waiters/logs/${logId}/losses`, values);

export const getDailyLogs = (waiterId) => api.get(`/api/waiters/${waiterId}/logs`);
export const addTransaction = (logId, transaction) => api.post(`/api/waiters/logs/${logId}/transactions`, transaction);

export const aggregateTotalLogs = (waiterId) => api.post(`/api/waiters/${waiterId}/logs/aggregate`);
export const getTotalLogs = (waiterId) => api.get(`/api/waiters/${waiterId}/logs/total`);
export const getLogsAndTransactions = (waiterId) => api.get(`/api/waiters/${waiterId}/logs/transactions`);

export const getAllWaiters = () => api.get(`/api/waiters`);

export const getUnassignedTables = () => api.get(`/api/waiters/tables/unassigned`);

export const assignTableToWaiter = (waiterId, tableId, assignmentDate) =>
  api.post(`/api/waiters/${waiterId}/tables`, { tableId, assignmentDate });

export const getAssignedTables = (waiterId) =>
  api.get(`/api/waiters/${waiterId}/tables`);

// Remove specific table assignments from a waiter
export const removeTableAssignments = (waiterId, tableIds) =>
  api.delete(`/api/waiters/${waiterId}/tables`, { data: { tableIds } });

export const finalizeOrder = (waiterId, amount, description) =>
  api.post(`/api/waiters/${waiterId}/orders/finalize`, { amount, description });


export const searchTransactionsByDate = (waiterId, date) =>
  api.get(`/api/waiters/${waiterId}/transactions/search`, { params: { date } });

export const reconcileWaiter = (waiterId) => api.post(`/api/waiters/${waiterId}/reconcile`);

export const getDataSinceReconciliation = (waiterId) =>
  api.get(`/api/waiters/${waiterId}/data-since-reconciliation`);

export const getDataWithTransactions = (waiterId) =>
  api.get(`/api/waiters/${waiterId}/data-with-transactions`);
