import React, { useState } from 'react';
import { PageHeader } from '../../components/hotelInterfaceComponents/lists/PageHeader.tsx';
import { SearchBar } from '../../components/hotelInterfaceComponents/lists/SearchBar.tsx';
import { BookingTable } from '../../components/hotelInterfaceComponents/lists/BookingTable.tsx';
import { Search } from 'lucide-react';

import Navbar from '../../components/hotelInterfaceComponents/Navbar.tsx';

const columns = [
  { key: 'id', label: '#' },
  { key: 'refId', label: 'Ref ID' },
  { key: 'stayDuration', label: 'Stay Duration' },
  { key: 'room', label: 'Room # / Type / Block / Floor' },
  { key: 'pax', label: 'Pax' },
  { key: 'status', label: 'Status' },
  { key: 'deletedAt', label: 'Deleted At' },
  { key: 'charges', label: 'Charges' },
  { key: 'amount', label: 'Amount' },
  { key: 'supplierAccount', label: 'Supplier Account' },
  { key: 'salesChannel', label: 'Sales Channel' },
  { key: 'notes', label: 'Notes' },
];

const CancelledBookings: React.FC = () => {
  const [startDate, setStartDate] = useState('2024-10-30');
  const [endDate, setEndDate] = useState('2024-10-30');
  const [currentPage, setCurrentPage] = useState(1);
  const [enquiryId, setEnquiryId] = useState('');

  return (
    <div className="min-h-screen bg-tealish-blue">
        <Navbar />
      <PageHeader title="Cancelled Bookings List" />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="mb-6">
          <h2 className="text-lg font-medium text-bluish-grey mb-3">
            Quick Search Cancelled Bookings
          </h2>
          <div className="flex gap-4">
            <input
              type="text"
              placeholder="Enter Enquiry ID"
              value={enquiryId}
              onChange={(e) => setEnquiryId(e.target.value)}
              className="flex-1 max-w-md px-4 py-2 border border-grey-goose rounded-md focus:outline-none focus:ring-2 focus:ring-hazel-green"
            />
            <button className="bg-salmon-pink hover:bg-watermelon text-white px-6 py-2 rounded-md transition-colors duration-200 flex items-center">
              <Search size={18} className="mr-2" />
              SEARCH
            </button>
          </div>
        </div>
        <SearchBar
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          onChannelChange={() => {}}
          onSubmit={() => {}}
        />
        <BookingTable
          columns={columns}
          data={[]}
          currentPage={currentPage}
          totalPages={1}
          onPageChange={setCurrentPage}
        />
      </main>
    </div>
  );
};

export default CancelledBookings;