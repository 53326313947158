import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, X, Save } from 'lucide-react';
import { createFiscalYear } from '../../api/company';
import { toast } from 'react-hot-toast';

interface AddFiscalYearModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  companyId: string;
}

const AddFiscalYearModal: React.FC<AddFiscalYearModalProps> = ({ isOpen, onClose, onSuccess, companyId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    endDate: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!companyId) {
      toast.error('Company ID is required');
      return;
    }
    
    setIsLoading(true);
    try {
      await createFiscalYear({
        ...formData,
        companyId,
        createdBy: 'system' // You might want to get this from your auth context
      });
      toast.success('Fiscal year created successfully');
      onSuccess();
      onClose();
    } catch (error) {
      toast.error('Failed to create fiscal year');
      console.error('Error creating fiscal year:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-bluish-grey/20 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="bg-white rounded-2xl shadow-2xl w-full max-w-2xl max-h-[90vh] flex flex-col overflow-hidden border border-grey-goose/30"
          >
            {/* Header */}
            <div className="px-6 py-4 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="p-2 bg-hazel-green/10 rounded-xl">
                    <Calendar className="w-6 h-6 text-hazel-green" />
                  </div>
                  <div>
                    <h2 className="text-xl font-semibold text-bluish-grey">Shto Vit Fiskal</h2>
                    <p className="text-sm text-grey-goose">Plotësoni të dhënat për vitin e ri fiskal</p>
                  </div>
                </div>
                <button
                  onClick={onClose}
                  className="p-2 hover:bg-grey-goose/10 rounded-xl transition-colors"
                >
                  <X className="w-5 h-5 text-bluish-grey" />
                </button>
              </div>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="flex-1 overflow-y-auto">
              <div className="p-6 space-y-6">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-1">
                      Emri i vitit fiskal
                    </label>
                    <input
                      type="text"
                      placeholder="Shkruaj vitin fiskal, psh. 2024, 2025, etj."
                      className="w-full px-4 py-2.5 bg-seashell border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-bluish-grey mb-1">
                        Data kur fillon
                      </label>
                      <input
                        type="date"
                        className="w-full px-4 py-2.5 bg-seashell border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all"
                        value={formData.startDate}
                        onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-bluish-grey mb-1">
                        Data kur mbaron
                      </label>
                      <input
                        type="date"
                        className="w-full px-4 py-2.5 bg-seashell border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all"
                        value={formData.endDate}
                        onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer */}
              <div className="px-6 py-4 bg-seashell border-t border-grey-goose/20">
                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2.5 text-sm font-medium text-bluish-grey bg-white border border-grey-goose/30 rounded-xl hover:bg-grey-goose/10 transition-colors"
                  >
                    Anulo
                  </button>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="px-4 py-2.5 text-sm font-medium text-white bg-hazel-green rounded-xl hover:bg-opacity-90 transition-colors flex items-center space-x-2"
                  >
                    {isLoading ? (
                      <>
                        <motion.div
                          animate={{ rotate: 360 }}
                          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                        >
                          <Calendar className="w-4 h-4" />
                        </motion.div>
                        <span>Duke ruajtur...</span>
                      </>
                    ) : (
                      <>
                        <Save className="w-4 h-4" />
                        <span>Ruaj</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AddFiscalYearModal;
