import React from 'react';
import { Search } from 'lucide-react';

interface SearchBarProps {
  startDate: string;
  endDate: string;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
  onChannelChange: (channel: string) => void;
  onSubmit: () => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onChannelChange,
  onSubmit
}) => {
  return (
    <div className="bg-dawn-pink p-6 rounded-lg shadow-sm mb-6">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Start Date
          </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => onStartDateChange(e.target.value)}
            className="w-full px-3 py-2 border border-grey-goose rounded-md focus:outline-none focus:ring-2 focus:ring-hazel-green"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            End Date
          </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => onEndDateChange(e.target.value)}
            className="w-full px-3 py-2 border border-grey-goose rounded-md focus:outline-none focus:ring-2 focus:ring-hazel-green"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Booking Channel
          </label>
          <select
            onChange={(e) => onChannelChange(e.target.value)}
            className="w-full px-3 py-2 border border-grey-goose rounded-md focus:outline-none focus:ring-2 focus:ring-hazel-green"
          >
            <option value="">Select Channel</option>
            <option value="direct">Direct</option>
            <option value="booking">Booking.com</option>
            <option value="airbnb">Airbnb</option>
            <option value="expedia">Expedia</option>
          </select>
        </div>
        <div className="flex items-end">
          <button
            onClick={onSubmit}
            className="w-full bg-hazel-green hover:bg-sage text-white font-medium py-2 px-4 rounded-md transition-colors duration-200 flex items-center justify-center"
          >
            <Search size={18} className="mr-2" />
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};