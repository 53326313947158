import React, { useState, useEffect } from 'react';
import { X, Wine, ShoppingBag, ClipboardCheck, CheckCircle2, AlertTriangle, Clock, MessageSquareWarning } from 'lucide-react';
import { createCleaningReport, createMajorIssue, getLatestCleaningReport } from '../../api/cleaning';

import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useTranslation } from 'react-i18next';

interface RoomCleaningModalProps {
  isOpen: boolean;
  onClose: () => void;
  roomNumber: string;
  roomType: string;
  inventory: any[];
  minibar: any[];
  date: Date;
}

const defaultCleaningReport = {
  roomCondition: '',
  cleanlinessLevel: '',
  minibarChecklist: {},
  inventoryChecklist: {},
  roomChecklist: {
    Bathroom: {
      Toilet: false,
      Shower: false,
      Sink: false,
      Mirror: false,
      Amenities: false
    },
    Bedroom: {
      'Bed Made': false,
      Pillows: false,
      Sheets: false,
      Duvet: false
    },
    General: {
      Floor: false,
      Windows: false,
      Curtains: false,
      Dust: false
    },
    Furniture: {
      Tables: false,
      Chairs: false,
      Wardrobe: false,
      Desk: false
    },
    Electronics: {
      TV: false,
      Phone: false,
      Remote: false,
      Safe: false
    },
    Other: {
      'AC Unit': false,
      Lighting: false,
      'Door Lock': false,
      Balcony: false
    }
  },
  issuesFound: [],
  issuesDescription: '',
  additionalNotes: '',
  status: 'draft'
};

const RoomCleaningModal: React.FC<RoomCleaningModalProps> = ({
  isOpen,
  onClose,
  roomNumber,
  roomType,
  inventory,
  minibar,
  date
}) => {
  const { t } = useTranslation(['roomCleaning', 'common']);

  const { user } = useSelector((state: any) => state.user);

  const [activeTab, setActiveTab] = useState('initial');
  const [minibarQuantities, setMinibarQuantities] = useState<{ [key: string]: number }>({});
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState(true);

  const inventoryWithIds = inventory.map((item, index) => ({
    ...item,
    id: `inv-${index}` 
  }));

  const initialInventoryChecklist = inventoryWithIds.reduce((acc, item) => ({
    ...acc,
    [item.id]: { 
      checked: false,
      quantity: 0,
      name: item.item
    }
  }), {});

  const initialMinibarChecklist = minibar.reduce((acc, item) => ({
    ...acc,
    [item.id]: { 
      checked: false, 
      quantity: item.quantity || 0,
      name: item.name
    }
  }), {});

  const [reportData, setReportData] = useState({
    ...defaultCleaningReport,
    roomNumber,
    cleanerId: user?.id || '',
    minibarChecklist: minibar.reduce((acc, item) => ({
      ...acc,
      [item.id]: { checked: false, quantity: item.quantity || 0, name: item.name }
    }), {}),
    inventoryChecklist: inventory.reduce((acc, item, index) => ({
      ...acc,
      [`inv-${index}`]: { checked: false, quantity: 0, name: item.item }
    }), {})
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isEmergencyModalOpen, setIsEmergencyModalOpen] = useState(false);
  const [emergencyDescription, setEmergencyDescription] = useState('');

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await getLatestCleaningReport(roomNumber);
        if (response.data) {
          setReportData(prev => ({
            ...prev,
            ...response.data,
            roomNumber,
            cleanerId: user?.id || ''
          }));
        }
      } catch (error) {
        console.log('No previous report found, using default data');
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchReport();
    }
  }, [isOpen, roomNumber, user?.id]);

  const handleEmergencySubmit = async () => {
    try {
      const emergencyData = {
        roomNumber: roomNumber.toString(),
        description: emergencyDescription,
        reportedBy: user?.id,
        priority: 'high',
        cleaningReportId: reportData.id 
      };

      await createMajorIssue(emergencyData);
      toast.success(t('emergency.success'));
      setIsEmergencyModalOpen(false);
      setEmergencyDescription('');
    } catch (error) {
      console.error('Error reporting emergency:', error);
      toast.error(t('emergency.error'));
    }
  };

  const handleMinibarChange = (itemId: string, checked: boolean, quantity: number) => {
    setReportData(prev => ({
      ...prev,
      minibarChecklist: {
        ...prev.minibarChecklist,
        [itemId]: {
          ...prev.minibarChecklist[itemId],
          checked,
          quantity
        }
      }
    }));
  };

  const handleInventoryChange = (itemId: string, checked: boolean, quantity: number) => {
    const inventoryItem = inventoryWithIds.find(item => item.id === itemId);
    
    setReportData(prev => ({
      ...prev,
      inventoryChecklist: {
        ...prev.inventoryChecklist,
        [itemId]: {
          checked: checked,
          quantity: quantity,
          name: inventoryItem?.item || '' 
        }
      }
    }));
  };

  const handleInputChange = (field: string, value: any) => {
    setReportData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleIssueToggle = (issue: string) => {
    setReportData(prev => ({
      ...prev,
      issuesFound: prev.issuesFound.includes(issue)
        ? prev.issuesFound.filter(i => i !== issue)
        : [...prev.issuesFound, issue]
    }));
  };

  const prepareReportData = (isDraft: boolean) => {
    return {
      roomNumber,
      roomCondition: reportData.roomCondition,
      cleanlinessLevel: reportData.cleanlinessLevel,
      minibarChecklist: Object.entries(minibarQuantities).reduce((acc, [id, quantity]) => ({
        ...acc,
        [id]: {
          checked: checkedItems[id],
          quantity
        }
      }), {}),
      inventoryChecklist: inventory.reduce((acc, item) => ({
        ...acc,
        [item.item]: {
          checked: checkedItems[item.item],
          quantity: item.quantity
        }
      }), {}),
      roomChecklist: ['Bathroom', 'Bedroom', 'General', 'Furniture', 'Electronics', 'Other']
        .reduce((acc, category) => ({
          ...acc,
          [category]: Object.entries(checkedItems)
            .filter(([key]) => key.startsWith(category))
            .reduce((catAcc, [key, value]) => ({
              ...catAcc,
              [key]: value
            }), {})
        }), {}),
      issuesFound: reportData.issuesFound,
      issuesDescription: reportData.issuesDescription,
      additionalNotes: reportData.additionalNotes,
      status: isDraft ? 'draft' : 'submitted'
    };
  };

  const handleSubmit = async (isDraft: boolean) => {
    try {
      setIsSubmitting(true);
      
      const formData = {
        ...reportData,
        status: isDraft ? 'draft' : 'submitted',
        cleanerId: user?.id
      };

      console.log('Submitting form data:', formData); 

      const response = await createCleaningReport(formData);
      toast.success(isDraft 
        ? t('actions.draftSuccess') 
        : t('actions.submitSuccess')
      );
      
      if (response.data) {
        onClose();
      }
    } catch (error) {
      toast.error(t('actions.error'));
      console.error('Error submitting report:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const today = new Date();
  const cleaningDate = new Date(date);
  const isCleaningDate = today.toDateString() === cleaningDate.toDateString();

  const handleRoomChecklistChange = (category: string, item: string, checked: boolean) => {
    setReportData(prev => ({
      ...prev,
      roomChecklist: {
        ...prev.roomChecklist,
        [category]: {
          ...prev.roomChecklist[category],
          [item]: checked
        }
      }
    }));
  };

  useEffect(() => {
    console.log('Inventory Checklist State:', reportData.inventoryChecklist);
  }, [reportData.inventoryChecklist]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-seashell w-full max-w-3xl rounded-lg shadow-xl flex flex-col max-h-[90vh]">
        <div className="p-4 border-b border-grey-goose flex justify-between items-center bg-gradient-to-r from-hazel-green/10 to-transparent shrink-0">
          <h2 className="text-xl font-semibold text-hazel-green flex items-center gap-2">
            {`${t('roomCleaning:header.room')} ${roomNumber} - ${t('roomCleaning:header.cleaningManagement')}`}
          </h2>

          {!isCleaningDate && (
            <div className="flex flex-row items-center gap-2 text-sm px-2 py-0.5 bg-watermelon/10 text-watermelon rounded ml-auto">
              <MessageSquareWarning size={20} className="animate-pulse" />
              <span>{t('header.notScheduled')}</span>
            </div>
          )}

          <button
            onClick={onClose}
            className="text-bluish-grey hover:text-hazel-green transition-colors ml-4"
          >
            <X size={24} />
          </button>
        </div>

        <div className="border-b border-grey-goose shrink-0">
          <nav className="flex">
            {[
              { id: 'initial', label: t('tabs.initialState') },
              { id: 'checklist', label: t('tabs.cleaningChecklist') },
              { id: 'report', label: t('tabs.finalReport') },
            ].map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-6 py-3 text-sm font-medium transition-colors relative ${
                  activeTab === tab.id
                    ? 'text-hazel-green'
                    : 'text-bluish-grey hover:text-hazel-green'
                }`}
              >
                {tab.label}
                {activeTab === tab.id && (
                  <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-hazel-green" />
                )}
              </button>
            ))}
          </nav>
        </div>

        <div className="overflow-y-auto flex-1">
          {activeTab === 'initial' && (
            <div className="grid grid-cols-2 gap-6 p-6">
              <div className="bg-white rounded-lg p-4 shadow-sm border border-grey-goose">
                <div className="flex items-center gap-2 mb-4">
                  <Wine className="text-hazel-green" size={20} />
                  <h3 className="text-lg font-medium text-hazel-green">{t('minibar.title')}</h3>
                </div>
                <div className="space-y-3">
                  {minibar.length > 0 ? (
                    minibar.map((item: any) => (
                      <div
                        key={item.id}
                        className="flex justify-between items-center p-2 bg-tealish-blue rounded"
                      >
                        <span className="text-sm text-bluish-grey">{item.name}</span>
                        <span className="text-sm font-medium text-hazel-green">
                          {item.quantity} {t('common.units')}
                        </span>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm text-bluish-grey">{t('minibar.noItems')}</p>
                  )}
                </div>
              </div>

              <div className="bg-white rounded-lg p-4 shadow-sm border border-grey-goose">
                <div className="flex items-center gap-2 mb-4">
                  <ShoppingBag className="text-hazel-green" size={20} />
                  <h3 className="text-lg font-medium text-hazel-green">{t('inventory.title')}</h3>
                </div>
                <div className="space-y-3">
                  {inventoryWithIds.length > 0 ? (
                    inventoryWithIds.map((item: any) => (
                      <div
                        key={item.id}
                        className="flex justify-between items-center p-2 bg-tealish-blue rounded"
                      >
                        <span className="text-sm text-bluish-grey">{item.item}</span>
                        <div className="flex flex-col items-end">
                          <span className="text-sm font-medium text-hazel-green">
                            {item.quantity} {t('common.units')}
                          </span>
                          <span className={`text-xs ${item.goodCondition ? 'text-green-500' : 'text-red-500'}`}>
                            {item.goodCondition ? t('inventory.goodCondition') : t('inventory.poorCondition')}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm text-bluish-grey">{t('inventory.noItems')}</p>
                  )}
                </div>
              </div>
            </div>
          )}

          {activeTab === 'checklist' && (
            <div className="space-y-4 p-6">
              <div className="bg-white rounded-lg p-3 shadow-sm border border-grey-goose">
                <h3 className="text-sm font-medium text-hazel-green mb-2 sticky top-0 bg-white py-2">
                  {t('minibar.checklist')}
                </h3>
                <div className="grid grid-cols-2 gap-2">
                  {minibar.map((item) => (
                    <div 
                      key={item.id}
                      className="flex items-center justify-between p-2 bg-seashell rounded-md"
                    >
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          id={`minibar-${item.id}`}
                          checked={reportData.minibarChecklist[item.id]?.checked || false}

                          onChange={(e) => handleMinibarChange(
                            item.id, 
                            e.target.checked,
                            reportData.minibarChecklist[item.id]?.quantity || 0
                          )}
                          className="w-3.5 h-3.5 rounded border-grey-goose text-hazel-green focus:ring-hazel-green"
                        />
                        <label htmlFor={`minibar-${item.id}`} className="text-xs text-bluish-grey">
                          {item.name}
                        </label>
                      </div>
                      <div className="flex items-center gap-1">
                        <input
                          type="number"
                          min="0"
                          max={item.quantity}
                          value={reportData.minibarChecklist[item.id]?.quantity || 0}

                          onChange={(e) => handleMinibarChange(
                            item.id,
                            reportData.minibarChecklist[item.id]?.checked || false,
                            parseInt(e.target.value) || 0
                          )}
                          className="w-12 px-1 py-0.5 text-xs border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green"
                        />
                        <span className="text-xs text-bluish-grey">/{item.quantity}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-white rounded-lg p-3 shadow-sm border border-grey-goose">
                <h3 className="text-sm font-medium text-hazel-green mb-2 sticky top-0 bg-white py-2">
                  {t('inventory.checklist')}
                </h3>
                <div className="grid grid-cols-3 gap-2">
                  {inventoryWithIds.filter(item => item.item.trim() !== '').map((item) => (
                    <div 
                      key={item.id}
                      className="flex items-center justify-between p-2 bg-seashell rounded-md"
                    >
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          id={`inventory-${item.id}`}
                          checked={reportData.inventoryChecklist[item.id]?.checked || false}
                          onChange={(e) => handleInventoryChange(
                            item.id,
                            e.target.checked,
                            reportData.inventoryChecklist[item.id]?.quantity || 0
                          )}
                          className="w-3.5 h-3.5 rounded border-grey-goose text-hazel-green focus:ring-hazel-green"
                        />
                        <label htmlFor={`inventory-${item.id}`} className="text-xs text-bluish-grey">
                          {item.item}
                        </label>
                      </div>
                      <div className="flex items-center gap-1">
                        <input
                          type="number"
                          min="0"
                          max={item.quantity}
                          value={reportData.inventoryChecklist[item.id]?.quantity || 0}
                          onChange={(e) => handleInventoryChange(
                            item.id,
                            reportData.inventoryChecklist[item.id]?.checked || false,
                            parseInt(e.target.value) || 0
                          )}
                          className="w-12 px-1 py-0.5 text-xs border border-grey-goose rounded-md"
                        />
                        <span className="text-xs text-bluish-grey">/{item.quantity}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-white rounded-lg p-4 shadow-sm border border-grey-goose">
                <h3 className="text-lg font-medium text-hazel-green mb-4 sticky top-0 bg-white py-2">
                  {t('room.checklist')}
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                  {[
                    { category: 'Bathroom', items: ['Toilet', 'Shower', 'Sink', 'Mirror', 'Amenities'] },
                    { category: 'Bedroom', items: ['Bed Made', 'Pillows', 'Sheets', 'Duvet'] },
                    { category: 'General', items: ['Floor', 'Windows', 'Curtains', 'Dust'] },
                    { category: 'Furniture', items: ['Tables', 'Chairs', 'Wardrobe', 'Desk'] },
                    { category: 'Electronics', items: ['TV', 'Phone', 'Remote', 'Safe'] },
                    { category: 'Other', items: ['AC Unit', 'Lighting', 'Door Lock', 'Balcony'] }
                  ].map((section) => (
                    <div key={section.category} className="bg-seashell rounded-md p-3">
                      <h4 className="text-sm font-medium text-hazel-green mb-2">
                        {t(`room.${section.category}`)}
                      </h4>
                      <div className="space-y-1.5">
                        {section.items.map((item) => (
                          <div 
                            key={item}
                            className="flex items-center gap-2 py-1"
                          >
                            <input
                              type="checkbox"
                              id={`room-${section.category}-${item}`}
                              checked={reportData.roomChecklist[section.category][item] || false}
                              onChange={(e) => handleRoomChecklistChange(section.category, item, e.target.checked)}
                              className="w-3.5 h-3.5 rounded border-grey-goose text-hazel-green focus:ring-1 focus:ring-hazel-green"
                            />
                            <label 
                              htmlFor={`room-${section.category}-${item}`} 
                              className="text-xs text-bluish-grey cursor-pointer hover:text-hazel-green transition-colors"
                            >
                              {item}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {activeTab === 'report' && (
            <div className="bg-white rounded-lg p-4 shadow-sm border border-grey-goose">
              <button 
                className="w-full mb-4 p-3 bg-red-50 border border-red-200 rounded-lg flex items-center justify-center gap-2 text-red-600 hover:bg-red-100 transition-colors"
                onClick={() => setIsEmergencyModalOpen(true)}
              >
                <AlertTriangle size={18} />
                <span className="text-sm font-medium">{t('emergency.reportIssue')}</span>
              </button>

              <div className="flex items-center gap-2 mb-4">
                <ClipboardCheck className="text-hazel-green" size={18} />
                <h3 className="text-sm font-medium text-hazel-green">{t('report.title')}</h3>
              </div>

              <div className="flex gap-4 mb-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                    {t('report.roomCondition')}
                  </label>
                  <select 
                    value={reportData.roomCondition}
                    onChange={(e) => handleInputChange('roomCondition', e.target.value)}
                    className="w-full p-2 text-sm rounded-lg border border-grey-goose focus:ring-1 focus:ring-hazel-green bg-seashell"
                  >
                    <option value="">{t('common.select')}</option>
                    <option value="excellent">{t('conditions.excellent')}</option>
                    <option value="good">{t('conditions.good')}</option>
                    <option value="fair">{t('conditions.fair')}</option>
                    <option value="poor">{t('conditions.needsAttention')}</option>
                    <option value="maintenance">{t('conditions.maintenanceRequired')}</option>
                  </select>
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                    {t('report.cleanlinessLevel')}
                  </label>
                  <select 
                    value={reportData.cleanlinessLevel}
                    onChange={(e) => handleInputChange('cleanlinessLevel', e.target.value)}
                    className="w-full p-2 text-sm rounded-lg border border-grey-goose focus:ring-1 focus:ring-hazel-green bg-seashell"
                  >
                    <option value="">{t('common.select')}</option>
                    <option value="spotless">{t('cleanliness.spotless')}</option>
                    <option value="clean">{t('cleanliness.clean')}</option>
                    <option value="acceptable">{t('cleanliness.acceptable')}</option>
                    <option value="needsWork">{t('cleanliness.needsWork')}</option>
                    <option value="unsatisfactory">{t('cleanliness.unsatisfactory')}</option>
                  </select>
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-bluish-grey mb-2">
                  {t('report.issuesFound')}
                </label>
                <div className="grid grid-cols-3 gap-2 mb-2">
                  {['Plumbing', 'Electrical', 'Furniture', 'Electronics', 'Structural', 'Other'].map((issue) => (
                    <label key={issue} className="flex items-center gap-2 p-2 bg-seashell rounded-lg cursor-pointer">
                      <input 
                        type="checkbox"
                        checked={reportData.issuesFound.includes(issue)}
                        onChange={() => handleIssueToggle(issue)}
                        className="w-4 h-4 rounded"
                      />
                      <span className="text-sm">{issue}</span>
                    </label>
                  ))}
                </div>
                <textarea
                  value={reportData.issuesDescription}
                  onChange={(e) => handleInputChange('issuesDescription', e.target.value)}
                  className="w-full p-2 text-sm rounded-lg"
                  placeholder={t('report.issuesDescription')}
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                  {t('report.additionalNotes')}
                </label>
                <textarea
                  value={reportData.additionalNotes}
                  onChange={(e) => handleInputChange('additionalNotes', e.target.value)}
                  className="w-full p-2 text-sm rounded-lg"
                  placeholder={t('report.additionalNotesPlaceholder')}
                />
              </div>

              <div className="flex justify-between items-center border-t border-grey-goose pt-4">
                <div className="flex items-center gap-2">
                  <Clock size={16} className="text-bluish-grey" />
                  <span className="text-sm text-bluish-grey">
                    {new Date().toLocaleString()}
                  </span>
                </div>
                <div className="flex gap-2">
                  <button 
                    onClick={() => handleSubmit(true)}
                    disabled={isSubmitting}
                    className="px-3 py-1.5 text-sm border border-hazel-green text-hazel-green rounded-lg hover:bg-hazel-green/5 transition-colors"
                  >
                    {t('actions.saveDraft')}
                  </button>
                  <button 
                    onClick={() => handleSubmit(false)}
                    disabled={isSubmitting}
                    className="px-3 py-1.5 text-sm bg-hazel-green text-white rounded-lg hover:bg-sage transition-colors flex items-center gap-1.5"
                  >
                    <CheckCircle2 size={14} />
                    <span className='mx-3'>{t('actions.submitReport')}</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

      {isEmergencyModalOpen && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-96">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-hazel-green">
                {t('emergency.title')}
              </h3>
              <button
                onClick={() => setIsEmergencyModalOpen(false)}
                className="text-bluish-grey hover:text-hazel-green transition-colors"
              >
                <X size={20} />
              </button>
            </div>

            <textarea
              value={emergencyDescription}
              onChange={(e) => setEmergencyDescription(e.target.value)}
              placeholder={t('emergency.description')}
              className="w-full h-32 p-2 text-sm border border-grey-goose rounded-lg resize-none focus:ring-1 focus:ring-hazel-green"
            />

            <div className="flex justify-end gap-2 mt-4">
              <button
                onClick={() => setIsEmergencyModalOpen(false)}
                className="px-4 py-2 text-sm text-bluish-grey hover:text-hazel-green transition-colors"
              >
                {t('common.cancel')}
              </button>
              <button
                onClick={handleEmergencySubmit}
                className="px-4 py-2 text-sm text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors"
              >
                {t('emergency.submit')}
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default RoomCleaningModal;