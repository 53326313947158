import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, AlertTriangle } from 'lucide-react';
import { cn } from '../../lib/utils.ts';

interface DeleteJournalEntryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  documentNumber: string;
}

const DeleteJournalEntryModal: React.FC<DeleteJournalEntryModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  documentNumber
}) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        {/* Backdrop */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute inset-0 bg-bluish-grey/20 backdrop-blur-sm"
          onClick={onClose}
        />

        {/* Modal */}
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          className="relative w-full max-w-lg bg-white rounded-2xl shadow-lg p-6"
        >
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 p-2 text-bluish-grey hover:text-red-500 rounded-lg transition-colors"
          >
            <X className="w-5 h-5" />
          </button>

          {/* Content */}
          <div className="mt-2">
            <div className="flex items-center gap-4 mb-6">
              <div className="p-3 bg-red-100 rounded-full">
                <AlertTriangle className="w-6 h-6 text-red-600" />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-bluish-grey">Fshij Regjistrimin</h3>
                <p className="text-grey-goose mt-1">
                  A jeni të sigurt që dëshironi të fshini regjistrimin në ditar me numër dokumenti <span className="font-medium text-bluish-grey">{documentNumber}</span>?
                </p>
              </div>
            </div>

            <div className="text-sm text-red-600 bg-red-50 rounded-lg p-4 mb-6">
              <p>
                <strong>Kujdes:</strong> Ky veprim nuk mund të kthehet. Të gjitha të dhënat e lidhura me këtë regjistrim do të fshihen përgjithmonë.
              </p>
            </div>

            <div className="flex justify-end gap-3">
              <button
                onClick={onClose}
                className={cn(
                  "px-4 py-2 rounded-lg font-medium text-bluish-grey",
                  "border border-mercury hover:bg-seashell transition-colors"
                )}
              >
                Anulo
              </button>
              <button
                onClick={onConfirm}
                className={cn(
                  "px-4 py-2 rounded-lg font-medium text-white",
                  "bg-red-500 hover:bg-red-600 transition-colors"
                )}
              >
                Fshij Regjistrimin
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default DeleteJournalEntryModal;
