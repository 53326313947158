import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Building2, MapPin, Building, Settings, Upload, RotateCw, Calendar, Plus } from 'lucide-react';
import FiscalYearTable from '../../../../components/company/FiscalYearTable.tsx';
import LocationsTable from '../../../../components/company/LocationsTable.tsx';
import BankAccountsTable from '../../../../components/company/BankAccountsTable.tsx';
import AddFiscalYearModal from '../../../../components/company/AddFiscalYearModal.tsx';
import AddLocationModal from '../../../../components/company/AddLocationModal.tsx';
import AddBankAccountModal from '../../../../components/company/AddBankAccountModal.tsx';
import { getCompany, updateCompany } from '../../../../api/company';
import { toast } from 'react-hot-toast';

export default function CompanyPage() {
  const [activeTab, setActiveTab] = useState('detajet');
  const [showFiscalYearModal, setShowFiscalYearModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showBankAccountModal, setShowBankAccountModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState({
    id: '',
    name: '',
    description: '',
    vatNumber: '',
    uniqueId: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postalCode: '',
    country: '',
    phone: '',
    email: '',
    website: '',
    logo: ''
  });
  const [fiscalYears, setFiscalYears] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const fetchCompanyData = async () => {
    try {
      setLoading(true);
      const response = await getCompany();
      if (response?.data?.message) {
        const company = response.data.message;
        setCompanyData({
          id: company.id || '',
          name: company.name || '',
          description: company.description || '',
          vatNumber: company.vatNo || '',
          uniqueId: company.registrationNo || '',
          addressLine1: company.address || '',
          addressLine2: company.addressLine2 || '',
          city: company.city || '',
          postalCode: company.postalCode || '',
          country: company.country || '',
          phone: company.phone || '',
          email: company.email || '',
          website: company.website || '',
          logo: company.logo || ''
        });
        setFiscalYears(company.fiscalYears || []);
        setLocations(company.locations || []);
      }
    } catch (error) {
      console.error('Error fetching company data:', error);
      toast.error('Failed to fetch company data');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      await updateCompany({
        name: companyData.name,
        description: companyData.description,
        vatNo: companyData.vatNumber,
        registrationNo: companyData.uniqueId,
        address: companyData.addressLine1,
        addressLine2: companyData.addressLine2,
        city: companyData.city,
        postalCode: companyData.postalCode,
        country: companyData.country,
        phone: companyData.phone,
        email: companyData.email,
        website: companyData.website,
        logo: companyData.logo,
        updatedBy: 'current-user' // TODO: Get from auth context
      });
      toast.success('Të dhënat u ruajtën me sukses');
      await fetchCompanyData(); // Refresh data after update
    } catch (error) {
      toast.error('Gabim gjatë ruajtjes së të dhënave');
      console.error('Error updating company:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // TODO: Implement logo upload
      toast.error('Ngarkimi i logos nuk është implementuar ende');
    }
  };

  return (
    <div className="p-6 space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-bluish-grey">Konfigurimi</h1>
          <p className="text-grey-goose">Menaxhoni kompaninë tuaj, vitet fiskale dhe lokacionet (njësitë organizative).</p>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="flex space-x-1 bg-white p-1 rounded-xl shadow-sm">
        <button
          onClick={() => setActiveTab('detajet')}
          className={`flex items-center gap-2 px-4 py-2.5 rounded-lg ${
            activeTab === 'detajet'
              ? 'bg-hazel-green text-white'
              : 'text-bluish-grey hover:bg-seashell'
          } transition-all duration-200`}
        >
          <Building2 className="w-5 h-5" />
          Detajet e Kompanisë
        </button>
        <button
          onClick={() => setActiveTab('vitet-fiskale')}
          className={`flex items-center gap-2 px-4 py-2.5 rounded-lg ${
            activeTab === 'vitet-fiskale'
              ? 'bg-hazel-green text-white'
              : 'text-bluish-grey hover:bg-seashell'
          } transition-all duration-200`}
        >
          <Calendar className="w-5 h-5" />
          Vitet Fiskale
        </button>
        <button
          onClick={() => setActiveTab('lokacionet')}
          className={`flex items-center gap-2 px-4 py-2.5 rounded-lg ${
            activeTab === 'lokacionet'
              ? 'bg-hazel-green text-white'
              : 'text-bluish-grey hover:bg-seashell'
          } transition-all duration-200`}
        >
          <Building className="w-5 h-5" />
          Lokacionet
        </button>
        <button
          onClick={() => setActiveTab('llogarite')}
          className={`flex items-center gap-2 px-4 py-2.5 rounded-lg ${
            activeTab === 'llogarite'
              ? 'bg-hazel-green text-white'
              : 'text-bluish-grey hover:bg-seashell'
          } transition-all duration-200`}
        >
          <Building2 className="w-5 h-5" />
          Llogaritë Bankare
        </button>
        <button
          onClick={() => setActiveTab('konfigurimet')}
          className={`flex items-center gap-2 px-4 py-2.5 rounded-lg ${
            activeTab === 'konfigurimet'
              ? 'bg-hazel-green text-white'
              : 'text-bluish-grey hover:bg-seashell'
          } transition-all duration-200`}
        >
          <Settings className="w-5 h-5" />
          Konfigurimet e Përgjithshme
        </button>
      </div>

      {/* Main Content */}
      {activeTab === 'detajet' && (
        <div className="space-y-6">
          {/* Company Information Section */}
          <div className="bg-white rounded-xl shadow-sm border border-grey-goose/20 overflow-hidden">
            <div className="px-6 py-4 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
              <h2 className="text-lg font-semibold text-bluish-grey">Informatat Kryesore</h2>
              <p className="text-sm text-grey-goose">Informacioni bazë mbi organizatën.</p>
            </div>
            <div className="p-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Company Name */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">
                      Emri i organizatës
                    </label>
                    <input
                      type="text"
                      value={companyData.name}
                      onChange={(e) => setCompanyData({ ...companyData, name: e.target.value })}
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white"
                      placeholder="Emri i organizatës"
                    />
                  </div>

                  {/* Description */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">
                      Përshkrimi
                    </label>
                    <textarea
                      value={companyData.description}
                      onChange={(e) => setCompanyData({ ...companyData, description: e.target.value })}
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white"
                      placeholder="Një përshkrim i shkurtër i organizatës"
                      rows={3}
                    />
                  </div>

                  {/* VAT Number */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">
                      Numri i TVSH-së së organizatës
                    </label>
                    <input
                      type="text"
                      value={companyData.vatNumber}
                      onChange={(e) => setCompanyData({ ...companyData, vatNumber: e.target.value })}
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white"
                      placeholder="18"
                    />
                  </div>

                  {/* Unique ID */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">
                      Numri Unik Identifikues
                    </label>
                    <input
                      type="text"
                      value={companyData.uniqueId}
                      onChange={(e) => setCompanyData({ ...companyData, uniqueId: e.target.value })}
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white"
                      placeholder="121212121"
                    />
                  </div>

                  {/* Phone */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">
                      Numri i telefonit
                    </label>
                    <input
                      type="text"
                      value={companyData.phone}
                      onChange={(e) => setCompanyData({ ...companyData, phone: e.target.value })}
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white"
                      placeholder="+383 44 123 456"
                    />
                  </div>

                  {/* Email */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">
                      Adresa e emailit
                    </label>
                    <input
                      type="email"
                      value={companyData.email}
                      onChange={(e) => setCompanyData({ ...companyData, email: e.target.value })}
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white"
                      placeholder="info@example.com"
                    />
                  </div>

                  {/* Website */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">
                      Adresa e uebfaqes
                    </label>
                    <input
                      type="text"
                      value={companyData.website}
                      onChange={(e) => setCompanyData({ ...companyData, website: e.target.value })}
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white"
                      placeholder="https://example.com"
                    />
                  </div>
                </div>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  className="px-6 py-3 bg-hazel-green text-white rounded-xl hover:bg-hazel-green/90 transition-all duration-200 flex items-center gap-2 font-medium shadow-lg shadow-hazel-green/20"
                >
                  <RotateCw className="w-5 h-5" />
                  Ruaj
                </motion.button>
              </form>
            </div>
          </div>

          {/* Address Section */}
          <div className="bg-white rounded-xl shadow-sm border border-grey-goose/20 overflow-hidden">
            <div className="px-6 py-4 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
              <h2 className="text-lg font-semibold text-bluish-grey">Adresa</h2>
              <p className="text-sm text-grey-goose">Adresa e kompanisë dhe informacionet e kontaktit.</p>
            </div>
            <div className="p-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Address Line 1 */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">
                      Rreshi i parë i adresës tuaj.
                    </label>
                    <input
                      type="text"
                      value={companyData.addressLine1}
                      onChange={(e) => setCompanyData({ ...companyData, addressLine1: e.target.value })}
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white"
                      placeholder="Luan Haradinaj"
                    />
                  </div>

                  {/* Address Line 2 */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">
                      Rreshi i dytë i adresës tuaj.
                    </label>
                    <input
                      type="text"
                      value={companyData.addressLine2}
                      onChange={(e) => setCompanyData({ ...companyData, addressLine2: e.target.value })}
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white"
                      placeholder="Adresa Rreshi 2"
                    />
                  </div>

                  {/* City */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">
                      Qyteti ku ndodhet organizata juaj.
                    </label>
                    <input
                      type="text"
                      value={companyData.city}
                      onChange={(e) => setCompanyData({ ...companyData, city: e.target.value })}
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white"
                      placeholder="Prishtinë"
                    />
                  </div>

                  {/* Postal Code */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">
                      Kodi postar i organizatës tuaj.
                    </label>
                    <input
                      type="text"
                      value={companyData.postalCode}
                      onChange={(e) => setCompanyData({ ...companyData, postalCode: e.target.value })}
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white"
                      placeholder="51000"
                    />
                  </div>

                  {/* Country */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">
                      Shteti ku ndodhet organizata juaj.
                    </label>
                    <div className="relative">
                      <select
                        value={companyData.country}
                        onChange={(e) => setCompanyData({ ...companyData, country: e.target.value })}
                        className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white appearance-none"
                      >
                        <option value="">Zgjidhni shtetin</option>
                        <option value="Kosovë">Kosovë</option>
                        <option value="Shqipëri">Shqipëri</option>
                        <option value="Maqedoni">Maqedoni</option>
                      </select>
                    </div>
                  </div>
                </div>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  className="px-6 py-3 bg-hazel-green text-white rounded-xl hover:bg-hazel-green/90 transition-all duration-200 flex items-center gap-2 font-medium shadow-lg shadow-hazel-green/20"
                >
                  <RotateCw className="w-5 h-5" />
                  Ruaj
                </motion.button>
              </form>
            </div>
          </div>

          {/* Logo Section */}
          <div className="bg-white rounded-xl shadow-sm border border-grey-goose/20 overflow-hidden">
            <div className="px-6 py-4 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
              <h2 className="text-lg font-semibold text-bluish-grey">Logo e Kompanisë</h2>
              <p className="text-sm text-grey-goose">Logoja e kompanisë dhe informacionet për markën.</p>
            </div>
            <div className="p-6">
              <div className="space-y-4">
                <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-grey-goose/30 border-dashed rounded-xl cursor-pointer hover:bg-seashell transition-all">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <Upload className="w-8 h-8 text-grey-goose mb-2" />
                    <p className="text-sm text-grey-goose">Upload</p>
                  </div>
                  <input type="file" className="hidden" onChange={handleLogoUpload} accept="image/*" />
                </label>
                <p className="text-sm text-grey-goose">
                  Ngarko logon e kompanisë tuaj. (Mund të ngarkoni logon e biznesit në madhësi maksimale 500 KB, në formatet JPG, PNG. Madhësia e rekomanduar 400x250 px.)
                </p>

                <motion.button
                  type="submit"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="px-6 py-3 bg-hazel-green text-white rounded-xl hover:bg-hazel-green/90 transition-all duration-200 flex items-center gap-2 font-medium shadow-lg shadow-hazel-green/20"
                >
                  <RotateCw className="w-5 h-5" />
                  Ruaj
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Fiscal Years Tab Content */}
      {activeTab === 'vitet-fiskale' && (
        <div className="space-y-6">
          <div className="bg-white rounded-xl shadow-sm border border-grey-goose/20 overflow-hidden">
            <div className="px-6 py-4 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-lg font-semibold text-bluish-grey">Vitet Fiskale</h2>
                  <p className="text-sm text-grey-goose">Menaxhoni vitet fiskale të organizatës suaj</p>
                </div>
                <motion.button
                  onClick={() => setShowFiscalYearModal(true)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 transition-all duration-200 flex items-center gap-2 shadow-lg shadow-hazel-green/20"
                >
                  <Plus className="w-4 h-4" />
                  Shto Vit Fiskal
                </motion.button>
              </div>
            </div>
            <div className="p-6">
              <FiscalYearTable 
                data={fiscalYears} 
                onRefresh={fetchCompanyData} 
              />
            </div>
          </div>
        </div>
      )}

      {/* Locations Tab Content */}
      {activeTab === 'lokacionet' && (
        <div className="space-y-6">
          <div className="bg-white rounded-xl shadow-sm border border-grey-goose/20 overflow-hidden">
            <div className="px-6 py-4 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-lg font-semibold text-bluish-grey">Lokacionet</h2>
                  <p className="text-sm text-grey-goose">Menaxhoni lokacionet e organizatës suaj</p>
                </div>
                <motion.button
                  onClick={() => setShowLocationModal(true)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 transition-all duration-200 flex items-center gap-2 shadow-lg shadow-hazel-green/20"
                >
                  <Plus className="w-4 h-4" />
                  Shto Lokacion
                </motion.button>
              </div>
            </div>
            <div className="p-6">
              <LocationsTable 
                data={locations} 
                onRefresh={fetchCompanyData} 
              />
            </div>
          </div>
        </div>
      )}

      {activeTab === 'llogarite' && (
        <BankAccountsTable onAdd={() => setShowBankAccountModal(true)} companyId={companyData.id} />
      )}
      {activeTab === 'konfigurimet' && (
        <div className="space-y-6">
          <div className="bg-white rounded-xl shadow-sm border border-grey-goose/20 overflow-hidden">
            <div className="px-6 py-4 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
              <h2 className="text-lg font-semibold text-bluish-grey">Konfigurimet e Përgjithshme</h2>
              <p className="text-sm text-grey-goose">Konfigurimet e përgjithshme të sistemit.</p>
            </div>
            <div className="p-6">
              <form className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-1">
                      Viti fiskal aktual
                    </label>
                    <select
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white appearance-none"
                    >
                      <option>2025</option>
                    </select>
                  </div>
                </div>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  className="px-6 py-3 bg-hazel-green text-white rounded-xl hover:bg-hazel-green/90 transition-all duration-200 flex items-center gap-2 font-medium shadow-lg shadow-hazel-green/20"
                >
                  <RotateCw className="w-5 h-5" />
                  Ruaj
                </motion.button>
              </form>
            </div>
          </div>
        </div>
      )}
      <AddFiscalYearModal 
        isOpen={showFiscalYearModal} 
        onClose={() => setShowFiscalYearModal(false)}
        onSuccess={fetchCompanyData}
        companyId={companyData.id}
      />
      <AddLocationModal 
        isOpen={showLocationModal} 
        onClose={() => setShowLocationModal(false)}
        onSuccess={fetchCompanyData}
        companyId={companyData.id}
      />
      <AddBankAccountModal 
        isOpen={showBankAccountModal} 
        onClose={() => setShowBankAccountModal(false)} 
        onSuccess={() => {
          // Refresh the bank accounts list when a new account is added
          if (activeTab === 'llogarite') {
            const bankAccountsTable = document.querySelector('BankAccountsTable');
            if (bankAccountsTable) {
              bankAccountsTable.dispatchEvent(new Event('refresh'));
            }
          }
        }}
        companyId={companyData.id}
      />
    </div>
  );
}
