
import * as React from "react";
import { Check, Search } from "lucide-react";
import { cn } from "../../utils/cn.ts"; // Ensure your utility function is correctly imported

interface SimpleCommandProps {
  items: { label: string; value: string }[];
  selectedItem: { label: string; value: string };
  onSelect: (item: { label: string; value: string }) => void;
}

const SimpleCommand = React.forwardRef<HTMLDivElement, SimpleCommandProps>(
  ({ items, selectedItem, onSelect }, ref) => {
    const [searchQuery, setSearchQuery] = React.useState("");

    const filteredItems = items.filter(item =>
      item.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <div ref={ref} className="w-56 p-2 bg-white rounded shadow-lg">
        <div className="flex items-center border-b mb-2">
          <Search className="h-4 w-4 text-muted-foreground" />
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="flex-1 p-2 outline-none border rounded"
          />
        </div>
        <div className="max-h-48 overflow-y-auto">
          {filteredItems.length === 0 ? (
            <div className="py-2 text-center text-muted-foreground">No results found</div>
          ) : (
            filteredItems.map(item => (
              <div
                key={item.value}
                className={cn("flex items-center p-2 cursor-pointer hover:bg-accent/50", {
                  "bg-accent": selectedItem.value === item.value,
                })}
                onClick={() => onSelect(item)}
              >
                <Check className={cn("h-4 w-4 mr-2", selectedItem.value === item.value ? "opacity-100" : "opacity-0")} />
                {item.label}
              </div>
            ))
          )}
        </div>
      </div>
    );
  }
);

SimpleCommand.displayName = "SimpleCommand";

export default SimpleCommand;
