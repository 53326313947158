import api from './api';


export const getAllBarItems = () => api.get('/api/restaurantInterface/bar-items');

// export const getBarOrderById = (id) => api.get(`/api/restaurantInterface/bar-orders/${id}`);

export const getAllDishes = () => api.get('/api/restaurantInterface/dishes');

// export const getTablesByEnvironmentId = (enviromentId) => api.get(`/api/restaurantInterface/environments/${enviromentId}/tables`);
export const getTablesByEnvironmentId = (environmentId) => api.get(`/api/restaurantInterface/environments/${environmentId}/tables`);

export const getOrdersList = () => api.get("/api/restaurantInterface/orders");

export const getPaginatedOrders = (page = 1, limit = 10) => 
    api.get(`/api/restaurantInterface/orders?page=${page}&limit=${limit}`);

export const getUnpaidOrders = () => api.get('/api/restaurantInterface/orders/unpaid');

export const updateOrderStatus = (id, status) => api.patch(`/api/restaurantInterface/orders/${id}/status`, { status });


export const createDishOrder = (data) => 
  api.post('/api/restaurantInterface/create-dish-order', data)
    .catch(error => {
      console.error('Error creating dish order:', error.response);
      throw error;
    });

export const getOrderById = (id) => api.get(`/api/restaurantInterface/orders/${id}`);




export const getUnpaidBarOrders  = () => api.get('/api/restaurantInterface/bar-orders/unpaid');
export const updateBarOrderStatus = (id, status) => api.patch(`/api/restaurantInterface/bar-orders/${id}/status`, { status });



export const getAllTables = () => api.get('/api/restaurantInterface/tables');

export const getTableById = (id) => api.get(`/api/restaurantInterface/tables/${id}`);

export const createTable = (data) => api.post('/api/restaurantInterface/tables', data);

export const updateTable = (id, data) => api.patch(`/api/restaurantInterface/tables/${id}`, data);

export const deleteTable = (id) => api.delete(`/api/restaurantInterface/tables/${id}`);



export const getAllRestaurants = () => api.get('/api/restaurantInterface/restaurants');

export const getRestaurantById = (id) => api.get(`/api/restaurantInterface/restaurants/${id}`);

export const createRestaurant = (data) => api.post('/api/restaurantInterface/restaurants', data);

export const updateRestaurant = (id, data) => api.patch(`/api/restaurantInterface/restaurants/${id}`, data);

export const deleteRestaurant = (id) => api.delete(`/api/restaurantInterface/restaurants/${id}`);



export const createOrder = (data) => api.post('/api/restaurantInterface/create-order', data);

export const updateOrder = (id, data) => api.put(`/api/restaurantInterface/update-order/${id}`, data);




// Environment API functions

export const getAllEnvironments = () => api.get('/api/restaurantInterface/environments');

export const getEnvironmentById = (id) => api.get(`/api/restaurantInterface/environments/${id}`);

export const createEnvironment = (data) => api.post('/api/restaurantInterface/environments', data);

export const updateEnvironment = (id, data) => api.patch(`/api/restaurantInterface/environments/${id}`, data);

export const deleteEnvironment = (id) => api.delete(`/api/restaurantInterface/environments/${id}`);



// Reservation API functions

export const getAllTableReservations = () => api.get('/api/restaurantInterface/table-reservations');

export const getTableReservationById = (id) => api.get(`/api/restaurantInterface/table-reservations/${id}`);

export const createTableReservation = (data) => api.post('/api/restaurantInterface/table-reservations', data);

export const updateTableReservation = (id, data) => api.patch(`/api/restaurantInterface/table-reservations/${id}`, data);

export const deleteTableReservation = (id) => api.delete(`/api/restaurantInterface/table-reservations/${id}`);


//cereate bar orders
export const createBarOrder = (data) => api.post('/api/restaurantInterface/create-bar-order', data);
export const updateBarOrder = (id, data) => api.put(`/api/restaurantInterface/bar-orders/${id}`, data);
export const getBarOrderById = (id) => api.get(`/api/restaurantInterface/bar-orders/${id}`);
export const getPaginatedBarOrders = (page = 1, limit = 10) => api.get(`/api/restaurantInterface/bar-orders?page=${page}&limit=${limit}`);

//bar apis

export const getBars = () => api.get('/api/bar/bars');
export const getBarById = (id) => api.get(`/api/bar/bars/${id}`);
export const createBar = (values) => api.post('/api/bar/bars', values);
export const updateBar = (id, values) => api.patch(`/api/bar/bars/${id}`, values);
export const deleteBar = (id) => api.delete(`/api/bar/bars/${id}`);
