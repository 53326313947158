import api from './api';

export const getVatReport = async (year, month, locationId = null) => {
  try {
    const response = await api.get('/api/vat-report', {
      params: {
        year,
        month,
        locationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const generateVatReport = async (year, month, locationId = null) => {
  try {
    const response = await api.post('/api/vat-report/generate', {
      year,
      month,
      locationId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
