import React, { useState } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../../components/custom-input';
import SubmitButton from '../../components/buttons/submit-button';
import useAuth from '../../hooks/useAuth';
import ConfirmMessage from '../../components/alerts/alert-message';

function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [passcodePopup, setPasscodePopup] = useState(null);

  return (
    <div className='flex items-center justify-center bg-hazel-green bg-loginBgImage bg-no-repeat bg-center bg-cover min-h-screen p-4'>
      <div className='bg-white rounded-lg w-full max-w-md md:max-w-lg lg:max-w-xl shadow-md px-6 sm:px-10 md:px-14 pt-8 sm:pt-12 md:pt-16 pb-6 sm:pb-10 md:pb-12'>
        <h3 className='text-hazel-green text-2xl sm:text-3xl font-bold'>
          Hi, Welcome Back
        </h3>
        <p className='text-hazel-green font-light'>
          Enter your credentials to continue
        </p>
        <Formik
          initialValues={{
            email: '',
            password: '',
            ownerPasscode: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Invalid email format')
              .required('Email is required*'),
            password: Yup.string()
              .min(8, 'Password must be at least 8 characters.')
              .required('Password is required*')
              .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
                'Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character.'
              ),
            ownerPasscode: Yup.string()
              .matches(/^\d{6}$/, 'Passcode must be exactly 6 digits.')
              .notRequired()
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              await login(values.email, values.password, values.ownerPasscode);
              navigate('/');
            } catch (err) {
              setErrors({
                submit: 'Email, password, or passcode is incorrect.'
              });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit
          }) => (
            <form onSubmit={handleSubmit}>
              <div className='mt-6'>
                <CustomInput
                  type='text'
                  name='email'
                  label='Email'
                  value={values.email}
                  onChange={handleChange('email')}
                  onBlur={handleBlur}
                  showError={errors.email && touched.email}
                  errorMessage={errors.email}
                />
                <CustomInput
                  type='password'
                  name='password'
                  label='Password'
                  value={values.password}
                  onChange={handleChange('password')}
                  onBlur={handleBlur}
                  showError={errors.password && touched.password}
                  errorMessage={errors.password}
                />
                <CustomInput
                  type='password'
                  name='ownerPasscode'
                  label='Passcode (Optional)'
                  value={values.ownerPasscode}
                  onChange={handleChange('ownerPasscode')}
                  onBlur={handleBlur}
                  showError={errors.ownerPasscode && touched.ownerPasscode}
                  errorMessage={errors.ownerPasscode}
                />
                <hr className='mt-6 mb-4' />
                {errors.submit && <p className='text-error'>{errors.submit}</p>}
                <div className='flex justify-end mb-4'>
                  <Link
                    to='/forgot-password'
                    className='text-hazel-green text-sm'
                  >
                    Forgot password?
                  </Link>
                </div>
                <div className='flex justify-center items-center'>
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    width='w-full sm:w-3/5'
                    title='Log in'
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Login;
