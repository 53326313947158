import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import DishCard from './DishCard';
import { createOrder, updateOrder } from '../../api/restaurantInterface';
import { getAllDishes } from '../../api/restaurantInterface';
import SelectInput from '../../components/select-input';
import { useDrop } from 'react-dnd';
import { getAllEnvironments } from '../../api/restaurantInterface';
import { getTablesByEnvironmentId } from '../../api/restaurantInterface';
import { v4 as uuidv4 } from 'uuid';




function OrderForm({ isEdit, order, tableId, selectedDishes, setSelectedDishes, handleDishDrop }) {
  const [droppedDishes, setDroppedDishes] = useState(selectedDishes);
  const [loading, setLoading] = useState(false);
  const [environments, setEnvironments] = useState([]);
  const [tables, setTables] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEnvironments = async () => {
      try {
        const response = await getAllEnvironments();
        setEnvironments(response.data);
      } catch (err) {
        console.error('Failed to fetch environments:', err);
      }
    };

    fetchEnvironments();
  }, []);

  useEffect(() => {
    const calculatedTotalPrice = droppedDishes.reduce((total, dish) => total + dish.price, 0);
    setTotalPrice(calculatedTotalPrice);
  }, [droppedDishes]);

  const [{ isOver }, drop] = useDrop({
    accept: 'dish',
    drop: (item) => {
      const newItem = { ...item, uniqueId: uuidv4() };
      setDroppedDishes((prevDishes) => [...prevDishes, newItem]);
      handleDishDrop(newItem);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const fetchTables = useCallback(async (environmentId) => {
    try {
      if (!environmentId) {
        setTables([]);
        return;
      }
      const response = await getTablesByEnvironmentId(environmentId);
      setTables(response.data);
    } catch (err) {
      console.error('Failed to fetch tables:', err);
    }
  }, []);

  const initialValues = {
    tableId: tableId ? parseInt(tableId, 10) : '',
    environmentId: '',
  };

  const validationSchema = Yup.object().shape({
    tableId: Yup.number().required('Table ID is required*'),
    environmentId: Yup.string().required('Environment is required*'),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setLoading(true);
    try {
      // const orderData = {
      //   tableId: values.tableId,
      //   totalPrice: totalPrice,
      //   environmentId: values.environmentId,
      //   status: 'unpaid',
      //   dishes: droppedDishes.map(dish => ({
      //     dishSectionId: dish.id,
      //     quantity: 1, // You can modify this if you have quantity in your form
      //   })),
      // };


      const orderData = {
        tableId: values.tableId,
        totalPrice: totalPrice,
        environmentId: values.environmentId,
        status: 'unpaid',
        dishSections: droppedDishes.map(dish => ({
          dishSectionId: dish.id,
          quantity: 1, // You can modify this if you have quantity in your form
        })),
      };
      const response = await createOrder(orderData);
      navigate('/orders');
    } catch (err) {
      setErrors({ submit: err?.response?.data });
      console.error('Submission error:', err);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <div className='z-2 border border-gray-200 rounded-xl shadow-lg mt-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className='bg-gray-100 px-7 py-5'>
            <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
              <SelectInput
                name='environmentId'
                value={values.environmentId}
                label='Environment'
                options={environments.map((env) => ({
                  id: env.id,
                  title: env.name,
                }))}
                onChange={(e) => {
                  setFieldValue('environmentId', e.target.value);
                  fetchTables(e.target.value);
                }}
                onBlur={handleBlur}
                showError={errors.environmentId && touched.environmentId}
                errorMessage={errors.environmentId}
              />
              <SelectInput
                name='tableId'
                value={values.tableId}
                label='Table'
                options={tables.map((table) => ({
                  id: table.id,
                  title: `Table ${table.number}`,
                }))}
                onChange={(e) => setFieldValue('tableId', e.target.value)}
                onBlur={handleBlur}
                showError={errors.tableId && touched.tableId}
                errorMessage={errors.tableId}
                disabled={!values.environmentId}
              />
              <div
                ref={drop}
                className={`relative border-2 border-dashed ${
                  isOver ? 'border-green-400 bg-green-100' : 'border-gray-300 bg-white'
                } p-4 rounded-lg min-h-[200px]`}
              >
                <p className='text-gray-600'>
                  {isOver ? 'Release to drop' : 'Drag dishes here'}
                </p>
                <div className='p-1 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 gap-y-0.5 gap-x-1 mt-4'>
                  {droppedDishes.map((dish) => (
                    <div key={dish.uniqueId} className='transform scale-50'>
                      <DishCard
                        name={dish.name}
                        imageUrl={dish.imageUrl}
                        description={dish.description}
                        price={dish.price}
                      />
                      <button
                        type='button'
                        onClick={() => setDroppedDishes(dishes => dishes.filter(d => d.uniqueId !== dish.uniqueId))}
                        className='absolute top-0 right-0 p-1 text-red-600 hover:text-red-800'
                      >
                        ❌
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <CustomInput
                type='number'
                name='totalPrice'
                label='Total Price'
                value={totalPrice}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.totalPrice && touched.totalPrice}
                errorMessage={errors.totalPrice}
                disabled
              />
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
            </div>
            <div className='z-2 flex justify-end items-center w-full mt-4 text-sm'>
              <CancelButton onCancel={() => navigate('/restaurant-interface/orders')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/2 md:w-1/6'
                title={`${isEdit ? 'Edit Order' : 'Create Order'}`}
              />
            </div>
            {errors.submit && <p className='text-error'>{errors.submit}</p>}
          </form>
        )}
      </Formik>
    </div>
  );
}

export default OrderForm;







