import React from "react";
import { Link } from "react-router-dom";

function Heading({ heading, title, subtitle }) {
  return (
    // <>
    //   <div className="container-fluid page-header mb-5 p-0 bg-image">
    //     <div className="container-fluid page-header-inner py-5">
    //       <div className="container text-center pb-5">
    //         <h1 className="display-3 text-white mb-3 animated slideInDown">
    //           {heading}
    //         </h1>
    //         <nav aria-label="breadcrumb">
    //           <ol className="breadcrumb justify-content-center text-uppercase">
    //             <li className="breadcrumb-item">
    //               <Link to="/">{title}</Link>
    //             </li>
    //             <li
    //               className="breadcrumb-item text-white active"
    //               aria-current="page"
    //             >
    //               {subtitle}
    //             </li>
    //           </ol>
    //         </nav>
    //       </div>
    //     </div>
    //   </div>
    // </>

    <>
  <div className="bg-cover bg-center bg-gray-800 p-0 mb-5">
    <div className="bg-gray-900 py-16">
      <div className="max-w-7xl mx-auto text-center pb-16">
        <h1 className="text-4xl text-white mb-3 animate-slide-in-down">
          {heading}
        </h1>
        <nav aria-label="breadcrumb">
          <ol className="flex justify-center space-x-2 text-white uppercase">
            <li>
              <Link className="hover:underline" to="/">
                {title}
              </Link>
            </li>
            <li className="text-white" aria-current="page">
              {subtitle}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</>

  );
}


export default Heading;