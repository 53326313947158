import React from "react";
import { Building2, Phone, Mail, Globe } from "lucide-react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button } from "../shadCN/Button.tsx";

import "../../../src/views/administration/tables/accountingTables/pdf.css";

interface InvoiceDocumentProps {
  invoiceNumber: string;
  date: string;
  clientName: string;
  items: Array<{
    id: number;
    details: string;
    quantity: number;
    rate: number;
    amount: number;
    tax?: number;
    discount?: number;
  }>;
  totalAmount: number;
  status: string;
  notes?: string;
}

const TAX_RATE = 0.18; // 18% tax rate

export const InvoiceDocument: React.FC<InvoiceDocumentProps> = ({
  invoiceNumber,
  date,
  clientName,
  items,
  totalAmount,
  status,
  notes,
}) => {
  console.log('InvoiceDocument received:', {
    invoiceNumber,
    date,
    clientName,
    items,
    totalAmount,
    status,
    notes,
  });

  // Derive isPaid from status
  const isPaid = status.toLowerCase() === 'paid';
  
  // Format dates with proper error handling
  const formatDate = (dateString: string) => {
    try {
      if (!dateString) return 'Date not available';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'Invalid date';
      
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(date);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date error';
    }
  };

  // Format details to be more minimal
  const formatDetails = (details: string): string => {
    // For pool tickets, just show "Pool Ticket (Standard/Adult/Child)"
    if (details.includes('Pool') && details.includes('Ticket')) {
      const ticketType = details.includes('STANDARD') ? 'Standard' : 
                        details.includes('MAN') ? 'Adult' : 
                        details.includes('WOMAN') ? 'Adult' :
                        details.includes('KID') ? 'Child' : '';
      return `Pool Ticket (${ticketType})`;
    }
    
    // For restaurant orders, just show the item name
    if (details.includes('Restaurant Order') || details.includes('Bar Order')) {
      // Extract just the item name before the order number
      const itemName = details.split('(')[0].trim();
      return itemName;
    }
    
    return details;
  };

  const invoiceDate = formatDate(date);
  // Calculate due date (15 days from invoice date)
  const dueDate = formatDate(new Date(new Date(date).getTime() + 15 * 24 * 60 * 60 * 1000).toISOString());

  // Calculate subtotal (before tax)
  const calculateSubtotal = (items: any[]) => {
    return items.reduce((sum, item) => 
      sum + ((item.quantity * item.rate) - (item.discount || 0)), 0
    );
  };

  // Calculate tax for a single item
  const calculateItemTax = (item: any) => {
    const baseAmount = (item.quantity * item.rate) - (item.discount || 0);
    return baseAmount * TAX_RATE;
  };

  // Calculate total tax
  const calculateTotalTax = (items: any[]) => {
    return items.reduce((sum, item) => {
      // If tax is already provided, use it; otherwise calculate it
      const itemTax = item.tax ?? calculateItemTax(item);
      return sum + itemTax;
    }, 0);
  };

  const subtotal = calculateSubtotal(items);
  const totalTax = calculateTotalTax(items);
  const calculatedTotal = subtotal + totalTax;

  const generatePDF = async () => {
    const element = document.getElementById('invoice-content');
    if (!element) return;

    try {
      const canvas = await html2canvas(element, {
        scale: 2, // Higher scale for better quality
        useCORS: true, // To handle cross-origin images
        logging: false, // Disable logging
        backgroundColor: '#ffffff' // Ensure white background
      });

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(`invoice-${invoiceNumber}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div className="max-w-5xl mx-auto">
      {/* Separate the actual invoice content for PDF generation */}
      <div id="invoice-content" className="bg-white rounded-xl border border-gray-100">
        {/* Header Section with Logo */}
        <div className="border-b border-gray-100">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-4 md:p-8">
            {/* Logo and Company Info */}
            <div className="space-y-4">
              <div className="w-36 md:w-48 h-16 md:h-20 bg-gray-50 rounded-lg flex items-center justify-center overflow-hidden mx-auto md:mx-0">
                <img 
                  src="/images/lake-side-logo.png"
                  alt="Dolphins Group Logo"
                  className="object-contain w-full h-full p-2"
                  crossOrigin="anonymous"
                />
              </div>
              <div className="space-y-2 text-center md:text-left">
                <h2 className="text-xl font-bold text-hazel-green">Dolphins Group</h2>
                <div className="space-y-1 text-sm text-gray-600">
                  <div className="flex items-center justify-center md:justify-start gap-2">
                    <Building2 className="w-4 h-4 flex-shrink-0" />
                    <span>Peje, Kosova Republic</span>
                  </div>
                  <div className="flex items-center justify-center md:justify-start gap-2">
                    <Phone className="w-4 h-4 flex-shrink-0" />
                    <span>+383 49 123 456</span>
                  </div>
                  <div className="flex items-center justify-center md:justify-start gap-2">
                    <Mail className="w-4 h-4 flex-shrink-0" />
                    <span className="break-all">enisgjocaj1@hotmail.com</span>
                  </div>
                  <div className="flex items-center justify-center md:justify-start gap-2">
                    <Globe className="w-4 h-4 flex-shrink-0" />
                    <span>www.dolphins-group.com</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Invoice Details */}
            <div className="text-center md:text-right space-y-4">
              <div>
                <h1 className="text-4xl font-bold text-hazel-green tracking-tight">INVOICE</h1>
                <p className="text-gray-500 mt-1">#{invoiceNumber}</p>
              </div>
              <div className="inline-block rounded-lg bg-gray-50 px-6 py-4 mt-4">
                <p className="text-sm text-gray-600 mb-1">Balance Due</p>
                <p className="text-3xl font-bold text-hazel-green">
                  €{calculatedTotal.toFixed(2)}
                </p>
              </div>
              <div className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
                ${isPaid ? 'bg-green-50 text-green-700' : 'bg-amber-50 text-amber-700'}`}>
                {isPaid ? '● Paid' : '○ Pending'}
              </div>
            </div>
          </div>
        </div>

        {/* Bill To & Invoice Details */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-4 md:p-8 border-b border-gray-100">
          <div className="space-y-4">
            <div>
              <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider">Bill To</h3>
              <div className="mt-2">
                <p className="font-medium text-gray-800">{clientName}</p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <p className="text-sm font-medium text-gray-500">Invoice Date</p>
              <p className="mt-1 text-gray-800">{invoiceDate}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Due Date</p>
              <p className="mt-1 text-gray-800">{dueDate}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Terms</p>
              <p className="mt-1 text-gray-800">Due on Receipt</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">P.O.#</p>
              <p className="mt-1 text-gray-800">1</p>
            </div>
          </div>
        </div>

        {/* Items Table */}
        <div className="p-4 md:p-8">
          <div className="overflow-x-auto -mx-4 md:mx-0">
            <div className="min-w-[600px] md:w-full rounded-lg border border-gray-100">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Qty</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Rate</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Tax</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {items.map((item, index) => {
                    const itemTax = item.tax ?? calculateItemTax(item);
                    return (
                      <tr key={item.id} className="text-gray-800">
                        <td className="px-6 py-4 whitespace-nowrap text-sm">{index + 1}</td>
                        <td className="px-6 py-4 text-sm">{formatDetails(item.details)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right">{item.quantity}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right">€{item.rate?.toFixed(2) || '0.00'}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right">€{itemTax.toFixed(2)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right font-medium">€{((item.quantity * item.rate) + itemTax - (item.discount || 0)).toFixed(2)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Totals */}
        <div className="mt-8 flex justify-end px-4 md:px-8">
          <div className="w-full md:w-80 space-y-3">
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Subtotal</span>
              <span className="font-medium text-gray-800">€{subtotal.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Tax (18%)</span>
              <span className="font-medium text-gray-800">€{totalTax.toFixed(2)}</span>
            </div>
            <div className="border-t border-gray-100 pt-3 flex justify-between font-medium">
              <span className="text-gray-800">Total</span>
              <span className="text-hazel-green">€{calculatedTotal.toFixed(2)}</span>
            </div>
          </div>
        </div>

        {/* Notes & Terms */}
        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8 p-4 md:p-8">
          <div>
            <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-3">Notes</h3>
            <p className="text-gray-600 text-sm">{notes || 'Thanks for your business.'}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-3">Terms & Conditions</h3>
            <p className="text-gray-600 text-sm">Please pay within 15 days of receiving this invoice.</p>
          </div>
        </div>
      </div>

      {/* Options and actions outside the PDF content */}
      <div className="mt-6 flex justify-end space-x-4">
        <Button
          onClick={generatePDF}
          className="bg-hazel-green hover:bg-sage text-white"
        >
          Download PDF
        </Button>
        {/* Other action buttons */}
      </div>
    </div>
  );
};

InvoiceDocument.displayName = 'InvoiceDocument';