// PurchasesLayout.js
import { Outlet } from 'react-router-dom';
import BudgetNavigation from './BudgetNavigation.tsx';
import { Plus } from 'lucide-react';
import { Button } from '../../../../../components/shadCN/Button.tsx';

import React from 'react';

export default function BudgetsLayout() {
  return (
    <div className="min-h-screen bg-seashell">
      <div className="bg-white border-b border-mercury px-8 py-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold text-hazel-green">Budgets</h1>
          
        </div>
        <BudgetNavigation />
      </div>
      <div className="container mx-auto py-8 px-4">
        <Outlet />
      </div>
    </div>
  );
}
