import React, { useState, useEffect } from 'react';
import { Search, Filter, Plus, Trash2, ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '../../../../../components/shadCN/Button.tsx';
import ItemForm from '../../../../../components/items/ItemsForm.tsx';
import { createItemOrService, getAllItemsOrServices, deleteItemOrService } from '../../../../../api/itemOrService.js';

interface Item {
  id: number;
  name: string;
  description: string | null;
  unit: string;
  sellingPrice: number;
  costPrice: number;
  category: string;
  itemType: string;
  incomeAccountId: number;
  expenseAccountId: number;
  createdAt: string;
  updatedAt: string;
}

export default function ItemsList() {
  const [items, setItems] = useState<Item[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await getAllItemsOrServices();
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const handleAddItem = async (newItem: Item) => {
    try {
      const response = await createItemOrService(newItem);
      if (response.data) {
        setItems((prevItems) => [...prevItems, response.data]);
      } else {
        await fetchItems();
      }
    } catch (error) {
      console.error("Error creating item:", error);
    }
  };

  const handleDeleteItem = async (itemId: number) => {
    try {
      await deleteItemOrService(itemId);
      setItems((prevItems) => prevItems.filter(item => item.id !== itemId));
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Generate page numbers to display
  const getPageNumbers = (currentPage: number, totalPages: number): number[] => {
    const pageNumbers: number[] = [];
    const totalPagesToShow: number = 3;
    
    // Determine the starting and ending page numbers
    let startPage: number = Math.max(1, currentPage - 1);
    let endPage: number = Math.min(totalPages, startPage + totalPagesToShow - 1);

    // Adjust startPage if necessary
    if (endPage - startPage + 1 < totalPagesToShow) {
        startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    // Generate the page numbers
    for (let i: number = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return pageNumbers;
};


return (
  <div className="max-w-[1400px] mx-auto px-4">
    <div className="flex justify-between items-center mb-6">
      <button 
        onClick={() => setIsFormOpen(true)}
        className="inline-flex items-center px-4 py-2 bg-hazel-green hover:bg-sage text-white rounded-lg transition-colors duration-200"
      >
        <Plus className="h-4 w-4 mr-2" />
        New Item
      </button>
      <div className="flex items-center gap-4">
        <div className="relative">
          <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
          <input
            type="text"
            placeholder="Search items..."
            className="pl-10 pr-4 py-2 border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
          />
        </div>
        <Button variant="outline" className="border-grey-goose text-bluish-grey hover:bg-tealish-blue">
          <Filter className="h-4 w-4 mr-2" />
          Filter
        </Button>
      </div>
    </div>

    <div className="bg-white rounded-xl shadow-sm border border-mercury overflow-x-auto">
      <table className="w-full min-w-[600px]">
        <thead>
          <tr className="bg-tealish-blue">
            <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">ITEM #</th>
            <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">NAME</th>
            <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">DESCRIPTION</th>
            <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">CATEGORY</th>
            <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">UNIT</th>
            <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">SELLING PRICE</th>
            <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">COST PRICE</th>
            <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">ITEM TYPE</th>
            <th className="px-2 py-1 text-center text-xs font-semibold text-bluish-grey">ACTIONS</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-mercury">
          {currentItems.map((item) => (
            <tr key={item.id} className="hover:bg-seashell transition-colors duration-200">
              <td className="px-2 py-1 text-sm font-medium text-hazel-green">{item.id}</td>
              <td className="px-2 py-1 text-sm text-bluish-grey truncate max-w-[100px]">{item.name}</td>
              <td className="px-2 py-1 text-sm text-bluish-grey truncate max-w-[150px]">{item.description || 'N/A'}</td>
              <td className="px-2 py-1 text-sm text-bluish-grey truncate max-w-[100px]">{item.category}</td>
              <td className="px-2 py-1 text-sm text-bluish-grey">{item.unit}</td>
              <td className="px-2 py-1 text-sm font-medium text-bluish-grey">€{item.sellingPrice.toFixed(2)}</td>
              <td className="px-2 py-1 text-sm font-medium text-bluish-grey">€{item.costPrice.toFixed(2)}</td>
              <td className="px-2 py-1 text-sm text-bluish-grey truncate max-w-[100px]">{item.itemType}</td>
              <td className="px-2 py-1 text-center">
                <button 
                  onClick={() => handleDeleteItem(item.id)}
                  className="inline-flex items-center justify-center p-2 rounded-full hover:bg-red-50 transition-colors"
                >
                  <Trash2 className="h-4 w-4 text-red-500 hover:text-red-700" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    {/* Enhanced Pagination */}
    <div className="flex justify-center items-center gap-2 mt-6">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="p-2 rounded-lg border border-mercury disabled:opacity-50 disabled:cursor-not-allowed hover:bg-seashell transition-colors"
      >
        <ChevronLeft className="h-4 w-4 text-bluish-grey" />
      </button>
      
      {getPageNumbers(currentPage, totalPages).map((number) => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
              ${currentPage === number 
                ? 'bg-hazel-green text-white' 
                : 'bg-white text-bluish-grey border border-mercury hover:bg-seashell'
              }`}
          >
            {number}
          </button>
        ))}

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="p-2 rounded-lg border border-mercury disabled:opacity-50 disabled:cursor-not-allowed hover:bg-seashell transition-colors"
      >
        <ChevronRight className="h-4 w-4 text-bluish-grey" />
      </button>
    </div>

    <ItemForm isOpen={isFormOpen} onClose={() => setIsFormOpen(false)} onAddItem={handleAddItem} />
  </div>
);
}