function CommonHeading({ heading, title, subtitle }) {
  return (
    // <>
    //   <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
    //     <h6 className="section-title text-center text-[#FEA116] text-uppercase">
    //       {heading}
    //     </h6>
    //     <h1 className="mb-5">
    //       {subtitle}{" "}
    //       <span className="text-[#FEA116] text-uppercase">{title}</span>
    //     </h1>
    //   </div>
    // </>
    <>
  <div className="text-center wow fadeInUp">
    <h6 className="text-[#FEA116] text-uppercase text-lg font-semibold mb-3">
      {heading}
    </h6>
    <h1 className="text-3xl font-bold mb-5">
      {subtitle}{" "}
      <span className="text-[#FEA116] text-uppercase">{title}</span>
    </h1>
  </div>
</>

  );
}

export default CommonHeading;