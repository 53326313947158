import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Card } from './shadCN/Card.tsx';
import { Input } from './shadCN/Input.tsx';
import { Button } from './shadCN/Button.tsx';
import { getInventoryProducts } from '../api/inventory';
import api from "../api/api.js";
import { toast } from 'react-hot-toast';

const modalVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: {
      type: "spring",
      duration: 0.5,
      bounce: 0.3
    }
  },
  exit: { 
    opacity: 0, 
    scale: 0.95,
    transition: {
      duration: 0.2
    }
  }
};

const overlayVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: {
      duration: 0.3
    }
  },
  exit: { 
    opacity: 0,
    transition: {
      duration: 0.2
    }
  }
};

const ProductSelectionModal = ({ isOpen, onClose, onSelect }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const data = await getInventoryProducts();
        
        // Group products by base name (removing section tags)
        const groupedProducts = data.reduce((acc, product) => {
          // Remove section tags with different formats: [section], (section)
          const baseProductName = product.name
            .replace(/[\[\(\s]+(?:bar|pool|kitchen|minibar|main|outdoor_pool)[\]\)\s]+$/i, '')
            .trim();
          
          if (!acc[baseProductName]) {
            // Initialize with the first product's data
            acc[baseProductName] = {
              ...product,
              name: baseProductName,
              originalQuantity: product.quantity,
              quantity: product.quantity,
              sections: [{
                section: product.section,
                quantity: product.quantity,
                id: product.id 
              }]
            };
          } else {
            // Update existing product
            acc[baseProductName].quantity += product.quantity;
            acc[baseProductName].sections.push({
              section: product.section,
              quantity: product.quantity,
              id: product.id // Keep the original ID for selection
            });
          }
          return acc;
        }, {});

        setProducts(Object.values(groupedProducts));
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchProducts();
    }
  }, [isOpen]);

  const filteredProducts = products?.filter(product => {
    if (!product) return false;
    
    const matchesSearch = (product.name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
                         (product.code?.toLowerCase() || '').includes(searchTerm.toLowerCase());
    return matchesSearch;
  }) || [];

  const handleProductSelect = async (product) => {
    try {
      // Get the section with highest quantity
      const highestQuantitySection = product.sections.reduce((prev, curr) => 
        curr.quantity > prev.quantity ? curr : prev
      );

      // Get the accounting article link for this product
      const response = await api.get(`/api/accounting-article/by-product/${highestQuantitySection.id}`);
      console.log('Article link response:', response.data);

      if (!response.data?.message?.accountingArticle) {
        toast.error(`No accounting article found for ${product.name}`);
        return;
      }

      const { accountingArticle } = response.data.message;

      // Format the product data with the correct article ID
      const lineItem = {
        inventoryProductId: highestQuantitySection.id, // Add this line
        id: Date.now(),
        article: accountingArticle.id, // This is the accounting article ID
        name: product.name,
        unit: accountingArticle.unit.name,
        quantity: 1,
        price: product.unitPrice || 0,
        valuePrice: product.totalPrice || 0,
        supplierPrice: product.unitPrice || 0,
        rabate: 0,
        transport: 0,
        tariff: 0,
        account: accountingArticle.purchaseAccount?.id || '',
        tvsh: accountingArticle.vatRate?.rate || 0,
        section: highestQuantitySection.section,
        availableQuantity: highestQuantitySection.quantity,
        description: product.description,
        // Keep reference to original product
        inventoryProductId: highestQuantitySection.id
      };

      console.log('Created line item with article:', lineItem);
      onSelect(lineItem);
    } catch (error) {
      console.error('Error getting article link:', error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const renderProduct = (product) => (
    <motion.div
      key={product.id}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="group"
    >
      <button
        onClick={() => handleProductSelect(product)}
        className="w-full text-left p-4 rounded-lg border border-mercury hover:border-sage focus:border-sage focus:ring-2 focus:ring-sage/20 transition-all duration-200 bg-white hover:bg-grey-goose/5"
      >
        <div className="font-medium text-hazel-green mb-1">{product.name}</div>
        <div className="text-sm text-bluish-grey mb-2">
          Total Quantity: {product.quantity} {product.unit}
        </div>
        <div className="text-xs text-gray-500 mb-2 flex flex-wrap gap-2">
          {product.sections?.map(({ section, quantity }) => (
            <span key={section} className="bg-gray-100 px-2 py-1 rounded">
              {section}: {quantity}
            </span>
          ))}
        </div>
        <div className="flex justify-between items-center text-sm">
          <span className="text-sage">{product.unit}</span>
          <span className="bg-grey-goose/10 px-2 py-1 rounded text-bluish-grey">
            ${product.unitPrice?.toFixed(2) || '0.00'}
          </span>
        </div>
      </button>
    </motion.div>
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50"
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={onClose}
          />
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-6"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <Card className="bg-white rounded-xl shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-hidden border-0">
              <div className="bg-gradient-to-r from-hazel-green/90 to-sage/90 p-6 flex justify-between items-center">
                <div>
                  <h2 className="text-2xl font-semibold text-white mb-1">Select Product</h2>
                  <p className="text-white/80 text-sm">Choose a product from inventory</p>
                </div>
                <button
                  onClick={onClose}
                  className="text-white/80 hover:text-white transition-colors p-2 hover:bg-white/10 rounded-full"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="p-6">
                <div className="flex gap-4 mb-6">
                  <Input
                    type="text"
                    placeholder="Search products..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="flex-1"
                  />
                </div>

                <div className="overflow-y-auto max-h-[calc(90vh-16rem)]">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {loading ? (
                      <div className="col-span-full flex justify-center items-center py-12">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-sage"></div>
                      </div>
                    ) : filteredProducts.length === 0 ? (
                      <div className="col-span-full text-center py-12 text-bluish-grey">
                        No products found
                      </div>
                    ) : (
                      filteredProducts.map(renderProduct)
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default ProductSelectionModal;
