import React from 'react';
import { IconAlertTriangle } from '@tabler/icons-react';
import StockLossItemForm from './StockLossItemForm';
import PageTitle from '../../../components/page-title';

function AddStockLossItem() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconAlertTriangle className='text-hazel-green mr-2' size={22} />}
        title='Add New Stock Loss Item'
      />
      <StockLossItemForm isEdit={false} />
    </div>
  );
}

export default AddStockLossItem;