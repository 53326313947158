import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../components/loader';
import SelectInput from '../../../components/select-input';
import CustomInput from '../../../components/custom-input';
import DateInput from '../../../components/date-input';
import { SubmitButton, CancelButton } from '../../../components/buttons';
import { checkRoomReservation } from '../../../api/conferenceRoomReservation';
import { getAllConferenceRooms } from '../../../api/conferenceRoom';
import { getRoomTypes as getRoomTypesApi } from '../../../api/rooms';
import { getAllMenus } from '../../../api/conferenceRoomMenu';
import api from '../../../api/api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { paymentMethods } from '../../../constants/entities';

function ConferenceRoomReservationForm({ 
  isEdit, 
  formType = 'conference',
  reservation, 
  conferenceRoomId 
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const defaultReservationPage = location.pathname === '/conference-room/reservation';
  const [availabilityError, setAvailabilityError] = useState('');
  const [loading, setLoading] = useState(true);
  const [roomTypes, setRoomTypes] = useState([]);
  const [menus, setMenus] = useState([]);
  const [conferenceRooms, setConferenceRooms] = useState([]);

  useEffect(() => {
    getRoomTypes();
    getMenus();
    getConferenceRooms();
  }, []);

  const getConferenceRooms = async () => {
    try {
      const response = await getAllConferenceRooms();
      const formattedRooms = response.data.map((room) => ({
        id: room.id,
        title: room.name,
        pricePerHour: room.pricePerHour,
      }));
      setConferenceRooms(formattedRooms);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching conference rooms:', error);
      setLoading(false);
    }
  };

  const getRoomTypes = async () => {
    try {
      const response = await getRoomTypesApi();
      setRoomTypes(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching room types:', error);
      setLoading(false);
    }
  };

  const getMenus = async () => {
    try {
      const response = await getAllMenus();
      setMenus(response.data);
    } catch (error) {
      console.error('Error fetching menus:', error);
    }
  };

  const calculateTotalPrice = (values, setFieldValue) => {
    const discountAmount = values.roomPrice * (values.discount / 100);
    const totalPrice = values.roomPrice - discountAmount + values.deposit;
    setFieldValue('totalPrice', totalPrice);
  };

  const checkAvailability = async (roomId, startDate, endDate) => {
    try {
      const response = await checkRoomReservation(roomId, startDate, endDate);
      return !response.data.isReserved;
    } catch (error) {
      console.error('Error checking room reservation:', error);
      return false;
    }
  };

  const handleSubmit = async (values, { setErrors, setSubmitting }) => {
    try {
      console.log('Form values:', values);
      const isAvailable = await checkAvailability(
        values.roomId,
        new Date(values.fromDate),
        new Date(values.toDate)
      );

      if (!isAvailable) {
        setAvailabilityError('The conference room is not available for the selected dates.');
        setSubmitting(false);
        return;
      }

      setAvailabilityError('');

      // Create or get client
      let clientId;
      try {
        const response = await api.post('/api/accounting/clients/check-or-create', {
          name: `${user.name} ${user.surname}`,
          phone: values.phoneNumber || '',
          email: values.email || '',
          address: ''
        });
        clientId = response.data.id;
      } catch (error) {
        console.error('Error checking or creating client:', error);
        toast.error('Failed to check or create client.');
        return;
      }

      // Calculate total amount
      const totalAmount = values.totalPrice;

      // Generate invoice number using timestamp
      const timestamp = new Date().getTime();
      const invoiceNumber = `INV-ConferenceRoom-${timestamp}`;

      // Prepare invoice data
      const invoiceData = {
        invoiceNumber,
        clientId,
        invoiceDate: new Date(),
        reference: invoiceNumber,
        terms: 'Payment due upon receipt',
        notes: `Conference Room Reservation Invoice - ${values.guests} guests${formType === 'room' ? ' with Room Accommodation' : ''}`,
        paymentMethod: values.paymentType,
        status: 'Completed',
        totalAmount,
        items: [
          {
            details: 'Conference Room Reservation',
            quantity: 1,
            rate: values.roomPrice,
            amount: values.roomPrice,
            discount: values.discount || 0,
            tax: 0
          },
          ...(formType === 'room' ? [{
            details: `Room Accommodation - ${values.numberOfPeople} people`,
            quantity: 1,
            rate: values.roomPrice || 0,
            amount: values.roomPrice || 0,
            discount: 0,
            tax: 0
          }] : []),
          ...(values.menuId ? [{
            details: `Conference Room Menu Package${menus.find(m => m.id === values.menuId)?.name ? ` - ${menus.find(m => m.id === values.menuId).name}` : ''}`,
            quantity: values.guests,
            rate: menus.find(m => m.id === values.menuId)?.price || 0,
            amount: (menus.find(m => m.id === values.menuId)?.price || 0) * values.guests,
            discount: 0,
            tax: 0
          }] : [])
        ]
      };

      // Prepare transaction data
      const transactionData = {
        date: new Date(),
        description: `Conference Room Reservation - ${invoiceNumber}`,
        amount: totalAmount,
        paymentMethod: values.paymentType === 'CASH' ? 'CASH' : 
                    values.paymentType === 'BANK' ? 'BANK_TRANSFER' : 'CASH',
        transactionType: 'RESERVATION',
        reference: invoiceNumber,
        acceptedBy: user.id,
        paymentStatus: 'PAID',
        paymentDate: new Date(),
        paymentReference: invoiceNumber,
      };

      // Prepare reservation data
      const reservationData = {
        conferenceRoomId: parseInt(values.roomId, 10),
        userId: user.id,
        startDate: values.fromDate,
        endDate: values.toDate,
        numberOfGuests: parseInt(values.guests, 10),
        email: values.email,
        phoneNumber: values.phoneNumber,
        paidPrice: parseFloat(values.paidPrice) || null,
        menuId: values.menuId,
        roomTypeId: values.roomTypeId,
        discount: parseFloat(values.discount) || null,
        roomPrice: parseFloat(values.roomPrice) || null,
        deposit: parseFloat(values.deposit) || null,
        totalPrice: parseFloat(values.totalPrice) || null,
        paymentType: values.paymentType || 'CASH',
        ...(formType === 'room' && {
          numberOfPeople: parseInt(values.numberOfPeople, 10),
        }),
      };

      // Create reservation with all financial records in a single transaction
      const response = await api.post('/api/conferenceRoomReservation/create-reservation-with-financials', {
        reservationData,
        invoiceData,
        transactionData,
        accountId: 1 // Using account ID 1 for conference room revenue
      });

      console.log('Reservation created with financials:', response.data);
      toast.success('Reservation created successfully');
      navigate('/conference-room');
      setSubmitting(false);
    } catch (err) {
      console.error('Submission Error:', err);
      const errorMessage = err?.response?.data?.error || 'An unknown error occurred';
      setErrors({ submit: errorMessage });
      setSubmitting(false);
      toast.error(errorMessage);
    }
  };

  const validationSchema = Yup.object().shape({
    roomId: Yup.number().required('Conference Room is required*'),
    fromDate: Yup.string().required('Reservation start date is required*'),
    toDate: Yup.string().required('Reservation end date is required*'),
    guests: Yup.number().required('Number of guests is required*'),
    email: Yup.string().email('Invalid email format').required('Email is required*'),
    phoneNumber: Yup.string().required('Phone Number is required*'),
    discount: Yup.number().nullable(),
    roomPrice: Yup.number(),
    deposit: Yup.number().nullable(),
    totalPrice: Yup.number(),
    paymentType: Yup.string().required('Payment Type is required*'),
    paidPrice: Yup.number().nullable(),
    menuId: Yup.string().required('Menu is required'),
    ...(formType === 'room' && {
      roomTypeId: Yup.string().required('Room Type is required*'),
      numberOfPeople: Yup.number()
        .required('Number of people per room is required*')
        .min(1, 'At least 1 person is required'),
    }),
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={{
          roomId: defaultReservationPage ? (isEdit ? reservation?.roomId : '') : conferenceRoomId || '',
          roomTypeId: defaultReservationPage ? isEdit ? reservation?.roomTypeId : '' : '',
          fromDate: isEdit ? dayjs(reservation.fromDate) : null,
          toDate: isEdit ? dayjs(reservation.toDate) : null,
          guests: isEdit ? reservation.guests : 0,
          email: isEdit ? reservation.email : '',
          phoneNumber: isEdit ? reservation.phoneNumber : '',
          discount: isEdit ? reservation.discount : 0,
          roomPrice: defaultReservationPage ? isEdit ? reservation.roomPrice : 0 : conferenceRooms.find(room => room.id === conferenceRoomId)?.pricePerHour || 0,
          deposit: isEdit ? reservation.deposit : 0,
          totalPrice: defaultReservationPage ? isEdit ? reservation.totalPrice : 0 : conferenceRooms.find(room => room.id === conferenceRoomId)?.pricePerHour || 0,
          paymentType: isEdit ? reservation.paymentType : '',
          paidPrice: isEdit ? reservation.paidPrice : 0,
          menuId: '',
          ...(formType === 'room' && {
            numberOfPeople: isEdit ? reservation?.numberOfPeople : 1,
          }),
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-2 gap-x-6 gap-y-3'>
              <SelectInput
                value={Number(values.roomId)}
                label='Conference Room'
                options={conferenceRooms}
                onChange={(event) => {
                  const selectedRoomId = event.target.value;
                  const selectedRoom = conferenceRooms.find(room => room.id === selectedRoomId);
                  if (selectedRoom) {
                    setFieldValue('roomId', selectedRoomId);
                    setFieldValue('roomPrice', selectedRoom.pricePerHour);
                    setFieldValue('totalPrice', selectedRoom.pricePerHour);
                  } else {
                    setFieldValue('roomId', '');
                    setFieldValue('roomPrice', 0);
                    setFieldValue('totalPrice', 0);
                  }
                }}
                onBlur={handleBlur}
                showError={errors.roomId && touched.roomId}
                errorMessage={errors.roomId}
              />

              <SelectInput
                value={values.menuId}
                label='Menu'
                options={menus.map(menu => ({ id: menu.id, title: menu.name }))}
                onChange={(event) => {
                  setFieldValue('menuId', event.target.value);
                }}
                onBlur={handleBlur}
                showError={errors.menuId && touched.menuId}
                errorMessage={errors.menuId}
              />

              {formType === 'room' && (
                <>
                  <SelectInput
                    value={values.roomTypeId}
                    label='Room Type'
                    options={roomTypes.map(type => ({ id: type.id, title: type.title }))}
                    onChange={(event) => setFieldValue('roomTypeId', event.target.value)}
                    onBlur={handleBlur}
                    showError={errors.roomTypeId && touched.roomTypeId}
                    errorMessage={errors.roomTypeId}
                  />

                  <CustomInput
                    name='numberOfPeople'
                    label='Number of People per Room'
                    type='number'
                    value={values.numberOfPeople}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.numberOfPeople && touched.numberOfPeople}
                    errorMessage={errors.numberOfPeople}
                  />
                </>
              )}

              <SelectInput
                value={values.paymentType}
                label='Payment Type'
                options={paymentMethods}
                onChange={handleChange('paymentType')}
                onBlur={handleBlur}
                showError={errors.paymentType && touched.paymentType}
                errorMessage={errors.paymentType}
                isEntity
              />
            </div>

            <h3 className='text-bluish-grey font-medium uppercase mt-3'>
              Reservation Info
            </h3>
            <div className='border-b border-hazel-green py-1' />
            <div className='grid grid-cols-9 gap-3 mt-4'>
              <div className='col-span-2'>
                <DateInput
                  name='fromDate'
                  label='From Date'
                  value={values.fromDate}
                  onChange={(date) => setFieldValue('fromDate', date)}
                  onBlur={handleBlur}
                  showError={errors.fromDate && touched.fromDate}
                  errorMessage={errors.fromDate}
                  disablePast
                />
              </div>
              <div className='col-span-2'>
                <DateInput
                  name='toDate'
                  label='To Date'
                  value={values.toDate}
                  onChange={(date) => setFieldValue('toDate', date)}
                  onBlur={handleBlur}
                  showError={errors.toDate && touched.toDate}
                  errorMessage={errors.toDate}
                  disablePast
                />
              </div>
              <div className='col-span-2'>
                <CustomInput
                  name='guests'
                  label='Number of Guests'
                  type='number'
                  value={values.guests}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.guests && touched.guests}
                  errorMessage={errors.guests}
                />
              </div>
            </div>

            <h3 className='text-bluish-grey font-medium uppercase mt-3'>
              Contact Info
            </h3>
            <div className='border-b border-hazel-green py-1' />
            <div className='grid grid-cols-9 gap-3 mt-4'>
              <div className='col-span-3'>
                <CustomInput
                  name='email'
                  label='Email'
                  type='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.email && touched.email}
                  errorMessage={errors.email}
                />
              </div>
              <div className='col-span-3'>
                <CustomInput
                  name='phoneNumber'
                  label='Phone Number'
                  type='text'
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.phoneNumber && touched.phoneNumber}
                  errorMessage={errors.phoneNumber}
                />
              </div>
            </div>

            <h3 className='text-bluish-grey font-medium uppercase mt-3'>
              Reservation Cost
            </h3>
            <div className='border-b border-hazel-green py-1' />
            <div className='grid grid-cols-9 gap-3 mt-4'>
              <div className='col-span-3'>
                <CustomInput
                  name='discount'
                  label='Discount (%)'
                  type='number'
                  value={values.discount}
                  onChange={(e) => {
                    const discount = Number(e.target.value);
                    setFieldValue('discount', discount);
                    calculateTotalPrice(values, setFieldValue);
                  }}
                  onBlur={handleBlur}
                  showError={errors.discount && touched.discount}
                  errorMessage={errors.discount}
                />
              </div>
              <div className='col-span-3'>
                <CustomInput
                  name='deposit'
                  label='Deposit'
                  type='number'
                  value={values.deposit}
                  onChange={(e) => {
                    setFieldValue('deposit', Number(e.target.value));
                    calculateTotalPrice(values, setFieldValue);
                  }}
                  onBlur={handleBlur}
                  showError={errors.deposit && touched.deposit}
                  errorMessage={errors.deposit}
                />
              </div>
              <div className='col-span-3'>
                <CustomInput
                  name='totalPrice'
                  label='Total Price'
                  type='number'
                  value={values.totalPrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.totalPrice && touched.totalPrice}
                  errorMessage={errors.totalPrice}
                  disabled
                />
              </div>
            </div>

            <div className='flex justify-end items-center w-full mt-7 text-sm'>
              <CancelButton onCancel={() => navigate('/conference-room')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/5'
                title={isEdit ? 'Update Reservation' : 'Create Reservation'}
              />
            </div>

            {availabilityError && (
              <div className='mt-4 text-red-500'>
                {availabilityError}
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ConferenceRoomReservationForm;
