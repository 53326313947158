import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, AlertTriangle } from 'lucide-react';

interface DeleteUnitConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  unitName: string;
}

const DeleteUnitConfirmationModal: React.FC<DeleteUnitConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  unitName,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-bluish-grey/60 backdrop-blur-sm z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
          <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{ type: "spring", duration: 0.5 }}
              className="bg-white rounded-2xl shadow-2xl w-full max-w-lg overflow-hidden border border-grey-goose/20"
            >
              <div className="relative px-8 py-6 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <h2 className="text-2xl font-semibold text-bluish-grey">Fshi Njësinë Matëse</h2>
                    <span className="px-2 py-1 text-xs bg-red-100 text-red-600 rounded-full">
                      Fshirje
                    </span>
                  </div>
                  <button
                    onClick={onClose}
                    className="text-grey-goose hover:text-bluish-grey transition-colors"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              </div>

              <div className="p-8">
                <div className="flex items-start gap-4 mb-6">
                  <div className="p-3 bg-red-50 rounded-full">
                    <AlertTriangle className="w-6 h-6 text-red-600" />
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-bluish-grey mb-2">
                      A jeni i sigurt që dëshironi të fshini njësinë matëse "{unitName}"?
                    </h3>
                    <p className="text-sm text-grey-goose">
                      Ky veprim nuk mund të zhbëhet. Të gjitha të dhënat e lidhura me këtë njësi matëse do të fshihen përgjithmonë.
                    </p>
                  </div>
                </div>

                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 text-sm font-medium text-bluish-grey hover:bg-seashell rounded-lg transition-colors"
                  >
                    Anulo
                  </button>
                  <button
                    type="button"
                    onClick={onConfirm}
                    className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors"
                  >
                    Fshi Njësinë
                  </button>
                </div>
              </div>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  );
};

export default DeleteUnitConfirmationModal;
