import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ConfirmMessage from '../../components/alerts/alert-message';
import CustomInput from '../../components/custom-input';
import SubmitButton from '../../components/buttons/submit-button';
import { forgotPassword } from '../../api/auth.api';

function ForgotPassword() {
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  return (
    <div className='flex items-center justify-center bg-hazel-green bg-loginBgImage bg-no-repeat bg-center bg-cover h-screen'>
      <div className='bg-white rounded-lg md:w-1/2 lg:w-1/3 shadow-md px-10 md:px-14 pt-16 pb-12'>
        <h3 className='text-hazel-green text-3xl font-bold'>Forgot Password</h3>
        <Formik
          initialValues={{
            email: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Invalid email format')
              .required('Email is required*')
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              const response = await forgotPassword(values);
              if (
                response.data ===
                'Your password reset request was successful, please check your email.'
              ) {
                setShowAlert(true);
              }
              localStorage.setItem('email', values.email);
            } catch (err) {
              setErrors({
                submit: err.response.data
              });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit
          }) => (
            <form onSubmit={handleSubmit}>
              <div className='mt-6'>
                <CustomInput
                  type='text'
                  name='email'
                  label='Email'
                  value={values.email}
                  onChange={handleChange('email')}
                  onBlur={handleBlur}
                  showError={errors.email && touched.email}
                  errorMessage={errors.email}
                />
                <hr className='mt-6 mb-4' />
                {errors.submit && <p className='text-error'>{errors.submit}</p>}
                <div className='flex justify-end mb-4'>
                  <Link to='/' className='text-hazel-green text-sm'>
                    Back to login
                  </Link>
                </div>
                <div className='flex justify-center items-center'>
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    width='w-3/5'
                    title='Submit'
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      {showAlert && (
        <ConfirmMessage
          text='Please check your email for further steps.'
          onConfirm={() => {
            setShowAlert(false);
            navigate('/new-password');
          }}
        />
      )}
    </div>
  );
}

export default ForgotPassword;
