import { Damage, Booking } from "../../gobal";

export const mockDamages: Damage[] = [
  {
    id: "DMG001",
    propertyName: "Room 101",
    guestName: "John Doe",
    guestEmail: "john@example.com",
    status: "in_progress",
    lastUpdatedBy: "Admin User",
    createdBy: "Staff Member",
    createdAt: "2024-03-15T10:30:00Z",
    description: "Broken window",
    cost: 250
  },
  {
    id: "DMG002",
    propertyName: "Room 205",
    guestName: "Jane Smith",
    guestEmail: "jane@example.com",
    status: "closed",
    lastUpdatedBy: "Manager",
    createdBy: "Staff Member",
    createdAt: "2024-03-14T15:45:00Z",
    description: "Damaged furniture",
    cost: 500
  }
];

export const mockBookings: Booking[] = [
  {
    id: "BKG001",
    guestName: "Alice Johnson",
    guestEmail: "alice@example.com",
    roomNumber: "301",
    arrival: "2024-03-10",
    departure: "2024-03-17"
  },
  {
    id: "BKG002",
    guestName: "Bob Wilson",
    guestEmail: "bob@example.com",
    roomNumber: "402",
    arrival: "2024-03-12",
    departure: "2024-03-19"
  }
];