import api from './api';

export const getProductsByType = (type) =>
  api.get(`/api/products/type?type=${type}`);

export const updateInventory = (id, values) =>
  api.patch(`/api/minibars/${id}`, values);

export const getSectionProducts = async (section, limit, page, searchText) => {
  const params = new URLSearchParams();
  if (limit) params.append('limit', limit);
  if (page) params.append('page', page);
  if (searchText) params.append('searchText', searchText);
  
  return api.get(`/api/products/section/${section.toLowerCase()}?${params.toString()}`);
};

export const transferProduct = async (productId, amount, targetSection, adminUserId) => {
  return api.post('/api/products/transfer', {
    productId,
    amount,
    targetSection,
    adminUserId
  });
};

export const getInventoryProducts = async () => {
  try {
    console.log('Calling inventory API...');
    // Get token from localStorage
    const token = localStorage.getItem('access_token');
    
    if (!token) {
      throw new Error('No authentication token found');
    }

    // Set the authorization header for this specific request
    const response = await api.get('/api/products/avaliable-products', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    console.log('API Response:', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory products:', error);
    throw error;
  }
};
