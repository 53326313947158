import React from "react";

import Header from "../common/Header";
import Footer from "../common/Footer";

import RoomDetails from "../home/RoomDetails";

function RoomDetailsPage() {

    return (
        <>
            <Header />
            <div className="my-24">
                <RoomDetails />
            </div>
            <Footer />
        </>
    );

}

export default RoomDetailsPage;