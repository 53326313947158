import React, { useState } from 'react';
import { IconSettingsFilled, IconFiles, IconLock } from '@tabler/icons-react';
import GeneralSettings from './GeneralSettings';
import ChangePassword from './ChangePassword';
import PageTitle from '../../components/page-title';

function AccountSettings() {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      icon: <IconFiles className='text-hazel-green' />,
      label: 'General Settings',
      content: <GeneralSettings />
    },
    {
      icon: <IconLock className='text-hazel-green' />,
      label: 'Change Password',
      content: <ChangePassword />
    }
  ];

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <IconSettingsFilled className='text-hazel-green mr-2' size={22} />
        }
        title='Account Settings'
      />
      <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
        <div className='flex items-center w-full border-b border-gray-200'>
          {tabs.map((tab, index) => (
            <div key={index}>
              <div
                className={`flex items-center px-5 py-3.5 ${
                  index === activeTab
                    ? 'bg-hazel-green bg-opacity-30 rounded-md'
                    : ''
                } cursor-pointer`}
                onClick={() => handleTabClick(index)}
              >
                {tab.icon}
                <p className='text-bluish-grey ml-2'>{tab.label}</p>
              </div>
            </div>
          ))}
        </div>
        <div className='mt-3 px-7 py-5'>{tabs[activeTab].content}</div>
      </div>
    </div>
  );
}

export default AccountSettings;
