import api from './api';

// Create a new reservation
export const createReservation = (values) =>
  api.post(`/api/reservationsv2`, values);

// Get all reservations
export const getAllReservations = (page = 1, limit = 10) =>
  api.get(`/api/reservationsv2?page=${page}&limit=${limit}`);

// Get a reservation by ID
export const getReservationById = (id) =>
  api.get(`/api/reservationsv2/${id}`);

// Update a reservation
export const updateReservation = (id, values) =>
  api.patch(`/api/reservationsv2/${id}`, values);

// Fix the getReservationStatistics function
export const getReservationStatistics = async () => {
    return await api.get('/api/reservationsv2/statistics');
};

// Delete a reservation
export const deleteReservation = (id) =>
  api.delete(`/api/reservationsv2/${id}`);

// Optional: Check if a reservation exists for a room
export const checkReservationExists = async (roomId) => {
  try {
    const response = await api.get(`/api/reservationsv2/${roomId}/exists`);
    return response.data.exists; // Assume the response contains a boolean
  } catch (error) {
    console.error("Error checking reservation existence:", error);
    return false; // If there's an error, assume the reservation does not exist
  }
};

// Optional: Get room history
export const getRoomHistory = (id) => api.get(`/api/reservationsv2/rooms/${id}`);

// Add this new function
export const getReservationByRoomAndDate = (roomNumber, date) =>
  api.get(`/api/reservationsv2/room/${roomNumber}/date/${date}`);