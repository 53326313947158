import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Building2, X, Save, CreditCard } from 'lucide-react';
import { createBankAccount } from '../../api/company';
import { toast } from 'react-hot-toast';

interface AddBankAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  companyId: string;
  locationId?: string;
}

const AddBankAccountModal: React.FC<AddBankAccountModalProps> = ({ 
  isOpen, 
  onClose, 
  onSuccess, 
  companyId,
  locationId 
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    bankName: '',
    accountNumber: '',
    description: '',
    currency: 'EUR',
    isActive: true
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.bankName || !formData.accountNumber) {
      toast.error('Ju lutem plotësoni të gjitha fushat e detyrueshme');
      return;
    }

    if (!companyId) {
      toast.error('Gabim: Mungon ID e kompanisë');
      return;
    }

    try {
      setIsLoading(true);
      await createBankAccount({
        ...formData,
        companyId,
        locationId,
        createdBy: 'system', // TODO: Get from auth context
        updatedBy: 'system'  // TODO: Get from auth context
      });
      toast.success('Llogaria bankare u krijua me sukses');
      if (onSuccess) {
        onSuccess();
      }
      onClose();
    } catch (error: any) {
      console.error('Error creating bank account:', error);
      toast.error(error?.response?.data?.message || 'Gabim gjatë krijimit të llogarisë bankare');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const banks = [
    { id: 'raiffeisen', name: 'Raiffeisen Bank' },
    { id: 'procredit', name: 'ProCredit Bank' },
    { id: 'nlb', name: 'NLB Bank' },
    { id: 'teb', name: 'TEB Bank' },
    { id: 'bkt', name: 'BKT Bank' },
    { id: 'other', name: 'Tjetër' }
  ];

  const currencies = [
    { code: 'EUR', name: 'Euro (€)' },
    { code: 'USD', name: 'US Dollar ($)' },
    { code: 'ALL', name: 'Albanian Lek (L)' },
    { code: 'GBP', name: 'British Pound (£)' },
    { code: 'CHF', name: 'Swiss Franc (Fr)' }
  ];

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-bluish-grey/20 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="bg-white rounded-2xl shadow-2xl w-full max-w-2xl max-h-[90vh] flex flex-col overflow-hidden border border-grey-goose/30"
          >
            {/* Header */}
            <div className="px-6 py-4 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="p-2 bg-hazel-green/10 rounded-xl">
                    <CreditCard className="w-6 h-6 text-hazel-green" />
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold text-bluish-grey">Shto Llogari Bankare</h2>
                    <p className="text-sm text-grey-goose">Plotësoni të dhënat për llogarinë e re bankare</p>
                  </div>
                </div>
                <button
                  onClick={onClose}
                  className="p-2 hover:bg-grey-goose/10 rounded-xl transition-colors"
                  disabled={isLoading}
                >
                  <X className="w-5 h-5 text-grey-goose" />
                </button>
              </div>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="p-6 space-y-6">
              <div className="space-y-4">
                {/* Bank Name */}
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-1">
                    Banka <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="bankName"
                    value={formData.bankName}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 rounded-lg border border-grey-goose/20 focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green"
                  >
                    <option value="">Zgjidhni bankën</option>
                    {banks.map(bank => (
                      <option key={bank.id} value={bank.name}>
                        {bank.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Account Number */}
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-1">
                    Numri i Llogarisë <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 rounded-lg border border-grey-goose/20 focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green"
                    placeholder="Shkruani numrin e llogarisë"
                  />
                </div>

                {/* Currency */}
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-1">
                    Valuta <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="currency"
                    value={formData.currency}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 rounded-lg border border-grey-goose/20 focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green"
                  >
                    {currencies.map(currency => (
                      <option key={currency.code} value={currency.code}>
                        {currency.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Description */}
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-1">
                    Përshkrimi
                  </label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    rows={3}
                    className="w-full px-4 py-2 rounded-lg border border-grey-goose/20 focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green resize-none"
                    placeholder="Shkruani një përshkrim të shkurtër për llogarinë"
                  />
                </div>
              </div>

              {/* Actions */}
              <div className="flex justify-end space-x-3 pt-4 border-t border-grey-goose/20">
                <button
                  type="button"
                  onClick={onClose}
                  disabled={isLoading}
                  className="px-4 py-2 text-grey-goose hover:bg-grey-goose/10 rounded-lg transition-colors"
                >
                  Anulo
                </button>
                <motion.button
                  type="submit"
                  disabled={isLoading}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 transition-all duration-200 flex items-center gap-2 disabled:opacity-50"
                >
                  <Save className="w-4 h-4" />
                  {isLoading ? 'Duke ruajtur...' : 'Ruaj'}
                </motion.button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AddBankAccountModal;
