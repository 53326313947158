import React, { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IconX } from '@tabler/icons-react';
import { loginWithPasscode } from '../api/passcode';
import AuthContext from '../contexts/auth-context';
import { toast } from 'react-toastify';

const OrderPasscodeModal = ({ isOpen, onClose, onAuthenticated }) => {
  const [passcode, setPasscode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { loginWithPasscode: contextLoginWithPasscode } = useContext(AuthContext);

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const modalVariants = {
    hidden: { 
      opacity: 0,
      scale: 0.8,
      y: 20
    },
    visible: { 
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 300
      }
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: 20
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!passcode.trim()) {
      setError('Please enter the passcode');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      // Use loginWithPasscode with the correct URL segment that matches the backend mapping
      const response = await loginWithPasscode(passcode, 'depot');
      
      // Check for successful response with statusCode 200
      if (response.data?.statusCode === 200 && response.data?.data?.token) {
        // Store the authentication token from the nested data object
        localStorage.setItem('access_token', response.data.data.token);
        onAuthenticated(response);
        toast.success(`Welcome ${response.data.data.user.name}`);
        onClose(); // Close the modal after successful authentication
      } else {
        throw new Error('Authentication failed');
      }
    } catch (err) {
      console.error('Login error:', err);
      const errorMessage = err.response?.data?.message || 'Authentication failed. Please try again.';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 z-50 flex items-center justify-center"
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          {/* Overlay with blur effect */}
          <motion.div
            className="absolute inset-0 bg-black/30 backdrop-blur-sm"
            variants={overlayVariants}
            onClick={onClose}
          />

          {/* Modal */}
          <motion.div
            variants={modalVariants}
            className="relative w-full max-w-md p-6 bg-white rounded-2xl shadow-xl"
          >
            {/* Close button */}
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-bluish-grey hover:text-hazel-green transition-colors"
            >
              <IconX size={24} />
            </button>

            <div className="text-center mb-6">
              <h2 className="text-2xl font-semibold text-hazel-green mb-2">Depot Manager Access</h2>
              <p className="text-bluish-grey">Please enter your depot manager passcode</p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  type="password"
                  value={passcode}
                  onChange={(e) => {
                    setPasscode(e.target.value);
                    setError('');
                  }}
                  className="w-full px-4 py-3 border-2 border-grey-goose rounded-lg focus:border-hazel-green focus:ring-1 focus:ring-hazel-green outline-none transition-colors"
                  placeholder="Enter passcode"
                  disabled={isLoading}
                />
                {error && (
                  <p className="mt-2 text-sm text-error">{error}</p>
                )}
              </div>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className={`w-full py-3 px-4 ${isLoading ? 'bg-sage' : 'bg-hazel-green'} text-white rounded-lg font-medium hover:bg-sage transition-colors`}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? 'Verifying...' : 'Submit'}
              </motion.button>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OrderPasscodeModal;  