import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IconPlus, IconPencil, IconTrash } from '@tabler/icons-react';
import ConfirmMessage from '../../components/alerts/alert-message';
import Modal from '../../components/modal';
import Loading from '../../components/loader';
import PermissionCheck from '../../utils/PermissionCheck';

function RestaurantsList() {
  const [restaurantsTypes, setRestaurantsTypes] = useState([
    {
      id: '1',
      name: 'Restaurant 1',
      dishes: [
        { id: '11', name: 'Dish 1' },
        { id: '12', name: 'Dish 2' }
      ]
    }
  ]);
  const [activeRestaurant, setActiveRestaurant] = useState(restaurantsTypes[0]);
  const [hoveredDish, setHoveredDish] = useState(null);
  const [showRoomTypeDeleteAlert, setShowRoomTypeDeleteAlert] = useState(false);
  const [selectedDish, setSelectedDish] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  return (
    <div className='p-10'>
      <div className='flex justify-end gap-x-3 mb-8'>
        <PermissionCheck action='edit_rooms'>
          <Link
            to='/dishes/add-dish'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
          >
            <IconPlus className='mr-2' size={20} />
            New restaurant
          </Link>
        </PermissionCheck>
      </div>
      <div className='border border-gray-200 rounded-xl shadow-lg px-3 mt-5'>
        {restaurantsTypes.length > 0 ? (
          <>
            <div className='flex items-center justify-between w-full border-b border-gray-200 overflow-x-scroll no-scrollbar '>
              <div className='flex items-center w-48'>
                {restaurantsTypes.map((tab) => (
                  <div key={tab.id}>
                    <div
                      className={`flex items-center px-5 py-3.5 ${
                        tab.id === activeRestaurant
                          ? 'bg-seashell bg-opacity-30 rounded-md'
                          : ''
                      } cursor-pointer`}
                      onClick={() => setActiveRestaurant(tab)}
                    >
                      <p className='text-bluish-grey capitalize ml-2'>
                        {tab.name}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 my-4'>
              <PermissionCheck action='edit_room_types'>
                <Link
                  to={`/dishes/add-dish?${activeRestaurant.id}`}
                  className='flex justify-center items-center p-5 bg-seashell rounded-md'
                >
                  <IconPlus
                    className='text-bluish-grey font-bold cursor-pointer'
                    size={24}
                  />
                </Link>
              </PermissionCheck>
              {activeRestaurant.dishes?.map((item) => (
                <div
                  key={item.id}
                  className={`flex ${
                    hoveredDish === item.id
                      ? 'justify-center'
                      : 'justify-between'
                  } items-center rounded-md bg-seashell w-full`}
                  onMouseEnter={() => setHoveredDish(item.id)}
                  onMouseLeave={() => setHoveredDish(null)}
                >
                  {hoveredDish === item.id ? (
                    <div className='flex justify-center items-center bg-white py-2.5 px-5 gap-x-5 rounded'>
                      <PermissionCheck action='edit_rooms'>
                        <Link to={`/dishes/${item.id}`}>
                          <IconPencil className='text-bluish-grey' size={18} />
                        </Link>
                      </PermissionCheck>
                      <PermissionCheck action='delete_rooms'>
                        <IconTrash
                          className='text-bluish-grey cursor-pointer'
                          size={18}
                          onClick={() => {
                            setSelectedDish(item.id);
                            setShowAlert(true);
                          }}
                        />
                      </PermissionCheck>
                    </div>
                  ) : (
                    <p className='text-bluish-grey font-medium w-full text-center'>
                      {item.name}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <p className='text-sage font-bold py-10 px-8'>
            There is no data to show!
          </p>
        )}
      </div>
      {showAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this?'
          onCancel={() => setShowAlert(!showAlert)}
          //   onConfirm={() => deleteRoom()}
        />
      )}
      {showRoomTypeDeleteAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this room type?'
          text='Once you delete this type, all the related rooms will be deleted'
          onCancel={() => setShowRoomTypeDeleteAlert(!showRoomTypeDeleteAlert)}
          //   onConfirm={() => deleteRoomType()}
        />
      )}
    </div>
  );
}

export default RestaurantsList;
