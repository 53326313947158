// import React from 'react';

// const CustomMenuBar = ({ category }) => {
//   // Helper function to get menu options based on category
//   const getMenuOptions = () => {
//     switch (category) {
//       case 'accounting':
//         return ['File', 'Export', 'Raporti', 'POS'];
//       case 'finances':
//         return ['File', 'Export', 'Invoice', 'Reports'];
//       case 'statistics':
//         return ['Data', 'Charts', 'Reports', 'Export'];
//       default:
//         return [];
//     }
//   };

//   const menuOptions = getMenuOptions();

//   return (
//     <div className="bg-tealish-blue p-2 shadow-md">
//       <nav className="container mx-auto flex space-x-6 justify-center items-center">
//         {/* Category name as part of the menu */}
//         <span className="text-white font-semibold px-4 py-1 text-lg capitalize">
//           {category.charAt(0).toUpperCase() + category.slice(1)}
//         </span>
        
//         {/* Dynamic menu items */}
//         {menuOptions.map((menuItem, index) => (
//           <button
//             key={index}
//             className="text-bluish-grey hover:text-white font-semibold px-4 py-1 transition-colors duration-200"
//           >
//             {menuItem}
//           </button>
//         ))}
//       </nav>
//     </div>
//   );
// };

// export default CustomMenuBar;

import React from 'react';

const CustomMenuBar = ({ category }) => {
  // Fallback if category is undefined
  const formattedCategory = category
    ? category.charAt(0).toUpperCase() + category.slice(1)
    : 'Default Category'; // Provide a fallback

  // Helper function to get menu options based on category
  const getMenuOptions = () => {
    switch (category) {
      case 'accounting':
        return ['File', 'Export', 'Raporti', 'POS'];
      case 'finances':
        return ['File', 'Export', 'Invoice', 'Reports'];
      case 'statistics':
        return ['Data', 'Charts', 'Reports', 'Export'];
      default:
        return [];
    }
  };

  const menuOptions = getMenuOptions();

  return (
    <div className="bg-tealish-blue p-2 shadow-md">
      <nav className="container mx-auto flex space-x-6 justify-start items-center">
        {/* Category name as part of the menu */}
        <span className="text-hazel-green font-semibold px-4 py-1 text-lg capitalize">
          {formattedCategory}
        </span>
        
        {/* Dynamic menu items */}
        {menuOptions.map((menuItem, index) => (
          <button
            key={index}
            className="text-bluish-grey hover:text-hazel-green font-semibold px-4 py-1 transition-colors duration-200"
          >
            {menuItem}
          </button>
        ))}
      </nav>
    </div>
  );
};

export default CustomMenuBar;

