import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
// import DishSectionForm from './DishSectionForm';
import {
    IconBeerFilled
  } from '@tabler/icons-react';
import BarProductForm from './BarProductForm';

function AddBarProductSection() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
        //   <FontAwesomeIcon
        //     icon={faUtensils}
        //     className='text-hazel-green mr-2 w-12'
        //   />
            <IconBeerFilled size={23} className='text-hazel-green' />
        }
        title='Add new Bar Product'
      />
      <BarProductForm isEdit={false} />
    </div>
  );
}

export default AddBarProductSection;
