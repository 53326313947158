import { Link } from 'react-router-dom';
import { Lock, Settings, Plus, Search, ChevronDown } from 'lucide-react';
import { useState, useEffect } from 'react';
import CreateAccountForm from '../../../../../components/accounts/CreateAccountForm.tsx';
import { getAccounts } from '../../../../../api/account.js';

const accountTypeMap = {

    "1": "Cash",
    "2": "Accounts Receivable",
    "3": "Inventory",
    "4": "Fixed Asset",
    "5": "Liability",
    "6": "Equity",
    "7": "Revenue",
    "8": "Expense",
    
};


export default function AccountsList() {
    const [accounts, setAccounts] = useState([]); // State to hold fetched accounts
    const [isFormOpen, setIsFormOpen] = useState(false);

    // Fetch accounts from the API
    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const response = await getAccounts();
                setAccounts(response.data); // Assuming response.data contains the accounts array
            } catch (error) {
                console.error('Error fetching accounts:', error);
            }
        };

        fetchAccounts();
    }, []); // Empty dependency array means this runs once when the component mounts

    const capitalizeFirstLetter = (str) => {
        if (!str) return ''; // Handle empty string
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return (
        <div className="min-h-screen bg-seashell container mx-auto py-8 px-4">
            <div className="flex justify-between items-center mb-8">
                <div className="flex items-center gap-3">
                    <h1 className="text-2xl font-semibold text-hazel-green">Active Accounts</h1>
                    <button className="text-bluish-grey hover:text-hazel-green transition-colors">
                        <ChevronDown className="h-4 w-4" />
                    </button>
                </div>
                <div className="flex items-center gap-4">
                    <button className="flex items-center px-4 py-2 border border-grey-goose text-bluish-grey hover:bg-tealish-blue rounded-lg transition-colors">
                        <Search className="h-4 w-4 mr-2" />
                        Find Accountants
                    </button>
                    <button
                        onClick={() => setIsFormOpen(true)}
                        className="flex items-center px-4 py-2 bg-hazel-green hover:bg-sage text-white rounded-lg transition-colors"
                    >
                        <Plus className="h-4 w-4 mr-2" />
                        New Account
                    </button>
                </div>
            </div>

            <div className="bg-white rounded-xl shadow-sm border border-mercury">
                <div className="grid grid-cols-5 gap-4 p-5 border-b border-mercury bg-tealish-blue">
                    <div className="text-sm font-medium text-bluish-grey">ACCOUNT NAME</div>
                    <div className="text-sm font-medium text-bluish-grey">ACCOUNT CODE</div>
                    <div className="text-sm font-medium text-bluish-grey">ACCOUNT TYPE</div>
                    <div className="text-sm font-medium text-bluish-grey">DOCUMENTS</div>
                    <div className="text-sm font-medium text-bluish-grey">PARENT ACCOUNT NAME</div>
                </div>

                <div className="divide-y divide-mercury">
                    {accounts.map((account) => (
                        <div
                            key={account.id}
                            className="grid grid-cols-5 gap-4 p-5 hover:bg-seashell transition-colors duration-200"
                        >
                            <div className="flex items-center gap-3">
                                {account.locked && <Lock className="h-4 w-4 text-heather" />}
                                <Link
                                    to={`/accounting/account/${account.id}`} // Link to account details page
                                    className="text-hazel-green hover:text-sage transition-colors duration-200"
                                >
                                    {account.name}
                                </Link>
                            </div>
                            <div className="text-bluish-grey">{account.code}</div>
                            <div className="text-bluish-grey">
                                {account.AccountSubCategory.length > 0
                                    ? capitalizeFirstLetter(account.AccountSubCategory[0].name)
                                    : 'N/A'}
                            </div>
                            <div className="flex items-center gap-2">
                                <Settings className="h-4 w-4 text-heather" />
                            </div>
                            <div className="text-heather">-</div>
                        </div>
                    ))}
                </div>
            </div>

            <CreateAccountForm isOpen={isFormOpen} onClose={() => setIsFormOpen(false)} />
        </div>
    );
}
