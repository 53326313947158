import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, Settings, Upload } from 'lucide-react';

import { getCompany } from '../../../api/company';
import { getSuppliers } from '../../../api/accounting';
import { getNextReturnDocumentNumber, getPurchaseForReturn, createPurchaseReturn } from '../../../api/purchase';
import { toast } from 'react-toastify';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '../../../components/shadCN/Select.tsx';
import { Button } from '../../../components/shadCN/Button.tsx';
import SupplierModal from '../../../components/SupplierModal';

interface ReturnItem {
  id: string;
  quantity: number;
  priceWithoutVAT: number;
  vatRate: number;
  vatAmount: number;
  totalWithVAT: number;
  reason: string;
  purchaseItemId: string;
  articleId: string;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Supplier {
  id: string;
  name: string;
  fiscalNumber: string;
  address?: string;
  email?: string;
  phone?: string;
}

const ReturnPurchasePage = () => {
  const navigate = useNavigate();
  const [documentNumber, setDocumentNumber] = useState('');
  const [returnDate, setReturnDate] = useState(new Date().toISOString().split('T')[0]);
  const [originalPurchaseDocument, setOriginalPurchaseDocument] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [description, setDescription] = useState('');
  const [returnItems, setReturnItems] = useState<ReturnItem[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);
  const [isLoadingPurchase, setIsLoadingPurchase] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [purchase, setPurchase] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Get company data
        try {
          const companyRes = await getCompany();
          console.log('Company response:', companyRes);
          const companyLocations = companyRes?.data?.message?.locations || [];
          if (!companyLocations.length) {
            console.warn('No locations found');
          }
          setLocations(Array.isArray(companyLocations) ? companyLocations : []);
        } catch (error) {
          console.error('Error fetching company data:', error);
          toast.error('Failed to load locations');
        }
        
        // Get suppliers
        try {
          const suppliersRes = await getSuppliers();
          console.log('Raw suppliers response:', suppliersRes);
          const suppliersList = suppliersRes?.data || [];
          console.log('Processed suppliers list:', suppliersList);
          if (!suppliersList.length) {
            console.warn('No suppliers found');
          }
          setSuppliers(suppliersList);
        } catch (error) {
          console.error('Error fetching suppliers:', error);
          toast.error('Failed to load suppliers');
        }

        // Get document number
        try {
          const docNumberRes = await getNextReturnDocumentNumber();
          console.log('Document number response:', docNumberRes);
          
          // Check if we have a valid response
          if (docNumberRes?.data?.message?.documentNumber) {
            setDocumentNumber(docNumberRes.data.message.documentNumber);
          } else {
            // If we don't have a document number, generate a default one
            const currentYear = new Date().getFullYear();
            const defaultDocNumber = `RET-PUR-${currentYear}-000001`;
            console.log('Using default document number:', defaultDocNumber);
            setDocumentNumber(defaultDocNumber);
          }
        } catch (error) {
          console.error('Error fetching document number:', error);
          // If we fail to get a document number, generate a default one
          const currentYear = new Date().getFullYear();
          const defaultDocNumber = `RET-PUR-${currentYear}-000001`;
          console.log('Using default document number after error:', defaultDocNumber);
          setDocumentNumber(defaultDocNumber);
          toast.warning('Using default document number');
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handlePurchaseSearch = async () => {
    if (!originalPurchaseDocument) {
      toast.error('Please enter a purchase document number');
      return;
    }

    try {
      setIsLoadingPurchase(true);
      const response = await getPurchaseForReturn(originalPurchaseDocument);
      console.log('Purchase response:', response);
      
      // Handle the nested message structure
      const purchaseData = response?.data?.message;
      
      if (purchaseData) {
        console.log('Purchase data:', purchaseData);
        
        // Set the purchase data
        setPurchase(purchaseData);
        
        // Set supplier
        if (purchaseData.supplierId) {
          console.log('Setting supplier ID:', purchaseData.supplierId);
          setSelectedSupplier(purchaseData.supplierId);
        } else {
          console.warn('No supplier ID found in purchase data');
          toast.warning('No supplier information found');
        }
        
        // Set location
        if (purchaseData.locationId) {
          console.log('Setting location ID:', purchaseData.locationId);
          setSelectedLocation(purchaseData.locationId);
        } else {
          console.warn('No location ID in purchase data');
          toast.warning('No location information found');
        }
        
        // Set description
        setDescription(purchaseData.description || '');
        
        // Map items with proper calculations
        if (Array.isArray(purchaseData.items) && purchaseData.items.length > 0) {
          const initialReturnItems = purchaseData.items
            .filter(item => (item.remainingQuantity || 0) > 0) // Only include items with remaining quantity
            .map((item) => ({
              id: crypto.randomUUID(), // Generate new ID for return item
              quantity: 0, // Start with 0 quantity for return
              priceWithoutVAT: item.priceWithoutVAT || 0,
              vatRate: item.vatRate || 0,
              vatAmount: 0, // Will be calculated when quantity changes
              totalWithVAT: 0, // Will be calculated when quantity changes
              reason: '',
              purchaseItemId: item.id,
              articleId: item.articleId,
              remainingQuantity: item.remainingQuantity || 0,
              articleName: item.article?.name || 'Unknown Article',
              unit: item.unit || { name: 'N/A' }
            }));

          if (initialReturnItems.length > 0) {
            setReturnItems(initialReturnItems);
            toast.success('Purchase details loaded successfully');
          } else {
            toast.warning('No items available for return');
            setReturnItems([]);
          }
        } else {
          console.warn('No items found in purchase data');
          toast.warning('No items found in purchase');
          setReturnItems([]);
        }
      } else {
        console.error('Invalid response data:', response);
        toast.error('Invalid purchase data received');
        resetForm();
      }
    } catch (error: any) {
      console.error('Error fetching purchase:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch purchase details';
      toast.error(errorMessage);
      resetForm();
    } finally {
      setIsLoadingPurchase(false);
    }
  };

  const resetForm = () => {
    setPurchase(null);
    setReturnItems([]);
    setSelectedSupplier('');
    setSelectedLocation('');
    setDescription('');
  };

  const handleQuantityChange = (index: number, quantity: number) => {
    const item = returnItems[index];
    if (!item) return;

    // Validate quantity against remaining quantity
    if (quantity > item.remainingQuantity) {
      toast.error('Return quantity cannot exceed remaining quantity');
      return;
    }

    const updatedItems = [...returnItems];
    const itemTotalWithoutVAT = item.priceWithoutVAT * quantity;
    const itemVatAmount = (itemTotalWithoutVAT * item.vatRate) / 100;
    const itemTotalWithVAT = itemTotalWithoutVAT + itemVatAmount;

    updatedItems[index] = {
      ...item,
      quantity,
      vatAmount: Number(itemVatAmount.toFixed(2)),
      totalWithVAT: Number(itemTotalWithVAT.toFixed(2))
    };

    setReturnItems(updatedItems);
  };

  const calculateTotals = () => {
    return returnItems.reduce((acc, item) => {
      const itemTotalWithoutVAT = item.priceWithoutVAT * (item.quantity || 0);
      const itemVatAmount = (itemTotalWithoutVAT * item.vatRate) / 100;
      const itemTotalWithVAT = itemTotalWithoutVAT + itemVatAmount;

      return {
        totalWithoutVAT: acc.totalWithoutVAT + itemTotalWithoutVAT,
        totalVAT: acc.totalVAT + itemVatAmount,
        totalWithVAT: acc.totalWithVAT + itemTotalWithVAT
      };
    }, {
      totalWithoutVAT: 0,
      totalVAT: 0,
      totalWithVAT: 0
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!purchase || returnItems.length === 0) {
      toast.error('Please add items to return');
      return;
    }

    if (!selectedSupplier) {
      toast.error('Please select a supplier');
      return;
    }

    if (!selectedLocation) {
      toast.error('Please select a location');
      return;
    }

    setIsSubmitting(true);
    try {
      const data = {
        purchaseId: purchase.id,
        documentNumber,
        originalPurchaseDocumentNumber: originalPurchaseDocument,
        returnDate: new Date().toISOString(),
        description,
        items: returnItems
          .filter(item => item.quantity > 0)
          .map(item => ({
            purchaseItemId: item.purchaseItemId,
            quantity: item.quantity,
            reason: item.reason || ''
          }))
      };

      console.log('Submitting return data:', data);
      await createPurchaseReturn(data);
      toast.success('Purchase return created successfully');
      navigate('/accounting/purchases');
    } catch (error: any) {
      console.error('Error creating purchase return:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Failed to create purchase return';
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/accounting/purchases')}
                className="inline-flex items-center gap-x-2 text-bluish-grey hover:text-hazel-green transition-colors"
              >
                <ArrowLeft className="h-5 w-5" />
                Kthehu
              </button>
            </div>
            <h1 className="text-lg font-semibold text-hazel-green">Kthim Blerje</h1>
            <div className="flex items-center gap-x-4">
              <button 
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm disabled:opacity-50"
              >
                {isSubmitting ? 'Duke ruajtur...' : 'Ruaj'}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Document Details */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Detajet për Kthim nga Blerje Produkti</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i Dokumentit
                  </label>
                  <input
                    type="text"
                    value={documentNumber}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                  <p className="mt-1 text-sm text-grey-goose">
                    Emri i dokumentit është automatikisht i gjeneruar në bazë të formatit.
                  </p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Data e Kthimit
                  </label>
                  <input
                    type="date"
                    value={returnDate}
                    onChange={(e) => setReturnDate(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i Dokumentit të Blerjes
                  </label>
                  <input
                    type="text"
                    value={originalPurchaseDocument}
                    onChange={(e) => setOriginalPurchaseDocument(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                  />
                  <button
                    onClick={handlePurchaseSearch}
                    className="inline-flex items-center gap-x-2 px-4 py-2 text-sm text-bluish-grey hover:text-hazel-green transition-colors"
                  >
                    Kërko Blerje
                  </button>
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Përshkrimi
                  </label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                  />
                </div>
                {/* Supplier Select */}
                <div className="relative mb-4">
                  <label htmlFor="supplier" className="mb-2 block text-sm font-medium text-slate-600">
                    Furnitori
                  </label>
                  <div className="flex gap-2 items-start">
                    <div className="flex-1">
                      <Select
                        value={selectedSupplier}
                        onValueChange={(value) => {
                          console.log('Selected supplier:', value);
                          setSelectedSupplier(value);
                        }}
                        disabled={isLoading}
                      >
                        <SelectTrigger 
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                          disabled={isLoading}
                        >
                          <SelectValue 
                            placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni furnitorin"}
                            className="text-bluish-grey"
                          >
                            {selectedSupplier && (
                              <span className="text-hazel-green">
                                {suppliers.find(s => s.id === selectedSupplier)?.businessName || 
                                 suppliers.find(s => s.id === selectedSupplier)?.tradeName || 
                                 'Furnitori i Panjohur'}
                              </span>
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent 
                          className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                          sideOffset={5}
                          position="popper"
                          align="start"
                        >
                          <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                            <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                              Zgjidhni Furnitorin
                            </h3>
                          </div>
                          {suppliers.map(supplier => (
                            <SelectItem
                              key={supplier.id}
                              value={supplier.id}
                              textValue={supplier.businessName || supplier.tradeName}
                              className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                            >
                              <div className="flex flex-col gap-1">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm sm:text-base font-medium text-hazel-green">
                                    {supplier.businessName || supplier.tradeName}
                                  </span>
                                  {supplier.businessType && (
                                    <span className="text-xs text-bluish-grey">
                                      {supplier.businessType}
                                    </span>
                                  )}
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 text-xs sm:text-sm">
                                  {supplier.ubin && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <span>UBIN: {supplier.ubin}</span>
                                    </div>
                                  )}
                                  {supplier.municipality && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <span>Komuna: {supplier.municipality}</span>
                                    </div>
                                  )}
                                  {supplier.address && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <span>Adresa: {supplier.address}</span>
                                    </div>
                                  )}
                                  {supplier.email && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <span>Email: {supplier.email}</span>
                                    </div>
                                  )}
                                  {supplier.phone && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <span>Tel: {supplier.phone}</span>
                                    </div>
                                  )}
                                  {supplier.representative && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <span>Përfaqësuesi: {supplier.representative}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <Button
                      type="button"
                      variant="outline"
                      size="icon"
                      onClick={() => setIsSupplierModalOpen(true)}
                      className="flex-shrink-0 h-10 w-10 rounded-lg hover:bg-gray-100"
                    >
                      <Plus className="h-5 w-5" />
                    </Button>
                  </div>
                </div>
                {/* Location Select */}
                <div className="mb-4">
                  <label htmlFor="location" className="mb-2 block text-sm font-medium text-slate-600">
                    Lokacioni
                  </label>
                  <Select
                    value={selectedLocation}
                    onValueChange={(value) => {
                      console.log('Selected location:', value);
                      setSelectedLocation(value);
                    }}
                    disabled={isLoading}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                      disabled={isLoading}
                    >
                      <SelectValue 
                        placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni lokacionin"}
                        className="text-bluish-grey"
                      >
                        {selectedLocation && (
                          <span className="text-hazel-green">
                            {locations.find(l => l.id === selectedLocation)?.name || 'Unknown Location'}
                          </span>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                      sideOffset={5}
                      position="popper"
                      align="start"
                    >
                      <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                        <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                          Zgjidhni Lokacionin
                        </h3>
                      </div>
                      {locations.map(location => (
                        <SelectItem
                          key={location.id}
                          value={location.id}
                          textValue={location.name}
                          className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm sm:text-base font-medium text-hazel-green">
                              {location.name}
                            </span>
                            {location.address && (
                              <span className="text-xs sm:text-sm text-bluish-grey">
                                {location.address}
                              </span>
                            )}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>

          {/* Return Items Table */}
          {purchase && (
            <div className="mt-6">
              <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
                <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
                  <h2 className="text-lg font-semibold text-hazel-green">Produktet për Kthim</h2>
                </div>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-mercury">
                    <thead>
                      <tr className="bg-seashell/50">
                        <th className="px-4 py-3 text-left text-sm font-medium text-bluish-grey">Produkti</th>
                        <th className="px-4 py-3 text-left text-sm font-medium text-bluish-grey">Njësia</th>
                        <th className="px-4 py-3 text-right text-sm font-medium text-bluish-grey">Sasia në Dispozicion</th>
                        <th className="px-4 py-3 text-right text-sm font-medium text-bluish-grey">Sasia për Kthim</th>
                        <th className="px-4 py-3 text-right text-sm font-medium text-bluish-grey">Çmimi pa TVSH</th>
                        <th className="px-4 py-3 text-right text-sm font-medium text-bluish-grey">TVSH %</th>
                        <th className="px-4 py-3 text-right text-sm font-medium text-bluish-grey">Vlera e TVSH</th>
                        <th className="px-4 py-3 text-right text-sm font-medium text-bluish-grey">Totali me TVSH</th>
                        <th className="px-4 py-3 text-left text-sm font-medium text-bluish-grey">Arsyeja</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-mercury bg-white">
                      {returnItems.map((item, index) => (
                        <tr key={item.id} className="hover:bg-seashell/30 transition-colors">
                          <td className="px-4 py-3 text-sm text-slate-700">{item.articleName}</td>
                          <td className="px-4 py-3 text-sm text-slate-700">{item.unit?.name}</td>
                          <td className="px-4 py-3 text-sm text-right text-slate-700">{item.remainingQuantity}</td>
                          <td className="px-4 py-3 text-right">
                            <input
                              type="number"
                              min="0"
                              max={item.remainingQuantity}
                              value={item.quantity}
                              onChange={(e) => handleQuantityChange(index, Number(e.target.value))}
                              className="w-20 px-2 py-1 text-right border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                            />
                          </td>
                          <td className="px-4 py-3 text-sm text-right text-slate-700">{item.priceWithoutVAT.toFixed(2)}</td>
                          <td className="px-4 py-3 text-sm text-right text-slate-700">{item.vatRate}%</td>
                          <td className="px-4 py-3 text-sm text-right text-slate-700">{item.vatAmount.toFixed(2)}</td>
                          <td className="px-4 py-3 text-sm text-right text-slate-700">{item.totalWithVAT.toFixed(2)}</td>
                          <td className="px-4 py-3">
                            <input
                              type="text"
                              value={item.reason}
                              onChange={(e) => {
                                const updatedItems = [...returnItems];
                                updatedItems[index] = { ...item, reason: e.target.value };
                                setReturnItems(updatedItems);
                              }}
                              className="w-full px-2 py-1 text-sm border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                              placeholder="Arsyeja e kthimit"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {/* Totals */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Totali</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-4 gap-4">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Lloji
                  </label>
                  <input
                    type="text"
                    disabled
                    value="0.00"
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Vlera pa TVSH
                  </label>
                  <input
                    type="text"
                    disabled
                    value={calculateTotals().totalWithoutVAT.toFixed(2)}
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Shuma Totale e TVSH-së
                  </label>
                  <input
                    type="text"
                    disabled
                    value={calculateTotals().totalVAT.toFixed(2)}
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Vlera me TVSH
                  </label>
                  <input
                    type="text"
                    disabled
                    value={calculateTotals().totalWithVAT.toFixed(2)}
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Attachments */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Bashkëngjit dokument (attachment)</h2>
            </div>
            <div className="p-6">
              <div className="border-2 border-dashed border-mercury rounded-lg p-8">
                <div className="flex flex-col items-center justify-center gap-2">
                  <Upload className="h-8 w-8 text-grey-goose" />
                  <p className="text-sm text-bluish-grey">Mund vendosni dokument këtu (drag and drop)</p>
                  <p className="text-xs text-grey-goose">
                    Shto një dokument (attachment), psh. faturën orgjinaie te kthimit, faturën e pranuar nga furnitori.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SupplierModal
          isOpen={isSupplierModalOpen}
          onClose={() => setIsSupplierModalOpen(false)}
          onSuccess={async (newSupplier: any) => {
            setIsSupplierModalOpen(false);
            try {
              const suppliersRes = await getSuppliers();
              setSuppliers(Array.isArray(suppliersRes.data) ? suppliersRes.data : []);
              setSelectedSupplier(newSupplier.id);
              toast.success('Furnitori u shtua me sukses');
            } catch (error) {
              console.error('Error refreshing suppliers:', error);
              toast.error('Failed to refresh suppliers list');
            }
          }}
        />
      </div>
    </div>
  );
};

export default ReturnPurchasePage;
