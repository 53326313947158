import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IconMoneybag } from '@tabler/icons-react'; // Assuming you have a money bag icon
import PayrollForm from './PayrollForm';
import PageTitle from '../../../../../components/page-title';
import Loading from '../../../../../components/loader';
import { getPayrollById } from '../../../../../api/finances'; // API call to fetch payroll by ID

function EditPayroll() {
  const params = useParams();
  const payrollId = params.id; // Payroll ID from URL
  const [payroll, setPayroll] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPayrollData();
  }, []);

  const getPayrollData = async () => {
    try {
      const response = await getPayrollById(payrollId);
      setPayroll(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching payroll:', err);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconMoneybag className='text-hazel-green mr-2' size={22} />}
        title='Edit Payroll'
      />
      <PayrollForm isEdit={true} payroll={payroll} /> {/* Pass payroll data */}
    </div>
  );
}

export default EditPayroll;
