import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowLeft, Plus, Settings, Upload, X, Save, Package } from 'lucide-react';
import { motion } from 'framer-motion';
import { getCompany } from '../../../api/company';
import { getClients } from '../../../api/accounting';
import { getArticles } from '../../../api/accountingArticle';
import { getSaleById, updateSale } from '../../../api/sale';
import { toast } from 'react-toastify';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue, SelectGroup, SelectLabel } from '../../../components/shadCN/Select.tsx';
import { Label } from '../../../components/shadCN/Label.tsx';
import { Button } from '../../../components/shadCN/Button.tsx';
import ClientModal from '../../../components/ClientModal';
import { Input } from '../../../components/shadCN/Input.tsx';
import { Textarea } from '../../../components/shadCN/TextArea.tsx';
import Loading from '../../../components/loader';

interface Article {
  id: string;
  articleId?: string;
  name: string;
  unit: string;
  quantity: number;
  priceWithoutVAT: number;
  vatAmount: number;
  totalWithVAT: number;
  vatRate?: number;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Client {
  id: string;
  name: string;
  fiscalNumber: string;
  address?: string;
  email?: string;
  phone?: string;
}

interface AccountingArticle {
  id: string;
  code: string;
  name: string;
  description?: string;
  purchasePrice?: number;
  sellingPrice?: number;
  unit: {
    id: string;
    name: string;
  };
  vatRate: {
    id: string;
    rate: number;
    percentage: number;
  };
}

const EditSalePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useSelector((state: any) => state.user);
  
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [documentNumber, setDocumentNumber] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [selectedBuyer, setSelectedBuyer] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [description, setDescription] = useState('');
  const [articles, setArticles] = useState<Article[]>([]);
  const [accountingArticles, setAccountingArticles] = useState<AccountingArticle[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState('');
  const [sale, setSale] = useState<any>(null);
  const [saleStatus, setSaleStatus] = useState('DRAFT');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchingInitialData(true);
        setErrorMessage('');
        
        // Fetch all required data first
        const [companyResponse, clientsResponse, articlesResponse] = await Promise.all([
          getCompany(),
          getClients(),
          getArticles()
        ]);

        console.log('Full clients response:', clientsResponse);
        console.log('Clients data structure:', clientsResponse.data);
        
        // Set clients from the response
        if (clientsResponse?.data) {
          setClients(clientsResponse.data);
        }
        
        // Set locations from company data with proper structure handling
        if (companyResponse?.data?.message?.locations) {
          setLocations(companyResponse.data.message.locations);
        } else {
          console.warn('Unexpected locations structure:', companyResponse?.data);
          setLocations([]);
        }
        
        // Set articles with proper type checking
        console.log('Articles response:', articlesResponse);
        if (articlesResponse?.data?.message) {
          setAccountingArticles(articlesResponse.data.message);
        } else if (Array.isArray(articlesResponse?.data)) {
          setAccountingArticles(articlesResponse.data);
        } else {
          console.warn('Unexpected articles response structure:', articlesResponse);
          setAccountingArticles([]);
        }
        
        // Fetch sale data and set initial values
        const saleResponse = await getSaleById(id);
        console.log('Full sale data:', saleResponse);
        
        const sale = saleResponse.data.message;
        if (!sale) {
          throw new Error('Sale not found');
        }
        
        setSale(sale);
        
        // Set all the form fields from sale data
        setDocumentNumber(sale.documentNumber || '');
        setIssueDate(sale.issueDate ? new Date(sale.issueDate).toISOString().split('T')[0] : '');
        setDueDate(sale.dueDate ? new Date(sale.dueDate).toISOString().split('T')[0] : '');
        setSelectedBuyer(sale.clientId || '');
        setSelectedLocation(sale.locationId || '');
        setDescription(sale.description || '');
        setSaleStatus(sale.status || 'DRAFT');
        
        // Map the articles with proper error handling
        if (sale.items) {
          const mappedArticles = await Promise.all(sale.items.map(async (item: any) => {
            try {
              // Find the full article data from accountingArticles
              const articleData = articlesResponse.data.message.find(
                (a: any) => a.id === item.article.id
              );

              return {
                id: item.id || Math.random().toString(),
                articleId: item.article.id || '',
                name: articleData?.name || item.article.name || 'Unknown Article',
                unit: articleData?.unit?.name || item.unit || 'N/A',
                quantity: Number(item.quantity) || 0,
                priceWithoutVAT: Number(item.priceWithoutVAT) || 0,
                vatAmount: Number(item.vatAmount) || 0,
                totalWithVAT: Number(item.totalWithVAT) || 0,
                vatRate: Number(item.vatRate) || 0
              };
            } catch (error) {
              console.error('Error mapping article:', item, error);
              return {
                id: Math.random().toString(),
                articleId: '',
                name: 'Error Loading Article',
                unit: 'N/A',
                quantity: 0,
                priceWithoutVAT: 0,
                vatAmount: 0,
                totalWithVAT: 0,
                vatRate: 0
              };
            }
          }));
          console.log('Mapped articles:', mappedArticles);
          setArticles(mappedArticles);
        }
        
      } catch (error: any) {
        console.error('Error fetching data:', error);
        setErrorMessage(error.message || 'An error occurred while fetching data');
        toast.error(error.message || 'An error occurred while fetching data');
      } finally {
        setFetchingInitialData(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (sale?.clientId && clients.length > 0) {
      console.log('Setting selected buyer:', sale.clientId);
      console.log('Available clients:', clients);
      setSelectedBuyer(sale.clientId);
    }
  }, [sale?.clientId, clients]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      setSubmitting(true);
      setErrorMessage('');

      if (!selectedBuyer) {
        throw new Error('Please select a buyer');
      }

      const formData = {
        description,
        issueDate: new Date(issueDate).toISOString(),
        dueDate: new Date(dueDate).toISOString(),
        status: saleStatus,
        clientId: selectedBuyer,
        locationId: selectedLocation || undefined,
        items: articles.map(item => ({
          id: item.id,
          articleId: item.articleId || item.id,
          quantity: item.quantity,
          priceWithoutVAT: item.priceWithoutVAT,
          vatRate: item.vatRate || 0
        })),
        updatedBy: user.id,
        adminUserId: user.id
      };

      console.log('Submitting form data:', formData);
      
      const response = await updateSale(id!, formData);
      console.log('Update response:', response);
      
      toast.success('Sale updated successfully');
      navigate('/accounting/sales/v2');
      
    } catch (error: any) {
      console.error('Error updating sale:', error);
      const errorMsg = error?.response?.data?.message || error.message || 'Failed to update sale';
      setErrorMessage(errorMsg);
      toast.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  const handleArticleSelect = (articleId: string, selectedArticleId: string) => {
    const selectedArticle = accountingArticles.find(a => a.id === selectedArticleId);
    if (selectedArticle) {
      const newArticles = [...articles];
      const articleToUpdate = newArticles.find(a => a.id === articleId);
      if (articleToUpdate) {
        articleToUpdate.articleId = selectedArticle.id;
        articleToUpdate.name = selectedArticle.name;
        articleToUpdate.unit = selectedArticle.unit.name;
        articleToUpdate.priceWithoutVAT = selectedArticle.sellingPrice || 0;
        articleToUpdate.vatRate = selectedArticle.vatRate?.percentage || 0;
        articleToUpdate.vatAmount = (selectedArticle.sellingPrice || 0) * (selectedArticle.vatRate?.percentage || 0) / 100;
        articleToUpdate.totalWithVAT = (selectedArticle.sellingPrice || 0) * (1 + (selectedArticle.vatRate?.percentage || 0) / 100);
        
        console.log('Updated article:', articleToUpdate);
        setArticles(newArticles);
      }
    }
  };

  // Show loading spinner while fetching initial data
  if (fetchingInitialData) {
    return <Loading />;
  }

  // Show error message if there's an error
  if (!fetchingInitialData && errorMessage) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h1 className="text-2xl font-bold text-red-600 mb-4">Error</h1>
        <p className="text-gray-600 mb-4">{errorMessage}</p>
        <Button onClick={() => navigate('/accounting/sales')}>
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Sales
        </Button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-ghost-white">
      {/* Header */}
      <div className="bg-white border-b border-mercury/50">
        <div className="px-8 py-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate(-1)}
                className="p-2 text-bluish-grey hover:bg-seashell rounded-xl transition-colors"
              >
                <ArrowLeft className="w-5 h-5" />
              </motion.button>
              <div>
                <h1 className="text-2xl font-semibold text-bluish-grey">Ndrysho Shitjen</h1>
                <p className="text-sm text-grey-goose">Ndrysho të dhënat e shitjes #{documentNumber}</p>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <Button
                onClick={handleSubmit}
                disabled={submitting}
                className="bg-hazel-green hover:bg-opacity-90 text-white px-6 py-2.5 rounded-xl flex items-center gap-2 disabled:opacity-50"
              >
                {submitting ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
                    Duke ruajtur...
                  </>
                ) : (
                  <>
                    <Save className="w-5 h-5" />
                    Ruaj Ndryshimet
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
          {/* Document Details */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Detajet për Shitje Produkti</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <Label className="text-sm font-medium text-bluish-grey mb-2">
                    Numri i Dokumentit
                  </Label>
                  <Input
                    type="text"
                    value={documentNumber}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                  />
                  <p className="mt-1 text-sm text-grey-goose">
                    Emri i dokumentit është automatikisht i gjeneruar në bazë të formatit.
                  </p>
                </div>

                <div>
                  <Label className="text-sm font-medium text-bluish-grey mb-2">
                    Data e lëshimit
                  </Label>
                  <Input
                    type="date"
                    value={issueDate}
                    onChange={(e) => setIssueDate(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                </div>

                <div>
                  <Label className="text-sm font-medium text-bluish-grey mb-2">
                    Data e skadimit
                  </Label>
                  <Input
                    type="date"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Statusi
                  </label>
                  <Select
                    value={saleStatus}
                    onValueChange={setSaleStatus}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                    >
                      <SelectValue className="text-bluish-grey">
                        {saleStatus === 'DRAFT' ? (
                          <div className="flex items-center gap-2">
                            <span className="w-2 h-2 rounded-full bg-yellow-400"></span>
                            <span>Draft</span>
                          </div>
                        ) : saleStatus === 'COMPLETED' ? (
                          <div className="flex items-center gap-2">
                            <span className="w-2 h-2 rounded-full bg-green-500"></span>
                            <span>Completed</span>
                          </div>
                        ) : saleStatus === 'RETURNED' ? (
                          <div className="flex items-center gap-2">
                            <span className="w-2 h-2 rounded-full bg-blue-500"></span>
                            <span>Returned</span>
                          </div>
                        ) : (
                          <div className="flex items-center gap-2">
                            <span className="w-2 h-2 rounded-full bg-red-500"></span>
                            <span>Cancelled</span>
                          </div>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden"
                      sideOffset={5}
                    >
                      <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                        <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                          Zgjidhni Statusin
                        </h3>
                      </div>
                      <SelectItem
                        value="DRAFT"
                        className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50"
                      >
                        <div className="flex items-center gap-2">
                          <span className="w-2 h-2 rounded-full bg-yellow-400"></span>
                          <span className="text-sm font-medium">Draft</span>
                        </div>
                      </SelectItem>
                      <SelectItem
                        value="COMPLETED"
                        className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50"
                      >
                        <div className="flex items-center gap-2">
                          <span className="w-2 h-2 rounded-full bg-green-500"></span>
                          <span className="text-sm font-medium">Completed</span>
                        </div>
                      </SelectItem>
                      <SelectItem
                        value="RETURNED"
                        className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50"
                      >
                        <div className="flex items-center gap-2">
                          <span className="w-2 h-2 rounded-full bg-blue-500"></span>
                          <span className="text-sm font-medium">Returned</span>
                        </div>
                      </SelectItem>
                      <SelectItem
                        value="CANCELLED"
                        className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer"
                      >
                        <div className="flex items-center gap-2">
                          <span className="w-2 h-2 rounded-full bg-red-500"></span>
                          <span className="text-sm font-medium">Cancelled</span>
                        </div>
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="relative mb-4">
                  <label htmlFor="buyer" className="mb-2 block text-sm font-medium text-slate-600">
                    Blerësi
                  </label>
                  <div className="flex gap-2 items-start">
                    <div className="flex-1">
                      <Select
                        value={selectedBuyer}
                        onValueChange={(value) => setSelectedBuyer(value)}
                      >
                        <SelectTrigger 
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                        >
                          <SelectValue 
                            placeholder={fetchingInitialData ? "Duke ngarkuar..." : "Zgjidhni blerësin"}
                            className="text-bluish-grey"
                          >
                            {selectedBuyer && (
                              <span className="text-hazel-green">
                                {clients.find(c => c.id === selectedBuyer)?.name}
                              </span>
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent 
                          className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden"
                          sideOffset={5}
                        >
                          <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                            <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                              Zgjidhni Blerësin
                            </h3>
                          </div>
                          {clients.length === 0 ? (
                            <div className="py-3 px-4 text-sm text-gray-500">
                              Nuk ka blerës të regjistruar
                            </div>
                          ) : (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.2 }}
                            >
                              {clients.map(client => (
                                <SelectItem
                                  key={client.id}
                                  value={client.id}
                                  textValue={client.name}
                                  className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                                >
                                  <div className="flex flex-col gap-1">
                                    <div className="flex flex-col">
                                      <span className="text-sm sm:text-base font-medium text-hazel-green">
                                        {client.name}
                                      </span>
                                      {client.fiscalNumber && (
                                        <span className="text-xs sm:text-sm text-bluish-grey">
                                          Nr. Fiskal: {client.fiscalNumber}
                                        </span>
                                      )}
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 text-xs sm:text-sm">
                                      {client.address && (
                                        <div className="flex items-center gap-1 text-bluish-grey">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                          </svg>
                                          <span>{client.address}</span>
                                        </div>
                                      )}
                                      {client.phone && (
                                        <div className="flex items-center gap-1 text-bluish-grey">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                          </svg>
                                          <span>{client.phone}</span>
                                        </div>
                                      )}
                                      {client.email && (
                                        <div className="flex items-center gap-1 text-bluish-grey">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                          </svg>
                                          <span>{client.email}</span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </SelectItem>
                              ))}
                            </motion.div>
                          )}
                        </SelectContent>
                      </Select>
                    </div>
                    <Button
                      type="button"
                      onClick={() => setIsClientModalOpen(true)}
                      variant="ghost"
                      className="px-3 py-2 text-hazel-green hover:bg-sage/10 rounded-lg"
                    >
                      <Settings className="w-4 h-4" />
                    </Button>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Lokacioni
                  </label>
                  <Select
                    value={selectedLocation}
                    onValueChange={setSelectedLocation}
                    disabled={submitting}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                      disabled={submitting}
                    >
                      <SelectValue 
                        placeholder="Zgjidhni lokacionin"
                        className="text-bluish-grey"
                      >
                        {selectedLocation && (
                          <span className="text-hazel-green">
                            {locations.find(l => l.id === selectedLocation)?.name}
                          </span>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden"
                      sideOffset={5}
                    >
                      <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                        <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                          Zgjidhni Lokacionin
                        </h3>
                      </div>
                      {locations.map(location => (
                        <SelectItem
                          key={location.id}
                          value={location.id}
                          textValue={location.name}
                          className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm sm:text-base font-medium text-hazel-green">
                              {location.name}
                            </span>
                            {location.address && (
                              <span className="text-xs sm:text-sm text-bluish-grey">
                                {location.address}
                              </span>
                            )}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="sm:col-span-2">
                  <Label className="text-sm font-medium text-bluish-grey mb-2">
                    Përshkrimi
                  </Label>
                  <Textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green min-h-[100px]"
                    placeholder="Shkruani përshkrimin e shitjes këtu..."
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Articles */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4 flex justify-between items-center">
              <h2 className="text-lg font-semibold text-hazel-green">Artikujt</h2>
              <button
                type="button"
                onClick={() => {
                  const newArticle = {
                    id: Math.random().toString(),
                    articleId: '',
                    name: '',
                    unit: '',
                    quantity: 1,
                    priceWithoutVAT: 0,
                    vatAmount: 0,
                    totalWithVAT: 0,
                    vatRate: 0
                  };
                  setArticles([...articles, newArticle]);
                }}
                className="inline-flex items-center gap-x-2 px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                         hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
              >
                <Plus className="h-5 w-5" />
                Shto Artikull
              </button>
            </div>
            <div className="p-6">
              <div className="space-y-4">
                {articles.length === 0 ? (
                  <div className="flex flex-col items-center justify-center py-8">
                    <div className="w-16 h-16 bg-gradient-to-br from-sage/20 to-hazel-green/10 rounded-lg flex items-center justify-center mb-4">
                      <Plus className="h-8 w-8 text-hazel-green" />
                    </div>
                    <h3 className="text-base font-medium text-bluish-grey mb-1">Nuk ka artikuj</h3>
                    <p className="text-sm text-grey-goose mb-4">Shtoni artikuj për të vazhduar me shitjen</p>
                    <button
                      type="button"
                      onClick={() => {
                        const newArticle = {
                          id: Math.random().toString(),
                          articleId: '',
                          name: '',
                          unit: '',
                          quantity: 1,
                          priceWithoutVAT: 0,
                          vatAmount: 0,
                          totalWithVAT: 0,
                          vatRate: 0
                        };
                        setArticles([...articles, newArticle]);
                      }}
                      className="inline-flex items-center gap-x-2 px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                               hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
                    >
                      <Plus className="h-5 w-5" />
                      Shto Artikull
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="grid grid-cols-12 gap-4 items-center mb-2 text-sm font-medium text-bluish-grey">
                      <div className="col-span-3">Emri i artikullit</div>
                      <div className="col-span-2">Njësia</div>
                      <div className="col-span-1">Sasia</div>
                      <div className="col-span-2">Çmimi pa TVSH</div>
                      <div className="col-span-2">TVSH</div>
                      <div className="col-span-1">Totali</div>
                      <div className="col-span-1"></div>
                    </div>
                    {articles.map((article, index) => (
                      <div key={article.id} className="grid grid-cols-12 gap-4 items-center">
                        <div className="col-span-3">
                          <Select
                            value={article.articleId || ''}
                            onValueChange={(value) => handleArticleSelect(article.id, value)}
                          >
                            <SelectTrigger 
                              className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                            >
                              <SelectValue 
                                placeholder="Zgjidhni artikullin"
                                className="text-bluish-grey"
                              >
                                {article.name || "Zgjidhni artikullin"}
                              </SelectValue>
                            </SelectTrigger>
                            <SelectContent 
                              className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden max-h-[300px]"
                              position="popper"
                              sideOffset={5}
                            >
                              <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                                <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                                  Zgjidhni Artikullin
                                </h3>
                              </div>
                              {(accountingArticles || []).map(item => (
                                <SelectItem
                                  key={item.id}
                                  value={item.id}
                                  textValue={item.name}
                                  className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                                >
                                  <div className="flex flex-col gap-1">
                                    <div className="flex flex-col">
                                      <span className="text-sm sm:text-base font-medium text-hazel-green">
                                        {item.name}
                                      </span>
                                      <span className="text-xs sm:text-sm text-bluish-grey">
                                        Kodi: {item.code}
                                      </span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-1 text-xs sm:text-sm">
                                      <div className="flex items-center gap-1 text-bluish-grey">
                                        <span>Çmimi: {item.sellingPrice?.toFixed(2)} €</span>
                                      </div>
                                      <div className="flex items-center gap-1 text-bluish-grey">
                                        <span>TVSH: {item.vatRate?.percentage || 0}%</span>
                                      </div>
                                      {item.description && (
                                        <div className="col-span-2 text-xs text-grey-goose">
                                          {item.description}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="col-span-2">
                          <input
                            type="text"
                            value={article.unit}
                            onChange={(e) => {
                              const newArticles = [...articles];
                              newArticles.find(a => a.id === article.id).unit = e.target.value;
                              setArticles(newArticles);
                            }}
                            placeholder="Njësia"
                            className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                          />
                        </div>
                        <div className="col-span-1">
                          <input
                            type="number"
                            value={article.quantity}
                            onChange={(e) => {
                              const newArticles = [...articles];
                              newArticles.find(a => a.id === article.id).quantity = parseFloat(e.target.value);
                              setArticles(newArticles);
                            }}
                            placeholder="Sasia"
                            className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                          />
                        </div>
                        <div className="col-span-2">
                          <input
                            type="number"
                            value={article.priceWithoutVAT}
                            onChange={(e) => {
                              const newArticles = [...articles];
                              newArticles.find(a => a.id === article.id).priceWithoutVAT = parseFloat(e.target.value);
                              newArticles.find(a => a.id === article.id).vatAmount = (parseFloat(e.target.value)) * (article.vatRate || 0) / 100;
                              newArticles.find(a => a.id === article.id).totalWithVAT = (parseFloat(e.target.value)) * (1 + (article.vatRate || 0) / 100);
                              setArticles(newArticles);
                            }}
                            placeholder="Çmimi pa TVSH"
                            className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                          />
                        </div>
                        <div className="col-span-2">
                          <input
                            type="number"
                            value={article.vatAmount}
                            readOnly
                            placeholder="TVSH"
                            className="block w-full rounded-lg border border-mercury bg-slate-50 px-4 py-2.5 text-bluish-grey shadow-sm"
                          />
                        </div>
                        <div className="col-span-1">
                          <input
                            type="number"
                            value={article.totalWithVAT}
                            readOnly
                            placeholder="Totali"
                            className="block w-full rounded-lg border border-mercury bg-slate-50 px-4 py-2.5 text-bluish-grey shadow-sm"
                          />
                        </div>
                        <div className="col-span-1 flex justify-end">
                          <button
                            type="button"
                            onClick={() => {
                              const newArticles = [...articles];
                              newArticles.splice(newArticles.findIndex(a => a.id === article.id), 1);
                              setArticles(newArticles);
                            }}
                            className="p-2 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                          >
                            <X className="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>

              {/* Totals */}
              <div className="mt-6 border-t border-mercury/50 pt-6">
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-bluish-grey">Totali pa TVSH:</span>
                    <span className="font-medium text-bluish-grey">{articles.reduce((acc, article) => acc + article.priceWithoutVAT * article.quantity, 0).toFixed(2)} €</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-bluish-grey">TVSH:</span>
                    <span className="font-medium text-bluish-grey">{articles.reduce((acc, article) => acc + article.vatAmount * article.quantity, 0).toFixed(2)} €</span>
                  </div>
                  <div className="flex justify-between text-base pt-2 border-t border-mercury/50">
                    <span className="font-medium text-hazel-green">Totali me TVSH:</span>
                    <span className="font-semibold text-hazel-green">{articles.reduce((acc, article) => acc + article.totalWithVAT * article.quantity, 0).toFixed(2)} €</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* Client Modal */}
      <ClientModal
        isOpen={isClientModalOpen}
        onClose={() => setIsClientModalOpen(false)}
        onSuccess={(newClient) => {
          setClients([...clients, newClient]);
          setSelectedBuyer(newClient.id);
          setIsClientModalOpen(false);
        }}
      />
    </div>
  );
};

export default EditSalePage;
