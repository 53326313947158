import React from 'react';
import { motion } from 'framer-motion';
import { MapPin, Trash2, Building2 } from 'lucide-react';
import { deleteLocation } from '../../api/company';
import { toast } from 'react-hot-toast';

interface Location {
  id: string;
  name: string;
  address?: string;
  isActive: boolean;
  parentId?: string;
  parent?: Location;
  children?: Location[];
}

interface LocationsTableProps {
  data: Location[];
  onRefresh: () => void;
}

export default function LocationsTable({ data, onRefresh }: LocationsTableProps) {
  const handleDelete = async (id: string) => {
    try {
      await deleteLocation(id);
      toast.success('Lokacioni u fshi me sukses');
      onRefresh();
    } catch (error) {
      console.error('Error deleting location:', error);
      toast.error('Dështoi fshirja e lokacionit');
    }
  };

  const getLocationHierarchy = (location: Location): string => {
    if (!location.parent) return location.name;
    return `${getLocationHierarchy(location.parent)} > ${location.name}`;
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {data.map((location) => (
        <motion.div
          key={location.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="bg-white rounded-xl shadow-sm border border-grey-goose/20 overflow-hidden hover:shadow-md transition-all duration-200"
        >
          <div className="p-6 space-y-4">
            {/* Header */}
            <div className="flex items-start justify-between">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-lg bg-hazel-green/10 flex items-center justify-center">
                  <Building2 className="w-5 h-5 text-hazel-green" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-bluish-grey">{location.name}</h3>
                  {location.parent && (
                    <p className="text-sm text-grey-goose">
                      {getLocationHierarchy(location)}
                    </p>
                  )}
                </div>
              </div>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => handleDelete(location.id)}
                className="p-2 hover:bg-red-50 rounded-lg transition-colors"
              >
                <Trash2 className="w-4 h-4 text-red-500" />
              </motion.button>
            </div>

            {/* Address */}
            {location.address && (
              <div className="flex items-start gap-3 text-grey-goose">
                <MapPin className="w-5 h-5 shrink-0 mt-0.5" />
                <p className="text-sm">{location.address}</p>
              </div>
            )}

            {/* Status */}
            <div className="pt-2">
              <span
                className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${
                  location.isActive
                    ? 'bg-green-100 text-green-700'
                    : 'bg-grey-goose/20 text-grey-goose'
                }`}
              >
                {location.isActive ? 'Aktiv' : 'Joaktiv'}
              </span>
            </div>
          </div>
        </motion.div>
      ))}

      {data.length === 0 && (
        <div className="col-span-full text-center py-8 text-grey-goose bg-white rounded-xl border border-grey-goose/20">
          Nuk ka lokacione
        </div>
      )}
    </div>
  );
}
