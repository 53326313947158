import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { IconChevronDown } from '@tabler/icons-react';

const SidebarDropdown = ({ label, icon, items, isActive, isOpen, onClick }) => {
  const dropdownVariants = {
    open: {
      height: 'auto',
      opacity: 1,
      transition: {
        duration: 0.2,
        ease: 'easeOut'
      }
    },
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: 'easeIn'
      }
    }
  };

  return (
    <div className="mb-1">
      <button
        onClick={onClick}
        className={`w-full flex items-center justify-between px-4 py-2.5 text-sm rounded-lg transition-colors ${
          isActive
            ? 'bg-mercury text-hazel-green font-medium'
            : 'text-bluish-grey hover:bg-seashell'
        }`}
      >
        <div className="flex items-center gap-3">
          <span className={isActive ? 'text-hazel-green' : 'text-grey-goose'}>
            {icon}
          </span>
          <span>{label}</span>
        </div>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.2 }}
          className={isActive ? 'text-hazel-green' : 'text-grey-goose'}
        >
          <IconChevronDown size={16} />
        </motion.div>
      </button>
      
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={dropdownVariants}
            className="overflow-hidden"
          >
            <div className="pl-4 py-1">
              {items.map((item, index) => (
                <Link
                  key={index}
                  to={item.href}
                  className={`block px-4 py-2.5 text-sm rounded-lg transition-colors ${
                    item.isActive
                      ? 'bg-mercury text-hazel-green font-medium'
                      : 'text-bluish-grey hover:bg-seashell'
                  }`}
                >
                  <div className="flex items-center gap-3">
                    <span className={item.isActive ? 'text-hazel-green' : 'text-grey-goose'}>
                      {item.icon}
                    </span>
                    <span>{item.label}</span>
                  </div>
                </Link>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SidebarDropdown;
