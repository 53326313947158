import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, ChevronDown, Download, RefreshCw, Settings, Filter, Minus, Plus } from 'lucide-react';
import { generateBalanceSheet, listBalanceSheets, updateBalanceSheetStatus } from '../../../api/balanceSheet';
import { useLocation } from '../../../hooks/useLocation.ts';
import { formatCurrency } from '../../../utils/formatters.ts';
import { toast } from 'react-hot-toast';

interface BalanceSheetItem {
  id: string;
  accountId: string;
  description: string;
  amount: number;
  account?: {
    code: string;
    name: string;
  };
}

interface BalanceSheet {
  id: string;
  documentNumber: string;
  asOfDate: string;
  status: string;
  totalAssets: number;
  totalLiabilities: number;
  totalEquity: number;
  assetItems: BalanceSheetItem[];
  liabilityItems: BalanceSheetItem[];
  equityItems: BalanceSheetItem[];
}

const BalanceSheetPage = () => {
  const [date, setDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [balanceSheet, setBalanceSheet] = useState<BalanceSheet | null>(null);
  const [expandedSections, setExpandedSections] = useState({
    assets: true,
    currentAssets: true,
    nonCurrentAssets: true,
    liabilities: true,
    currentLiabilities: true,
    nonCurrentLiabilities: true,
    equity: true
  });
  const { selectedLocation } = useLocation();

  const handleGenerateReport = async () => {
    if (!date || !selectedLocation?.id) {
      toast.error('Please select a date and location');
      return;
    }

    setLoading(true);
    try {
      const response = await generateBalanceSheet({
        locationId: selectedLocation.id,
        asOfDate: date
      });
      setBalanceSheet(response.data);
      toast.success('Balance sheet generated successfully');
    } catch (error) {
      console.error('Error generating balance sheet:', error);
      toast.error('Failed to generate balance sheet');
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    toast.info('Export functionality coming soon');
  };

  const handleRefresh = () => {
    handleGenerateReport();
  };

  const toggleSection = (section: string) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const renderSectionHeader = (title: string, section: string, total?: number, depth: number = 0) => (
    <motion.div 
      onClick={() => toggleSection(section)}
      className={`flex items-center justify-between p-3 hover:bg-seashell/50 cursor-pointer group transition-colors duration-200 ${
        depth === 0 ? 'bg-gradient-to-r from-hazel-green/5 to-transparent' : ''
      }`}
      whileHover={{ backgroundColor: 'rgba(242, 242, 242, 0.8)' }}
    >
      <div className="flex items-center gap-2">
        <motion.div
          initial={false}
          animate={{ rotate: expandedSections[section] ? 90 : 0 }}
          transition={{ duration: 0.2 }}
          className="w-4 h-4 flex items-center justify-center"
        >
          {expandedSections[section] ? 
            <Minus className="w-4 h-4 text-hazel-green" /> : 
            <Plus className="w-4 h-4 text-hazel-green" />
          }
        </motion.div>
        <span className={`font-medium ${depth === 0 ? 'text-hazel-green text-lg' : 'text-bluish-grey'}`}>
          {title}
        </span>
      </div>
      {total !== undefined && (
        <motion.span 
          className={`font-medium ${depth === 0 ? 'text-hazel-green' : 'text-bluish-grey'}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
        >
          {formatCurrency(total)}
        </motion.span>
      )}
    </motion.div>
  );

  const renderItems = (items: BalanceSheetItem[]) => (
    <AnimatePresence>
      <div className="max-h-[240px] overflow-y-auto scrollbar-thin scrollbar-thumb-mercury scrollbar-track-transparent hover:scrollbar-thumb-hazel-green/20">
        {items.map((item, index) => (
          <motion.div
            key={item.id}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2, delay: index * 0.05 }}
            className="flex justify-between p-3 text-sm hover:bg-seashell/30 rounded-lg transition-colors"
          >
            <div className="flex items-center space-x-3">
              <span className="text-xs text-bluish-grey/70 font-mono">{item.account?.code}</span>
              <span className="text-bluish-grey">{item.description}</span>
            </div>
            <span className="text-bluish-grey font-medium">{formatCurrency(item.amount)}</span>
          </motion.div>
        ))}
      </div>
    </AnimatePresence>
  );

  const renderBalanceSheet = () => {
    if (!balanceSheet) return null;

    const currentAssets = balanceSheet.assetItems.filter(item => item.account?.code?.startsWith('1'));
    const nonCurrentAssets = balanceSheet.assetItems.filter(item => !item.account?.code?.startsWith('1'));
    const currentLiabilities = balanceSheet.liabilityItems.filter(item => item.account?.code?.startsWith('2'));
    const nonCurrentLiabilities = balanceSheet.liabilityItems.filter(item => !item.account?.code?.startsWith('2'));

    const totalCurrentAssets = currentAssets.reduce((sum, item) => sum + item.amount, 0);
    const totalNonCurrentAssets = nonCurrentAssets.reduce((sum, item) => sum + item.amount, 0);
    const totalCurrentLiabilities = currentLiabilities.reduce((sum, item) => sum + item.amount, 0);
    const totalNonCurrentLiabilities = nonCurrentLiabilities.reduce((sum, item) => sum + item.amount, 0);

    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-1 bg-white rounded-lg shadow-sm border border-mercury/50"
      >
        {/* Assets Section */}
        <div className="border-b border-mercury/50">
          {renderSectionHeader('Assets', 'assets', balanceSheet.totalAssets, 0)}
          <AnimatePresence>
            {expandedSections.assets && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="pl-4 overflow-hidden"
              >
                {/* Current Assets */}
                <div>
                  {renderSectionHeader('Current Assets', 'currentAssets', totalCurrentAssets, 1)}
                  <AnimatePresence>
                    {expandedSections.currentAssets && (
                      <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.2 }}
                        className="pl-4 overflow-hidden"
                      >
                        {renderItems(currentAssets)}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                {/* Non-Current Assets */}
                <div>
                  {renderSectionHeader('Non-Current Assets', 'nonCurrentAssets', totalNonCurrentAssets, 1)}
                  <AnimatePresence>
                    {expandedSections.nonCurrentAssets && (
                      <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.2 }}
                        className="pl-4 overflow-hidden"
                      >
                        {renderItems(nonCurrentAssets)}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Liabilities Section */}
        <div className="border-b border-mercury/50">
          {renderSectionHeader('Liabilities', 'liabilities', balanceSheet.totalLiabilities, 0)}
          <AnimatePresence>
            {expandedSections.liabilities && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="pl-4 overflow-hidden"
              >
                {/* Current Liabilities */}
                <div>
                  {renderSectionHeader('Current Liabilities', 'currentLiabilities', totalCurrentLiabilities, 1)}
                  <AnimatePresence>
                    {expandedSections.currentLiabilities && (
                      <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.2 }}
                        className="pl-4 overflow-hidden"
                      >
                        {renderItems(currentLiabilities)}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                {/* Non-Current Liabilities */}
                <div>
                  {renderSectionHeader('Non-Current Liabilities', 'nonCurrentLiabilities', totalNonCurrentLiabilities, 1)}
                  <AnimatePresence>
                    {expandedSections.nonCurrentLiabilities && (
                      <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.2 }}
                        className="pl-4 overflow-hidden"
                      >
                        {renderItems(nonCurrentLiabilities)}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Equity Section */}
        <div>
          {renderSectionHeader("Shareholders' Equity", 'equity', balanceSheet.totalEquity, 0)}
          <AnimatePresence>
            {expandedSections.equity && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="pl-4 overflow-hidden"
              >
                {renderItems(balanceSheet.equityItems)}
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Total */}
        <motion.div 
          className="border-t border-mercury mt-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <div className="flex justify-between p-4 bg-gradient-to-r from-hazel-green/5 to-transparent">
            <span className="font-semibold text-lg text-hazel-green">Total Liabilities & Equity</span>
            <span className="font-semibold text-lg text-hazel-green">
              {formatCurrency(balanceSheet.totalLiabilities + balanceSheet.totalEquity)}
            </span>
          </div>
        </motion.div>
      </motion.div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue/5 via-seashell to-seashell/30">
      {/* Header Section */}
      <div className="bg-white shadow-sm border-b border-mercury sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2 text-sm">
              <span className="text-sage hover:text-hazel-green font-medium transition-colors">Pasqyrat</span>
              <ChevronDown className="w-4 h-4 text-sage" />
              <span className="font-medium text-hazel-green">Bilanci i Gjendjes</span>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">
        {/* Form Card */}
        <div className="bg-white rounded-2xl shadow-lg border border-mercury/50 mb-8 overflow-hidden">
          <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-8">
            <div className="max-w-2xl">
              <h1 className="text-2xl font-semibold text-hazel-green mb-3">Bilanci i Gjendjes</h1>
              <p className="text-grey-goose">
                Shfaqni Bilancin e Gjendjes (Pasqyrën e Pozicionit Financiar)
              </p>
            </div>
          </div>

          {/* Form Content */}
          <div className="p-8">
            <div className="max-w-xl mx-auto mb-8">
              <div className="relative group">
                <label className="inline-flex items-center text-sm font-medium text-bluish-grey mb-2 group-hover:text-hazel-green transition-colors">
                  <Calendar className="w-4 h-4 mr-2 opacity-70" />
                  <span>Data</span>
                </label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="w-full p-3 bg-white border border-mercury rounded-xl text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 hover:border-hazel-green/30 transition-colors"
                />
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-center gap-4">
              <button
                onClick={() => {
                  setDate('');
                  setBalanceSheet(null);
                }}
                className="px-6 py-3 bg-seashell text-grey-goose rounded-xl hover:bg-mercury transition-all duration-200"
              >
                Reset
              </button>
              <button
                onClick={handleGenerateReport}
                disabled={loading}
                className={`px-6 py-3 bg-gradient-to-r from-hazel-green to-sage text-white rounded-xl hover:from-sage hover:to-hazel-green transition-all duration-300 flex items-center space-x-3 ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                <span>{loading ? 'Generating...' : 'Gjenero Raportin'}</span>
              </button>
            </div>
          </div>
        </div>

        {/* Balance Sheet Content */}
        {balanceSheet && (
          <div className="space-y-6">
            {/* Report Header */}
            <div className="bg-white rounded-xl shadow-sm border border-mercury/50 p-6 mb-6">
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-lg font-semibold text-hazel-green mb-2">Detajet e Bilancit</h2>
                  <p className="text-sm text-grey-goose">Document: {balanceSheet.documentNumber}</p>
                </div>
                <div className="flex items-center gap-3">
                  <button className="inline-flex items-center px-4 py-2.5 bg-white text-hazel-green rounded-lg hover:bg-seashell transition-colors duration-200 gap-2 font-medium border border-mercury/50 shadow-sm">
                    <Filter className="w-4 h-4" />
                    <span>Filtro</span>
                  </button>
                  <button
                    onClick={handleRefresh}
                    className="inline-flex items-center px-4 py-2.5 bg-white text-hazel-green rounded-lg hover:bg-seashell transition-colors duration-200 gap-2 font-medium border border-mercury/50 shadow-sm"
                  >
                    <RefreshCw className="w-4 h-4" />
                    <span>Rifresko</span>
                  </button>
                  <button
                    onClick={handleExport}
                    className="inline-flex items-center px-4 py-2.5 bg-white text-hazel-green rounded-lg hover:bg-seashell transition-colors duration-200 gap-2 font-medium border border-mercury/50 shadow-sm"
                  >
                    <Download className="w-4 h-4" />
                    <span>Eksporto</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Balance Sheet */}
            {renderBalanceSheet()}
          </div>
        )}
      </div>
    </div>
  );
};

export default BalanceSheetPage;
