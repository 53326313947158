import api from './api';

export const createOffer = async (data) => {
  try {
    const response = await api.post('/api/offer', data);
    return {
      data: response.data.message,
      success: true
    };
  } catch (error) {
    console.error('Error creating offer:', error);
    throw error;
  }
};

export const getOffers = async (filters) => {
  try {
    const response = await api.get('/api/offer', { 
      params: {
        ...filters,
        page: filters.page || 1,
        limit: filters.limit || 6
      } 
    });
    return {
      data: response.data.message,
      success: true
    };
  } catch (error) {
    console.error('Error fetching offers:', error);
    throw error;
  }
};

export const getOfferById = async (id) => {
  try {
    const response = await api.get(`/api/offer/${id}`);
    return {
      data: response.data.message,
      success: true
    };
  } catch (error) {
    console.error('Error fetching offer:', error);
    throw error;
  }
};

export const updateOffer = async (id, data) => {
  try {
    const response = await api.patch(`/api/offer/${id}`, data);
    return {
      data: response.data.message,
      success: true
    };
  } catch (error) {
    console.error('Error updating offer:', error);
    throw error;
  }
};

export const deleteOffer = async (id) => {
  try {
    const response = await api.delete(`/api/offer/${id}`);
    return {
      data: response.data.message,
      success: true
    };
  } catch (error) {
    console.error('Error deleting offer:', error);
    throw error;
  }
};

export const getClientsForOffer = async () => {
  try {
    const response = await api.get('/api/offer/clients/list');
    return {
      data: response.data.message,
      success: true
    };
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
};

export const getNextOfferDocumentNumber = async () => {
  try {
    const response = await api.get('/api/offer/document-number');
    console.log('Document number raw response:', response);
    return {
      data: {
        message: {
          documentNumber: response.data.message.documentNumber
        }
      }
    };
  } catch (error) {
    console.error('Error getting next offer document number:', error);
    throw error;
  }
};
