import React from 'react';
import { useState, useEffect} from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { getReservationStats } from '../../api/poolsInterface';
// const data = [
//     { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
//     { name: 'Feb', uv: 3000, pv: 1398, amt: 2210 },
//     { name: 'Mar', uv: 2000, pv: 9800, amt: 2290 },
//     { name: 'Apr', uv: 2780, pv: 3908, amt: 2000 },
//     { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
//     { name: 'Jun', uv: 2390, pv: 3800, amt: 2500 },
//     { name: 'Jul', uv: 3490, pv: 4300, amt: 2100 },
//   ];

const Statistics = () => {
  const [data, setData] = useState([]);


  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getReservationStats();
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    // <div className="bg-white p-6 rounded-lg shadow-md">
    //   <h2 className="font-bold text-xl mb-4">Pool Statistics</h2>
    //   <ResponsiveContainer width="100%" height={200}>
    //     <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
    //       <CartesianGrid strokeDasharray="3 3" />
    //       <XAxis dataKey="name" />
    //       <YAxis />
    //       <Tooltip />
    //       <Legend />
    //       <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
    //       <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
    //     </LineChart>
    //   </ResponsiveContainer>
    // </div>

    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="font-bold text-xl mb-4">Pool Statistics</h2>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="today" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="yesterday" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Statistics;
