import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../components/loader';
import CustomTable from '../components/table-components/table';
import { getProducts, deleteProduct } from '../api/products';
import { getSectionProducts, transferProduct } from '../api/inventory';
import { CustomTabs, CustomTabPanel } from '../components/tab-components/CustomTabs';
import Modal from '../components/modal';
import { IconArrowsTransferDown } from '@tabler/icons-react';
import toast from 'react-hot-toast';

function TransferModal({ product, onClose, onTransfer }) {
  const [amount, setAmount] = useState('');
  const [targetSection, setTargetSection] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = useSelector((state) => state.user.user);
  
  const sections = ['Bar', 'Kitchen', 'Pool', 'Outdoor Pool', 'Minibar'].filter(
    section => section.toLowerCase() !== product.section?.toLowerCase()
  );

  useEffect(() => {
    if (!user || !user.id) {
      console.error('No user ID found in Redux state');
      toast.error('Please log in to continue');
      onClose();
    }
  }, [user, onClose]);

  const handleTransfer = async () => {
    try {
      if (!user || !user.id) {
        toast.error('Please log in to continue');
        return;
      }

      if (!amount || !targetSection) {
        toast.error('Please fill in all required fields');
        return;
      }

      if (!product || !product.id) {
        toast.error('Invalid product selected');
        return;
      }

      setIsSubmitting(true);
      const formattedSection = targetSection.toUpperCase().replace(' ', '_');
      await onTransfer(product.id, Number(amount), formattedSection, user.id);
      toast.success('Transfer completed successfully');
      onClose();
    } catch (error) {
      console.error('Error transferring product:', error);
      toast.error(error.response?.data?.message || 'Failed to transfer product');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!user || !user.id) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <label className="block text-sm font-medium text-sage">
          Amount to Transfer
        </label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          max={product.quantity}
          min={1}
          className="w-full px-3 py-2 border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
          placeholder="Enter amount"
        />
      </div>
      
      <div className="space-y-2">
        <label className="block text-sm font-medium text-sage">
          Transfer to Section
        </label>
        <select
          value={targetSection}
          onChange={(e) => setTargetSection(e.target.value)}
          className="w-full px-3 py-2 border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
        >
          <option value="">Select section</option>
          {sections.map((section) => (
            <option key={section} value={section}>
              {section}
            </option>
          ))}
        </select>
      </div>

      <div className="flex justify-end space-x-3 mt-6">
        <button
          onClick={onClose}
          className="px-4 py-2 text-sage border border-grey-goose rounded-md hover:bg-seashell"
          disabled={isSubmitting}
        >
          Cancel
        </button>
        <button
          onClick={handleTransfer}
          disabled={!amount || !targetSection || amount > product.quantity || isSubmitting}
          className="px-4 py-2 bg-hazel-green text-white rounded-md hover:bg-opacity-90 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSubmitting ? 'Transferring...' : 'Transfer'}
        </button>
      </div>
    </div>
  );
}

function SectionInventory({ section }) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchSectionInventory = async () => {
      try {
        const response = await getSectionProducts(section, rowsPerPage, currentPage + 1, searchText);
        setProducts(response.data.products);
        setTotalCount(response.data.count);
      } catch (error) {
        console.error('Error fetching section inventory:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchSectionInventory();
  }, [section, currentPage, rowsPerPage, searchText]);

  return (
    <CustomTable
      rows={products}
      columns={[
        {
          title: 'Name',
          renderValue: (item) => item.name
        },
        {
          title: 'Type',
          renderValue: (item) => item.type
        },
        {
          title: 'Quantity',
          renderValue: (item) => (
            <div className={`${
              item.quantity > 30
                ? 'bg-seashell text-bluish-grey'
                : 'bg-dawn-pink text-salmon-pink'
              } rounded-md py-3`}
            >
              <p className='text-center'>{item.quantity}</p>
            </div>
          )
        },
        {
          title: 'Unit',
          renderValue: (item) => item.unit
        },
        {
          title: 'Unit Price',
          renderValue: (item) => item.unitPrice
        },
        {
          title: 'Total Price',
          renderValue: (item) => item.totalPrice
        },
        {
          title: 'Provider',
          renderValue: (item) => item.invoice?.provider || '-'
        },
        {
          title: 'Invoice Number',
          renderValue: (item) => item.invoice?.number || '-'
        }
      ]}
      setSearchText={setSearchText}
      loading={loading}
      showMoreActions={true}
      actionsType="delete-only"
      entity="inventory"
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      totalCount={totalCount}
    />
  );
}

function Inventory() {
  const [products, setProducts] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const getProductsList = useCallback(async () => {
    try {
      const response = await getProducts(
        rowsPerPage,
        currentPage + 1,
        searchText
      );
      setProducts(response.data.products);
      setTotalCount(response.data.count);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, searchText]);

  useEffect(() => {
    getProductsList();
  }, [getProductsList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  const handleDelete = async (id) => {
    try {
      await deleteProduct(id);
      setProducts((prevState) => [
        ...prevState.filter((item) => item.id !== id)
      ]);
      setTotalCount(totalCount - 1);
    } catch (err) {
      return err;
    }
  };

  const handleTransfer = async (productId, amount, targetSection, adminUserId) => {
    try {
      await transferProduct(productId, amount, targetSection, adminUserId);
      // Refresh both main inventory and section inventories
      await getProductsList();
    } catch (error) {
      console.error('Error transferring product:', error);
      throw error; // Re-throw to be handled by the modal
    }
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10 space-y-8'>
      <div>
        <h2 className="text-xl font-semibold mb-4 text-hazel-green">Main Inventory</h2>
        <CustomTable
          rows={products}
          columns={[
            {
              title: 'Name',
              renderValue: (item) => item.name
            },
            {
              title: 'Type',
              renderValue: (item) => item.type
            },
            {
              title: 'Quantity',
              renderValue: (item) => (
                <div
                  className={`${
                    item.quantity > 30
                      ? 'bg-seashell text-bluish-grey'
                      : 'bg-dawn-pink text-salmon-pink'
                  } rounded-md py-3`}
                >
                  <p className='text-center'>{item.quantity}</p>
                </div>
              )
            },
            {
              title: 'Unit',
              renderValue: (item) => item.unit
            },
            {
              title: 'Unit Price',
              renderValue: (item) => item.unitPrice
            },
            {
              title: 'Total Price',
              renderValue: (item) => item.totalPrice
            },
            {
              title: 'Provider',
              renderValue: (item) => item.invoice?.provider || '-'
            },
            {
              title: 'Invoice Number',
              renderValue: (item) => item.invoice?.number || '-'
            },
            {
              title: 'Actions',
              renderValue: (item) => {
                // Check if the product name contains section indicators like [kitchen] or (bar)
                const isFromOtherSection = /[\[\(][^\]\)]+[\]\)]/.test(item.name);
                
                // Only show transfer button for products not from other sections
                return !isFromOtherSection ? (
                  <button
                    onClick={() => {
                      setSelectedProduct(item);
                      setShowTransferModal(true);
                    }}
                    className="p-2 text-hazel-green hover:bg-seashell rounded-full transition-colors"
                    title="Transfer to another section"
                  >
                    <IconArrowsTransferDown className="w-5 h-5 stroke-[1.5]" />
                  </button>
                ) : null;
              }
            }
          ]}
          setSearchText={setSearchText}
          loading={loading}
          showMoreActions={true}
          actionsType="delete-only"
          entity="inventory"
          onDelete={handleDelete}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
        />
      </div>

      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4 text-hazel-green">Section Inventories</h2>
        <CustomTabs tabs={['Bar', 'Kitchen', 'Pool', 'Outdoor Pool', 'Minibar']}>
          <CustomTabPanel>
            <SectionInventory section="bar" />
          </CustomTabPanel>
          <CustomTabPanel>
            <SectionInventory section="kitchen" />
          </CustomTabPanel>
          <CustomTabPanel>
            <SectionInventory section="pool" />
          </CustomTabPanel>
          <CustomTabPanel>
            <SectionInventory section="outdoor_pool" />
          </CustomTabPanel>
          <CustomTabPanel>
            <SectionInventory section="minibar" />
          </CustomTabPanel>
        </CustomTabs>
      </div>

      {showTransferModal && selectedProduct && (
        <Modal
          title={`Transfer ${selectedProduct.name}`}
          onClose={() => {
            setShowTransferModal(false);
            setSelectedProduct(null);
          }}
        >
          <TransferModal
            product={selectedProduct}
            onClose={() => {
              setShowTransferModal(false);
              setSelectedProduct(null);
            }}
            onTransfer={handleTransfer}
          />
        </Modal>
      )}
    </div>
  );
}

export default Inventory;
