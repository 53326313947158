import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Select, MenuItem } from '@mui/material';
import { IconUpload, IconX } from '@tabler/icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import CustomInput from '../../../components/custom-input';
import { SubmitButton, CancelButton } from '../../../components/buttons';
import { icons } from '../../../constants/additional-info-icons';
import { createRoomType, updateRoomType, fileUpload } from '../../../api/rooms';

import { createTheme, ThemeProvider } from '@mui/material/styles';

// Add the unique method to Yup
Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, function (list) {
    const uniqueItems = new Set(list.map(mapper));
    return uniqueItems.size === list.length;
  });
});

function RoomTypeForm({ isEdit, roomTypeData }) {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#90a68c'
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <div className='border border-gray-200 rounded-xl shadow-lg py-6 sm:py-10 px-4 sm:px-8 mt-5'>
        <Formik
          initialValues={{
            title: isEdit ? roomTypeData.title : '',
            price: isEdit ? roomTypeData.price : null,
            description: isEdit ? roomTypeData.description : '',
            imagePath: isEdit ? roomTypeData.imagePath : null,
            capacity: isEdit ? roomTypeData.capacity : 2,
            additionalInfo: isEdit
              ? Array.isArray(roomTypeData.additionalInfo) ? roomTypeData.additionalInfo : []
              : [{ icon: '', title: '', value: '' }],
            inventory: isEdit
              ? Array.isArray(roomTypeData.inventory) ? roomTypeData.inventory : []
              : [{ item: '', quantity: 1, goodCondition: true }]
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().min(3).required('Title is required*'),
            price: Yup.number().required('Price is required*'),
            description: Yup.string()
              .min(4)
              .required('Description is required*'),
            imagePath: Yup.string().nullable(),
            capacity: Yup.string().min(1).required('Capacity is required*'),
            additionalInfo: Yup.array()
              .of(
                Yup.object().shape({
                  title: Yup.string(),
                  value: Yup.string(),
                  icon: Yup.string()
                })
              )
              .unique('Option already used', (a) => a.title),
            inventory: Yup.array()
              .of(
                Yup.object().shape({
                  item: Yup.string(),
                  quantity: Yup.number(),
                  goodCondition: Yup.boolean()
                })
              )
              .unique('Option already used', (a) => a.item)
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            console.log('Form submitted with values:', values);
            try {
              const formData = new FormData();
              if (uploadedFile) {
                formData.append('image', uploadedFile);
                const response = await fileUpload(formData);
                values.imagePath = response.data;
              }
              const dataToSend = {
                ...values,
                additionalInfo: JSON.stringify(values.additionalInfo),
                inventory: JSON.stringify(values.inventory)
              };
              if (isEdit) {
                await updateRoomType(roomTypeData.id, dataToSend);
              } else {
                await createRoomType(dataToSend);
                navigate('/rooms');
              }
              setSubmitting(false);
            } catch (err) {
              setErrors({
                submit: err?.response?.data
              });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <h3 className='text-bluish-grey font-medium uppercase px-7'>
                General info
              </h3>
              <div className='space-y-4 sm:space-y-6'>
                <CustomInput
                  type='text'
                  name='title'
                  label='Title'
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.title && touched.title}
                  errorMessage={errors.title}
                />
                <CustomInput
                  type='number'
                  name='price'
                  label='Price per night'
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.price && touched.price}
                  errorMessage={errors.price}
                />
                <CustomInput
                  type='text'
                  name='description'
                  label='Description'
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiline={true}
                  showError={errors.description && touched.description}
                  errorMessage={errors.description}
                />
                <div>
                  <p className='text-bluish-grey mb-2 sm:mb-3'>Image</p>
                  <div className='flex flex-wrap gap-3 sm:gap-4'>
                    <div
                      className='flex items-center justify-center bg-mercury w-28 h-28 sm:w-32 sm:h-32 rounded-md cursor-pointer'
                      onClick={() => fileInputRef.current.click()}
                    >
                      <IconUpload className='text-hazel-green w-6 h-6 sm:w-8 sm:h-8' />
                    </div>
                    {(uploadedFile || values.imagePath) && (
                      <div className='relative'>
                        <img
                          src={uploadedFile ? URL.createObjectURL(uploadedFile) : `http://localhost:3001/${values.imagePath}`}
                          alt='room'
                          className='w-28 h-28 sm:w-32 sm:h-32 rounded-md object-cover'
                        />
                        <button
                          type='button'
                          className='absolute -top-1 -right-1 sm:top-0 sm:right-0'
                          onClick={() => setUploadedFile(null)}
                        >
                          <div className='rounded-full w-5 h-5 sm:w-6 sm:h-6 bg-white border border-sage flex items-center justify-center'>
                            <IconX className='w-3 h-3 sm:w-4 sm:h-4 text-sage' />
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='mt-6 sm:mt-8'>
                <h3 className='text-bluish-grey font-medium uppercase px-3 sm:px-7'>
                  Additional info
                </h3>
                
                <div className='grid grid-cols-1 sm:grid-cols-11 gap-3 sm:gap-6 mt-4 px-3 sm:px-7'>
                  <div className='hidden sm:flex sm:col-span-1 justify-center items-center border border-silver rounded'>
                    <FontAwesomeIcon icon={faUsers} className='text-hazel-green' />
                  </div>
                  <div className='sm:col-span-4'>
                    <CustomInput
                      type='text'
                      name='title'
                      label='Capacity'
                      disabled={true}
                    />
                  </div>
                  <div className='sm:col-span-5'>
                    <CustomInput
                      type='number'
                      name='capacity'
                      label='Value'
                      value={values.capacity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.capacity && touched.capacity}
                      errorMessage={errors.capacity}
                    />
                  </div>
                </div>

                <FieldArray name='additionalInfo'>
                  {({ push, remove }) => (
                    <div className='px-3 sm:px-7 mt-5'>
                      {values.additionalInfo.map((option, index) => (
                        <div key={index} className='grid grid-cols-1 sm:grid-cols-11 gap-3 sm:gap-6 mt-4'>
                          <div className='sm:col-span-1'>
                            <Select
                              className='w-full'
                              value={option.icon}
                              onChange={async (event) => {
                                setFieldValue(
                                  `additionalInfo[${index}].icon`,
                                  event.target.value
                                );
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              sx={{
                                borderColor: 'primary.main'
                              }}
                            >
                              <MenuItem value=''>Icon</MenuItem>
                              {icons.map((icon) => (
                                <MenuItem key={icon.title} value={icon.title}>
                                  {icon.icon}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                          <div className='sm:col-span-4'>
                            <CustomInput
                              type='text'
                              name={`additionalInfo[${index}].title`}
                              label='Title'
                              value={option.title}
                              onChange={async (event) => {
                                setFieldValue(
                                  `additionalInfo[${index}].title`,
                                  event.target.value
                                );
                              }}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className='sm:col-span-5'>
                            <CustomInput
                              type='text'
                              name={`additionalInfo[${index}].value`}
                              label='Value'
                              value={option.value}
                              onChange={async (event) => {
                                setFieldValue(
                                  `additionalInfo[${index}].value`,
                                  event.target.value
                                );
                              }}
                              onBlur={handleBlur}
                            />
                          </div>
                          <button
                            type='button'
                            className='flex justify-center items-center bg-dawn-pink text-salmon-pink rounded h-[42px] sm:h-auto sm:col-span-1'
                            onClick={() => remove(index)}
                          >
                            <IconX className='w-4 h-4' />
                          </button>
                        </div>
                      ))}
                      <button
                        type='button'
                        className='border-dashed border-2 border-sage w-full sm:w-1/2 text-sage rounded-md mt-4 py-2'
                        onClick={() => push({ icon: '', title: '', value: '' })}
                      >
                        Add more
                      </button>
                    </div>
                  )}
                </FieldArray>

                <div className='mt-6 sm:mt-8'>
                  <h3 className='text-bluish-grey font-medium uppercase px-3 sm:px-7'>
                    Inventory
                  </h3>
                  <FieldArray name='inventory'>
                    {({ push, remove }) => (
                      <div className='px-3 sm:px-7 mt-5'>
                        {values.inventory.map((option, index) => (
                          <div key={index} className='grid grid-cols-1 sm:grid-cols-11 gap-3 sm:gap-6 mt-4'>
                            <div className='sm:col-span-5'>
                              <CustomInput
                                type='text'
                                name={`inventory[${index}].item`}
                                label='Item'
                                value={option.item}
                                onChange={async (event) => {
                                  setFieldValue(
                                    `inventory[${index}].item`,
                                    event.target.value
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className='sm:col-span-5'>
                              <CustomInput
                                type='number'
                                name={`inventory[${index}].quantity`}
                                label='Quantity'
                                value={option.quantity}
                                onChange={async (event) => {
                                  setFieldValue(
                                    `inventory[${index}].quantity`,
                                    event.target.value
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </div>
                            <button
                              type='button'
                              className='flex justify-center items-center bg-dawn-pink text-salmon-pink rounded h-[42px] sm:h-auto sm:col-span-1'
                              onClick={() => remove(index)}
                            >
                              <IconX className='w-4 h-4' />
                            </button>
                          </div>
                        ))}
                        <button
                          type='button'
                          className='border-dashed border-2 border-sage w-full sm:w-1/2 text-sage rounded-md mt-4 py-2'
                          onClick={() => push({ item: '', quantity: 1, goodCondition: true })}
                        >
                          Add more
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </div>

                {errors.submit && (
                  <p className='text-error px-7'>{errors.submit}</p>
                )}
                {errorMessage && (
                  <p className='text-error px-7'>{errorMessage}</p>
                )}

                <div className='flex flex-col sm:flex-row justify-end items-stretch sm:items-center gap-3 sm:gap-4 mt-7 px-3 sm:px-7'>
                  <CancelButton 
                    onCancel={() => navigate('/rooms')} 
                    className="w-full sm:w-auto"
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    width='w-full sm:w-[120px]'
                    title='Save'
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </ThemeProvider>
  );
}

export default RoomTypeForm;
