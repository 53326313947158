import React, { useState, useEffect } from 'react';
import { IconPlus, IconTrash, IconSearch, IconContract } from '@tabler/icons-react';
import ConfirmMessage from '../../components/alerts/alert-message';
import Loading from '../../components/loader';
import PermissionCheck from '../../utils/PermissionCheck';
import Modal from '../../components/modal';
import { getAllWaiters, getUnassignedTables, getAssignedTables as getTablesForWaiter, searchTransactionsByDate } from '../../api/waiter';
import { assignTableToWaiter, removeTableAssignments } from '../../api/waiter';
import { Input } from '../../components/shadCN/Input.tsx';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '../../components/shadCN/Select.tsx';

import { Card, CardContent, CardTitle, CardHeader } from '../../components/shadCN/Card.tsx';

import CustomTable from '../../components/table-components/table.js';

import { useNavigate } from 'react-router-dom';

function WaitersTablesList() {

  const navigate = useNavigate();

  const [searchText, setSearchText] = useState('');

  
    const [activeWaiter, setActiveWaiter] = useState(null);
    const [activeTab, setActiveTab] = useState('waiters');
    const [hoveredTable, setHoveredTable] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [deleteTableId, setDeleteTableId] = useState('');
    const [tables, setTables] = useState([]);
    const [unassignedTables, setUnassignedTables] = useState([]);
    const [waiters, setWaiters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showAssignTableModal, setShowAssignTableModal] = useState(false);
    const [selectedTableId, setSelectedTableId] = useState('');
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        async function fetchWaitersAndTables() {
            try {
                setLoading(true);
                const waiterResponse = await getAllWaiters();
                if (waiterResponse?.data) {
                    setWaiters(waiterResponse.data);
                    if (waiterResponse.data.length) {
                        setActiveWaiter(waiterResponse.data[0]);
                    }
                } else {
                    console.error('No valid waiter data received');
                    setWaiters([]);
                }

                const unassignedResponse = await getUnassignedTables();
                if (unassignedResponse?.data && Array.isArray(unassignedResponse.data)) {
                    setUnassignedTables(unassignedResponse.data);
                } else {
                    console.error('No valid unassigned table data received');
                    setUnassignedTables([]);
                }
            } catch (error) {
                console.error("Failed to fetch waiters or tables:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchWaitersAndTables();
    }, []);

    useEffect(() => {
        if (activeWaiter) {
            const fetchTables = async () => {
                setLoading(true);
                try {
                    const response = await getTablesForWaiter(activeWaiter.id);
                    const sortedTables = response.data.sort((a, b) => a.table.number - b.table.number);
                    setTables(sortedTables.map(item => ({
                        ...item.table,
                        environmentName: item.table?.enviroment?.name || "Unknown"
                    })));
                } catch (error) {
                    console.error("Failed to fetch tables for waiter:", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchTables();
        }
    }, [activeWaiter]);

    const handleDeleteTable = async () => {
        try {
            await removeTableAssignments(activeWaiter.id, [deleteTableId]);
            setTables((prevTables) => prevTables.filter((table) => table.id !== deleteTableId));
            await fetchUnassignedTables();
            setShowDeleteAlert(false);
            setDeleteTableId('');
        } catch (err) {
            console.error('Failed to delete table assignment:', err);
        }
    };

    const fetchUnassignedTables = async () => {
        try {
            const unassignedResponse = await getUnassignedTables();
            if (unassignedResponse?.data && Array.isArray(unassignedResponse.data)) {
                setUnassignedTables(unassignedResponse.data);
            }
        } catch (error) {
            console.error('Failed to fetch unassigned tables:', error);
        }
    };

    const redirectToReconciliation = () => {
        navigate("/waiter/waiterReconciliation")
    }

    const handleAssignTable = async () => {
        if (!selectedTableId) {
            console.error("No table selected for assignment");
            return;
        }

        try {
            await assignTableToWaiter(activeWaiter.id, selectedTableId);
            const assignedTable = unassignedTables.find(table => table.id === selectedTableId);
            if (assignedTable) {
                setTables((prevTables) => [...prevTables, assignedTable]);
                setUnassignedTables((prevUnassigned) => prevUnassigned.filter(table => table.id !== selectedTableId));
            }
            await fetchTablesForActiveWaiter();
            await fetchUnassignedTables();
            setShowAssignTableModal(false);
            setSelectedTableId('');
        } catch (error) {
            console.error("Failed to assign table to waiter:", error);
        }
    };

    const fetchTablesForActiveWaiter = async () => {
        if (activeWaiter) {
            setLoading(true);
            try {
                const response = await getTablesForWaiter(activeWaiter.id);
                const sortedTables = response.data.sort((a, b) => a.table.number - b.table.number);
                setTables(sortedTables.map(item => ({
                    ...item.table,
                    environmentName: item.table.enviroment?.name || "Unknown"
                })));
            } catch (error) {
                console.error("Failed to fetch tables for waiter:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSearch = async () => {
      if (!activeWaiter || !selectedDate) {
          console.error("Waiter and date must be selected");
          return;
      }

      try {
          const response = await searchTransactionsByDate(activeWaiter.id, selectedDate);
          setTransactions(response.data);
      } catch (error) {
          console.error("Failed to fetch transactions:", error);
      }
  };

  // Filter transactions based on search text
  const filteredTransactions = transactions.filter(transaction =>
      transaction.description.toLowerCase().includes(searchText.toLowerCase())
  );
    if (loading) return <Loading />;

    return (
        <div className='p-10'>
            <div className='border border-gray-200 rounded-xl shadow-lg px-3 mt-5'>
                <div className='flex items-center justify-between w-full border-b border-gray-200 overflow-x-scroll no-scrollbar'>
                    <div className='flex items-center w-56'>
                        <div
                            className={`flex items-center px-5 py-3.5 whitespace-nowrap ${activeTab === 'unassigned' ? 'bg-seashell bg-opacity-30 rounded-md' : ''} cursor-pointer`}
                            onClick={() => setActiveTab('unassigned')}
                        >
                            <p className='text-bluish-grey capitalize ml-2'>Unassigned Tables</p>
                        </div>

                        {Array.isArray(waiters) && waiters.map((tab) => (
                            <div key={tab.id}>
                                <div
                                    className={`flex items-center px-5 py-3.5 whitespace-nowrap ${tab.id === activeWaiter?.id && activeTab === 'waiters' ? 'bg-seashell bg-opacity-30 rounded-md' : ''} cursor-pointer`}
                                    onClick={() => {
                                        setActiveWaiter(tab);
                                        setActiveTab('waiters');
                                    }}
                                >
                                    <p className='text-bluish-grey capitalize ml-2'>{`${tab.name} ${tab.surname}`}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {activeTab === 'waiters' && (
                    <div className='flex flex-row gap-3 justify-end items-center bg-white py-2.5 px-5 gap-x-5'>
                        <PermissionCheck action='edit_tables'>
                            <button
                                onClick={() => setShowAssignTableModal(true)}
                                className='flex justify-center items-center p-5 bg-seashell rounded-md'
                            >
                                <IconPlus className='text-bluish-grey font-bold cursor-pointer' size={24} />
                                Add Table
                            </button>
                        </PermissionCheck>
                        <PermissionCheck action='edit_tables'>
                            <button
                                onClick={() => redirectToReconciliation()}
                                className='flex justify-center items-center p-5 bg-seashell rounded-md'
                            >
                                <IconPlus className='text-bluish-grey font-bold cursor-pointer' size={24} />
                                Waiter Reconciliation
                            </button>
                        </PermissionCheck>
                    </div>
                )}

                <div className='grid grid-cols-5 gap-y-4 gap-x-3 my-4'>
                    {activeTab === 'unassigned' ? (
                        unassignedTables.length ? (
                            unassignedTables.map((table) => (
                                <div key={table.id} className='flex justify-center items-center p-5 bg-seashell rounded-md'>
                                    <p className='text-bluish-grey text-2xl font-medium py-2.5'>{table.number}</p>
                                </div>
                            ))
                        ) : (
                            <p className='text-sage font-bold py-10 px-8'>No unassigned tables available!</p>
                        )
                    ) : tables.length ? (
                        tables.map((table) => (
                            <div
                                key={table.id}
                                className='flex justify-center items-center p-3 px-1 bg-seashell rounded-md'
                                onMouseEnter={() => setHoveredTable(table.id)}
                                onMouseLeave={() => setHoveredTable(null)}
                            >
                                {hoveredTable === table.id ? (
                                    <div className='flex justify-center items-center bg-white py-2.5 px-5 gap-x-5 rounded'>
                                        <PermissionCheck action='delete_tables'>
                                            <IconTrash
                                                className='text-bluish-grey cursor-pointer'
                                                size={18}
                                                onClick={() => {
                                                    setDeleteTableId(table.id);
                                                    setShowDeleteAlert(true);
                                                }}
                                            />
                                        </PermissionCheck>
                                    </div>
                                ) : (
                                    <div className='flex flex-col space-y-2 items-center justify-center text-center'>
                                        <p className='text-bluish-grey text-4xl font-medium py-2.5'>{table.number}</p>
                                        <p className='font-semibold text-bluish-grey text-sm'>{table.environmentName}</p>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <p className='text-sage font-bold py-10 px-8'>No assigned tables available!</p>
                    )}
                </div>

                {showAssignTableModal && (
                    <Modal onClose={() => setShowAssignTableModal(false)}>
                        <div>
                            <h2 className='text-lg text-hazel-green font-bold mb-4'>Assign Table to Waiter</h2>
                            <select
                                value={selectedTableId}
                                onChange={(e) => setSelectedTableId(e.target.value)}
                                className='border border-gray-300 p-2 mb-4 w-full'
                            >
                                <option value="" disabled>Select a table</option>
                                {unassignedTables.map((table) => (
                                    <option key={table.id} value={table.id}>{table.number}</option>
                                ))}
                            </select>
                            <div className="flex justify-end mt-4">
                                <button
                                    onClick={() => setShowAssignTableModal(false)}
                                    className="mr-2 text-gray-500"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleAssignTable}
                                    className="bg-hazel-green text-white rounded-md px-4 py-2"
                                >
                                    Assign
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}

                {showDeleteAlert && (
                    <ConfirmMessage
                        title='Are you sure you want to delete this table assignment?'
                        onCancel={() => setShowDeleteAlert(false)}
                        onConfirm={handleDeleteTable}
                    />
                )}
            </div>


            <div className="p-6 min-h-screen">
                <Card className="mb-6 bg-seashell">
                    <CardHeader>
                        <CardTitle className="text-3xl font-bold text-hazel-green">Transaction Search</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="waiter" className="block text-sm font-medium text-bluish-grey mb-1">
                                        Select Waiter
                                    </label>
                                    <Select onValueChange={(value) => setActiveWaiter(waiters.find(w => w.id === value))}>
                                        <SelectTrigger className="w-full rounded-md border border-gray-300 shadow-md hover:shadow-lg transition-all duration-200">
                                            <SelectValue placeholder="Select a waiter" />
                                        </SelectTrigger>
                                        <SelectContent className="bg-white">
                                            {waiters.map(waiter => (
                                                <SelectItem key={waiter.id} value={waiter.id}>
                                                    {`${waiter.name} ${waiter.surname}`}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>

                                <div>
                                    <label htmlFor="date" className="block text-sm font-medium text-bluish-grey mb-1">
                                        Select Date
                                    </label>
                                    <Input
                                        type="date"
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                        className="w-full rounded-md border border-gray-300 shadow-md hover:shadow-lg transition-all duration-200"
                                    />
                                </div>
                            </div>
                            <div className="flex gap-x-3">
                                <button
                                    className="flex items-center text-white rounded-md bg-sage px-4 py-2.5"
                                    onClick={handleSearch}
                                >
                                    <IconSearch className="mr-2" size={20} />
                                    Search
                                </button>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {filteredTransactions.length > 0 ? ( // Use filtered transactions here
                    <CustomTable
                        rows={filteredTransactions} // Pass filtered transactions
                        columns={[
                            { title: 'Transaction Date', renderValue: (item) => new Date(item.transactionDate).toLocaleDateString() },
                            { title: 'Amount', renderValue: (item) => `$${item.amount.toFixed(2)}` },
                            { title: 'Payment Type', renderValue: (item) => item.paymentType },
                            { title: 'Description', renderValue: (item) => item.description || 'N/A' },
                            { title: 'Client', renderValue: (item) => item.Client?.length > 0 ? item.Client[0].name : 'N/A' },
                            { title: 'Supplier', renderValue: (item) => item.Supplier?.length > 0 ? item.Supplier[0].name : 'N/A' },
                        ]}
                        loading={loading}
                        setSearchText={setSearchText} // Pass the setSearchText function
                    />
                ) : (
                    <p className="mt-4 text-center text-gray-500">No transactions found.</p>
                )}
            </div>

        </div>
    );
}

export default WaitersTablesList;
