import React, { useState, useEffect } from 'react';
import { createReservation } from '../../api/reservationsv2'; // Import the createReservation API
import { X, ArrowLeft, ArrowRight } from 'lucide-react';

import { AgeGroup } from '../../constants/entities.js';

import { createBlockedRoom } from '../../api/blockedRooms';
import { adjustAccountValue, getIncomeAccounts } from '../../api/account';
import { createInvoice } from '../../api/invoiceSale';

import api from "../../api/api.js";
import { createTransaction } from '../../api/accounting.js';

import { invoiceCount as invoiceCountAPI } from '../../api/invoiceSale.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getDishSections as getRestaurantProducts} from '../../api/dishSection.js';
import { getBarProducts  } from '../../api/barProduct.js';
import { differenceInCalendarDays } from 'date-fns';

interface ReservationModalProps {
  isOpen: boolean;
  onClose: () => void;
  roomName: string;
  selectedDate: Date;
  roomNumber: string;
  roomType: string;
  price: number;
  roomId: string;
  minibar: { id: string; name: string; quantity: number }[];
  inventory: any[]; // Add inventory prop with default empty array
}


interface Product {
  id: string;
  name: string;
  description: string;
  price: number;
  type: string;
  categoryOrderProductId: string;
}

type BookingType = 'book' | 'block';
type BookingStep = 'details' | 'extras';

const BAR_ITEMS = [
  { id: 1, name: 'House Wine', price: 25 },
  { id: 2, name: 'Craft Beer', price: 8 },
  { id: 3, name: 'Cocktail', price: 12 },
];

const RESTAURANT_ITEMS = [
  { id: 1, name: 'Breakfast Buffet', price: 20 },
  { id: 2, name: 'Lunch Set Menu', price: 30 },
  { id: 3, name: 'Dinner À la carte', price: 45 },
];

const INVENTORY_ITEMS = [
  { id: 1, name: 'Extra Towels', price: 5 },
  { id: 2, name: 'Bathrobe', price: 15 },
  { id: 3, name: 'Slippers', price: 8 },
];

const MINIBAR_ITEMS = [
  { id: 1, name: 'Soft Drinks', price: 4 },
  { id: 2, name: 'Snacks', price: 6 },
  { id: 3, name: 'Water', price: 3 },
];

interface InventoryItem {
  item: string;
  quantity: number;
  goodCondition: boolean;
  price?: number; // Add default price for inventory items
}

const INVENTORY_PRICE = 5; // Default price per inventory item

const ReservationModal = ({ isOpen, onClose, roomName, selectedDate, roomNumber, roomType, price , roomId, minibar = [], inventory = []}: ReservationModalProps) => {
  const [bookingType, setBookingType] = useState<BookingType>('book');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [extraBeds, setExtraBeds] = useState([]);
  const [selectedExtras, setSelectedExtras] = useState({
    barItems: [],
    restaurantItems: [],
    inventoryItems: inventory
      .filter(item => item.item.trim() !== '')
      .map(item => ({
        id: item.item,
        quantity: item.quantity,
        baseQuantity: item.quantity,
        goodCondition: item.goodCondition,
        price: INVENTORY_PRICE
      })),
    minibarItems: minibar.map(item => ({
      id: item.id,
      name: item.product?.name || '',
      quantity: item.quantity || 0
    }))
  });
  const [minibarTotal, setMinibarTotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(price);
  const [extrasTotalPrice, setExtrasTotalPrice] = useState(price);
  const [invoiceCount, setInvoiceCount] = useState(0);

  const [barProducts, setBarProducts] = useState<any[]>([]);
  const [restaurantProducts, setRestaurantProducts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  console.log("MINIBAR",minibar);

  const [blockFormData, setBlockFormData] = useState({
    fromDate: '',
    toDate: '',
    reason: '',
    notes: ''
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const [barResponse, restaurantResponse] = await Promise.all([
          getBarProducts(),
          getRestaurantProducts()
        ]);
        setBarProducts(Array.isArray(barResponse.data) ? barResponse.data : []);
        setRestaurantProducts(Array.isArray(restaurantResponse.data) ? restaurantResponse.data : []);
        
        console.log('Bar Products:', barResponse.data);
        console.log('Restaurant Products:', restaurantResponse.data);
      } catch (error) {
        console.error('Error fetching products:', error);
        toast.error('Failed to load products');
        setBarProducts([]);
        setRestaurantProducts([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchProducts();
    }
  }, [isOpen]);
  
  useEffect(() => {
    const fetchInvoiceCount = async () => {
        try {
            const response = await invoiceCountAPI(); // Adjust the endpoint as necessary
            console.log("INVOICE COUNT", response);
            setInvoiceCount(response.data.count); // Set the invoice count
        } catch (error) {
            console.error('Error fetching invoice count:', error);
            toast.error('Failed to load invoice count.');
        }
    };

    fetchInvoiceCount();
}, []);

  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    personalNumber: '',
    phoneNumber: '',
    adults: 0,
    children: 0,
    discount: 0,
    paymentType: null,
    deposit: 0,
    totalPrice: price,
    paidPrice: price,
    additionaPay: 0,
    cardNumber: null,
    selectedExtras: [],
    minibarItems: [],
    restaurantItems: [],
    inventoryItems: [],
    barItems: [],
    account: 'Primary Income',
    rentPerNight: price, // Store the per night rate
    tax: null,
    holdBookingTill: null,
    enquiryAppId: null,
    checkInDate: selectedDate.toISOString().split('T')[0],
    checkOutDate: '',
  });

    console.log('Room Number:', roomNumber);
    console.log('Room Type:', roomType);
    console.log('Room Price:', price); 


  const taxRates = {
    VAT: 0.20, // 20%
    GST: 0.18, // 18%
    ServiceTax: 0.15, // 15%
  };

  const [basePrice, setBasePrice] = useState(price);

  // Initialize the fixed total price that includes room and minibar
  const [initialTotal, setInitialTotal] = useState(price);

  const calculateInitialMinibarTotal = () => {
    return minibar.reduce((total, item) => {
      return total + (item.soldPrice || 0) * (item.quantity || 1);
    }, 0);
  };

  useEffect(() => {
    const calculatePaidPrice = () => {
      const discountAmount = formData.discount || 0;
      const depositAmount = formData.deposit || 0;
  
      let taxAmount = 0;
      if (formData.tax && formData.tax !== "NoTax") {
        // Use appropriate base price depending on step
        const basePrice = price;
        taxAmount = (taxRates[formData.tax] || 0) * (basePrice - discountAmount);
      }
  
      const newPaidPrice = (price) - discountAmount + depositAmount + taxAmount;
  
      setFormData(prev => ({ 
        ...prev, 
        paidPrice: newPaidPrice,
      }));
    };
  
    calculatePaidPrice();
  }, [formData.discount, formData.deposit, formData.tax, price]);

  const [incomeAccounts, setIncomeAccounts] = useState([]);

  useEffect(() => {
    const fetchIncomeAccounts = async () => {
      try {
        const response = await getIncomeAccounts();
        setIncomeAccounts(response.data); // Assuming the response data is an array of accounts
      } catch (error) {
        console.error('Error fetching income accounts:', error);
        toast.error('Failed to load income accounts.');
      }
    };

    fetchIncomeAccounts();
  }, []);

  useEffect(() => {
    calculateTotalPrice(); // Calculate total price on mount
  }, [selectedExtras]);

  const calculateMinibarTotal = () => {
    const total = selectedExtras.minibarItems.reduce((total, item) => {
      const itemData = minibar.find(i => i.id === item.id);
      return total + (itemData?.soldPrice || 0) * item.quantity; // Use sold price
    }, 0);
    setMinibarTotal(total); // Update the minibar total state
  };
  //I dont think that I can do this anymore I am broken my sould has shaken in its core
  const updateMinibarQuantity = (itemId: string, newQuantity: number) => {
    console.log('Updating minibar quantity:', { itemId, newQuantity });
    
    setSelectedExtras(prev => {
      const updatedMinibarItems = [...prev.minibarItems];
      const existingItemIndex = updatedMinibarItems.findIndex(item => item.id === itemId);
      
      if (existingItemIndex >= 0) {
        // Update existing item pleas ehelp me 
        updatedMinibarItems[existingItemIndex] = {
          ...updatedMinibarItems[existingItemIndex],
          quantity: newQuantity
        };
      } else if (newQuantity > 0) {
        // Add new item
        const minibarItem = minibar.find(item => item.id === itemId);
        if (minibarItem) {
          updatedMinibarItems.push({
            id: itemId,
            quantity: newQuantity,
            name: minibarItem.name,
            price: minibarItem.soldPrice
          });
        }
      }

      const newExtras = {
        ...prev,
        minibarItems: updatedMinibarItems.filter(item => item.quantity > 0)
      };

      // Calculate new total
      const newTotal = calculateTotalPrice();
      setFormData(prevForm => ({
        ...prevForm,
        totalPrice: newTotal
      }));

      return newExtras;
    });
  };

  useEffect(() => {
    calculateMinibarTotal(); // Calculate minibar total on mount
  }, [selectedExtras]);

  useEffect(() => {
    calculateTotalPrice();
  }, [selectedExtras]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      setIsSubmitting(true);

      // Validate required fields
      if (bookingType === 'book') {
        if (!formData.name || !formData.checkInDate || !formData.checkOutDate) {
          toast.error('Please fill in all required fields');
          return;
        }
      } else {
        if (!blockFormData.fromDate || !blockFormData.toDate || !blockFormData.reason) {
          toast.error('Please fill in all required fields for blocking');
          return;
        }

        // Validate dates if we cna do so
        const fromDate = new Date(blockFormData.fromDate);
        const toDate = new Date(blockFormData.toDate);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (fromDate < today) {
          toast.error('Start date cannot be in the past');
          return;
        }

        if (toDate <= fromDate) {
          toast.error('End date must be after start date');
          return;
        }
      }

      if (bookingType === 'book') {
        const reservationData = {
          ...formData,
          roomId,
          roomNumber,
          roomType,
          selectedExtras,
          extraBeds,
          fromDate: new Date(formData.checkInDate).toISOString(),
          toDate: new Date(formData.checkOutDate).toISOString(),
          rooms: [{
            id: roomId,
            roomNumber,
            roomType,
            price
          }]
        };

        delete reservationData.checkInDate;
        delete reservationData.checkOutDate;

        await createReservation(reservationData);
        toast.success('Reservation created successfully');
      } else {
        const blockData = {
          roomId,
          roomNumber,
          roomType,
          reason: blockFormData.reason,
          notes: blockFormData.notes || '',
          startDate: new Date(blockFormData.fromDate).toISOString(),
          endDate: new Date(blockFormData.toDate).toISOString(),
          status: 'BLOCKED'
        };

        await createBlockedRoom(blockData);
        toast.success('Room blocked successfully');
      }

      onClose();
      
      // Add a small delay before refreshing to ensure the modal is closed and toast is shown
      setTimeout(() => {
        window.location.reload();
      }, 1000);

    } catch (error: any) {
      console.error('Error:', error);
      toast.error(error.response?.data?.message || (bookingType === 'book' ? 'Failed to create reservation' : 'Failed to block room'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAccountChange = (selectedAccountId: any) => {
    setFormData((prevData) => ({
        ...prevData,
        account: selectedAccountId, // Ensure this is set correctly
    }));
};

  const generateInvoiceNumber = () => {
    return `INV-Room-${String(invoiceCount + 1).padStart(3, '0')}`; // Increment the count for the new invoice
};
  
  const calculateTotalPrice = () => {
    if (!formData.checkInDate || !formData.checkOutDate) {
      return formData.rentPerNight; // Return just the nightly rate if dates aren't set
    }

    // Calculate number of nights
    const nights = differenceInCalendarDays(
      new Date(formData.checkOutDate),
      new Date(formData.checkInDate)
    );

    // Calculate base room price for all nights
    const basePrice = formData.rentPerNight * nights;
    console.log('Base Price calculation:', {
      rentPerNight: formData.rentPerNight,
      nights,
      basePrice
    });

    // Calculate extras
    const barTotal = selectedExtras.barItems.reduce((total, item) => {
      const barItem = barProducts.find(i => i.id === item.id);
      return total + (item.quantity * Number(barItem?.price || 0));
    }, 0);

    const restaurantTotal = selectedExtras.restaurantItems.reduce((total, item) => {
      const restaurantItem = restaurantProducts.find(i => i.id === item.id);
      return total + (item.quantity * Number(restaurantItem?.price || 0));
    }, 0);

    const minibarTotal = selectedExtras.minibarItems.reduce((total, item) => {
      const minibarItem = minibar.find(i => i.id === item.id);
      return total + (item.quantity * (minibarItem?.soldPrice || 0));
    }, 0);

    const inventoryTotal = selectedExtras.inventoryItems.reduce((total, item) => {
      return total + (item.quantity * INVENTORY_PRICE);
    }, 0);

    const grandTotal = basePrice + barTotal + restaurantTotal + minibarTotal + inventoryTotal;
    console.log('Total price breakdown:', {
      basePrice,
      barTotal,
      restaurantTotal,
      minibarTotal,
      inventoryTotal,
      grandTotal,
      nights
    });

    return grandTotal;
  };

  useEffect(() => {
    if (formData.checkInDate && formData.checkOutDate) {
      const nights = differenceInCalendarDays(
        new Date(formData.checkOutDate),
        new Date(formData.checkInDate)
      );
      
      // Calculate total room price based on nights
      const roomTotal = formData.rentPerNight * nights;
      console.log('Calculating total for nights:', {
        rentPerNight: formData.rentPerNight,
        nights,
        roomTotal
      });

      setFormData(prev => ({
        ...prev,
        totalPrice: roomTotal,
        paidPrice: roomTotal
      }));
    }
  }, [formData.checkInDate, formData.checkOutDate, formData.rentPerNight]);

  const handleQuantityChange = (category: string, itemId: string, newQuantity: number) => {
    console.log('Handling quantity change:', { category, itemId, newQuantity });

    if (!itemId) return;

    // Ensure newQuantity is not negative
    const validQuantity = Math.max(0, newQuantity);

    setSelectedExtras(prev => {
      const categoryItems = prev[category];
      const existingItemIndex = categoryItems.findIndex(item => item.id === itemId);
      
      let updatedItems;
      if (existingItemIndex >= 0) {
        // Update existing item with the new quantity
        updatedItems = categoryItems.map(item => 
          item.id === itemId 
            ? { ...item, quantity: validQuantity }
            : item
        );
      } else if (validQuantity > 0) {
        // Add new item only if quantity is greater than 0
        const itemDetails = category === 'barItems' 
          ? barProducts.find(item => item.id === itemId)
          : restaurantProducts.find(item => item.id === itemId);

        if (itemDetails) {
          updatedItems = [
            ...categoryItems,
            {
              id: itemId,
              name: itemDetails.name,
              quantity: validQuantity,
              price: Number(itemDetails.price)
            }
          ];
        } else {
          updatedItems = categoryItems;
        }
      } else {
        // Remove item if quantity is 0
        updatedItems = categoryItems.filter(item => item.id !== itemId);
      }

      const newExtras = {
        ...prev,
        [category]: updatedItems
      };

      // Calculate new total
      const newTotal = calculateTotalPrice();
      console.log('New Total after quantity change:', newTotal);
      
      setFormData(prevForm => ({
        ...prevForm,
        totalPrice: newTotal
      }));

      return newExtras;
    });
  };

  const renderExtrasStep = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {/* Bar Orders Section */}
      <div className="bg-seashell rounded-lg p-4 max-w-xs mx-auto">
        <h3 className="text-base font-medium text-hazel-green mb-2 flex items-center">
          <span className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center mr-2">
            <span className="text-sm text-hazel-green">1</span>
          </span>
          Bar Orders
        </h3>
        <div className="grid grid-cols-1 gap-2">
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Select Item
            </label>
            <select 
            className="w-full border border-grey-goose rounded-md px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-hazel-green bg-white"
            onChange={(e) => handleQuantityChange('barItems', e.target.value, 1)}
            disabled={isLoading}
          >
            <option value="">Choose a bar item</option>
            {barProducts.map(item => (
              <option key={item.id} value={item.id}>
                {item.name} - €{Number(item.price).toFixed(2)}
              </option>
            ))}
          </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Selected Items
            </label>
            <div className="space-y-1">
              {selectedExtras.barItems.map(item => {
                const barItem = barProducts.find(i => i.id === item.id);
                return (
                  <div key={item.id} className="flex items-center justify-between bg-white p-1 rounded-md border border-grey-goose">
                    <span className="text-sm text-bluish-grey">{barItem?.name}</span>
                    <div className="flex items-center space-x-1">
                      <input
                        type="number"
                        min="1"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(
                          'barItems',
                          item.id,
                          parseInt(e.target.value) || 0
                        )}
                        className="w-12 px-1 py-1 text-sm border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                      />
                      <button
                        onClick={() => handleQuantityChange('barItems', item.id, 0)}
                        className="text-watermelon hover:text-watermelon/80 p-1"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
  
      {/* Restaurant Orders Section */}
      <div className="bg-seashell rounded-lg p-4 max-w-xs mx-auto">
        <h3 className="text-base font-medium text-hazel-green mb-2 flex items-center">
          <span className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center mr-2">
            <span className="text-sm text-hazel-green">2</span>
          </span>
          Restaurant Orders
        </h3>
        <div className="grid grid-cols-1 gap-2">
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Select Item
            </label>
            <select 
              className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-hazel-green bg-white"
              onChange={(e) => handleQuantityChange('restaurantItems', e.target.value, 1)}
              disabled={isLoading}
            >
              <option value="">Choose a restaurant item</option>
              {!isLoading && restaurantProducts && restaurantProducts.map(item => (
                <option key={item.id} value={item.id}>
                  {item.name} - €{Number(item.price).toFixed(2)}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Selected Items
            </label>
            <div className="space-y-2">
              {selectedExtras.restaurantItems.map(item => {
                const restaurantItem = restaurantProducts.find(i => i.id === item.id);
                return (
                  <div key={item.id} className="flex items-center justify-between bg-white p-2 rounded-md border border-grey-goose">
                    <span className="text-sm text-bluish-grey">{restaurantItem?.name}</span>
                    <div className="flex items-center space-x-2">
                      <input
                        type="number"
                        min="1"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(
                          'restaurantItems',
                          item.id,
                          parseInt(e.target.value) || 0
                        )}
                        className="w-16 px-2 py-1 text-sm border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                      />
                      <button
                        onClick={() => handleQuantityChange('restaurantItems', item.id, 0)}
                        className="text-watermelon hover:text-watermelon/80 p-1"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
  
      {/* Extra Inventory Section */}
      <div className="bg-seashell rounded-lg p-4 max-w-xs mx-auto">
        <h3 className="text-base font-medium text-hazel-green mb-2 flex items-center">
          <span className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center mr-2">
            <span className="text-sm text-hazel-green">3</span>
          </span>
          Room Inventory
        </h3>
        <div className="grid grid-cols-1 gap-2">
          {inventory.filter(item => item.item.trim() !== '').map(item => (
            <div key={item.item} className="flex items-center justify-between bg-white p-2 rounded-md border border-grey-goose">
              <div className="flex flex-col">
                <span className="text-sm text-bluish-grey">{item.item}</span>
                <div className="flex items-center space-x-2">
                  <span className="text-xs text-gray-500">
                    Included: {item.quantity}
                  </span>
                  {item.goodCondition ? (
                    <span className="text-xs text-green-500">Good Condition</span>
                  ) : (
                    <span className="text-xs text-red-500">Poor Condition</span>
                  )}
                </div>
                {(selectedExtras.inventoryItems.find(i => i.id === item.item)?.quantity || 0) > item.quantity && (
                  <span className="text-xs text-orange-500">
                    Additional items: {(selectedExtras.inventoryItems.find(i => i.id === item.item)?.quantity || 0) - item.quantity} 
                    (€{((selectedExtras.inventoryItems.find(i => i.id === item.item)?.quantity || 0) - item.quantity) * INVENTORY_PRICE})
                  </span>
                )}
              </div>
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  min="0"
                  value={selectedExtras.inventoryItems.find(i => i.id === item.item)?.quantity || 0}
                  onChange={(e) => handleQuantityChange('inventoryItems', item.item, parseInt(e.target.value) || 0)}
                  className="w-16 px-2 py-1 text-sm border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
  
      {/* Minibar Items Section */}
      <div className="bg-seashell rounded-lg p-4 max-w-xs mx-auto">
      <h3 className="text-base font-medium text-hazel-green mb-2 flex items-center">
        <span className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center mr-2">
          <span className="text-sm text-hazel-green">4</span>
        </span>
        Minibar Items
      </h3>
      <div className="grid grid-cols-1 gap-2 max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent pr-2">
        {minibar.map(item => {
          const selectedItem = selectedExtras.minibarItems.find(si => si.id === item.id);
          return (
            <div key={item.id} className="flex items-center justify-between bg-white p-2 rounded-md border border-grey-goose">
              <div className="flex flex-col mx-4">
                <span className="text-sm text-bluish-grey">{item.name}</span>
                <span className="text-xs text-gray-500">€{Number(item.soldPrice).toFixed(2)} each</span>
              </div>
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  min="0"
                  value={selectedItem?.quantity || 0}
                  onChange={(e) => handleQuantityChange('minibarItems', item.id, parseInt(e.target.value) || 0)}
                  className="w-16 px-2 py-1 text-sm border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>

  
      {/* Order Summary */}
      <div className="bg-tealish-blue rounded-lg p-6">
        <h3 className="text-base font-medium text-hazel-green mb-4">Order Summary</h3>
        <div className="space-y-2">
          {/* Base Room Price */}
          <div className="flex justify-between text-sm">
            <span className="text-bluish-grey">Room Total:</span>
            <span className="font-medium text-hazel-green">€{formData.rentPerNight * differenceInCalendarDays(new Date(formData.checkOutDate), new Date(formData.checkInDate))}</span>
          </div>

          {/* Bar Items Total */}
          {selectedExtras.barItems.length > 0 && (
            <div className="flex justify-between text-sm">
              <span className="text-bluish-grey">Bar Items:</span>
              <span className="font-medium text-hazel-green">
                €{selectedExtras.barItems.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2)}
              </span>
            </div>
          )}

          {/* Restaurant Items Total */}
          {selectedExtras.restaurantItems.length > 0 && (
            <div className="flex justify-between text-sm">
              <span className="text-bluish-grey">Restaurant Items:</span>
              <span className="font-medium text-hazel-green">
                €{selectedExtras.restaurantItems.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2)}
              </span>
            </div>
          )}

          {/* Grand Total */}
          <div className="pt-2 border-t border-tealish-blue/20">
            <div className="flex justify-between text-sm font-medium">
              <span className="text-bluish-grey">Total Amount:</span>
              <span className="text-hazel-green">€{calculateTotalPrice().toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
  const renderBookForm = () => (
    <div className="flex flex-col space-y-2 max-h-[60vh] overflow-y-auto px-2 pb-2">
      {/* Personal Information - More Compact */}
      <div className="bg-gray-50 p-3 rounded-lg shadow-sm">
        <h3 className="text-xs font-semibold text-gray-700 mb-1">Personal Information</h3>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-1">
          <div>
            <label className="block text-xs font-medium text-gray-600">Title</label>
            <select
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            >
              <option value="">Select</option>
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Dr.">Dr.</option>
            </select>
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Surname <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.surname}
              onChange={(e) => setFormData({ ...formData, surname: e.target.value })}
              required
            />
          </div>
          <div className="col-span-2">
            <label className="block text-xs font-medium text-gray-600">Personal Number</label>
            <input
              type="text"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.personalNumber}
              onChange={(e) => setFormData({ ...formData, personalNumber: e.target.value })}
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">Phone</label>
            <input
              type="tel"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.phoneNumber}
              onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
            />
          </div>
        </div>
      </div>

      {/* Booking Details - More Compact */}
      <div className="bg-gray-50 p-3 rounded-lg shadow-sm">
        <h3 className="text-xs font-semibold text-gray-700 mb-2">Booking Details</h3>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Check-In <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.checkInDate}
              onChange={(e) => setFormData({ ...formData, checkInDate: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Check-Out <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.checkOutDate}
              onChange={(e) => setFormData({ ...formData, checkOutDate: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Status <span className="text-red-500">*</span>
            </label>
            <select
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.status}
              onChange={(e) => setFormData({ ...formData, status: e.target.value })}
              required
            >
              <option value="">Select</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Pending">Pending</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Adults <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.adults}
              onChange={(e) => setFormData({ ...formData, adults: parseInt(e.target.value) })}
              required
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">Children</label>
            <input
              type="number"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.children}
              onChange={(e) => setFormData({ ...formData, children: parseInt(e.target.value) })}
            />
          </div>
        </div>
      </div>

      {/* Extra Beds - More Compact */}
      <div className="bg-gray-50 p-3 rounded-lg shadow-sm">
        <h3 className="text-xs font-semibold text-gray-700 mb-2">Extra Beds</h3>
        <div className="grid grid-cols-3 gap-2">
          {Object.values(AgeGroup).map((ageGroup) => (
            <div key={ageGroup} className="bg-white p-2 rounded border">
              <div className="flex items-center justify-between">
                <span className="text-xs font-medium">{ageGroup}</span>
                <input
                  type="number"
                  min="0"
                  className="w-16 h-7 text-xs border rounded px-2"
                  value={extraBeds.find(bed => bed.ageGroup === ageGroup)?.quantity || 0}
                  onChange={(e) => {
                    const newBeds = [...extraBeds];
                    const bedIndex = newBeds.findIndex(bed => bed.ageGroup === ageGroup);
                    const quantity = parseInt(e.target.value) || 0;
                    
                    if (bedIndex >= 0) {
                      newBeds[bedIndex] = { ...newBeds[bedIndex], quantity };
                    } else {
                      newBeds.push({ ageGroup, quantity });
                    }
                    
                    setExtraBeds(newBeds);
                  }}
                />
              </div>
              {extraBeds.find(bed => bed.ageGroup === ageGroup)?.quantity > 0 && (
                <div className="text-xs text-gray-500 mt-1">
                  Total: €{calculateExtraBedsPrice([{ ageGroup, quantity: extraBeds.find(bed => bed.ageGroup === ageGroup)?.quantity || 0 }])}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Payment Information - More Compact */}
      <div className="bg-gray-50 p-3 rounded-lg shadow-sm">
        <h3 className="text-xs font-semibold text-gray-700 mb-2">Payment Information</h3>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Payment Type <span className="text-red-500">*</span>
            </label>
            <select
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              onChange={(e) => setFormData({ ...formData, paymentType: e.target.value })}
              required
            >
              <option value="">Select</option>
              <option value="CREDIT_CARD">Credit Card</option>
              <option value="CASH">Cash</option>
              <option value="BANK_TRANSFER">Bank Transfer</option>
            </select>
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">Deposit</label>
            <input
              type="number"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.deposit}
              onChange={(e) => setFormData({ ...formData, deposit: Math.max(0, parseFloat(e.target.value) || 0) })}
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">Discount</label>
            <input
              type="number"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.discount}
              onChange={(e) => setFormData({ ...formData, discount: Math.max(0, parseFloat(e.target.value) || 0) })}
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">Total Price</label>
            <input
              type="number"
              className="w-full h-8 text-xs border rounded px-2 bg-gray-100"
              value={formData.totalPrice}
              readOnly
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">Paid Price</label>
            <input
              type="number"
              className="w-full h-8 text-xs border rounded px-2 bg-gray-100"
              value={formData.paidPrice}
              readOnly
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">Tax</label>
            <select
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={formData.tax}
              onChange={(e) => setFormData({ ...formData, tax: e.target.value })}
            >
              <option value="">Select</option>
              <option value="NoTax">No Tax</option>
              <option value="VAT">VAT (20%)</option>
              <option value="GST">GST (18%)</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );

  const renderHoldForm = () => (
    <div className="space-y-4 p-4">
      {/* Basic Information */}
      <div className="bg-gray-50 p-3 rounded-lg shadow-sm">
        <h3 className="text-xs font-semibold text-gray-700 mb-2">Basic Information</h3>
        <div className="grid grid-cols-3 gap-2">
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Check-In <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              defaultValue={selectedDate.toISOString().split('T')[0]}
              onChange={(e) => setFormData({ ...formData, checkInDate: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Check-Out <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              onChange={(e) => setFormData({ ...formData, checkOutDate: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">Duration</label>
            <input
              type="text"
              value="1 Nights"
              readOnly
              className="w-full h-8 text-xs border rounded px-2 bg-gray-50"
            />
          </div>
        </div>
      </div>

      {/* Guest Information */}
      <div className="bg-gray-50 p-3 rounded-lg shadow-sm">
        <h3 className="text-xs font-semibold text-gray-700 mb-2">Guest Information</h3>
        <div className="grid grid-cols-3 gap-2">
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              placeholder="Enter Name"
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Phone <span className="text-red-500">*</span>
            </label>
            <input
              type="tel"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              placeholder="Enter Phone Number"
              onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              placeholder="Enter Email"
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
            />
          </div>
        </div>
      </div>

      {/* Hold Details */}
      <div className="bg-gray-50 p-3 rounded-lg shadow-sm">
        <h3 className="text-xs font-semibold text-gray-700 mb-2">Hold Details</h3>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Hold Booking Till <span className="text-red-500">*</span>
            </label>
            <div className="grid grid-cols-2 gap-2">
              <input
                type="date"
                className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
                onChange={(e) => setFormData({ ...formData, holdBookingTill: e.target.value })}
                required
              />
              <input
                type="time"
                className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
                onChange={(e) => {
                  const date = formData.holdBookingTill ? new Date(formData.holdBookingTill) : new Date();
                  const [hours, minutes] = e.target.value.split(':');
                  date.setHours(parseInt(hours), parseInt(minutes));
                  setFormData({ ...formData, holdBookingTill: date.toISOString() });
                }}
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Enquiry App Id
            </label>
            <input
              type="text"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              placeholder="Enter Enquiry ID"
              onChange={(e) => setFormData({ ...formData, enquiryAppId: e.target.value })}
            />
          </div>
        </div>
      </div>

      {/* Notes */}
      <div className="bg-gray-50 p-3 rounded-lg shadow-sm">
        <h3 className="text-xs font-semibold text-gray-700 mb-2">Additional Information</h3>
        <div>
          <label className="block text-xs font-medium text-gray-600">
            Notes
          </label>
          <textarea
            className="w-full text-xs border rounded px-2 py-1 focus:ring-1 focus:ring-indigo-500"
            rows={3}
            placeholder="Enter any additional notes"
            onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
          />
        </div>
      </div>
    </div>
  );

  const renderBlockForm = () => (
    <div className="space-y-4 p-4">
      {/* Block Details */}
      <div className="bg-gray-50 p-3 rounded-lg shadow-sm">
        <h3 className="text-xs font-semibold text-gray-700 mb-2">Block Details</h3>
        <div className="grid grid-cols-3 gap-2">
          <div>
            <label className="block text-xs font-medium text-gray-600">
              From Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={blockFormData.fromDate}
              onChange={(e) => setBlockFormData({ ...blockFormData, fromDate: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">
              To Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={blockFormData.toDate}
              onChange={(e) => setBlockFormData({ ...blockFormData, toDate: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">Duration</label>
            <input
              type="text"
              value="1 Nights"
              readOnly
              className="w-full h-8 text-xs border rounded px-2 bg-gray-50"
            />
          </div>
        </div>
      </div>

      {/* Block Reason */}
      <div className="bg-gray-50 p-3 rounded-lg shadow-sm">
        <h3 className="text-xs font-semibold text-gray-700 mb-2">Block Reason</h3>
        <div className="space-y-2">
          <div>
            <label className="block text-xs font-medium text-gray-600">
              Reason <span className="text-red-500">*</span>
            </label>
            <select
              className="w-full h-8 text-xs border rounded px-2 focus:ring-1 focus:ring-indigo-500"
              value={blockFormData.reason}
              onChange={(e) => setBlockFormData({ ...blockFormData, reason: e.target.value })}
              required
            >
              <option value="">Select a reason</option>
              <option value="MAINTENANCE">Maintenance</option>
              <option value="RENOVATION">Renovation</option>
              <option value="OTHER">Other</option>
            </select>
          </div>
          <div>
            <label className="block text-xs font-medium text-gray-600">Notes</label>
            <textarea
              className="w-full text-xs border rounded px-2 py-1 focus:ring-1 focus:ring-indigo-500"
              rows={3}
              value={blockFormData.notes}
              onChange={(e) => setBlockFormData({ ...blockFormData, notes: e.target.value })}
              placeholder="Enter any additional notes about blocking this room"
            />
          </div>
        </div>
      </div>
    </div>
  );


  // Function to calculate the duration between two dates
  const getDuration = (fromDate, toDate) => {
    const start = new Date(fromDate);
    const end = new Date(toDate);
    const timeDiff = end.getTime() - start.getTime();
    return timeDiff > 0 ? timeDiff / (1000 * 3600 * 24) : 0; // Calculate duration in days
  };

  // Update inventory quantity handler
  const updateInventoryQuantity = (itemId: string, newQuantity: number) => {
    console.log('Updating inventory quantity:', { itemId, newQuantity });
    
    setSelectedExtras(prev => {
      const updatedInventoryItems = [...prev.inventoryItems];
      const existingItemIndex = updatedInventoryItems.findIndex(item => item.id === itemId);
      
      if (existingItemIndex >= 0) {
        // Update existing item
        updatedInventoryItems[existingItemIndex] = {
          ...updatedInventoryItems[existingItemIndex],
          quantity: newQuantity
        };
      } else if (newQuantity > 0) {
        // Add new item
        updatedInventoryItems.push({
          id: itemId,
          quantity: newQuantity,
          price: INVENTORY_PRICE
        });
      }

      const newExtras = {
        ...prev,
        inventoryItems: updatedInventoryItems.filter(item => item.quantity > 0)
      };

      // Calculate new total
      const newTotal = calculateTotalPrice();
      setFormData(prevForm => ({
        ...prevForm,
        totalPrice: newTotal
      }));

      return newExtras;
    });
  };

  const incrementQuantity = (itemId: string, category: 'barItems' | 'restaurantItems' | 'minibar' | 'inventory') => {
    const items = selectedExtras[category];
    const currentQuantity = items.find(item => item.id === itemId)?.quantity || 0;
    
    if (category === 'barItems' || category === 'restaurantItems') {
      handleQuantityChange(category, itemId, currentQuantity + 1);
    } else if (category === 'minibar') {
      updateMinibarQuantity(itemId, currentQuantity + 1);
    } else {
      updateInventoryQuantity(itemId, currentQuantity + 1);
    }
  };

  const decrementQuantity = (itemId: string, category: 'barItems' | 'restaurantItems' | 'minibar' | 'inventory') => {
    const items = selectedExtras[category];
    const currentQuantity = items.find(item => item.id === itemId)?.quantity || 0;
    
    if (currentQuantity > 0) {
      if (category === 'barItems' || category === 'restaurantItems') {
        handleQuantityChange(category, itemId, currentQuantity - 1);
      } else if (category === 'minibar') {
        updateMinibarQuantity(itemId, currentQuantity - 1);
      } else {
        updateInventoryQuantity(itemId, currentQuantity - 1);
      }
    }
  };

  // Add extraBeds state


  // Add calculateExtraBedsPrice function
  const calculateExtraBedsPrice = (beds) => {
    return beds.reduce((total, bed) => {
      const quantity = Number(bed.quantity) || 0;
      switch (bed.ageGroup) {
        case 'INFANT':
          return total + 0;
        case 'CHILD':
          return total + (15 * quantity);
        case 'ADULT':
          return total + (25 * quantity);
        default:
          return total;
      }
    }, 0);
  };

  const updateTotalPrice = () => {
    const basePrice = Number(price) || 0;
    const extraBedsPrice = calculateExtraBedsPrice(extraBeds);
    const discountAmount = basePrice * (Number(formData.discount || 0) / 100);
    const totalPrice = basePrice + extraBedsPrice - discountAmount;
    
    setFormData(prev => ({
      ...formData,
      totalPrice: totalPrice
    }));
  };

  useEffect(() => {
    const calculateExtraBedsPrice = (beds) => {
      return beds.reduce((total, bed) => {
        const quantity = Number(bed.quantity) || 0;
        switch (bed.ageGroup) {
          case AgeGroup.INFANT:
            return total + 0;
          case AgeGroup.CHILD:
            return total + (15 * quantity);
          case AgeGroup.ADULT:
            return total + (25 * quantity);
          default:
            return total;
        }
      }, 0);
    };

    const basePrice = price;
    const discountAmount = basePrice * (formData.discount / 100);
    const extraBedsAmount = calculateExtraBedsPrice(extraBeds);
    const extrasAmount = Object.values(selectedExtras).reduce((total, items: any[]) => {
      return total + items.reduce((sum, item) => sum + ((item.price || 0) * (item.quantity || 0)), 0);
    }, 0);

    const total = basePrice - discountAmount + extraBedsAmount + extrasAmount;
    setTotalPrice(total);
    setFormData(prev => ({
      ...prev,
      totalPrice: total,
      paidPrice: total
    }));
  }, [price, formData.discount, extraBeds, selectedExtras]);

  return (
  <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
    <div className="relative bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] flex flex-col">
      {/* Header */}
      <div className="sticky top-0 bg-white p-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-900">
            {roomName} - {bookingType === 'book' ? 'Add Reservation' : 'Block Room'}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X size={20} />
          </button>
        </div>

        {/* Booking Type Tabs */}
        <div className="flex space-x-4 mt-4">
          {['book', 'block'].map((type) => (
            <button
              key={type}
              type="button"
              onClick={() => setBookingType(type as 'book' | 'block')}
              className={`px-3 py-1.5 text-sm font-medium capitalize
                ${bookingType === type
                  ? 'text-hazel-green border-b-2 border-hazel-green'
                  : 'text-gray-500 hover:text-gray-700'}`}
            >
              {type}
            </button>
          ))}
        </div>
      </div>

      {/* Content Area */}
      <div className="flex-1 overflow-auto">
        <form onSubmit={handleSubmit} className="h-full flex flex-col">
          {bookingType === 'book' && renderBookForm()}
          {bookingType === 'block' && renderBlockForm()}
          
          {/* Footer with Submit Button */}
          <div className="sticky bottom-0 bg-white p-4 border-t border-gray-200">
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-hazel-green hover:bg-hazel-green/90 rounded-md disabled:opacity-50"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  bookingType === 'book' ? 'Create Reservation' : 'Block Room'
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
);
};

export default ReservationModal;