import api from './api';

// Get main book entries
export const getMainBook = (params) => {
  const { startDate, endDate, dateType = 'document' } = params;
  return api.get('/api/main-book', {
    params: {
      startDate,
      endDate,
      dateType,
    },
  });
};

// Export main book
export const exportMainBook = (params) => {
  const { startDate, endDate, dateType = 'document', format = 'pdf' } = params;
  return api.get('/api/main-book/export', {
    params: {
      startDate,
      endDate,
      dateType,
      format,
    },
  });
};
