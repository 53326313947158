import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../../../components/loader';
import { SubmitButton, CancelButton } from '../../../../../components/buttons';
import CustomInput from '../../../../../components/custom-input';
import { createAsset, updateAsset } from '../../../../../api/accounting';

function AssetForm({ isEdit, asset }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Validation schema for asset form
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Asset name is required*'),
    value: Yup.number().positive('Value must be positive').required('Asset value is required*'),
    dateAcquired: Yup.date().required('Date acquired is required*'),
  });

  // Display loading spinner if form is in a loading state
  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
      <Formik
        initialValues={asset}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          setLoading(true);
          try {
            if (isEdit) {
              // Update existing asset
              await updateAsset(asset.id, values);
            } else {
              // Create new asset
              await createAsset(values);
              navigate('/accounting/assets');  // Redirect to assets list
            }
            setSubmitting(false);
          } catch (err) {
            setErrors({
              submit: err?.response?.data
            });
            setSubmitting(false);
          } finally {
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit} className='px-7 py-5'>
            <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
              <CustomInput
                type='text'
                name='name'
                label='Asset Name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.name && touched.name}
                errorMessage={errors.name}
              />
              <CustomInput
                type='number'
                name='value'
                label='Asset Value'
                value={values.value}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.value && touched.value}
                errorMessage={errors.value}
              />
              <CustomInput
                type='date'
                name='dateAcquired'
                label='Date Acquired'
                value={values.dateAcquired}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.dateAcquired && touched.dateAcquired}
                errorMessage={errors.dateAcquired}
              />
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
            </div>

            <div className='flex justify-end items-center w-full mt-4 text-sm'>
              <CancelButton onCancel={() => navigate('/accounting/assets')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/2 md:w-1/6'
                title={`${isEdit ? 'Edit Asset' : 'Create Asset'}`}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AssetForm;
