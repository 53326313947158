import React, { useRef } from "react";
import { IconFileSpreadsheet, IconPdf } from "@tabler/icons-react";
import FinancialStatement from "./FinancialStatement";
import PageTitleWithButtons from "../../../../../components/PageTitleWithButtons";
import html2pdf from "html2pdf.js";  // For PDF export
import * as XLSX from "xlsx";  // For Excel export

const FinancialStateContainer = () => {
  const financialStateRef = useRef(null);

  // Example financial statement data
  const dynamicFinancialStateData = {
    assets: {
      longTerm: [
        { name: "Licenca", value2021: "65,000 €", value2020: "65,000 €" },
        { name: "Toka", value2021: "400,000 €", value2020: "400,000 €" },
        { name: "Ndërtesa", value2021: "110,000 €", value2020: "110,000 €" },
        { name: "Pajisjet", value2021: "50,000 €", value2020: "60,000 €" },
        { name: "Zhvlerësimi i Akumuluar", value2021: "-76,000 €", value2020: "-124,995 €" },
        { name: "Makineritë", value2021: "430,000 €", value2020: "446,000 €" },
      ],
      shortTerm: [
        { name: "Paraja", value2021: "520,500 €", value2020: "1,500,825 €" },
        { name: "LL/A", value2021: "150,000 €", value2020: "197,200 €" },
        { name: "Mallrat", value2021: "350,000 €", value2020: "58,885 €" },
        { name: "Parapagimet për sigurime", value2021: "65,000 €", value2020: "78,332 €" },
        { name: "Investimet financiare", value2021: "620,000 €", value2020: "620,000 €" },
      ],
      totals: {
        longTerm: { value2021: "979,000 €", value2020: "956,005 €" },
        shortTerm: { value2021: "1,705,500 €", value2020: "2,455,242 €" },
      }
    },
    liabilities: {
      longTerm: [
        { name: "Kreditë afatgjate", value2021: "200,000 €", value2020: "300,000 €" },
      ],
      shortTerm: [
        { name: "Llogaria e Pagueshme", value2021: "100,000 €", value2020: "132,600 €" },
        { name: "Pagat e Pagueshme", value2021: "43,000 €", value2020: "43,000 €" },
        { name: "Kredit afatshkurta", value2021: "80,000 €", value2020: "80,000 €" },
        { name: "Interesi i pagueshëm", value2021: "25,000 €", value2020: "25,000 €" },
        { name: "Tatimi i pagueshëm", value2021: "47,000 €", value2020: "97,297 €" },
        { name: "Detyrimet komunale", value2021: "5,000 €", value2020: "5,000 €" },
        { name: "TVSH-ja për pagesë", value2021: "20,000 €", value2020: "-" },
        { name: "Avancet e pagueshme", value2021: "-", value2020: "149,175 €" },
        { name: "Obligacionet e pagueshme", value2021: "700,500 €", value2020: "15,000 €" },
      ],
      totals: {
        shortTerm: { value2021: "1,020,500 €", value2020: "1,174,572 €" },
        totalLiabilities: { value2021: "1,220,500 €", value2020: "1,474,572 €" },
      },
    },
    equity: {
      totalEquity: { value2021: "1,464,000 €", value2020: "1,936,675 €" },
      profit: { value2021: "300,000 €", value2020: "732,675 €" },
    },
    totals: {
      totalAssets: { value2021: "2,684,500 €", value2020: "3,411,247 €" },
      totalLiabilitiesAndEquity: { value2021: "2,684,500 €", value2020: "3,411,247 €" }
    }
  };

  // Function to handle PDF export
  const handleExportPDF = () => {
    const element = financialStateRef.current;
    const options = {
      margin: 0.5,
      filename: "financial-state.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(element).set(options).save();
  };

  // Function to handle Excel export
  const handleExportExcel = () => {
    const data = [
      ["Category", "Name", "2021", "2020"],
      ["Asetet", "", "", ""],
      ...dynamicFinancialStateData.assets.map((item) => [
        "", item.name, item.value2021, item.value2020,
      ]),
      ["Gjithsej Asetet", "", dynamicFinancialStateData.totals.assets.value2021, dynamicFinancialStateData.totals.assets.value2020],
      ["Detyrimet dhe Kapitali", "", "", ""],
      ...dynamicFinancialStateData.liabilities.map((item) => [
        "", item.name, item.value2021, item.value2020,
      ]),
      ["Gjithsej Detyrimet", "", dynamicFinancialStateData.totals.liabilities.value2021, dynamicFinancialStateData.totals.liabilities.value2020],
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Financial Statement");
    XLSX.writeFile(wb, "financial-state.xlsx");
  };

  return (
    <div className='p-10 bg-seashell'>
      <PageTitleWithButtons
        title="Pasqyra e Gjendjes Financiare"
        icon={<IconFileSpreadsheet className='text-hazel-green font-bold mx-4' size={24} />}
        buttons={[
          <div key="1" className='flex gap-x-3'>
            <button
              className='flex items-center text-white rounded-md bg-sage px-4 py-2.5'
              onClick={handleExportPDF}  // Call the PDF export function
            >
              <IconPdf className='mr-2' size={20} />
              Download PDF
            </button>
            <button
              onClick={handleExportExcel}  // Call the Excel export function
              className='flex items-center text-white rounded-md bg-sage px-4 py-2.5'
            >
              <IconFileSpreadsheet className='mr-2' size={20} />
              Excel
            </button>
          </div>
        ]}
      />

      <div ref={financialStateRef}>
        {/* Rendering the FinancialStatement component and passing dynamic data */}
        <FinancialStatement data={dynamicFinancialStateData} />
      </div>
    </div>
  );
};

export default FinancialStateContainer;
