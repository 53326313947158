import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Input } from './shadCN/Input.tsx';
import { Label } from './shadCN/Label.tsx';
import { Button } from './shadCN/Button.tsx';
import { Card } from './shadCN/Card.tsx';
import { createSupplier } from '../api/accounting';

const modalVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: {
      type: "spring",
      duration: 0.5,
      bounce: 0.3
    }
  },
  exit: { 
    opacity: 0, 
    scale: 0.95,
    transition: {
      duration: 0.2
    }
  }
};

const overlayVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: {
      duration: 0.3
    }
  },
  exit: { 
    opacity: 0,
    transition: {
      duration: 0.2
    }
  }
};

const formSectionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (custom) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: custom * 0.1,
      duration: 0.4
    }
  })
};

const SupplierModal = ({ isOpen, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    ubin: Yup.string().required('UBIN is required*'),
    businessName: Yup.string().min(3).required('Business name is required*'),
    tradeName: Yup.string().min(3).required('Trade name is required*'),
    businessType: Yup.string().required('Business type is required*'),
    municipality: Yup.string().required('Municipality is required*'),
    address: Yup.string().required('Address is required*'),
    email: Yup.string().email('Invalid email format').required('Email is required*'),
    phone: Yup.string().required('Phone number is required*'),
    representative: Yup.string(),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setLoading(true);
      const response = await createSupplier(values);
      toast.success('Supplier created successfully!');
      resetForm();
      onSuccess(response.data);
      onClose();
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error creating supplier');
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50"
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={onClose}
          />
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-6"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <Card className="bg-white rounded-xl shadow-2xl w-full max-w-3xl max-h-[90vh] overflow-hidden border-0">
              <div className="bg-gradient-to-r from-hazel-green/90 to-sage/90 p-6 flex justify-between items-center">
                <div>
                  <h2 className="text-2xl font-semibold text-white mb-1">Add New Supplier</h2>
                  <p className="text-white/80 text-sm">Fill in the supplier details below</p>
                </div>
                <button
                  onClick={onClose}
                  className="text-white/80 hover:text-white transition-colors p-2 hover:bg-white/10 rounded-full"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              
              <div className="p-6 overflow-y-auto max-h-[calc(90vh-8rem)]">
                <Formik
                  initialValues={{
                    ubin: '',
                    businessName: '',
                    tradeName: '',
                    businessType: '',
                    municipality: '',
                    address: '',
                    email: '',
                    phone: '',
                    representative: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="space-y-8">
                      <motion.div 
                        variants={formSectionVariants}
                        custom={0}
                        initial="hidden"
                        animate="visible"
                        className="space-y-4"
                      >
                        <h3 className="text-lg font-medium text-hazel-green mb-4">Business Identification</h3>
                        <div className="p-4 bg-grey-goose/5 rounded-lg space-y-4">
                          <div>
                            <Label htmlFor="ubin" className="text-bluish-grey font-medium">UBIN</Label>
                            <Input
                              id="ubin"
                              name="ubin"
                              value={values.ubin}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`mt-1 ${errors.ubin && touched.ubin ? 'border-salmon-pink' : ''}`}
                              placeholder="Enter business identification number"
                            />
                            {errors.ubin && touched.ubin && (
                              <p className="text-salmon-pink text-sm mt-1">{errors.ubin}</p>
                            )}
                          </div>

                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                              <Label htmlFor="businessName" className="text-bluish-grey font-medium">Business Name</Label>
                              <Input
                                id="businessName"
                                name="businessName"
                                value={values.businessName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`mt-1 ${errors.businessName && touched.businessName ? 'border-salmon-pink' : ''}`}
                                placeholder="Official business name"
                              />
                              {errors.businessName && touched.businessName && (
                                <p className="text-salmon-pink text-sm mt-1">{errors.businessName}</p>
                              )}
                            </div>

                            <div>
                              <Label htmlFor="tradeName" className="text-bluish-grey font-medium">Trade Name</Label>
                              <Input
                                id="tradeName"
                                name="tradeName"
                                value={values.tradeName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`mt-1 ${errors.tradeName && touched.tradeName ? 'border-salmon-pink' : ''}`}
                                placeholder="Trading or brand name"
                              />
                              {errors.tradeName && touched.tradeName && (
                                <p className="text-salmon-pink text-sm mt-1">{errors.tradeName}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </motion.div>

                      <motion.div
                        variants={formSectionVariants}
                        custom={1}
                        initial="hidden"
                        animate="visible"
                        className="space-y-4"
                      >
                        <h3 className="text-lg font-medium text-hazel-green mb-4">Business Details</h3>
                        <div className="p-4 bg-grey-goose/5 rounded-lg space-y-4">
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                              <Label htmlFor="businessType" className="text-bluish-grey font-medium">Business Type</Label>
                              <Input
                                id="businessType"
                                name="businessType"
                                value={values.businessType}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`mt-1 ${errors.businessType && touched.businessType ? 'border-salmon-pink' : ''}`}
                                placeholder="Type of business"
                              />
                              {errors.businessType && touched.businessType && (
                                <p className="text-salmon-pink text-sm mt-1">{errors.businessType}</p>
                              )}
                            </div>

                            <div>
                              <Label htmlFor="municipality" className="text-bluish-grey font-medium">Municipality</Label>
                              <Input
                                id="municipality"
                                name="municipality"
                                value={values.municipality}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`mt-1 ${errors.municipality && touched.municipality ? 'border-salmon-pink' : ''}`}
                                placeholder="Business municipality"
                              />
                              {errors.municipality && touched.municipality && (
                                <p className="text-salmon-pink text-sm mt-1">{errors.municipality}</p>
                              )}
                            </div>
                          </div>

                          <div>
                            <Label htmlFor="address" className="text-bluish-grey font-medium">Address</Label>
                            <Input
                              id="address"
                              name="address"
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`mt-1 ${errors.address && touched.address ? 'border-salmon-pink' : ''}`}
                              placeholder="Complete business address"
                            />
                            {errors.address && touched.address && (
                              <p className="text-salmon-pink text-sm mt-1">{errors.address}</p>
                            )}
                          </div>
                        </div>
                      </motion.div>

                      <motion.div
                        variants={formSectionVariants}
                        custom={2}
                        initial="hidden"
                        animate="visible"
                        className="space-y-4"
                      >
                        <h3 className="text-lg font-medium text-hazel-green mb-4">Contact Information</h3>
                        <div className="p-4 bg-grey-goose/5 rounded-lg space-y-4">
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                              <Label htmlFor="email" className="text-bluish-grey font-medium">Email</Label>
                              <Input
                                id="email"
                                name="email"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`mt-1 ${errors.email && touched.email ? 'border-salmon-pink' : ''}`}
                                placeholder="business@example.com"
                              />
                              {errors.email && touched.email && (
                                <p className="text-salmon-pink text-sm mt-1">{errors.email}</p>
                              )}
                            </div>

                            <div>
                              <Label htmlFor="phone" className="text-bluish-grey font-medium">Phone</Label>
                              <Input
                                id="phone"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`mt-1 ${errors.phone && touched.phone ? 'border-salmon-pink' : ''}`}
                                placeholder="+1 (555) 000-0000"
                              />
                              {errors.phone && touched.phone && (
                                <p className="text-salmon-pink text-sm mt-1">{errors.phone}</p>
                              )}
                            </div>
                          </div>

                          <div>
                            <Label htmlFor="representative" className="text-bluish-grey font-medium">Representative (Optional)</Label>
                            <Input
                              id="representative"
                              name="representative"
                              value={values.representative}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="mt-1"
                              placeholder="Name of business representative"
                            />
                          </div>
                        </div>
                      </motion.div>

                      <motion.div 
                        variants={formSectionVariants}
                        custom={3}
                        initial="hidden"
                        animate="visible"
                        className="sticky bottom-0 pt-4 mt-8 border-t border-mercury bg-white"
                      >
                        <div className="flex justify-end gap-3">
                          <Button
                            type="button"
                            onClick={onClose}
                            variant="outline"
                            className="border-mercury hover:bg-grey-goose/10"
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            disabled={loading}
                            className="bg-hazel-green hover:bg-sage text-white min-w-[120px]"
                          >
                            {loading ? (
                              <span className="flex items-center gap-2">
                                <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                </svg>
                                Creating...
                              </span>
                            ) : (
                              'Create Supplier'
                            )}
                          </Button>
                        </div>
                      </motion.div>
                    </form>
                  )}
                </Formik>
              </div>
            </Card>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default SupplierModal;
