import React from 'react';
import { motion } from 'framer-motion';
import { FileText, FileSpreadsheet, ClipboardList, HelpCircle, ChevronRight, TrendingUp, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

const TaxesPage = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  const quickActions = [
    {
      title: 'Libri i Shitjeve',
      description: 'Gjenero dhe menaxho raportet e shitjeve në mënyrë efikase',
      icon: <FileText className="w-8 h-8" />,
      href: '/accounting/taxes/sales',
      stats: {
        total: '15,750.00 €',
        label: 'Shitjet e muajit',
        trend: '+12.5%',
        trendUp: true
      },
      color: 'hazel-green'
    },
    {
      title: 'Libri i Blerjeve',
      description: 'Gjenero dhe menaxho raportet e blerjeve në mënyrë të organizuar',
      icon: <FileSpreadsheet className="w-8 h-8" />,
      href: '/accounting/taxes/purchases',
      stats: {
        total: '8,320.00 €',
        label: 'Blerjet e muajit',
        trend: '+8.3%',
        trendUp: true
      },
      color: 'sage'
    },
    {
      title: 'Formulari i TVSH-së',
      description: 'Përgatit dhe dorëzo formularët e TVSH-së',
      icon: <ClipboardList className="w-8 h-8" />,
      href: '/accounting/taxes/vat-form',
      stats: {
        total: '1,480.00 €',
        label: 'TVSH për pagesë',
        trend: '-2.1%',
        trendUp: false
      },
      color: 'salmon-pink'
    }
  ];

  const usefulResources = [
    {
      title: 'Deklarimi Elektronik',
      description: 'Deklarimi Elektronik i Tatimeve në mënyrë elektronike',
      icon: <FileText className="w-6 h-6" />,
      href: '#',
      color: 'sage'
    },
    {
      title: 'Legjislacioni Tatimor',
      description: 'Informacione rreth ligjeve dhe rregulloreve tatimore',
      icon: <FileSpreadsheet className="w-6 h-6" />,
      href: '#',
      color: 'hazel-green'
    },
    {
      title: 'Kontakto Mbështetjen',
      description: 'Na kontaktoni për çdo pyetje ose ndihmë në +383 45 108 656',
      icon: <HelpCircle className="w-6 h-6" />,
      href: '#',
      color: 'bluish-grey'
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-tealish-blue to-seashell/30">
      {/* Header Section with Background */}
      <div className="bg-white border-b border-mercury">
        <div className="max-w-7xl mx-auto px-6 py-8">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center max-w-3xl mx-auto"
          >
            <div className="inline-flex items-center justify-center p-2 bg-dawn-pink rounded-lg mb-4">
              <AlertCircle className="w-5 h-5 text-watermelon mr-2" />
              <span className="text-sm font-medium text-watermelon">Afati i fundit për deklarim: 20 Janar 2025</span>
            </div>
            <h1 className="text-3xl font-semibold text-bluish-grey mb-3">Tatimet</h1>
            <p className="text-grey-goose">
              Gjenero dhe menaxho dokumentet e kërkuara nga Administrata Tatimore e Kosovës
            </p>
          </motion.div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">
        {/* Quick Actions Grid */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="mb-12"
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {quickActions.map((action, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                whileHover={{ scale: 1.02 }}
                className="group"
              >
                <Link
                  to={action.href}
                  className="block h-full bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-200 overflow-hidden"
                >
                  <div className="p-6">
                    <div className="flex items-center justify-between mb-6">
                      <div className={`p-3 rounded-xl bg-${action.color}/10 text-${action.color} group-hover:bg-${action.color}/20 transition-colors duration-200`}>
                        {action.icon}
                      </div>
                      <ChevronRight className={`w-5 h-5 text-${action.color}`} />
                    </div>
                    <h3 className={`text-lg font-medium text-bluish-grey mb-2 group-hover:text-${action.color} transition-colors duration-200`}>
                      {action.title}
                    </h3>
                    <p className="text-grey-goose text-sm mb-4">
                      {action.description}
                    </p>
                    <div className="pt-4 border-t border-mercury">
                      <div className="flex items-center justify-between">
                        <div>
                          <div className={`text-lg font-medium text-${action.color}`}>
                            {action.stats.total}
                          </div>
                          <div className="text-sm text-grey-goose">
                            {action.stats.label}
                          </div>
                        </div>
                        <div className={`flex items-center ${action.stats.trendUp ? 'text-hazel-green' : 'text-salmon-pink'}`}>
                          <TrendingUp className={`w-4 h-4 mr-1 ${!action.stats.trendUp && 'transform rotate-180'}`} />
                          <span className="text-sm font-medium">{action.stats.trend}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* FAQ Section */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="lg:col-span-2 bg-white rounded-2xl shadow-sm p-8"
          >
            <h2 className="text-xl font-medium text-bluish-grey mb-6 flex items-center">
              <HelpCircle className="w-6 h-6 mr-2 text-sage" />
              Pyetjet e Shpeshta
            </h2>
            <div className="space-y-4">
              <div className="p-4 rounded-xl bg-tealish-blue hover:bg-seashell transition-colors duration-200">
                <h3 className="text-lg text-bluish-grey font-medium mb-2">Si të gjeneroj një raport të ri?</h3>
                <p className="text-grey-goose">
                  Zgjidhni kategorinë përkatëse nga veprimet e shpejta dhe ndiqni udhëzimet në ekran për të gjeneruar raportin tuaj.
                </p>
              </div>
              <div className="p-4 rounded-xl bg-tealish-blue hover:bg-seashell transition-colors duration-200">
                <h3 className="text-lg text-bluish-grey font-medium mb-2">Cilat janë afatet për dorëzimin e raporteve?</h3>
                <p className="text-grey-goose">
                  Raportet duhet të dorëzohen deri në ditën e 20-të të çdo muaji për muajin paraprak.
                </p>
              </div>
            </div>
          </motion.div>

          {/* Useful Resources Section */}
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="bg-white rounded-2xl shadow-sm p-8"
          >
            <h2 className="text-xl font-medium text-bluish-grey mb-6 flex items-center">
              <FileText className="w-6 h-6 mr-2 text-sage" />
              Burime të Dobishme
            </h2>
            <div className="space-y-4">
              {usefulResources.map((resource, index) => (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  whileHover={{ scale: 1.01 }}
                  className="group"
                >
                  <Link
                    to={resource.href}
                    className={`block p-4 rounded-xl bg-${resource.color}/10 hover:bg-${resource.color}/20 transition-all duration-200`}
                  >
                    <div className="flex items-start space-x-3">
                      <div className={`p-2 rounded-lg text-${resource.color}`}>
                        {resource.icon}
                      </div>
                      <div className="flex-1">
                        <h3 className={`text-base font-medium text-bluish-grey group-hover:text-${resource.color} transition-colors duration-200`}>
                          {resource.title}
                        </h3>
                        <p className="text-sm text-grey-goose mt-1">
                          {resource.description}
                        </p>
                      </div>
                      <ChevronRight className={`w-5 h-5 text-${resource.color}`} />
                    </div>
                  </Link>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default TaxesPage;
