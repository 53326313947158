import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, Settings, Upload, X, Save } from 'lucide-react';
import { useSelector } from 'react-redux';

import { getCompany } from '../../../api/company';
import { getSuppliers } from '../../../api/accounting';
import { getArticles } from '../../../api/accountingArticle';
import { createPurchase, getNextPurchaseDocumentNumber } from '../../../api/purchase';
import { toast } from 'react-toastify';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '../../../components/shadCN/Select.tsx';
import { Button } from '../../../components/shadCN/Button.tsx';
import SupplierModal from '../../../components/SupplierModal';

interface Article {
  id: string;
  articleId?: string;
  name: string;
  unit: string;
  quantity: number;
  priceWithoutVAT: number;
  vatAmount: number;
  totalWithVAT: number;
  vatRate?: number;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Supplier {
  id: string;
  name: string;
  businessName?: string;
  tradeName?: string;
  businessType?: string;
  fiscalNumber: string;
  address?: string;
  email?: string;
  phone?: string;
}

interface AccountingArticle {
  id: string;
  code: string;
  name: string;
  description?: string;
  purchasePrice?: number;
  sellingPrice?: number;
  unit: {
    id: string;
    name: string;
  };
  vatRate: {
    id: string;
    rate: number;
    percentage: number;
  };
}

const AddPurchasePage = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.user);
  const [documentNumber, setDocumentNumber] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [issueDate, setIssueDate] = useState(new Date().toISOString().split('T')[0]);
  const [expiryDate, setExpiryDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [description, setDescription] = useState('');
  const [articles, setArticles] = useState<Article[]>([]);
  const [accountingArticles, setAccountingArticles] = useState<AccountingArticle[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState('');
  const [purchaseStatus] = useState('DRAFT');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch document number first
        console.log('Fetching purchase document number...');
        const docNumberRes = await getNextPurchaseDocumentNumber('PURCHASE');
        console.log('Document number response:', docNumberRes);
        
        const docNumber = docNumberRes?.data?.message?.documentNumber;
        console.log('Setting document number to:', docNumber);
        
        if (!docNumber) {
          console.error('No document number received:', docNumberRes);
          toast.error('Failed to get document number');
          return;
        }
        
        setDocumentNumber(docNumber);
        
        // Fetch other data
        const [companyRes, suppliersRes, articlesData] = await Promise.all([
          getCompany(),
          getSuppliers(),
          getArticles()
        ]);
        
        // Handle locations
        console.log('Company response:', companyRes);
        const companyLocations = companyRes?.data?.message?.locations || [];
        if (!companyLocations.length) {
          console.warn('No locations found');
        }
        setLocations(Array.isArray(companyLocations) ? companyLocations : []);
        
        // Handle suppliers
        console.log('Raw suppliers response:', suppliersRes);
        const suppliersList = suppliersRes?.data || [];
        console.log('Processed suppliers list:', suppliersList);
        if (!suppliersList.length) {
          console.warn('No suppliers found');
        }
        setSuppliers(suppliersList);
        
        // Handle articles
        console.log('Raw articles response:', articlesData);
        const articles = articlesData?.data?.message || [];
        if (!articles.length) {
          console.warn('No articles found');
        }
        console.log('Articles with VAT rates:', articles.map(a => ({
          name: a.name,
          vatRate: a.vatRate,
          purchasePrice: a.purchasePrice
        })));
        setAccountingArticles(articles);
        
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error instanceof Error) {
          const errorMessage = error.message.toLowerCase();
          if (!errorMessage.includes('purchase not found')) {
            toast.error(error.message);
          }
        } else {
          toast.error('Failed to load initial data');
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  // Article handling functions
  const handleArticleSelect = (articleId: string, id: string) => {
    const selectedArticle = accountingArticles.find((a) => a.id === articleId);
    if (selectedArticle) {
      setArticles(articles.map((article) =>
        article.id === id
          ? {
              ...article,
              articleId: selectedArticle.id,
              name: selectedArticle.name,
              unit: selectedArticle.unit.name,
              vatRate: selectedArticle.vatRate.percentage,
              priceWithoutVAT: selectedArticle.purchasePrice || 0,
              vatAmount: Number(((selectedArticle.purchasePrice || 0) * (selectedArticle.vatRate.percentage / 100)).toFixed(2)),
              totalWithVAT: Number(((selectedArticle.purchasePrice || 0) * (1 + selectedArticle.vatRate.percentage / 100)).toFixed(2))
            }
          : article
      ));
    }
  };

  const handleArticleChange = (articleId: string, field: string, value: any) => {
    setArticles(articles.map(article => {
      if (article.id === articleId) {
        const updatedArticle = { ...article, [field]: value };
        
        // Recalculate totals
        const quantity = Number(updatedArticle.quantity) || 0;
        const priceWithoutVAT = Number(updatedArticle.priceWithoutVAT) || 0;
        const vatRate = Number(updatedArticle.vatRate) || 0;
        
        const subtotal = quantity * priceWithoutVAT;
        const vatAmount = Number((subtotal * vatRate / 100).toFixed(2));
        const totalWithVAT = Number((subtotal + vatAmount).toFixed(2));
        
        return {
          ...updatedArticle,
          quantity: Number(quantity),
          priceWithoutVAT: Number(priceWithoutVAT),
          vatRate: Number(vatRate),
          vatAmount,
          totalWithVAT
        };
      }
      return article;
    }));
  };

  const handleAddArticle = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    const newArticle: Article = {
      id: crypto.randomUUID(),
      name: '',
      unit: '',
      quantity: 1,
      priceWithoutVAT: 0,
      vatAmount: 0,
      totalWithVAT: 0,
      vatRate: 18
    };
    setArticles([...articles, newArticle]);
  };

  const removeArticle = (id: string) => {
    setArticles(articles.filter(a => a.id !== id));
  };

  const calculateTotals = () => {
    let totalWithoutVAT = 0;
    let totalVAT = 0;

    articles.forEach((article) => {
      const quantity = Number(article.quantity) || 0;
      const price = Number(article.priceWithoutVAT) || 0;
      const vatRate = Number(article.vatRate) || 0;

      const lineTotal = quantity * price;
      totalWithoutVAT += lineTotal;
      totalVAT += Number((lineTotal * vatRate / 100).toFixed(2));
    });

    return {
      totalWithoutVAT,
      totalVAT,
      totalWithVAT: Number((totalWithoutVAT + totalVAT).toFixed(2)),
    };
  };

  const calculateVatGroups = () => {
    const vatGroups: { [key: string]: number } = {};

    articles.forEach((article) => {
      const quantity = Number(article.quantity) || 0;
      const price = Number(article.priceWithoutVAT) || 0;
      const vatRate = Number(article.vatRate) || 0;

      const lineTotal = quantity * price;
      const vatAmount = Number((lineTotal * vatRate / 100).toFixed(2));

      if (vatRate in vatGroups) {
        vatGroups[vatRate] += vatAmount;
      } else {
        vatGroups[vatRate] = vatAmount;
      }
    });

    return vatGroups;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      // Validate required fields
      if (!selectedSupplier || !articles.length || !issueDate || !expiryDate) {
        toast.error('Please fill in all required fields');
        return;
      }

      // Format dates to ISO string format
      const formattedIssueDate = new Date(issueDate).toISOString();
      const formattedDueDate = new Date(expiryDate).toISOString();

      // Calculate totals
      const totals = articles.reduce((acc, article) => {
        const subtotal = Number(article.quantity) * Number(article.priceWithoutVAT);
        const vatAmount = Number((subtotal * Number(article.vatRate) / 100).toFixed(2));
        const totalWithVAT = Number((subtotal + vatAmount).toFixed(2));
        
        return {
          totalWithoutVAT: acc.totalWithoutVAT + subtotal,
          totalVAT: acc.totalVAT + vatAmount,
          totalWithVAT: acc.totalWithVAT + totalWithVAT
        };
      }, { totalWithoutVAT: 0, totalVAT: 0, totalWithVAT: 0 });

      const purchaseData = {
        type: 'PURCHASE',
        documentNumber: documentNumber,
        issueDate: formattedIssueDate,
        dueDate: formattedDueDate,
        supplierId: selectedSupplier,
        locationId: selectedLocation,
        description: description,
        items: articles.map(article => {
          const subtotal = Number(article.quantity) * Number(article.priceWithoutVAT);
          const vatAmount = Number((subtotal * Number(article.vatRate) / 100).toFixed(2));
          const totalWithVAT = Number((subtotal + vatAmount).toFixed(2));
          
          return {
            articleId: article.articleId,
            quantity: Number(article.quantity),
            priceWithoutVAT: Number(article.priceWithoutVAT),
            vatRate: Number(article.vatRate),
            vatAmount,
            totalWithVAT
          };
        }),
        totalWithoutVAT: Number(totals.totalWithoutVAT.toFixed(2)),
        totalVAT: Number(totals.totalVAT.toFixed(2)),
        totalWithVAT: Number(totals.totalWithVAT.toFixed(2)),
        createdBy: user.id
      };

      setIsSubmitting(true);
      const response = await createPurchase(purchaseData);
      
      if (response?.data) {
        // Handle the response which contains data array and pagination
        const createdPurchase = Array.isArray(response.data) ? response.data[0] : response.data;
        if (createdPurchase) {
          toast.success('Purchase created successfully');
          navigate('/accounting/purchases');
        } else {
          toast.error('Failed to create purchase: Invalid response format');
        }
      } else {
        toast.error('Failed to create purchase: No response data');
      }
    } catch (error) {
      console.error('Error creating purchase:', error);
      toast.error(error.message || 'Failed to create purchase');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSupplierSelect = (supplierId: string) => {
    setSelectedSupplier(supplierId);
  };

  const handleSupplierSuccess = async (newSupplier: any) => {
    setIsSupplierModalOpen(false);
    try {
      const suppliersRes = await getSuppliers();
      setSuppliers(Array.isArray(suppliersRes.data) ? suppliersRes.data : []);
      setSelectedSupplier(newSupplier.id);
      toast.success('Furnitori u shtua me sukses');
    } catch (error) {
      console.error('Error refreshing suppliers:', error);
      toast.error('Failed to refresh suppliers list');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <button
                  onClick={() => navigate('/accounting/purchases')}
                  className="p-2 text-bluish-grey hover:bg-seashell rounded-xl transition-colors"
                >
                  <ArrowLeft className="w-5 h-5" />
                </button>
                <div>
                  <h1 className="text-xl font-semibold text-bluish-grey">Shto blerje të re</h1>
                  <p className="text-sm text-grey-goose">Plotësoni të dhënat e blerjes së re</p>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <Button
                  onClick={handleSubmit}
                  className="bg-gradient-to-r from-hazel-green to-sage hover:from-sage hover:to-hazel-green text-white px-6 py-2.5 rounded-xl flex items-center gap-2"
                >
                  <Save className="w-5 h-5" />
                  Ruaj
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Purchase Information */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Detajet për Blerje Produkti</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i Dokumentit
                  </label>
                  <input
                    type="text"
                    value={documentNumber}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                  <p className="mt-1 text-sm text-grey-goose">
                    Emri i dokumentit është automatikisht i gjeneruar në bazë të formatit.
                  </p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i faturës
                  </label>
                  <input
                    type="text"
                    value={invoiceNumber}
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                    placeholder="Numri i faturës së pranuar nga furnitori"
                  />
                  <p className="mt-1 text-sm text-grey-goose">
                    Numri i faturës së pranuar nga furnitori.
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-2">
                      Data e lëshimit
                    </label>
                    <input
                      type="date"
                      value={issueDate}
                      onChange={(e) => setIssueDate(e.target.value)}
                      className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-2">
                      Data e skadimit
                    </label>
                    <input
                      type="date"
                      value={expiryDate}
                      onChange={(e) => setExpiryDate(e.target.value)}
                      className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                    />
                  </div>
                </div>
                {/* Supplier Select */}
                <div className="relative mb-4">
                  <label htmlFor="supplier" className="mb-2 block text-sm font-medium text-slate-600">
                    Furnitori
                  </label>
                  <div className="flex gap-2 items-start">
                    <div className="flex-1">
                      <Select
                        value={selectedSupplier}
                        onValueChange={handleSupplierSelect}
                        disabled={isLoading}
                      >
                        <SelectTrigger 
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                          disabled={isLoading}
                        >
                          <SelectValue 
                            placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni furnitorin"}
                            className="text-bluish-grey"
                          >
                            {selectedSupplier && (
                              <span className="text-hazel-green">
                                {suppliers.find(s => s.id === selectedSupplier)?.businessName || suppliers.find(s => s.id === selectedSupplier)?.name}
                              </span>
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent 
                          className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                          sideOffset={5}
                          position="popper"
                          align="start"
                        >
                          <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                            <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                              Zgjidhni Furnitorin
                            </h3>
                          </div>
                          {suppliers.map(supplier => (
                            <SelectItem
                              key={supplier.id}
                              value={supplier.id}
                              textValue={supplier.businessName || supplier.name}
                              className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                            >
                              {/* Hidden span for the select field display */}
                              <span className="hidden">
                                {supplier.businessName || supplier.name}
                              </span>
                              
                              {/* Detailed content for dropdown */}
                              <div className="flex flex-col gap-1">
                                <div className="flex items-center justify-between">
                                  <div className="flex flex-col">
                                    <span className="text-sm sm:text-base font-medium text-hazel-green">
                                      {supplier.businessName || supplier.name}
                                    </span>
                                    {supplier.tradeName && supplier.tradeName !== supplier.businessName && (
                                      <span className="text-xs sm:text-sm text-bluish-grey">
                                        Trading as: {supplier.tradeName}
                                      </span>
                                    )}
                                  </div>
                                  {supplier.businessType && (
                                    <span className="px-2 py-0.5 rounded-full text-xs sm:text-sm font-medium bg-sage/10 text-sage">
                                      {supplier.businessType}
                                    </span>
                                  )}
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 text-xs sm:text-sm">
                                  {supplier.fiscalNumber && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm3 1h6v4H7V5zm5 6H7v2h6v-2z" clipRule="evenodd" />
                                      </svg>
                                      <span>Nr. Fiskal: {supplier.fiscalNumber}</span>
                                    </div>
                                  )}
                                  {supplier.address && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                      </svg>
                                      <span>{supplier.address}</span>
                                    </div>
                                  )}
                                  {supplier.phone && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                      </svg>
                                      <span>{supplier.phone}</span>
                                    </div>
                                  )}
                                  {supplier.email && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                      </svg>
                                      <span>{supplier.email}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <Button
                      type="button"
                      variant="outline"
                      size="icon"
                      onClick={() => setIsSupplierModalOpen(true)}
                      className="flex-shrink-0 h-10 w-10 rounded-lg hover:bg-gray-100"
                    >
                      <Plus className="h-5 w-5" />
                    </Button>
                  </div>
                </div>

                {/* Location Select */}
                <div className="mb-4">
                  <label htmlFor="location" className="mb-2 block text-sm font-medium text-slate-600">
                    Lokacioni
                  </label>
                  <Select
                    value={selectedLocation}
                    onValueChange={setSelectedLocation}
                    disabled={isLoading}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                      disabled={isLoading}
                    >
                      <SelectValue 
                        placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni lokacionin"}
                        className="text-bluish-grey"
                      >
                        {selectedLocation && (
                          <span className="text-hazel-green">
                            {locations.find(l => l.id === selectedLocation)?.name}
                          </span>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                      sideOffset={5}
                      position="popper"
                      align="start"
                    >
                      {locations.map(location => (
                        <SelectItem
                          key={location.id}
                          value={location.id}
                          textValue={location.name}
                          className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm sm:text-base font-medium text-hazel-green">
                              {location.name}
                            </span>
                            {location.address && (
                              <span className="text-xs sm:text-sm text-bluish-grey">
                                {location.address}
                              </span>
                            )}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                
              </div>
            </div>
          </div>

          {/* Articles */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4 flex justify-between items-center">
              <h2 className="text-lg font-semibold text-hazel-green">Artikujt</h2>
              <button
                type="button"
                onClick={handleAddArticle}
                className="inline-flex items-center gap-x-2 px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                         hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
              >
                <Plus className="h-5 w-5" />
                Shto Artikull
              </button>
            </div>
            <div className="p-6">
              <div className="space-y-4">
                {articles.length === 0 ? (
                  <div className="flex flex-col items-center justify-center py-8">
                    <div className="w-16 h-16 bg-gradient-to-br from-sage/20 to-hazel-green/10 rounded-lg flex items-center justify-center mb-4">
                      <Plus className="h-8 w-8 text-hazel-green" />
                    </div>
                    <h3 className="text-base font-medium text-bluish-grey mb-1">Nuk ka artikuj</h3>
                    <p className="text-sm text-grey-goose mb-4">Shtoni artikuj për të vazhduar me blerjen</p>
                    <button
                      type="button"
                      onClick={handleAddArticle}
                      className="inline-flex items-center gap-x-2 px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                               hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
                    >
                      <Plus className="h-5 w-5" />
                      Shto Artikull
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="grid grid-cols-12 gap-4 items-center mb-2 text-sm font-medium text-bluish-grey">
                      <div className="col-span-3">Emri i artikullit</div>
                      <div className="col-span-2">Njësia</div>
                      <div className="col-span-1">Sasia</div>
                      <div className="col-span-2">Çmimi pa TVSH</div>
                      <div className="col-span-2">TVSH</div>
                      <div className="col-span-1">Totali</div>
                      <div className="col-span-1"></div>
                    </div>
                    {articles.map((article, index) => (
                      <div key={article.id} className="grid grid-cols-12 gap-4 items-center">
                        <div className="col-span-3">
                          <Select
                            value={article.articleId || ''}
                            onValueChange={(value) => handleArticleSelect(value, article.id)}
                          >
                            <SelectTrigger 
                              className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                            >
                              <SelectValue 
                                placeholder="Zgjidhni artikullin"
                                className="text-bluish-grey"
                              >
                                {article.name || "Zgjidhni artikullin"}
                              </SelectValue>
                            </SelectTrigger>
                            <SelectContent 
                              className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden max-h-[300px]"
                              position="popper"
                              sideOffset={5}
                            >
                              <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                                <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                                  Zgjidhni Artikullin
                                </h3>
                              </div>
                              {(accountingArticles || []).map(item => (
                                <SelectItem
                                  key={item.id}
                                  value={item.id}
                                  textValue={item.name}
                                  className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                                >
                                  <div className="flex flex-col gap-1">
                                    <div className="flex flex-col">
                                      <span className="text-sm font-medium text-hazel-green">
                                        {item.name}
                                      </span>
                                      <span className="text-xs text-gray-500">
                                        Kodi: {item.code}
                                      </span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-1 text-xs text-gray-500">
                                      <div className="flex items-center gap-1 text-bluish-grey">
                                        <span>Çmimi: {item.purchasePrice?.toFixed(2)} €</span>
                                      </div>
                                      <div className="flex items-center gap-1 text-bluish-grey">
                                        <span>TVSH: {item.vatRate?.percentage || 0}%</span>
                                      </div>
                                      {item.description && (
                                        <div className="col-span-2 text-xs text-grey-goose">
                                          {item.description}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="col-span-2">
                          <input
                            type="text"
                            value={article.unit}
                            readOnly
                            placeholder="Njësia"
                            className="block w-full rounded-lg border border-mercury bg-gray-50 px-4 py-2.5 text-bluish-grey shadow-sm"
                          />
                        </div>
                        <div className="col-span-1">
                          <input
                            type="number"
                            value={article.quantity}
                            onChange={(e) => handleArticleChange(article.id, 'quantity', parseFloat(e.target.value))}
                            placeholder="Sasia"
                            className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                          />
                        </div>
                        <div className="col-span-2">
                          <input
                            type="number"
                            value={article.priceWithoutVAT}
                            onChange={(e) => handleArticleChange(article.id, 'priceWithoutVAT', parseFloat(e.target.value))}
                            placeholder="Çmimi pa TVSH"
                            className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                          />
                        </div>
                        <div className="col-span-2">
                          <input
                            type="text"
                            value={`${article.vatRate || 18}%`}
                            readOnly
                            className="block w-full rounded-lg border border-mercury bg-gray-50 px-4 py-2.5 text-bluish-grey shadow-sm"
                          />
                        </div>
                        <div className="col-span-1">
                          <span className="text-sm font-medium text-bluish-grey">
                            ${(article.totalWithVAT || 0).toFixed(2)}
                          </span>
                        </div>
                        <div className="col-span-1">
                          <button
                            type="button"
                            onClick={() => removeArticle(article.id)}
                            className="p-1.5 rounded-lg hover:bg-gray-100 text-grey-goose hover:text-watermelon transition-all"
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Pricing Section */}
          <div className="mt-8 border-t border-mercury pt-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-2">
                  Përshkrimi
                </label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Përshkrimi i blerjes"
                  className="h-32 block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                />
              </div>
              <div className="space-y-4">
                {/* Group VAT rates */}
                {Object.entries(calculateVatGroups()).map(([rate, amount]) => (
                  <div key={rate} className="flex justify-between items-center">
                    <span className="text-sm text-bluish-grey">TVSH {rate}%</span>
                    <span className="text-sm font-medium text-hazel-green">
                      {amount.toFixed(2)} €
                    </span>
                  </div>
                ))}

                {/* Subtotal */}
                <div className="flex justify-between items-center">
                  <span className="text-sm text-bluish-grey">Nëntotali pa TVSH</span>
                  <span className="text-sm font-medium text-hazel-green">
                    {calculateTotals().totalWithoutVAT.toFixed(2)} €
                  </span>
                </div>

                {/* Total VAT */}
                <div className="flex justify-between items-center">
                  <span className="text-sm text-bluish-grey">Totali TVSH</span>
                  <span className="text-sm font-medium text-hazel-green">
                    {calculateTotals().totalVAT.toFixed(2)} €
                  </span>
                </div>

                {/* Total with VAT */}
                <div className="flex justify-between items-center pt-4 border-t border-mercury">
                  <span className="text-base font-medium text-bluish-grey">Totali me TVSH</span>
                  <span className="text-base font-medium text-hazel-green">
                    {calculateTotals().totalWithVAT.toFixed(2)} €
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Supplier Modal */}
      <SupplierModal
        isOpen={isSupplierModalOpen}
        onClose={() => setIsSupplierModalOpen(false)}
        onSuccess={handleSupplierSuccess}
      />
    </div>
  );
};

export default AddPurchasePage;
