import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { X, FileText, Save } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BasicDetails } from '../../../../../components/invoice/BasicDetails.tsx';
import { ItemTable } from '../../../../../components/invoice/ItemTable.tsx';
import { Notes } from '../../../../../components/invoice/Notes.tsx';
import { PaymentDetails } from '../../../../../components/invoice/PaymentDetails.tsx';
import ItemSelectionModal from '../../../../../components/quotes/ItemSelectionModal.tsx';
import { getClients } from '../../../../../api/accounting';
import { getAllItemsOrServices } from '../../../../../api/itemOrService';
import { createInvoice } from '../../../../../api/invoiceSale';

interface InvoiceItem {
  id: string;
  details: string;
  quantity: number;
  rate: number;
  discount: number;
  tax: string;
  amount: number;
  itemId?: number;
}

interface Client {
  id: string;
  name: string;
}

interface Item {
  id: number;
  name: string;
  description: string;
  sellingPrice: number;
}

function InvoiceForm() {
  const [clients, setClients] = useState<Client[]>([]);
  const [items, setItems] = useState<InvoiceItem[]>([{ id: '1', details: '', quantity: 1, rate: 0, discount: 0, tax: '', amount: 0 }]);
  const [availableItems, setAvailableItems] = useState<Item[]>([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [reference, setReference] = useState('');
  const [terms, setTerms] = useState('');
  const [notes, setNotes] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [status, setStatus] = useState('draft');
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientsResponse, itemsResponse] = await Promise.all([
          getClients(),
          getAllItemsOrServices()
        ]);
        setClients(clientsResponse.data);
        setAvailableItems(itemsResponse.data);
        console.log("ITESM RESPONSE", itemsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleItemSelect = (selectedItem: Item) => {
    setItems(currentItems => {
      const updatedItems = [...currentItems];
      updatedItems[selectedRowIndex] = {
        ...updatedItems[selectedRowIndex],
        details: selectedItem.name,
        rate: selectedItem.sellingPrice,
        amount: selectedItem.sellingPrice * updatedItems[selectedRowIndex].quantity,
        itemId: selectedItem.id,
      };
      return updatedItems;
    });
    setIsItemModalOpen(false);
  };

  const subTotal = items.reduce((sum, item) => sum + item.amount, 0);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate all items have 'details'
    const incompleteItems = items.filter(item => !item.details);
    if (incompleteItems.length > 0) {
        toast.error("Each item must have a 'details' field.");
        return;
    }

    // Proceed with submitting the invoice data
    const invoiceData = {
        invoiceNumber,
        clientId: selectedClient,
        invoiceDate,
        notes,
        paymentMethod,
        reference,
        status,
        items: items.map(item => ({
            quantity: item.quantity,
            discount: item.discount,
            tax: item.tax,
            rate: item.rate,
            itemId: item.itemId,
            amount: item.amount,
            details: item.details, // Ensure details are sent to the backend
        })),
    };

    try {
        const response = await createInvoice(invoiceData);
        toast.success('Invoice created successfully!');
        console.log('Invoice created successfully:', response.data);
        // Reset form here
    } catch (error) {
        toast.error('Error creating invoice.');
        console.error('Error creating invoice:', error);
    }
};

  return (
    <motion.div
      className="min-h-screen p-4 md:p-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <ToastContainer />
      <form onSubmit={handleSubmit} className="max-w-5xl mx-auto">
        <div className="bg-white rounded-xl shadow-lg overflow-hidden border border-grey-goose">
          <div className="flex justify-between items-center p-5 border-b border-grey-goose bg-seashell">
            <div className="flex items-center space-x-3">
              <FileText className="text-hazel-green" size={24} />
              <h1 className="text-xl font-semibold text-bluish-grey">New Invoice</h1>
            </div>
            <button type="button" className="text-silver hover:text-bluish-grey transition-colors p-2 rounded-full hover:bg-grey-goose/10">
              <X size={20} />
            </button>
          </div>

          <div className="p-6 space-y-8">
            <BasicDetails
              clients={clients}
              selectedClient={selectedClient}
              onClientChange={setSelectedClient}
              invoiceDate={invoiceDate}
              onDateChange={setInvoiceDate}
              invoiceNumber={invoiceNumber}
              onNumberChange={setInvoiceNumber}
              reference={reference}
              onReferenceChange={setReference}
              terms={terms}
              onTermsChange={setTerms}
            />

            <ItemTable
              items={items}
              availableItems={availableItems}
              onRowSelect={(index) => {
                setSelectedRowIndex(index);
                setIsItemModalOpen(true);
              }}
              onItemChange={setItems}
            />

            <Notes value={notes} onChange={setNotes} />

            <PaymentDetails subTotal={subTotal} paymentMethod={paymentMethod} onPaymentMethodChange={setPaymentMethod} />

            <div className="flex justify-between items-center pt-6 border-t border-grey-goose">
              <div className="flex items-center gap-4">
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="p-2.5 border border-grey-goose rounded-lg focus:outline-none focus:border-sage bg-white text-bluish-grey text-sm transition-colors hover:border-sage"
                >
                  <option value="draft">Draft</option>
                  <option value="sent">Sent</option>
                  <option value="paid">Paid</option>
                  <option value="overdue">Overdue</option>
                </select>
              </div>
              <div className="flex gap-3">
                <button type="submit" className="px-6 py-2.5 bg-hazel-green text-white rounded-lg hover:bg-sage transition-colors duration-200 flex items-center gap-2">
                  <Save size={18} />
                  Save and Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <ItemSelectionModal
        isOpen={isItemModalOpen}
        onClose={() => setIsItemModalOpen(false)}
        items={availableItems}
        onSelectItem={handleItemSelect}
      />
    </motion.div>
  );
}

export default InvoiceForm;