import api from './noAuthApi';

const handleApiError = (error) => {
  if (error.response?.status === 401) {
    console.error('Authentication error:', error);
    throw error;
  }
  throw error;
};

export const createFolder = (data) => {
  return api.post('/api/documents/folders', data);
};

export const updateFolder = (id, data) => {
  return api.put(`/api/documents/folders/${id}`, data);
};

export const getFolders = (locationId) => {
  console.log('Fetching folders with locationId:', locationId);
  return api.get('/api/documents/folders', {
    params: { locationId },
  });
};

export const deleteFolder = (id) => {
  return api.delete(`/api/documents/folders/${id}`);
};

export const createDocument = async (formData) => {
  try {
    console.log('Creating document with formData:', Object.fromEntries(formData.entries()));
    const response = await api.post('/api/documents', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log('Create document response:', response);
    return response.data;
  } catch (error) {
    console.error('Error creating document:', error);
    throw error;
  }
};

export const updateDocument = (id, data) => {
  return api.put(`/api/documents/${id}`, data);
};

export const getDocuments = (params) => {
  // Filter out empty or undefined values
  const filteredParams = {};
  
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      // Only include non-empty values and handle null folderId explicitly
      if (key === 'folderId') {
        filteredParams[key] = value;
      } else if (value !== undefined && value !== null && value !== '') {
        filteredParams[key] = value;
      }
    });
  }
  
  console.log('Filtered params:', JSON.stringify(filteredParams, null, 2));
  
  return api.get('/api/documents', { 
    params: filteredParams,
  });
};

export const getDocument = (id) => {
  return api.get(`/api/documents/${id}`);
};

export const deleteDocument = (id) => {
  return api.delete(`/api/documents/${id}`);
};

export const generateDocument = async (referenceType, referenceId, format) => {
  try {
    const response = await api.post('/api/documents/generate', {
      referenceType,
      referenceId,
      format
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const generateBulkDocuments = async ({ entityType, format }) => {
  try {
    console.log('Generating bulk documents:', { entityType, format });
    const response = await api.post('/api/documents/generate-bulk', {
      entityType,
      format
    });
    console.log('Generate bulk response:', response);
    return response;
  } catch (error) {
    console.error('Generate bulk error:', error);
    handleApiError(error);
  }
};

export const cleanupDuplicateDocuments = async (referenceType) => {
  try {
    console.log('Cleaning up duplicates for:', referenceType);
    const response = await api.post('/api/documents/cleanup-duplicates', { 
      referenceType: referenceType 
    });
    console.log('Cleanup response:', response);
    return response.data;
  } catch (error) {
    console.error('Cleanup API error:', error);
    handleApiError(error);
  }
};

export const createTestDocument = () => {
  return api.post('/api/documents/test');
};

export const getDocumentContent = async (id) => {
  try {
    const response = await api.get(`/api/documents/${id}/content`, {
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    console.error('Error loading document:', error);
    if (error.response?.status === 404) {
      throw new Error('Document not found');
    } else if (error.response?.status === 401) {
      throw new Error('Unauthorized access - please log in again');
    }
    throw new Error('Failed to load document: ' + (error.message || 'Unknown error'));
  }
};

export const getDocumentDetails = async (id) => {
  try {
    console.log('Fetching document details for ID:', id);
    const response = await api.get(`/api/documents/${id}`, {
      params: {
        includeReferences: true,
        includeItems: true,
        includeClient: true,
        includeSupplier: true,
        includeArticles: true
      }
    });
    
    console.log('Document details response:', response.data);
    
    if (!response.data || !response.data.message) {
      console.error('Invalid response format:', response.data);
      throw new Error('Invalid response format from server');
    }
    
    const document = response.data.message;
    
    // Transform the data structure for the template
    const transformedData = {
      ...document,
      type: document.type.toLowerCase(),
      data: {
        documentNumber: document.documentNumber,
        issueDate: document.createdAt,
        dueDate: document.dueDate,
        description: document.description,
        client: document.Sale?.client || document.Offer?.client || null,
        supplier: document.Purchase?.supplier || null,
        items: (document.Sale?.items || document.Purchase?.items || document.Offer?.items || []).map(item => ({
          name: item.article?.name || 'Unknown Item',
          code: item.article?.code,
          unit: item.article?.unit?.name || 'pcs',
          quantity: item.quantity || 0,
          price: item.priceWithoutVAT || item.unitPrice || 0,
          vatRate: item.article?.vatRate?.percentage || 0,
          total: item.totalWithVAT || (item.quantity * item.unitPrice) || 0
        }))
      }
    };
    
    console.log('Transformed document data:', transformedData);
    return transformedData;
  } catch (error) {
    console.error('Error fetching document details:', error);
    if (error.response?.status === 404) {
      throw new Error('Document not found');
    }
    throw error;
  }
};

export const updateDocumentFolder = async (documentId, folderId) => {
  try {
    const response = await api.put(`/api/documents/${documentId}/move`, { 
      folderId: folderId || null 
    });
    return response.data;
  } catch (error) {
    console.error('Error moving document:', error);
    throw error;
  }
};

export const fixDocumentPaths = async () => {
  try {
    console.log('Starting document path fix...');
    const response = await api.post('/api/documents/fix-document-paths');
    console.log('Fix document paths response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fixing document paths:', error);
    throw error;
  }
};

export const checkAndFixDocuments = async () => {
  try {
    console.log('Starting document check and fix...');
    const response = await api.post('/api/documents/check-and-fix');
    console.log('Check and fix response:', response.data);
    return response.data.message;
  } catch (error) {
    console.error('Error in check and fix:', error);
    throw error;
  }
};

export const cleanupDocuments = async () => {
  try {
    console.log('Starting document cleanup...');
    const response = await api.get('/api/documents/temp-cleanup');
    console.log('Cleanup response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in cleanup:', error);
    throw error;
  }
};

export const uploadDocument = async (file, data) => {
  const formData = new FormData();
  formData.append('file', file);
  
  // Append other document data
  if (data) {
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  }

  return await api.post('/documents/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
