import { useState } from 'react';
import { generateDocument } from '../api/document';
import toast from 'react-hot-toast';

interface UseDocumentGenerationProps {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

type EntityType = 'Sale' | 'Purchase' | 'Offer';  
type DocumentFormat = 'pdf' | 'excel';

export const useDocumentGeneration = ({ onSuccess, onError }: UseDocumentGenerationProps = {}) => {
  const [loading, setLoading] = useState(false);

  const generateDocumentForEntity = async (
    entityType: EntityType,
    entityId: string,
    format: DocumentFormat
  ) => {
    try {
      setLoading(true);
      const response = await generateDocument(entityType, entityId, format);
      
      if (response?.statusCode === 200) {
        const formatLabel = format.toUpperCase();
        const entityLabel = entityType.charAt(0).toUpperCase() + entityType.slice(1).toLowerCase();
        toast.success(`${entityLabel} ${formatLabel} generated successfully`);
        onSuccess?.();
        return response.data;
      } else {
        throw new Error(response?.message || 'Failed to generate document');
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || `Failed to generate ${entityType.toLowerCase()} document`;
      console.error(`Error generating ${entityType} document:`, error);
      toast.error(errorMessage);
      onError?.(new Error(errorMessage));
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    generateDocumentForEntity
  };
};
