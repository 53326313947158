import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Building2, RefreshCw, Download, Plus, Filter, Settings, CreditCard } from 'lucide-react';
import TransactionFormModal from '../../../components/banking/TransactionFormModal.tsx';

const BankExtractPage = () => {
  const [selectedAccount, setSelectedAccount] = useState('Enis Gjocaj - 123234343241232');
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('për t\'u regjistruar');

  return (
    <div className="min-h-screen bg-tealish-blue/30 p-8">
      {/* Breadcrumb */}
      <div className="mb-6">
        <div className="flex items-center gap-2 text-sm">
          <span className="text-bluish-grey hover:text-hazel-green cursor-pointer transition-colors">
            Kontabiliteti
          </span>
          <span className="text-grey-goose">/</span>
          <span className="text-hazel-green font-medium">Ekstrakti Bankar</span>
        </div>
      </div>

      {/* Title and Description */}
      <div className="mb-6">
        <h1 className="text-2xl font-semibold text-bluish-grey mb-2">
          Ekstrakti Bankar
        </h1>
        <p className="text-grey-goose text-sm">
          Përshtatni transaksionet tuaja bankare me regjistrat e kontabilitetit.
        </p>
      </div>

      {/* Account Selection */}
      <div className="mb-6">
        <select
          value={selectedAccount}
          onChange={(e) => setSelectedAccount(e.target.value)}
          className="w-full max-w-xl appearance-none rounded-lg border border-mercury/50 bg-white py-2.5 px-4 text-sm 
                   text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                   hover:border-hazel-green/50 transition-all"
        >
          <option value="Enis Gjocaj - 123234343241232">Enis Gjocaj - 123234343241232</option>
        </select>
      </div>

      {/* Account Card */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative bg-sage rounded-xl p-8 mb-8 text-white overflow-hidden shadow-xl"
      >
        {/* Background Pattern */}
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48cGF0dGVybiBpZD0iZ3JpZCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIj48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLW9wYWNpdHk9IjAuMSIgc3Ryb2tlLXdpZHRoPSIxIi8+PC9wYXR0ZXJuPjwvZGVmcz48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyaWQpIi8+PC9zdmc+')] opacity-20" />
        
        {/* Decorative Elements */}
        <div className="absolute top-0 right-0 w-96 h-96 bg-sage/20 rounded-full transform translate-x-1/3 -translate-y-1/3 blur-3xl" />
        <div className="absolute bottom-0 left-0 w-80 h-80 bg-seashell/20 rounded-full transform -translate-x-1/3 translate-y-1/3 blur-2xl" />
        
        <div className="relative">
          <div className="flex items-center gap-4 mb-8">
            <div className="p-4 bg-white/20 rounded-2xl backdrop-blur-sm shadow-inner border border-white/10">
              <CreditCard className="h-8 w-8 text-seashell" />
            </div>
            <div>
              <p className="text-sm font-medium text-seashell/80">Llogaria bankare</p>
              <h2 className="text-2xl font-bold text-seashell">Enis Gjocaj</h2>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="p-6 bg-white/10 rounded-xl backdrop-blur-sm border border-white/10">
              <p className="text-sm text-seashell/80 mb-2">Numri i llogarisë</p>
              <p className="font-mono text-xl tracking-wider text-seashell">
                123234343241232
              </p>
            </div>
            
            <div className="p-6 bg-white/10 rounded-xl backdrop-blur-sm border border-white/10">
              <p className="text-sm text-seashell/80 mb-2">Balanca në LakeSide</p>
              <div className="flex items-baseline gap-3">
                <h3 className="text-4xl font-bold text-seashell">0.00</h3>
                <span className="text-xl text-seashell/80">EUR</span>
              </div>
            </div>
          </div>

          <div className="mt-8 flex gap-4">
            <button className="px-4 py-2.5 bg-white/10 hover:bg-white/20 text-seashell rounded-lg backdrop-blur-sm transition-all duration-200 flex items-center gap-2 border border-white/10">
              <RefreshCw className="h-4 w-4" />
              <span className="text-sm">Rifresko</span>
            </button>
            <button className="px-4 py-2.5 bg-seashell hover:bg-seashell/90 text-bluish-grey rounded-lg transition-all duration-200 flex items-center gap-2 font-medium">
              <Download className="h-4 w-4" />
              <span className="text-sm">Shkarko ekstraktin</span>
            </button>
          </div>
        </div>
      </motion.div>

      {/* Actions Bar */}
      <div className="flex flex-wrap items-center justify-between gap-4 mb-6 bg-white rounded-xl border border-mercury/40 shadow-sm p-3">
        {/* Tabs */}
        <div className="flex bg-white rounded-lg p-1 border border-mercury/40">
          {['për t\'u regjistruar', 'të kompletuara', 'të anuluara'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-4 py-2 text-sm rounded-md transition-all ${
                activeTab === tab
                  ? 'bg-hazel-green text-white shadow-sm'
                  : 'text-bluish-grey hover:text-hazel-green'
              }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        {/* Right Actions */}
        <div className="flex items-center gap-3">
          <button className="p-2 rounded-lg text-grey-goose hover:text-hazel-green hover:bg-seashell/70 transition-all">
            <RefreshCw className="h-5 w-5" />
          </button>
          <button className="p-2 rounded-lg text-grey-goose hover:text-hazel-green hover:bg-seashell/70 transition-all">
            <Download className="h-5 w-5" />
          </button>
          <button
            onClick={() => setIsTransactionModalOpen(true)}
            className="flex items-center gap-2 px-4 py-2 bg-hazel-green text-white rounded-lg 
                     hover:bg-hazel-green/90 transition-all shadow-sm hover:shadow-md"
          >
            <Plus className="h-4 w-4" />
            <span>Shto transaksion</span>
          </button>
        </div>
      </div>

      {/* Transactions Table */}
      <div className="bg-white rounded-xl border border-mercury/40 shadow-sm overflow-hidden">
        {/* Table Header Actions */}
        <div className="flex justify-between items-center p-4 border-b border-mercury/40">
          <h3 className="text-sm font-medium text-bluish-grey">Transaksionet</h3>
          <div className="flex gap-2">
            <button className="p-2 rounded-lg hover:bg-seashell/70 text-grey-goose hover:text-hazel-green transition-all">
              <Filter className="h-4 w-4" />
            </button>
            <button className="p-2 rounded-lg hover:bg-seashell/70 text-grey-goose hover:text-hazel-green transition-all">
              <Settings className="h-4 w-4" />
            </button>
          </div>
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-mercury/40">
            <thead>
              <tr className="bg-seashell/50">
                <th className="py-3 px-4 text-left text-sm font-medium text-hazel-green">Përshkrimi</th>
                <th className="py-3 px-4 text-left text-sm font-medium text-hazel-green">Debi (dalje nga llogaria)</th>
                <th className="py-3 px-4 text-left text-sm font-medium text-hazel-green">Kredi (hyrje në llogari)</th>
                <th className="py-3 px-4 text-left text-sm font-medium text-hazel-green">Statusi</th>
                <th className="py-3 px-4 text-left text-sm font-medium text-hazel-green">Veprimet</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-mercury/40">
              <tr>
                <td colSpan={5} className="py-16">
                  <div className="text-center">
                    <p className="text-sm text-grey-goose">No data</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex flex-wrap gap-3 mb-6">
        
      </div>

      {/* Transaction Modal */}
      <TransactionFormModal 
        isOpen={isTransactionModalOpen} 
        onClose={() => setIsTransactionModalOpen(false)} 
      />
    </div>
  );
};

export default BankExtractPage;
