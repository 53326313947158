import React from "react";
import Dashboard from "./Dashboard";
import ReservationHistory from "./ReservationHistory";
import TicketTypes from "./TicketTypes";
import { Button } from "../../components/buttons/button";

import GridPattern from "./GridPattern";
import { cn } from "../../utils/cn.ts";
import Earnings from "./Earnings";

function Pool() {
  return (
    <div className="relative bg-slate-100 min-h-screen w-full flex flex-col items-center justify-center">
      <div className="absolute inset-0 z-2">
        <GridPattern
          width={20}
          height={20}
          x={-1}
          y={-1}
          className={cn(
            "[mask-image:linear-gradient(to_bottom_right,white,transparent,transparent)]"
          )}
        />
      </div>

      <div className="relative z-10 flex flex-col items-center w-full">
        <div className="w-full flex flex-col sm:flex-row gap-4 mt-6 mb-4 px-6">
          <div className="flex-shrink-0">
            <Earnings />
          </div>

          <div className="ml-auto flex flex-col sm:flex-row items-center gap-4">
            <button className="bg-gray-500 text-white py-2 px-4 rounded">
              Hello
            </button>
            <Button gray>Print the History</Button>
          </div>
        </div>

        <Dashboard />
        <div className="flex flex-col sm:flex-row items-center justify-center gap-6">
          <TicketTypes />
        </div>
      </div>
    </div>
  );
}

export default Pool;