import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { IconSearch } from '@tabler/icons-react';

export default function SearchFilter({ searchItem }) {
  const [inputValue, setInputValue] = useState('');

  const fetch = useMemo(
    () =>
      _.throttle(async (searchText) => {
        searchItem(searchText);
      }, 2000),
    []
  );

  useEffect(() => {
    (async () => {
      await fetch(inputValue);
      return true;
    })();
  }, [fetch, inputValue]);

  return (
    <div className='relative border rounded-md border-gray-200 w-1/2 mb-3'>
      <input
        type='text'
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        className='block w-full p-2.5 ps-3 text-sm text-gray-900 rounded-lg focus:outline-none'
        placeholder='Search...'
        required
      />
      <div className='absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none'>
        <IconSearch className='w-4 h-4 text-gray-300' />
      </div>
    </div>
  );
}
