import React from 'react';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../shadCN/Table.tsx';
import { Task } from '../../../gobal.ts';
import { format } from 'date-fns';
import { Button } from '../../shadCN/Button.tsx';
import { Check, X } from 'lucide-react';

type TasksTableProps = {
  tasks: Task[];
  type: 'open' | 'closed';
};

export function TasksTable({ tasks, type }: TasksTableProps) {
  const filteredTasks = tasks.filter(task => task.status === type);

  return (
    <div className="rounded-lg border border-grey-goose bg-white">
      <Table>
        <TableHeader className="bg-tealish-blue">
          <TableRow>
            <TableHead className="text-sage">Title</TableHead>
            <TableHead className="text-sage">Tag</TableHead>
            <TableHead className="text-sage">Assigned By</TableHead>
            <TableHead className="text-sage">Assigned To</TableHead>
            <TableHead className="text-sage">Deadline</TableHead>
            {type === 'closed' && <TableHead className="text-sage">Actions</TableHead>}
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredTasks.map((task) => (
            <TableRow key={task.id}>
              <TableCell className="font-medium">{task.title}</TableCell>
              <TableCell>
                <span className={`px-2 py-1 rounded-full text-sm bg-${task.tag.toLowerCase()} text-white`}>
                  {task.tag}
                </span>
              </TableCell>
              <TableCell>{task.assignedBy}</TableCell>
              <TableCell>{task.assignedTo}</TableCell>
              <TableCell>{format(new Date(task.deadline), 'MMM dd, yyyy HH:mm')}</TableCell>
              {type === 'closed' && (
                <TableCell>
                  <div className="flex gap-2">
                    <Button size="sm" variant="ghost" className="text-hazel-green hover:text-sage">
                      <Check className="h-4 w-4" />
                    </Button>
                    <Button size="sm" variant="ghost" className="text-salmon-pink hover:text-watermelon">
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}