import React from 'react';

interface TimeInputProps {
  name: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  showError?: boolean;
  errorMessage?: string;
}

const TimeInput: React.FC<TimeInputProps> = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  showError,
  errorMessage,
}) => {
  return (
    <div className="flex flex-col">
      <label htmlFor={name} className="text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <input
        type="time"
        id={name}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        className={`rounded-md border ${
          showError ? 'border-red-500' : 'border-gray-300'
        } px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500`}
        step="3600"
      />
      {showError && (
        <p className="mt-1 text-sm text-red-500">{errorMessage}</p>
      )}
    </div>
  );
};

export default TimeInput;