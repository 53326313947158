import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getInvoiceById as getInvoice } from '../../../../api/invoiceSale.js';
import { getClientById } from '../../../../api/accounting.js';
import { Button } from '../../../../components/shadCN/Button.tsx';
import { InvoiceSidebar } from '../../../../components/invoice/InvoiceSidebar.tsx';
import { InvoiceDocument } from '../../../../components/invoice/InvoiceDocument.tsx';

import { cn } from '../../../../utils/cn.ts';

import {
  Plus,
  Search,
  Edit,
  Send,
  Share,
  Printer,
  MoreHorizontal,
  PaperclipIcon,
  MessageSquare,
  X,
  ChevronDown,
  Menu,
  ArrowLeft
} from 'lucide-react';
import Loading from '../../../../components/loader.js';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';

export default function InvoiceDetails() {
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState(null);
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const invoiceRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchInvoiceAndClientData = async () => {
      try {
        setLoading(true);
        const response = await getInvoice(invoiceId);
        
        if (response && response.data) {
          let clientName = 'N/A';
          
          // For purchase invoices, use supplier name
          if (response.data.invoiceType === 'purchase' && response.data.supplier) {
            clientName = response.data.supplier.businessName || 'Unknown Supplier';
          } 
          // For other invoices or if no supplier, try to get client name
          else if (response.data.clientId) {
            try {
              const clientResponse = await getClientById(response.data.clientId);
              clientName = clientResponse.data?.name || 'Unknown Client';
              setClientData(clientResponse.data);
            } catch (error) {
              console.warn('Failed to fetch client data:', error);
              clientName = 'Unknown Client';
            }
          }
          
          const formattedDate = response.data.invoiceDate 
            ? new Date(response.data.invoiceDate).toISOString().split('T')[0]
            : new Date(response.data.createdAt).toISOString().split('T')[0];
          
          const transformedInvoice = {
            id: response.data.id,
            invoiceNumber: response.data.invoiceNumber,
            date: formattedDate,
            totalAmount: Number(response.data.totalAmount) || 0,
            clientId: response.data.clientId,
            clientName: clientName,
            status: response.data.status || 'pending',
            notes: response.data.notes || '',
            items: response.data.items?.map(item => ({
              id: item.id,
              details: item.details || '',
              quantity: Number(item.quantity) || 0,
              rate: Number(item.rate) || 0,
              amount: Number(item.amount) || 0,
              tax: Number(item.tax) || 0,
              discount: Number(item.discount) || 0
            })) || []
          };
          
          console.log('Raw Invoice Data:', response.data);
          console.log('Transformed Invoice:', transformedInvoice);
          setInvoice(transformedInvoice);
        } else {
          console.error('No data in response');
        }
      } catch (error) {
        console.error('Failed to fetch invoice:', error);
      } finally {
        setLoading(false);
      }
    };

    if (invoiceId) {
      fetchInvoiceAndClientData();
    }
  }, [invoiceId]);

  useEffect(() => {
    if (invoice) {
      console.log('Current Invoice State:', invoice);
    }
  }, [invoice]);

  const handleDownloadPDF = async () => {
    if (!invoiceRef.current) {
      console.error('Invoice element not found');
      return;
    }

    try {
      // First, convert the element to an image
      const element = invoiceRef.current;
      const padding = 20; // Add padding around the content
      
      // Set white background and capture the image
      element.style.background = 'white';
      const dataUrl = await toPng(element, {
        quality: 1.0,
        backgroundColor: 'white',
        width: element.offsetWidth + (padding * 2),
        height: element.offsetHeight + (padding * 2),
        style: {
          padding: `${padding}px`,
        },
      });

      // Create PDF
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      
      const img = new Image();
      img.src = dataUrl;
      
      await new Promise((resolve) => {
        img.onload = () => {
          const aspectRatio = img.height / img.width;
          const imgHeight = imgWidth * aspectRatio;
          
          const pdf = new jsPDF({
            orientation: imgHeight > pageHeight ? 'portrait' : 'portrait',
            unit: 'mm',
            format: 'a4',
          });

          // Add image to PDF
          pdf.addImage(
            dataUrl,
            'PNG',
            0,
            0,
            imgWidth,
            imgHeight,
            undefined,
            'FAST'
          );

          // Save the PDF
          pdf.save(`invoice-${invoice?.invoiceNumber || 'download'}.pdf`);
          resolve(true);
        };
      });

      console.log('PDF generated successfully');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  

  if (loading) {
    return <Loading />
  }

  if (!invoice) {
    return <div className="flex items-center justify-center h-screen">
      <div className="text-lg text-red-600">Invoice not found. ID: {invoiceId}</div>
    </div>;
  }

  return (
    <div className="flex bg-seashell">
      <div
        className={cn(
          "w-80 lg:w-96 border-r border-mercury bg-white",
          isSidebarOpen ? "block" : "hidden lg:block"
        )}
      >
        <div className="h-full flex flex-col">
          <div className="p-4 border-b border-mercury">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <Button 
                  variant="ghost" 
                  size="sm"
                  onClick={() => navigate('/accounting/invoices-list')}
                  className="text-hazel-green hover:text-sage flex items-center gap-2"
                >
                  <ArrowLeft className="h-4 w-4" />
                  Back to Invoices
                </Button>
              </div>
            </div>
            <div className="relative">
              <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
              <input
                type="text"
                placeholder="Search invoices..."
                className="w-full pl-10 pr-4 py-2 border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
              />
            </div>
          </div>
          <InvoiceSidebar
            selectedInvoiceId={invoiceId}
            onInvoiceSelect={(id) => {
              navigate(`/accounting/invoices/${id}`);
              setIsSidebarOpen(false);
            }}
          />
        </div>
      </div>

      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="lg:hidden absolute top-4 left-4 z-10 p-2 bg-white rounded-lg shadow-md"
      >
        <Menu className="h-6 w-6 text-hazel-green" />
      </button>

      {isSidebarOpen && (
        <div
          className="lg:hidden fixed inset-0 bg-black/20 z-20"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      <div className="flex-1 w-full overflow-auto">
        <div className="p-4 lg:p-6 max-w-[1200px] mx-auto">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6 gap-4">
            <h1 className="text-xl font-semibold text-hazel-green">{invoice.id}</h1>
            <div className="flex flex-wrap items-center gap-3">
              <Button variant="outline" size="sm" className="text-bluish-grey">
                <PaperclipIcon className="h-4 w-4 mr-2" />
                <span className="hidden sm:inline">Attach Files</span>
              </Button>
              <Button variant="outline" size="sm" className="text-bluish-grey">
                <MessageSquare className="h-4 w-4 mr-2" />
                <span className="hidden sm:inline">Comments</span>
              </Button>
              <Button variant="ghost" size="sm">
                <X className="h-4 w-4 text-bluish-grey" />
              </Button>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm border border-mercury">
            <div ref={invoiceRef} className="pdf-container bg-white p-4 lg:p-6">
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6 gap-4">
                <div className="flex items-center gap-4">
                  <input type="checkbox" className="rounded border-mercury" />
                  <span className="text-bluish-grey font-medium">
                    {invoice.clientName}
                  </span>
                  <span className="text-bluish-grey font-semibold">
                    €{invoice.totalAmount.toFixed(2)}
                  </span>
                </div>
                <div className="flex flex-wrap items-center gap-3">
                  <Button variant="outline" size="sm">
                    <Edit className="h-4 w-4 sm:mr-2" />
                    <span className="hidden sm:inline">Edit</span>
                  </Button>
                  <Button variant="outline" size="sm">
                    <Send className="h-4 w-4 sm:mr-2" />
                    <span className="hidden sm:inline">Send</span>
                  </Button>
                  <Button variant="outline" size="sm">
                    <Share className="h-4 w-4 sm:mr-2" />
                    <span className="hidden sm:inline">Share</span>
                  </Button>
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={handleDownloadPDF}
                  >
                    <Printer className="h-4 w-4 sm:mr-2" />
                    <span className="hidden sm:inline">PDF</span>
                  </Button>
                  <Button variant="ghost" size="sm">
                    <MoreHorizontal className="h-4 w-4 text-bluish-grey" />
                  </Button>
                </div>
              </div>

              <InvoiceDocument 
                invoiceNumber={invoice.invoiceNumber}
                date={invoice.date}
                clientName={invoice.clientName}
                items={invoice.items}
                totalAmount={invoice.totalAmount}
                status={invoice.status}
                notes={invoice.notes}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
