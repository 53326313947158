import React from "react";
import Heading from "../common/Heading";

export default function PageNotFound() {
  return (
    <>
      <Heading
        heading="Page Not Found"
        title="Home"
        subtitle="Page Not Found"
      />
    </>
  );
}
