import React from 'react';

interface CreateCaseProps {
  roomNumber: string;
}

export default function CreateCase({ roomNumber }: CreateCaseProps) {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">
          Case Title <span className="text-watermelon">*</span>
        </label>
        <input
          type="text"
          value={`Apartment - Room - ${roomNumber} // `}
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">
            Reason <span className="text-watermelon">*</span>
          </label>
          <select className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green">
            <option value="">Select Reason</option>
            <option value="maintenance">Maintenance</option>
            <option value="cleaning">Cleaning</option>
            <option value="complaint">Complaint</option>
          </select>
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">
            Sub Reason <span className="text-watermelon">*</span>
          </label>
          <select className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green">
            <option value="">Select Sub-Reason</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">
            Origin <span className="text-watermelon">*</span>
          </label>
          <select className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green">
            <option value="">Select Origin</option>
          </select>
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">
            Priority <span className="text-watermelon">*</span>
          </label>
          <select className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green">
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
        </div>
      </div>

      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">Attach Images</label>
        <input
          type="file"
          multiple
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
        />
      </div>

      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">Issue Type</label>
        <div className="space-y-1.5">
          <label className="flex items-center space-x-2 text-sm">
            <input type="radio" name="issueType" className="text-hazel-green" />
            <span>Guest related issue (case will be associated with booking)</span>
          </label>
          <label className="flex items-center space-x-2 text-sm">
            <input type="radio" name="issueType" className="text-hazel-green" />
            <span>Apartment related issue (case will be associated with apartment)</span>
          </label>
        </div>
      </div>

      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">
          Description <span className="text-watermelon">*</span>
        </label>
        <textarea
          rows={3}
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          placeholder="Describe Task Details"
        ></textarea>
      </div>

      <div className="flex justify-end space-x-3 pt-2">
        <button className="px-4 py-1.5 text-sm bg-hazel-green text-white rounded-md hover:bg-sage transition-colors">
          Create Case
        </button>
      </div>
    </div>
  );
}