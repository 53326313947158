import api from './api';

// Purchases
export const createPurchase = async (data) => {
  console.log('Creating purchase with data:', data);
  try {
    const response = await api.post('/api/purchase', data);
    console.log('Purchase creation response:', response);
    return response;
  } catch (error) {
    console.error('Error creating purchase:', error);
    throw error;
  }
};

export const getPurchases = async (filters = {}) => {
  try {
    console.log('Fetching purchases with filters:', filters);
    const response = await api.get('/api/purchase', { params: filters });
    console.log('Raw API response:', response);
    return response;
  } catch (error) {
    console.error('Error getting purchases:', error);
    throw error;
  }
};

export const getPurchaseById = async (id) => {
  try {
    const response = await api.get(`/api/purchase/${id}`);
    return response;
  } catch (error) {
    console.error('Error getting purchase:', error);
    throw error;
  }
};

export const updatePurchase = async (id, data) => {
  console.log('Updating purchase with data:', data);
  try {
    const response = await api.patch(`/api/purchase/${id}`, data);
    console.log('Purchase update response:', response);
    return response;
  } catch (error) {
    console.error('Error updating purchase:', error);
    throw error;
  }
};

export const deletePurchase = async (id) => {
  try {
    console.log('Deleting purchase:', id);
    const response = await api.delete(`/api/purchase/${id}`);
    console.log('Purchase delete response:', response);
    return response;
  } catch (error) {
    console.error('Error deleting purchase:', error);
    throw error;
  }
};

// Returns
export const getPurchaseForReturn = async (documentNumber) => {
  try {
    console.log('Getting purchase for return:', documentNumber);
    if (!documentNumber) {
      throw new Error('Document number is required');
    }
    
    const response = await api.get(`/api/purchase/for-return/${encodeURIComponent(documentNumber)}`);
    console.log('Purchase for return response:', response);
    
    if (!response?.data?.message) {
      throw new Error('Invalid response format from server');
    }
    
    return response;
  } catch (error) {
    console.error('Error getting purchase for return:', error);
    throw error;
  }
};

export const createPurchaseReturn = async (data) => {
  console.log('Creating purchase return with data:', data);
  try {
    const response = await api.post('/api/purchase/return', data);
    console.log('Purchase return creation response:', response);
    return response;
  } catch (error) {
    console.error('Error in createPurchaseReturn:', error.response || error);
    throw error;
  }
};

export const getPurchaseReturns = async (purchaseId) => {
  try {
    const response = await api.get(`/api/purchase/${purchaseId}/returns`);
    return response;
  } catch (error) {
    console.error('Error getting purchase returns:', error);
    throw error;
  }
};

// Expense Returns
export const getExpenseForReturn = async (documentNumber) => {
  try {
    const response = await api.get(`/api/purchase/for-return/${documentNumber}`);
    return response;
  } catch (error) {
    console.error('Error getting expense for return:', error);
    throw error;
  }
};

export const createExpenseReturn = async (data) => {
  console.log('Creating expense return with data:', data);
  try {
    const response = await api.post(`/api/purchase/${data.expenseId}/expense-return`, data);
    console.log('Expense return creation response:', response);
    return response;
  } catch (error) {
    console.error('Error creating expense return:', error);
    throw error;
  }
};

export const getExpenseReturns = async (expenseId) => {
  try {
    const response = await api.get(`/api/purchase/${expenseId}/expense-returns`);
    return response;
  } catch (error) {
    console.error('Error getting expense returns:', error);
    throw error;
  }
};

// Asset Returns
export const getAssetForReturn = async (documentNumber) => {
  try {
    const response = await api.get(`/api/purchase/asset-for-return/${documentNumber}`);
    return response;
  } catch (error) {
    console.error('Error getting asset for return:', error);
    throw error;
  }
};

export const createAssetReturn = async (data) => {
  console.log('Creating asset return with data:', data);
  try {
    const response = await api.post(`/api/purchase/asset-return`, data);
    console.log('Asset return creation response:', response);
    return response;
  } catch (error) {
    console.error('Error creating asset return:', error);
    throw error;
  }
};

export const getAssetReturns = async (assetId) => {
  try {
    const response = await api.get(`/api/purchase/${assetId}/asset-returns`);
    return response;
  } catch (error) {
    console.error('Error getting asset returns:', error);
    throw error;
  }
};

// Document Numbers
export const getNextPurchaseDocumentNumber = async (type) => {
  try {
    const response = await api.get(`/api/purchase/document-number/${type}`);
    return response;
  } catch (error) {
    console.error('Error getting next purchase document number:', error);
    throw error;
  }
};

export const getNextExpenseDocumentNumber = async () => {
  try {
    const response = await api.get(`/api/purchase/document-number/EXPENSE`);
    return response;
  } catch (error) {
    console.error('Error getting next expense document number:', error);
    throw error;
  }
};

export const getNextReturnDocumentNumber = async () => {
  try {
    const response = await api.get('/api/purchase/return-document-number');
    console.log('Return document number API response:', response);
    
    if (response?.data?.statusCode === 200 && response?.data?.message?.documentNumber) {
      return {
        data: {
          message: response.data.message
        }
      };
    }
    
    console.error('Unexpected response format:', response);
    throw new Error('Invalid response format from return document number API');
  } catch (error) {
    console.error('Error getting next return document number:', error);
    throw error;
  }
};
