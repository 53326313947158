import api from './noAuthApi';

export const generateBalanceSheet = async (data) => {
  try {
    const response = await api.post('/api/balance-sheet', data);
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const getBalanceSheet = async (id) => {
  try {
    const response = await api.get(`/api/balance-sheet/${id}`);
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const listBalanceSheets = async (locationId) => {
  try {
    const response = await api.get(`/api/balance-sheet/location/${locationId}`);
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const updateBalanceSheetStatus = async (id, status) => {
  try {
    const response = await api.patch(`/api/balance-sheet/${id}/status`, { status });
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};
