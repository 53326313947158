import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLarge } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import UserForm from './UserForm';

function AddUser() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faUserLarge}
            className='text-hazel-green mr-2'
          />
        }
        title='Create a new user'
      />
      <UserForm
        isEdit={false}
        user={{
          personalNumber: '',
          name: '',
          surname: '',
          email: '',
          password: '',
          jobRole: '',
          wage: '',
          phoneNumber: '',
          address: '',
          roleId: ''
        }}
      />
    </div>
  );
}

export default AddUser;
