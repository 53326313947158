import React, { useState, useEffect } from 'react';
import { getProductsByType, updateInventory } from '../../api/inventory';
import Loading from '../loader';
import LoadingCircle from '../loading-circle';
import { SearchFilter } from '../table-components';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MinibarForm({ roomMinibar, setShowModal, setFieldValue }) {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  console.log(roomMinibar)

  useEffect(() => {
    getProductsList();
  }, []);

  useEffect(() => {
    const filteredData = products.filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredProducts(filteredData);
  }, [searchText, products]);

  const getProductsList = async () => {
    try {
      const response = await getProductsByType('DRINK');
      const updatedProducts = response.data.map((product) => {
        const minibarProduct = roomMinibar?.products.find(
          (item) => item.id === product.id
        );

        const currentQuantity = minibarProduct ? minibarProduct.quantity : 0;
        const maxQuantity = currentQuantity + product.quantity;
        return {
          ...product,
          currentQuantity,
          maxQuantity
        };
      });
      setProducts(updatedProducts);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleQuantityChange = (productId, newQuantity) => {
    const updatedProducts = products.map((product) => {
      if (product.id === productId) {
        return {
          ...product,
          currentQuantity: newQuantity
        };
      }
      return product;
    });
    setProducts(updatedProducts);
  };


  const handleSave = async () => {
    setSubmitting(true);

    console.log("Room minibar", roomMinibar)
    
    if (!roomMinibar || !roomMinibar.id) {
      console.error("Minibar ID is undefined. RoomMinibar:", roomMinibar);
      setSubmitting(false);
      return;
    }

    const increaseProducts = [];
    const decreaseProducts = [];

    products.forEach((product) => {
      const minibarProduct = roomMinibar?.products.find(
        (item) => item.id === product.id
      );
      if (!minibarProduct && product.currentQuantity > 0) {
        increaseProducts.push({
          productId: product.id,
          quantity: product.currentQuantity
        });
      } else if (product.currentQuantity > minibarProduct?.quantity) {
        increaseProducts.push({
          productId: product.id,
          quantity: product.currentQuantity - minibarProduct.quantity
        });
      } else if (product.currentQuantity < minibarProduct?.quantity) {
        decreaseProducts.push({
          productId: product.id,
          quantity: minibarProduct.quantity - product.currentQuantity
        });
      }
    });

    try {
      const response = await updateInventory(roomMinibar.id, {
        increaseProducts,
        decreaseProducts
      });
      const updatedMinibarData = response.data;
      setFieldValue('minibar', updatedMinibarData);
      setSubmitting(false);
      window.location.reload();
    } catch (err) {
      console.error("Error updating inventory:", err);
      toast.error("Not enough items on the room inventroy to craete minibar item");
      setSubmitting(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <SearchFilter
        searchItem={(searchText) => {
          setSearchText(searchText);
        }}
      />
      {filteredProducts.map((item) => (
        <div
          key={item.id}
          className='flex justify-between items-center text-hazel-green p-2 pb-1 border-b'
        >
          <p>{item.name}</p>
          <input
            type='number'
            value={item.currentQuantity}
            min={0}
            max={item.maxQuantity}
            onChange={(e) =>
              handleQuantityChange(item.id, parseInt(e.target.value))
            }
            placeholder='Quantity'
            className='w-16 border rounded-lg p-2'
          />
        </div>
      ))}
      <div className='flex justify-end items-center w-full mt-7 text-sm'>
        <button
          type='submit'
          className={`bg-sage w-1/5 text-white rounded-md py-3`}
          onClick={handleSave}
          disabled={isSubmitting}
        >
          {isSubmitting ? <LoadingCircle /> : 'Save'}
        </button>
      </div>
    </div>
  );
}

export default MinibarForm;
