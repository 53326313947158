import React, { useState, useEffect } from 'react';
import { Plus, Search, Settings, ChevronDown, FileText, Pencil, SlidersHorizontal, Download, Eye, Edit, Trash2, ChevronLeft, ChevronRight, Calendar, Clock, User, FileX } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { getOffers, deleteOffer } from '../../../../api/offer';
import toast from 'react-hot-toast';
import ViewOfferModal from '../../../../components/ViewOfferModal.tsx';
import DeleteConfirmationModal from '../../../../components/articles/DeleteConfirmationModal.tsx';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectGroup, SelectItem, SelectLabel } from '../../../../components/shadCN/Select.tsx';

import { OfferStatus } from '../../../../types/offer.ts';

interface Offer {
  id: string;
  documentNumber: string;
  description?: string;
  issueDate: string;
  expiryDate: string;
  status: string;
  client: {
    name: string;
  };
  location?: {
    name: string;
  };
  articles: Array<{
    quantity: number;
    unitPrice: number;
    vatRate: number;
    totalAmount: number;
  }>;
  createdAt: string;
  createdBy: string;
}

const OffersPage = () => {
  const navigate = useNavigate();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    limit: 6,
    totalPages: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [offerToDelete, setOfferToDelete] = useState<string | null>(null);

  const fetchOffers = async (page = 1) => {
    try {
      setIsLoading(true);
      const filters = {
        search: searchQuery || undefined,
        status: statusFilter !== 'all' ? statusFilter : undefined,
        page,
        limit: pagination.limit
      };
      const response = await getOffers(filters);
      setOffers(response.data.offers);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error('Error fetching offers:', error);
      toast.error('Failed to fetch offers');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOffers(1);
  }, [searchQuery, statusFilter]);

  const handleDeleteClick = (offerId: string) => {
    setOfferToDelete(offerId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!offerToDelete) return;

    try {
      await deleteOffer(offerToDelete);
      toast.success('Oferta u fshi me sukses');
      fetchOffers(1);
    } catch (error) {
      console.error('Error deleting offer:', error);
      toast.error('Dështoi fshirja e ofertës');
    } finally {
      setIsDeleteModalOpen(false);
      setOfferToDelete(null);
    }
  };

  const handleViewOffer = (offer: Offer) => {
    setSelectedOffer(offer);
    setIsViewModalOpen(true);
  };

  const handleEditClick = (offerId: string) => {
    navigate(`/accounting/offers/edit/${offerId}`);
  };

  const getStatusBadgeStyle = (status: string) => {
    switch (status?.toUpperCase()) {
      case OfferStatus.DRAFT:
        return 'bg-grey-goose/10 text-grey-goose';
      case OfferStatus.ACTIVE:
        return 'bg-sage/10 text-sage';
      case OfferStatus.EXPIRED:
        return 'bg-watermelon/10 text-watermelon';
      case OfferStatus.REJECTED:
        return 'bg-bluish-grey/10 text-bluish-grey';
      default:
        return 'bg-grey-goose/10 text-grey-goose';
    }
  };

  const getStatusLabel = (status: string) => {
    switch (status?.toUpperCase()) {
      case OfferStatus.DRAFT:
        return 'Draft';
      case OfferStatus.ACTIVE:
        return 'Aktive';
      case OfferStatus.EXPIRED:
        return 'Skaduar';
      case OfferStatus.REJECTED:
        return 'Refuzuar';
      default:
        return status;
    }
  };

  const handlePageChange = (newPage: number) => {
    fetchOffers(newPage);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <h1 className="text-xl font-semibold text-hazel-green">Ofertat</h1>
            <div className="flex items-center gap-x-4">
              <button
                onClick={() => navigate('/accounting/offers/new')}
                className="inline-flex items-center gap-x-2 px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
              >
                <Plus className="h-5 w-5" />
                Shto Ofertë
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
          {/* Filters */}
          <div className="border-b border-mercury/50 bg-gradient-to-r from-hazel-green/5 to-transparent p-4">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
              <div className="flex items-center gap-x-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Kërko..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-10 pr-4 py-2 rounded-lg border border-mercury bg-white/50 text-bluish-grey placeholder-grey-goose focus:border-hazel-green focus:ring-1 focus:ring-hazel-green transition-colors"
                  />
                  <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-grey-goose" />
                </div>
                <Select
                  value={statusFilter}
                  onValueChange={setStatusFilter}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Filtro sipas statusit" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="all">Të gjitha</SelectItem>
                      <SelectItem value="DRAFT">Draft</SelectItem>
                      <SelectItem value="ACTIVE">Aktive</SelectItem>
                      <SelectItem value="EXPIRED">Skaduar</SelectItem>
                      <SelectItem value="REJECTED">Refuzuar</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex items-center gap-x-2">
                <button className="p-2 rounded-lg border border-mercury hover:border-hazel-green/50 hover:bg-seashell/50 text-bluish-grey hover:text-hazel-green transition-all duration-200">
                  <SlidersHorizontal className="h-5 w-5" />
                </button>
                <button className="p-2 rounded-lg border border-mercury hover:border-hazel-green/50 hover:bg-seashell/50 text-bluish-grey hover:text-hazel-green transition-all duration-200">
                  <Download className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>

          {/* Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-mercury/50">
              <thead>
                <tr className="bg-gradient-to-r from-hazel-green/5 to-transparent">
                  <th scope="col" className="py-4 pl-4 pr-3 text-left text-sm font-semibold text-hazel-green">
                    #Emri
                  </th>
                  <th scope="col" className="px-3 py-4 text-left text-sm font-semibold text-hazel-green">
                    #Tipi
                  </th>
                  <th scope="col" className="px-3 py-4 text-right text-sm font-semibold text-hazel-green">
                    Totali
                  </th>
                  <th scope="col" className="px-3 py-4 text-center text-sm font-semibold text-hazel-green">
                    #Statusi
                  </th>
                  <th scope="col" className="px-3 py-4 text-left text-sm font-semibold text-hazel-green">
                    Klienti
                  </th>
                  <th scope="col" className="px-3 py-4 text-left text-sm font-semibold text-hazel-green">
                    Lokacioni
                  </th>
                  <th scope="col" className="px-3 py-4 text-left text-sm font-semibold text-hazel-green">
                    Data e lëshimit
                  </th>
                  <th scope="col" className="px-3 py-4 text-left text-sm font-semibold text-hazel-green">
                    Data e skadimit
                  </th>
                  <th scope="col" className="px-3 py-4 text-left text-sm font-semibold text-hazel-green">
                    Krijuar më
                  </th>
                  <th scope="col" className="px-3 py-4 text-left text-sm font-semibold text-hazel-green">
                    Krijuar nga
                  </th>
                  <th scope="col" className="relative py-4 pl-3 pr-4">
                    <span className="sr-only">Veprime</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-mercury/50">
                {offers.map((offer, index) => (
                  <tr
                    key={offer.id}
                    className={`group transition-colors duration-150 ${
                      index % 2 === 0 ? 'bg-white hover:bg-seashell/50' : 'bg-seashell/20 hover:bg-seashell/50'
                    }`}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                      <div className="flex items-center">
                        <FileText className="h-5 w-5 text-hazel-green/70 mr-2" />
                        <span className="font-medium text-bluish-grey">{offer.documentNumber}</span>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4">
                      <span className="inline-flex items-center rounded-md bg-sage/10 px-2 py-1 text-xs font-medium text-sage">
                        {offer.description}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-right">
                      <span className="font-medium text-hazel-green">{offer.articles[0].totalAmount.toFixed(2)} €</span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4">
                      <div className="flex justify-center">
                        <span className={`inline-flex items-center gap-1 rounded-full px-2.5 py-0.5 text-xs font-medium ${getStatusBadgeStyle(offer.status)}`}>
                          <span className={`h-1.5 w-1.5 rounded-full ${offer.status === 'ACTIVE' ? 'bg-sage' : 'bg-grey-goose'}`} />
                          {getStatusLabel(offer.status)}
                        </span>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-bluish-grey">
                      {offer.client.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-bluish-grey">
                      {offer.location?.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-bluish-grey">
                      <div className="flex items-center gap-1">
                        <Calendar className="h-4 w-4 text-grey-goose" />
                        {offer.issueDate}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-bluish-grey">
                      <div className="flex items-center gap-1">
                        <Calendar className="h-4 w-4 text-grey-goose" />
                        {offer.expiryDate}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-bluish-grey">
                      <div className="flex items-center gap-1">
                        <Clock className="h-4 w-4 text-grey-goose" />
                        {offer.createdAt}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-bluish-grey">
                      <div className="flex items-center gap-1">
                        <User className="h-4 w-4 text-grey-goose" />
                        {offer.createdBy}
                      </div>
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4">
                      <div className="flex justify-end gap-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                        <button 
                          className="p-1.5 rounded-lg hover:bg-sage/10 text-bluish-grey hover:text-sage transition-colors"
                          title="Shiko"
                          onClick={() => handleViewOffer(offer)}
                        >
                          <Eye className="h-4 w-4" />
                        </button>
                        <button 
                          className="p-1.5 rounded-lg hover:bg-hazel-green/10 text-bluish-grey hover:text-hazel-green transition-colors"
                          title="Ndrysho"
                          onClick={() => handleEditClick(offer.id)}
                        >
                          <Edit className="h-4 w-4" />
                        </button>
                        <button 
                          className="p-1.5 rounded-lg hover:bg-watermelon/10 text-bluish-grey hover:text-watermelon transition-colors"
                          title="Fshij"
                          onClick={() => handleDeleteClick(offer.id)}
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
                {offers.length === 0 && (
                  <tr>
                    <td colSpan={11} className="py-12">
                      <div className="text-center">
                        <FileX className="mx-auto h-12 w-12 text-grey-goose" />
                        <h3 className="mt-2 text-sm font-medium text-bluish-grey">Nuk ka të dhëna</h3>
                        <p className="mt-1 text-sm text-grey-goose">Nuk u gjet asnjë ofertë për të shfaqur.</p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex items-center justify-between border-t border-mercury/50 bg-gradient-to-r from-hazel-green/5 to-transparent px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
              <button 
                onClick={() => handlePageChange(pagination.page - 1)}
                disabled={pagination.page === 1}
                className="relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-bluish-grey hover:text-hazel-green disabled:opacity-50"
              >
                Previous
              </button>
              <button 
                onClick={() => handlePageChange(pagination.page + 1)}
                disabled={pagination.page === pagination.totalPages}
                className="relative ml-3 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-bluish-grey hover:text-hazel-green disabled:opacity-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-grey-goose">
                  Duke shfaqur <span className="font-medium text-bluish-grey">{((pagination.page - 1) * pagination.limit) + 1}</span> deri{' '}
                  <span className="font-medium text-bluish-grey">{Math.min(pagination.page * pagination.limit, pagination.total)}</span> nga{' '}
                  <span className="font-medium text-bluish-grey">{pagination.total}</span> rezultate
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                  <button 
                    onClick={() => handlePageChange(pagination.page - 1)}
                    disabled={pagination.page === 1}
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-bluish-grey hover:text-hazel-green focus:z-20 disabled:opacity-50"
                  >
                    <ChevronLeft className="h-5 w-5" />
                  </button>
                  {[...Array(pagination.totalPages)].map((_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageChange(index + 1)}
                      className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                        pagination.page === index + 1
                          ? 'text-hazel-green bg-seashell/50'
                          : 'text-bluish-grey hover:text-hazel-green'
                      } focus:z-20`}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button 
                    onClick={() => handlePageChange(pagination.page + 1)}
                    disabled={pagination.page === pagination.totalPages}
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-bluish-grey hover:text-hazel-green focus:z-20 disabled:opacity-50"
                  >
                    <ChevronRight className="h-5 w-5" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedOffer && (
        <ViewOfferModal
          isOpen={isViewModalOpen}
          onClose={() => setIsViewModalOpen(false)}
          offer={selectedOffer}
        />
      )}

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setOfferToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
        title="Fshi Ofertën"
        message="A jeni të sigurt që dëshironi të fshini këtë ofertë? Ky veprim nuk mund të kthehet."
      />
    </div>
  );
};

export default OffersPage;
