import { Search, Plus, ChevronRight } from 'lucide-react';
import { useState } from 'react';
import { Button } from '../../../../../components/shadCN/Button.tsx';
import { Link } from 'react-router-dom';
import React from 'react';
import BudgetForm from '../../../../../components/budgets/BudgetForm.tsx';
const budgets = [
  {
    id: 1,
    name: 'Annual Budget 2024',
    fiscalYear: 'Jan 2024 - Dec 2024',
    period: 'Monthly'
  },
  {
    id: 2,
    name: 'Q1 Marketing Budget',
    fiscalYear: 'Jan 2024 - Mar 2024',
    period: 'Monthly'
  },
  {
    id: 3,
    name: 'H1 Operations Budget',
    fiscalYear: 'Jan 2024 - Jun 2024',
    period: 'Monthly'
  }
];

export default function BudgetList() {

    const [isFormOpen, setIsFormOpen] = useState(false);

  return (
    <div className="min-h-screen bg-seashell p-8">
      <div className="mb-8 flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-hazel-green">Budgets</h1>
        <Button onClick={() => setIsFormOpen(true)} className="bg-hazel-green hover:bg-sage text-white">
          <Plus className="h-4 w-4 mr-2" />
          New Budget
        </Button>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-mercury">
        <div className="p-4 border-b border-mercury">
          <div className="relative w-64">
            <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
            <input
              type="text"
              placeholder="Search budgets..."
              className="pl-10 pr-4 py-2 w-full border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4 p-4 bg-tealish-blue">
          <div className="text-sm font-medium text-bluish-grey">NAME</div>
          <div className="text-sm font-medium text-bluish-grey">FISCAL YEAR</div>
          <div className="text-sm font-medium text-bluish-grey">BUDGET PERIOD</div>
        </div>

        <div className="divide-y divide-mercury">
          {budgets.map((budget) => (
            <Link
              key={budget.id}
              to={`/accounting/budgets/${budget.id}`}
              className="grid grid-cols-3 gap-4 p-4 hover:bg-seashell transition-colors duration-200 group"
            >
              <div className="flex items-center">
                <span className="text-hazel-green font-medium group-hover:text-sage">
                  {budget.name}
                </span>
                <ChevronRight className="h-4 w-4 ml-2 text-bluish-grey opacity-0 group-hover:opacity-100 transition-opacity" />
              </div>
              <div className="text-bluish-grey">{budget.fiscalYear}</div>
              <div className="text-bluish-grey">{budget.period}</div>
            </Link>
          ))}
        </div>
      </div>

      <BudgetForm isOpen={isFormOpen} onClose={() => setIsFormOpen(false)} />
    </div>
  );
}