import api from './api';

export const createReservation = (values) =>
  api.post(`/api/reservations`, values);

export const updateReservation = (id, values) =>
  api.patch(`/api/reservations/${id}`, values);

export const giveReservationFeedback = async (id, values) => {
  try {
    console.log('Sending feedback for reservation ID:', id);
    console.log('Feedback data:', values);
    const response = await api.patch(`/api/reservations/room-reservations/${id}/feedback/`, values);
    return response.data;
  } catch (error) {
    console.error('Error giving reservation feedback:', error);
    throw error; // Rethrow or handle the error as needed
  }
};


export const checkReservationExists = async (roomId) => {
  try {
      const response = await api.get(`/api/reservations/${roomId}/exists`); // Adjust the endpoint as needed
      return response.data.exists; // Assume the response contains a boolean
  } catch (error) {
      console.error("Error checking reservation existence:", error);
      return false; // If there's an error, assume the reservation does not exist
  }
};


export const getRoomHistory = (id) => api.get(`/api/reservations/rooms/${id}`);

export const getReservation = (id) => api.get(`/api/reservations/${id}`);

// export const getAllReservationsHistory = () => api.get(`/api/reservations/history`);

export const getAllReservationsHistory = (page = 1, limit = 10) => 
  api.get(`/api/reservations/history?page=${page}&limit=${limit}`);