import { useEffect, useState } from 'react';
import { getAccountById, getAccounts } from '../../../../../api/account.js'; // Ensure you import getAccounts
import { useParams, Link } from 'react-router-dom';
import { ChevronDown, Lock, Pencil, Paperclip, X, Plus, ArrowLeft, Search } from 'lucide-react';
import { Button } from '../../../../../components/shadCN/Button.tsx';

import { getTransactionsByAccountId } from '../../../../../api/account.js';

import CreateAccountForm from '../../../../../components/accounts/CreateAccountForm.tsx';
import Loading from '../../../../../components/loader.js';
import { cn } from '../../../../../utils/cn.ts';

export default function AccountDetails() {
  const { id } = useParams();
  const [account, setAccount] = useState(null);
  const [transactions, setTransactions] = useState([]);

  const ITEMS_PER_PAGE = 5;

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const [accounts, setAccounts] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const response = await getAccountById(id);
        setAccount(response.data); // Assuming the account data is in response.data
      } catch (err) {
        setError('Account not found');
      }
    };

    const fetchTransactions = async () => {
      try {
        const response = await getTransactionsByAccountId(id);
        setTransactions(response.data);
      } catch (err) {
        console.error('Error fetching transactions:', err);
        setError('Failed to fetch transactions');
      }
    };


    const fetchAccounts = async () => {
      try {
        const response = await getAccounts(); // Fetch all accounts
        setAccounts(response.data); // Assuming response.data contains the accounts array
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    // Fetch account and all accounts concurrently
    Promise.all([fetchAccount(), fetchAccounts(), fetchTransactions()])
      .finally(() => {
        setLoading(false);
    
      });
  }, [id]);

  const capitalizeFirstLetter = (str) => {
    if (!str) return ''; // Handle empty string
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const filteredTransactions = transactions?.filter((transaction) =>
    transaction.transactionType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    transaction.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    transaction.paymentType?.toLowerCase().includes(searchQuery.toLowerCase())
  ) || [];

  const paginatedTransactions = filteredTransactions.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const totalPages = Math.ceil(filteredTransactions.length / ITEMS_PER_PAGE);

  const getPageNumbers = (currentPage, totalPages) => {
    const pageNumbers = [];
    const totalPagesToShow = 3;

    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

    if (endPage - startPage + 1 < totalPagesToShow) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  if (loading) return <Loading />;
  if (error) return <div>{error}</div>;
  if (!account) return <div>Account not found</div>;

  return (
    <div className="flex h-screen bg-seashell">
      {/* Sidebar */}
      <div className="w-72 h-screen border-r border-mercury bg-white flex flex-col">
  {/* Header Section */}
  <div className="p-5 border-b border-mercury">
    <Link to="/accounting/accounts" className="flex items-center gap-2 text-bluish-grey mb-6 hover:text-sage transition-colors">
      <ArrowLeft className="h-4 w-4" />
      Back to Accounts
    </Link>
    <div className="flex items-center justify-between">
      <h2 className="font-semibold text-hazel-green">Active Accounts</h2>
      <Button onClick={() => setIsFormOpen(true)} variant="outline" size="sm" className="border-grey-goose hover:bg-seashell">
        <Plus className="h-4 w-4" />
      </Button>
    </div>
  </div>

  {/* Scrollable Account List */}
  <div className="flex-1 overflow-y-auto divide-y divide-mercury">
    {/* Map over accounts to display in the sidebar */}
    {accounts.map((a) => (
      <Link
        key={a.id}
        to={`/accounting/account/${a.id}`}
        className={`block p-5 hover:bg-seashell transition-colors duration-200 ${
          a.id === Number(id) ? 'bg-tealish-blue border-l-2 border-hazel-green' : ''
        }`}
      >
        <div className="flex items-center gap-2">
          <Lock className="h-4 w-4 text-heather" />
          <span className="text-bluish-grey">{a.name}</span>
        </div>
        <div className="text-sm text-heather ml-6">
          {a.AccountSubCategory.length > 0
            ? capitalizeFirstLetter(a.AccountSubCategory[0].name)
            : 'N/A'}
        </div>
      </Link>
    ))}
  </div>
</div>


      {/* Main Content */}
      <div className="flex-1 overflow-auto">
        <div className="p-8">
          <div className="flex justify-between items-center mb-8">
            <div>
              <div className="text-sm text-heather mb-1">
                {account.AccountSubCategory.length > 0
                  ? capitalizeFirstLetter(account.AccountSubCategory[0].name)
                  : 'N/A'}
              </div>
              <h1 className="text-2xl font-semibold text-hazel-green">{account.name}</h1>
            </div>
            <div className="flex items-center gap-4">
              <Button variant="outline" className="border-grey-goose hover:bg-tealish-blue">
                <Paperclip className="h-4 w-4 mr-2" />
                Attach Files
                <X className="h-4 w-4 ml-2" />
              </Button>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm border border-mercury mb-8 p-6">
            <div className="flex items-center justify-between mb-6">
              <div>
                <div className="text-sm text-heather mb-1">CLOSING BALANCE</div>
                <div className="text-3xl font-semibold text-hazel-green">
                  {account.closingValue} <span className="text-bluish-grey">(Cr)</span>
                </div>
              </div>
              <Button variant="outline" className="border-grey-goose hover:bg-tealish-blue">
                <Pencil className="h-4 w-4 mr-2" />
                Edit
              </Button>
            </div>
            <div className="text-bluish-grey">
              <span className="font-medium">Description:</span> {account.description}
            </div>
          </div>

          {/* Transactions Section */}
          {transactions?.length > 0 ? (
            <div className="bg-white rounded-xl shadow-sm border border-mercury">
              <div className="p-4 border-b border-mercury">
                <div className="flex justify-between items-center">
                  <h3 className="font-semibold text-hazel-green">Transactions</h3>
                  <div className="flex items-center gap-4">
                    <div className="relative">
                      <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
                      <input
                        type="text"
                        placeholder="Search transactions..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="pl-10 pr-4 py-2 border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
                      />
                    </div>
                    <Button variant="outline" className="border-grey-goose hover:bg-tealish-blue">
                      <Plus className="h-4 w-4 mr-2" />
                      Add Transaction
                    </Button>
                  </div>
                </div>
              </div>

              <div className="overflow-x-auto">
                <table className="w-full min-w-[600px]">
                  <thead>
                    <tr className="bg-tealish-blue">
                      <th className="px-4 py-2 text-left text-xs font-semibold text-bluish-grey">DATE</th>
                      <th className="px-4 py-2 text-left text-xs font-semibold text-bluish-grey">TYPE</th>
                      <th className="px-4 py-2 text-left text-xs font-semibold text-bluish-grey">DESCRIPTION</th>
                      <th className="px-4 py-2 text-left text-xs font-semibold text-bluish-grey">PAYMENT</th>
                      <th className="px-4 py-2 text-left text-xs font-semibold text-bluish-grey">AMOUNT</th>
                      <th className="px-4 py-2 text-left text-xs font-semibold text-bluish-grey">STATUS</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-mercury">
                    {paginatedTransactions.map((transaction) => (
                      <tr key={transaction.id} className="hover:bg-seashell transition-colors duration-200">
                        <td className="px-4 py-2 text-sm text-bluish-grey">
                          {new Date(transaction.transactionDate).toLocaleDateString()}
                        </td>
                        <td className="px-4 py-2 text-sm text-bluish-grey">
                          {transaction.transactionType || 'N/A'}
                        </td>
                        <td className="px-4 py-2 text-sm text-bluish-grey">
                          {transaction.description || 'N/A'}
                        </td>
                        <td className="px-4 py-2 text-sm text-bluish-grey">
                          {transaction.paymentType || 'N/A'}
                        </td>
                        <td className="px-4 py-2 text-sm font-medium text-hazel-green">
                          ${Number(transaction.amount).toFixed(2)}
                        </td>
                        <td className="px-4 py-2 text-sm">
                          <span className={`px-2 py-1 rounded-full text-xs ${
                            transaction.isPaid 
                              ? 'bg-sage/20 text-sage' 
                              : 'bg-watermelon/20 text-watermelon'
                          }`}>
                            {transaction.isPaid ? 'Paid' : 'Pending'}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="p-4 border-t border-mercury flex justify-between items-center">
                <span className="text-sm text-bluish-grey">
                  Showing {paginatedTransactions.length} of {filteredTransactions.length} transactions
                </span>
                <div className="flex space-x-2">
                  {getPageNumbers(currentPage, totalPages).map((number) => (
                    <button
                      key={number}
                      onClick={() => setCurrentPage(number)}
                      className={cn(
                        'px-4 py-2 rounded-lg transition-colors duration-200',
                        currentPage === number 
                          ? 'bg-hazel-green text-white' 
                          : 'bg-white text-hazel-green hover:bg-tealish-blue'
                      )}
                    >
                      {number}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-xl shadow-sm border border-mercury p-8 text-center">
              <div className="mb-6">
                <div className="w-16 h-16 bg-seashell rounded-full flex items-center justify-center mx-auto mb-4">
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIgNUw4IDlIMTFWMTVIMTNWOUgxNkwxMiA1WiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+"
                    className="h-8 w-8 text-heather"
                    alt="No transactions"
                  />
                </div>
                <div className="text-bluish-grey font-medium">No Transactions Available</div>
                <p className="text-heather text-sm mt-2">Start adding transactions to see them here</p>
              </div>
              <Button variant="outline" className="border-grey-goose hover:bg-tealish-blue">
                <Plus className="h-4 w-4 mr-2" />
                Add Transaction
              </Button>
            </div>
          )}
        </div>
      </div>
      <CreateAccountForm isOpen={isFormOpen} onClose={() => setIsFormOpen(false)} />
    </div>
  );
}

