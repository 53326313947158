import api from './api';

export const getStockLossItems = (categoryId) => api.get('/api/stock-loss', { params: { category: categoryId } });
export const getStockLossItemById = (id) => api.get(`/api/stock-loss/${id}`);
export const addStockLossItem = async (data) => {
  console.log('Sending stock loss data to API:', JSON.stringify(data, null, 2));
  if (!data.adminUserId) {
    console.error('No adminUserId provided to addStockLossItem');
    throw new Error('Admin User ID is required');
  }
  
  try {
    const response = await api.post('/api/stock-loss', data);
    console.log('Stock loss API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in addStockLossItem:', {
      error: error.message,
      response: error.response?.data,
      data: data
    });
    throw error;
  }
};
export const updateStockLossItem = (id, data) => api.put(`/api/stock-loss/${id}`, data);
export const updateStockLossQuantity = (id, quantity) => api.put(`/api/stock-loss/${id}/quantity`, { quantity });
export const deleteStockLossItem = (id) => api.delete(`/api/stock-loss/${id}`);