import api from './api';

export const getPurchaseJournal = async (year, month, page = 1, pageSize = 5) => {
  try {
    const response = await api.get('/api/purchase-journal', {
      params: {
        year,
        month,
        page,
        pageSize
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const exportPurchaseJournal = async (year, month, format) => {
  try {
    const response = await api.get('/api/purchase-journal/export', {
      params: {
        year,
        month,
        format,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
