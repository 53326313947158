import React, { useState } from 'react';
import { X, AlertCircle } from 'lucide-react';
import BlockedRoomInfo from './tabs/BlockedRoomInfo.tsx';
import StatusChange from './tabs/StatusChange.tsx';

interface BlockedRoomModalProps {
  isOpen: boolean;
  onClose: () => void;
  roomNumber: string;
  fromDate: string;
  toDate: string;
  reason: string;
  notes: string;
  status: string;
}

const tabs = [
  { id: 'info', label: 'Room Information' },
  { id: 'status', label: 'Status Management' },
];

export default function BlockedRoomModal({
  isOpen,
  onClose,
  roomNumber,
  fromDate,
  toDate,
  reason,
  notes,
  status,
}: BlockedRoomModalProps) {
  const [activeTab, setActiveTab] = useState('info');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white w-full max-w-4xl rounded-lg shadow-xl">
        <div className="p-4 border-b border-grey-goose flex justify-between items-center bg-gradient-to-r from-hazel-green/10 to-transparent">
          <h2 className="text-xl font-semibold text-hazel-green flex items-center gap-2">
            Blocked Room Details - Room {roomNumber}
            <span className="text-sm px-2 py-0.5 bg-watermelon/10 text-watermelon rounded">
              {status}
            </span>
          </h2>

          <button
            onClick={onClose}
            className="text-bluish-grey hover:text-hazel-green transition-colors"
          >
            <X size={24} />
          </button>
        </div>

        <div className="border-b border-grey-goose bg-seashell">
          <nav className="flex px-4">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-6 py-3 text-sm font-medium transition-colors relative ${
                  activeTab === tab.id
                    ? 'text-hazel-green'
                    : 'text-bluish-grey hover:text-hazel-green'
                }`}
              >
                {tab.label}
                {activeTab === tab.id && (
                  <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-hazel-green" />
                )}
              </button>
            ))}
          </nav>
        </div>

        <div className="p-6 max-h-[calc(100vh-12rem)] overflow-y-auto">
          {activeTab === 'info' && (
            <BlockedRoomInfo
              roomNumber={roomNumber}
              fromDate={fromDate}
              toDate={toDate}
              reason={reason}
              notes={notes}
              status={status}
            />
          )}
          {activeTab === 'status' && (
            <StatusChange
              roomNumber={roomNumber}
              currentStatus={status}
            />
          )}
        </div>
      </div>
    </div>
  );
}