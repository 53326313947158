import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowLeft, Plus, Settings, Upload, X, Save } from 'lucide-react';
import { motion } from 'framer-motion';
import { getCompany } from '../../../api/company';
import { getSuppliers } from '../../../api/accounting';
import { getArticles } from '../../../api/accountingArticle';
import { getPurchaseById, updatePurchase } from '../../../api/purchase';
import { toast } from 'react-toastify';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue, SelectGroup, SelectLabel } from '../../../components/shadCN/Select.tsx';
import { Label } from '../../../components/shadCN/Label.tsx';
import { Button } from '../../../components/shadCN/Button.tsx';
import SupplierModal from '../../../components/SupplierModal';
import { Input } from '../../../components/shadCN/Input.tsx';
import { Textarea } from '../../../components/shadCN/TextArea.tsx';
import Loading from '../../../components/loader';

interface Article {
  id: string;
  articleId?: string;
  name: string;
  unit: string;
  quantity: number;
  priceWithoutVAT: number;
  vatAmount: number;
  totalWithVAT: number;
  vatRate?: number;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Supplier {
  id: string;
  name: string;
  businessName?: string;
  tradeName?: string;
  businessType?: string;
  fiscalNumber: string;
  address?: string;
  email?: string;
  phone?: string;
}

interface AccountingArticle {
  id: string;
  code: string;
  name: string;
  description?: string;
  purchasePrice?: number;
  sellingPrice?: number;
  unit: {
    id: string;
    name: string;
  };
  vatRate: {
    id: string;
    rate: number;
    percentage: number;
  };
}

const EditPurchasePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useSelector((state: any) => state.user);
  
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [documentNumber, setDocumentNumber] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [description, setDescription] = useState('');
  const [articles, setArticles] = useState<Article[]>([]);
  const [accountingArticles, setAccountingArticles] = useState<AccountingArticle[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState('');
  const [purchase, setPurchase] = useState<any>(null);
  const [purchaseStatus, setPurchaseStatus] = useState('DRAFT');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchingInitialData(true);
        setErrorMessage('');
        
        // Fetch all required data first
        const [companyResponse, suppliersResponse, articlesResponse, purchaseResponse] = await Promise.all([
          getCompany(),
          getSuppliers(),
          getArticles(),
          getPurchaseById(id!)
        ]);

        console.log('Purchase data:', purchaseResponse?.data);

        // Set locations from company data
        if (companyResponse?.data?.message?.locations) {
          setLocations(companyResponse.data.message.locations);
        } else if (companyResponse?.data?.locations) {
          setLocations(companyResponse.data.locations);
        } else {
          console.warn('Unexpected company response structure:', companyResponse);
          setLocations([]);
        }

        // Set suppliers
        if (suppliersResponse?.data) {
          setSuppliers(suppliersResponse.data);
        }

        // Set accounting articles
        if (articlesResponse?.data?.message) {
          setAccountingArticles(articlesResponse.data.message);
        } else if (Array.isArray(articlesResponse?.data)) {
          setAccountingArticles(articlesResponse.data);
        } else {
          console.warn('Unexpected articles response structure:', articlesResponse);
          setAccountingArticles([]);
        }

        // Set purchase data
        if (purchaseResponse?.data?.message) {
          const purchaseData = purchaseResponse.data.message;
          setPurchase(purchaseData);
          
          // Set form fields
          setDocumentNumber(purchaseData.documentNumber || '');
          setIssueDate(purchaseData.issueDate ? new Date(purchaseData.issueDate).toISOString().split('T')[0] : '');
          setDueDate(purchaseData.dueDate ? new Date(purchaseData.dueDate).toISOString().split('T')[0] : '');
          setSelectedSupplier(purchaseData.supplierId || '');
          setSelectedLocation(purchaseData.locationId || '');
          setDescription(purchaseData.description || '');
          setPurchaseStatus(purchaseData.status || 'DRAFT');
          
          // Set articles with their complete data
          if (purchaseData.items && Array.isArray(purchaseData.items)) {
            setArticles(purchaseData.items.map((item: any) => ({
              id: item.id,
              articleId: item.articleId || item.article?.id,
              name: item.article?.name || '',
              unit: item.article?.unit?.name || '',
              quantity: Number(item.quantity),
              priceWithoutVAT: Number(item.priceWithoutVAT),
              vatAmount: Number(item.vatAmount),
              totalWithVAT: Number(item.totalWithVAT),
              vatRate: Number(item.vatRate)
            })));
          }
        }

      } catch (error: any) {
        console.error('Error fetching initial data:', error);
        setErrorMessage(error.message || 'Error loading purchase data');
        toast.error('Error loading purchase data');
      } finally {
        setFetchingInitialData(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (purchase) {
      setPurchaseStatus(purchase.status);
    }
  }, [purchase]);

  const handleStatusChange = async (newStatus) => {
    try {
      setSubmitting(true);
      setErrorMessage('');

      const response = await updatePurchase(id, {
        ...purchase,
        status: newStatus,
        updatedBy: user?.email || 'system'
      });

      if (response?.data?.statusCode === 200) {
        setPurchaseStatus(newStatus);
        toast.success(`Purchase status updated to ${newStatus}`);
      } else {
        throw new Error('Failed to update purchase status');
      }
    } catch (error) {
      console.error('Error updating purchase status:', error);
      const errorMsg = error.response?.data?.message || error.message || 'Failed to update status';
      setErrorMessage(errorMsg);
      toast.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!selectedSupplier || !selectedLocation || articles.length === 0) {
      toast.error('Please fill in all required fields and add at least one article');
      return;
    }

    try {
      setSubmitting(true);
      setErrorMessage('');

      const purchaseData = {
        documentNumber,
        issueDate: new Date(issueDate).toISOString(),
        dueDate: new Date(dueDate).toISOString(),
        supplierId: selectedSupplier,
        locationId: selectedLocation,
        description,
        items: articles.map(article => ({
          articleId: article.articleId,
          quantity: Number(article.quantity),
          priceWithoutVAT: Number(article.priceWithoutVAT),
          vatRate: Number(article.vatRate),
          vatAmount: Number(article.vatAmount),
          totalWithVAT: Number(article.totalWithVAT)
        })),
        status: purchaseStatus,
        totalWithoutVAT: Number(articles.reduce((sum, article) => sum + (article.priceWithoutVAT * article.quantity), 0).toFixed(2)),
        totalVAT: Number(articles.reduce((sum, article) => sum + article.vatAmount, 0).toFixed(2)),
        totalWithVAT: Number(articles.reduce((sum, article) => sum + article.totalWithVAT, 0).toFixed(2)),
        updatedBy: user?.id || 'system'
      };

      console.log('Submitting purchase data:', purchaseData);
      await updatePurchase(id!, purchaseData);
      
      toast.success('Purchase updated successfully');
      navigate('/administration/accounting/purchases');
    } catch (error: any) {
      console.error('Error updating purchase:', error);
      setErrorMessage(error.message || 'Error updating purchase');
      toast.error('Error updating purchase');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-ghost-white">
      {/* Header */}
      <div className="bg-white border-b border-mercury/50">
        <div className="px-8 py-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate(-1)}
                className="p-2 text-bluish-grey hover:bg-seashell rounded-xl transition-colors"
              >
                <ArrowLeft className="w-5 h-5" />
              </motion.button>
              <div>
                <h1 className="text-2xl font-semibold text-bluish-grey">Edit Purchase</h1>
                <p className="text-sm text-grey-goose">Edit purchase details #{documentNumber}</p>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <Button
                onClick={handleSubmit}
                disabled={submitting}
                className="bg-hazel-green hover:bg-opacity-90 text-white px-6 py-2.5 rounded-xl flex items-center gap-2 disabled:opacity-50"
              >
                {submitting ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
                    Saving...
                  </>
                ) : (
                  <>
                    <Save className="w-5 h-5" />
                    Save Changes
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        {fetchingInitialData ? (
          <Loading />
        ) : errorMessage ? (
          <div className="flex flex-col items-center justify-center min-h-screen">
            <h1 className="text-2xl font-bold text-red-600 mb-4">Error</h1>
            <p className="text-gray-600 mb-4">{errorMessage}</p>
            <Button onClick={() => navigate('/accounting/purchases')}>
              <ArrowLeft className="mr-2 h-4 w-4" />
              Back to Purchases
            </Button>
          </div>
        ) : (
          <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
            {/* Document Details */}
            <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
              <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
                <h2 className="text-lg font-semibold text-hazel-green">Purchase Details</h2>
              </div>
              <div className="p-6">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                  <div className="space-y-2">
                    <Label className="text-sm font-medium text-bluish-grey">Document Number</Label>
                    <Input
                      value={documentNumber}
                      onChange={(e) => setDocumentNumber(e.target.value)}
                      disabled
                      className="w-full px-3 py-2 bg-seashell border border-mercury rounded-lg"
                    />
                  </div>

                  <div className="space-y-2">
                    <Label className="text-sm font-medium text-bluish-grey">Issue Date</Label>
                    <Input
                      type="date"
                      value={issueDate}
                      onChange={(e) => setIssueDate(e.target.value)}
                      className="w-full px-3 py-2 border border-mercury rounded-lg"
                    />
                  </div>

                  <div className="space-y-2">
                    <Label className="text-sm font-medium text-bluish-grey">Due Date</Label>
                    <Input
                      type="date"
                      value={dueDate}
                      onChange={(e) => setDueDate(e.target.value)}
                      className="w-full px-3 py-2 border border-mercury rounded-lg"
                    />
                  </div>

                  <div className="space-y-2">
                    <Label className="text-sm font-medium text-bluish-grey">Supplier</Label>
                    <div className="flex gap-2">
                      <Select
                        value={selectedSupplier}
                        onValueChange={setSelectedSupplier}
                      >
                        <SelectTrigger 
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                          disabled={submitting}
                        >
                          <SelectValue 
                            placeholder={submitting ? "Duke ngarkuar..." : "Zgjidhni furnitorin"}
                            className="text-bluish-grey"
                          >
                            {selectedSupplier && (
                              <span className="text-hazel-green">
                                {suppliers.find(s => s.id === selectedSupplier)?.businessName || suppliers.find(s => s.id === selectedSupplier)?.name}
                              </span>
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent 
                          className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                          sideOffset={5}
                          position="popper"
                          align="start"
                        >
                          <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                            <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                              Zgjidhni Furnitorin
                            </h3>
                          </div>
                          {suppliers.map(supplier => (
                            <SelectItem
                              key={supplier.id}
                              value={supplier.id}
                              textValue={supplier.businessName || supplier.name}
                              className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                            >
                              {/* Hidden span for the select field display */}
                              <span className="hidden">
                                {supplier.businessName || supplier.name}
                              </span>
                              
                              {/* Detailed content for dropdown */}
                              <div className="flex flex-col gap-1">
                                <div className="flex items-center justify-between">
                                  <div className="flex flex-col">
                                    <span className="text-sm sm:text-base font-medium text-hazel-green">
                                      {supplier.businessName || supplier.name}
                                    </span>
                                    {supplier.tradeName && supplier.tradeName !== supplier.businessName && (
                                      <span className="text-xs sm:text-sm text-bluish-grey">
                                        Trading as: {supplier.tradeName}
                                      </span>
                                    )}
                                  </div>
                                  {supplier.businessType && (
                                    <span className="px-2 py-0.5 rounded-full text-xs sm:text-sm font-medium bg-sage/10 text-sage">
                                      {supplier.businessType}
                                    </span>
                                  )}
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 text-xs sm:text-sm">
                                  {supplier.fiscalNumber && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm3 1h6v4H7V5zm5 6H7v2h6v-2z" clipRule="evenodd" />
                                      </svg>
                                      <span>Nr. Fiskal: {supplier.fiscalNumber}</span>
                                    </div>
                                  )}
                                  {supplier.address && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                      </svg>
                                      <span>{supplier.address}</span>
                                    </div>
                                  )}
                                  {supplier.phone && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                      </svg>
                                      <span>{supplier.phone}</span>
                                    </div>
                                  )}
                                  {supplier.email && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                      </svg>
                                      <span>{supplier.email}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <Button
                        type="button"
                        variant="outline"
                        onClick={() => setIsSupplierModalOpen(true)}
                        className="px-3 py-2 border border-mercury rounded-lg hover:bg-seashell"
                      >
                        <Plus className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <Label className="text-sm font-medium text-bluish-grey">Location</Label>
                    <Select
                      value={selectedLocation}
                      onValueChange={setSelectedLocation}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Zgjidhni lokacionin" />
                      </SelectTrigger>
                      <SelectContent 
                        className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                        sideOffset={5}
                        position="popper"
                        align="start"
                      >
                        {locations.map(location => (
                          <SelectItem
                            key={location.id}
                            value={location.id}
                            textValue={location.name}
                            className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                          >
                            <div className="flex flex-col">
                              <span className="text-sm sm:text-base font-medium text-hazel-green">
                                {location.name}
                              </span>
                              {location.address && (
                                <span className="text-xs sm:text-sm text-bluish-grey">
                                  {location.address}
                                </span>
                              )}
                            </div>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="mt-6">
                  <Label className="text-sm font-medium text-bluish-grey">Description</Label>
                  <Textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Shkruani përshkrimin e blerjes"
                    className="w-full px-3 py-2 border border-mercury rounded-lg mt-2"
                    rows={3}
                  />
                </div>
              </div>
            </div>

            {/* Articles Section */}
            <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
              <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4 flex justify-between items-center">
                <h2 className="text-lg font-semibold text-hazel-green">Articles</h2>
                <button
                  type="button"
                  onClick={() => {
                    const newArticle: Article = {
                      id: Date.now().toString(),
                      name: '',
                      unit: '',
                      quantity: 1,
                      priceWithoutVAT: 0,
                      vatAmount: 0,
                      totalWithVAT: 0,
                      vatRate: 0
                    };
                    setArticles([...articles, newArticle]);
                  }}
                  className="inline-flex items-center gap-x-2 px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                           hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
                >
                  <Plus className="h-5 w-5" />
                  Add Article
                </button>
              </div>
              <div className="p-6">
                <div className="space-y-4">
                  {articles.length === 0 ? (
                    <div className="flex flex-col items-center justify-center py-8">
                      <div className="w-16 h-16 bg-gradient-to-br from-sage/20 to-hazel-green/10 rounded-lg flex items-center justify-center mb-4">
                        <Plus className="h-8 w-8 text-hazel-green" />
                      </div>
                      <h3 className="text-base font-medium text-bluish-grey mb-1">No articles</h3>
                      <p className="text-sm text-grey-goose mb-4">Add articles to continue with the purchase</p>
                      <button
                        type="button"
                        onClick={() => {
                          const newArticle: Article = {
                            id: Date.now().toString(),
                            name: '',
                            unit: '',
                            quantity: 1,
                            priceWithoutVAT: 0,
                            vatAmount: 0,
                            totalWithVAT: 0,
                            vatRate: 0
                          };
                          setArticles([...articles, newArticle]);
                        }}
                        className="inline-flex items-center gap-x-2 px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                                 hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
                      >
                        <Plus className="h-5 w-5" />
                        Add Article
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="grid grid-cols-12 gap-4 items-center mb-2 text-sm font-medium text-bluish-grey">
                        <div className="col-span-3">Article Name</div>
                        <div className="col-span-2">Unit</div>
                        <div className="col-span-1">Quantity</div>
                        <div className="col-span-2">Price without VAT</div>
                        <div className="col-span-2">VAT</div>
                        <div className="col-span-1">Total</div>
                        <div className="col-span-1"></div>
                      </div>
                      {articles.map((article, index) => (
                        <div key={article.id} className="grid grid-cols-12 gap-4 items-center">
                          <div className="col-span-3">
                            <Select
                              value={article.articleId || ''}
                              onValueChange={(value) => {
                                const selectedArticle = accountingArticles.find((a) => a.id === value);
                                if (selectedArticle) {
                                  setArticles(articles.map((article) =>
                                    article.id === article.id
                                      ? {
                                          ...article,
                                          articleId: selectedArticle.id,
                                          name: selectedArticle.name,
                                          unit: selectedArticle.unit.name,
                                          vatRate: selectedArticle.vatRate.percentage,
                                          priceWithoutVAT: selectedArticle.purchasePrice || 0,
                                        }
                                      : article
                                  ));
                                }
                              }}
                            >
                              <SelectTrigger 
                                className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                              >
                                <SelectValue 
                                  placeholder="Zgjidhni artikullin"
                                  className="text-bluish-grey"
                                >
                                  {article.name || "Zgjidhni artikullin"}
                                </SelectValue>
                              </SelectTrigger>
                              <SelectContent 
                                className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden max-h-[300px]"
                                position="popper"
                                sideOffset={5}
                              >
                                <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                                  <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                                    Zgjidhni Artikullin
                                  </h3>
                                </div>
                                {accountingArticles.map(item => (
                                  <SelectItem
                                    key={item.id}
                                    value={item.id}
                                    textValue={item.name}
                                    className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                                  >
                                    <div className="flex flex-col gap-1">
                                      <div className="flex flex-col">
                                        <span className="text-sm font-medium text-hazel-green">
                                          {item.name}
                                        </span>
                                        <span className="text-xs text-gray-500">
                                          Kodi: {item.code}
                                        </span>
                                      </div>
                                      <div className="grid grid-cols-2 gap-1 text-xs text-gray-500">
                                        <div className="flex items-center gap-1 text-bluish-grey">
                                          <span>Çmimi: {item.purchasePrice?.toFixed(2)} €</span>
                                        </div>
                                        <div className="flex items-center gap-1 text-bluish-grey">
                                          <span>TVSH: {item.vatRate?.percentage || 0}%</span>
                                        </div>
                                        {item.description && (
                                          <div className="col-span-2 text-xs text-grey-goose">
                                            {item.description}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="col-span-2">
                            <input
                              type="text"
                              value={article.unit}
                              readOnly
                              placeholder="Njësia"
                              className="block w-full rounded-lg border border-mercury bg-gray-50 px-4 py-2.5 text-bluish-grey shadow-sm"
                            />
                          </div>
                          <div className="col-span-1">
                            <input
                              type="number"
                              value={article.quantity}
                              onChange={(e) => {
                                const quantity = parseFloat(e.target.value);
                                const priceWithoutVAT = article.priceWithoutVAT;
                                const vatRate = article.vatRate;
                                
                                const subtotal = quantity * priceWithoutVAT;
                                const vatAmount = Number((subtotal * vatRate / 100).toFixed(2));
                                const totalWithVAT = Number((subtotal + vatAmount).toFixed(2));
                                
                                setArticles(articles.map((article) =>
                                  article.id === article.id
                                    ? {
                                        ...article,
                                        quantity,
                                        vatAmount,
                                        totalWithVAT
                                      }
                                    : article
                                ));
                              }}
                              placeholder="Sasia"
                              className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                            />
                          </div>
                          <div className="col-span-2">
                            <input
                              type="number"
                              value={article.priceWithoutVAT}
                              onChange={(e) => {
                                const priceWithoutVAT = parseFloat(e.target.value);
                                const quantity = article.quantity;
                                const vatRate = article.vatRate;
                                
                                const subtotal = quantity * priceWithoutVAT;
                                const vatAmount = Number((subtotal * vatRate / 100).toFixed(2));
                                const totalWithVAT = Number((subtotal + vatAmount).toFixed(2));
                                
                                setArticles(articles.map((article) =>
                                  article.id === article.id
                                    ? {
                                        ...article,
                                        priceWithoutVAT,
                                        vatAmount,
                                        totalWithVAT
                                      }
                                    : article
                                ));
                              }}
                              placeholder="Çmimi pa TVSH"
                              className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                            />
                          </div>
                          <div className="col-span-2">
                            <input
                              type="text"
                              value={`${article.vatRate || 18}%`}
                              readOnly
                              className="block w-full rounded-lg border border-mercury bg-gray-50 px-4 py-2.5 text-bluish-grey shadow-sm"
                            />
                          </div>
                          <div className="col-span-1">
                            <input
                              type="text"
                              value={`${article.totalWithVAT.toFixed(2)} €`}
                              readOnly
                              className="block w-full rounded-lg border border-mercury bg-gray-50 px-4 py-2.5 text-bluish-grey shadow-sm font-medium"
                            />
                          </div>
                          <div className="col-span-1 flex justify-end">
                            <button
                              type="button"
                              onClick={() => setArticles(articles.filter(article => article.id !== article.id))}
                              className="p-2 text-red-500 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                            >
                              <X className="h-5 w-5" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>

                {articles.length > 0 && (
                  <div className="mt-8 border-t border-mercury pt-6">
                    <div className="flex justify-end">
                      <div className="w-80">
                        <div className="space-y-3">
                          <div className="flex justify-between items-center text-sm">
                            <span className="text-bluish-grey">Total without VAT:</span>
                            <span className="font-medium">{articles.reduce((acc, article) => acc + article.priceWithoutVAT * article.quantity, 0).toFixed(2)} €</span>
                          </div>
                          <div className="flex justify-between items-center text-sm">
                            <span className="text-bluish-grey">Total VAT:</span>
                            <span className="font-medium">{articles.reduce((acc, article) => acc + article.vatAmount, 0).toFixed(2)} €</span>
                          </div>
                          <div className="pt-3 border-t border-mercury">
                            <div className="flex justify-between items-center">
                              <span className="font-medium text-bluish-grey">Total with VAT:</span>
                              <span className="text-lg font-semibold text-hazel-green">
                                {articles.reduce((acc, article) => acc + article.totalWithVAT, 0).toFixed(2)} €
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Status Section */}
            <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
              <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
                <h2 className="text-lg font-semibold text-hazel-green">Status</h2>
              </div>
              <div className="p-6">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Status
                  </label>
                  <Select
                    value={purchaseStatus}
                    onValueChange={setPurchaseStatus}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                    >
                      <SelectValue className="text-bluish-grey">
                        {purchaseStatus === 'DRAFT' ? (
                          <div className="flex items-center gap-2">
                            <span className="w-2 h-2 rounded-full bg-yellow-400"></span>
                            <span>Draft</span>
                          </div>
                        ) : purchaseStatus === 'COMPLETED' ? (
                          <div className="flex items-center gap-2">
                            <span className="w-2 h-2 rounded-full bg-green-500"></span>
                            <span>Completed</span>
                          </div>
                        ) : purchaseStatus === 'RETURNED' ? (
                          <div className="flex items-center gap-2">
                            <span className="w-2 h-2 rounded-full bg-blue-500"></span>
                            <span>Returned</span>
                          </div>
                        ) : (
                          <div className="flex items-center gap-2">
                            <span className="w-2 h-2 rounded-full bg-red-500"></span>
                            <span>Cancelled</span>
                          </div>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden"
                      sideOffset={5}
                    >
                      <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                        <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                          Select Status
                        </h3>
                      </div>
                      <SelectItem
                        value="DRAFT"
                        className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50"
                      >
                        <div className="flex items-center gap-2">
                          <span className="w-2 h-2 rounded-full bg-yellow-400"></span>
                          <span className="text-sm font-medium">Draft</span>
                        </div>
                      </SelectItem>
                      <SelectItem
                        value="COMPLETED"
                        className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50"
                      >
                        <div className="flex items-center gap-2">
                          <span className="w-2 h-2 rounded-full bg-green-500"></span>
                          <span className="text-sm font-medium">Completed</span>
                        </div>
                      </SelectItem>
                      <SelectItem
                        value="RETURNED"
                        className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50"
                      >
                        <div className="flex items-center gap-2">
                          <span className="w-2 h-2 rounded-full bg-blue-500"></span>
                          <span className="text-sm font-medium">Returned</span>
                        </div>
                      </SelectItem>
                      <SelectItem
                        value="CANCELLED"
                        className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer"
                      >
                        <div className="flex items-center gap-2">
                          <span className="w-2 h-2 rounded-full bg-red-500"></span>
                          <span className="text-sm font-medium">Cancelled</span>
                        </div>
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex items-center gap-2 mt-4">
                  {purchaseStatus === 'DRAFT' && (
                    <Button
                      variant="default"
                      onClick={() => handleStatusChange('COMPLETED')}
                      disabled={submitting}
                    >
                      Complete Purchase
                    </Button>
                  )}
                  {purchaseStatus === 'COMPLETED' && (
                    <Button
                      variant="destructive"
                      onClick={() => handleStatusChange('CANCELLED')}
                      disabled={submitting}
                    >
                      Cancel Purchase
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </div>

      {/* Supplier Modal */}
      {isSupplierModalOpen && (
        <SupplierModal
          isOpen={isSupplierModalOpen}
          onClose={() => setIsSupplierModalOpen(false)}
          onSuccess={() => {
            setIsSupplierModalOpen(false);
            // fetchInitialData();
          }}
        />
      )}
    </div>
  );
};

export default EditPurchasePage;
