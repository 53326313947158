import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MapPin, X, Save } from 'lucide-react';
import { createLocation } from '../../api/company';
import { toast } from 'react-hot-toast';

interface Location {
  id: string;
  name: string;
  address?: string;
  isActive: boolean;
  parentId?: string;
}

interface AddLocationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  companyId: string;
  existingLocations?: Location[];
}

const AddLocationModal: React.FC<AddLocationModalProps> = ({ 
  isOpen, 
  onClose, 
  onSuccess, 
  companyId,
  existingLocations = []
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    parentId: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!companyId) {
      toast.error('Company ID is required');
      return;
    }
    
    setIsLoading(true);
    try {
      await createLocation({
        ...formData,
        companyId,
        parentId: formData.parentId || undefined,
        createdBy: 'system' // You might want to get this from your auth context
      });
      toast.success('Lokacioni u krijua me sukses');
      onSuccess();
      onClose();
    } catch (error) {
      toast.error('Dështoi krijimi i lokacionit');
      console.error('Error creating location:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-bluish-grey/20 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="bg-white rounded-2xl shadow-2xl w-full max-w-2xl max-h-[90vh] flex flex-col overflow-hidden border border-grey-goose/30"
          >
            {/* Header */}
            <div className="px-6 py-4 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="p-2 bg-hazel-green/10 rounded-xl">
                    <MapPin className="w-6 h-6 text-hazel-green" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-bluish-grey">Shto Lokacion</h3>
                    <p className="text-sm text-grey-goose">Plotësoni të dhënat e lokacionit</p>
                  </div>
                </div>
                <button
                  onClick={onClose}
                  className="p-2 hover:bg-grey-goose/10 rounded-xl transition-colors"
                >
                  <X className="w-5 h-5 text-grey-goose" />
                </button>
              </div>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="p-6 space-y-6">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-1">
                    Emri i Lokacionit
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 rounded-lg border border-grey-goose/20 focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green"
                    placeholder="Shkruani emrin e lokacionit"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-1">
                    Lokacioni Prind
                  </label>
                  <select
                    name="parentId"
                    value={formData.parentId}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border border-grey-goose/20 focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green"
                  >
                    <option value="">Zgjedh lokacionin prind (opsionale)</option>
                    {existingLocations.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-1">
                    Adresa
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border border-grey-goose/20 focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green"
                    placeholder="Shkruani adresën e lokacionit"
                  />
                </div>
              </div>

              {/* Actions */}
              <div className="flex justify-end space-x-3 pt-4 border-t border-grey-goose/20">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-grey-goose hover:bg-grey-goose/10 rounded-lg transition-colors"
                >
                  Anulo
                </button>
                <motion.button
                  type="submit"
                  disabled={isLoading}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 transition-all duration-200 flex items-center gap-2 disabled:opacity-50"
                >
                  <Save className="w-4 h-4" />
                  {isLoading ? 'Duke ruajtur...' : 'Ruaj'}
                </motion.button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AddLocationModal;
