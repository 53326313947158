import React, { useState } from 'react';
import { PageHeader } from '../../components/hotelInterfaceComponents/lists/PageHeader.tsx';
import { SearchBar } from '../../components/hotelInterfaceComponents/lists/SearchBar.tsx';
import { BookingTable } from '../../components/hotelInterfaceComponents/lists/BookingTable.tsx';

import Navbar from '../../components/hotelInterfaceComponents/Navbar.tsx';

const columns = [
  { key: 'id', label: '#' },
  { key: 'refId', label: 'Ref ID' },
  { key: 'stayDuration', label: 'Stay Duration' },
  { key: 'room', label: 'Room # / Type / Block / Floor' },
  { key: 'pax', label: 'Pax' },
  { key: 'status', label: 'Status' },
  { key: 'amount', label: 'Amount' },
  { key: 'account', label: 'Account' },
  { key: 'salesChannel', label: 'Sales Channel' },
  { key: 'checkInCard', label: 'Check-in Card' },
];

export const ReservationRoomsListPage: React.FC = () => {
  const [startDate, setStartDate] = useState('2024-10-30');
  const [endDate, setEndDate] = useState('2024-10-30');
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className="min-h-screen bg-tealish-blue">
        <Navbar />
      <PageHeader title="Reservations List" />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <SearchBar
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          onChannelChange={() => {}}
          onSubmit={() => {}}
        />
        <BookingTable
          columns={columns}
          data={[]}
          currentPage={currentPage}
          totalPages={1}
          onPageChange={setCurrentPage}
        />
      </main>
    </div>
  );
};

export default ReservationRoomsListPage;