import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../../components/shadCN/Button.tsx';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/shadCN/Table.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/shadCN/Dialog.tsx";
import { Input } from "../../components/shadCN/Input.tsx";
import { Label } from "../../components/shadCN/Label.tsx";
import { Textarea } from "../../components/shadCN/TextArea.tsx";
import { 
  BookOpen, 
  Plus,
  Search,
  Filter,
  MoreVertical,
  Eye,
  Edit,
  Trash2,
  Clock,
  BookmarkCheck,
  Signal,
  ChevronLeft,
  ChevronRight,
  X,
  FileText,
  Sparkles,
  AlertCircle,
  TrendingUp,
  Video,
} from 'lucide-react';
import toast from 'react-hot-toast';
import DeleteConfirmationModal from '../../components/articles/DeleteConfirmationModal.tsx';

import { getAllTutorials, deleteTutorial, createTutorial } from '../../api/tutorial.js';

const TutorialManagement = () => {
  const [tutorials, setTutorials] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    duration: '',
    difficulty: 'beginner',
    category: '',
    content: '',
    videoUrl: '',
    chapters: [{ title: '', sections: [] }]
  });
  const [videoFile, setVideoFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tutorialToDelete, setTutorialToDelete] = useState(null);

  const itemsPerPage = 10;

  useEffect(() => {
    fetchTutorials();
  }, [currentPage, searchQuery]);

  const fetchTutorials = async () => {
    try {
      setLoading(true);
      // Replace with your actual API call
      const response = await getAllTutorials();
      setTutorials(response.data);
    } catch (error) {
      console.error('Error fetching tutorials:', error);
      toast.error('Failed to load tutorials');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await createTutorial(formData);
      toast.success('Tutorial created successfully');
      setIsCreateModalOpen(false);
      fetchTutorials();
      resetForm();
    } catch (error) {
      console.error('Error creating tutorial:', error);
      toast.error('Failed to create tutorial');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      duration: '',
      difficulty: 'beginner',
      category: '',
      content: '',
      videoUrl: '',
      chapters: [{ title: '', sections: [] }]
    });
  };

  const handleDelete = async (tutorial) => {
    setTutorialToDelete(tutorial);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      await deleteTutorial(tutorialToDelete.id);
      toast.success('Tutorial deleted successfully');
      fetchTutorials();
    } catch (error) {
      console.error('Error deleting tutorial:', error);
      toast.error('Failed to delete tutorial');
    } finally {
      setLoading(false);
      setIsDeleteModalOpen(false);
      setTutorialToDelete(null);
    }
  };

  const handleChapterChange = (index, field, value) => {
    const updatedChapters = [...formData.chapters];
    updatedChapters[index][field] = value;
    setFormData({ ...formData, chapters: updatedChapters });
  };

  const addChapter = () => {
    setFormData(prevState => ({
      ...prevState,
      chapters: [...prevState.chapters, { title: '', sections: [] }]
    }));
  };

  const validateBasicInfo = () => {
    if (!formData.title?.trim()) return false;
    if (!formData.description?.trim()) return false;
    if (!formData.duration || formData.duration <= 0) return false;
    return true;
  };

  const validateContent = () => {
    if (!formData.content?.trim()) return false;
    return true;
  };

  const validateMedia = () => {
    if (!videoFile) return false;
    return true;
  };

  const validateChapters = () => {
    if (!formData.chapters?.length) return false;
    return formData.chapters.every(chapter => chapter.title?.trim());
  };

  const handleNextStep = () => {
    let canProceed = false;

    switch (currentStep) {
      case 1:
        canProceed = validateBasicInfo();
        if (!canProceed) {
          toast.error('Please fill in all required fields in Basic Info');
        }
        break;
      case 2:
        canProceed = validateContent();
        if (!canProceed) {
          toast.error('Please add content for the tutorial');
        }
        break;
      case 3:
        canProceed = validateMedia();
        if (!canProceed) {
          toast.error('Please upload a video for the tutorial');
        }
        break;
      default:
        canProceed = true;
    }

    if (canProceed && currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleFinalSubmit = async (e) => {
    e.preventDefault();

    if (!validateBasicInfo()) {
      setCurrentStep(1);
      toast.error('Please complete the Basic Info section');
      return;
    }

    if (!validateContent()) {
      setCurrentStep(2);
      toast.error('Please complete the Content section');
      return;
    }

    if (!validateMedia()) {
      setCurrentStep(3);
      toast.error('Please upload a video');
      return;
    }

    if (!validateChapters()) {
      setCurrentStep(4);
      toast.error('Please add at least one chapter with a title');
      return;
    }

    try {
      setLoading(true);
      const formDataToSubmit = new FormData();
      formDataToSubmit.append('title', formData.title);
      formDataToSubmit.append('description', formData.description);
      formDataToSubmit.append('duration', formData.duration);
      formDataToSubmit.append('content', formData.content);
      formDataToSubmit.append('video', videoFile);
      formDataToSubmit.append('chapters', JSON.stringify(formData.chapters));

      await createTutorial(formDataToSubmit);
      toast.success('Tutorial created successfully');
      setIsCreateModalOpen(false);
      fetchTutorials();
      resetForm();
      setCurrentStep(1);
    } catch (error) {
      console.error('Error creating tutorial:', error);
      toast.error('Failed to create tutorial');
    } finally {
      setLoading(false);
    }
  };

  // Add loading skeleton component
  const TableSkeleton = () => (
    <div className="animate-pulse">
      {[...Array(5)].map((_, i) => (
        <div key={i} className="h-16 bg-mercury/20 mb-2 rounded-lg" />
      ))}
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-seashell to-white p-8">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Enhanced Header with Stats */}
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative"
        >
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-6">
            <div className="space-y-2">
              <motion.div 
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 }}
                className="inline-flex items-center px-4 py-2 rounded-full bg-sage/10 text-sage hover:bg-sage/20 transition-colors"
              >
                <BookOpen className="w-4 h-4 mr-2" />
                <span className="text-sm font-medium">Tutorial Management</span>
              </motion.div>
              <motion.h1 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="text-3xl font-bold text-hazel-green"
              >
                Manage Learning Content
              </motion.h1>
              <motion.p 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="text-bluish-grey max-w-2xl"
              >
                Create, edit, and organize tutorial content for the Hotel Management System.
              </motion.p>
            </div>

            <motion.div 
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.5 }}
              className="flex flex-wrap gap-4"
            >
              {/* Enhanced Stats Cards */}
              <div className="bg-white p-6 rounded-xl border border-mercury/30 min-w-[180px] hover:shadow-lg transition-all duration-300">
                <div className="text-sm text-bluish-grey mb-2">Total Tutorials</div>
                <div className="text-3xl font-bold text-hazel-green">{tutorials.length}</div>
                <div className="mt-2 text-xs text-sage flex items-center">
                  <TrendingUp className="w-3 h-3 mr-1" />
                  <span>12% increase</span>
                </div>
              </div>
              <div className="bg-white p-6 rounded-xl border border-mercury/30 min-w-[180px] hover:shadow-lg transition-all duration-300">
                <div className="text-sm text-bluish-grey mb-2">Active Tutorials</div>
                <div className="text-3xl font-bold text-sage">
                  {tutorials.filter(t => t.status === 'active').length}
                </div>
                <div className="mt-2 text-xs text-sage flex items-center">
                  <Signal className="w-3 h-3 mr-1" />
                  <span>Currently Active</span>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>

        {/* Enhanced Search and Actions Bar */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="bg-white rounded-xl shadow-sm p-6 border border-mercury/30 hover:shadow-md transition-all duration-300"
        >
          <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
            <div className="relative flex-1 w-full md:w-auto group">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey/70 w-4 h-4 group-hover:text-sage transition-colors" />
              <Input
                placeholder="Search tutorials..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-10 w-full border-mercury/50 focus:border-sage focus:ring-sage/20"
              />
            </div>
            <div className="flex gap-3 w-full md:w-auto">
              <Button 
                variant="outline" 
                className="flex-1 md:flex-none hover:bg-sage/10 hover:text-sage transition-colors"
              >
                <Filter className="w-4 h-4 mr-2" />
                Filter
              </Button>
              <Button 
                onClick={() => setIsCreateModalOpen(true)}
                className="bg-hazel-green hover:bg-hazel-green/90 flex-1 md:flex-none transition-all duration-300 hover:shadow-lg"
              >
                <Plus className="w-4 h-4 mr-2" />
                Create Tutorial
              </Button>
            </div>
          </div>
        </motion.div>

        {/* Enhanced Table Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7 }}
          className="bg-white rounded-xl shadow-sm overflow-hidden border border-mercury/30 hover:shadow-md transition-all duration-300"
        >
          {loading ? (
            <div className="p-6">
              <TableSkeleton />
            </div>
          ) : tutorials.length === 0 ? (
            <div className="p-12 text-center">
              <div className="flex justify-center mb-4">
                <AlertCircle className="w-12 h-12 text-bluish-grey/50" />
              </div>
              <h3 className="text-lg font-semibold text-hazel-green mb-2">
                No Tutorials Found
              </h3>
              <p className="text-bluish-grey mb-6">
                Get started by creating your first tutorial.
              </p>
              <Button 
                onClick={() => setIsCreateModalOpen(true)}
                className="bg-hazel-green hover:bg-hazel-green/90 flex-1 md:flex-none"
              >
                <Plus className="w-4 h-4 mr-2" />
                Create Tutorial
              </Button>
            </div>
          ) : (
            <Table>
              <TableHeader>
                <TableRow className="bg-mercury/5">
                  <TableHead>Title</TableHead>
                  <TableHead>Category</TableHead>
                  <TableHead>Difficulty</TableHead>
                  <TableHead>Duration</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {tutorials.map((tutorial) => (
                  <TableRow 
                    key={tutorial.id}
                    className="hover:bg-mercury/5 transition-colors duration-200"
                  >
                    <TableCell className="font-medium">{tutorial.title}</TableCell>
                    <TableCell>
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-mercury/20 text-bluish-grey">
                        {tutorial.category}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        tutorial.difficulty === 'beginner' ? 'bg-sage/20 text-sage' :
                        tutorial.difficulty === 'intermediate' ? 'bg-tealish-blue/20 text-tealish-blue' :
                        'bg-watermelon/20 text-watermelon'
                      }`}>
                        {tutorial.difficulty}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="inline-flex items-center text-bluish-grey">
                        <Clock className="w-4 h-4 mr-1" />
                        {tutorial.duration} mins
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-sage/20 text-sage">
                        Active
                      </span>
                    </TableCell>
                    <TableCell className="text-right">
                      <div className="flex items-center justify-end space-x-2">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => {
                            setSelectedTutorial(tutorial);
                            setIsViewModalOpen(true);
                          }}
                          className="hover:bg-sage/10 hover:text-sage"
                        >
                          <Eye className="w-4 h-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => {
                            setSelectedTutorial(tutorial);
                            setIsCreateModalOpen(true);
                          }}
                          className="hover:bg-sage/10 hover:text-sage"
                        >
                          <Edit className="w-4 h-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleDelete(tutorial)}
                          className="hover:bg-watermelon/10 hover:text-watermelon"
                        >
                          <Trash2 className="w-4 h-4" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          {/* Enhanced Pagination */}
          <div className="flex items-center justify-between px-6 py-4 border-t border-mercury/30">
            <div className="text-sm text-bluish-grey">
              Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, tutorials.length)} of {tutorials.length} entries
            </div>
            <div className="flex items-center space-x-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                disabled={currentPage === 1}
                className="hover:bg-sage/10 hover:text-sage"
              >
                <ChevronLeft className="w-4 h-4" />
              </Button>
              <span className="text-sm text-bluish-grey px-4">
                Page {currentPage}
              </span>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setCurrentPage(prev => prev + 1)}
                disabled={currentPage * itemsPerPage >= tutorials.length}
                className="hover:bg-sage/10 hover:text-sage"
              >
                <ChevronRight className="w-4 h-4" />
              </Button>
            </div>
          </div>
        </motion.div>
      </div>

      {/* View Details Modal */}
      <AnimatePresence>
        {isViewModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/40 backdrop-blur-md z-50 flex items-center justify-center p-4"
            onClick={() => setIsViewModalOpen(false)}
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0, y: 20 }}
              animate={{ scale: 1, opacity: 1, y: 0 }}
              exit={{ scale: 0.95, opacity: 0, y: 20 }}
              transition={{ type: "spring", duration: 0.5 }}
              onClick={(e) => e.stopPropagation()}
              className="relative w-full max-w-3xl bg-white rounded-2xl shadow-xl max-h-[90vh] overflow-y-auto"
            >
              {selectedTutorial && (
                <>
                  {/* Modal Header */}
                  <div className="sticky top-0 bg-white border-b border-mercury/30 p-6">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <div className="p-2 bg-sage/10 rounded-lg">
                          <FileText className="w-5 h-5 text-sage" />
                        </div>
                        <div>
                          <h2 className="text-2xl font-bold text-hazel-green">
                            Tutorial Details
                          </h2>
                          <p className="text-sm text-bluish-grey mt-1">
                            View complete tutorial information
                          </p>
                        </div>
                      </div>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setIsViewModalOpen(false)}
                        className="hover:bg-sage/10 hover:text-sage rounded-full p-2 h-auto"
                      >
                        <X className="w-5 h-5" />
                      </Button>
                    </div>
                  </div>

                  {/* Modal Content */}
                  <div className="p-6">
                    <div className="space-y-8">
                      {/* Basic Info Section */}
                      <div className="space-y-4">
                        <h3 className="text-lg font-semibold text-hazel-green flex items-center">
                          <BookOpen className="w-5 h-5 mr-2" />
                          Basic Information
                        </h3>
                        <div className="grid grid-cols-2 gap-6 bg-seashell rounded-xl p-4">
                          <div>
                            <label className="text-sm text-bluish-grey">Title</label>
                            <p className="text-hazel-green font-medium mt-1">
                              {selectedTutorial.title}
                            </p>
                          </div>
                          <div>
                            <label className="text-sm text-bluish-grey">Duration</label>
                            <p className="text-hazel-green font-medium mt-1 flex items-center">
                              <Clock className="w-4 h-4 mr-1 text-sage" />
                              {selectedTutorial.duration} minutes
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Description Section */}
                      <div className="space-y-4">
                        <h3 className="text-lg font-semibold text-hazel-green flex items-center">
                          <FileText className="w-5 h-5 mr-2" />
                          Description
                        </h3>
                        <div className="bg-seashell rounded-xl p-4">
                          <p className="text-bluish-grey whitespace-pre-wrap">
                            {selectedTutorial.description}
                          </p>
                        </div>
                      </div>

                      {/* Content Section */}
                      <div className="space-y-4">
                        <h3 className="text-lg font-semibold text-hazel-green flex items-center">
                          <BookmarkCheck className="w-5 h-5 mr-2" />
                          Content
                        </h3>
                        <div className="bg-seashell rounded-xl p-4">
                          <p className="text-bluish-grey whitespace-pre-wrap">
                            {selectedTutorial.content}
                          </p>
                        </div>
                      </div>

                      {/* Chapters Section */}
                      <div className="space-y-4">
                        <h3 className="text-lg font-semibold text-hazel-green flex items-center">
                          <BookOpen className="w-5 h-5 mr-2" />
                          Chapters
                        </h3>
                        <div className="space-y-3">
                          {selectedTutorial.chapters?.map((chapter, index) => (
                            <div 
                              key={index}
                              className="bg-seashell rounded-xl p-4 hover:shadow-md transition-shadow"
                            >
                              <div className="flex items-center space-x-3">
                                <div className="w-8 h-8 bg-sage/10 rounded-full flex items-center justify-center text-sage font-medium">
                                  {index + 1}
                                </div>
                                <h4 className="text-hazel-green font-medium">
                                  {chapter.title}
                                </h4>
                              </div>
                              {chapter.sections?.length > 0 && (
                                <div className="mt-3 ml-11 space-y-2">
                                  {chapter.sections.map((section, sIndex) => (
                                    <div 
                                      key={sIndex}
                                      className="text-bluish-grey text-sm flex items-center"
                                    >
                                      <div className="w-1.5 h-1.5 bg-sage/40 rounded-full mr-2" />
                                      {section}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Video Preview Section */}
                      {selectedTutorial.videoUrl && (
                        <div className="space-y-4">
                          <h3 className="text-lg font-semibold text-hazel-green flex items-center">
                            <Video className="w-5 h-5 mr-2" />
                            Tutorial Video
                          </h3>
                          <div className="bg-seashell rounded-xl p-4">
                            <div className="aspect-video rounded-lg overflow-hidden bg-mercury">
                              <video
                                src={selectedTutorial.videoUrl}
                                controls
                                className="w-full h-full object-cover"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Modal Footer */}
                    <div className="flex justify-end space-x-3 mt-8 pt-6 border-t border-mercury/30">
                      <Button
                        variant="outline"
                        onClick={() => setIsViewModalOpen(false)}
                        className="text-bluish-grey hover:bg-mercury/20"
                      >
                        Close
                      </Button>
                      <Button
                        onClick={() => {
                          setIsViewModalOpen(false);
                          setSelectedTutorial(selectedTutorial);
                          setIsCreateModalOpen(true);
                        }}
                        className="bg-sage hover:bg-sage/90"
                      >
                        <Edit className="w-4 h-4 mr-2" />
                        Edit Tutorial
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Create/Edit Modal */}
      <AnimatePresence>
        {isCreateModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/40 backdrop-blur-md z-50 flex items-center justify-center p-4"
            onClick={() => setIsCreateModalOpen(false)}
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0, y: 20 }}
              animate={{ scale: 1, opacity: 1, y: 0 }}
              exit={{ scale: 0.95, opacity: 0, y: 20 }}
              transition={{ type: "spring", duration: 0.5 }}
              onClick={(e) => e.stopPropagation()}
              className="relative w-full max-w-2xl bg-white rounded-2xl shadow-xl max-h-[90vh] overflow-y-auto"
            >
              <div className="sticky top-0 bg-white border-b border-mercury/30 p-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-sage/10 rounded-lg">
                      <Sparkles className="w-5 h-5 text-sage" />
                    </div>
                    <div>
                      <h2 className="text-2xl font-bold text-hazel-green">
                        {selectedTutorial ? 'Edit Tutorial' : 'Create New Tutorial'}
                      </h2>
                      <p className="text-sm text-bluish-grey mt-1">
                        {selectedTutorial ? 'Update the tutorial information below.' : 'Fill in the details to create a new tutorial.'}
                      </p>
                    </div>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setIsCreateModalOpen(false)}
                    className="hover:bg-sage/10 hover:text-sage rounded-full p-2 h-auto"
                  >
                    <X className="w-5 h-5" />
                  </Button>
                </div>

                {/* Step Progress */}
                <div className="flex items-center justify-between mt-6 px-4">
                  {['Basic Info', 'Content', 'Media', 'Chapters'].map((step, index) => (
                    <div key={step} className="flex items-center">
                      <div className={`flex items-center justify-center w-8 h-8 rounded-full text-sm ${
                        index + 1 === currentStep ? 'bg-sage text-white' : 
                        index + 1 < currentStep ? 
                          (index === 0 && !validateBasicInfo() || 
                           index === 1 && !validateContent() || 
                           index === 2 && !validateMedia() || 
                           index === 3 && !validateChapters()) 
                            ? 'bg-watermelon/60 text-white' 
                            : 'bg-sage/60 text-white'
                        : 'bg-mercury/20 text-bluish-grey'
                      }`}>
                        {index + 1 < currentStep ? 
                          (index === 0 && !validateBasicInfo() || 
                           index === 1 && !validateContent() || 
                           index === 2 && !validateMedia() || 
                           index === 3 && !validateChapters()) 
                            ? '!' 
                            : '✓' 
                          : index + 1}
                      </div>
                      <span className="ml-2 text-xs font-medium">{step}</span>
                      {index < 3 && (
                        <div className={`w-12 h-0.5 mx-2 ${
                          index + 1 < currentStep ? 'bg-sage/60' : 'bg-mercury/30'
                        }`} />
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="px-8 py-6">
                <form onSubmit={handleFinalSubmit} className="space-y-6">
                  {currentStep === 1 && (
                    <motion.div
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      className="space-y-6"
                    >
                      <div className="grid grid-cols-2 gap-6">
                        <div className="space-y-2">
                          <Label htmlFor="title" className="flex items-center">
                            Tutorial Title <span className="text-watermelon ml-1">*</span>
                          </Label>
                          <Input
                            id="title"
                            value={formData.title}
                            onChange={(e) => setFormData({...formData, title: e.target.value})}
                            className={`h-10 ${!formData.title?.trim() && 'border-watermelon'}`}
                            required
                            placeholder="Enter tutorial title"
                          />
                          {!formData.title?.trim() && (
                            <p className="text-xs text-watermelon mt-1">Title is required</p>
                          )}
                        </div>
                        <div className="space-y-2">
                          <Label htmlFor="duration">Duration (minutes)</Label>
                          <Input
                            id="duration"
                            type="number"
                            value={formData.duration}
                            onChange={(e) => setFormData({...formData, duration: e.target.value})}
                            className="h-10"
                            required
                          />
                        </div>
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="description">Description</Label>
                        <Textarea
                          id="description"
                          value={formData.description}
                          onChange={(e) => setFormData({...formData, description: e.target.value})}
                          className="min-h-[100px]"
                          required
                        />
                      </div>
                    </motion.div>
                  )}

                  {currentStep === 2 && (
                    <motion.div
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      className="space-y-4"
                    >
                      <div className="space-y-2">
                        <Label htmlFor="content">Tutorial Content</Label>
                        <Textarea
                          id="content"
                          value={formData.content}
                          onChange={(e) => setFormData({...formData, content: e.target.value})}
                          className="min-h-[300px]"
                        />
                      </div>
                    </motion.div>
                  )}

                  {currentStep === 3 && (
                    <motion.div
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      className="space-y-6"
                    >
                      <div className="border-2 border-dashed border-mercury/50 rounded-xl p-8 text-center hover:border-sage/50 transition-colors">
                        <input
                          type="file"
                          id="video-upload"
                          className="hidden"
                          accept="video/*"
                          onChange={(e) => setVideoFile(e.target.files[0])}
                        />
                        <label
                          htmlFor="video-upload"
                          className="cursor-pointer space-y-4 block"
                        >
                          <div className="w-16 h-16 mx-auto bg-sage/10 rounded-full flex items-center justify-center">
                            <Video className="w-8 h-8 text-sage" />
                          </div>
                          <div>
                            <p className="text-lg font-medium text-hazel-green">
                              Upload Tutorial Video
                            </p>
                            <p className="text-sm text-bluish-grey mt-1">
                              Drag and drop or click to select a video file
                            </p>
                          </div>
                          <div className="text-xs text-bluish-grey">
                            Maximum file size: 500MB
                          </div>
                        </label>
                      </div>

                      {videoFile && (
                        <div className="bg-sage/5 rounded-lg p-4 mt-4">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                              <Video className="w-5 h-5 text-sage" />
                              <span className="text-sm font-medium">{videoFile.name}</span>
                            </div>
                            <Button
                              type="button"
                              variant="ghost"
                              size="sm"
                              onClick={() => setVideoFile(null)}
                              className="text-watermelon hover:text-watermelon/80"
                            >
                              <X className="w-4 h-4" />
                            </Button>
                          </div>
                          {uploadProgress > 0 && (
                            <div className="mt-3">
                              <div className="h-1.5 bg-mercury/30 rounded-full overflow-hidden">
                                <div
                                  className="h-full bg-sage transition-all duration-300"
                                  style={{ width: `${uploadProgress}%` }}
                                />
                              </div>
                              <span className="text-xs text-bluish-grey mt-1">
                                {uploadProgress}% uploaded
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </motion.div>
                  )}

                  {currentStep === 4 && (
                    <motion.div
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      className="space-y-6"
                    >
                      {formData.chapters.map((chapter, index) => (
                        <div key={index} className="space-y-3">
                          <Label htmlFor={`chapter-${index}`} className="text-hazel-green font-medium">
                            Chapter {index + 1} Title
                          </Label>
                          <Input
                            id={`chapter-${index}`}
                            value={chapter.title}
                            onChange={(e) => handleChapterChange(index, 'title', e.target.value)}
                            placeholder="Enter chapter title"
                            className="h-12 border-mercury/30 focus:border-sage focus:ring-sage/20 rounded-xl"
                          />
                        </div>
                      ))}
                      <Button
                        type="button"
                        onClick={addChapter}
                        className="w-full mt-4 bg-sage/10 text-sage hover:bg-sage/20"
                      >
                        <Plus className="w-4 h-4 mr-2" />
                        Add Chapter
                      </Button>
                    </motion.div>
                  )}

                  <div className="flex justify-end space-x-3 pt-6 border-t border-mercury/30">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => setIsCreateModalOpen(false)}
                    >
                      Cancel
                    </Button>
                    
                    {currentStep > 1 && (
                      <Button
                        type="button"
                        variant="outline"
                        onClick={handlePreviousStep}
                      >
                        Previous
                      </Button>
                    )}

                    {currentStep < 4 ? (
                      <Button
                        type="button"
                        onClick={handleNextStep}
                        className="bg-sage hover:bg-sage/90"
                      >
                        Continue
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="bg-sage hover:bg-sage/90"
                        disabled={loading}
                      >
                        {loading ? 'Creating...' : 'Create Tutorial'}
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setTutorialToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
        title="Delete Tutorial"
        message={`Are you sure you want to delete "${tutorialToDelete?.title}"? This action cannot be undone.`}
      />
    </div>
  );
};

export default TutorialManagement; 