import api from './api';


export const getAllRooms = () => api.get('api/rooms/rooms-list');




export const getRoomTypes = () => api.get(`/api/room-types`);

export const createRoomType = (values) => api.post(`/api/room-types`, values);

export const getRoomType = (id) => api.get(`/api/room-types/${id}`);

export const updateRoomType = (id, values) =>
  api.patch(`/api/room-types/${id}`, values);

export const deleteRoomType = (id) => api.delete(`/api/room-types/${id}`);

export const createRoom = (values) => api.post(`/api/rooms`, values);

export const getRoom = (id) => api.get(`/api/rooms/${id}`);

export const updateRoom = (id, values) => api.patch(`/api/rooms/${id}`, values);

export const deleteRoom = (id) => api.delete(`/api/rooms/${id}`);

export const fileUpload = (values) => api.post(`/api/media/upload`, values);



//reservations api:
export const getMetrics = () => api.get('/api/reservations/metrics');

export const getAllReservationsHistory = (page = 1, limit = 10) => 
  api.get(`/api/reservations/history?page=${page}&limit=${limit}`);

export const createReservation = (values) =>
  api.post(`/api/reservations`, values);

export const updateReservation = (id, values) =>
  api.patch(`/api/reservations/${id}`, values);

export const giveReservationFeedback = (id, values) =>
  api.patch(`/api/reservations/room-reservations/${id}/feedback/`, values);

export const getRoomHistory = (id) => api.get(`/api/reservations/rooms/${id}`);

export const getReservation = (id) => api.get(`/api/reservations/${id}`);
