import api from './noAuthApi';

export const getFixedAssets = async (params = {}) => {
  try {
    const response = await api.get('/api/fixed-assets', { params });
    return response.data?.data?.data?.data || [];
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const getFixedAsset = async (id) => {
  try {
    const response = await api.get(`/api/fixed-assets/${id}`);
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const createFixedAsset = async (data) => {
  try {
    const response = await api.post('/api/fixed-assets', data);
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const updateFixedAsset = async (id, data) => {
  try {
    const response = await api.put(`/api/fixed-assets/${id}`, data);
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const createMaintenanceRecord = async (assetId, data) => {
  try {
    const response = await api.post(`/api/fixed-assets/${assetId}/maintenance`, data);
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const createDisposalRecord = async (assetId, data) => {
  try {
    const response = await api.post(`/api/fixed-assets/${assetId}/disposal`, data);
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};
