import { motion } from 'framer-motion';
import React from 'react';

import { useState, useEffect } from 'react';
import { getAllInvoices, getAllInvoicesWithoutPagination } from '../../api/invoiceSale';
import { formatDate } from '../../lib/dateFormatter.ts';
import { cn } from '../../utils/cn.ts';

interface InvoiceSidebarProps {
  selectedInvoiceId: string;
  onInvoiceSelect: (id: string) => void;
}


interface Invoice {
  id: string;
  customer: string;
  date: string;
  amount: string;
  status: 'DRAFT' | 'PENDING' | 'PAID';
  orderNumber: string;
}

const invoices: Invoice[] = [
  {
    id: 'INV-000001',
    customer: 'sdsjkfsd,f',
    date: '27 Oct 2024',
    amount: '€0,00',
    status: 'DRAFT',
    orderNumber: '1'
  },
  {
    id: 'INV-000002',
    customer: 'Tech Solutions Inc',
    date: '26 Oct 2024',
    amount: '€2,500.00',
    status: 'PENDING',
    orderNumber: '2'
  },
  {
    id: 'INV-000003',
    customer: 'Global Systems Ltd',
    date: '25 Oct 2024',
    amount: '€1,800.00',
    status: 'PAID',
    orderNumber: '3'
  }
];

export function InvoiceSidebar({ selectedInvoiceId, onInvoiceSelect }: InvoiceSidebarProps) {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchInvoices = async () => {
    try {
      setLoading(true);
      const response = await getAllInvoicesWithoutPagination();
      console.log("All Invoices without pagination", response)
      setInvoices(response.data);
    } catch (error) {
      console.error('Failed to fetch invoices:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  if (loading) {
    return (
      <div className="p-4">
        <div className="animate-pulse space-y-4">
          {[1, 2, 3, 4, 5].map((i) => (
            <div key={i} className="h-20 bg-gray-100 rounded-lg"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-y-auto h-[calc(100vh-120px)]">
      <div className="space-y-2 p-4">
        {invoices.map((invoice) => (
          <motion.button
            key={invoice.id}
            onClick={() => onInvoiceSelect(invoice.id)}
            className={cn(
              "w-full text-left p-4 rounded-lg transition-colors duration-200",
              "hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-hazel-green focus:ring-opacity-50",
              selectedInvoiceId === invoice.id ? "bg-hazel-green/10 border-hazel-green" : "border-transparent"
            )}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="flex justify-between items-start">
              <div className="space-y-1">
                <p className="font-medium text-gray-900 line-clamp-1">
                  Invoice #{invoice.invoiceNumber}
                </p>
                <p className="text-sm text-gray-500 line-clamp-1">
                  {invoice.clientName || 'Client Name'}
                </p>
              </div>
              <div className="text-right">
                <p className="font-semibold text-hazel-green">
                  €{Number(invoice.totalAmount).toFixed(2)}
                </p>
                <p className="text-xs text-gray-500">
                  {formatDate(invoice.invoiceDate)}
                </p>
              </div>
            </div>
            <div className="mt-2 flex justify-between items-center">
              <span className={cn(
                "px-2 py-1 text-xs rounded-full",
                invoice.status === 'paid' 
                  ? "bg-green-100 text-green-800" 
                  : "bg-amber-100 text-amber-800"
              )}>
                {invoice.status}
              </span>
            </div>
          </motion.button>
        ))}
      </div>
    </div>
  );
}
