import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Save, Calendar, CreditCard, FileText, DollarSign, RotateCw } from 'lucide-react';
import { Label } from '../shadCN/Label.tsx';
import { Input } from '../shadCN/Input.tsx';
import { Textarea } from '../shadCN/TextArea.tsx';
import { Button } from '../shadCN/Button.tsx';

interface TransactionFormModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TransactionFormModal: React.FC<TransactionFormModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [formData, setFormData] = useState({
    date: new Date().toISOString().split('T')[0],
    description: '',
    debit: '0',
    credit: '0',
    status: 'për t\'u regjistruar'
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    // Add your submit logic here
    setTimeout(() => setIsLoading(false), 1000);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-bluish-grey/10 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="bg-white rounded-xl shadow-lg w-full max-w-2xl max-h-[90vh] flex flex-col overflow-hidden border border-mercury"
          >
            {/* Header */}
            <div className="p-6 border-b border-mercury bg-tealish-blue">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-hazel-green/10 rounded-lg">
                    <FileText className="h-5 w-5 text-hazel-green" />
                  </div>
                  <div>
                    <h2 className="text-xl font-semibold text-bluish-grey">
                      Regjistro transaksion
                    </h2>
                    <p className="text-sm text-grey-goose mt-1">
                      Plotësoni detajet e transaksionit bankar
                    </p>
                  </div>
                </div>
                <button
                  onClick={onClose}
                  className="p-2 rounded-lg text-grey-goose hover:text-bluish-grey hover:bg-mercury/50 transition-colors"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>

            {/* Form Content */}
            <div className="flex-1 overflow-y-auto bg-white">
              <div className="p-6 space-y-6">
                {/* Amount Input */}
                <div className="space-y-2">
                  <Label htmlFor="amount" className="text-sm text-bluish-grey font-medium">
                    Shuma e transaksionit
                  </Label>
                  <div className="relative">
                    <span className="absolute left-3 top-1/2 -translate-y-1/2">
                      <DollarSign className="h-5 w-5 text-grey-goose" />
                    </span>
                    <Input
                      type="number"
                      id="amount"
                      placeholder="0.00"
                      className="pl-10 bg-white border-mercury focus:border-hazel-green transition-colors text-bluish-grey placeholder:text-grey-goose"
                      value={formData.debit}
                      onChange={(e) => setFormData({ ...formData, debit: e.target.value })}
                    />
                  </div>
                </div>

                {/* Date Input */}
                <div className="space-y-2">
                  <Label htmlFor="date" className="text-sm text-bluish-grey font-medium">
                    Data e transaksionit
                  </Label>
                  <div className="relative">
                    <span className="absolute left-3 top-1/2 -translate-y-1/2">
                      <Calendar className="h-5 w-5 text-grey-goose" />
                    </span>
                    <Input
                      type="date"
                      id="date"
                      className="pl-10 bg-white border-mercury focus:border-hazel-green transition-colors text-bluish-grey"
                      value={formData.date}
                      onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                    />
                  </div>
                </div>

                {/* Description Input */}
                <div className="space-y-2">
                  <Label htmlFor="description" className="text-sm text-bluish-grey font-medium">
                    Përshkrimi i transaksionit
                  </Label>
                  <Textarea
                    id="description"
                    placeholder="Shkruani një përshkrim të shkurtër..."
                    className="bg-white border-mercury focus:border-hazel-green transition-colors text-bluish-grey placeholder:text-grey-goose resize-none min-h-[100px]"
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  />
                </div>

                {/* Status */}
                <div className="space-y-2">
                  <Label htmlFor="status" className="text-sm text-bluish-grey font-medium">
                    Statusi i transaksionit
                  </Label>
                  <div className="relative">
                    <select
                      id="status"
                      value={formData.status}
                      onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                      className="w-full appearance-none px-4 py-2 bg-white border border-mercury rounded-md text-bluish-grey focus:border-hazel-green transition-colors"
                    >
                      <option value="për t'u regjistruar">Për t'u regjistruar</option>
                      <option value="të kompletuara">Të kompletuara</option>
                      <option value="të anuluara">Të anuluara</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* Footer */}
            <div className="p-6 border-t border-mercury bg-tealish-blue flex justify-end gap-3">
              <Button
                variant="outline"
                onClick={onClose}
                className="border-mercury bg-white text-bluish-grey hover:bg-seashell hover:text-bluish-grey hover:border-mercury"
                disabled={isLoading}
              >
                Anulo
              </Button>
              <Button
                onClick={handleSubmit}
                className="bg-hazel-green text-white hover:bg-hazel-green/90 flex items-center gap-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <RotateCw className="h-4 w-4 animate-spin" />
                    Duke ruajtur...
                  </>
                ) : (
                  <>
                    <Save className="h-4 w-4" />
                    Ruaj
                  </>
                )}
              </Button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TransactionFormModal;
