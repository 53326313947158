import React, { useEffect, useState } from 'react';
import { getMessagesByGroup, sendMessage } from '../../api/chat';
import { format } from 'date-fns';
import { deleteMessage } from '../../api/chat';

const Chat = ({ groupId, receiverId, userId }) => {
  const [messages, setMessages] = useState([]);
  const [messageContent, setMessageContent] = useState('');
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  // const handleDeleteMessage = async (messageId) => {
  //   try {
  //     await deleteMessage(messageId);
  //     setMessages(messages.filter(message => message.id !== messageId));
  //   } catch (error) {
  //     console.error('Error deleting message:', error);
  //   }
  // };

  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteMessage(messageId);
      // Update local state to reflect message deletion
      setMessages(prevMessages => 
        prevMessages.map(message =>
          message.id === messageId ? { ...message, deleted: true } : message
        )
      );
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };


  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await getMessagesByGroup(groupId);
        // Initialize messages with deleted flag as false
        setMessages(response.data.map(msg => ({ ...msg, deleted: false })));
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [groupId]);
  



  const handleSendMessage = async () => {
    if (messageContent.trim()) {
      try {
        await sendMessage(messageContent, userId, receiverId, groupId);
        setMessageContent('');
        // Fetch and merge new messages while retaining deleted states
        const response = await getMessagesByGroup(groupId);
        setMessages(prevMessages =>
          response.data.map(newMsg => {
            const existingMsg = prevMessages.find(msg => msg.id === newMsg.id);
            return existingMsg ? { ...newMsg, deleted: existingMsg.deleted } : newMsg;
          })
        );
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };



  // return (

  //   <div className="flex flex-col h-full p-4 border border-gray-300">
  //   <div className="flex-1 overflow-y-auto p-2 border border-gray-200 mb-2 space-y-4">
  //     {messages.map((message, index) => (
        
  //       <div
  //         key={message.id}
  //         className={`flex items-start gap-2.5 ${
  //           message.sender.id === userId ? 'justify-end' : 'justify-start'
  //         }`}
  //       >
  //         <img
  //                 src='/images/profile-placeholder.png'
  //                 alt='logo'
  //                 className='w-10 h-10'
  //         />
  //         <div
  //           className={`flex flex-col w-full max-w-[320px] leading-1.5 p-4 ${
  //             message.sender.id === userId
  //               ? 'bg-blue-500 text-white rounded-tl-xl rounded-tr-xl'
  //               : 'bg-gray-100 text-gray-900 rounded-tr-xl rounded-br-xl'
  //           }`}
  //         >
  //           <div className="flex items-center space-x-2 rtl:space-x-reverse">
  //             <span
  //               className={`text-sm font-semibold ${
  //                 message.sender.id === userId ? 'text-white' : 'text-gray-900'
  //               }`}
  //             >
  //               {message.sender.name}
  //             </span>
  //             <span 
  //                 // className="text-sm font-normal text-gray-500 dark:text-gray-400"
  //                 className={`text-sm font-normal ${
  //                   message.sender.id == userId 
  //                     ? "text-gray-300 dark:text-gray-400"
  //                     : "text-gray-500 dark:text-gray-400"
  //                 }`}
  //             >
  //                       {format(new Date(message.createdAt), 'MMM d, yyyy h:mm a')}
  //             </span>
  //           </div>
  //           {message.deleted ? (
  //               <p className="text-sm font-normal py-2.5 text-gray-500 italic">
  //                 This message was deleted by {message.sender.name}.
  //               </p>
  //             ) : (
  //               <p className={`text-sm font-normal py-2.5 ${
  //                 message.sender.id === userId
  //                   ? 'text-slate-50 dark:text-white'
  //                   : 'text-gray-900 dark:text-white'
  //               }`}
  //               >
  //                 {message.content}
  //               </p>
  //             )}
  //             <span
  //               className={`text-sm font-normal ${
  //                 message.sender.id === userId
  //                   ? 'text-gray-300 dark:text-gray-400'
  //                   : 'text-gray-500 dark:text-gray-400'
  //               }`}
  //             >
  //               {message.deleted ? 'Deleted' : 'Delivered'}
  //             </span>
            
  //         </div>
  //         {message.sender.id === userId && (
  //           <div className="relative">
  //             <button
  //               onClick={() => toggleDropdown(index)}
  //               className="inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-800 dark:focus:ring-gray-600"
  //               type="button"
  //             >
  //               <svg
  //                 className="w-4 h-4 text-gray-500 dark:text-gray-400"
  //                 aria-hidden="true"
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 fill="currentColor"
  //                 viewBox="0 0 4 15"
  //               >
  //                 <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
  //               </svg>
  //             </button>

  //             {/* Dropdown menu */}
  //             {openDropdownIndex === index && (
  //               <div
  //                 className="absolute right-0 mt-2 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600"
  //               >
  //                 <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconButton">
  //                   <li>
  //                     <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Reply</a>
  //                   </li>
  //                   <li>
  //                     <a 
  //                         href="#"
  //                         className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
  //                         onClick={(e) => {
  //                           e.preventDefault();
  //                           handleDeleteMessage(message.id);
  //                         }}
  //                     >Delete</a>
  //                   </li>
  //                 </ul>
  //               </div>
  //             )}
  //           </div>
  //         )}
  //       </div>
  //     ))}
  //   </div>
  //   <div className="flex items-center p-4 border-t border-gray-300">
  //     <input
  //       type="text"
  //       value={messageContent}
  //       onChange={(e) => setMessageContent(e.target.value)}
  //       className="flex-1 p-2 border border-gray-300 rounded"
  //     />
  //     <button
  //       onClick={handleSendMessage}
  //       className="ml-2 p-2 bg-blue-500 text-white rounded"
  //     >
  //       Send
  //     </button>
  //   </div>
  // </div>
  // );

  return (
    <div className="flex flex-col h-full p-4 border border-gray-300 bg-gray-50">
      <div className="flex-1 overflow-y-auto p-2 bg-white border border-gray-200 rounded-lg shadow-md mb-2">
        {messages.map((message, index) => (
          <div
            key={message.id}
            className={`flex items-start gap-2 mb-2 ${message.sender.id === userId ? 'justify-end' : 'justify-start'}`}
          >
            <img
              src='/images/profile-placeholder.png'
              alt='Profile'
              className='w-10 h-10 rounded-full'
            />
            <div
              className={`flex flex-col max-w-xs p-4 rounded-lg ${message.sender.id === userId ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-900'}`}
            >
              <div className="flex items-center space-x-2 mb-1">
                <span className={`text-sm font-semibold ${message.sender.id === userId ? 'text-white' : 'text-gray-900'}`}>
                  {message.sender.name}
                </span>
                <span className="text-xs text-gray-500">
                  {format(new Date(message.createdAt), 'MMM d, yyyy h:mm a')}
                </span>
              </div>
              {message.deleted ? (
                <p className="text-sm italic text-gray-500">
                  This message was deleted by {message.sender.name}.
                </p>
              ) : (
                <p className={`text-sm ${message.sender.id === userId ? 'text-white' : 'text-gray-900'}`}>
                  {message.content}
                </p>
              )}
              <span className="text-xs text-gray-500">
                {message.deleted ? 'Deleted' : 'Delivered'}
              </span>
            </div>
            {message.sender.id === userId && (
              <div className="relative">
                <button
                  onClick={() => toggleDropdown(index)}
                  className="inline-flex items-center p-2 text-gray-500 hover:bg-gray-100 rounded-lg"
                >
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 4 15"
                  >
                    <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                  </svg>
                </button>
                {openDropdownIndex === index && (
                  <div className="absolute right-0 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg">
                    <ul className="py-2 text-sm text-gray-700">
                      <li>
                        <a href="#" className="block px-4 py-2 hover:bg-gray-100">Reply</a>
                      </li>
                      <li>
                        <a 
                          href="#"
                          className="block px-4 py-2 hover:bg-gray-100"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteMessage(message.id);
                          }}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="flex items-center mt-2 space-x-2 border-t border-gray-300 pt-2">
        <input
          type="text"
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          className="flex-1 p-2 border border-gray-300 rounded-lg"
          placeholder="Type a message..."
        />
        <button
          onClick={handleSendMessage}
          className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-lg"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;
