import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Calendar, ChevronDown, Download, RefreshCw, Settings, Filter } from 'lucide-react';
import { generateIncomeStatement } from '../../../api/incomeStatement';
import { useLocation } from '../../../hooks/useLocation.ts';

interface IncomeStatementData {
  id: string;
  documentNumber: string;
  startDate: string;
  endDate: string;
  status: string;
  totalRevenue: number;
  totalExpenses: number;
  netIncome: number;
  grossProfit: number;
  ebitda: number;
  ebit: number;
  ebt: number;
  eat: number;
  adjustedEat: number;
}

const IncomeStatementPage = () => {
  const [dateRange, setDateRange] = useState('');
  const [showReport, setShowReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [incomeStatement, setIncomeStatement] = useState<IncomeStatementData | null>(null);
  const { selectedLocation } = useLocation();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleGenerateReport = async () => {
    if (!startDate || !endDate || !selectedLocation?.id) {
      // Add your preferred toast/notification here
      return;
    }

    setLoading(true);
    try {
      const response = await generateIncomeStatement({
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate).toISOString(),
        locationId: selectedLocation.id
      });
      setIncomeStatement(response);
      console.log("response data for the generated response",response)
      setShowReport(true);
    } catch (error) {
      console.error('Error generating income statement:', error);
      // Add your preferred error notification here
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    handleGenerateReport();
  };

  const handleExport = () => {
    // Implement export functionality
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header Section */}
      <div className="bg-white/80 backdrop-blur-sm border-b border-mercury sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2 text-sm">
              <span className="text-sage hover:text-hazel-green font-medium transition-colors">Pasqyrat</span>
              <ChevronDown className="w-4 h-4 text-sage" />
              <span className="font-medium text-hazel-green">Pasqyra e të Ardhurave</span>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">
        {/* Form Card */}
        <div className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 mb-8 overflow-hidden">
          <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-8">
            <div className="max-w-2xl">
              <h1 className="text-2xl font-semibold text-hazel-green mb-3">Pasqyra e të Ardhurave</h1>
              <p className="text-grey-goose">
                Shfaqni Pasqyrën e të Ardhurave (Bilancin e Suksesit, Pasqyrën e Fitimit)
              </p>
            </div>
          </div>

          {/* Form Content */}
          <div className="p-8">
            <div className="max-w-xl mx-auto mb-8 space-y-4">
              <div className="relative group">
                <label>Start Date</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="w-full p-3 bg-seashell border border-mercury rounded-xl text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 hover:border-hazel-green/30 transition-colors"
                />
              </div>
              <div className="relative group">
                <label>End Date</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="w-full p-3 bg-seashell border border-mercury rounded-xl text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 hover:border-hazel-green/30 transition-colors"
                />
              </div>
            </div>

            {/* Generate Button */}
            <div className="flex justify-center gap-4">
              <button
                onClick={() => {
                  setStartDate('');
                  setEndDate('');
                }}
                className="px-6 py-3 bg-seashell text-grey-goose rounded-xl hover:bg-mercury transition-all duration-200"
              >
                Reset
              </button>
              <button
                onClick={handleGenerateReport}
                disabled={loading}
                className="px-6 py-3 bg-gradient-to-r from-hazel-green to-sage text-white rounded-xl hover:from-sage hover:to-hazel-green transition-all duration-300 flex items-center space-x-3 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span>{loading ? 'Duke gjeneruar...' : 'Gjenero Raportin'}</span>
              </button>
            </div>
          </div>
        </div>

        {/* Report Section */}
        {showReport ? (
          <div className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 overflow-hidden">
            {/* Report Header */}
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent p-6 border-b border-mercury/50">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-hazel-green">Detajet e Raportit</h2>
                <div className="flex items-center gap-3">
                  <button className="inline-flex items-center px-4 py-2.5 bg-white text-hazel-green rounded-lg hover:bg-seashell transition-colors duration-200 gap-2 font-medium border border-mercury/50 shadow-sm">
                    <Filter className="w-4 h-4" />
                    <span>Filtro</span>
                  </button>
                  <button 
                    onClick={handleRefresh}
                    className="inline-flex items-center px-4 py-2.5 bg-white text-hazel-green rounded-lg hover:bg-seashell transition-colors duration-200 gap-2 font-medium border border-mercury/50 shadow-sm"
                  >
                    <RefreshCw className="w-4 h-4" />
                    <span>Rifresko</span>
                  </button>
                  <button 
                    onClick={handleExport}
                    className="inline-flex items-center px-4 py-2.5 bg-white text-hazel-green rounded-lg hover:bg-seashell transition-colors duration-200 gap-2 font-medium border border-mercury/50 shadow-sm"
                  >
                    <Download className="w-4 h-4" />
                    <span>Eksporto</span>
                  </button>
                  <button className="inline-flex items-center px-4 py-2.5 bg-white text-hazel-green rounded-lg hover:bg-seashell transition-colors duration-200 gap-2 font-medium border border-mercury/50 shadow-sm">
                    <Settings className="w-4 h-4" />
                    <span>Opsionet</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Table Content */}
            <div className="p-6">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="text-left text-sm font-medium text-hazel-green py-3">Emri</th>
                    <th className="text-right text-sm font-medium text-hazel-green py-3">Periudha Aktuale (€)</th>
                    <th className="text-right text-sm font-medium text-hazel-green py-3">Periudha Paraprake (€)</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-mercury/50">
                  <tr className="hover:bg-seashell/30 transition-colors">
                    <td className="py-4 text-sm font-medium text-bluish-grey">Gross Profit</td>
                    <td className="py-4 text-sm text-right text-bluish-grey">{incomeStatement?.grossProfit.toFixed(2) || '0.00'}</td>
                    <td className="py-4 text-sm text-right text-bluish-grey">0.00</td>
                  </tr>
                  <tr className="hover:bg-seashell/30 transition-colors">
                    <td className="py-4 text-sm font-medium text-bluish-grey">EBITDA</td>
                    <td className="py-4 text-sm text-right text-bluish-grey">{incomeStatement?.ebitda.toFixed(2) || '0.00'}</td>
                    <td className="py-4 text-sm text-right text-bluish-grey">0.00</td>
                  </tr>
                  <tr className="hover:bg-seashell/30 transition-colors">
                    <td className="py-4 text-sm font-medium text-bluish-grey">EBIT</td>
                    <td className="py-4 text-sm text-right text-bluish-grey">{incomeStatement?.ebit.toFixed(2) || '0.00'}</td>
                    <td className="py-4 text-sm text-right text-bluish-grey">0.00</td>
                  </tr>
                  <tr className="hover:bg-seashell/30 transition-colors">
                    <td className="py-4 text-sm font-medium text-bluish-grey">EBT</td>
                    <td className="py-4 text-sm text-right text-bluish-grey">{incomeStatement?.ebt.toFixed(2) || '0.00'}</td>
                    <td className="py-4 text-sm text-right text-bluish-grey">0.00</td>
                  </tr>
                  <tr className="hover:bg-seashell/30 transition-colors">
                    <td className="py-4 text-sm font-medium text-bluish-grey">EAT</td>
                    <td className="py-4 text-sm text-right text-bluish-grey">{incomeStatement?.eat.toFixed(2) || '0.00'}</td>
                    <td className="py-4 text-sm text-right text-bluish-grey">0.00</td>
                  </tr>
                  <tr className="hover:bg-seashell/30 transition-colors">
                    <td className="py-4 text-sm font-medium text-bluish-grey">EAT Net Profit (after adjustments)</td>
                    <td className="py-4 text-sm text-right text-bluish-grey">{incomeStatement?.adjustedEat.toFixed(2) || '0.00'}</td>
                    <td className="py-4 text-sm text-right text-bluish-grey">0.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-gradient-to-b from-white to-seashell rounded-2xl shadow-sm p-16 text-center relative overflow-hidden"
          >
            {/* Background Pattern */}
            <div className="absolute inset-0 opacity-5">
              <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,#90a68c_1px,transparent_1px)] bg-[length:24px_24px]"></div>
            </div>

            {/* Content */}
            <div className="relative z-10">
              {/* Icon Container */}
              <div className="mb-6 relative">
                <div className="w-20 h-20 bg-seashell rounded-full flex items-center justify-center mx-auto">
                  <Calendar className="w-10 h-10 text-hazel-green" />
                </div>
              </div>

              {/* Text Content */}
              <div className="max-w-md mx-auto">
                <h3 className="text-xl font-semibold text-hazel-green mb-3">
                  Nuk ka pasqyrë të gjeneruar
                </h3>
                <p className="text-grey-goose mb-6 leading-relaxed">
                  Për të shfaqur pasqyrën e të ardhurave, ju lutem përzgjidhni periudhën kohore në formën më lart.
                </p>

                {/* Steps */}
                <div className="space-y-3 text-left bg-white/50 rounded-xl p-4 backdrop-blur-sm">
                  <div className="flex items-start gap-3">
                    <div className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center flex-shrink-0 mt-0.5">
                      <span className="text-sm text-hazel-green">1</span>
                    </div>
                    <div>
                      <p className="text-sm text-bluish-grey font-medium">Zgjidhni periudhën</p>
                      <p className="text-xs text-grey-goose">Përzgjidhni datën për të cilën dëshironi të shikoni pasqyrën</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <div className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center flex-shrink-0 mt-0.5">
                      <span className="text-sm text-hazel-green">2</span>
                    </div>
                    <div>
                      <p className="text-sm text-bluish-grey font-medium">Gjeneroni raportin</p>
                      <p className="text-xs text-grey-goose">Klikoni butonin "Gjenero Raportin" për të shfaqur të dhënat e pasqyrës</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="absolute bottom-0 left-0 w-32 h-32 bg-hazel-green/5 rounded-tr-full"></div>
            <div className="absolute top-0 right-0 w-24 h-24 bg-dawn-pink/10 rounded-bl-full"></div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default IncomeStatementPage;