import React from 'react';
import { IconArrowLeft } from '@tabler/icons-react';

interface MenuItem {
  id: string;
  name: string;
  price: number;
  imageUrl?: string;
  type: 'kitchen' | 'bar';
}

interface MenuSectionProps {
  title: string;
  items: MenuItem[];
  isExpanded: boolean;
  onToggle: () => void;
  onItemClick: (item: MenuItem) => void;
}

export default function MenuSection({ 
  title, 
  items, 
  isExpanded, 
  onToggle, 
  onItemClick 
}: MenuSectionProps) {
  return (
    <div className="rounded-lg bg-white shadow-sm overflow-hidden border border-grey-goose">
      <div 
        className="flex justify-between items-center p-3 cursor-pointer bg-seashell hover:bg-grey-goose/20 transition duration-300"
        onClick={onToggle}
      >
        <span className="text-sm font-medium text-bluish-grey">{title}</span>
        <IconArrowLeft 
          size={14} 
          className={`transform transition-transform duration-200 ${
            isExpanded ? 'rotate-90' : '-rotate-90'
          }`} 
        />
      </div>
      
      {isExpanded && (
        <div className="divide-y divide-grey-goose/30">
          {items.map((item) => (
            <div
              key={item.id}
              className="flex items-center space-x-3 p-2 hover:bg-gray-50 cursor-pointer transition-colors"
              onClick={() => onItemClick(item)}
            >
              {item.imageUrl && (
                <img
                  src={`http://localhost:3001${item.imageUrl}`}
                  alt={item.name}
                  className="w-10 h-10 object-cover rounded"
                />
              )}
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-bluish-grey truncate">{item.name}</p>
                <p className="text-xs text-gray-500">${item.price.toFixed(2)}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}