import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
// import  EnvironmentForm  from './EnvironmentForm';
import UserInvitationForm from './UserInvitationForm';
import {
    IconUserFilled
} from '@tabler/icons-react';

function AddUserInvitationForm() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
        //   <FontAwesomeIcon
        //     icon={faBuilding}
        //     className='text-hazel-green mr-2'
        //   />
        <IconUserFilled size={22}/>
        }
        title='Invite a user to the group'
      />
      <UserInvitationForm
        // isEdit={false}
        // environment={{
        //   name: '',
        //   restaurantId: ''
        // }}
      />
    </div>
  );
}

export default AddUserInvitationForm;
