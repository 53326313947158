import React, { useState, useEffect, useCallback } from 'react';
import CustomTable from '../../../../../components/table-components/table';
import Loading from '../../../../../components/loader';
import { getExpenses, deleteExpense } from '../../../../../api/finances'; 
import { IconTrash } from '@tabler/icons-react';
import { getEmployeeById } from '../../../../../api/employee';
import { getJobTitleById } from '../../../../../api/jobTitles'; // Import the job title API

function ExpenseList() {
    const [expensesList, setExpensesList] = useState([]);
    const [fetchingInitialData, setFetchingInitialData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [totalCount, setTotalCount] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [employeeCache, setEmployeeCache] = useState({}); // Cache for employee data
    const [jobTitleCache, setJobTitleCache] = useState({}); // Cache for job titles

    const getExpensesList = useCallback(async () => {
        try {
            const response = await getExpenses(rowsPerPage, currentPage + 1, searchText);
            const expenses = response.data || [];
            setTotalCount(expenses.length || 0);

            // Fetch employee and job title details for each expense
            const expensesWithEmployeeData = await Promise.all(expenses.map(async (expense) => {
                const employeeIdMatch = expense.transaction.description.match(/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/);
                let employeeData = null;
                let jobTitleData = null;

                if (employeeIdMatch && employeeIdMatch[0]) {
                    const employeeId = employeeIdMatch[0];
                    
                    // Check if employee data is already cached
                    if (!employeeCache[employeeId]) {
                        const employeeResponse = await getEmployeeById(employeeId); // Fetch employee details
                        employeeData = employeeResponse.data;
                        setEmployeeCache((prev) => ({ ...prev, [employeeId]: employeeData })); // Cache employee data
                    } else {
                        employeeData = employeeCache[employeeId]; // Use cached employee data
                    }

                    // Fetch job title if employee data is available
                    if (employeeData && employeeData.jobTitleId) {
                        if (!jobTitleCache[employeeData.jobTitleId]) {
                            const jobTitleResponse = await getJobTitleById(employeeData.jobTitleId);
                            jobTitleData = jobTitleResponse.data;
                            setJobTitleCache((prev) => ({ ...prev, [employeeData.jobTitleId]: jobTitleData })); // Cache job title
                        } else {
                            jobTitleData = jobTitleCache[employeeData.jobTitleId]; // Use cached job title
                        }
                    }
                }

                // Create a new expense object with employee and job title information
                return {
                    ...expense,
                    employee: employeeData,  // Store employee data
                    jobTitle: jobTitleData ? jobTitleData.title : null, // Add job title to expense
                };
            }));

            setExpensesList(expensesWithEmployeeData);
            console.log("EXPENSES", expensesWithEmployeeData);
        } catch (err) {
            setErrorMessage(err?.response?.data?.message || err.message);
        } finally {
            setFetchingInitialData(false);
            setLoading(false);
        }
    }, [currentPage, rowsPerPage, searchText, employeeCache, jobTitleCache]);

    useEffect(() => {
        setLoading(true);
        getExpensesList().finally(() => setLoading(false));
    }, [getExpensesList]);

    useEffect(() => {
        setLoading(true);
        getExpensesList().finally(() => setLoading(false));
    }, [currentPage, rowsPerPage, searchText]);

    const handleDelete = async (id) => {
        try {
            setLoading(true);
            await deleteExpense(id);
            setExpensesList((prevState) => prevState.filter((item) => item.id !== id));
            setTotalCount(totalCount - 1);
        } catch (err) {
            setErrorMessage(err?.response?.data?.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    if (fetchingInitialData) {
        return <Loading />;
    }

    if (!fetchingInitialData && errorMessage) {
        return (
            <div className='text-center'>
                <h1>{errorMessage}</h1>
            </div>
        );
    }

    return (
        <div className='p-10'>
            <div className='flex justify-between items-center mb-8'>
                <h1 className='text-2xl font-bold text-hazel-green'>Expenses</h1>
            </div>
            <CustomTable
                rows={expensesList}
                columns={[
                    {
                        title: 'Description',
                        renderValue: (item) => {
                            const employee = item.employee;
                            const jobTitle = item.jobTitle;

                            if (employee && jobTitle) {
                                return `Payment of the wage to employee ${employee.name} ${employee.surname} with the job title ${jobTitle}`;
                            } else if (employee) {
                                return `Payment related to employee ${employee.name} ${employee.surname}`;
                            } else if (jobTitle) {
                                return `Payment related to the job title ${jobTitle}`;
                            } else {
                                return 'Other expenses (e.g., hotel, supplies, etc.)';
                            }
                        },
                    },
                    {
                        title: 'Amount',
                        renderValue: (item) => `$${item.amount}`,
                    },
                    {
                        title: 'Date',
                        renderValue: (item) => new Date(item.transaction.transactionDate).toLocaleDateString(),
                    },
                ]}
                entity='expenses'
                setSearchText={setSearchText}
                loading={loading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                totalCount={totalCount}
                onDelete={handleDelete}
                showMoreActions={true}
            />
        </div>
    );
}

export default ExpenseList;
