import React, { useState, useRef, useEffect } from 'react';
import { 
  Download, 
  ZoomIn, 
  ZoomOut, 
  RotateCw, 
  Share2, 
  Printer, 
  ChevronLeft, 
  ChevronRight,
  Maximize2,
  FileText,
  Search,
  MoreHorizontal,
  X,
  Minus,
  Plus
} from 'lucide-react';
import { Document as PDFDocument, Page as PDFPage, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Modal from '../../../components/modal';
import { motion, AnimatePresence } from 'framer-motion';
import { Document as DocumentType, DocumentStatus } from '../../../types/document';
import { getDocumentContent, getDocumentDetails } from '../../../api/document';
import DocumentTemplate from './DocumentTemplate.tsx';
import { Button } from '../../../components/shadCN/Button.tsx';

// Initialize PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface DocumentPreviewProps {
  document: DocumentType;
  onClose: () => void;
}

const ZOOM_LEVELS = [25, 50, 75, 100, 125, 150, 175, 200];

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ document, onClose }) => {
  const [zoomLevel, setZoomLevel] = useState(100); 
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showToolbar, setShowToolbar] = useState(true);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageWidth, setPageWidth] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [documentUrl, setDocumentUrl] = useState<string | null>(null);
  const [documentDetails, setDocumentDetails] = useState<any>(null);
  const [isTemplateView, setIsTemplateView] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const previewRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const viewportRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        const delta = e.deltaY * -0.01;
        const newZoom = Math.min(Math.max(25, zoomLevel + delta * 10), 200);
        setZoomLevel(newZoom);
      }
    };

    const viewport = viewportRef.current;
    if (viewport) {
      viewport.addEventListener('wheel', handleWheel, { passive: false });
      return () => viewport.removeEventListener('wheel', handleWheel);
    }
  }, [zoomLevel]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (typeof document !== 'undefined') {
        setIsFullscreen(!!document.fullscreenElement);
      }
    };

    if (typeof document !== 'undefined' && document.addEventListener) {
      document.addEventListener('fullscreenchange', handleFullscreenChange);
      
      return () => {
        document.removeEventListener('fullscreenchange', handleFullscreenChange);
      };
    }
  }, []);

  const handleZoomIn = () => {
    const currentIndex = ZOOM_LEVELS.findIndex(level => level >= zoomLevel);
    const nextLevel = ZOOM_LEVELS[Math.min(currentIndex + 1, ZOOM_LEVELS.length - 1)];
    setZoomLevel(nextLevel);
  };

  const handleZoomOut = () => {
    const currentIndex = ZOOM_LEVELS.findIndex(level => level >= zoomLevel);
    const prevLevel = ZOOM_LEVELS[Math.max(currentIndex - 1, 0)];
    setZoomLevel(prevLevel);
  };

  const handleRotate = () => setRotation(prev => (prev + 90) % 360);
  const handlePrint = () => window.print();
  
  const handleDownload = async () => {
    if (documentUrl) {
      const link = document.createElement('a');
      link.href = documentUrl;
      link.download = document.name || 'document';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleFullscreen = async () => {
    if (!previewRef.current) return;

    try {
      if (typeof document !== 'undefined') {
        if (!document.fullscreenElement) {
          await previewRef.current.requestFullscreen();
        } else {
          await document.exitFullscreen();
        }
      }
    } catch (err) {
      console.error('Error toggling fullscreen:', err);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (zoomLevel > 100) {
      setIsDragging(true);
      setStartPos({
        x: e.clientX - position.x,
        y: e.clientY - position.y
      });
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging && zoomLevel > 100) {
      const newX = e.clientX - startPos.x;
      const newY = e.clientY - startPos.y;
      setPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleReset = () => {
    setZoomLevel(100);
    setPosition({ x: 0, y: 0 });
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setTotalPages(numPages);
    setLoading(false);
  };

  useEffect(() => {
    const loadDocument = async () => {
      try {
        setLoading(true);
        setError(null);

        // Get document details with all related data
        const details = await getDocumentDetails(document.id);
        setDocumentDetails(details);

        // Determine if we should use template view
        const useTemplate = details?.format === 'template' || 
                          ['sale', 'purchase', 'offer'].includes(details?.type?.toLowerCase());
        setIsTemplateView(useTemplate);

        if (!useTemplate && details?.fileUrl) {
          const content = await getDocumentContent(document.id);
          setDocumentUrl(content);
        }

        setLoading(false);
      } catch (err) {
        console.error('Error loading document:', err);
        setError('Failed to load document. Please try again.');
        setLoading(false);
      }
    };

    loadDocument();
  }, [document.id]);

  return (
    <AnimatePresence>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/60 backdrop-blur-sm p-4 md:p-6">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.2 }}
          className="relative w-full h-full max-w-[1800px] max-h-[90vh] bg-white rounded-2xl shadow-2xl overflow-hidden flex flex-col"
          ref={previewRef}
        >
          {/* Header */}
          <div className="flex items-center justify-between px-6 py-4 bg-seashell border-b border-mercury">
            <div className="flex items-center space-x-4">
              <div className="flex items-center justify-center w-10 h-10 bg-hazel-green/10 rounded-lg">
                <FileText className="w-5 h-5 text-hazel-green" />
              </div>
              <div>
                <h2 className="text-lg font-semibold text-gray-900">{document.name}</h2>
                <p className="text-sm text-bluish-grey">
                  {documentDetails?.type || 'Document'} #{document.id}
                </p>
              </div>
            </div>
            
            <div className="flex items-center space-x-2">
              <Button
                variant="ghost"
                size="sm"
                onClick={handleDownload}
                className="flex items-center space-x-2 text-bluish-grey hover:text-hazel-green hover:bg-hazel-green/5"
              >
                <Download className="w-4 h-4" />
                <span className="text-sm">Download</span>
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={handlePrint}
                className="flex items-center space-x-2 text-bluish-grey hover:text-hazel-green hover:bg-hazel-green/5"
              >
                <Printer className="w-4 h-4" />
                <span className="text-sm">Print</span>
              </Button>
              <button
                onClick={onClose}
                className="p-2 text-bluish-grey hover:text-gray-700 rounded-lg hover:bg-mercury/50 transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          </div>

          {/* Toolbar */}
          <div className="flex items-center justify-between px-6 py-3 bg-white border-b border-mercury">
            <div className="flex items-center space-x-4">
              <div className="flex items-center h-9 bg-tealish-blue rounded-lg">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleZoomOut}
                  disabled={zoomLevel <= 25}
                  className="px-2.5 h-full rounded-l-lg hover:bg-mercury/50 text-bluish-grey hover:text-hazel-green disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <Minus className="w-4 h-4" />
                </Button>
                <div className="px-3 flex items-center justify-center min-w-[4rem] border-x border-mercury">
                  <span className="text-sm font-medium text-gray-700">{zoomLevel}%</span>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleZoomIn}
                  disabled={zoomLevel >= 200}
                  className="px-2.5 h-full rounded-r-lg hover:bg-mercury/50 text-bluish-grey hover:text-hazel-green disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <Plus className="w-4 h-4" />
                </Button>
              </div>

              <div className="flex items-center space-x-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleRotate}
                  className="p-2 hover:bg-mercury/50 rounded-lg text-bluish-grey hover:text-hazel-green"
                >
                  <RotateCw className="w-4 h-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleFullscreen}
                  className="p-2 hover:bg-mercury/50 rounded-lg text-bluish-grey hover:text-hazel-green"
                >
                  <Maximize2 className="w-4 h-4" />
                </Button>
              </div>
            </div>

            {numPages > 1 && (
              <div className="flex items-center h-9 bg-tealish-blue rounded-lg">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className="px-2.5 h-full rounded-l-lg hover:bg-mercury/50 text-bluish-grey hover:text-hazel-green disabled:opacity-50"
                >
                  <ChevronLeft className="w-4 h-4" />
                </Button>
                <div className="px-3 flex items-center justify-center min-w-[5rem] border-x border-mercury">
                  <span className="text-sm font-medium text-gray-700">
                    {currentPage} of {numPages}
                  </span>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, numPages || 1))}
                  disabled={currentPage === numPages}
                  className="px-2.5 h-full rounded-r-lg hover:bg-mercury/50 text-bluish-grey hover:text-hazel-green disabled:opacity-50"
                >
                  <ChevronRight className="w-4 h-4" />
                </Button>
              </div>
            )}
          </div>

          {/* Document Content */}
          <div 
            ref={viewportRef}
            className="flex-1 overflow-auto relative bg-gradient-to-br from-tealish-blue to-seashell"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <div 
              ref={contentRef}
              className="min-h-full w-fit mx-auto p-4 transition-transform duration-200 ease-out cursor-move"
              style={{ 
                transform: `scale(${zoomLevel / 100}) translate(${position.x}px, ${position.y}px) rotate(${rotation}deg)`,
                transformOrigin: 'center',
                willChange: 'transform'
              }}
            >
              {loading ? (
                <div className="flex flex-col items-center justify-center space-y-3">
                  <div className="relative">
                    <div className="w-12 h-12 border-3 border-hazel-green/20 border-t-hazel-green rounded-full animate-spin" />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="w-6 h-6 bg-white rounded-full" />
                    </div>
                  </div>
                  <p className="text-sm text-bluish-grey">Loading document...</p>
                </div>
              ) : error ? (
                <div className="flex flex-col items-center justify-center space-y-3 text-watermelon">
                  <div className="p-3 bg-dawn-pink rounded-full">
                    <X className="w-6 h-6" />
                  </div>
                  <div className="text-center">
                    <p className="text-base font-medium">{error}</p>
                    <p className="text-sm text-bluish-grey mt-1">Please try again or contact support</p>
                  </div>
                </div>
              ) : isTemplateView ? (
                <div className="w-full max-w-[1200px] mx-auto">
                  <DocumentTemplate
                    type={documentDetails?.type}
                    data={documentDetails}
                  />
                </div>
              ) : (
                <div
                  className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform duration-300 ease-in-out"
                >
                  <PDFDocument
                    file={documentUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="max-w-none"
                  >
                    <PDFPage
                      pageNumber={currentPage}
                      width={pageWidth}
                      renderTextLayer={true}
                      renderAnnotationLayer={true}
                      className="bg-white"
                    />
                  </PDFDocument>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default DocumentPreview;
