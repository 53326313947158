import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const CategoryPage = () => {
  const { category } = useParams();

  // Helper function to generate table links
  const generateTableLink = (tableName) => {
    return `/${category}/tables/${tableName.replace(/\s+/g, '-').toLowerCase()}`;
  };

  // Helper function to get table options based on category
  const getTableOptions = () => {
    switch (category) {
      case 'accounting':
        return ['Libri i blerjes', 'Libri i shitjes', 'Balanci i gjendjes'];
      case 'finances':
        return ['Table A', 'Table B', 'Table C'];
      case 'statistics':
        return ['Table X', 'Table Y', 'Table Z'];
      default:
        return [];
    }
  };

  const tableOptions = getTableOptions();

  if (!category || tableOptions.length === 0) {
    return (
      <div className="p-10 bg-tealish-blue min-h-screen">
        <div className="container mx-auto">
          <h1 className="text-4xl font-bold mb-12 text-bluish-grey text-center">
            Invalid Category
          </h1>
          <p className="text-lg text-bluish-grey text-center">
            The selected category is invalid or no tables are available.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-10 min-h-screen">
      <div className="container mx-auto">
        {/* Title at the top */}
        <h1 className="text-4xl font-bold mb-12 text-bluish-grey text-center">
          {category.charAt(0).toUpperCase() + category.slice(1)} Page
        </h1>

        {/* Table Grid */}
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold mb-6 text-hazel-green border-b border-grey-goose pb-4">
            Select a Table
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-t border-l border-grey-goose">
            {tableOptions.map((table, index) => (
              <Link
                to={generateTableLink(table)}
                key={index}
                className="bg-tealish-blue p-6 border-b border-r border-grey-goose hover:bg-mercury transition-colors duration-300 text-hazel-green font-medium text-lg text-center flex items-center justify-center"
              >
                {table}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
