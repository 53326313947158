import React from 'react';
import { IconPropeller } from '@tabler/icons-react';
import PageTitle from '../../components/page-title';
import RoleForm from './RoleForm';

function AddRole() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconPropeller className='text-hazel-green mr-2' size={22} />}
        title='Create a new role'
      />
      <RoleForm role={{ title: '', entity: 'user' }} />
    </div>
  );
}

export default AddRole;
