import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Save, RotateCw, AlertCircle } from 'lucide-react';
import { createUnit, updateUnit } from '../../api/accountingArticle';
import { toast } from 'react-hot-toast';

interface UnitOfMeasurementModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  unit?: {
    id: string;
    name: string;
    isActive: boolean;
    isWholeNumber: boolean;
  } | null;
}

const UnitOfMeasurementModal: React.FC<UnitOfMeasurementModalProps> = ({ isOpen, onClose, onSuccess, unit }) => {
  const [formData, setFormData] = useState({
    name: '',
    isActive: true,
    isWholeNumber: true
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (unit) {
      setFormData({
        name: unit.name,
        isActive: unit.isActive,
        isWholeNumber: unit.isWholeNumber
      });
    } else {
      setFormData({
        name: '',
        isActive: true,
        isWholeNumber: true
      });
    }
  }, [unit]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      if (unit) {
        await updateUnit(unit.id, {
          ...formData,
          updatedBy: 'admin' // Replace with actual user
        });
        toast.success('Njësia matëse u përditësua me sukses');
      } else {
        await createUnit({
          ...formData,
          createdBy: 'admin' // Replace with actual user
        });
        toast.success('Njësia matëse u krijua me sukses');
      }
      onSuccess?.();
      onClose();
    } catch (err: any) {
      console.error('Failed to save unit:', err);
      setError(err.response?.data?.message || (unit ? 'Dështoi përditësimi i njësisë matëse' : 'Dështoi krijimi i njësisë matëse'));
      toast.error(unit ? 'Dështoi përditësimi i njësisë matëse' : 'Dështoi krijimi i njësisë matëse');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-bluish-grey/60 backdrop-blur-sm z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
          <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{ type: "spring", duration: 0.5 }}
              className="bg-white rounded-2xl shadow-2xl w-full max-w-lg overflow-hidden border border-grey-goose/20"
            >
              <div className="relative px-8 py-6 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <h2 className="text-2xl font-semibold text-bluish-grey">
                      {unit ? 'Përditëso Njësinë Matëse' : 'Shto një Njësi Matëse'}
                    </h2>
                    <span className="px-2 py-1 text-xs bg-hazel-green/10 text-hazel-green rounded-full">
                      {unit ? 'Përditësim' : 'Njësi e re'}
                    </span>
                  </div>
                  <button
                    onClick={onClose}
                    className="text-grey-goose hover:text-bluish-grey transition-colors"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="p-8">
                {error && (
                  <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg flex items-center gap-3 text-red-700">
                    <AlertCircle className="w-5 h-5" />
                    <p>{error}</p>
                  </div>
                )}

                <div className="space-y-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-bluish-grey mb-2">
                      Emri i Njësisë Matëse
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="w-full px-4 py-2 border border-mercury rounded-lg focus:outline-none focus:border-hazel-green"
                      placeholder="p.sh. Copë, Kilogram, Litër"
                      required
                    />
                  </div>

                  <div className="flex items-center gap-4">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="isActive"
                        checked={formData.isActive}
                        onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
                        className="w-4 h-4 text-hazel-green border-mercury rounded focus:ring-hazel-green"
                      />
                      <label htmlFor="isActive" className="ml-2 text-sm text-bluish-grey">
                        Aktiv
                      </label>
                    </div>

                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="isWholeNumber"
                        checked={formData.isWholeNumber}
                        onChange={(e) => setFormData({ ...formData, isWholeNumber: e.target.checked })}
                        className="w-4 h-4 text-hazel-green border-mercury rounded focus:ring-hazel-green"
                      />
                      <label htmlFor="isWholeNumber" className="ml-2 text-sm text-bluish-grey">
                        Numër i plotë
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-8 flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 text-bluish-grey hover:bg-seashell rounded-lg transition-colors"
                  >
                    Anulo
                  </button>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="flex items-center gap-2 px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-opacity-90 transition-colors disabled:opacity-50"
                  >
                    {isLoading ? <RotateCw className="w-4 h-4 animate-spin" /> : <Save className="w-4 h-4" />}
                    {unit ? 'Ruaj ndryshimet' : 'Ruaj'}
                  </button>
                </div>
              </form>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  );
};

export default UnitOfMeasurementModal;
