import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: JSON.parse(localStorage.getItem('user'))
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    }
  }
});

const { actions, reducer } = userSlice;

export const { setUser } = actions;

export default reducer;
