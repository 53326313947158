import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Search, Filter, Settings, Plus, RefreshCw, ArrowDownUp, Calendar, ChevronDown, Eye, Edit, Trash2, ChevronLeft, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { getJournalEntries, getJournalEntryById, deleteJournalEntry } from '../../../api/journal';
import { toast } from 'react-hot-toast';
import ViewJournalEntryModal from '../../../components/modals/ViewJournalEntryModal.tsx';
import DeleteJournalEntryModal from '../../../components/modals/DeleteJournalEntryModal.tsx';

const StatusBadge = ({ status }: { status: string }) => {
  const statusStyles = {
    DRAFT: 'bg-grey-goose/10 text-grey-goose',
    POSTED: 'bg-hazel-green/10 text-hazel-green',
    CANCELLED: 'bg-red-100 text-red-600',
  };

  const statusText = {
    DRAFT: 'Draft',
    POSTED: 'Postuar',
    CANCELLED: 'Anuluar'
  };

  return (
    <span className={`px-2.5 py-1 rounded-full text-xs font-medium ${statusStyles[status] || statusStyles.DRAFT}`}>
      {statusText[status] || status}
    </span>
  );
};

const JournalEntriesPage = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState(null);
  const [filters, setFilters] = useState({
    search: '',
    status: '',
    startDate: '',
    endDate: '',
    page: 1,
    limit: 6
  });

  // Add pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const entriesPerPage = 6;

  // Calculate total pages
  const totalPages = Math.max(1, Math.ceil(totalEntries / entriesPerPage));

  const handleViewEntry = async (id: string) => {
    try {
      const response = await getJournalEntryById(id);
      setSelectedEntry(response.data.message);
      setIsViewModalOpen(true);
    } catch (error) {
      console.error('Error fetching journal entry:', error);
      toast.error('Dështoi në marrjen e të dhënave');
    }
  };

  // Modify fetchEntries function
  const fetchEntries = async () => {
    try {
      const response = await getJournalEntries({
        ...filters,
        page: currentPage,
        limit: 6
      });
      
      if (response?.message) {
        setEntries(response.message.data || []);
        setTotalEntries(response.message.total || 0);
      }
    } catch (error) {
      console.error('Failed to fetch entries:', error);
    }
  };

  // Update handleFilterChange to reset pagination when filters change
  const handleFilterChange = (key: string, value: any) => {
    setFilters(prev => ({
      ...prev,
      [key]: value,
      page: key === 'page' ? value : 1
    }));
    setCurrentPage(1);
  };

  // Update handlePageChange
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      setFilters(prev => ({
        ...prev,
        page: page
      }));
    }
  };

  // Add useEffect to handle pagination
  useEffect(() => {
    fetchEntries();
  }, [currentPage, filters]);

  const handleDeleteEntry = async () => {
    try {
      setIsLoading(true);
      await deleteJournalEntry(entryToDelete.id);
      toast.success('Regjistrimi u fshi me sukses');
      setIsDeleteModalOpen(false);
      setEntryToDelete(null);
      // Refresh the entries list
      fetchEntries();
    } catch (error) {
      console.error('Error deleting journal entry:', error);
      toast.error(error.response?.data?.message || 'Dështoi fshirja e regjistrimit');
    } finally {
      setIsLoading(false);
    }
  };

  const openDeleteModal = (entry) => {
    setEntryToDelete(entry);
    setIsDeleteModalOpen(true);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <h1 className="text-lg font-semibold text-hazel-green">Regjistrimet në Ditar</h1>
            <button
              onClick={() => navigate('/accounting/journal-entries/new')}
              className="px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 
                       inline-flex items-center gap-2 transition-colors"
            >
              <Plus className="h-4 w-4" />
              Shto Regjistrim
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Filters */}
          <div className="bg-white/80 backdrop-blur-sm rounded-xl shadow-sm border border-mercury/40 p-6">
            <div className="flex flex-col sm:flex-row gap-4 sm:items-center sm:justify-between">
              {/* Search */}
              <div className="relative flex-1 max-w-lg">
                <input
                  type="text"
                  placeholder="Kërko sipas numrit të dokumentit..."
                  value={filters.search}
                  onChange={(e) => handleFilterChange('search', e.target.value)}
                  className="w-full pl-10 pr-4 py-2.5 rounded-lg border border-mercury/50 text-sm text-bluish-grey
                           focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                           hover:border-hazel-green/50 transition-all"
                />
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-grey-goose" />
              </div>

              {/* Filter Buttons */}
              <div className="flex items-center gap-3">
                <button
                  onClick={() => setCollapsed(!collapsed)}
                  className="px-4 py-2.5 bg-white text-bluish-grey rounded-lg hover:bg-seashell
                           inline-flex items-center gap-2 border border-mercury/50 transition-colors"
                >
                  <Filter className="h-4 w-4" />
                  Filtro
                  <ChevronDown className={collapsed ? "h-4 w-4 transition-transform rotate-180" : "h-4 w-4 transition-transform"} />
                </button>
                <button
                  onClick={fetchEntries}
                  className="p-2.5 bg-white text-bluish-grey rounded-lg hover:bg-seashell
                           border border-mercury/50 transition-colors"
                >
                  <RefreshCw className="h-4 w-4" />
                </button>
              </div>
            </div>

            {/* Expanded Filters */}
            <AnimatePresence>
              {collapsed && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="overflow-hidden"
                >
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4 pt-4 border-t border-mercury/40">
                    {/* Status Filter */}
                    <div>
                      <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                        Statusi
                      </label>
                      <select
                        value={filters.status}
                        onChange={(e) => handleFilterChange('status', e.target.value)}
                        className="w-full px-3 py-2 rounded-lg border border-mercury/50 text-sm text-bluish-grey
                                 focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                 hover:border-hazel-green/50 transition-all"
                      >
                        <option value="">Të gjitha</option>
                        <option value="DRAFT">Draft</option>
                        <option value="POSTED">Postuar</option>
                        <option value="CANCELLED">Anuluar</option>
                      </select>
                    </div>

                    {/* Date Range */}
                    <div>
                      <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                        Data e fillimit
                      </label>
                      <div className="relative">
                        <input
                          type="date"
                          value={filters.startDate}
                          onChange={(e) => handleFilterChange('startDate', e.target.value)}
                          className="w-full pl-10 pr-4 py-2 rounded-lg border border-mercury/50 text-sm text-bluish-grey
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all"
                        />
                        <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-grey-goose" />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                        Data e mbarimit
                      </label>
                      <div className="relative">
                        <input
                          type="date"
                          value={filters.endDate}
                          onChange={(e) => handleFilterChange('endDate', e.target.value)}
                          className="w-full pl-10 pr-4 py-2 rounded-lg border border-mercury/50 text-sm text-bluish-grey
                                   focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                                   hover:border-hazel-green/50 transition-all"
                        />
                        <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-grey-goose" />
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Entries Table */}
          <div className="bg-white/80 backdrop-blur-sm rounded-xl shadow-sm border border-mercury/40 overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-mercury/40 bg-seashell/50">
                    <th scope="col" className="py-4 px-4 text-left">
                      <div className="flex items-center gap-2">
                        <span className="text-xs font-medium text-bluish-grey uppercase tracking-wider">Nr. Dokumenti</span>
                        <ArrowDownUp className="h-3 w-3 text-grey-goose" />
                      </div>
                    </th>
                    <th scope="col" className="py-4 px-4 text-left">
                      <span className="text-xs font-medium text-bluish-grey uppercase tracking-wider">Data</span>
                    </th>
                    <th scope="col" className="py-4 px-4 text-left">
                      <span className="text-xs font-medium text-bluish-grey uppercase tracking-wider">Statusi</span>
                    </th>
                    <th scope="col" className="py-4 px-4 text-left">
                      <span className="text-xs font-medium text-bluish-grey uppercase tracking-wider">Përshkrimi</span>
                    </th>
                    <th scope="col" className="py-4 px-4 text-right">
                      <span className="text-xs font-medium text-bluish-grey uppercase tracking-wider">Debi</span>
                    </th>
                    <th scope="col" className="py-4 px-4 text-right">
                      <span className="text-xs font-medium text-bluish-grey uppercase tracking-wider">Kredi</span>
                    </th>
                    <th scope="col" className="py-4 px-4 text-right">
                      <span className="text-xs font-medium text-bluish-grey uppercase tracking-wider">Veprime</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-mercury/40">
                  {entries.map((entry) => (
                    <tr key={entry.id} className="group hover:bg-seashell/50 transition-colors">
                      <td className="py-4 px-4">
                        <span className="text-sm font-medium text-bluish-grey">{entry.documentNumber}</span>
                      </td>
                      <td className="py-4 px-4">
                        <span className="text-sm text-grey-goose">
                          {format(new Date(entry.postingDate), 'dd/MM/yyyy')}
                        </span>
                      </td>
                      <td className="py-4 px-4">
                        <StatusBadge status={entry.status} />
                      </td>
                      <td className="py-4 px-4">
                        <span className="text-sm text-grey-goose line-clamp-1">{entry.description || '-'}</span>
                      </td>
                      <td className="py-4 px-4 text-right">
                        <span className="text-sm font-medium text-bluish-grey">{entry.totalDebit.toFixed(2)}</span>
                      </td>
                      <td className="py-4 px-4 text-right">
                        <span className="text-sm font-medium text-bluish-grey">{entry.totalCredit.toFixed(2)}</span>
                      </td>
                      <td className="py-4 px-4">
                        <div className="flex items-center justify-end gap-2">
                          <button
                            onClick={() => handleViewEntry(entry.id)}
                            className="p-2 text-bluish-grey hover:text-hazel-green hover:bg-hazel-green/10 
                                     rounded-lg transition-colors"
                            title="Shiko"
                          >
                            <Eye className="w-4 h-4" />
                          </button>
                          {entry.status === 'DRAFT' && (
                            <>
                              <button
                                onClick={() => navigate(`/accounting/journal-entries/${entry.id}/edit`)}
                                className="p-2 text-bluish-grey hover:text-hazel-green hover:bg-hazel-green/10 
                                         rounded-lg transition-colors"
                                title="Ndrysho"
                              >
                                <Edit className="w-4 h-4" />
                              </button>
                              <button
                                onClick={() => openDeleteModal(entry)}
                                className="p-2 text-bluish-grey hover:text-red-500 hover:bg-red-50 
                                         rounded-lg transition-colors"
                                title="Fshij"
                              >
                                <Trash2 className="w-4 h-4" />
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Add pagination after the table */}
          <div className="mt-4 flex items-center justify-between px-4 py-3 bg-white/80 backdrop-blur-sm rounded-xl border border-mercury/40">
            <div className="flex items-center text-sm text-grey-goose">
              Showing page {currentPage} of {totalPages}
            </div>
            
            <div className="flex items-center gap-2">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="p-2 text-bluish-grey hover:text-hazel-green hover:bg-hazel-green/10 
                         rounded-lg transition-colors disabled:opacity-50 disabled:hover:bg-transparent
                         disabled:hover:text-bluish-grey"
                title="Previous"
              >
                <ChevronLeft className="w-4 h-4" />
              </button>

              {totalPages > 0 && Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                let pageNumber;
                if (totalPages <= 5) {
                  pageNumber = i + 1;
                } else {
                  if (currentPage <= 3) {
                    pageNumber = i + 1;
                  } else if (currentPage >= totalPages - 2) {
                    pageNumber = totalPages - 4 + i;
                  } else {
                    pageNumber = currentPage - 2 + i;
                  }
                }
                
                return (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`w-8 h-8 rounded-lg text-sm font-medium transition-colors
                              ${currentPage === pageNumber
                                ? 'bg-hazel-green text-white'
                                : 'text-bluish-grey hover:bg-hazel-green/10'
                              }`}
                  >
                    {pageNumber}
                  </button>
                );
              })}

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="p-2 text-bluish-grey hover:text-hazel-green hover:bg-hazel-green/10 
                         rounded-lg transition-colors disabled:opacity-50 disabled:hover:bg-transparent
                         disabled:hover:text-bluish-grey"
                title="Next"
              >
                <ChevronRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      <ViewJournalEntryModal
        isOpen={isViewModalOpen}
        onClose={() => {
          setIsViewModalOpen(false);
          setSelectedEntry(null);
        }}
        entry={selectedEntry}
      />

      <DeleteJournalEntryModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setEntryToDelete(null);
        }}
        onConfirm={handleDeleteEntry}
        documentNumber={entryToDelete?.documentNumber}
      />
    </div>
  );
};

export default JournalEntriesPage;
