import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Building2, MoreVertical, Plus, Edit2, Trash2, CreditCard, DollarSign } from 'lucide-react';
import { getBankAccounts, deleteBankAccount } from '../../api/company';
import { toast } from 'react-hot-toast';

interface BankAccount {
  id: string;
  bankName: string;
  accountNumber: string;
  currency: string;
  description?: string;
  balance: string;
}

interface BankAccountsTableProps {
  onAdd: () => void;
  companyId: string;
}

export default function BankAccountsTable({ onAdd, companyId }: BankAccountsTableProps) {
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (companyId) {
      fetchBankAccounts();
    }
  }, [companyId]);

  const fetchBankAccounts = async () => {
    if (!companyId) return;
    try {
      setLoading(true);
      const response = await getBankAccounts(companyId);
      // Ensure we always set an array, even if the response is null/undefined
      setBankAccounts(Array.isArray(response?.data?.message) ? response.data.message : []);
    } catch (error) {
      console.error('Error fetching bank accounts:', error);
      toast.error('Gabim gjatë marrjes së llogarive bankare');
      setBankAccounts([]); // Set empty array on error
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      await deleteBankAccount(id);
      toast.success('Llogaria bankare u fshi me sukses');
      fetchBankAccounts();
    } catch (error) {
      console.error('Error deleting bank account:', error);
      toast.error('Gabim gjatë fshirjes së llogarisë bankare');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-xl shadow-sm border border-grey-goose/20 overflow-hidden">
        <div className="px-6 py-4 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/20">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-lg font-semibold text-bluish-grey">Llogaritë Bankare</h2>
              <p className="text-sm text-grey-goose">Menaxhoni llogaritë bankare të organizatës suaj</p>
            </div>
            <motion.button
              onClick={onAdd}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 transition-all duration-200 flex items-center gap-2 shadow-lg shadow-hazel-green/20"
              disabled={loading}
            >
              <Plus className="w-4 h-4" />
              Shto Llogari
            </motion.button>
          </div>
        </div>

        <div className="p-6">
          {loading ? (
            <div className="text-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-hazel-green mx-auto"></div>
              <p className="text-grey-goose mt-2">Duke ngarkuar...</p>
            </div>
          ) : bankAccounts.length === 0 ? (
            <div className="text-center py-8 text-grey-goose">
              <p>Nuk ka llogari bankare të regjistruara</p>
              <button
                onClick={onAdd}
                className="mt-2 text-hazel-green hover:underline focus:outline-none"
              >
                Shto llogarinë e parë bankare
              </button>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {bankAccounts.map((account) => (
                <motion.div
                  key={account.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="bg-white rounded-xl shadow-sm border border-grey-goose/20 overflow-hidden hover:shadow-md transition-all duration-200"
                >
                  <div className="p-6 space-y-4">
                    {/* Header */}
                    <div className="flex items-start justify-between">
                      <div className="flex items-center gap-3">
                        <div className="w-10 h-10 rounded-lg bg-hazel-green/10 flex items-center justify-center">
                          <CreditCard className="w-5 h-5 text-hazel-green" />
                        </div>
                        <div>
                          <h3 className="text-lg font-semibold text-bluish-grey">{account.bankName}</h3>
                          <p className="text-sm text-grey-goose">{account.accountNumber}</p>
                        </div>
                      </div>
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => handleDelete(account.id)}
                        className="p-2 hover:bg-red-50 rounded-lg transition-colors"
                      >
                        <Trash2 className="w-4 h-4 text-red-500" />
                      </motion.button>
                    </div>

                    {/* Currency and Balance */}
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-2 text-grey-goose">
                        <DollarSign className="w-4 h-4" />
                        <span className="text-sm">{account.currency}</span>
                      </div>
                      {account.balance && (
                        <div className="text-sm font-medium text-bluish-grey">
                          {account.balance}
                        </div>
                      )}
                    </div>

                    {/* Description */}
                    {account.description && (
                      <p className="text-sm text-grey-goose">{account.description}</p>
                    )}
                  </div>
                </motion.div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
