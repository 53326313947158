import React, { useState, useEffect } from 'react';
import { PlusIcon, CalendarIcon, ArrowUpIcon, ArrowDownIcon, HistoryIcon, BanknoteIcon, SearchIcon } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/shadCN/Card.tsx";
import { Input } from "../../components/shadCN/Input.tsx";
import { Button } from '../../components/shadCN/Button.tsx';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/shadCN/Select.tsx";
import { useSelector } from 'react-redux';
import { getDailyLogs, addTransaction, aggregateTotalLogs } from "../../api/waiter.js";

const WaiterCashLog = () => {
  const [date, setDate] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [transactionType, setTransactionType] = useState('Hyrje');
  const [dailyCashRegisters, setDailyCashRegisters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalLosses, setTotalLosses] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const user = useSelector((state) => state.user.user);
  const waiterId = user?.id;

  useEffect(() => {
    const fetchDailyLogs = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getDailyLogs(waiterId);
        const logs = response.data;
        const allTransactions = logs.flatMap(log => log.WaiterTransaction || []);
        setDailyCashRegisters(allTransactions);

        const totalResponse = await aggregateTotalLogs(waiterId);
        const { totalEarnings, totalLosses, totalBalance } = totalResponse.data;
        setTotalEarnings(totalEarnings);
        setTotalLosses(totalLosses);
        setTotalBalance(totalBalance);
      } catch (error) {
        console.error("Error fetching daily logs:", error);
        setError("Failed to fetch daily logs.");
      } finally {
        setLoading(false);
      }
    };

    fetchDailyLogs();
  }, [waiterId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newTransaction = { date, amount: parseFloat(amount), description, transactionType };
    try {
      const logsResponse = await getDailyLogs(waiterId);
      const logs = logsResponse.data;
      const logId = logs[0]?.id;

      if (logId) {
        await addTransaction(logId, newTransaction);
        setDailyCashRegisters(prev => [...prev, newTransaction]);
        setDate('');
        setAmount('');
        setDescription('');
      } else {
        console.error("No active log found for this waiter.");
      }
    } catch (error) {
      console.error("Error creating transaction:", error);
      setError("Failed to create transaction.");
    }
  };

  const filteredTransactions = dailyCashRegisters.filter(transaction =>
    transaction.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    transaction.amount.toString().includes(searchTerm) ||
    new Date(transaction.createdAt).toLocaleDateString().includes(searchTerm)
  );

  return (
    <div className="min-h-screen bg-seashell">
      <div className="max-w-7xl mx-auto p-6 space-y-8">
        {/* Header */}
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <div className="p-3 bg-hazel-green/10 rounded-full">
              <BanknoteIcon className="h-8 w-8 text-hazel-green" />
            </div>
            <h1 className="text-4xl font-bold text-hazel-green">
              Daily Cash Log
            </h1>
          </div>
        </div>

        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <Card className="bg-white/80 backdrop-blur border-l-4 border-l-sage hover:shadow-lg transition-all duration-300">
            <CardHeader className="pb-2">
              <CardTitle className="text-sage flex items-center text-lg font-medium">
                <ArrowUpIcon className="mr-2 h-5 w-5" />
                Total Income
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-3xl font-bold text-hazel-green">{totalEarnings.toFixed(2)} €</p>
            </CardContent>
          </Card>

          <Card className="bg-white/80 backdrop-blur border-l-4 border-l-salmon-pink hover:shadow-lg transition-all duration-300">
            <CardHeader className="pb-2">
              <CardTitle className="text-salmon-pink flex items-center text-lg font-medium">
                <ArrowDownIcon className="mr-2 h-5 w-5" />
                Total Expenses
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-3xl font-bold text-watermelon">{totalLosses.toFixed(2)} €</p>
            </CardContent>
          </Card>

          <Card className="bg-white/80 backdrop-blur border-l-4 border-l-bluish-grey hover:shadow-lg transition-all duration-300">
            <CardHeader className="pb-2">
              <CardTitle className="text-bluish-grey flex items-center text-lg font-medium">
                <HistoryIcon className="mr-2 h-5 w-5" />
                Balance
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-3xl font-bold text-bluish-grey">{(totalEarnings - totalLosses).toFixed(2)} €</p>
            </CardContent>
          </Card>
        </div>

        {/* Transaction Form */}
        <Card className="bg-white/90 backdrop-blur border-t-4 border-t-hazel-green">
          <CardHeader>
            <CardTitle className="text-hazel-green flex items-center text-xl">
              <PlusIcon className="mr-2 h-5 w-5" />
              New Transaction
            </CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-bluish-grey">Type</label>
                  <Select onValueChange={setTransactionType} defaultValue={transactionType}>
                    <SelectTrigger className="w-full border-grey-goose focus:ring-hazel-green focus:border-hazel-green">
                      <SelectValue placeholder="Select type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Hyrje">Income</SelectItem>
                      <SelectItem value="Dalje">Expense</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-bluish-grey">Date</label>
                  <div className="relative">
                    <Input
                      type="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      className="w-full border-grey-goose focus:ring-hazel-green focus:border-hazel-green"
                    />
                    <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-5 w-5 text-grey-goose" />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-bluish-grey">Amount</label>
                  <div className="relative">
                    <Input
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      className="w-full border-grey-goose focus:ring-hazel-green focus:border-hazel-green pr-8"
                    />
                    <span className="absolute right-3 top-1/2 -translate-y-1/2 text-grey-goose">€</span>
                  </div>
                </div>
              </div>

              <div>
                <label className="text-sm font-medium text-bluish-grey">Description</label>
                <Input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full border-grey-goose focus:ring-hazel-green focus:border-hazel-green"
                />
              </div>

              <Button 
                type="submit" 
                className="w-full bg-hazel-green hover:bg-sage text-white transition-colors duration-300"
              >
                Add Transaction
              </Button>
              {error && <p className="text-error text-sm mt-2">{error}</p>}
            </form>
          </CardContent>
        </Card>

        {/* Transactions Table */}
        <Card className="bg-white/90 backdrop-blur">
          <CardHeader className="pb-0">
            <div className="flex justify-between items-center">
              <CardTitle className="text-hazel-green text-xl">Transaction History</CardTitle>
              <div className="relative w-64">
                <Input
                  type="text"
                  placeholder="Search transactions..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-10 border-grey-goose focus:ring-hazel-green focus:border-hazel-green"
                />
                <SearchIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-grey-goose" />
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-grey-goose">
                    <th className="py-3 px-4 text-left text-sm font-medium text-bluish-grey">Date</th>
                    <th className="py-3 px-4 text-left text-sm font-medium text-bluish-grey">Type</th>
                    <th className="py-3 px-4 text-left text-sm font-medium text-bluish-grey">Amount</th>
                    <th className="py-3 px-4 text-left text-sm font-medium text-bluish-grey">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTransactions.map((transaction, index) => (
                    <tr 
                      key={index} 
                      className="border-b border-grey-goose/50 hover:bg-tealish-blue transition-colors duration-200"
                    >
                      <td className="py-3 px-4 text-sm text-bluish-grey">
                        {new Date(transaction.createdAt).toLocaleDateString()}
                      </td>
                      <td className="py-3 px-4">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          transaction.transactionType === 'Hyrje' 
                            ? 'bg-sage/10 text-sage' 
                            : 'bg-salmon-pink/10 text-salmon-pink'
                        }`}>
                          {transaction.transactionType}
                        </span>
                      </td>
                      <td className={`py-3 px-4 font-medium ${
                        transaction.transactionType === 'Hyrje' 
                          ? 'text-sage' 
                          : 'text-salmon-pink'
                      }`}>
                        {transaction.amount.toFixed(2)} €
                      </td>
                      <td className="py-3 px-4 text-sm text-bluish-grey">{transaction.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default WaiterCashLog;