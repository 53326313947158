import React, { useState, useRef, useEffect } from "react";
import Transition from "../utils/Transition";

function DropdownFilter({ align }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  //Clear  filtres on click clear button 

  const Checkrefs = {
    DirectorIndirect: useRef(null),
    RealTimeValue: useRef(null),
    Topcahnnels: useRef(null),
    SalesRefunds: useRef(null),
    LastOrder: useRef(null),
    TotalSpent: useRef(null),
  };

  const handleFilters = () => {
    Object.keys(Checkrefs).forEach((key) => {
      if (Checkrefs[key].current.checked) {
        Checkrefs[key].current.checked = false;
      }
    });
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    // <div className="relative inline-flex">
    //   <button
    //     ref={trigger}
    //     className="btn px-2.5 bg-white dark:bg-gray-800 border-gray-200 hover:border-gray-300 dark:border-gray-700/60 dark:hover:border-gray-600 text-gray-400 dark:text-gray-500"
    //     aria-haspopup="true"
    //     onClick={() => setDropdownOpen(!dropdownOpen)}
    //     aria-expanded={dropdownOpen}
    //   >
    //     <span className="sr-only">Filter</span>
    //     <wbr />
    //     <svg
    //       className="fill-current"
    //       width="16"
    //       height="16"
    //       viewBox="0 0 16 16"
    //     >
    //       <path d="M0 3a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1ZM3 8a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1ZM7 12a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Z" />
    //     </svg>
    //   </button>
    //   <Transition
    //     show={dropdownOpen}
    //     tag="div"
    //     className={`origin-top-right z-10 absolute top-full left-0 right-auto min-w-56 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700/60 pt-1.5 rounded-lg shadow-lg overflow-hidden mt-1 ${
    //       align === "right"
    //         ? "md:left-auto md:right-0"
    //         : "md:left-0 md:right-auto"
    //     }`}
    //     enter="transition ease-out duration-200 transform"
    //     enterStart="opacity-0 -translate-y-2"
    //     enterEnd="opacity-100 translate-y-0"
    //     leave="transition ease-out duration-200"
    //     leaveStart="opacity-100"
    //     leaveEnd="opacity-0"
    //   >
    //     <div ref={dropdown}>
    //       <div className="text-xs font-semibold text-gray-400 dark:text-gray-500 uppercase pt-1.5 pb-2 px-3">
    //         Filters
    //       </div>
    //       <ul className="mb-4">
    //         <li className="py-1 px-3">
    //           <label className="flex items-center">
    //             <input
    //               ref={Checkrefs.DirectorIndirect}
    //               type="checkbox"
    //               className="form-checkbox"
    //             />
    //             <span className="text-sm font-medium ml-2">
    //               Direct VS Indirect
    //             </span>
    //           </label>
    //         </li>
    //         <li className="py-1 px-3">
    //           <label className="flex items-center">
    //             <input
    //               ref={Checkrefs.RealTimeValue}
    //               type="checkbox"
    //               className="form-checkbox"
    //             />
    //             <span className="text-sm font-medium ml-2">
    //               Real Time Value
    //             </span>
    //           </label>
    //         </li>
    //         <li className="py-1 px-3">
    //           <label className="flex items-center">
    //             <input
    //               ref={Checkrefs.Topcahnnels}
    //               type="checkbox"
    //               className="form-checkbox"
    //             />
    //             <span className="text-sm font-medium ml-2">Top Channels</span>
    //           </label>
    //         </li>
    //         <li className="py-1 px-3">
    //           <label className="flex items-center">
    //             <input
    //               ref={Checkrefs.SalesRefunds}
    //               type="checkbox"
    //               className="form-checkbox"
    //             />
    //             <span className="text-sm font-medium ml-2">
    //               Sales VS Refunds
    //             </span>
    //           </label>
    //         </li>
    //         <li className="py-1 px-3">
    //           <label className="flex items-center">
    //             <input
    //               ref={Checkrefs.LastOrder}
    //               type="checkbox"
    //               className="form-checkbox"
    //             />
    //             <span className="text-sm font-medium ml-2">Last Order</span>
    //           </label>
    //         </li>
    //         <li className="py-1 px-3">
    //           <label className="flex items-center">
    //             <input
    //               ref={Checkrefs.TotalSpent}
    //               type="checkbox"
    //               className="form-checkbox"
    //             />
    //             <span className="text-sm font-medium ml-2">Total Spent</span>
    //           </label>
    //         </li>
    //       </ul>
    //       <div className="py-2 px-3 border-t border-gray-200 dark:border-gray-700/60 bg-gray-50 dark:bg-gray-700/20">
    //         <ul className="flex items-center justify-between">
    //           <li>
    //             <button
    //               onClick={handleFilters}
    //               className="btn-xs bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 text-red-500"
    //             >
    //               Clear
    //             </button>
    //           </li>
    //           <li>
    //             <button
    //               className="btn-xs bg-gray-900 text-gray-100 hover:bg-gray-800 dark:bg-gray-100 dark:text-gray-800 dark:hover:bg-white"
    //               onClick={() => setDropdownOpen(false)}
    //               onBlur={() => setDropdownOpen(false)}
    //             >
    //               Apply
    //             </button>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //   </Transition>
    // </div>


    <div className="relative inline-flex">
    <button
      ref={trigger}
      className="btn px-3 py-1.5 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 text-gray-600 dark:text-gray-400 hover:border-gray-300 dark:hover:border-gray-600 rounded-md flex items-center"
      aria-haspopup="true"
      onClick={() => setDropdownOpen(!dropdownOpen)}
      aria-expanded={dropdownOpen}
    >
      <span className="sr-only">Filter</span>
      <svg
        className="fill-current"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <path d="M0 3a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1ZM3 8a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1ZM7 12a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Z" />
      </svg>
    </button>
    <Transition
      show={dropdownOpen}
      tag="div"
      className={`origin-top-right z-10 absolute top-full ${align === "right" ? "right-0" : "left-0"} mt-2 min-w-[12rem] bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg overflow-hidden`}
      enter="transition ease-out duration-200 transform"
      enterStart="opacity-0 translate-y-2"
      enterEnd="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveStart="opacity-100"
      leaveEnd="opacity-0"
    >
      <div ref={dropdown}>
        <div className="text-xs font-semibold text-gray-500 dark:text-gray-400 uppercase py-2 px-4 bg-gray-100 dark:bg-gray-700">
          Filters
        </div>
        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
          <li className="py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600">
            <label className="flex items-center">
              <input
                ref={Checkrefs.DirectorIndirect}
                type="checkbox"
                className="form-checkbox"
              />
              <span className="text-sm font-medium ml-2">Direct VS Indirect</span>
            </label>
          </li>
          <li className="py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600">
            <label className="flex items-center">
              <input
                ref={Checkrefs.RealTimeValue}
                type="checkbox"
                className="form-checkbox"
              />
              <span className="text-sm font-medium ml-2">Real Time Value</span>
            </label>
          </li>
          <li className="py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600">
            <label className="flex items-center">
              <input
                ref={Checkrefs.Topcahnnels}
                type="checkbox"
                className="form-checkbox"
              />
              <span className="text-sm font-medium ml-2">Top Channels</span>
            </label>
          </li>
          <li className="py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600">
            <label className="flex items-center">
              <input
                ref={Checkrefs.SalesRefunds}
                type="checkbox"
                className="form-checkbox"
              />
              <span className="text-sm font-medium ml-2">Sales VS Refunds</span>
            </label>
          </li>
          <li className="py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600">
            <label className="flex items-center">
              <input
                ref={Checkrefs.LastOrder}
                type="checkbox"
                className="form-checkbox"
              />
              <span className="text-sm font-medium ml-2">Last Order</span>
            </label>
          </li>
          <li className="py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600">
            <label className="flex items-center">
              <input
                ref={Checkrefs.TotalSpent}
                type="checkbox"
                className="form-checkbox"
              />
              <span className="text-sm font-medium ml-2">Total Spent</span>
            </label>
          </li>
        </ul>
        <div className="py-2 px-4 border-t border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 flex justify-between">
          <button
            onClick={handleFilters}
            className="btn-xs bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 text-red-500 hover:border-gray-300 dark:hover:border-gray-600 rounded-md"
          >
            Clear
          </button>
          <button
            onClick={() => setDropdownOpen(false)}
            onBlur={() => setDropdownOpen(false)}
            className="btn-xs bg-gray-900 text-white dark:bg-gray-100 dark:text-gray-800 hover:bg-gray-800 dark:hover:bg-white rounded-md"
          >
            Apply
          </button>
        </div>
      </div>
    </Transition>
  </div>
  );
}

export default DropdownFilter;
