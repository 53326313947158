import React from 'react';

export default function AddPayment() {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">
          Payment Mode <span className="text-watermelon">*</span>
        </label>
        <select className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green">
          <option value="">Select</option>
          <option value="cash">Cash</option>
          <option value="card">Card</option>
          <option value="bank">Bank Transfer</option>
        </select>
      </div>

      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">
          Amount <span className="text-watermelon">*</span>
        </label>
        <input
          type="number"
          placeholder="Enter Amount"
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
        />
      </div>

      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">Reference No.</label>
        <input
          type="text"
          placeholder="Enter Reference ID"
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
        />
      </div>

      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">Notes (If Any)</label>
        <textarea
          rows={3}
          placeholder="Notes"
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
        ></textarea>
      </div>

      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">
          Accepted By <span className="text-watermelon">*</span>
        </label>
        <input
          type="text"
          value="Enis Gjocaj"
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
        />
      </div>

      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">Receipt</label>
        <input
          type="file"
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
        />
      </div>

      <div className="flex justify-end space-x-3 pt-2">
        <button className="px-4 py-1.5 text-sm bg-hazel-green text-white rounded-md hover:bg-sage transition-colors">
          Add Payment
        </button>
      </div>
    </div>
  );
}