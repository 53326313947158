// import React from 'react';
// import { Trash2 } from 'lucide-react';

// interface ItemRow {
//   id: string;
//   details: string;
//   quantity: number;
//   rate: number;
//   discount: number;
//   tax: string;
//   amount: number;
// }

// interface ItemTableProps {
//   items: ItemRow[];
//   onOpenModal: () => void;
//   onUpdateItem: (index: number, field: keyof ItemRow, value: any) => void;
//   onDeleteItem: (index: number) => void;
//   onAddRow: () => void;
// }

// export default function ItemTable({ 
//   items, 
//   onOpenModal, 
//   onUpdateItem, 
//   onDeleteItem,
//   onAddRow 
// }: ItemTableProps) {
//   return (
//     <div className="space-y-4">
//       <div className="border border-grey-goose rounded-lg overflow-hidden">
//         <table className="w-full">
//           <thead className="bg-gray-50">
//             <tr>
//               <th className="text-left p-4 text-sm font-medium text-bluish-grey">ITEM DETAILS</th>
//               <th className="text-right p-4 text-sm font-medium text-bluish-grey">QUANTITY</th>
//               <th className="text-right p-4 text-sm font-medium text-bluish-grey">RATE</th>
//               <th className="text-right p-4 text-sm font-medium text-bluish-grey">DISCOUNT</th>
//               <th className="text-right p-4 text-sm font-medium text-bluish-grey">TAX</th>
//               <th className="text-right p-4 text-sm font-medium text-bluish-grey">AMOUNT</th>
//               <th className="w-12"></th>
//             </tr>
//           </thead>
//           <tbody className="divide-y divide-grey-goose">
//             {items.map((item, index) => (
//               <tr key={item.id}>
//                 <td className="p-4">
//                   <input
//                     type="text"
//                     value={item.details}
//                     onClick={onOpenModal}
//                     readOnly
//                     placeholder="Click to select an item"
//                     className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage cursor-pointer"
//                   />
//                 </td>
//                 <td className="p-4">
//                   <input
//                     type="number"
//                     value={item.quantity}
//                     onChange={(e) => onUpdateItem(index, 'quantity', Number(e.target.value))}
//                     className="w-20 p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage text-right"
//                   />
//                 </td>
//                 <td className="p-4">
//                   <input
//                     type="number"
//                     value={item.rate}
//                     onChange={(e) => onUpdateItem(index, 'rate', Number(e.target.value))}
//                     className="w-24 p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage text-right"
//                   />
//                 </td>
//                 <td className="p-4">
//                   <input
//                     type="number"
//                     value={item.discount}
//                     onChange={(e) => onUpdateItem(index, 'discount', Number(e.target.value))}
//                     className="w-20 p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage text-right"
//                   />
//                 </td>
//                 <td className="p-4">
//                   <select
//                     value={item.tax}
//                     onChange={(e) => onUpdateItem(index, 'tax', e.target.value)}
//                     className="w-24 p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage"
//                   >
//                     <option value="">No Tax</option>
//                     <option value="VAT@20">VAT@20%</option>
//                   </select>
//                 </td>
//                 <td className="p-4 text-right text-sm text-bluish-grey font-medium">
//                   €{item.amount.toFixed(2)}
//                 </td>
//                 <td className="p-4">
//                   <button
//                     onClick={() => onDeleteItem(index)}
//                     className="text-red-500 hover:text-red-600 transition-colors"
//                   >
//                     <Trash2 size={16} />
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       <div className="flex gap-2">
//         <button
//           type="button"
//           onClick={onAddRow}
//           className="text-blue-500 hover:text-blue-600 text-sm px-4 py-2 bg-blue-50 rounded-md transition-colors"
//         >
//           + Add New Row
//         </button>
//       </div>
//     </div>
//   );
// }


import React from 'react';
import { Trash2 } from 'lucide-react';

interface ItemRow {
  id: string;
  details: string;
  quantity: number;
  rate: number;
  discount: number;
  tax: string;
  amount: number;
}

interface ItemTableProps {
  items: ItemRow[];
  onOpenModal: (index: number) => void;
  onUpdateItem: (index: number, field: keyof ItemRow, value: any) => void;
  onDeleteItem: (index: number) => void;
  onAddRow: () => void;
}

export default function ItemTable({ 
  items, 
  onOpenModal, 
  onUpdateItem, 
  onDeleteItem,
  onAddRow 
}: ItemTableProps) {
  return (
    <div className="space-y-4">
      <div className="border border-grey-goose rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[800px]">
            <thead className="bg-gray-50">
              <tr>
                <th className="text-left p-4 text-sm font-medium text-bluish-grey">ITEM DETAILS</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-24">QUANTITY</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-32">RATE</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-24">DISCOUNT</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-32">TAX</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-32">AMOUNT</th>
                <th className="w-16"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-grey-goose">
              {items.map((item, index) => (
                <tr key={item.id}>
                  <td className="p-4">
                    <input
                      type="text"
                      value={item.details}
                      onClick={() => onOpenModal(index)}
                      readOnly
                      placeholder="Click to select an item"
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage cursor-pointer"
                    />
                  </td>
                  <td className="p-4">
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => onUpdateItem(index, 'quantity', Number(e.target.value))}
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage text-right"
                    />
                  </td>
                  <td className="p-4">
                    <input
                      type="number"
                      value={item.rate}
                      onChange={(e) => onUpdateItem(index, 'rate', Number(e.target.value))}
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage text-right"
                    />
                  </td>
                  <td className="p-4">
                    <input
                      type="number"
                      value={item.discount}
                      onChange={(e) => onUpdateItem(index, 'discount', Number(e.target.value))}
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage text-right"
                    />
                  </td>
                  <td className="p-4">
                    <select
                      value={item.tax}
                      onChange={(e) => onUpdateItem(index, 'tax', e.target.value)}
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage"
                    >
                      <option value="">No Tax</option>
                      <option value="VAT@20">VAT@20%</option>
                    </select>
                  </td>
                  <td className="p-4 text-right text-sm text-bluish-grey font-medium">
                    €{item.amount.toFixed(2)}
                  </td>
                  <td className="p-4">
                    <button
                      onClick={() => onDeleteItem(index)}
                      className="text-red-500 hover:text-red-600 transition-colors"
                    >
                      <Trash2 size={16} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex gap-2">
        <button
          type="button"
          onClick={onAddRow}
          className="text-blue-500 hover:text-blue-600 text-sm px-4 py-2 bg-blue-50 rounded-md transition-colors"
        >
          + Add New Row
        </button>
      </div>
    </div>
  );
}