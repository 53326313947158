import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import DishSectionForm from './DishSectionForm';

function AddDishSection() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faUtensils}
            className='text-hazel-green mr-2 w-12'
          />
        }
        title='Add new dish section'
      />
      <DishSectionForm isEdit={false} />
    </div>
  );
}

export default AddDishSection;
