import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import {
  IconPlus,
  IconHistory,
  IconPencil,
  IconListNumbers,
  IconTrash
} from '@tabler/icons-react';
import RoomHistory from './RoomHistory';
import ConfirmMessage from '../../components/alerts/alert-message';
import Modal from '../../components/modal';
import Loading from '../../components/loader';
import PermissionCheck from '../../utils/PermissionCheck';
import { fetcher } from '../../api/api';
import {
  deleteRoomType as deleteRoomTypeApi,
  deleteRoom as deleteRoomApi,
  getAllRooms
} from '../../api/rooms';

function RoomsList() {
  const [activeRoomType, setActiveRoomType] = useState('');
  const [activeTab, setActiveTab] = useState('room-types');
  const [hoveredRoom, setHoveredRoom] = useState(null);
  const [showRoomHistory, setShowRoomHistory] = useState(false);
  const [showRoomTypeDeleteAlert, setShowRoomTypeDeleteAlert] = useState(false);
  const [deleteRoomTypeId, setShowDeleteRoomTypeId] = useState('');
  const [selectedRoomId, setSelectedRoomId] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const { data: roomTypes, error, isValidating, mutate } = useSWR('/api/room-types', fetcher);

  const { data: allRoomsData, error: allRoomsError, isValidating: isValidatingAllRooms } = useSWR(
    activeTab === 'all-rooms' ? `/api/rooms/all-rooms` : null,
    fetcher
  );

  useEffect(() => {
    if (roomTypes) {
      setActiveRoomType(roomTypes[0] || '');
    }
  }, [roomTypes]);

  const deleteRoomType = async () => {
    try {
      await deleteRoomTypeApi(deleteRoomTypeId);
      mutate();
      const updatedRoomTypes = roomTypes.filter(
        (item) => item.id !== deleteRoomTypeId
      );
      setActiveRoomType(updatedRoomTypes[0] || '');
      setShowRoomTypeDeleteAlert(false);
    } catch (err) {
      console.error('Failed to delete room type:', err);
    }
  };

  const deleteRoom = async () => {
    try {
      await deleteRoomApi(selectedRoomId);
      mutate();
      setShowAlert(false);
    } catch (err) {
      console.error('Failed to delete room:', err);
    }
  };

  if (error) return <div>Error loading data...</div>;

  if ((!roomTypes && !isValidating && activeTab === 'room-types') || (!allRoomsData && !isValidatingAllRooms && activeTab === 'all-rooms')) return <Loading />;

  return (
    <div className='p-4 sm:p-6 lg:p-10'>
      <div className='flex flex-col sm:flex-row justify-end gap-3 sm:gap-x-3 mb-6 sm:mb-8'>
        <PermissionCheck action='edit_rooms'>
          <Link
            to='/reservations/add-new-reservation'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5 w-full sm:w-auto text-sm sm:text-base'
          >
            <IconPlus className='mr-2 w-5 h-5' />
            New reservation
          </Link>
        </PermissionCheck>
        <PermissionCheck action='edit_room_types'>
          <Link
            to='/rooms/add-room-type'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5 w-full sm:w-auto text-sm sm:text-base'
          >
            <IconPlus className='mr-2 w-5 h-5' />
            New room type
          </Link>
        </PermissionCheck>
        <Link
          to='/rooms/reservation-list'
          className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5 w-full sm:w-auto text-sm sm:text-base'
        >
          <IconListNumbers className='mr-2 w-5 h-5' />
          Room Reservations List
        </Link>
      </div>
      <div className='border border-gray-200 rounded-xl shadow-lg px-2 sm:px-3 mt-5'>
        <div className='flex items-center justify-between w-full border-b border-gray-200 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300'>
          <div className='flex items-center gap-2 py-2'>
            {roomTypes?.map((tab) => (
              <div key={tab.id} className='flex-shrink-0'>
                <div
                  className={`flex items-center px-3 sm:px-5 py-2 sm:py-3.5 whitespace-nowrap ${
                    tab.id === activeRoomType.id && activeTab === 'room-types'
                      ? 'bg-seashell bg-opacity-30 rounded-md'
                      : ''
                  } cursor-pointer`}
                  onClick={() => {
                    setActiveRoomType(tab);
                    setActiveTab('room-types');
                  }}
                >
                  <p className='text-bluish-grey capitalize text-sm sm:text-base'>
                    {tab.title}
                  </p>
                </div>
              </div>
            ))}
            <div
              className={`flex items-center px-3 sm:px-5 py-2 sm:py-3.5 whitespace-nowrap flex-shrink-0 ${
                activeTab === 'all-rooms'
                  ? 'bg-seashell bg-opacity-30 rounded-md'
                  : ''
              } cursor-pointer`}
              onClick={() => setActiveTab('all-rooms')}
            >
              <p className='text-bluish-grey capitalize text-sm sm:text-base'>
                All Rooms
              </p>
            </div>
          </div>
        </div>
        <div className='flex justify-end items-center bg-white py-2 sm:py-2.5 px-3 sm:px-5 gap-x-3 sm:gap-x-5'>
          {activeTab === 'room-types' && (
            <>
              <PermissionCheck action='edit_room_types'>
                <Link to={`/rooms/edit-type/${activeRoomType.id}`}>
                  <IconPencil className='text-sage w-4 h-4 sm:w-5 sm:h-5' />
                </Link>
              </PermissionCheck>
              <PermissionCheck action='delete_room_types'>
                <IconTrash
                  className='text-sage cursor-pointer w-4 h-4 sm:w-5 sm:h-5'
                  onClick={() => {
                    setShowRoomTypeDeleteAlert(true);
                    setShowDeleteRoomTypeId(activeRoomType.id);
                  }}
                />
              </PermissionCheck>
            </>
          )}
        </div>
        {activeTab === 'room-types' ? (
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-3 my-4 px-2'>
            <PermissionCheck action='edit_rooms'>
              <Link
                to={`/rooms/add-new-room?${activeRoomType.id}`}
                className='flex justify-center items-center p-4 sm:p-5 bg-seashell rounded-md'
              >
                <IconPlus className='text-bluish-grey font-bold cursor-pointer w-5 h-5 sm:w-6 sm:h-6' />
              </Link>
            </PermissionCheck>
            {activeRoomType?.rooms?.length ? (
              activeRoomType.rooms.map((item) => (
                <div
                  key={item.id}
                  className={`flex justify-center items-center p-3 sm:p-5 rounded-md ${
                    item.status === 'VACANT' ? 'bg-seashell' : 'bg-dawn-pink'
                  }`}
                  onMouseEnter={() => setHoveredRoom(item.id)}
                  onMouseLeave={() => setHoveredRoom(null)}
                >
                  {hoveredRoom === item.id ? (
                    <div className='flex justify-center items-center bg-white py-2 px-3 sm:py-2.5 sm:px-5 gap-x-2 sm:gap-x-5 rounded'>
                      <PermissionCheck action='read_rooms'>
                        <IconHistory
                          className='text-bluish-grey cursor-pointer w-4 h-4 sm:w-4.5 sm:h-4.5'
                          onClick={() => {
                            setSelectedRoomId(item.id);
                            setShowRoomHistory(true);
                          }}
                        />
                      </PermissionCheck>
                      <PermissionCheck action='edit_rooms'>
                        <Link to={`/rooms/${item.id}`}>
                          <IconPencil className='text-bluish-grey w-4 h-4 sm:w-4.5 sm:h-4.5' />
                        </Link>
                      </PermissionCheck>
                      <PermissionCheck action='delete_rooms'>
                        <IconTrash
                          className='text-bluish-grey cursor-pointer w-4 h-4 sm:w-4.5 sm:h-4.5'
                          onClick={() => {
                            setSelectedRoomId(item.id);
                            setShowAlert(true);
                          }}
                        />
                      </PermissionCheck>
                    </div>
                  ) : (
                    <p className='text-bluish-grey text-lg sm:text-xl lg:text-2xl font-medium'>
                      {item.number}
                    </p>
                  )}
                </div>
              ))
            ) : (
              <p className='text-sage font-bold py-6 sm:py-10 px-4 sm:px-8 col-span-full text-center'>
                No rooms available for this room type!
              </p>
            )}
          </div>
        ) : (
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-3 my-4 px-2'>
            {allRoomsData?.length ? (
              allRoomsData.map((item) => (
                <div
                  key={item.id}
                  className={`flex justify-center items-center p-3 sm:p-5 rounded-md ${
                    item.status === 'VACANT' ? 'bg-seashell' : 'bg-dawn-pink'
                  }`}
                  onMouseEnter={() => setHoveredRoom(item.id)}
                  onMouseLeave={() => setHoveredRoom(null)}
                >
                  {hoveredRoom === item.id ? (
                    <div className='flex justify-center items-center bg-white py-2 px-3 sm:py-2.5 sm:px-5 gap-x-2 sm:gap-x-5 rounded'>
                      <PermissionCheck action='read_rooms'>
                        <IconHistory
                          className='text-bluish-grey cursor-pointer w-4 h-4 sm:w-4.5 sm:h-4.5'
                          onClick={() => {
                            setSelectedRoomId(item.id);
                            setShowRoomHistory(true);
                          }}
                        />
                      </PermissionCheck>
                      <PermissionCheck action='edit_rooms'>
                        <Link to={`/rooms/${item.id}`}>
                          <IconPencil className='text-bluish-grey w-4 h-4 sm:w-4.5 sm:h-4.5' />
                        </Link>
                      </PermissionCheck>
                      <PermissionCheck action='delete_rooms'>
                        <IconTrash
                          className='text-bluish-grey cursor-pointer w-4 h-4 sm:w-4.5 sm:h-4.5'
                          onClick={() => {
                            setSelectedRoomId(item.id);
                            setShowAlert(true);
                          }}
                        />
                      </PermissionCheck>
                    </div>
                  ) : (
                    <p className='text-bluish-grey text-lg sm:text-xl lg:text-2xl font-medium'>
                      {item.number}
                    </p>
                  )}
                </div>
              ))
            ) : (
              <p className='text-sage font-bold py-6 sm:py-10 px-4 sm:px-8 col-span-full text-center'>
                No rooms available!
              </p>
            )}
          </div>
        )}
      </div>
      {showRoomHistory && (
      <Modal
          title='Reservation History'
          onClose={() => setShowRoomHistory(false)} // Use the onClose prop here
          children={<RoomHistory roomId={selectedRoomId} />}
        />
      )}

      {showAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this?'
          onCancel={() => setShowAlert(!showAlert)}
          onConfirm={() => deleteRoom()}
        />
      )}
      {showRoomTypeDeleteAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this room type?'
          text='Once you delete this type, all the related rooms will be deleted'
          onCancel={() => setShowRoomTypeDeleteAlert(!showRoomTypeDeleteAlert)}
          onConfirm={() => deleteRoomType()}
        />
      )}
    </div>
  );
}

export default RoomsList;

