import { useState, useEffect } from 'react';
import { useLocalStorage } from './useLocalStorage.ts';
import { getCompany } from '../api/company';
import { toast } from 'react-hot-toast';

interface Location {
  id: string;
  name: string;
  address?: string;
}

export const useLocation = () => {
  const [selectedLocation, setSelectedLocation] = useLocalStorage<Location | null>('selectedLocation', null);
  const [locations, setLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true);
      try {
        const response = await getCompany();
        const companyLocations = response?.data?.message?.locations || [];
        if (!companyLocations.length) {
          console.warn('No locations found');
        }
        setLocations(Array.isArray(companyLocations) ? companyLocations : []);

        // If we have locations but no selected location, select the first one
        if (companyLocations.length > 0 && !selectedLocation) {
          setSelectedLocation(companyLocations[0]);
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
        toast.error('Failed to load locations');
      } finally {
        setLoading(false);
      }
    };

    fetchLocations();
  }, []);

  const selectLocation = (location: Location) => {
    setSelectedLocation(location);
  };

  return {
    selectedLocation,
    locations,
    selectLocation,
    loading
  };
};
