import React from 'react';
import { Calendar, Clock, AlertTriangle, FileText } from 'lucide-react';
import { format, differenceInDays } from 'date-fns';

interface BlockedRoomInfoProps {
  roomNumber: string;
  fromDate: string;
  toDate: string;
  reason: string;
  notes: string;
  status: string;
}

export default function BlockedRoomInfo({
  roomNumber,
  fromDate,
  toDate,
  reason,
  notes,
  status,
}: BlockedRoomInfoProps) {
  const duration = differenceInDays(new Date(toDate), new Date(fromDate));

  // Check if fromDate and toDate are valid dates
  const formattedFromDate = fromDate ? format(new Date(fromDate), 'PPP') : 'Invalid Date';
  const formattedToDate = toDate ? format(new Date(toDate), 'PPP') : 'Invalid Date';

  return (
    <div className="space-y-6">
      {/* Duration Summary */}
      <div className="grid grid-cols-3 gap-4">
        <div className="bg-gradient-to-br from-hazel-green to-sage p-4 rounded-lg text-white">
          <div className="flex items-center gap-2 mb-2">
            <Calendar size={20} />
            <h3 className="text-sm font-medium">Block Period</h3>
          </div>
          <p className="text-2xl font-bold">{duration} Days</p>
        </div>
        <div className="bg-gradient-to-br from-bluish-grey to-tealish-blue p-4 rounded-lg text-white">
          <div className="flex items-center gap-2 mb-2">
            <Clock size={20} />
            <h3 className="text-sm font-medium">Current Status</h3>
          </div>
          <p className="text-2xl font-bold">{status}</p>
        </div>
        <div className="bg-gradient-to-br from-watermelon to-watermelon/70 p-4 rounded-lg text-white">
          <div className="flex items-center gap-2 mb-2">
            <AlertTriangle size={20} />
            <h3 className="text-sm font-medium">Block Reason</h3>
          </div>
          <p className="text-2xl font-bold">{reason}</p>
        </div>
      </div>

      {/* Date Details */}
      <div className="bg-seashell rounded-lg p-4">
        <h3 className="text-lg font-semibold text-hazel-green mb-4">Block Period Details</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">From Date</label>
            <input
              type="text"
              value={formattedFromDate}
              className="w-full px-3 py-2 bg-white rounded-md border border-grey-goose"
              readOnly
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">To Date</label>
            <input
              type="text"
              value={formattedToDate}
              className="w-full px-3 py-2 bg-white rounded-md border border-grey-goose"
              readOnly
            />
          </div>
        </div>
      </div>

      {/* Notes Section */}
      <div className="bg-seashell rounded-lg p-4">
        <div className="flex items-center gap-2 mb-4">
          <FileText size={20} className="text-hazel-green" />
          <h3 className="text-lg font-semibold text-hazel-green">Block Notes</h3>
        </div>
        <div className="bg-white p-4 rounded-md border border-grey-goose">
          <p className="text-bluish-grey whitespace-pre-wrap">{notes}</p>
        </div>
      </div>
    </div>
  );
}