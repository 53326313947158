import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import { SubmitButton, CancelButton } from '../../components/buttons';

import { addStockItem, getStockItemById, updateStockItem } from '../../api/stock';
import { getCategories } from '../../api/stockCategories';

function StockItemForm({ isEdit, stockItem }) {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStockCategories();
  }, []);

  const getStockCategories = async () => {
    try {
      const response = await getCategories();
      setCategories(response.data);
      console.log(response.data); // Ensure this logs the correct data
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      return err;
    }
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-4 sm:px-7 py-5'>
      <Formik
        initialValues={{
          categoryId: isEdit ? stockItem.categoryId : '', // Use stockItem for edit
          name: isEdit ? stockItem.name : '',
          description: isEdit ? stockItem.description : '',
          quantity: isEdit ? stockItem.stockQuantity : 0,
          price: isEdit ? stockItem.costPerUnit ?? '' : '',
          reorderLevel: isEdit ? stockItem.reorderLevel || '' : '',
          unitOfMeasurement: isEdit ? stockItem.unitOfMeasurement : '',
          supplierName: isEdit ? stockItem.supplierName || '' : '',
          stockLocation: isEdit ? stockItem.stockLocation || '' : '',
          
        }}
        validationSchema={Yup.object().shape({
          categoryId: Yup.string().required('Category is required*'),
          name: Yup.string().required('Name is required*'),
          description: Yup.string(),
          quantity: Yup.number()
            .required('Quantity is required*')
            .min(0, 'Quantity must be a positive number'),
          price: Yup.number().required('Price is required*').min(0, 'Price must be a positive number'),
          unitOfMeasurement: Yup.string()
            .oneOf(['PIECE', 'KILOGRAM', 'LITER', 'METER', 'GRAM'], 'Invalid unit of measurement')
            .required('Unit of Measurement is required*'),
          reorderLevel: Yup.number()
            .nullable() // Allow null values
            .min(0, 'Reorder Level must be a positive number'),
          supplierName: Yup.string().nullable(),
          stockLocation: Yup.string().nullable(),

                })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {

            const stockItemData = {
              ...values,
              stockQuantity: values.quantity,
              costPerUnit: values.price, 
            };
            

            if (isEdit) {
              console.log("EDITING MODE")
              await updateStockItem(stockItem.id, stockItemData);
              navigate('/stock');

            } else {
              await addStockItem(stockItemData);
              console.log("CREATING MODE")
              navigate('/stock');
            }
            setSubmitting(false);
          } catch (err) {
            // Extract error message safely
            const errorMessage = err?.response?.data?.message || err.message || 'An error occurred';
            setErrors({
              submit: errorMessage, // Set a string value for submit error
            });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3'>
              <SelectInput
                value={values.categoryId}
                label='Category'
                options={categories.map(cat => ({ id: cat.id, title: cat.name }))}
                onChange={(e) => setFieldValue('categoryId', e.target.value)}
                onBlur={handleBlur}
                showError={errors.categoryId && touched.categoryId}
                errorMessage={errors.categoryId}
              />

              <CustomInput
                type='text'
                name='name'
                label='Name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.name && touched.name}
                errorMessage={errors.name}
              />
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3 mt-4'>
              <CustomInput
                type='text'
                name='description'
                label='Description'
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.description && touched.description}
                errorMessage={errors.description}
              />

              <CustomInput
                type='number'
                name='quantity'
                label='Quantity'
                value={values.quantity}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.quantity && touched.quantity}
                errorMessage={errors.quantity}
              />

              <CustomInput
                type='number'
                name='price'
                label='Price'
                value={values.price || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.price && touched.price}
                errorMessage={errors.price}
              />

              <CustomInput
                type='number'
                name='reorderLevel'
                label='Reorder Level'
                value={values.reorderLevel}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.reorderLevel && touched.reorderLevel}
                errorMessage={errors.reorderLevel}
              />

              <CustomInput
                type='text'
                name='supplierName'
                label='Supplier Name'
                value={values.supplierName}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.supplierName && touched.supplierName}
                errorMessage={errors.supplierName}
              />

              <CustomInput
                type='text'
                name='stockLocation'
                label='Stock Location'
                value={values.stockLocation}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.stockLocation && touched.stockLocation}
                errorMessage={errors.stockLocation}
              />

              <SelectInput
                value={values.unitOfMeasurement}
                label="Unit of Measurement"
                options={[
                  { id: 'PIECE', title: 'Piece' },
                  { id: 'KILOGRAM', title: 'Kilogram' },
                  { id: 'LITER', title: 'Liter' },
                  { id: 'METER', title: 'Meter' },
                  { id: 'GRAM', title: 'Gram' },
                ]}
                onChange={(e) => setFieldValue('unitOfMeasurement', e.target.value)}
                onBlur={handleBlur}
                showError={errors.unitOfMeasurement && touched.unitOfMeasurement}
                errorMessage={errors.unitOfMeasurement}
              />
            </div>

            {errors.submit && (
              <p className='text-error mt-3'>{errors.submit}</p>
            )}

            <div className='flex flex-col sm:flex-row justify-end items-center w-full mt-7 gap-3 sm:gap-4'>
              <CancelButton 
                onCancel={() => navigate('/stock')} 
                className="w-full sm:w-[120px]"
              />
              <SubmitButton
                isSubmitting={isSubmitting}
                width="w-full sm:w-[120px]"
                title='Save'
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default StockItemForm;
