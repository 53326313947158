import React from 'react';

const BarCard = ({ name, imageUrl, description = "", price = 0 }) => {
  const truncateDescription = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div className="cursor-pointer block max-w-[12rem] rounded-lg bg-white text-surface shadow-secondary-1 dark:bg-surface-dark dark:text-white">
      <div className="relative overflow-hidden bg-cover bg-no-repeat">
        <img
          className="rounded-t-lg w-full h-32 object-cover"
          src={`http://localhost:3001${imageUrl}`}
          alt={name}
        />
      </div>
      <div className="p-2">
        <h3 className="text-sm font-semibold">{name}</h3>
        <p className="text-xs">{truncateDescription(description, 30)}</p>
        <p className="text-xs font-bold mt-2">${price.toFixed(2)}</p>
      </div>
    </div>
  );
};

export default BarCard;
