import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import ConferenceRoomMenuForm from './ConferenceRoomMenuForm';

function ConferenceRoomMenuFormContainer() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon icon={faUtensils} className='text-hazel-green mr-2'/>
        }
        title='Create Conference Room Menu'
      />
      <ConferenceRoomMenuForm />
    </div>
  );
}

export default ConferenceRoomMenuFormContainer;