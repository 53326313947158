import React from 'react';
import { IconCalendarMonth } from '@tabler/icons-react';
import PageTitle from '../../../../components/page-title';
import ReservationForm from './ReservationForm';
import { useLocation } from 'react-router-dom';

import { useParams } from 'react-router-dom';

function AddReservation() {

  const { roomNumber } = useParams();

  const location = useLocation();
  const { roomType, room } = location.state || {};

  console.log('Room NUM:', roomNumber); // Debug
  console.log(roomType);

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconCalendarMonth className='text-hazel-green mr-2' size={22} />}
        title='Add new reservation'
      />
      {/* <ReservationForm /> */}
      <ReservationForm isEdit={false} roomType={roomType} room={room} roomNumber={roomNumber} />
 
    </div>
  );
}

export default AddReservation;

