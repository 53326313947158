import React from 'react';
import { format } from 'date-fns';
import { X, Package, Calendar, User, MapPin, FileText, DollarSign } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface SaleDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  sale: any;
}

const SaleDetailsModal: React.FC<SaleDetailsModalProps> = ({ isOpen, onClose, sale }) => {
  if (!sale) return null;

  const DetailCard = ({ icon: Icon, label, value }: { icon: any, label: string, value: any }) => (
    <div className="flex items-center p-4 bg-seashell rounded-xl hover:bg-tealish-blue transition-colors duration-200">
      <Icon className="w-5 h-5 text-hazel-green mr-3" />
      <div>
        <p className="text-xs font-medium text-bluish-grey mb-1">{label}</p>
        <p className="text-sm font-semibold text-hazel-green">{value}</p>
      </div>
    </div>
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black/30 backdrop-blur-sm">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="relative w-full max-w-5xl p-1 mx-4 bg-gradient-to-br from-sage/20 to-hazel-green/20 rounded-2xl shadow-xl"
          >
            <div className="bg-white rounded-xl overflow-hidden">
              {/* Header */}
              <div className="relative px-6 py-4 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/30">
                <h2 className="text-2xl font-semibold text-hazel-green">Sale Details</h2>
                <button
                  onClick={onClose}
                  className="absolute right-4 top-4 p-2 rounded-full hover:bg-seashell transition-colors"
                >
                  <X className="w-6 h-6 text-bluish-grey" />
                </button>
              </div>

              {/* Content */}
              <div className="p-6">
                {/* Basic Info Grid */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                  <DetailCard
                    icon={FileText}
                    label="Document Number"
                    value={sale.documentNumber}
                  />
                  <DetailCard
                    icon={Calendar}
                    label="Issue Date"
                    value={format(new Date(sale.issueDate), 'PPP')}
                  />
                  <DetailCard
                    icon={Calendar}
                    label="Due Date"
                    value={format(new Date(sale.dueDate), 'PPP')}
                  />
                  <DetailCard
                    icon={User}
                    label="Client"
                    value={sale.client?.name || 'N/A'}
                  />
                  <DetailCard
                    icon={MapPin}
                    label="Location"
                    value={sale.location?.name || 'N/A'}
                  />
                  <DetailCard
                    icon={Package}
                    label="Type"
                    value={<span className="capitalize">{sale.type.toLowerCase()}</span>}
                  />
                </div>

                {/* Items Table */}
                <div className="mb-8">
                  <h3 className="text-lg font-semibold text-hazel-green mb-4">Items</h3>
                  <div className="overflow-x-auto rounded-xl border border-grey-goose/30">
                    <table className="min-w-full divide-y divide-grey-goose/30">
                      <thead>
                        <tr className="bg-seashell">
                          <th className="px-6 py-3 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Item</th>
                          <th className="px-6 py-3 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Quantity</th>
                          <th className="px-6 py-3 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Price (excl. VAT)</th>
                          <th className="px-6 py-3 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">VAT Rate</th>
                          <th className="px-6 py-3 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">VAT Amount</th>
                          <th className="px-6 py-3 text-left text-xs font-semibold text-bluish-grey uppercase tracking-wider">Total</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-grey-goose/30">
                        {sale.items?.map((item: any) => (
                          <tr key={item.id} className="hover:bg-seashell transition-colors duration-200">
                            <td className="px-6 py-4 text-sm text-bluish-grey">{item.article.name}</td>
                            <td className="px-6 py-4 text-sm text-bluish-grey">{item.quantity}</td>
                            <td className="px-6 py-4 text-sm text-bluish-grey">{item.priceWithoutVAT.toFixed(2)}</td>
                            <td className="px-6 py-4 text-sm text-bluish-grey">{(item.vatRate * 100).toFixed(0)}%</td>
                            <td className="px-6 py-4 text-sm text-bluish-grey">{item.vatAmount.toFixed(2)}</td>
                            <td className="px-6 py-4 text-sm font-medium text-hazel-green">{item.totalWithVAT.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Totals */}
                <div className="flex flex-col items-end gap-3 p-4 bg-seashell rounded-xl">
                  <div className="flex items-center gap-4 text-sm">
                    <span className="text-bluish-grey">Total (excl. VAT):</span>
                    <span className="font-medium text-hazel-green">{sale.totalWithoutVAT.toFixed(2)} €</span>
                  </div>
                  <div className="flex items-center gap-4 text-sm">
                    <span className="text-bluish-grey">VAT Total:</span>
                    <span className="font-medium text-hazel-green">{sale.totalVAT.toFixed(2)} €</span>
                  </div>
                  <div className="flex items-center gap-4 pt-2 border-t border-grey-goose/30">
                    <span className="text-base font-medium text-bluish-grey">Total (incl. VAT):</span>
                    <span className="text-lg font-semibold text-hazel-green">{sale.totalWithVAT.toFixed(2)} €</span>
                  </div>
                </div>

                {/* Returns Section */}
                {sale.returns && sale.returns.length > 0 && (
                  <div className="mt-8">
                    <h3 className="text-lg font-semibold text-hazel-green mb-4">Returns</h3>
                    <div className="space-y-4">
                      {sale.returns.map((returnItem: any) => (
                        <div key={returnItem.id} className="p-4 bg-seashell rounded-xl hover:bg-tealish-blue transition-colors duration-200">
                          <div className="flex justify-between mb-2">
                            <span className="font-medium text-hazel-green">{returnItem.documentNumber}</span>
                            <span className="text-bluish-grey">{format(new Date(returnItem.returnDate), 'PPP')}</span>
                          </div>
                          <div className="text-sm text-bluish-grey mb-3">{returnItem.reason || 'No reason provided'}</div>
                          <div className="flex justify-between items-center pt-2 border-t border-grey-goose/30">
                            <span className="text-sm text-bluish-grey">Return Total:</span>
                            <span className="font-medium text-hazel-green">{returnItem.totalWithVAT.toFixed(2)} €</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default SaleDetailsModal;
