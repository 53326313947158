import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowLeft, Plus, Settings, Upload, Save } from 'lucide-react';

import { getCompany } from '../../../api/company';
import { getSuppliers } from '../../../api/accounting';
import { getArticlesByType } from '../../../api/accountingArticle';
import { createPurchase, getNextPurchaseDocumentNumber } from '../../../api/purchase';
import { toast } from 'react-toastify';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '../../../components/shadCN/Select.tsx';
import { Button } from '../../../components/shadCN/Button.tsx';
import SupplierModal from '../../../components/SupplierModal';

interface Article {
  id: string;
  name: string;
  code: string;
  unit: {
    id: string;
    name: string;
  };
  vatRate: {
    id: string;
    percentage: number;
  };
}

interface Asset {
  id: number;
  articleId: string;
  article: Article;
  quantity: number;
  price: number;
  vatRate: number;
  amount: number;
  totalAmount: number;
  unit: string;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Supplier {
  id: string;
  name: string;
  fiscalNumber: string;
  address?: string;
  email?: string;
  phone?: string;
}

const AddAssetPage = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.user);
  const [documentNumber, setDocumentNumber] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [issueDate, setIssueDate] = useState(new Date().toISOString().split('T')[0]);
  const [expiryDate, setExpiryDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [assets, setAssets] = useState<Asset[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const [selectedArticle, setSelectedArticle] = useState('');

  const [locations, setLocations] = useState<Location[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddAsset = () => {
    const newAsset: Asset = {
      id: assets.length + 1,
      articleId: '',
      article: {} as Article,
      quantity: 0,
      price: 0,
      vatRate: 0,
      amount: 0,
      totalAmount: 0,
      unit: ''
    };
    setAssets([...assets, newAsset]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch document number first
        console.log('Fetching asset document number...');
        const docNumberRes = await getNextPurchaseDocumentNumber('ASSET');
        console.log('Document number response:', docNumberRes);
        
        const docNumber = docNumberRes?.data?.message?.documentNumber;
        console.log('Setting document number to:', docNumber);
        
        if (!docNumber) {
          console.error('No document number received:', docNumberRes);
          toast.error('Failed to get document number');
          return;
        }
        
        setDocumentNumber(docNumber);

        // Fetch suppliers, locations, and articles
        const [companyRes, suppliersRes, articlesRes] = await Promise.all([
          getCompany(),
          getSuppliers(),
          getArticlesByType('FIXED_ASSET')
        ]);
        
        // Handle locations
        console.log('Company response:', companyRes);
        const companyLocations = companyRes?.data?.message?.locations || [];
        if (!companyLocations.length) {
          console.warn('No locations found');
        }
        setLocations(Array.isArray(companyLocations) ? companyLocations : []);
        
        // Handle suppliers
        console.log('Raw suppliers response:', suppliersRes);
        const suppliersList = suppliersRes?.data || [];
        console.log('Suppliers loaded:', suppliersList);
        setSuppliers(suppliersList);
        
        // Handle articles
        console.log('Articles response:', articlesRes);
        const articlesList = articlesRes?.data?.message || [];
        if (!articlesList.length) {
          console.warn('No articles found');
        }
        setArticles(Array.isArray(articlesList) ? articlesList : []);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to load data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSupplierSelect = (supplierId: string) => {
    console.log('Selected supplier:', supplierId);
    setSelectedSupplier(supplierId);
  };

  const handleSupplierSuccess = async (newSupplier: any) => {
    setIsSupplierModalOpen(false);
    try {
      const suppliersRes = await getSuppliers();
      const suppliersList = suppliersRes?.data || [];
      console.log('Updated suppliers list:', suppliersList);
      setSuppliers(suppliersList);
      setSelectedSupplier(newSupplier.id);
      toast.success('Furnitori u shtua me sukses');
    } catch (error) {
      console.error('Error refreshing suppliers:', error);
      toast.error('Failed to refresh suppliers list');
    }
  };

  const handleArticleSelect = (assetId: number, articleId: string) => {
    const article = articles.find(a => a.id === articleId);
    if (article) {
      setAssets(assets.map(asset => {
        if (asset.id === assetId) {
          return {
            ...asset,
            articleId,
            article,
            vatRate: article.vatRate.percentage,
            unit: article.unit.name
          };
        }
        return asset;
      }));
    }
  };

  const calculateAmounts = (asset: Asset) => {
    const amount = asset.quantity * asset.price;
    const vatAmount = amount * (asset.vatRate / 100);
    const totalAmount = amount + vatAmount;

    return {
      ...asset,
      amount,
      totalAmount
    };
  };

  const handleQuantityChange = (assetId: number, quantity: number) => {
    setAssets(assets.map(asset => {
      if (asset.id === assetId) {
        return calculateAmounts({
          ...asset,
          quantity
        });
      }
      return asset;
    }));
  };

  const handlePriceChange = (assetId: number, price: number) => {
    setAssets(assets.map(asset => {
      if (asset.id === assetId) {
        return calculateAmounts({
          ...asset,
          price
        });
      }
      return asset;
    }));
  };

  const calculateTotals = () => {
    return assets.reduce((acc, asset) => {
      const amount = asset.quantity * asset.price;
      const vatAmount = amount * (asset.vatRate / 100);
      const totalAmount = amount + vatAmount;

      return {
        totalWithoutVAT: acc.totalWithoutVAT + amount,
        totalVAT: acc.totalVAT + vatAmount,
        totalWithVAT: acc.totalWithVAT + totalAmount
      };
    }, {
      totalWithoutVAT: 0,
      totalVAT: 0,
      totalWithVAT: 0
    });
  };

  const totals = calculateTotals();

  const handleSubmit = async () => {
    if (!selectedSupplier) {
      toast.error('Ju lutem zgjidhni furnitorin');
      return;
    }

    if (assets.length === 0) {
      toast.error('Ju lutem shtoni të paktën një aset');
      return;
    }

    if (assets.some(asset => !asset.articleId)) {
      toast.error('Ju lutem zgjidhni artikullin për të gjitha asetet');
      return;
    }

    try {
      setIsSubmitting(true);
      const totals = calculateTotals();

      const purchaseData = {
        type: 'ASSET',
        documentNumber,
        invoiceNumber,
        issueDate: new Date(issueDate).toISOString(),
        dueDate: new Date(expiryDate).toISOString(),
        supplierId: selectedSupplier,
        locationId: selectedLocation || undefined,
        description: '',
        items: assets.map(asset => {
          const quantity = Number(asset.quantity);
          const price = Number(asset.price);
          const amount = quantity * price;
          const vatAmount = amount * (asset.vatRate / 100);
          
          return {
            articleId: asset.articleId,
            quantity: quantity,
            priceWithoutVAT: price,
            vatRate: asset.vatRate,
            vatAmount: Number(vatAmount.toFixed(2)),
            totalWithVAT: Number((amount + vatAmount).toFixed(2))
          };
        }),
        totalWithoutVAT: Number(totals.totalWithoutVAT.toFixed(2)),
        totalVAT: Number(totals.totalVAT.toFixed(2)),
        totalWithVAT: Number(totals.totalWithVAT.toFixed(2)),
        createdBy: user.id,
        status: 'DRAFT'
      };

      const response = await createPurchase(purchaseData);
      
      if (response?.data) {
        toast.success('Aseti u krijua me sukses');
        navigate('/accounting/purchases');
      } else {
        toast.error('Dështoi krijimi i asetit: No response data');
      }
    } catch (error) {
      console.error('Error creating asset purchase:', error);
      toast.error(error.message || 'Dështoi krijimi i asetit');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <button
                  onClick={() => navigate('/accounting/purchases')}
                  className="p-2 text-bluish-grey hover:bg-seashell rounded-xl transition-colors"
                >
                  <ArrowLeft className="w-5 h-5" />
                </button>
                <h1 className="text-lg font-semibold text-hazel-green">Shto Aset</h1>
              </div>
              <div className="flex items-center gap-x-4">
                <button 
                  onClick={handleSubmit}
                  className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
                >
                  <Save className="w-5 h-5 mr-2" />
                  Ruaj
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Document Details */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Detajet për Aset</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i Dokumentit
                  </label>
                  <input
                    type="text"
                    value={documentNumber}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                  <p className="mt-1 text-sm text-grey-goose">
                    Emri i dokumentit është automatikisht i gjeneruar në bazë të formatit.
                  </p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i faturës
                  </label>
                  <input
                    type="text"
                    value={invoiceNumber}
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                    placeholder="Numri i faturës së pranuar nga furnitori"
                  />
                  <p className="mt-1 text-sm text-grey-goose">
                    Numri i faturës së pranuar nga furnitori.
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-2">
                      Data e lëshimit
                    </label>
                    <input
                      type="date"
                      value={issueDate}
                      onChange={(e) => setIssueDate(e.target.value)}
                      className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-2">
                      Data e skadimit
                    </label>
                    <input
                      type="date"
                      value={expiryDate}
                      onChange={(e) => setExpiryDate(e.target.value)}
                      className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                    />
                  </div>
                </div>
                {/* Supplier Select */}
                <div className="relative mb-4">
                  <label htmlFor="supplier" className="mb-2 block text-sm font-medium text-slate-600">
                    Furnitori
                  </label>
                  <div className="flex gap-2 items-start">
                    <div className="flex-1">
                      <Select
                        value={selectedSupplier}
                        onValueChange={handleSupplierSelect}
                        disabled={isLoading}
                      >
                        <SelectTrigger 
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                          disabled={isLoading}
                        >
                          <SelectValue 
                            placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni furnitorin"}
                            className="text-bluish-grey"
                          >
                            {selectedSupplier && (
                              <span className="text-hazel-green">
                                {suppliers.find(s => s.id === selectedSupplier)?.businessName || suppliers.find(s => s.id === selectedSupplier)?.name}
                              </span>
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent 
                          className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                          sideOffset={5}
                          position="popper"
                          align="start"
                        >
                          <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                            <div className="flex items-center justify-between">
                              <span className="text-sm font-medium text-bluish-grey">Zgjidhni furnitorin</span>
                              <button
                                onClick={() => setIsSupplierModalOpen(true)}
                                className="inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-sage hover:text-hazel-green transition-colors"
                              >
                                <Plus className="w-4 h-4" />
                                Shto furnitor
                              </button>
                            </div>
                          </div>
                          <div className="max-h-[300px] overflow-y-auto py-2">
                            {suppliers.map((supplier) => (
                              <SelectItem
                                key={supplier.id}
                                value={supplier.id}
                                className="py-2 px-3 cursor-pointer hover:bg-seashell/50 transition-colors"
                              >
                                <div>
                                  <div className="font-medium text-bluish-grey">
                                    {supplier.businessName || supplier.name}
                                  </div>
                                  {supplier.fiscalNumber && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm3 1h6v4H7V5zm2 6H7v2h6v-2z" clipRule="evenodd" />
                                      </svg>
                                      <span>Nr. Fiskal: {supplier.fiscalNumber}</span>
                                    </div>
                                  )}
                                  {supplier.address && (
                                    <div className="flex items-center gap-1 text-grey-goose">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                      </svg>
                                      <span>{supplier.address}</span>
                                    </div>
                                  )}
                                </div>
                              </SelectItem>
                            ))}
                          </div>
                        </SelectContent>
                      </Select>
                    </div>
                    <button
                      type="button"
                      onClick={() => setIsSupplierModalOpen(true)}
                      className="inline-flex items-center justify-center p-2 text-sage hover:text-hazel-green bg-white border border-mercury/50 rounded-lg hover:border-sage transition-colors"
                    >
                      <Settings className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                {/* Location Select */}
                <div className="mb-4">
                  <label htmlFor="location" className="mb-2 block text-sm font-medium text-slate-600">
                    Lokacioni
                  </label>
                  <Select
                    value={selectedLocation}
                    onValueChange={setSelectedLocation}
                    disabled={isLoading}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                      disabled={isLoading}
                    >
                      <SelectValue 
                        placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni lokacionin"}
                        className="text-bluish-grey"
                      >
                        {selectedLocation && (
                          <span className="text-hazel-green">
                            {locations.find(l => l.id === selectedLocation)?.name}
                          </span>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                      sideOffset={5}
                      position="popper"
                      align="start"
                    >
                      {locations.map(location => (
                        <SelectItem
                          key={location.id}
                          value={location.id}
                          textValue={location.name}
                          className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm sm:text-base font-medium text-hazel-green">
                              {location.name}
                            </span>
                            {location.address && (
                              <span className="text-xs sm:text-sm text-bluish-grey">
                                {location.address}
                              </span>
                            )}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>

          {/* Assets */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Asetet</h2>
              <p className="text-sm text-grey-goose">Asetet fikse për këtë blerje.</p>
            </div>
            <div className="p-6">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-mercury">
                  <thead>
                    <tr className="bg-gradient-to-r from-sage/5 to-transparent">
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Artikulli</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Njësi Matëse</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Sasia</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Çmimi për Njësi</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Norma e TVSH-së</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Shuma</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Shuma Totale</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Veprime</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-mercury/40">
                    {assets.length === 0 ? (
                      <tr>
                        <td colSpan={8} className="px-4 py-8 text-center text-sm text-bluish-grey">
                          Nuk ka të dhëna
                        </td>
                      </tr>
                    ) : (
                      assets.map((asset) => (
                        <tr key={asset.id}>
                          <td className="px-4 py-4">
                            <Select
                              value={asset.articleId}
                              onValueChange={(articleId) => handleArticleSelect(asset.id, articleId)}
                            >
                              <SelectTrigger 
                                className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury px-3 py-2 text-sm"
                              >
                                <SelectValue 
                                  placeholder="Zgjidhni artikullin"
                                  className="text-bluish-grey"
                                >
                                  {asset.articleId && (
                                    <span className="text-hazel-green">
                                      {asset.article.name}
                                    </span>
                                  )}
                                </SelectValue>
                              </SelectTrigger>
                              <SelectContent 
                                className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                                sideOffset={5}
                                position="popper"
                                align="start"
                              >
                                {articles.map(article => (
                                  <SelectItem
                                    key={article.id}
                                    value={article.id}
                                    textValue={article.name}
                                    className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                                  >
                                    <div className="flex flex-col">
                                      <span className="text-sm sm:text-base font-medium text-hazel-green">
                                        {article.name}
                                      </span>
                                      <span className="text-xs sm:text-sm text-bluish-grey">
                                        {article.code}
                                      </span>
                                    </div>
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="text"
                              disabled
                              value={asset.unit}
                              className="w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              value={asset.quantity}
                              onChange={(e) => handleQuantityChange(asset.id, Number(e.target.value))}
                              className="w-full rounded-lg border border-mercury px-3 py-2 text-sm"
                              placeholder="0.00"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              value={asset.price}
                              onChange={(e) => handlePriceChange(asset.id, Number(e.target.value))}
                              className="w-full rounded-lg border border-mercury px-3 py-2 text-sm"
                              placeholder="0.00"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <select className="w-full rounded-lg border border-mercury px-3 py-2 text-sm">
                              <option value="18">18%</option>
                              <option value="8">8%</option>
                              <option value="0">0%</option>
                            </select>
                          </td>
                          {/* Amount Column */}
                          <td className="px-4 py-4">
                            <input
                              type="text"
                              value={(asset.quantity * asset.price).toFixed(2)}
                              disabled
                              className="w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          {/* VAT Amount Column */}
                          <td className="px-4 py-4">
                            <input
                              type="text"
                              value={(asset.quantity * asset.price * (asset.vatRate / 100)).toFixed(2)}
                              disabled
                              className="w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          {/* Total Amount Column */}
                          <td className="px-4 py-4">
                            <input
                              type="text"
                              value={(asset.quantity * asset.price * (1 + asset.vatRate / 100)).toFixed(2)}
                              disabled
                              className="w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm font-medium text-hazel-green"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <button 
                              onClick={() => {
                                setAssets(assets.filter(a => a.id !== asset.id));
                              }}
                              className="text-red-500 hover:text-red-700 transition-colors"
                            >
                              Fshij
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <div className="mt-4">
                <button
                  onClick={handleAddAsset}
                  className="inline-flex items-center gap-x-2 px-4 py-2 text-sm text-bluish-grey hover:text-hazel-green transition-colors"
                >
                  <Plus className="h-4 w-4" />
                  Shto aset të ri
                </button>
              </div>
            </div>
          </div>

          {/* Pricing Section */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden mt-6">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Detajet e Çmimit</h2>
            </div>
            <div className="p-6 grid grid-cols-1 sm:grid-cols-3 gap-6">
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-2">
                  Totali pa TVSH
                </label>
                <input
                  type="text"
                  value={totals.totalWithoutVAT.toFixed(2)}
                  disabled
                  className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-2">
                  TVSH
                </label>
                <input
                  type="text"
                  value={totals.totalVAT.toFixed(2)}
                  disabled
                  className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-2">
                  Totali me TVSH
                </label>
                <input
                  type="text"
                  value={totals.totalWithVAT.toFixed(2)}
                  disabled
                  className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm font-medium text-hazel-green"
                />
              </div>
            </div>
          </div>

          {/* Attachments */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Bashkëngjit dokument (attachment)</h2>
            </div>
            <div className="p-6">
              <div className="border-2 border-dashed border-mercury rounded-lg p-8">
                <div className="flex flex-col items-center justify-center gap-2">
                  <Upload className="h-8 w-8 text-grey-goose" />
                  <p className="text-sm text-bluish-grey">Mund vendosni dokument këtu (drag and drop)</p>
                  <p className="text-xs text-grey-goose">
                    Mund të ngarkoni dokument të vetëm, apo edhe grup dokumentesh.
                  </p>
                </div>
              </div>
              <p className="mt-2 text-xs text-grey-goose">
                Shto një dokument (attachment), psh. faturën orgjinaie te asetit, faturën e pranuar nga furnitori.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Supplier Modal */}
      <SupplierModal
        isOpen={isSupplierModalOpen}
        onClose={() => setIsSupplierModalOpen(false)}
        onSuccess={handleSupplierSuccess}
      />
    </div>
  );
};

export default AddAssetPage;
