import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { TablePagination } from '@mui/material';
import { getRoles, deleteRole } from '../../api/roles';
import { SearchFilter } from '../../components/table-components';
import ConfirmMessage from '../../components/alerts/alert-message';
import Loading from '../../components/loader';
import { AddButton } from '../../components/buttons';
import PermissionCheck from '../../utils/PermissionCheck';

function RolesList() {
  const [rolesList, setRolesList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [rowToDelete, setRowToDelete] = useState('');
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getRolesList();
  }, []);

  const getRolesList = useCallback(async () => {
    try {
      const response = await getRoles(rowsPerPage, currentPage + 1, searchText);
      setRolesList(response.data.roles);
      setTotalCount(response.data.count);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, searchText]);

  useEffect(() => {
    getRolesList();
  }, [getRolesList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  const handleChangePage = async (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleDeleteRow = async (id) => {
    setRowToDelete(id);
    setShowAlert(true);
  };

  const deleteRow = async () => {
    try {
      setLoading(true);
      await deleteRole(rowToDelete);
      setRolesList((prevState) => [
        ...prevState.filter((item) => item.id !== rowToDelete)
      ]);
      setTotalCount(totalCount - 1);
      setShowAlert(false);
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
      <AddButton entity='roles' link='/roles/add-role' title='New role' />
      <div className='border border-gray-200 rounded-xl shadow-lg py-10 px-8'>
        {rolesList.length > 0 ? (
          <>
            <SearchFilter
              searchItem={(searchText) => {
                setSearchText(searchText);
              }}
            />
            {loading && <Loading />}
            {rolesList.map((role) => (
              <div
                key={role.id}
                className='flex justify-between items-center py-1.5 border-b border-gray-200'
              >
                <p className='font-medium'>{role.title}</p>
                <div className='flex justify-center items-center gap-x-6'>
                  <PermissionCheck action='edit_roles'>
                    <Link to={`/roles/${role.id}`}>
                      <IconPencil className='text-sage' size={20} />
                    </Link>
                  </PermissionCheck>
                  <PermissionCheck action='delete_roles'>
                    <IconTrash
                      className='text-sage cursor-pointer'
                      size={20}
                      onClick={() => handleDeleteRow(role.id)}
                    />
                  </PermissionCheck>
                </div>
              </div>
            ))}
            <TablePagination
              labelRowsPerPage='Rows per page'
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <p className='text-sage font-bold'>There is no data to show!</p>
        )}
      </div>
      {showAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this?'
          onCancel={() => setShowAlert(!showAlert)}
          onConfirm={() => deleteRow()}
        />
      )}
    </div>
  );
}

export default RolesList;
