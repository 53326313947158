import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, Settings, Upload } from 'lucide-react';

import { getCompany } from '../../../api/company';
import { getSuppliers } from '../../../api/accounting';
import { getNextReturnDocumentNumber, getAssetForReturn, createAssetReturn } from '../../../api/purchase';
import { toast } from 'react-toastify';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '../../../components/shadCN/Select.tsx';
import { Button } from '../../../components/shadCN/Button.tsx';
import SupplierModal from '../../../components/SupplierModal';

interface Asset {
  id: number;
  name: string;
  unit: string;
  quantity: number;
  price: number;
  vatRate: number;
  amount: number;
  totalAmount: number;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Supplier {
  id: string;
  name: string;
  fiscalNumber: string;
  address?: string;
  email?: string;
  phone?: string;
}

const ReturnAssetPage = () => {
  const navigate = useNavigate();
  const [documentNumber] = useState('RET-AST-2025-000001');
  const [originalAssetDocument, setOriginalAssetDocument] = useState('');
  const [asset, setAsset] = useState(null);
  const [issueDate, setIssueDate] = useState(new Date().toISOString().split('T')[0]);
  const [expiryDate, setExpiryDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [returnItems, setReturnItems] = useState([]);
  const [description, setDescription] = useState('');
  const [isLoadingAsset, setIsLoadingAsset] = useState(false);
  const [totalWithoutVAT, setTotalWithoutVAT] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [totalWithVAT, setTotalWithVAT] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [locations, setLocations] = useState<Location[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);

  const calculateItemTotals = (item) => {
    const quantity = parseFloat(item.quantity) || 0;
    const priceWithoutVAT = parseFloat(item.priceWithoutVAT) || 0;
    const vatRate = parseFloat(item.vatRate) || 0;

    const itemTotalWithoutVAT = quantity * priceWithoutVAT;
    const itemVatAmount = itemTotalWithoutVAT * (vatRate / 100);
    const itemTotalWithVAT = itemTotalWithoutVAT + itemVatAmount;

    return {
      vatAmount: itemVatAmount,
      totalWithVAT: itemTotalWithVAT
    };
  };

  const calculateTotals = (items) => {
    let withoutVAT = 0;
    let vat = 0;
    let withVAT = 0;

    items.forEach(item => {
      const quantity = parseFloat(item.quantity) || 0;
      const priceWithoutVAT = parseFloat(item.priceWithoutVAT) || 0;
      const vatRate = parseFloat(item.vatRate) || 0;

      const itemTotal = quantity * priceWithoutVAT;
      const itemVAT = itemTotal * (vatRate / 100);

      withoutVAT += itemTotal;
      vat += itemVAT;
      withVAT += (itemTotal + itemVAT);
    });

    setTotalWithoutVAT(withoutVAT);
    setTotalVAT(vat);
    setTotalWithVAT(withVAT);
  };

  const handleQuantityChange = (itemId: string, newQuantity: number) => {
    const updatedItems = returnItems.map(item => {
      if (item.id === itemId) {
        const quantity = Math.min(Math.max(0, newQuantity), item.remainingQuantity);
        const { vatAmount, totalWithVAT } = calculateItemTotals({
          ...item,
          quantity
        });

        return {
          ...item,
          quantity,
          vatAmount,
          totalWithVAT
        };
      }
      return item;
    });

    setReturnItems(updatedItems);
    calculateTotals(updatedItems);
  };

  const handleVatRateChange = (itemId: string, newVatRate: number) => {
    const updatedItems = returnItems.map(item => {
      if (item.id === itemId) {
        const { vatAmount, totalWithVAT } = calculateItemTotals({
          ...item,
          vatRate: newVatRate
        });

        return {
          ...item,
          vatRate: newVatRate,
          vatAmount,
          totalWithVAT
        };
      }
      return item;
    });

    setReturnItems(updatedItems);
    calculateTotals(updatedItems);
  };

  const resetForm = () => {
    setAsset(null);
    setSelectedSupplier('');
    setSelectedLocation('');
    setDescription('');
    setReturnItems([]);
  };

  const handleAssetSearch = async () => {
    if (!originalAssetDocument) {
      toast.error('Please enter an asset document number');
      return;
    }

    try {
      setIsLoadingAsset(true);
      const response = await getAssetForReturn(originalAssetDocument);
      console.log('Asset response:', response);

      const assetData = response?.data?.message;

      if (assetData) {
        console.log('Asset data:', assetData);

        // Set the asset data
        setAsset(assetData);

        // Set supplier
        if (assetData.supplierId) {
          console.log('Setting supplier ID:', assetData.supplierId);
          setSelectedSupplier(assetData.supplierId);
        } else {
          console.warn('No supplier ID found in asset data');
          toast.warning('No supplier information found');
        }

        // Set location
        if (assetData.locationId) {
          console.log('Setting location ID:', assetData.locationId);
          setSelectedLocation(assetData.locationId);
        } else {
          console.warn('No location ID in asset data');
          toast.warning('No location information found');
        }

        // Set description
        setDescription(assetData.description || '');

        // Map items with proper calculations
        if (Array.isArray(assetData.items) && assetData.items.length > 0) {
          const initialReturnItems = assetData.items
            .filter(item => (item.remainingQuantity || 0) > 0)
            .map((item) => ({
              id: crypto.randomUUID(),
              quantity: 0,
              priceWithoutVAT: item.priceWithoutVAT || 0,
              vatRate: item.vatRate || 0,
              vatAmount: 0,
              totalWithVAT: 0,
              reason: '',
              purchaseItemId: item.id,
              articleId: item.articleId,
              remainingQuantity: item.remainingQuantity || 0,
              articleName: item.article?.name || 'Unknown Article',
              unit: item.unit || { name: 'N/A' }
            }));

          if (initialReturnItems.length > 0) {
            setReturnItems(initialReturnItems);
            toast.success('Asset details loaded successfully');
          } else {
            toast.warning('No items available for return');
            setReturnItems([]);
          }
        } else {
          console.warn('No items found in asset data');
          toast.warning('No items found in asset');
          setReturnItems([]);
        }
      } else {
        console.error('Invalid response data:', response);
        toast.error('Invalid asset data received');
        resetForm();
      }
    } catch (error: any) {
      console.error('Error fetching asset:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch asset details';
      toast.error(errorMessage);
      resetForm();
    } finally {
      setIsLoadingAsset(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validation
    if (!selectedSupplier) {
      toast.error('Please select a supplier');
      return;
    }

    if (!issueDate) {
      toast.error('Please select an issue date');
      return;
    }

    if (!asset?.id) {
      toast.error('Please search for an asset first');
      return;
    }

    if (returnItems.length === 0) {
      toast.error('Please add at least one item to return');
      return;
    }

    // Check if all items have quantities
    const invalidItems = returnItems.filter(item => item.quantity <= 0);
    if (invalidItems.length > 0) {
      toast.error('All items must have a quantity greater than 0');
      return;
    }

    try {
      setIsSubmitting(true);

      // Prepare data for API
      const returnData = {
        assetId: asset.id,
        documentNumber,
        returnDate: new Date(issueDate),
        reason: description,
        items: returnItems.map(item => ({
          purchaseItemId: item.purchaseItemId,
          quantity: item.quantity,
          priceWithoutVAT: item.priceWithoutVAT,
          vatRate: item.vatRate
        })),
        createdBy: 'system' // Or get from user context if available
      };

      // Call API to create return
      const response = await createAssetReturn(returnData);
      
      if (response?.data?.statusCode === 200) {
        toast.success('Asset return created successfully');
        navigate('/administration/accounting/returns'); // Adjust path as needed
      } else {
        throw new Error('Failed to create asset return');
      }
    } catch (error: any) {
      console.error('Error creating asset return:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Failed to create asset return';
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [companyRes, suppliersRes] = await Promise.all([
          getCompany(),
          getSuppliers()
        ]);

        const companyLocations = companyRes?.data?.message?.locations || [];
        setLocations(Array.isArray(companyLocations) ? companyLocations : []);
        setSuppliers(Array.isArray(suppliersRes.data) ? suppliersRes.data : []);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to load locations and suppliers');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSupplierSelect = (supplierId: string) => {
    setSelectedSupplier(supplierId);
  };

  const handleSupplierSuccess = async (newSupplier: any) => {
    setIsSupplierModalOpen(false);
    try {
      const suppliersRes = await getSuppliers();
      setSuppliers(Array.isArray(suppliersRes.data) ? suppliersRes.data : []);
      setSelectedSupplier(newSupplier.id);
      toast.success('Furnitori u shtua me sukses');
    } catch (error) {
      console.error('Error refreshing suppliers:', error);
      toast.error('Failed to refresh suppliers list');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate(-1)}
                className="inline-flex items-center gap-x-2 px-4 py-2 text-sm text-bluish-grey hover:text-hazel-green transition-colors"
              >
                <ArrowLeft className="h-4 w-4" />
                Kthehu Prapa
              </button>
            </div>
            <h1 className="text-xl font-semibold text-hazel-green">
              Kthim i Asetit
            </h1>
            <div className="flex items-center gap-2">
              <button
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="inline-flex items-center gap-x-2 px-4 py-2 text-sm text-bluish-grey hover:text-hazel-green transition-colors"
              >
                {isSubmitting ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-sage/20 border-t-sage"></div>
                    Duke ruajtur...
                  </>
                ) : (
                  <>
                    <Settings className="h-4 w-4" />
                    Ruaj Kthimin
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Document Details */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Detajet për Kthim nga Aseti</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i Dokumentit
                  </label>
                  <input
                    type="text"
                    value={documentNumber}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                  <p className="mt-1 text-sm text-grey-goose">
                    Emri i dokumentit është automatikisht i gjeneruar në bazë të formatit.
                  </p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i dokumentit origjinal të asetit
                  </label>
                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={originalAssetDocument}
                      onChange={(e) => setOriginalAssetDocument(e.target.value)}
                      className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                      placeholder="Numri i dokumentit origjinal të asetit"
                    />
                    <Button
                      onClick={handleAssetSearch}
                      disabled={isLoadingAsset}
                      className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
                    >
                      {isLoadingAsset ? 'Duke kërkuar...' : 'Kërko'}
                    </Button>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-2">
                      Data e lëshimit
                    </label>
                    <input
                      type="date"
                      value={issueDate}
                      onChange={(e) => setIssueDate(e.target.value)}
                      className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-2">
                      Data e skadimit
                    </label>
                    <input
                      type="date"
                      value={expiryDate}
                      onChange={(e) => setExpiryDate(e.target.value)}
                      className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                    />
                  </div>
                </div>
                {/* Supplier Select */}
                <div className="relative mb-4">
                  <label htmlFor="supplier" className="mb-2 block text-sm font-medium text-slate-600">
                    Furnitori
                  </label>
                  <div className="flex gap-2 items-start">
                    <div className="flex-1">
                      <Select
                        value={selectedSupplier}
                        onValueChange={handleSupplierSelect}
                        disabled={isLoading}
                      >
                        <SelectTrigger
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                          disabled={isLoading}
                        >
                          <SelectValue
                            placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni furnitorin"}
                            className="text-bluish-grey"
                          >
                            {selectedSupplier && (
                              <span className="text-hazel-green">
                                {suppliers.find(s => s.id === selectedSupplier)?.businessName || suppliers.find(s => s.id === selectedSupplier)?.name}
                              </span>
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent
                          className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                          sideOffset={5}
                          position="popper"
                          align="start"
                        >
                          <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                            <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                              Zgjidhni Furnitorin
                            </h3>
                          </div>
                          {suppliers.map(supplier => (
                            <SelectItem
                              key={supplier.id}
                              value={supplier.id}
                              textValue={supplier.businessName || supplier.name}
                              className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                            >
                              {/* Hidden span for the select field display */}
                              <span className="hidden">
                                {supplier.businessName || supplier.name}
                              </span>

                              {/* Detailed content for dropdown */}
                              <div className="flex flex-col gap-1">
                                <div className="flex items-center justify-between">
                                  <div className="flex flex-col">
                                    <span className="text-sm sm:text-base font-medium text-hazel-green">
                                      {supplier.businessName || supplier.name}
                                    </span>
                                    {supplier.tradeName && supplier.tradeName !== supplier.businessName && (
                                      <span className="text-xs sm:text-sm text-bluish-grey">
                                        Trading as: {supplier.tradeName}
                                      </span>
                                    )}
                                  </div>
                                  {supplier.businessType && (
                                    <span className="px-2 py-0.5 rounded-full text-xs sm:text-sm font-medium bg-sage/10 text-sage">
                                      {supplier.businessType}
                                    </span>
                                  )}
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 text-xs sm:text-sm">
                                  {supplier.fiscalNumber && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm3 1h6v4H7V5zm8 8v2h1v-2h-1zm-2-6H7v4h6V7zm2 4h1v2h-1v-2z" clipRule="evenodd" />
                                      </svg>
                                      <span>Nr. Fiskal: {supplier.fiscalNumber}</span>
                                    </div>
                                  )}
                                  {supplier.address && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                      </svg>
                                      <span>{supplier.address}</span>
                                    </div>
                                  )}
                                  {supplier.phone && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                      </svg>
                                      <span>{supplier.phone}</span>
                                    </div>
                                  )}
                                  {supplier.email && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                      </svg>
                                      <span>{supplier.email}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <Button
                      type="button"
                      variant="outline"
                      size="icon"
                      onClick={() => setIsSupplierModalOpen(true)}
                      className="flex-shrink-0 h-10 w-10 rounded-lg hover:bg-gray-100"
                    >
                      <Plus className="h-5 w-5" />
                    </Button>
                  </div>
                </div>
                {/* Location Select */}
                <div className="mb-4">
                  <label htmlFor="location" className="mb-2 block text-sm font-medium text-slate-600">
                    Lokacioni
                  </label>
                  <Select
                    value={selectedLocation}
                    onValueChange={setSelectedLocation}
                    disabled={isLoading}
                  >
                    <SelectTrigger
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                      disabled={isLoading}
                    >
                      <SelectValue
                        placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni lokacionin"}
                        className="text-bluish-grey"
                      >
                        {selectedLocation && (
                          <span className="text-hazel-green">
                            {locations.find(l => l.id === selectedLocation)?.name}
                          </span>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                      sideOffset={5}
                      position="popper"
                      align="start"
                    >
                      {locations.map(location => (
                        <SelectItem
                          key={location.id}
                          value={location.id}
                          textValue={location.name}
                          className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm sm:text-base font-medium text-hazel-green">
                              {location.name}
                            </span>
                            {location.address && (
                              <span className="text-xs sm:text-sm text-bluish-grey">
                                {location.address}
                              </span>
                            )}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>

          {/* Assets */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Asetet</h2>
              <p className="text-sm text-grey-goose">Asetet për kthim.</p>
            </div>
            <div className="p-6">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-mercury">
                  <thead>
                    <tr className="bg-gradient-to-r from-sage/5 to-transparent">
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Artikulli</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Njësi Matëse</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Sasia</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Çmimi për Njësi</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Norma e TVSH-së</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Shuma</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Shuma Totale</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Veprime</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-mercury/40">
                    {returnItems.length === 0 ? (
                      <tr>
                        <td colSpan={8} className="px-4 py-8 text-center text-sm text-bluish-grey">
                          Nuk ka të dhëna
                        </td>
                      </tr>
                    ) : (
                      returnItems.map((item) => (
                        <tr key={item.id}>
                          <td className="px-4 py-4">
                            <input
                              type="text"
                              value={item.articleName}
                              disabled
                              className="block w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="text"
                              value={item.unit.name}
                              disabled
                              className="block w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              value={item.quantity}
                              onChange={(e) => handleQuantityChange(item.id, parseFloat(e.target.value))}
                              max={item.remainingQuantity}
                              min={0}
                              className="block w-full rounded-lg border border-mercury px-3 py-2 text-sm"
                            />
                            <div className="mt-1 text-xs text-grey-goose">
                              Sasia e mbetur: {item.remainingQuantity}
                            </div>
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              value={item.priceWithoutVAT}
                              disabled
                              className="block w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <select
                              value={item.vatRate}
                              onChange={(e) => handleVatRateChange(item.id, parseFloat(e.target.value))}
                              className="block w-full rounded-lg border border-mercury px-3 py-2 text-sm"
                            >
                              <option value="18">18%</option>
                              <option value="8">8%</option>
                              <option value="0">0%</option>
                            </select>
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              value={item.vatAmount}
                              disabled
                              className="block w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              value={item.totalWithVAT}
                              disabled
                              className="block w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <button
                              onClick={() => {
                                setReturnItems(returnItems.filter(i => i.id !== item.id));
                                calculateTotals(returnItems.filter(i => i.id !== item.id));
                              }}
                              className="text-red-500 hover:text-red-700 transition-colors"
                            >
                              Fshij
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Totals */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Totalet</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-3 gap-6">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Totali pa TVSH
                  </label>
                  <input
                    type="number"
                    value={totalWithoutVAT.toFixed(2)}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    TVSH
                  </label>
                  <input
                    type="number"
                    value={totalVAT.toFixed(2)}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Totali me TVSH
                  </label>
                  <input
                    type="number"
                    value={totalWithVAT.toFixed(2)}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Attachments */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Bashkëngjit dokument (attachment)</h2>
            </div>
            <div className="p-6">
              <div className="border-2 border-dashed border-mercury rounded-lg p-8">
                <div className="flex flex-col items-center justify-center gap-2">
                  <Upload className="h-8 w-8 text-grey-goose" />
                  <p className="text-sm text-bluish-grey">Mund vendosni dokument këtu (drag and drop)</p>
                  <p className="text-xs text-grey-goose">
                    Mund të ngarkoni dokument të vetëm, apo edhe grup dokumentesh.
                  </p>
                </div>
              </div>
              <p className="mt-2 text-xs text-grey-goose">
                Shto një dokument (attachment), psh. faturën orgjinaie te kthimit, faturën e pranuar nga furnitori.
              </p>
            </div>
          </div>
        </div>
      </div>

      <SupplierModal
        isOpen={isSupplierModalOpen}
        onClose={() => setIsSupplierModalOpen(false)}
        onSuccess={handleSupplierSuccess}
      />
    </div>
  );
};

export default ReturnAssetPage;
