import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IconBedFilled } from '@tabler/icons-react';
import RoomForm from './RoomForm';
import PageTitle from '../../components/page-title';
import { getRoomType as getRoomTypeApi } from '../../api/rooms';

function AddRoom() {
  const search = useLocation().search;
  const [roomType, setRoomType] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRoomType();
  }, []);

  const getRoomType = async () => {
    try {
      const response = await getRoomTypeApi(search?.replace('?', ''));
      setRoomType(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error('Error fetching room type:', err);
    }
  };

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconBedFilled className='text-hazel-green mr-2' size={22} />}
        title='Room'
      />
      <RoomForm
        room={{
          number: null,
          floor: 0,
          status: '',
          roomTypeId: roomType.id,
          minibar: []
        }}
        roomType={roomType}
        loading={loading}
        isEdit={false}
      />
    </div>
  );
}

export default AddRoom;
