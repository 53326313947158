import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Plus, Search, Filter, ChevronsUpDown, FileText, Calendar, DollarSign, User2, ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '../../../../components/shadCN/Button.tsx';
import { getAllInvoices } from '../../../../api/invoiceSale.js';
import { getClientById } from '../../../../api/accounting.js';
import Loading from '../../../../components/loader.js';
import { cn } from '../../../../utils/cn.ts';

const ITEMS_PER_PAGE = 5;

const statuses = [
  { label: 'All Invoices', value: 'all' },
  { label: 'Paid', value: 'paid' },
  { label: 'Pending', value: 'pending' },
  { label: 'Overdue', value: 'overdue' },
  { label: 'Draft', value: 'draft' },
];

export default function InvoicesListPage() {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clients, setClients] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(statuses[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchInvoices();
  }, [currentPage]);

  const fetchInvoices = async () => {
    try {
      setLoading(true);
      const serverPage = Math.ceil(currentPage / 2);
      const response = await getAllInvoices(serverPage, 10);
      
      const allFetchedInvoices = response.data.data;
      
      const startIndex = (currentPage % 2 === 0) ? 5 : 0;
      const endIndex = startIndex + 5;
      
      setInvoices(allFetchedInvoices.slice(startIndex, endIndex));
      setTotalItems(response.data.pagination.total);
      setTotalPages(Math.ceil(response.data.pagination.total / ITEMS_PER_PAGE));
    } catch (err) {
      setError(err.message);
      toast.error('Failed to fetch invoices');
    } finally {
      setLoading(false);
    }
  };

  const filteredInvoices = Array.isArray(invoices) ? invoices.filter((invoice) => {
    const matchesStatus =
      selectedStatus.value === 'all' ||
      invoice.status.toLowerCase() === selectedStatus.value;

    const matchesSearch =
      invoice.invoiceNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (invoice.client && invoice.client.name && 
       invoice.client.name.toLowerCase().includes(searchQuery.toLowerCase()));

    return matchesStatus && matchesSearch;
  }) : [];

  const currentInvoices = filteredInvoices;

  const handleCreateInvoice = () => {
    navigate('/accounting/sales/new-invoice');
  };

  const getPaginationRange = (currentPage: number, totalPages: number) => {
    const delta = 1;
    const range = [];
    const rangeWithDots = [];
    let l;

    range.push(1);

    if (totalPages <= 1) {
      return range;
    }

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i < totalPages && i > 1) {
        range.push(i);
      }
    }
    range.push(totalPages);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  const renderPagination = () => (
    <div className="flex flex-col sm:flex-row justify-between items-center mt-6 gap-4">
      <span className="text-sm text-bluish-grey">
        Showing {((currentPage - 1) * ITEMS_PER_PAGE) + 1} to {Math.min(currentPage * ITEMS_PER_PAGE, totalItems)} of {totalItems} invoices
      </span>
      <div className="flex items-center space-x-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="h-8 w-8 p-0 flex items-center justify-center"
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        
        {getPaginationRange(currentPage, totalPages).map((pageNum, idx) => (
          <React.Fragment key={idx}>
            {pageNum === '...' ? (
              <span className="text-bluish-grey px-2">...</span>
            ) : (
              <button
                onClick={() => setCurrentPage(Number(pageNum))}
                className={cn(
                  'h-8 min-w-[2rem] px-2 rounded-lg transition-all duration-200 text-sm font-medium',
                  currentPage === pageNum 
                    ? 'bg-hazel-green text-white shadow-sm' 
                    : 'bg-white text-hazel-green hover:bg-hazel-green/10 border border-mercury'
                )}
              >
                {pageNum}
              </button>
            )}
          </React.Fragment>
        ))}

        <Button
          variant="outline"
          size="sm"
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="h-8 w-8 p-0 flex items-center justify-center"
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );

  if (loading) return <Loading />;
  if (error) return <div>Error loading invoices: {error}</div>;

  return (
    <div className="min-h-screen bg-seashell p-6 lg:p-8">
      {/* Header Section with Stats */}
      <div className="mb-8">
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4 mb-6">
          <div>
            <h2 className="text-2xl font-bold text-hazel-green mb-2">Invoices Overview</h2>
            <p className="text-bluish-grey">Manage and track your financial documents</p>
          </div>
          <Button
            onClick={handleCreateInvoice}
            className="bg-hazel-green hover:bg-sage text-white shadow-sm transition-all duration-200 hover:shadow-md"
          >
            <Plus className="h-4 w-4 mr-2" />
            Create New Invoice
          </Button>
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          <div className="bg-white p-4 rounded-xl shadow-sm border border-mercury hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-3 bg-hazel-green/10 rounded-lg">
                <FileText className="h-5 w-5 text-hazel-green" />
              </div>
              <div>
                <p className="text-bluish-grey text-sm">Total Invoices</p>
                <p className="text-lg font-semibold text-hazel-green">{totalItems}</p>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 rounded-xl shadow-sm border border-mercury hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-3 bg-green-100 rounded-lg">
                <DollarSign className="h-5 w-5 text-green-600" />
              </div>
              <div>
                <p className="text-bluish-grey text-sm">Paid</p>
                <p className="text-lg font-semibold text-green-600">
                  {invoices.filter(inv => inv.status === 'paid').length}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 rounded-xl shadow-sm border border-mercury hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-3 bg-amber-100 rounded-lg">
                <Calendar className="h-5 w-5 text-amber-600" />
              </div>
              <div>
                <p className="text-bluish-grey text-sm">Pending</p>
                <p className="text-lg font-semibold text-amber-600">
                  {invoices.filter(inv => inv.status === 'pending').length}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 rounded-xl shadow-sm border border-mercury hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-3 bg-red-100 rounded-lg">
                <User2 className="h-5 w-5 text-red-600" />
              </div>
              <div>
                <p className="text-bluish-grey text-sm">Overdue</p>
                <p className="text-lg font-semibold text-red-600">
                  {invoices.filter(inv => inv.status === 'overdue').length}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Enhanced Filters Section */}
        <div className="bg-white rounded-xl shadow-sm border border-mercury p-4 mb-6">
          <div className="flex flex-col lg:flex-row items-stretch lg:items-center gap-4">
            {/* Enhanced Search Input */}
            <div className="relative flex-1">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-4 w-4 text-bluish-grey" />
              </div>
              <input
                type="text"
                placeholder="Search by invoice number or client..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2.5 border border-mercury rounded-lg text-sm 
                         focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green 
                         bg-white transition-all duration-200 placeholder-bluish-grey/60"
              />
            </div>

            {/* Enhanced Status Filter */}
            <div className="relative min-w-[200px]">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Filter className="h-4 w-4 text-bluish-grey" />
              </div>
              <select
                value={selectedStatus.value}
                onChange={(e) => setSelectedStatus(statuses.find(s => s.value === e.target.value))}
                className="w-full pl-10 pr-4 py-2.5 border border-mercury rounded-lg text-sm 
                         appearance-none bg-white cursor-pointer
                         focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                         transition-all duration-200"
              >
                {statuses.map((status) => (
                  <option key={status.value} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ChevronsUpDown className="h-4 w-4 text-bluish-grey" />
              </div>
            </div>

            {/* Optional: Add Clear Filters Button */}
            {(searchQuery || selectedStatus.value !== 'all') && (
              <Button
                variant="ghost"
                onClick={() => {
                  setSearchQuery('');
                  setSelectedStatus(statuses[0]);
                }}
                className="text-bluish-grey hover:text-hazel-green hover:bg-hazel-green/10
                         transition-all duration-200 whitespace-nowrap"
              >
                Clear Filters
              </Button>
            )}
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div className="bg-white rounded-xl shadow-sm border border-mercury overflow-hidden">
        <table className="w-full">
          <thead className="bg-seashell">
            <tr>
              <th className="px-6 py-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Invoice #</th>
              <th className="px-6 py-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Client</th>
              <th className="px-6 py-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Date</th>
              <th className="px-6 py-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Amount</th>
              <th className="px-6 py-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Status</th>
              <th className="px-6 py-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-mercury">
            {currentInvoices.map((invoice) => (
              <tr 
                key={invoice.id}
                className="hover:bg-seashell/50 transition-colors cursor-pointer group"
                onClick={() => navigate(`/accounting/invoices/${invoice.id}`)}
              >
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <FileText className="h-4 w-4 text-hazel-green mr-2 opacity-0 group-hover:opacity-100 transition-opacity" />
                    <span 
                      className="text-sm font-medium text-hazel-green cursor-pointer hover:underline"
                      onClick={() => navigate(`/accounting/invoices/${invoice.id}`)}
                    >
                      {invoice.invoiceNumber}
                    </span>                  
                    </div>
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <div className="w-8 h-8 rounded-full bg-dawn-pink text-salmon-pink flex items-center justify-center text-sm font-medium mr-2">
                      {(invoice.client && invoice.client.name) ? invoice.client.name[0].toUpperCase() : 'U'}
                    </div>
                    <span className="text-sm text-bluish-grey">{invoice.client && invoice.client.name ? invoice.client.name : 'Loading...'}</span>
                  </div>
                </td>
                <td className="px-6 py-4 text-sm text-bluish-grey">
                  {new Date(invoice.date).toLocaleDateString()}
                </td>
                <td className="px-6 py-4">
                  <span className="text-sm font-medium text-hazel-green">
                    €{invoice.totalAmount.toFixed(2)}
                  </span>
                </td>
                <td className="px-6 py-4">
                  <span className={cn(
                    "px-3 py-1.5 text-xs font-medium rounded-full inline-flex items-center",
                    {
                      'bg-green-100 text-green-800': invoice.status === 'paid',
                      'bg-amber-100 text-amber-800': invoice.status === 'pending',
                      'bg-red-100 text-red-800': invoice.status === 'overdue',
                    }
                  )}>
                    {invoice.status}
                  </span>
                </td>
                <td className="px-6 py-4">
                  <Button
                    variant="ghost"
                    className="text-bluish-grey hover:text-hazel-green hover:bg-hazel-green/10"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/accounting/invoices/${invoice.id}`);
                    }}
                  >
                    View Details
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {renderPagination()}
    </div>
  );
}