
import React, { useState, useEffect } from "react";
import { getAllDishes } from "../../api/restaurantInterface";
import DishCard from "./DishCard";
import { useDrag } from 'react-dnd';

const DraggableDishCard = ({ id, index, name, imageUrl, description, price }) => {
  const [, drag] = useDrag({
    type: "dish",
    item: { id, index, name, imageUrl, description, price },
  });

  return (
    <div ref={drag}>
      <DishCard
        name={name}
        imageUrl={imageUrl}
        description={description}
        price={price}
      />
    </div>
  );
};

const Restaurant = ({ onDrop }) => {
  const [dishes, setDishes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDishes = async () => {
      try {
        const response = await getAllDishes();
        console.log(response.data); // Log the fetched data for debugging
        setDishes(response.data || []);
      } catch (error) {
        console.error('Failed to fetch dishes:', error);
        setError('Failed to fetch dishes');
      } finally {
        setLoading(false);
      }
    };

    fetchDishes();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="flex flex-wrap gap-2">
      {dishes.map((dish, index) => (
        <DraggableDishCard
          key={dish.id}
          id={dish.id}
          index={index}
          name={dish.name}
          imageUrl={dish.imageUrl}
          description={dish.description}
          price={dish.price}
        />
      ))}
    </div>
  );
}

export default Restaurant;






