import React from 'react';
import { X } from 'lucide-react';
import { Input } from '../../shadCN/Input.tsx';
import { Textarea } from '../../shadCN/TextArea.tsx';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../shadCN/Select.tsx';
import { Button } from '../../shadCN/Button.tsx';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../../shadCN/Collapsible.tsx';
import { ChevronDown } from 'lucide-react';
import { tags } from '../../data/taskData.ts';

type TaskModalProps = {
  open: boolean;
  onClose: () => void;
};

export function TaskModal({ open, onClose }: TaskModalProps) {
  if (!open) return null; // Prevent rendering if not open

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-[600px] max-h-[90vh] overflow-auto">
        <div className="p-4 border-b border-grey-goose flex items-center justify-between">
          <h2 className="text-lg font-semibold text-bluish-grey">Add New Task</h2>
          <button onClick={onClose} className="text-bluish-grey hover:text-watermelon">
            <X size={20} />
          </button>
        </div>

        <div className="p-6">
          <form className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-bluish-grey mb-1">Title</label>
              <Input placeholder="Enter title of new task" className="w-full" />
            </div>

            <div>
              <label className="block text-sm font-medium text-bluish-grey mb-1">Description</label>
              <Textarea placeholder="Enter description" className="w-full" />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1">Deadline</label>
                <Input type="datetime-local" className="w-full" />
              </div>

              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1">Email Notification</label>
                <Select>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Choose an option..." />
                  </SelectTrigger>
                  <SelectContent className="bg-white">
                    <SelectItem value="none">None</SelectItem>
                    <SelectItem value="immediate">Immediate</SelectItem>
                    <SelectItem value="daily">Daily</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1">Tag</label>
                <Select>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Choose a tag..." />
                  </SelectTrigger>
                  <SelectContent className="bg-white">
                    {tags.map(tag => (
                      <SelectItem key={tag.id} value={tag.name}>
                        {tag.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1">Assign to User</label>
                <Select>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Choose an option..." />
                  </SelectTrigger>
                  <SelectContent className="bg-white">
                    <SelectItem value="alice">Alice Cleaner</SelectItem>
                    <SelectItem value="bob">Bob Technician</SelectItem>
                    <SelectItem value="emma">Emma Receptionist</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <Collapsible>
              <CollapsibleTrigger className="flex items-center gap-2 text-sage hover:text-hazel-green">
                <ChevronDown className="h-4 w-4" />
                Advanced Options
              </CollapsibleTrigger>
              <CollapsibleContent className="space-y-4 pt-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-1">Enquiry ID</label>
                    <Input placeholder="Enter enquiry id" className="w-full" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-1">Property</label>
                    <Select>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Choose a property..." />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        <SelectItem value="prop1">Property 1</SelectItem>
                        <SelectItem value="prop2">Property 2</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-1">Department</label>
                    <Select>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Choose a department..." />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        <SelectItem value="sales">Sales</SelectItem>
                        <SelectItem value="support">Support</SelectItem>
                        <SelectItem value="hr">HR</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-1">Case</label>
                    <Select>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Choose a case..." />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        <SelectItem value="case1">Case 1</SelectItem>
                        <SelectItem value="case2">Case 2</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </CollapsibleContent>
            </Collapsible>
          </form>
        </div>

        <div className="p-4 border-t border-grey-goose flex justify-end space-x-3">
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button className="bg-hazel-green hover:bg-sage text-white">Create Task</Button>
        </div>
      </div>
    </div>
  );
}