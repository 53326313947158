export const entities = [
  { id: 'users', title: 'Users' },
  { id: 'roles', title: 'Roles' },
  { id: 'room_types', title: 'Room Types' },
  { id: 'rooms', title: 'Rooms' },
  { id: 'invoices', title: 'Invoices' },
  { id: 'products', title: 'Products' },
  { id: 'indoor_pools', title: 'Indoor Pools' },
  { id: 'outdoor_pools', title: 'Outdoor Pools' },
  {id: 'waiter', title: "Waiter"},
  {id: 'depot_manager', title: "Depot Manager"}
];

export const AgeGroup = {
  INFANT: 'INFANT',
  CHILD: 'CHILD',
  ADULT: 'ADULT'
};

export const roomStatus = [
  { id: 'OCCUPIED', title: 'OCCUPIED' },
  { id: 'CLEAN', title: 'CLEAN' },
  { id: 'MAINTENANCE', title: 'MAINTENANCE' },
  { id: 'RESERVED', title: 'RESERVED' },
  { id: 'VACANT', title: 'VACANT' },
  { id: 'OUT_OF_ORDER', title: 'OUT OF ORDER' }
];

export const invoiceTypes = [
  { id: 'Purchase Invoice', title: 'Purchase Invoice' },
  { id: 'Payment Invoice', title: 'Payment Invoice' }
];

export const units = [
  { id: 'T', title: 'T' },
  { id: 'KG', title: 'KG' },
  { id: 'G', title: 'G' },
  { id: 'L', title: 'L' },
  { id: 'ML', title: 'ML' }
];

export const productTypes = [
  { id: 'DRINK', title: 'DRINK' },
  { id: 'FOOD', title: 'FOOD' },
  { id: 'OTHER', title: 'OTHER' }
];

export const paymentStatus = ['UNPAID', 'HALF PAID', 'PAID'];

export const paymentMethods = [
  { id: 'CASH', title: 'CASH' },
  { id: 'BANK', title: 'BANK' },
  { id: 'CARD', title: 'CARD' }
];

export const titleTypes = [
  { id: 'MR', title: 'MR' },
  { id: 'MRS', title: 'MRS' },
  { id: 'MISS', title: 'MISS' }
];

export const reservationStatus = [
  { id: 'CONFIRMED', title: 'CONFIRMED' },
  { id: 'CHECKIN', title: 'CHECKIN' },
  { id: 'CHECKOUT', title: 'CHECKOUT' },
  { id: 'CANCELLED', title: 'CANCELLED' }
];

export const dishTypes = [
  'Supat',
  'Mengjesi',
  'Parahaja e ngrohte',
  'Parahaja e ftohte',
  'Sallata',
  'Picat',
  'Pastat',
  'Rizoto',
  'Specialitete nga skara',
  'Specialitete nga furra',
  'Specialitete nga friteza',
  'Specialitete nga deti',
  'Embelsirat'
];

export const restaurants = ['Restaurant 1', 'Restaurant 2']

export const pools = {
  outdoor: {
    women: 10,
    men: 12,
    kids: 8
  },
  indoor: {
    women: 15,
    men: 18,
    kids: 12
  }
};
