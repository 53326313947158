import React from 'react';
import { Booking } from '../../../gobal.ts';
import { Search } from 'lucide-react';
interface BookingSearchProps {
  bookings: Booking[];
  onSelect: (booking: Booking) => void;
}

export default function BookingSearch({ bookings, onSelect }: BookingSearchProps) {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="mb-6">
        <label className="block text-bluish-grey text-sm font-medium mb-2">
          Select Property
        </label>
        <div className="relative">
          <select 
            className="w-full p-3 border border-mercury rounded-lg bg-seashell focus:outline-none focus:ring-2 focus:ring-hazel-green"
          >
            <option value="">Select a room...</option>
            {Array.from(new Set(bookings.map(b => b.roomNumber))).map(room => (
              <option key={room} value={room}>Room - {room}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-silver h-5 w-5" />
        <input
          type="text"
          placeholder="Search bookings..."
          className="w-full pl-10 pr-4 py-3 border border-mercury rounded-lg focus:outline-none focus:ring-2 focus:ring-hazel-green"
        />
      </div>

      <div className="mt-6">
        <table className="min-w-full divide-y divide-mercury">
          <thead className="bg-seashell">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                Enquiry ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                Guest Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                Guest Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                Guest Arrival
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                Guest Departure
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-mercury">
            {bookings.map((booking) => (
              <tr 
                key={booking.id}
                className="hover:bg-tealish-blue cursor-pointer transition-colors"
                onClick={() => onSelect(booking)}
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm text-hazel-green">{booking.id}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{booking.guestName}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{booking.guestEmail}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{booking.arrival}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{booking.departure}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}