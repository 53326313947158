

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../components/loader';
import { getAllRooms } from '../../../api/hotelInterface';
import { getRoomTypes } from '../../../api/hotelInterface';

import { useNavigate } from 'react-router-dom';

function Rooms() {
  const navigate = useNavigate();

  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [roomTypes, setRoomTypes] = useState([]);


  useEffect(() => {
    const fetchRoomTypes = async () => {
      try {
        const response = await getRoomTypes();
        setRoomTypes(response.data || []);
      } catch (error) {
        console.error('Failed to fetch room types:', error);
        setError('Failed to fetch room types');
      }
    };

    const fetchRooms = async () => {
      setLoading(true);
      try {
        const response = await getAllRooms();
        console.log(response.data);
        setRooms(response.data || []);
      } catch (error) {
        console.error('Failed to fetch rooms:', error);
        setError('Failed to fetch rooms');
      } finally {
        setLoading(false);
      }
    };

    fetchRoomTypes();
    fetchRooms();
  }, []);

  const handleRoomSelect = (roomNumber) => {
    navigate(`/reservation-form/${roomNumber}`);
  };
  
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (

    <div className="container mx-auto py-12">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-3xl font-bold mb-6">Our Rooms</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {rooms.length > 0 ? (
          rooms.map((room) => {
            const roomType = roomTypes.find(type => type.id === room.roomTypeId) || {};
            return (
              <div
                key={room.id}
                className="relative bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105"
              >
                <div className="relative">
                  <img
                    className="w-full h-48 object-cover"
                    src={`http://localhost:3001${roomType.imagePath}`} // Use a default image if `roomType.imagePath` is undefined
                    alt={roomType.title || 'Room Image'}
                  />
                  <small className="absolute bottom-4 left-4 bg-[#FEA116] text-white rounded py-1 px-3">
                    {roomType.price ? `$${roomType.price.toFixed(2)}` : 'N/A'}
                  </small>
                </div>
                <div className="p-4">
                  <div className="flex justify-between mb-3">
                    <h5 className="text-xl font-semibold">Room Number: {room.number}</h5>
                    <p className="text-gray-700">{room.status}</p>
                  </div>
                  <p className="text-gray-700 mb-3">Floor: {room.floor}</p>
                  <p className="text-gray-700 mb-3">Room Type: {roomType.title || 'No Title'}</p>
                  <p className="text-gray-500 mb-3">{roomType.description || 'No Description'}</p>
                  <div className="flex justify-between mt-4">
                    <Link
                      className="bg-[#FEA116] text-white rounded py-2 px-4 text-sm"
                      to={`/hotel-interface/rooms/${room.id}`}
                    >
                      View Details
                    </Link>
                    {/* <a
                      className="bg-gray-800 text-white rounded py-2 px-4 text-sm"
                      href={`/rooms/book/${room.id}`}
                    >
                      Book Now
                    </a> */}
                    <Link
                        className="bg-gray-800 text-white rounded py-2 px-4 text-sm"
                        to={`/hotel-interface/add-new-reservation/${room.id}`} 
                        state={{ roomType, room }}
                    >
                        Book Now
                    </Link>

                    {/* <Link
                        className="bg-gray-800 text-white rounded py-2 px-4 text-sm"
                        to={`/hotel-interface/add-new-reservation/${room.number}`}
                        state={{ roomType, room }}
                      >
                        Book Now
                      </Link> */}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-gray-700 font-bold py-10 px-8">
            There are no rooms available at the moment!
          </p>
        )}
      </div>
    </div>
  </div>
  );
}

export default Rooms;
