import React from 'react';
import PageTitle from '../../components/page-title';
import ReservationForm from './ReservationForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

function AddTableReservation() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={<FontAwesomeIcon icon={faCalendarAlt} className='text-hazel-green mr-2' />}
        title='Create a new reservation'
      />
      <ReservationForm isEdit={false} />
    </div>
  );
}

export default AddTableReservation;
