import React from 'react';
import { useLocation } from 'react-router-dom';
import { TableHead, TableRow, TableCell } from '@mui/material';
import PermissionCheck from '../../utils/PermissionCheck';

export default function TableHeadCustom({
  headCells,
  showMoreActions,
  actionsType,
  entity
}) {
  const location = useLocation();

  return (
    <TableHead
      className={`${location?.pathname.includes('inventory') && 'bg-mercury'} `}
    >
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            className='border-0'
            sx={{
              pl: 2,
              color: location?.pathname.includes('inventory') ? '#95A993' : ''
            }}
          >
            {headCell.title || ''}
          </TableCell>
        ))}
        {showMoreActions && (
          <>
            {actionsType === 'dots' ? (
              <TableCell>Actions</TableCell>
            ) : (
              <>
                <PermissionCheck action={`edit_${entity}`}>
                  <TableCell />
                </PermissionCheck>
                <PermissionCheck action={`delete_${entity}`}>
                  <TableCell />
                </PermissionCheck>
              </>
            )}
          </>
        )}
      </TableRow>
    </TableHead>
  );
}
