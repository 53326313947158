import React, { useState, useEffect } from 'react';
import { ArrowLeft, Plus, RotateCcw, Settings, Maximize2, Upload, Edit, Trash2, X } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { createOffer, getClientsForOffer, getNextOfferDocumentNumber, getOfferById, updateOffer } from '../../../../api/offer.js';
import toast from 'react-hot-toast';

import { getCompany } from '../../../../api/company.js';
import { getClients } from '../../../../api/accounting';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue, SelectGroup, SelectLabel } from '../../../../components/shadCN/Select.tsx';
import { Label } from '../../../../components/shadCN/Label.tsx';
import { Button } from '../../../../components/shadCN/Button.tsx';
import ClientModal from '../../../../components/ClientModal';
import { getArticles } from '../../../../api/accountingArticle';

interface Article {
  id: string;
  articleId?: string;
  name: string;
  unit: string;
  quantity: number;
  priceWithoutVAT: number;
  vatAmount: number;
  totalWithVAT: number;
  vatRate?: number;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Client {
  id: string;
  name: string;
  fiscalNumber: string;
  address?: string;
  email?: string;
  phone?: string;
}

interface AccountingArticle {
  id: string;
  code: string;
  name: string;
  description?: string;
  type: string;
  status: string;
  purchasePrice: number | null;
  sellingPrice: number | null;
  isWholeNumber: boolean;
  vatRate: {
    id: string;
    name: string;
    percentage: number;
  };
  unit: {
    id: string;
    name: string;
  };
}

export enum OfferStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED'
}

const getStatusStyle = (status: OfferStatus) => {
  switch (status) {
    case 'DRAFT':
      return 'text-bluish-grey bg-mercury/20';
    case 'ACTIVE':
      return 'text-hazel-green bg-seashell';
    case 'EXPIRED':
      return 'text-watermelon bg-dawn-pink';
    case 'REJECTED':
      return 'text-salmon-pink bg-red-25';
    default:
      return 'text-bluish-grey bg-mercury/20';
  }
};

const getStatusLabel = (status: OfferStatus) => {
  switch (status) {
    case 'DRAFT':
      return 'Draft';
    case 'ACTIVE':
      return 'Aktive';
    case 'EXPIRED':
      return 'Skaduar';
    case 'REJECTED':
      return 'Refuzuar';
    default:
      return status;
  }
};

const AddOfferPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEditing = Boolean(id);
  const [isLoading, setIsLoading] = useState(true);
  const [articles, setArticles] = useState<Article[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [description, setDescription] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [issueDate, setIssueDate] = useState(new Date().toISOString().split('T')[0]);
  const [expiryDate, setExpiryDate] = useState(new Date().toISOString().split('T')[0]);
  const [status, setStatus] = useState<OfferStatus>(OfferStatus.DRAFT);

  const [attachments, setAttachments] = useState<File[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [accountingArticles, setAccountingArticles] = useState<AccountingArticle[]>([]);
  const [isLoadingArticles, setIsLoadingArticles] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // First fetch all necessary data
        const [companyRes, clientsRes, articlesRes] = await Promise.all([
          getCompany(),
          getClientsForOffer(),
          getArticles()
        ]);

        // Set base data
        const companyLocations = companyRes?.data?.message?.locations || [];
        setLocations(companyLocations);
        setClients(clientsRes?.data || []);
        setAccountingArticles(articlesRes?.data?.message || []);

        // If editing, fetch and populate offer data
        if (isEditing && id) {
          const offerRes = await getOfferById(id);
          const offer = offerRes.data;

          // Set basic fields
          setDocumentNumber(offer.documentNumber);
          setDescription(offer.description || '');
          setIssueDate(new Date(offer.issueDate).toISOString().split('T')[0]);
          setExpiryDate(new Date(offer.expiryDate).toISOString().split('T')[0]);
          setStatus(offer.status as OfferStatus);

          // Set client
          if (offer.client) {
            setSelectedClient(offer.client.id);
          }

          // Set location
          if (offer.location) {
            setSelectedLocation(offer.location.id);
          }

          // Set articles
          if (offer.articles && Array.isArray(offer.articles)) {
            const mappedArticles = offer.articles.map(item => {
              const fullArticle = articlesRes?.data?.message?.find(
                a => a.id === item.article.id
              );

              return {
                id: crypto.randomUUID(),
                articleId: item.article.id,
                name: fullArticle?.name || item.article.name,
                unit: fullArticle?.unit?.name || item.article.unit.name,
                quantity: item.quantity,
                priceWithoutVAT: item.unitPrice,
                vatRate: item.vatRate,
                vatAmount: (item.unitPrice * item.quantity * (item.vatRate / 100)),
                totalWithVAT: item.totalAmount
              };
            });

            setArticles(mappedArticles);
          }
        } else {
          // For new offers
          const docNumberRes = await getNextOfferDocumentNumber();
          setDocumentNumber(docNumberRes?.data?.message?.documentNumber || '');
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        if (error instanceof Error) {
          toast.error(error.message);
        } else {
          toast.error('Failed to load data');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, isEditing]);

  const handleClientSelect = (clientId: string) => {
    setSelectedClient(clientId);
    const selectedClient = clients.find(client => client.id === clientId);
    console.log('Selected client:', selectedClient);
  };

  const handleClientSuccess = async (newClient: any) => {
    setIsClientModalOpen(false);
    // Refresh clients list
    try {
      const clientsRes = await getClients();
      setClients(Array.isArray(clientsRes.data) ? clientsRes.data : []);
      // Select the newly created client
      setSelectedClient(newClient.id);
      toast.success('Klienti u shtua me sukses');
    } catch (error) {
      console.error('Error refreshing clients:', error);
      toast.error('Failed to refresh clients list');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const offerData = {
        documentNumber,
        clientId: selectedClient,
        locationId: selectedLocation || undefined,
        description,
        issueDate: new Date(issueDate),
        expiryDate: new Date(expiryDate),
        status: status as OfferStatus,
        articles: articles.map(article => ({
          articleId: article.articleId,
          quantity: article.quantity,
          unitPrice: article.priceWithoutVAT,
          vatRate: article.vatRate || 18
        }))
      };

      if (isEditing && id) {
        await updateOffer(id, offerData);
        toast.success('Oferta u përditësua me sukses');
      } else {
        await createOffer(offerData);
        toast.success('Oferta u krijua me sukses');
      }

      navigate('/accounting/offers');
    } catch (error) {
      console.error('Error saving offer:', error);
      toast.error(isEditing ? 'Dështoi përditësimi i ofertës' : 'Dështoi krijimi i ofertës');
    } finally {
      setIsLoading(false);
    }
  };

  const handleArticleSelect = (articleId: string, selectedValue: string) => {
    const selectedArticle = accountingArticles.find(a => a.id === selectedValue);
    if (selectedArticle) {
      setArticles(prevArticles => prevArticles.map(article => {
        if (article.id === articleId) {
          const vatRate = selectedArticle.vatRate?.percentage || 0;
          const priceWithoutVAT = selectedArticle.sellingPrice || 0;
          const quantity = article.quantity || 1;
          const vatAmount = (priceWithoutVAT * vatRate / 100) * quantity;
          
          return {
            ...article,
            articleId: selectedArticle.id,
            name: selectedArticle.name,
            unit: selectedArticle.unit.name,
            priceWithoutVAT,
            vatAmount,
            vatRate,
            quantity,
            totalWithVAT: (priceWithoutVAT * quantity) + vatAmount
          };
        }
        return article;
      }));
    }
  };

  const handleArticleChange = (articleId: string, field: string, value: any) => {
    setArticles(articles.map(article => {
      if (article.id === articleId) {
        const updatedArticle = { ...article, [field]: value };
        
        // Recalculate totals
        const quantity = Number(updatedArticle.quantity) || 0;
        const priceWithoutVAT = Number(updatedArticle.priceWithoutVAT) || 0;
        const vatRate = Number(updatedArticle.vatRate) || 0;
        
        const vatAmount = Number((priceWithoutVAT * vatRate / 100).toFixed(2));
        const totalWithVAT = Number((priceWithoutVAT + vatAmount).toFixed(2));
        
        return {
          ...updatedArticle,
          quantity: Number(quantity),
          priceWithoutVAT: Number(priceWithoutVAT),
          vatRate: Number(vatRate),
          vatAmount,
          totalWithVAT
        };
      }
      return article;
    }));
  };

  const handleAddArticle = () => {
    const newArticle: Article = {
      id: crypto.randomUUID(),
      articleId: '',
      name: '',
      unit: '',
      quantity: 1,
      priceWithoutVAT: 0,
      vatAmount: 0,
      totalWithVAT: 0,
      vatRate: 18
    };
    setArticles(prev => [...prev, newArticle]);
  };

  const removeArticle = (id: string) => {
    setArticles(prev => prev.filter(article => article.id !== id));
  };

  const calculateTotals = () => {
    let totalWithoutVAT = 0;
    let totalVAT = 0;

    articles.forEach((article) => {
      const quantity = Number(article.quantity) || 0;
      const price = Number(article.priceWithoutVAT) || 0;
      const vatRate = Number(article.vatRate) || 0;

      const lineTotal = quantity * price;
      totalWithoutVAT += lineTotal;
      totalVAT += Number((lineTotal * vatRate / 100).toFixed(2));
    });

    return {
      totalWithoutVAT,
      totalVAT,
      totalWithVAT: Number((totalWithoutVAT + totalVAT).toFixed(2)),
    };
  };

  const calculateVatGroups = () => {
    const vatGroups: { [key: string]: number } = {};

    articles.forEach((article) => {
      const quantity = Number(article.quantity) || 0;
      const price = Number(article.priceWithoutVAT) || 0;
      const vatRate = Number(article.vatRate) || 0;

      const lineTotal = quantity * price;
      const vatAmount = Number((lineTotal * vatRate / 100).toFixed(2));

      if (vatRate in vatGroups) {
        vatGroups[vatRate] += vatAmount;
      } else {
        vatGroups[vatRate] = vatAmount;
      }
    });

    return vatGroups;
  };

  useEffect(() => {
    console.log('Current articles:', articles);
  }, [articles]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/accounting/offers')}
                className="inline-flex items-center gap-x-2 text-bluish-grey hover:text-hazel-green transition-colors"
              >
                <ArrowLeft className="h-5 w-5" />
                Kthehu
              </button>
            </div>
            <h1 className="text-lg font-semibold text-hazel-green">
              {isEditing ? 'Përditëso Ofertën' : 'Ofertë e Re'}
            </h1>
            <div className="flex items-center gap-x-4">
              <button
                onClick={handleSubmit}
                className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
              >
                Ruaj
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Document Details */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Detajet e Ofertës</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <Label>Numri i dokumentit</Label>
                  <input
                    type="text"
                    value={documentNumber}
                    readOnly
                    className="w-full h-10 px-3 py-2 text-sm bg-gray-50 border border-mercury rounded-lg text-bluish-grey"
                  />
                </div>
                {isEditing && (
                  <div>
                    <Label>Statusi</Label>
                    <Select
                      value={status}
                      onValueChange={(value) => setStatus(value as OfferStatus)}
                    >
                      <SelectTrigger className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors">
                        <SelectValue placeholder="Zgjidhni statusin">
                          {status && (
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(status as OfferStatus)}`}>
                              {getStatusLabel(status as OfferStatus)}
                            </span>
                          )}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent 
                        className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden min-w-[200px]"
                        position="popper"
                        sideOffset={5}
                      >
                        <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                          <h3 className="text-xs font-medium text-bluish-grey">
                            Zgjidhni Statusin
                          </h3>
                        </div>
                        <div className="p-1">
                          {Object.values(OfferStatus).map((statusValue) => (
                            <SelectItem
                              key={statusValue}
                              value={statusValue}
                              className="relative flex items-center px-3 py-2.5 rounded-md cursor-pointer hover:bg-sage/10 focus:bg-sage/10 transition-colors"
                            >
                              <div className="flex items-center justify-between w-full">
                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(statusValue as OfferStatus)}`}>
                                  {getStatusLabel(statusValue as OfferStatus)}
                                </span>
                                {status === statusValue && (
                                  <svg className="h-4 w-4 text-hazel-green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                  </svg>
                                )}
                              </div>
                            </SelectItem>
                          ))}
                        </div>
                      </SelectContent>
                    </Select>
                  </div>
                )}
                <div className="sm:col-span-2">
                  <Label>Përshkrimi</Label>
                  <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury rounded-lg text-bluish-grey focus:border-sage focus:ring-1 focus:ring-sage"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-2">
                      Data e lëshimit
                    </label>
                    <input
                      type="date"
                      value={issueDate}
                      onChange={(e) => setIssueDate(e.target.value)}
                      className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-2">
                      Data e skadimit
                    </label>
                    <input
                      type="date"
                      value={expiryDate}
                      onChange={(e) => setExpiryDate(e.target.value)}
                      className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                    />
                  </div>
                </div>
                <div className="relative mb-4">
                  <label htmlFor="buyer" className="mb-2 block text-sm font-medium text-slate-600">
                    Blerësi
                  </label>
                  <div className="flex gap-2 items-start">
                    <div className="flex-1">
                      <Select
                        value={selectedClient}
                        onValueChange={handleClientSelect}
                        disabled={isLoading}
                      >
                        <SelectTrigger 
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                          disabled={isLoading}
                        >
                          <SelectValue 
                            placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni blerësin"}
                            className="text-bluish-grey"
                          >
                            {selectedClient && (
                              <span className="text-hazel-green">
                                {clients.find(c => c.id === selectedClient)?.name}
                              </span>
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent 
                          className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden"
                          sideOffset={5}
                        >
                          <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                            <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                              Zgjidhni Blerësin
                            </h3>
                          </div>
                          {clients.map(client => (
                            <SelectItem
                              key={client.id}
                              value={client.id}
                              textValue={client.name}
                              className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                            >
                              {/* Hidden span for the select field display */}
                              <span className="hidden">{client.name}</span>
                              
                              {/* Detailed content for dropdown */}
                              <div className="flex flex-col gap-1">
                                <div className="flex flex-col">
                                  <span className="text-sm sm:text-base font-medium text-hazel-green">
                                    {client.name}
                                  </span>
                                  {client.fiscalNumber && (
                                    <span className="text-xs sm:text-sm text-bluish-grey">
                                      Nr. Fiskal: {client.fiscalNumber}
                                    </span>
                                  )}
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 text-xs sm:text-sm">
                                  {client.address && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                      </svg>
                                      <span>{client.address}</span>
                                    </div>
                                  )}
                                  {client.phone && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                      </svg>
                                      <span>{client.phone}</span>
                                    </div>
                                  )}
                                  {client.email && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                      </svg>
                                      <span>{client.email}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <Button
                      type="button"
                      variant="outline"
                      size="icon"
                      onClick={() => setIsClientModalOpen(true)}
                      className="flex-shrink-0 h-10 w-10 rounded-lg hover:bg-gray-100"
                    >
                      <Plus className="h-5 w-5" />
                    </Button>
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="location" className="mb-2 block text-sm font-medium text-slate-600">
                    Lokacioni
                  </label>
                  <Select
                    value={selectedLocation}
                    onValueChange={setSelectedLocation}
                    disabled={isLoading}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                      disabled={isLoading}
                    >
                      <SelectValue 
                        placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni lokacionin"}
                        className="text-bluish-grey"
                      >
                        {selectedLocation && (
                          <span className="text-hazel-green">
                            {locations.find(l => l.id === selectedLocation)?.name}
                          </span>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden"
                      sideOffset={5}
                      position="popper"
                      align="start"
                    >
                      <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                        <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                          Zgjidhni Lokacionin
                        </h3>
                      </div>
                      {locations.map(location => (
                        <SelectItem
                          key={location.id}
                          value={location.id}
                          textValue={location.name}
                          className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm sm:text-base font-medium text-hazel-green">
                              {location.name}
                            </span>
                            {location.address && (
                              <span className="text-xs sm:text-sm text-bluish-grey">
                                {location.address}
                              </span>
                            )}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>

          {/* Articles */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <div className="flex items-center justify-between">
                <h2 className="text-lg font-semibold text-hazel-green">Artikujt</h2>
                <button
                  type="button"
                  onClick={handleAddArticle}
                  className="inline-flex items-center gap-x-2 rounded-lg bg-hazel-green px-4 py-2 text-sm font-medium text-white hover:bg-sage transition-colors duration-200 shadow-sm"
                >
                  <Plus className="h-4 w-4" />
                  Shto Artikull
                </button>
              </div>
            </div>

            {articles.length === 0 ? (
              // Empty state
              <div className="p-12 text-center">
                <div className="mx-auto h-24 w-24 rounded-full bg-seashell flex items-center justify-center">
                  <Settings className="h-12 w-12 text-hazel-green/40" />
                </div>
                <h3 className="mt-4 text-base font-semibold text-hazel-green">
                  Nuk ka artikuj
                </h3>
                <p className="mt-2 text-sm text-bluish-grey">
                  Filloni duke shtuar artikullin e parë në ofertë.
                </p>
                <button
                  type="button"
                  onClick={handleAddArticle}
                  className="mt-6 inline-flex items-center gap-x-2 rounded-lg bg-hazel-green/10 px-4 py-2.5 text-sm font-medium text-hazel-green hover:bg-hazel-green/20 transition-colors duration-200"
                >
                  <Plus className="h-4 w-4" />
                  Shto Artikull të Ri
                </button>
              </div>
            ) : (
              // Articles table
              <div className="overflow-hidden">
                {/* Table Header */}
                <div className="border-b border-mercury/50">
                  <div className="grid grid-cols-12 gap-4 bg-gradient-to-r from-hazel-green/5 to-transparent p-4">
                    <div className="col-span-3 text-sm font-medium text-bluish-grey">Artikulli</div>
                    <div className="col-span-2 text-sm font-medium text-bluish-grey">Njësia</div>
                    <div className="col-span-1 text-sm font-medium text-bluish-grey">Sasia</div>
                    <div className="col-span-2 text-sm font-medium text-bluish-grey">Çmimi</div>
                    <div className="col-span-2 text-sm font-medium text-bluish-grey">TVSH</div>
                    <div className="col-span-1 text-sm font-medium text-bluish-grey">Totali</div>
                    <div className="col-span-1"></div>
                  </div>
                </div>

                {/* Table Body */}
                <div className="divide-y divide-mercury/50">
                  {articles.map((article, index) => (
                    <div 
                      key={article.id}
                      className={`grid grid-cols-12 gap-4 p-4 items-center ${
                        index % 2 === 0 ? 'bg-white' : 'bg-seashell/30'
                      } hover:bg-sage/5 transition-colors duration-150`}
                    >
                      {/* Article Select */}
                      <div className="col-span-3">
                        <Select
                          value={article.articleId || ''}
                          onValueChange={(value) => handleArticleSelect(article.id, value)}
                        >
                          <SelectTrigger className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury rounded-lg hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors">
                            <SelectValue>
                              {article.name || "Zgjidhni artikullin"}
                            </SelectValue>
                          </SelectTrigger>
                          <SelectContent className="bg-white rounded-lg shadow-lg border border-mercury/50 overflow-hidden max-h-[300px]">
                            <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                              <h3 className="text-xs font-medium text-bluish-grey">
                                Zgjidhni Artikullin
                              </h3>
                            </div>
                            {accountingArticles.map((item) => (
                              <SelectItem
                                key={item.id}
                                value={item.id}
                                textValue={item.name}
                                className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                              >
                                <div className="flex flex-col gap-1">
                                  <span className="font-medium text-hazel-green">{item.name}</span>
                                  <div className="flex items-center gap-x-2 text-xs text-bluish-grey">
                                    <span>Kodi: {item.code}</span>
                                    <span>•</span>
                                    <span>Çmimi: {item.sellingPrice?.toFixed(2) || '0.00'} €</span>
                                    <span>•</span>
                                    <span>TVSH: {item.vatRate?.percentage || 0}%</span>
                                  </div>
                                </div>
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>

                      {/* Unit */}
                      <div className="col-span-2">
                        <div className="h-10 px-3 py-2 text-sm bg-gray-50 border border-mercury rounded-lg text-bluish-grey">
                          {article.unit || 'N/A'}
                        </div>
                      </div>

                      {/* Quantity */}
                      <div className="col-span-1">
                        <input
                          type="number"
                          value={article.quantity}
                          onChange={(e) => handleArticleChange(article.id, 'quantity', parseFloat(e.target.value))}
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury rounded-lg text-bluish-grey focus:border-sage focus:ring-1 focus:ring-sage"
                        />
                      </div>

                      {/* Price */}
                      <div className="col-span-2">
                        <input
                          type="number"
                          value={article.priceWithoutVAT}
                          onChange={(e) => handleArticleChange(article.id, 'priceWithoutVAT', parseFloat(e.target.value))}
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury rounded-lg text-bluish-grey focus:border-sage focus:ring-1 focus:ring-sage"
                        />
                      </div>

                      {/* VAT */}
                      <div className="col-span-2">
                        <div className="h-10 px-3 py-2 text-sm bg-gray-50 border border-mercury rounded-lg text-bluish-grey">
                          {article.vatRate}%
                        </div>
                      </div>

                      {/* Total */}
                      <div className="col-span-1">
                        <span className="text-sm font-medium text-hazel-green">
                          {article.totalWithVAT.toFixed(2)} €
                        </span>
                      </div>

                      {/* Actions */}
                      <div className="col-span-1 flex justify-end">
                        <button
                          type="button"
                          onClick={() => removeArticle(article.id)}
                          className="p-1.5 rounded-lg text-grey-goose hover:text-watermelon hover:bg-watermelon/10 transition-all"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Pricing Section */}
          <div className="mt-8 border-t border-mercury pt-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-2">
                  Përshkrimi
                </label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Përshkrimi i ofertës"
                  className="h-32 block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                />
              </div>
              <div className="space-y-4">
                {/* Group VAT rates */}
                {Object.entries(calculateVatGroups()).map(([rate, amount]) => (
                  <div key={rate} className="flex justify-between items-center">
                    <span className="text-sm text-bluish-grey">TVSH {rate}%</span>
                    <span className="text-sm font-medium text-hazel-green">
                      {amount.toFixed(2)} €
                    </span>
                  </div>
                ))}

                {/* Subtotal */}
                <div className="flex justify-between items-center">
                  <span className="text-sm text-bluish-grey">Nëntotali pa TVSH</span>
                  <span className="text-sm font-medium text-hazel-green">
                    {calculateTotals().totalWithoutVAT.toFixed(2)} €
                  </span>
                </div>

                {/* Total VAT */}
                <div className="flex justify-between items-center">
                  <span className="text-sm text-bluish-grey">Totali TVSH</span>
                  <span className="text-sm font-medium text-hazel-green">
                    {calculateTotals().totalVAT.toFixed(2)} €
                  </span>
                </div>

                {/* Total with VAT */}
                <div className="flex justify-between items-center pt-4 border-t border-mercury">
                  <span className="text-base font-medium text-bluish-grey">Totali me TVSH</span>
                  <span className="text-base font-medium text-hazel-green">
                    {calculateTotals().totalWithVAT.toFixed(2)} €
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Attachments */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Bashkëngjit dokument</h2>
            </div>
            <div className="p-6">
              <div className="flex justify-center rounded-lg border-2 border-dashed border-mercury px-6 py-10 hover:border-hazel-green/30 transition-colors">
                <div className="text-center">
                  <Upload className="mx-auto h-12 w-12 text-grey-goose" />
                  <div className="mt-4">
                    <label className="relative cursor-pointer rounded-lg">
                      <span className="text-sm text-hazel-green hover:text-sage transition-colors">Mund vendosni dokument këtu (drag and drop)</span>
                      <input type="file" className="sr-only" />
                    </label>
                  </div>
                  <p className="mt-2 text-xs text-grey-goose">
                    Mund të ngarkoni dokument të vetëm, apo edhe grup dokumentesh.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClientModal
        isOpen={isClientModalOpen}
        onClose={() => setIsClientModalOpen(false)}
        onSuccess={handleClientSuccess}
      />
    </div>
  );
};

export default AddOfferPage;
