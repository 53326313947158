import React, { useState } from 'react';
import { PageHeader } from '../../components/hotelInterfaceComponents/lists/PageHeader.tsx';
import { SearchBar } from '../../components/hotelInterfaceComponents/lists/SearchBar.tsx';
import { BookingTable } from '../../components/hotelInterfaceComponents/lists/BookingTable.tsx';

import Navbar from '../../components/hotelInterfaceComponents/Navbar.tsx';

const columns = [
  { key: 'id', label: '#' },
  { key: 'refId', label: 'Ref ID' },
  { key: 'stayDuration', label: 'Stay Duration' },
  { key: 'room', label: 'Room # / Type / Block / Floor' },
  { key: 'pax', label: 'Pax' },
  { key: 'status', label: 'Status' },
  { key: 'checkInDate', label: 'Check-in Date' },
  { key: 'checkOutDate', label: 'Check-out Date' },
  { key: 'checkInTime', label: 'Check-in Time' },
  { key: 'amount', label: 'Amount' },
  { key: 'supplierAccount', label: 'Supplier Account' },
  { key: 'salesChannel', label: 'Sales Channel' },
  { key: 'splitBooking', label: 'Split Booking' },
  { key: 'notes', label: 'Notes' },
  { key: 'checkInGuest', label: 'Check-in Guest' },
];

const mockData = [
  {
    id: 1,
    refId: 'XBB10001',
    stayDuration: '1 Night',
    room: 'Room - 6',
    pax: 'Enis Gjocaj',
    status: 'Checked-In',
    checkInDate: '30-10-2024',
    checkOutDate: '31-10-2024',
    checkInTime: '00:00',
    amount: '€ 3.00',
    supplierAccount: '',
    salesChannel: '',
    splitBooking: '-',
    notes: 'Notes and Services',
    checkInGuest: 'Guest has checked-in',
  },
];

const CheckInList: React.FC = () => {
  const [startDate, setStartDate] = useState('2024-10-30');
  const [endDate, setEndDate] = useState('2024-10-30');
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className="min-h-screen bg-tealish-blue">
        <Navbar />
      <PageHeader title="Check-In List" />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <SearchBar
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          onChannelChange={() => {}}
          onSubmit={() => {}}
        />
        <BookingTable
          columns={columns}
          data={mockData}
          currentPage={currentPage}
          totalPages={1}
          onPageChange={setCurrentPage}
        />
      </main>
    </div>
  );
};

export default CheckInList;