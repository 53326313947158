import { Plus, FileText, TrendingUp, DollarSign } from 'lucide-react';
import { Button } from '../../../../../components/shadCN/Button.tsx';
import { Link } from 'react-router-dom';

import React from 'react';

export default function BudgetDefaultPage() {
  return (
    <div className="min-h-screen bg-seashell p-8">
      <div className="max-w-2xl mx-auto text-center">
        <div className="mb-8">
          <div className="w-16 h-16 bg-tealish-blue rounded-full flex items-center justify-center mx-auto mb-6">
            <FileText className="h-8 w-8 text-hazel-green" />
          </div>
          <h1 className="text-2xl font-semibold text-hazel-green mb-4">
            Welcome to Budget Management
          </h1>
          <p className="text-bluish-grey mb-8">
            Create and manage your budgets to track financial goals and monitor spending across different periods.
          </p>
        </div>

        <div className="grid grid-cols-2 gap-6 mb-8">
          <div className="bg-white p-6 rounded-xl border border-mercury">
            <div className="w-12 h-12 bg-tealish-blue rounded-full flex items-center justify-center mb-4">
              <TrendingUp className="h-6 w-6 text-hazel-green" />
            </div>
            <h3 className="font-medium text-hazel-green mb-2">Track Performance</h3>
            <p className="text-sm text-bluish-grey">
              Monitor actual vs budgeted amounts and analyze variances
            </p>
          </div>
          <div className="bg-white p-6 rounded-xl border border-mercury">
            <div className="w-12 h-12 bg-tealish-blue rounded-full flex items-center justify-center mb-4">
              <DollarSign className="h-6 w-6 text-hazel-green" />
            </div>
            <h3 className="font-medium text-hazel-green mb-2">Financial Planning</h3>
            <p className="text-sm text-bluish-grey">
              Plan and allocate resources effectively across different periods
            </p>
          </div>
        </div>

        <Button className="bg-hazel-green hover:bg-sage text-white">
          <Plus className="h-4 w-4 mr-2" />
          Create New Budget
        </Button>
      </div>
    </div>
  );
}