import React from 'react';
import { FileText, FileSpreadsheet } from 'lucide-react';
import { useDocumentGeneration } from '../hooks/useDocumentGeneration.ts';

interface DocumentGenerateButtonsProps {
  entityType: 'Sale' | 'Purchase' | 'Offer';
  entityId: string;
  onSuccess?: () => void;
  className?: string;
}

export const DocumentGenerateButtons: React.FC<DocumentGenerateButtonsProps> = ({
  entityType,
  entityId,
  onSuccess,
  className = ''
}) => {
  const { loading, generateDocumentForEntity } = useDocumentGeneration({ onSuccess });

  const handleGenerate = async (format: 'pdf' | 'excel') => {
    try {
      await generateDocumentForEntity(entityType, entityId, format);
    } catch (error) {
      // Error is already handled in the hook
    }
  };

  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      <button
        onClick={() => handleGenerate('pdf')}
        disabled={loading}
        className="p-2 text-hazel-green hover:bg-seashell rounded-lg transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
        title="Generate PDF"
      >
        <FileText className="h-4 w-4" />
      </button>
      <button
        onClick={() => handleGenerate('excel')}
        disabled={loading}
        className="p-2 text-hazel-green hover:bg-seashell rounded-lg transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
        title="Generate Excel"
      >
        <FileSpreadsheet className="h-4 w-4" />
      </button>
    </div>
  );
};
