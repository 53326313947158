import React from 'react';

export default function CancelButton({ onCancel }) {
  return (
    <button
      className='bg-white border border-sage w-1/2 md:w-1/6 text-sage rounded-md py-3 mr-3'
      type='button'
      onClick={onCancel}
    >
      Cancel
    </button>
  );
}
