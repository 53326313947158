import React from "react";
import Heading from "../common/Heading";
import About from "../home/About";
import Team from "./TeamPage";

import Header from "../common/Header";
import Footer from "../common/Footer";

export default function AboutUs() {
  return (
    <>
      <Header />
      <Heading heading="About" title="Home" subtitle="About" />
      <About />
      <Footer />
    </>
  );
}
