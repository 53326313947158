import api from "./api";

// Get dashboard metrics
export const getDashboardMetrics = async (locationId, period = 'month') => {
  try {
    // Make sure locationId is provided
    if (!locationId) {
      console.warn('No locationId provided to getDashboardMetrics');
      return null;
    }

    const response = await api.get(`/api/accounting-dashboard/${locationId}`, { 
      params: { period } 
    });
    console.log('Dashboard metrics response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching dashboard metrics:', error);
    throw error;
  }
};

// Get daily metrics snapshot
export const getDailyMetricsSnapshot = async (locationId) => {
  try {
    const response = await api.get(`/api/accounting-dashboard/${locationId}/daily-snapshot`);
    console.log('Daily snapshot response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching daily metrics snapshot:', error);
    throw error;
  }
};

