import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowLeft, Plus, Settings, Upload, X, Save } from 'lucide-react';
import { motion } from 'framer-motion';
import { getCompany } from '../../../api/company.js';
import { getClients } from '../../../api/accounting';
import { getArticlesByType } from '../../../api/accountingArticle';
import { createSale } from '../../../api/sale';
import { toast } from 'react-toastify';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '../../../components/shadCN/Select.tsx';
import { Label } from '../../../components/shadCN/Label.tsx';
import { Button } from '../../../components/shadCN/Button.tsx';
import { Input } from '../../../components/shadCN/Input.tsx';
import { Textarea } from '../../../components/shadCN/TextArea.tsx';
import ClientModal from '../../../components/ClientModal';
import Loading from '../../../components/loader';
import { getNextDocumentNumber } from '../../../api/sale';

interface Service {
  id: string;
  articleId?: string;
  name: string;
  description?: string;
  unit: string;
  quantity: number;
  priceWithoutVAT: number;
  vatAmount: number;
  totalWithVAT: number;
  vatRate?: number;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Client {
  id: string;
  name: string;
  fiscalNumber: string;
  address?: string;
  email?: string;
  phone?: string;
}

interface AccountingArticle {
  id: string;
  code: string;
  name: string;
  description?: string;
  purchasePrice?: number;
  sellingPrice?: number;
  unit: {
    id: string;
    name: string;
  };
  vatRate: {
    id: string;
    rate: number;
    percentage: number;
  };
}

const AddServiceSalePage = () => {
  const navigate = useNavigate();
  const [documentNumber, setDocumentNumber] = useState('');
  const [issueDate, setIssueDate] = useState(new Date().toISOString().split('T')[0]);
  const [dueDate, setDueDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedBuyer, setSelectedBuyer] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [description, setDescription] = useState('');
  const [services, setServices] = useState<Service[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [articles, setArticles] = useState<AccountingArticle[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch document number first
        console.log('Fetching document number...');
        const docNumberRes = await getNextDocumentNumber();
        console.log('Document number response:', docNumberRes);
        
        const docNumber = docNumberRes?.data?.message?.documentNumber;
        console.log('Setting document number to:', docNumber);
        
        if (!docNumber) {
          console.error('No document number received:', docNumberRes);
          toast.error('Failed to get document number');
          return;
        }
        
        setDocumentNumber(docNumber);

        // Continue with existing fetch calls
        const [companyRes, clientsRes, articlesRes] = await Promise.all([
          getCompany(),
          getClients(),
          getArticlesByType('SERVICE')
        ]);
        
        // Get locations from company response
        const companyLocations = companyRes?.data?.message?.locations || [];
        setLocations(Array.isArray(companyLocations) ? companyLocations : []);
        setClients(Array.isArray(clientsRes.data) ? clientsRes.data : []);
        setArticles(Array.isArray(articlesRes.data?.message) ? articlesRes.data.message : []);

        // Debug logs
        console.log('Company response:', companyRes?.data?.message);
        console.log('Locations:', companyLocations);
        console.log('Clients:', clientsRes.data);
        console.log('Articles:', articlesRes.data?.message);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error instanceof Error) {
          toast.error(error.message);
        } else {
          toast.error('Error loading data');
        }
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, []);

  const handleClientSelect = (clientId: string) => {
    setSelectedBuyer(clientId);
    const selectedClient = clients.find(client => client.id === clientId);
    console.log('Selected client:', selectedClient);
  };

  const handleClientSuccess = async (newClient: any) => {
    setIsClientModalOpen(false);
    // Refresh clients list
    try {
      const clientsRes = await getClients();
      setClients(Array.isArray(clientsRes.data) ? clientsRes.data : []);
      // Select the newly created client
      setSelectedBuyer(newClient.id);
      toast.success('Klienti u shtua me sukses');
    } catch (error) {
      console.error('Error refreshing clients:', error);
      toast.error('Failed to refresh clients list');
    }
  };

  const renderClientOption = (client: Client) => {
    return (
      <div className="flex flex-col">
        <span className="font-medium">{client.name}</span>
        <span className="text-sm text-gray-500">
          {client.fiscalNumber && `Nr. Fiskal: ${client.fiscalNumber}`}
          {client.address && ` • ${client.address}`}
        </span>
      </div>
    );
  };

  // Totals calculation
  const totalWithoutVAT = services.reduce((sum, service) => sum + service.priceWithoutVAT * service.quantity, 0);
  const totalVAT = services.reduce((sum, service) => sum + service.vatAmount, 0);
  const totalWithVAT = services.reduce((sum, service) => sum + service.totalWithVAT, 0);

  const handleAddService = () => {
    const newService: Service = {
      id: Date.now().toString(),
      name: '',
      description: '',
      unit: '',
      quantity: 0,
      priceWithoutVAT: 0,
      vatAmount: 0,
      totalWithVAT: 0,
    };
    setServices([...services, newService]);
  };

  const handleServiceSelect = (serviceId: string, selectedArticleId: string) => {
    const selectedArticle = articles.find(a => a.id === selectedArticleId);
    if (selectedArticle) {
      setServices(services.map(service => {
        if (service.id === serviceId) {
          return {
            ...service,
            articleId: selectedArticle.id,
            name: selectedArticle.name,
            description: selectedArticle.description || '',
            unit: selectedArticle.unit.name,
            priceWithoutVAT: selectedArticle.sellingPrice || 0,
            vatRate: selectedArticle.vatRate?.percentage || 0,
            vatAmount: (selectedArticle.sellingPrice || 0) * (selectedArticle.vatRate?.percentage || 0) / 100,
            totalWithVAT: (selectedArticle.sellingPrice || 0) * (1 + (selectedArticle.vatRate?.percentage || 0) / 100),
          };
        }
        return service;
      }));
    }
  };

  const handleServiceChange = (id: string, field: keyof Service, value: any) => {
    setServices(services.map(service => {
      if (service.id === id) {
        const updatedService = { ...service, [field]: value };
        
        if (field === 'priceWithoutVAT' || field === 'quantity') {
          const priceWithoutVAT = field === 'priceWithoutVAT' ? Number(value) : service.priceWithoutVAT;
          const quantity = field === 'quantity' ? Number(value) : service.quantity;
          const subtotal = priceWithoutVAT * quantity;
          const vatRate = service.vatRate || 0;
          const vatAmount = subtotal * (vatRate / 100);
          
          updatedService.vatAmount = vatAmount;
          updatedService.totalWithVAT = subtotal + vatAmount;
        }
        
        return updatedService;
      }
      return service;
    }));
  };

  const handleRemoveService = (id: string) => {
    setServices(services.filter(service => service.id !== id));
  };

  const handleSubmit = async () => {
    if (!selectedBuyer) {
      toast.error('Ju lutem zgjidhni një klient');
      return;
    }

    if (!selectedLocation) {
      toast.error('Ju lutem zgjidhni një lokacion');
      return;
    }

    if (services.length === 0) {
      toast.error('Ju lutem shtoni të paktën një shërbim');
      return;
    }

    // Validate services
    for (const service of services) {
      if (!service.articleId) {
        toast.error('Ju lutem zgjidhni një shërbim për të gjitha rreshtat');
        return;
      }
      if (service.quantity <= 0) {
        toast.error('Sasia duhet të jetë më e madhe se 0');
        return;
      }
    }

    try {
      setIsLoading(true);

      // Convert dates to ISO format with time
      const formattedIssueDate = new Date(issueDate + 'T00:00:00.000Z').toISOString();
      const formattedDueDate = new Date(dueDate + 'T00:00:00.000Z').toISOString();

      const saleData = {
        documentNumber,
        issueDate: formattedIssueDate,
        dueDate: formattedDueDate,
        clientId: selectedBuyer, 
        locationId: selectedLocation,
        description,
        type: 'SERVICE',
        status: 'DRAFT',
        items: services.map(service => ({
          articleId: service.articleId,
          quantity: Number(service.quantity),
          priceWithoutVAT: Number(service.priceWithoutVAT),
          vatRate: Number(service.vatRate || 0),
          vatAmount: Number(service.vatAmount),
          totalWithVAT: Number(service.totalWithVAT),
          description: service.description || ''
        })),
        createdBy: "52e8bbc3-6f2c-4b13-99fa-664e7f5d75a4", 
        adminUserId: "52e8bbc3-6f2c-4b13-99fa-664e7f5d75a4" 
      };

      console.log('Creating sale with data:', saleData);
      const response = await createSale(saleData);
      console.log('Sale created:', response);
      
      toast.success('Shitja u krijua me sukses');
      navigate('/accounting/sales/v2');
    } catch (error: any) {
      console.error('Error creating sale:', error);
      toast.error(error.response?.data?.message || 'Gabim gjatë krijimit të shitjes');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/accounting/sales')}
                className="inline-flex items-center gap-x-2 text-bluish-grey hover:text-hazel-green transition-colors"
              >
                <ArrowLeft className="h-5 w-5" />
                Kthehu
              </button>
            </div>
            <h1 className="text-lg font-semibold text-hazel-green">Shitje Shërbimi</h1>
            <div className="flex items-center gap-x-4">
              <Button
                onClick={handleSubmit}
                disabled={isLoading}
                className="bg-hazel-green hover:bg-opacity-90 text-white px-6 py-2.5 rounded-xl flex items-center gap-2 disabled:opacity-50"
              >
                {isLoading ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
                    Duke ruajtur...
                  </>
                ) : (
                  <>
                    <Save className="w-5 h-5" />
                    Ruaj
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Document Details */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Detajet për Shitje Shërbimi</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i Dokumentit
                  </label>
                  <input
                    type="text"
                    value={documentNumber}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                  <p className="mt-1 text-sm text-grey-goose">
                    Emri i dokumentit është automatikisht i gjeneruar në bazë të formatit.
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Data e lëshimit
                  </label>
                  <input
                    type="date"
                    value={issueDate}
                    onChange={(e) => setIssueDate(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Data e skadimit
                  </label>
                  <input
                    type="date"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                </div>

                <div className="relative mb-4">
                  <label htmlFor="buyer" className="mb-2 block text-sm font-medium text-slate-600">
                    Blerësi
                  </label>
                  <div className="flex gap-2 items-start">
                    <div className="flex-1">
                      <Select
                        value={selectedBuyer}
                        onValueChange={handleClientSelect}
                        disabled={isLoading}
                      >
                        <SelectTrigger 
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                          disabled={isLoading}
                        >
                          <SelectValue 
                            placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni blerësin"}
                            className="text-bluish-grey"
                          >
                            {selectedBuyer && (
                              <span className="text-hazel-green">
                                {clients.find(c => c.id === selectedBuyer)?.name}
                              </span>
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent 
                          className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                          sideOffset={5}
                          position="popper"
                          align="start"
                        >
                          <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                            <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                              Zgjidhni Blerësin
                            </h3>
                          </div>
                          {clients.map(client => (
                            <SelectItem
                              key={client.id}
                              value={client.id}
                              textValue={client.name}
                              className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                            >
                              {/* Hidden span for the select field display */}
                              <span className="hidden">{client.name}</span>
                              
                              {/* Detailed content for dropdown */}
                              <div className="flex flex-col gap-1">
                                <div className="flex flex-col">
                                  <span className="text-sm sm:text-base font-medium text-hazel-green">
                                    {client.name}
                                  </span>
                                  {client.fiscalNumber && (
                                    <span className="text-xs sm:text-sm text-bluish-grey">
                                      Nr. Fiskal: {client.fiscalNumber}
                                    </span>
                                  )}
                                </div>
                                {client.address && (
                                  <div className="flex items-center gap-1 text-bluish-grey">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                    </svg>
                                    <span>{client.address}</span>
                                  </div>
                                )}
                                {client.phone && (
                                  <div className="flex items-center gap-1 text-bluish-grey">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                    </svg>
                                    <span>{client.phone}</span>
                                  </div>
                                )}
                                {client.email && (
                                  <div className="flex items-center gap-1 text-bluish-grey">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                    </svg>
                                    <span>{client.email}</span>
                                  </div>
                                )}
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <Button
                      type="button"
                      variant="outline"
                      size="icon"
                      onClick={() => setIsClientModalOpen(true)}
                      className="flex-shrink-0 h-10 w-10 rounded-lg hover:bg-gray-100"
                    >
                      <Plus className="h-5 w-5" />
                    </Button>
                  </div>
                </div>

                <div>
                  <label htmlFor="location" className="mb-2 block text-sm font-medium text-slate-600">
                    Lokacioni
                  </label>
                  <Select
                    value={selectedLocation}
                    onValueChange={setSelectedLocation}
                    disabled={isLoading}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                      disabled={isLoading}
                    >
                      <SelectValue 
                        placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni lokacionin"}
                        className="text-bluish-grey"
                      >
                        {selectedLocation && (
                          <span className="text-hazel-green">
                            {locations.find(l => l.id === selectedLocation)?.name}
                          </span>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                      sideOffset={5}
                      position="popper"
                      align="start"
                    >
                      <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                        <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                          Zgjidhni Lokacionin
                        </h3>
                      </div>
                      {locations.map(location => (
                        <SelectItem
                          key={location.id}
                          value={location.id}
                          textValue={location.name}
                          className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm sm:text-base font-medium text-hazel-green">
                              {location.name}
                            </span>
                            {location.address && (
                              <span className="text-xs sm:text-sm text-bluish-grey">
                                {location.address}
                              </span>
                            )}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="sm:col-span-2">
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Përshkrimi
                  </label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={3}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                    placeholder="Shtoni një përshkrim për këtë shitje..."
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Services */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4 flex justify-between items-center">
              <h2 className="text-lg font-semibold text-hazel-green">Shërbimet</h2>
            </div>
            <div className="p-6">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="space-y-4">
                  {services.length === 0 ? (
                    <div className="flex flex-col items-center justify-center py-8">
                      <div className="w-16 h-16 bg-gradient-to-br from-sage/20 to-hazel-green/10 rounded-lg flex items-center justify-center mb-4">
                        <Plus className="h-8 w-8 text-hazel-green" />
                      </div>
                      <h3 className="text-base font-medium text-bluish-grey mb-1">Nuk ka shërbime</h3>
                      <p className="text-sm text-grey-goose mb-4">Shtoni shërbime për të vazhduar me shitjen</p>
                      <button
                        type="button"
                        onClick={handleAddService}
                        className="inline-flex items-center gap-x-2 px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
                      >
                        <Plus className="h-5 w-5" />
                        Shto Shërbim
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="grid grid-cols-12 gap-4 items-center mb-2 text-sm font-medium text-bluish-grey">
                        <div className="col-span-3">Emri i shërbimit</div>
                        <div className="col-span-3">Përshkrimi</div>
                        <div className="col-span-1">Sasia</div>
                        <div className="col-span-2">Çmimi pa TVSH</div>
                        <div className="col-span-2">TVSH</div>
                        <div className="col-span-1">Totali</div>
                        <div className="col-span-1"></div>
                      </div>
                      {services.map((service, index) => (
                        <div key={service.id} className="grid grid-cols-12 gap-4 items-center">
                          <div className="col-span-3">
                            <Select
                              value={service.articleId}
                              onValueChange={(articleId) => handleServiceSelect(service.id, articleId)}
                            >
                              <SelectTrigger 
                                className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                              >
                                <SelectValue 
                                  placeholder="Zgjidhni shërbimin"
                                  className="text-bluish-grey"
                                >
                                  {service.articleId && (
                                    <div className="flex flex-col">
                                      <span className="text-sm font-medium text-hazel-green">
                                        {articles.find(a => a.id === service.articleId)?.name}
                                      </span>
                                      <span className="text-xs text-bluish-grey">
                                        {articles.find(a => a.id === service.articleId)?.code}
                                      </span>
                                    </div>
                                  )}
                                </SelectValue>
                              </SelectTrigger>
                              <SelectContent 
                                className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                                sideOffset={5}
                                position="popper"
                                align="start"
                              >
                                <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                                  <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                                    Zgjidhni Shërbimin
                                  </h3>
                                </div>
                                {articles.length === 0 ? (
                                  <div className="px-4 py-3 text-sm text-bluish-grey">
                                    Nuk ka shërbime të regjistruara
                                  </div>
                                ) : (
                                  articles.map(article => (
                                    <SelectItem
                                      key={article.id}
                                      value={article.id}
                                      textValue={article.name}
                                      className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                                    >
                                      <div className="flex flex-col gap-1">
                                        <div className="flex flex-col">
                                          <span className="text-sm sm:text-base font-medium text-hazel-green">
                                            {article.name}
                                          </span>
                                          <span className="text-xs sm:text-sm text-bluish-grey">
                                            Kodi: {article.code}
                                          </span>
                                        </div>
                                        <div className="grid grid-cols-2 gap-1 text-xs sm:text-sm text-bluish-grey">
                                          <div className="flex items-center gap-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                              <path fillRule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clipRule="evenodd" />
                                            </svg>
                                            <span>Çmimi: {article.sellingPrice?.toFixed(2)} €</span>
                                          </div>
                                          <div className="flex items-center gap-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                              <path fillRule="evenodd" d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                                            </svg>
                                            <span>TVSH: {article.vatRate?.percentage}%</span>
                                          </div>
                                          {article.description && (
                                            <div className="col-span-2 flex items-center gap-1">
                                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                              </svg>
                                              <span>{article.description}</span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </SelectItem>
                                  ))
                                )}
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="col-span-3">
                            <input
                              type="text"
                              value={service.description}
                              onChange={(e) => handleServiceChange(service.id, 'description', e.target.value)}
                              placeholder="Përshkrimi"
                              className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                            />
                          </div>
                          <div className="col-span-1">
                            <input
                              type="number"
                              value={service.quantity}
                              onChange={(e) => handleServiceChange(service.id, 'quantity', e.target.value)}
                              placeholder="Sasia"
                              className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                            />
                          </div>
                          <div className="col-span-2">
                            <div className="flex items-center">
                              <input
                                type="number"
                                value={service.priceWithoutVAT}
                                onChange={(e) => handleServiceChange(service.id, 'priceWithoutVAT', e.target.value)}
                                placeholder="Çmimi pa TVSH"
                                className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                              />
                              <span className="ml-2 text-sm text-bluish-grey">€</span>
                            </div>
                          </div>
                          <div className="col-span-2">
                            <div className="flex items-center">
                              <input
                                type="number"
                                value={service.vatAmount}
                                disabled
                                className="block w-full rounded-lg border border-mercury bg-gray-50 px-4 py-2.5 text-bluish-grey shadow-sm"
                              />
                              <span className="ml-2 text-sm text-bluish-grey">€</span>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <div className="flex items-center">
                              <input
                                type="number"
                                value={service.totalWithVAT}
                                disabled
                                className="block w-full rounded-lg border border-mercury bg-gray-50 px-4 py-2.5 text-bluish-grey shadow-sm"
                              />
                              <span className="ml-2 text-sm text-bluish-grey">€</span>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <button
                              type="button"
                              onClick={() => handleRemoveService(service.id)}
                              className="inline-flex items-center justify-center w-10 h-10 rounded-lg text-red-500 hover:bg-red-50 transition-colors"
                            >
                              <X className="h-5 w-5" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  
                  {/* Add Service Button */}
                  <div className="mt-4">
                    <button
                      type="button"
                      onClick={handleAddService}
                      className="inline-flex items-center gap-x-2 text-sm text-sage hover:text-hazel-green transition-colors"
                    >
                      <Plus className="h-4 w-4" />
                      Shto Shërbim
                    </button>
                  </div>

                  {/* Totals */}
                  <div className="mt-8 border-t border-mercury pt-6">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <Label htmlFor="description" className="mb-2 block text-sm font-medium text-bluish-grey">
                          Përshkrimi
                        </Label>
                        <Textarea
                          id="description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder="Përshkrimi i shitjes"
                          className="h-32"
                        />
                      </div>
                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <span className="text-sm text-bluish-grey">Nëntotali pa TVSH</span>
                          <span className="text-sm font-medium text-hazel-green">{totalWithoutVAT.toFixed(2)} €</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-sm text-bluish-grey">TVSH</span>
                          <span className="text-sm font-medium text-hazel-green">{totalVAT.toFixed(2)} €</span>
                        </div>
                        <div className="flex justify-between items-center pt-4 border-t border-mercury">
                          <span className="text-base font-medium text-bluish-grey">Totali me TVSH</span>
                          <span className="text-base font-medium text-hazel-green">{totalWithVAT.toFixed(2)} €</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      
      {/* Client Modal */}
      <ClientModal
        isOpen={isClientModalOpen}
        onClose={() => setIsClientModalOpen(false)}
        onSuccess={handleClientSuccess}
      />
    </div>
  );
};

export default AddServiceSalePage;
