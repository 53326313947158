import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, AlertTriangle } from 'lucide-react';

interface DeleteSaleConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  saleNumber: string;
}

const DeleteSaleConfirmationModal: React.FC<DeleteSaleConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  saleNumber
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-0">
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-bluish-grey/30 backdrop-blur-sm"
            onClick={onClose}
          />

          {/* Modal */}
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative w-full max-w-md bg-white rounded-2xl shadow-xl p-6 overflow-hidden"
          >
            {/* Top Decorative Bar */}
            <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-watermelon via-salmon-pink to-dawn-pink" />

            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute top-4 right-4 p-2 text-bluish-grey hover:text-watermelon rounded-lg transition-colors"
            >
              <X className="w-5 h-5" />
            </button>

            {/* Content */}
            <div className="mt-4">
              {/* Warning Icon */}
              <div className="mx-auto w-12 h-12 rounded-full bg-dawn-pink flex items-center justify-center mb-4">
                <AlertTriangle className="w-6 h-6 text-watermelon" />
              </div>

              {/* Title */}
              <h3 className="text-xl font-semibold text-bluish-grey text-center mb-2">Fshi Shitjen</h3>
              
              {/* Message */}
              <div className="bg-seashell rounded-lg p-4 mb-6">
                <p className="text-bluish-grey/90 text-center">
                  A jeni të sigurt që dëshironi të fshini shitjen me numër <span className="font-semibold text-hazel-green">{saleNumber}</span>?
                </p>
                <p className="text-watermelon/80 text-sm text-center mt-2">
                  Ky veprim nuk mund të kthehet.
                </p>
              </div>

              {/* Actions */}
              <div className="flex justify-center gap-3">
                <button
                  onClick={onClose}
                  className="px-6 py-2.5 text-sm font-medium text-bluish-grey hover:text-hazel-green hover:bg-seashell rounded-lg transition-colors duration-200"
                >
                  Anulo
                </button>
                <button
                  onClick={onConfirm}
                  className="px-6 py-2.5 text-sm font-medium text-white bg-watermelon hover:from-watermelon/90 hover:to-salmon-pink/90 rounded-lg transition-all duration-200 shadow-lg shadow-watermelon/20 hover:shadow-watermelon/30"
                >
                  Fshi
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default DeleteSaleConfirmationModal;
