import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IconCalendarMonth } from '@tabler/icons-react';
import ReservationForm from './ReservationForm';
import PageTitle from '../../../components/page-title';
import Loading from '../../../components/loader';
import { getReservation } from '../../../api/reservations';

function EditReservation() {
  const params = useParams();
  const reservationId = params.id;
  const [reservation, setReservation] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getReservationData();
  }, []);

  const getReservationData = async () => {
    try {
      const response = await getReservation(reservationId);
      setReservation(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconCalendarMonth className='text-hazel-green mr-2' size={22} />}
        title='Edit Reservation'
      />
      <ReservationForm isEdit reservation={reservation} />
    </div>
  );
}

export default EditReservation;
