import React from 'react';
import { Link } from 'react-router-dom';
import {
    IconReportMoney,
    IconChartDots2,
    IconReportAnalytics
} from '@tabler/icons-react';




const Administration = () => {

        return (
          <div className="p-10 bg-tealish-blue min-h-screen">
            <div className="container mx-auto">
              <h1 className="text-4xl font-bold mb-16 text-bluish-grey text-center">
                Administration Dashboard
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <Link to="/accounting">
                  <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col justify-between">
                  <h2 className="flex flex-row text-center items-center gap-6 text-3xl font-semibold mb-8 text-hazel-green">
                      Accounting 
                      <IconReportAnalytics size={36} />
                    </h2>
                    <p className="text-bluish-grey text-lg mb-6">
                      Manage your accounting tasks, view reports, and track transactions.
                    </p>
                    <div className="mt-auto flex justify-end">
                      <span className="text-sm text-silver font-medium">View More</span>
                    </div>
                  </div>
                </Link>
                <Link to="/finances">
                  <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col justify-between">
                    <h2 className="flex flex-row text-center items-center gap-6 text-3xl font-semibold mb-8 text-hazel-green">
                      Finances 
                      <IconReportMoney size={36} />
                    </h2>
                    <p className="text-bluish-grey text-lg mb-6">
                      Oversee financial activities, analyze financial statements, and budget.
                    </p>
                    <div className="mt-auto flex justify-end">
                      <span className="text-sm text-silver font-medium">View More</span>
                    </div>
                  </div>
                </Link>
                <Link to="/statistics">
                  <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col justify-between">
                    <h2 className="flex flex-row text-center items-center gap-6 text-3xl font-semibold mb-8 text-hazel-green">
                      Statistics
                      <IconChartDots2 size={36} />
                    </h2>
                    <p className="text-bluish-grey text-lg mb-6">
                      Review statistical data, track performance metrics, and generate reports.
                    </p>
                    <div className="mt-auto flex justify-end">
                      <span className="text-sm text-silver font-medium">View More</span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        );
      };
export default Administration;
