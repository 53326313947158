import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FileText, 
  Calendar, 
  ArrowRight, 
  Download, 
  ChevronDown, 
  RefreshCw, 
  Settings, 
  Filter, 
  Clock, 
  Plus, 
  Minus, 
  Eye, 
  X, 
  Link,
  ExternalLink,
  AlertCircle,
  Search,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { getMainBook, exportMainBook } from '../../../api/mainBook';
import { toast } from 'react-hot-toast';

interface MainBookEntry {
  id: string;
  documentNumber: string;
  postingDate: string;
  createdAt: string;
  description: string;
  reference: string;
  location: any;
  items: Array<{
    id: string;
    accountCode: string;
    accountName: string;
    accountClass: string;
    debit: number;
    credit: number;
    description: string;
  }>;
  totalDebit: number;
  totalCredit: number;
  attachments: any[];
  referenceType?: 'sale' | 'purchase';
  referenceId?: string;
}

const MainBookPage = () => {
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [showReport, setShowReport] = useState(false);
  const [dateDisplayType, setDateDisplayType] = useState<'document' | 'creation'>('document');
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [mainBookData, setMainBookData] = useState<MainBookEntry[]>([]);
  const [totals, setTotals] = useState({ totalDebit: 0, totalCredit: 0 });
  const [selectedEntry, setSelectedEntry] = useState<MainBookEntry | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5;

  const toggleRow = (rowId: string) => {
    setExpandedRows(prev => 
      prev.includes(rowId) 
        ? prev.filter(id => id !== rowId)
        : [...prev, rowId]
    );
  };

  const getAccountTypeBadgeColor = (type: string) => {
    const colors = {
      revenue: 'bg-emerald-100 text-emerald-800',
      asset: 'bg-blue-100 text-blue-800',
      liability: 'bg-purple-100 text-purple-800',
      expense: 'bg-amber-100 text-amber-800',
      default: 'bg-gray-100 text-gray-800'
    };
    return colors[type] || colors.default;
  };

  const handleGenerateReport = async () => {
    // Reset validation error
    setValidationError(null);

    // Validate date range
    if (!dateRange.start || !dateRange.end) {
      setValidationError('Ju lutemi përzgjidhni periudhën kohore për të gjeneruar raportin.');
      return;
    }

    // Validate that end date is not before start date
    if (new Date(dateRange.end) < new Date(dateRange.start)) {
      setValidationError('Data e mbarimit nuk mund të jetë para datës së fillimit.');
      return;
    }

    setLoading(true);
    try {
      const response = await getMainBook({
        startDate: dateRange.start,
        endDate: dateRange.end,
        dateType: dateDisplayType
      });

      const mainBookResult = response.data.message;
      setMainBookData(mainBookResult.entries || []);
      setTotals(mainBookResult.totals || { totalDebit: 0, totalCredit: 0 });
      setShowReport(true);
    } catch (error) {
      toast.error('Failed to fetch main book data');
      console.error('Error fetching main book:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async (format: 'pdf' | 'excel') => {
    try {
      const response = await exportMainBook({
        startDate: dateRange.start,
        endDate: dateRange.end,
        dateType: dateDisplayType,
        format
      });
      
      toast.success(response.data.message);
    } catch (error) {
      toast.error(`Failed to export in ${format.toUpperCase()} format`);
      console.error('Error exporting main book:', error);
    }
  };

  const toggleDateDisplay = (type: 'document' | 'creation') => {
    setDateDisplayType(type);
  };

  const handleViewDetails = (entry: MainBookEntry) => {
    setSelectedEntry(entry);
    setShowModal(true);
  };

  const handleReferenceClick = async (type: string, id: string) => {
    try {
      // Navigate to reference document
      if (type === 'sale') {
        window.open(`/sales/${id}`, '_blank');
      } else if (type === 'purchase') {
        window.open(`/purchases/${id}`, '_blank');
      }
    } catch (error) {
      toast.error('Could not open reference document');
    }
  };

  const handleExportDetails = async (entry: MainBookEntry) => {
    try {
      await exportMainBook({
        startDate: dateRange.start,
        endDate: dateRange.end,
        dateType: dateDisplayType,
        format: 'pdf',
        documentId: entry.id
      });
      toast.success('Document exported successfully');
    } catch (error) {
      toast.error('Failed to export document');
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header Section */}
      <div className="bg-white/80 backdrop-blur-sm border-b border-mercury sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2 text-sm">
              <span className="text-sage hover:text-hazel-green font-medium transition-colors">Raportet</span>
              <ChevronDown className="w-4 h-4 text-sage" />
              <span className="font-medium text-tealish-blue">Libri Kryesor</span>
            </div>
            <div className="text-sm font-medium text-hazel-green bg-sage/10 px-4 py-2 rounded-full shadow-sm border border-sage/20">
              Periudha: {dateRange.start || 'N/A'} - {dateRange.end || 'N/A'}
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">
        {/* Form Card */}
        <div className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 mb-8 overflow-hidden">
          <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-8">
            <div className="max-w-2xl mx-auto text-center">
              <h1 className="text-2xl font-semibold text-bluish-grey mb-3">Libri Kryesor</h1>
              <p className="text-grey-goose">
                Shfletoni dhe analizoni të dhënat e librit kryesor duke përzgjedhur periudhën kohore
              </p>
            </div>
          </div>

          {/* Form Content */}
          <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-xl mx-auto mb-6">
              {/* Start Date */}
              <div className="relative group">
                <label className="inline-flex items-center text-sm font-medium text-bluish-grey mb-2 group-hover:text-hazel-green transition-colors">
                  <Calendar className="w-4 h-4 mr-2 opacity-70" />
                  <span>Data e fillimit</span>
                </label>
                <input
                  type="date"
                  value={dateRange.start}
                  onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
                  className={`w-full p-3 bg-seashell border rounded-xl text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 hover:border-hazel-green/30 transition-colors ${
                    validationError ? 'border-red-300' : 'border-mercury'
                  }`}
                />
              </div>

              {/* End Date */}
              <div className="relative group">
                <label className="inline-flex items-center text-sm font-medium text-bluish-grey mb-2 group-hover:text-hazel-green transition-colors">
                  <Calendar className="w-4 h-4 mr-2 opacity-70" />
                  <span>Data e mbarimit</span>
                </label>
                <input
                  type="date"
                  value={dateRange.end}
                  onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
                  className={`w-full p-3 bg-seashell border rounded-xl text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 hover:border-hazel-green/30 transition-colors ${
                    validationError ? 'border-red-300' : 'border-mercury'
                  }`}
                />
              </div>
            </div>

            {/* Validation Error Message */}
            {validationError && (
              <div className="max-w-xl mx-auto mb-6">
                <div className="flex items-center gap-2 p-3 rounded-lg bg-red-50 text-red-600 text-sm">
                  <AlertCircle className="w-4 h-4" />
                  <span>{validationError}</span>
                </div>
              </div>
            )}

            {/* Action Buttons */}
            <div className="flex justify-center space-x-4">
              <button
                onClick={handleGenerateReport}
                disabled={loading}
                className="inline-flex items-center px-6 py-3 bg-hazel-green text-white rounded-xl hover:bg-hazel-green/90 focus:outline-none focus:ring-2 focus:ring-hazel-green/20 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <RefreshCw className="w-5 h-5 mr-2 animate-spin" />
                ) : (
                  <FileText className="w-5 h-5 mr-2" />
                )}
                Gjenero Raportin
              </button>
            </div>
          </div>
        </div>

        {/* Empty State */}
        {!showReport && (
          <div className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 p-12 text-center">
            <div className="max-w-md mx-auto py-4">
              <div className="w-16 h-16 mx-auto mb-6 rounded-full bg-hazel-green/10 flex items-center justify-center">
                <Search className="w-8 h-8 text-hazel-green" />
              </div>
              <h3 className="text-xl font-semibold text-bluish-grey mb-3">
                Nuk ka të dhëna për të shfaqur
              </h3>
              <p className="text-grey-goose mb-8">
                Përzgjidhni periudhën kohore dhe klikoni butonin "Gjenero Raportin" për të shfaqur të dhënat e librit kryesor.
              </p>
              <div className="flex items-center justify-center gap-2 text-sm text-hazel-green">
                <Calendar className="w-4 h-4" />
                <span>Zgjidhni datat për të filluar</span>
              </div>
            </div>
          </div>
        )}

        {/* Report Section */}
        <AnimatePresence mode="wait">
          {showReport && (
            <motion.div
              key="report"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 overflow-hidden"
            >
              {/* Report Header */}
              <div className="bg-gradient-to-r from-hazel-green/10 to-transparent p-6 border-b border-mercury/50">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                  <div className="flex items-center gap-6">
                    <h2 className="text-lg font-semibold text-hazel-green">
                      Detajet e Librit Kryesor
                    </h2>
                    {/* Date Display Toggle */}
                    <div className="flex items-center bg-seashell rounded-lg p-1">
                      <button
                        onClick={() => toggleDateDisplay('document')}
                        className={`flex items-center px-3 py-2 rounded-md text-sm font-medium transition-all duration-200 ${
                          dateDisplayType === 'document'
                            ? 'bg-white text-hazel-green shadow-sm'
                            : 'text-grey-goose hover:text-sage'
                        }`}
                      >
                        <Calendar className="w-4 h-4 mr-2" />
                        Data e dokumentit
                      </button>
                      <button
                        onClick={() => toggleDateDisplay('creation')}
                        className={`flex items-center px-3 py-2 rounded-md text-sm font-medium transition-all duration-200 ${
                          dateDisplayType === 'creation'
                            ? 'bg-white text-hazel-green shadow-sm'
                            : 'text-grey-goose hover:text-sage'
                        }`}
                      >
                        <Clock className="w-4 h-4 mr-2" />
                        Data e krijimit
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <button className="inline-flex items-center px-4 py-2 bg-white text-hazel-green rounded-lg hover:bg-seashell transition-colors duration-200 gap-2 font-medium border border-mercury/50 shadow-sm">
                      <Filter className="w-4 h-4" />
                      <span>Filtro</span>
                    </button>
                    <button className="inline-flex items-center px-4 py-2 bg-white text-hazel-green rounded-lg hover:bg-seashell transition-colors duration-200 gap-2 font-medium border border-mercury/50 shadow-sm">
                      <RefreshCw className="w-4 h-4" />
                      <span>Rifresko</span>
                    </button>
                    <button 
                      onClick={() => handleExport('excel')}
                      className="inline-flex items-center px-4 py-2 bg-white text-hazel-green rounded-lg hover:bg-seashell transition-colors duration-200 gap-2 font-medium border border-mercury/50 shadow-sm"
                    >
                      <Download className="w-4 h-4" />
                      <span>Eksporto</span>
                    </button>
                  </div>
                </div>
              </div>

              {/* Table Section */}
              <div className="overflow-x-auto w-full">
                <div className="w-full align-middle">
                  <div className="overflow-hidden">
                    <table className="w-full table-fixed divide-y divide-mercury/50">
                      <thead>
                        <tr className="bg-seashell/50">
                          <th scope="col" className="sticky left-0 z-10 bg-seashell/50 px-4 py-3.5 text-left text-sm font-medium text-hazel-green w-[5%]"></th>
                          <th scope="col" className="sticky left-10 z-10 bg-seashell/50 px-4 py-3.5 text-left text-sm font-medium text-hazel-green w-[20%]">Nr. Dokumentit</th>
                          <th scope="col" className="px-4 py-3.5 text-left text-sm font-medium text-hazel-green w-[15%]">
                            {dateDisplayType === 'document' ? 'Data e dokumentit' : 'Data e krijimit'}
                          </th>
                          <th scope="col" className="px-4 py-3.5 text-left text-sm font-medium text-hazel-green w-[30%]">Përshkrimi</th>
                          <th scope="col" className="px-4 py-3.5 text-right text-sm font-medium text-hazel-green w-[15%]">Debit (€)</th>
                          <th scope="col" className="px-4 py-3.5 text-right text-sm font-medium text-hazel-green w-[15%]">Kredit (€)</th>
                          <th scope="col" className="w-[5%] px-2 py-3.5 text-center text-sm font-medium text-hazel-green">
                            <Eye className="w-4 h-4 mx-auto opacity-50" />
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-mercury/50 bg-white">
                        {mainBookData
                          .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                          .map((entry) => (
                            <React.Fragment key={entry.id}>
                              <tr className="hover:bg-seashell/30 transition-colors duration-200">
                                <td className="sticky left-0 z-10 bg-white px-4 py-4 border-r border-mercury/50">
                                  <button
                                    onClick={() => toggleRow(entry.id)}
                                    className="p-1 hover:bg-seashell rounded-lg transition-colors duration-200"
                                  >
                                    {expandedRows.includes(entry.id) ? (
                                      <Minus className="w-4 h-4 text-hazel-green" />
                                    ) : (
                                      <Plus className="w-4 h-4 text-hazel-green" />
                                    )}
                                  </button>
                                </td>
                                <td className="sticky left-10 z-10 bg-white px-4 py-4 text-sm text-bluish-grey border-r border-mercury/50">{entry.documentNumber}</td>
                                <td className="px-4 py-4 text-sm text-bluish-grey border-r border-mercury/50">
                                  {new Date(dateDisplayType === 'document' ? entry.postingDate : entry.createdAt).toLocaleDateString()}
                                </td>
                                <td className="px-4 py-4 text-sm text-bluish-grey border-r border-mercury/50">{entry.description}</td>
                                <td className="px-4 py-4 text-right text-sm text-bluish-grey border-r border-mercury/50">{entry.totalDebit.toFixed(2)}</td>
                                <td className="px-4 py-4 text-right text-sm text-bluish-grey border-r border-mercury/50">{entry.totalCredit.toFixed(2)}</td>
                                <td className="w-12 px-2 py-4 text-center">
                                  <button
                                    onClick={() => handleViewDetails(entry)}
                                    className="p-1.5 hover:bg-seashell rounded-lg transition-colors duration-200 text-bluish-grey hover:text-hazel-green mx-auto"
                                  >
                                    <Eye className="w-4 h-4" />
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={7} className="p-0 bg-white">
                                  <AnimatePresence>
                                    {expandedRows.includes(entry.id) && (
                                      <motion.div
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0, height: 0 }}
                                        transition={{ duration: 0.3, ease: "easeInOut" }}
                                        className="w-full"
                                      >
                                        <div className="border-l-2 border-hazel-green/20 ml-6 pl-4 py-4 pr-4 bg-seashell/30">
                                          <div className="bg-white rounded-xl border border-mercury/50 overflow-hidden">
                                            {/* Header */}
                                            <div className="grid grid-cols-12 gap-4 px-4 py-3 bg-seashell/50 text-xs font-medium text-hazel-green">
                                              <div className="col-span-2">Kodi</div>
                                              <div className="col-span-3">Emri i Llogarisë</div>
                                              <div className="col-span-3">Lloji</div>
                                              <div className="col-span-2 text-right">Debit (€)</div>
                                              <div className="col-span-2 text-right">Kredit (€)</div>
                                            </div>
                                            {/* Items */}
                                            <div className="divide-y divide-mercury/50">
                                              {entry.items.map((item) => (
                                                <div key={item.id} className="grid grid-cols-12 gap-4 px-4 py-3 group hover:bg-seashell/30 transition-colors duration-200">
                                                  {/* Code */}
                                                  <div className="col-span-2">
                                                    <div className="w-full text-center py-1 rounded-md bg-seashell group-hover:bg-sage/10 transition-colors duration-200">
                                                      <span className="text-xs font-medium text-sage">{item.accountCode}</span>
                                                    </div>
                                                  </div>
                                                  {/* Account Name */}
                                                  <div className="col-span-3">
                                                    <span className="text-xs text-bluish-grey group-hover:text-hazel-green transition-colors duration-200">
                                                      {item.accountName}
                                                    </span>
                                                  </div>
                                                  {/* Account Type */}
                                                  <div className="col-span-3">
                                                    <span className={`inline-flex px-2.5 py-0.5 rounded-md text-xs font-medium ${getAccountTypeBadgeColor(item.accountClass.toLowerCase())} capitalize`}>
                                                      {item.accountClass}
                                                    </span>
                                                  </div>
                                                  {/* Debit Amount */}
                                                  <div className="col-span-2 text-right">
                                                    <span className={`text-xs font-medium ${
                                                      item.debit > 0 ? 'text-hazel-green' : 'text-bluish-grey'
                                                    }`}>
                                                      {item.debit.toFixed(2)} €
                                                    </span>
                                                  </div>
                                                  {/* Credit Amount */}
                                                  <div className="col-span-2 text-right">
                                                    <span className={`text-xs font-medium ${
                                                      item.credit > 0 ? 'text-hazel-green' : 'text-bluish-grey'
                                                    }`}>
                                                      {item.credit.toFixed(2)} €
                                                    </span>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      </motion.div>
                                    )}
                                  </AnimatePresence>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr className="bg-seashell/50 font-medium">
                          <td colSpan={4} className="px-4 py-4 text-right text-sm text-hazel-green border-r border-mercury/50">Totali:</td>
                          <td className="px-4 py-4 text-right text-sm text-hazel-green border-r border-mercury/50">{totals.totalDebit.toFixed(2)} €</td>
                          <td className="px-4 py-4 text-right text-sm text-hazel-green border-r border-mercury/50">{totals.totalCredit.toFixed(2)} €</td>
                          <td className="px-4 py-4"></td>
                        </tr>
                      </tfoot>
                    </table>

                    {/* Pagination Controls */}
                    <div className="flex items-center justify-between border-t border-mercury/50 bg-white px-4 py-3 sm:px-6">
                      <div className="flex flex-1 justify-between sm:hidden">
                        <button
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                          className="relative inline-flex items-center rounded-md border border-mercury/50 bg-white px-4 py-2 text-sm font-medium text-hazel-green hover:bg-seashell disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === Math.ceil(mainBookData.length / itemsPerPage)}
                          className="relative ml-3 inline-flex items-center rounded-md border border-mercury/50 bg-white px-4 py-2 text-sm font-medium text-hazel-green hover:bg-seashell disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          Next
                        </button>
                      </div>
                      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                        <div>
                          <p className="text-sm text-bluish-grey">
                            Showing <span className="font-medium">{((currentPage - 1) * itemsPerPage) + 1}</span> to{' '}
                            <span className="font-medium">
                              {Math.min(currentPage * itemsPerPage, mainBookData.length)}
                            </span>{' '}
                            of{' '}
                            <span className="font-medium">{mainBookData.length}</span> results
                          </p>
                        </div>
                        <div>
                          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                            <button
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-hazel-green hover:bg-seashell disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                              <span className="sr-only">Previous</span>
                              <ChevronLeft className="h-5 w-5" aria-hidden="true" />
                            </button>
                            
                            {/* Page Numbers */}
                            {Array.from({ length: Math.ceil(mainBookData.length / itemsPerPage) }, (_, i) => i + 1).map((page) => (
                              <button
                                key={page}
                                onClick={() => handlePageChange(page)}
                                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                                  page === currentPage
                                    ? 'z-10 bg-hazel-green text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hazel-green'
                                    : 'text-bluish-grey hover:bg-seashell focus:z-20'
                                }`}
                              >
                                {page}
                              </button>
                            ))}

                            <button
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === Math.ceil(mainBookData.length / itemsPerPage)}
                              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-hazel-green hover:bg-seashell disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                              <span className="sr-only">Next</span>
                              <ChevronRight className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Details Modal */}
        <AnimatePresence>
          {showModal && selectedEntry && (
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="min-h-screen px-4 text-center flex items-center justify-center">
                {/* Overlay */}
                <div 
                  className="fixed inset-0 bg-black/20 backdrop-blur-sm transition-opacity"
                  onClick={() => setShowModal(false)}
                />

                <motion.div
                  initial={{ opacity: 0, scale: 0.95, y: 20 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  exit={{ opacity: 0, scale: 0.95, y: 20 }}
                  transition={{ duration: 0.3, ease: "easeOut" }}
                  className="relative bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all w-full max-w-5xl mx-auto"
                >
                  {/* Modal Header */}
                  <div className="bg-gradient-to-r from-hazel-green/10 to-transparent px-8 py-6 border-b border-mercury/50">
                    <div className="flex items-center justify-between">
                      <h3 className="text-xl font-semibold text-hazel-green flex items-center gap-2">
                        <FileText className="w-6 h-6 opacity-70" />
                        Document Details
                      </h3>
                      <button
                        onClick={() => setShowModal(false)}
                        className="p-2 hover:bg-seashell rounded-lg transition-colors"
                      >
                        <X className="w-6 h-6 text-bluish-grey" />
                      </button>
                    </div>
                  </div>

                  {/* Modal Content */}
                  <div className="px-8 py-6">
                    <div className="space-y-8">
                      {/* Document Info */}
                      <div className="grid grid-cols-2 gap-8">
                        <div className="space-y-2">
                          <label className="text-sm text-bluish-grey">Document Number</label>
                          <p className="text-xl font-medium text-hazel-green">{selectedEntry.documentNumber}</p>
                        </div>
                        <div className="space-y-2">
                          <label className="text-sm text-bluish-grey">Date</label>
                          <p className="text-xl font-medium text-hazel-green">
                            {new Date(selectedEntry.postingDate).toLocaleDateString()}
                          </p>
                        </div>
                      </div>

                      {/* Description */}
                      <div className="space-y-2">
                        <label className="text-sm text-bluish-grey">Description</label>
                        <p className="text-base text-bluish-grey">{selectedEntry.description}</p>
                      </div>

                      {/* Accounts Table */}
                      <div className="mt-6">
                        <table className="min-w-full divide-y divide-mercury/50">
                          <thead>
                            <tr className="bg-seashell/50">
                              <th className="px-4 py-3 text-left text-sm font-medium text-hazel-green">Account</th>
                              <th className="px-4 py-3 text-left text-sm font-medium text-hazel-green">Type</th>
                              <th className="px-4 py-3 text-right text-sm font-medium text-hazel-green">Debit (€)</th>
                              <th className="px-4 py-3 text-right text-sm font-medium text-hazel-green">Credit (€)</th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-mercury/50">
                            {selectedEntry.items.map((item) => (
                              <tr key={item.id} className="hover:bg-seashell/30">
                                <td className="px-4 py-3">
                                  <div className="flex flex-col">
                                    <span className="text-sm font-medium text-bluish-grey">{item.accountCode}</span>
                                    <span className="text-sm text-bluish-grey">{item.accountName}</span>
                                  </div>
                                </td>
                                <td className="px-4 py-3">
                                  <span className={`inline-flex px-2.5 py-0.5 rounded-md text-xs font-medium ${getAccountTypeBadgeColor(item.accountClass.toLowerCase())}`}>
                                    {item.accountClass}
                                  </span>
                                </td>
                                <td className="px-4 py-3 text-right text-sm text-bluish-grey">{item.debit.toFixed(2)}</td>
                                <td className="px-4 py-3 text-right text-sm text-bluish-grey">{item.credit.toFixed(2)}</td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr className="bg-seashell/50 font-medium">
                              <td colSpan={2} className="px-4 py-3 text-right text-sm text-hazel-green">Total:</td>
                              <td className="px-4 py-3 text-right text-sm text-hazel-green">{selectedEntry.totalDebit.toFixed(2)} €</td>
                              <td className="px-4 py-3 text-right text-sm text-hazel-green">{selectedEntry.totalCredit.toFixed(2)} €</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* Modal Footer */}
                  <div className="bg-seashell/30 px-8 py-6 border-t border-mercury/50">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-3">
                        {selectedEntry.referenceType && selectedEntry.referenceId && (
                          <button
                            onClick={() => handleReferenceClick(selectedEntry.referenceType!, selectedEntry.referenceId!)}
                            className="inline-flex items-center px-4 py-2 bg-white text-hazel-green rounded-lg hover:bg-seashell transition-colors duration-200 gap-2 font-medium border border-mercury/50 shadow-sm"
                          >
                            <Link className="w-4 h-4" />
                            <span>View {selectedEntry.referenceType === 'sale' ? 'Sale' : 'Purchase'}</span>
                          </button>
                        )}
                      </div>
                      <div className="flex items-center gap-3">
                        <button
                          onClick={() => handleExportDetails(selectedEntry)}
                          className="inline-flex items-center px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-sage transition-colors duration-200 gap-2 font-medium shadow-sm"
                        >
                          <Download className="w-4 h-4" />
                          <span>Export Details</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default MainBookPage;
