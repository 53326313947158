// import React from 'react';
// import { Formik } from 'formik';
// import * as Yup from 'yup';
// import { useNavigate } from 'react-router-dom';
// import CustomInput from '../../components/custom-input';
// import { createGroup } from '../../api/chat'; // Ensure this is the correct path
// import { SubmitButton, CancelButton } from '../../components/buttons';

// const GroupCreationForm = ({ onGroupCreated }) => {
//   const navigate = useNavigate();

//   const validationSchema = Yup.object().shape({
//     name: Yup.string()
//       .min(3, 'Group name must be at least 3 characters')
//       .required('Group name is required*'),
//   });

//   return (
//     <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
//       <Formik
//         initialValues={{ name: '' }}
//         validationSchema={validationSchema}
//         onSubmit={async (values, { setErrors, setSubmitting }) => {
//           try {
//             const response = await createGroup(values.name);
//             console.log("RESPONSE" ,response.data);
//             onGroupCreated(response.data); // Make sure response.data is correct
//             navigate('/some-path'); // Redirect after group creation
//           } catch (err) {
//             console.error('Error creating group:', err); // Log error for debugging
//             setErrors({ submit: err?.response?.data?.message || 'Error creating group' });
//           } finally {
//             setSubmitting(false);
//           }
//         }}
//       >
//         {({
//           errors,
//           values,
//           touched,
//           isSubmitting,
//           handleBlur,
//           handleChange,
//           handleSubmit
//         }) => (
//           <form onSubmit={handleSubmit} className='px-7 py-5'>
//             <div className='grid gap-y-3'>
//               <CustomInput
//                 type='text'
//                 name='name'
//                 label='Group Name'
//                 value={values.name}
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//                 showError={errors.name && touched.name}
//                 errorMessage={errors.name}
//               />
//               {errors.submit && <p className='text-red-500'>{errors.submit}</p>}
//             </div>
//             <div className='flex justify-end items-center w-full mt-4 text-sm gap-2'>
//               <CancelButton onCancel={() => navigate('/some-path')} />
//               <SubmitButton
//                 isSubmitting={isSubmitting}
//                 width='w-1/2 md:w-1/6'
//                 title='Create Group'
//               />
//             </div>
//           </form>
//         )}
//       </Formik>
//     </div>
//   );
// };

// export default GroupCreationForm;


import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import CustomInput from '../../components/custom-input';
import { createGroup } from '../../api/chat'; // Ensure this is the correct path
import { SubmitButton, CancelButton } from '../../components/buttons';
import { useSelector } from 'react-redux';

const GroupCreationForm = ({ onGroupCreated }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Group name must be at least 3 characters')
      .required('Group name is required*'),
  });

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
      <Formik
        initialValues={{ name: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            const response = await createGroup(values.name, user.id);
            console.log("RESPONSE", response.data);
            
            // Check if onGroupCreated is a function
            if (typeof onGroupCreated === 'function') {
              onGroupCreated(response.data); // Call the function if it exists
            } else {
              console.error('onGroupCreated is not a function');
            }
            
            navigate('/some-path'); // Redirect after group creation
          } catch (err) {
            console.error('Error creating group:', err); // Log error for debugging
            setErrors({ submit: err?.response?.data?.message || 'Error creating group' });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit} className='px-7 py-5'>
            <div className='grid gap-y-3'>
              <CustomInput
                type='text'
                name='name'
                label='Group Name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.name && touched.name}
                errorMessage={errors.name}
              />
              {errors.submit && <p className='text-red-500'>{errors.submit}</p>}
            </div>
            <div className='flex justify-end items-center w-full mt-4 text-sm gap-2'>
              <CancelButton onCancel={() => navigate('/some-path')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/2 md:w-1/6'
                title='Create Group'
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default GroupCreationForm;

