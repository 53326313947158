import React from 'react';
import { LucideIcon } from 'lucide-react';

interface StatCardProps {
  icon: LucideIcon;
  value: number | string;
  label: string;
  loading?: boolean;
}

const StatCard = ({ icon: Icon, value, label, loading = false }: StatCardProps) => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="p-2 bg-dawn-pink rounded-full">
          <Icon className="h-6 w-6 text-watermelon" />
        </div>
      </div>
      <div>
        {loading ? (
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-16 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-24"></div>
          </div>
        ) : (
          <>
            <p className="text-2xl font-semibold text-bluish-grey mb-1">{value}</p>
            <p className="text-sage text-sm">{label}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default StatCard;