import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import BarOrderForm from './BarOrderForm';
import { useNavigate, useParams } from 'react-router-dom';


function AddBarOrderForm() {
  const { tableId } = useParams();
  return (
    <div className='p-10'>
      <PageTitle
        icon={

        <FontAwesomeIcon
            icon={faBuilding}
            className='text-hazel-green mr-2'
          />        }
        title='Order'
      />
      <BarOrderForm
        isEdit={false}
        tableId={tableId}
      />
    </div>
  );
}

export default AddBarOrderForm;
