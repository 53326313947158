import api from "./customAPI";

// Fetch all bar products
export const getBarProducts = () => api.get('/api/barProduct/bar-items');

export const getPaginatedBarOrders = (page = 1, limit = 10) => 
    api.get(`/api/barProduct/barOrders?page=${page}&limit=${limit}`);



// Fetch a single bar product by ID
export const getBarProductById = (id) => api.get(`/api/barProduct/bar-products/${id}`);


export const createBarProduct = (values) => api.post('/api/barProduct/bar-products', values);

// Update an existing bar product
export const updateBarProduct = (id, values) => api.patch(`/api/barProduct/bar-products/${id}`, values);

// Delete a bar product
export const deleteBarProduct = (id) => api.delete(`/api/barProduct/bar-products/${id}`);


// export const getDrinks = () => api.get('/api/barProduct/bar-products/drinks');
export const getDrinks = () => api.get('/api/barProduct/drinks');


//apis for the orders of the bar
// export const createBarOrder = (data) => api.post('/api/barProduct/', data);
export const createBarOrder = (data) => {
    console.log('Creating bar order with data:', data); // Debug log
    return api.post('/api/barProduct', data); // Note: using root endpoint
  };
export const getBarOrders = () => api.get('/api/barProduct/orders');
export const getBarOrderById = (id) => api.get(`/api/barProduct/bar-orders/${id}`);
export const updateBarOrder = (id, data) => api.put(`/api/barProduct/bar-orders/${id}`, data);
export const deleteBarOrder = (id) => api.delete(`/api/barProduct/bar-orders/${id}`);
export const completeBarOrder = (id, data) => api.post(`/api/barProduct/bar-orders/${id}/complete`, data);