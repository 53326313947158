import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../../../components/page-title';
import SupplierForm from './SupplierForm';

function AddSupplier() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faBuilding}
            className='text-hazel-green mr-2'
          />
        }
        title='Create a new supplier'
      />
      <SupplierForm
        isEdit={false}
        supplier={{
          name: '',
          email: '',
          phone: '',
          address: '',
          representative: '',
          ubin: ''
        }}
      />
    </div>
  );
}

export default AddSupplier;
