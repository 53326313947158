import api from "./api";

// Accounting Classes
export const createAccountingClass = (data) => api.post('/api/accounting-plan/class', data);
export const getAccountingClasses = () => api.get('/api/accounting-plan/class');
export const getAccountingClassByType = (type) => api.get(`/api/accounting-plan/class/type/${type}`);

// Accounting Accounts
export const createAccount = (data) => api.post('/api/accounting-plan/account', data);
export const getAccounts = (filters) => api.get('/api/accounting-plan/account', { params: filters });
export const getAllAccounts = () => api.get('/api/accounting-plan/accounts/all');
export const getAccountById = (id) => api.get(`/api/accounting-plan/account/${id}`);
export const updateAccount = (id, data) => api.patch(`/api/accounting-plan/account/${id}`, data);
export const deleteAccount = (id) => api.delete(`/api/accounting-plan/account/${id}`);
