import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, Settings, Upload, X } from 'lucide-react';
import { motion } from 'framer-motion';
import { getCompany } from '../../../api/company';
import { getClients } from '../../../api/accounting';
import { getNextReturnDocumentNumber, getSaleForReturn, createSaleReturn } from '../../../api/sale';
import { toast } from 'react-toastify';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue, SelectGroup, SelectLabel } from '../../../components/shadCN/Select.tsx';
import { Label } from '../../../components/shadCN/Label.tsx';
import { Button } from '../../../components/shadCN/Button.tsx';
import ClientModal from '../../../components/ClientModal';

interface ReturnProduct {
  id: string;
  name: string;
  unit: string;
  quantity: number;
  priceWithoutVAT: number;
  vatRate: number;
  vatAmount: number;
  totalWithVAT: number;
  reason: string;
  originalProductId?: string;
  articleId?: string;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Client {
  id: string;
  name: string;
  fiscalNumber: string;
  address?: string;
  email?: string;
  phone?: string;
}

const ReturnProductSalePage = () => {
  const navigate = useNavigate();
  const [documentNumber, setDocumentNumber] = useState('');
  const [issueDate, setIssueDate] = useState(new Date().toISOString().split('T')[0]);
  const [originalSaleDocument, setOriginalSaleDocument] = useState('');
  const [selectedBuyer, setSelectedBuyer] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [description, setDescription] = useState('');
  const [returnProducts, setReturnProducts] = useState<ReturnProduct[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [isLoadingSale, setIsLoadingSale] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sale, setSale] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [companyRes, clientsRes, docNumberRes] = await Promise.all([
          getCompany(),
          getClients(),
          getNextReturnDocumentNumber()
        ]);
        
        // Get locations from company response
        const companyLocations = companyRes?.data?.message?.locations || [];
        setLocations(Array.isArray(companyLocations) ? companyLocations : []);
        setClients(Array.isArray(clientsRes.data) ? clientsRes.data : []);

        // Set document number
        if (docNumberRes?.data?.message?.documentNumber) {
          setDocumentNumber(docNumberRes.data.message.documentNumber);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to load initial data');
        setLocations([]);
        setClients([]);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleClientSelect = (clientId: string) => {
    setSelectedBuyer(clientId);
    const selectedClient = clients.find(client => client.id === clientId);
    console.log('Selected client:', selectedClient);
  };

  const handleClientSuccess = async (newClient: any) => {
    setIsClientModalOpen(false);
    // Refresh clients list
    try {
      const clientsRes = await getClients();
      setClients(Array.isArray(clientsRes.data) ? clientsRes.data : []);
      // Select the newly created client
      setSelectedBuyer(newClient.id);
      toast.success('Klienti u shtua me sukses');
    } catch (error) {
      console.error('Error refreshing clients:', error);
      toast.error('Failed to refresh clients list');
    }
  };

  // Totals calculation
  const totalWithoutVAT = returnProducts.reduce((sum, product) => sum + product.priceWithoutVAT * product.quantity, 0);
  const totalVAT = returnProducts.reduce((sum, product) => sum + product.vatAmount, 0);
  const totalWithVAT = returnProducts.reduce((sum, product) => sum + product.totalWithVAT, 0);

  const handleAddProduct = () => {
    const newProduct: ReturnProduct = {
      id: Date.now().toString(),
      name: '',
      unit: '',
      quantity: 0,
      priceWithoutVAT: 0,
      vatRate: 0,
      vatAmount: 0,
      totalWithVAT: 0,
      reason: '',
    };
    setReturnProducts([...returnProducts, newProduct]);
  };

  const handleProductChange = (id: string, field: keyof ReturnProduct, value: any) => {
    setReturnProducts(prevProducts => 
      prevProducts.map(product => {
        if (product.id === id) {
          const updatedProduct = { ...product, [field]: value };
          
          // Recalculate totals when quantity changes
          if (field === 'quantity') {
            const quantity = Number(value) || 0;
            const priceWithoutVAT = Number(product.priceWithoutVAT) || 0;
            const vatRate = Number(product.vatRate) || 0;
            
            const subtotal = quantity * priceWithoutVAT;
            const vatAmount = (subtotal * vatRate) / 100;
            
            updatedProduct.vatAmount = Number(vatAmount.toFixed(2));
            updatedProduct.totalWithVAT = Number((subtotal + vatAmount).toFixed(2));
          }
          
          return updatedProduct;
        }
        return product;
      })
    );
  };

  const handleRemoveProduct = (id: string) => {
    setReturnProducts(returnProducts.filter(product => product.id !== id));
  };

  const handleSaleDocumentSearch = async () => {
    if (!originalSaleDocument) {
      toast.error('Please enter a sale document number');
      return;
    }

    setIsLoadingSale(true);
    try {
      const response = await getSaleForReturn(originalSaleDocument);
      console.log('Sale search response:', response);

      if (!response?.message) {
        toast.error('Invalid response from server');
        return;
      }

      const saleData = response.message;
      if (!saleData.items || saleData.items.length === 0) {
        toast.error('No products found in this sale');
        return;
      }

      // Map the products with remaining quantities
      const products = saleData.items
        .filter(item => (item.remainingQuantity || 0) > 0)
        .map(item => ({
          id: crypto.randomUUID(),
          originalProductId: item.id,
          articleId: item.articleId,
          articleName: item.article?.name || 'Unknown Article',
          quantity: 0,
          remainingQuantity: item.remainingQuantity,
          priceWithoutVAT: item.priceWithoutVAT,
          vatRate: item.vatRate,
          vatAmount: 0,
          totalWithVAT: 0,
          unit: item.article?.unit || { name: 'N/A' }
        }));

      setReturnProducts(products);
      setSelectedBuyer(saleData.clientId);
      setSelectedLocation(saleData.locationId);
      setDescription(saleData.description || '');
      setSale(saleData);
      toast.success('Sale details loaded successfully');
    } catch (error: any) {
      console.error('Error fetching sale details:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch sale details';
      toast.error(errorMessage);
      setReturnProducts([]);
      setSelectedBuyer('');
      setSelectedLocation('');
      setDescription('');
    } finally {
      setIsLoadingSale(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!sale || returnProducts.length === 0) return;

    setIsSubmitting(true);
    try {
      const data = {
        documentNumber: documentNumber,
        originalSaleDocumentNumber: originalSaleDocument, // Use document number instead of ID
        returnDate: new Date(issueDate).toISOString(),
        reason: description,
        items: returnProducts.map(product => ({
          saleItemId: product.originalProductId || '',
          quantity: product.quantity
        }))
      };

      console.log('Submitting return data:', data);
      await createSaleReturn(data);
      toast.success('Product return created successfully');
      navigate('/accounting/sales');
    } catch (error) {
      console.error('Error creating product return:', error);
      toast.error('Failed to create product return');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/accounting/sales')}
                className="inline-flex items-center gap-x-2 text-bluish-grey hover:text-hazel-green transition-colors"
              >
                <ArrowLeft className="h-5 w-5" />
                Kthehu
              </button>
            </div>
            <h1 className="text-lg font-semibold text-hazel-green">Kthim Produkti</h1>
            <div className="flex items-center gap-x-4">
              <button 
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm disabled:opacity-50"
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Document Details */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Detajet për Kthim Produkti</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i Dokumentit
                  </label>
                  <input
                    type="text"
                    value={documentNumber}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Data e kthimit
                  </label>
                  <input
                    type="date"
                    value={issueDate}
                    onChange={(e) => setIssueDate(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                </div>

                <div className="col-span-2">
                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={originalSaleDocument}
                      onChange={(e) => setOriginalSaleDocument(e.target.value)}
                      placeholder="Enter sale document number"
                      className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                    />
                    <button
                      type="button"
                      onClick={handleSaleDocumentSearch}
                      disabled={isLoadingSale}
                      className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm disabled:opacity-50"
                    >
                      {isLoadingSale ? 'Loading...' : 'Search'}
                    </button>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Dokumenti i shitjes
                  </label>
                  <select
                    value={originalSaleDocument}
                    onChange={(e) => setOriginalSaleDocument(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  >
                    <option value="">Zgjidhni dokumentin e shitjes</option>
                    {/* Add sale documents here */}
                  </select>
                </div>

                <div className="relative mb-4">
                  <label htmlFor="buyer" className="mb-2 block text-sm font-medium text-slate-600">
                    Blerësi
                  </label>
                  <div className="flex gap-2 items-start">
                    <div className="flex-1">
                      <Select
                        value={selectedBuyer}
                        onValueChange={handleClientSelect}
                        disabled={isLoading}
                      >
                        <SelectTrigger 
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                          disabled={isLoading}
                        >
                          <SelectValue 
                            placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni blerësin"}
                            className="text-bluish-grey"
                          >
                            {selectedBuyer && (
                              <span className="text-hazel-green">
                                {clients.find(c => c.id === selectedBuyer)?.name}
                              </span>
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent 
                          className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                          sideOffset={5}
                          position="popper"
                          align="start"
                        >
                          <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                            <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                              Zgjidhni Blerësin
                            </h3>
                          </div>
                          {clients.map(client => (
                            <SelectItem
                              key={client.id}
                              value={client.id}
                              textValue={client.name}
                              className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                            >
                              {/* Hidden span for the select field display */}
                              <span className="hidden">{client.name}</span>
                              
                              {/* Detailed content for dropdown */}
                              <div className="flex flex-col gap-1">
                                <div className="flex flex-col">
                                  <span className="text-sm sm:text-base font-medium text-hazel-green">
                                    {client.name}
                                  </span>
                                  {client.fiscalNumber && (
                                    <span className="text-xs sm:text-sm text-bluish-grey">
                                      Nr. Fiskal: {client.fiscalNumber}
                                    </span>
                                  )}
                                </div>
                                {client.address && (
                                  <div className="flex items-center gap-1 text-bluish-grey">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                    </svg>
                                    <span>{client.address}</span>
                                  </div>
                                )}
                                {client.phone && (
                                  <div className="flex items-center gap-1 text-bluish-grey">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                    </svg>
                                    <span>{client.phone}</span>
                                  </div>
                                )}
                                {client.email && (
                                  <div className="flex items-center gap-1 text-bluish-grey">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                    </svg>
                                    <span>{client.email}</span>
                                  </div>
                                )}
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <Button
                      type="button"
                      variant="outline"
                      size="icon"
                      onClick={() => setIsClientModalOpen(true)}
                      className="flex-shrink-0 h-10 w-10 rounded-lg hover:bg-gray-100"
                    >
                      <Plus className="h-5 w-5" />
                    </Button>
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="location" className="mb-2 block text-sm font-medium text-slate-600">
                    Lokacioni
                  </label>
                  <Select
                    value={selectedLocation}
                    onValueChange={setSelectedLocation}
                    disabled={isLoading}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                      disabled={isLoading}
                    >
                      <SelectValue 
                        placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni lokacionin"}
                        className="text-bluish-grey"
                      >
                        {selectedLocation && (
                          <span className="text-hazel-green">
                            {locations.find(l => l.id === selectedLocation)?.name}
                          </span>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                      sideOffset={5}
                      position="popper"
                      align="start"
                    >
                      <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                        <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                          Zgjidhni Lokacionin
                        </h3>
                      </div>
                      {locations.map(location => (
                        <SelectItem
                          key={location.id}
                          value={location.id}
                          textValue={location.name}
                          className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm sm:text-base font-medium text-hazel-green">
                              {location.name}
                            </span>
                            {location.address && (
                              <span className="text-xs sm:text-sm text-bluish-grey">
                                {location.address}
                              </span>
                            )}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="sm:col-span-2">
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Përshkrimi
                  </label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={3}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                    placeholder="Shtoni një përshkrim për këtë kthim..."
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Return Products */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4 flex justify-between items-center">
              <h2 className="text-lg font-semibold text-hazel-green">Produktet për Kthim</h2>
              <button
                type="button"
                onClick={handleAddProduct}
                className="inline-flex items-center gap-x-2 px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                         hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
              >
                <Plus className="h-5 w-5" />
                Shto Produkt
              </button>
            </div>
            <div className="p-6">
              <div className="space-y-4">
                {returnProducts.length === 0 ? (
                  <div className="flex flex-col items-center justify-center py-8">
                    <div className="w-16 h-16 bg-gradient-to-br from-watermelon/20 to-error/10 rounded-lg flex items-center justify-center mb-4">
                      <ArrowLeft className="h-8 w-8 text-watermelon" />
                    </div>
                    <h3 className="text-base font-medium text-bluish-grey mb-1">Nuk ka produkte për kthim</h3>
                    <p className="text-sm text-grey-goose mb-4">Shtoni produkte për të vazhduar me kthimin</p>
                    <button
                      type="button"
                      onClick={handleAddProduct}
                      className="inline-flex items-center gap-x-2 px-4 py-2 bg-gradient-to-r from-watermelon to-error text-white rounded-lg 
                               hover:from-error hover:to-watermelon transition-all duration-300 shadow-sm"
                    >
                      <Plus className="h-5 w-5" />
                      Shto Produkt për Kthim
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="grid grid-cols-12 gap-4 items-center mb-2 text-sm font-medium text-bluish-grey">
                      <div className="col-span-2">Emri i produktit</div>
                      <div className="col-span-1">Njësia</div>
                      <div className="col-span-1">Sasia</div>
                      <div className="col-span-2">Çmimi pa TVSH</div>
                      <div className="col-span-1">TVSH %</div>
                      <div className="col-span-1">TVSH</div>
                      <div className="col-span-2">Totali me TVSH</div>
                      <div className="col-span-1">Arsyeja</div>
                      <div className="col-span-1"></div>
                    </div>
                    {returnProducts.map(product => (
                      <div key={product.id} className="grid grid-cols-12 gap-4 items-center py-2 border-b border-mercury last:border-b-0">
                        <div className="col-span-2">
                          <input
                            type="text"
                            value={product.name}
                            onChange={(e) => handleProductChange(product.id, 'name', e.target.value)}
                            placeholder="Emri"
                            className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                          />
                        </div>
                        <div className="col-span-1">
                          <input
                            type="text"
                            value={product.unit}
                            readOnly
                            placeholder="Njësia"
                            className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                          />
                        </div>
                        <div className="col-span-1">
                          <input
                            type="number"
                            value={product.quantity}
                            onChange={(e) => handleProductChange(product.id, 'quantity', parseFloat(e.target.value))}
                            placeholder="Sasia"
                            className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                          />
                        </div>
                        <div className="col-span-2">
                          <input
                            type="number"
                            value={product.priceWithoutVAT}
                            readOnly
                            placeholder="Çmimi pa TVSH"
                            className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                          />
                        </div>
                        <div className="col-span-1">
                          <input
                            type="number"
                            value={product.vatRate}
                            readOnly
                            placeholder="TVSH %"
                            className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                          />
                        </div>
                        <div className="col-span-1">
                          <input
                            type="number"
                            value={product.vatAmount}
                            readOnly
                            placeholder="TVSH"
                            className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                          />
                        </div>
                        <div className="col-span-2">
                          <input
                            type="number"
                            value={product.totalWithVAT}
                            readOnly
                            placeholder="Totali"
                            className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                          />
                        </div>
                        <div className="col-span-1">
                          <input
                            type="text"
                            value={product.reason}
                            onChange={(e) => handleProductChange(product.id, 'reason', e.target.value)}
                            placeholder="Arsyeja"
                            className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                          />
                        </div>
                        <div className="col-span-1">
                          <button
                            type="button"
                            onClick={() => handleRemoveProduct(product.id)}
                            className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-watermelon/10 text-watermelon hover:bg-watermelon/20 transition-colors"
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Totals */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Totalet</h2>
            </div>
            <div className="p-6">
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-bluish-grey">Totali pa TVSH:</span>
                  <span className="font-medium">{totalWithoutVAT.toFixed(2)} €</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-bluish-grey">TVSH:</span>
                  <span className="font-medium">{totalVAT.toFixed(2)} €</span>
                </div>
                <div className="flex justify-between items-center text-lg font-semibold text-hazel-green">
                  <span>Totali me TVSH:</span>
                  <span>{totalWithVAT.toFixed(2)} €</span>
                </div>
              </div>
            </div>
          </div>

          {/* Attachments */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Bashkëngjitjet</h2>
            </div>
            <div className="p-6">
              <div className="flex items-center justify-center w-full">
                <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-mercury border-dashed rounded-lg cursor-pointer bg-seashell/30 hover:bg-seashell/50">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <Upload className="w-8 h-8 mb-3 text-bluish-grey" />
                    <p className="mb-2 text-sm text-bluish-grey">
                      <span className="font-medium">Kliko për të ngarkuar</span> ose tërhiq dhe lësho
                    </p>
                    <p className="text-xs text-grey-goose">PDF, PNG, JPG deri në 10MB</p>
                  </div>
                  <input type="file" className="hidden" multiple accept=".pdf,.png,.jpg,.jpeg" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Client Modal */}
      <ClientModal
        isOpen={isClientModalOpen}
        onClose={() => setIsClientModalOpen(false)}
        onSuccess={handleClientSuccess}
      />
    </div>
  );
};

export default ReturnProductSalePage;
