import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, FileText, Calendar, MapPin, DollarSign } from 'lucide-react';
import { format } from 'date-fns';

interface JournalEntry {
  id: string;
  documentNumber: string;
  postingDate: string;
  description?: string;
  location?: {
    id: string;
    name: string;
  };
  totalDebit: number;
  totalCredit: number;
  items: Array<{
    id: string;
    description?: string;
    debitAmount: number;
    creditAmount: number;
    account?: {
      id: string;
      code: string;
      name: string;
    };
  }>;
}

interface ViewJournalEntryModalProps {
  isOpen: boolean;
  onClose: () => void;
  entry: JournalEntry | null;
}

const ViewJournalEntryModal: React.FC<ViewJournalEntryModalProps> = ({ isOpen, onClose, entry }) => {
  if (!isOpen || !entry) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
      <div className="flex min-h-full items-center justify-center p-4">
        <motion.div
          initial={{ opacity: 0, scale: 0.95, y: 20 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.95, y: 20 }}
          transition={{ type: "spring", duration: 0.5 }}
          className="relative w-full max-w-2xl bg-white rounded-xl shadow-xl overflow-hidden"
        >
          {/* Header */}
          <div className="relative p-6 bg-gradient-to-r from-sage/10 to-transparent border-b border-mercury/40">
            <button
              onClick={onClose}
              className="absolute right-4 top-4 p-2 rounded-lg text-grey-goose 
                       hover:text-hazel-green hover:bg-sage/10 transition-all"
            >
              <X className="h-5 w-5" />
            </button>
            <h2 className="text-xl font-semibold text-bluish-grey">Detajet e Regjistrimit</h2>
            <p className="text-grey-goose mt-1">Shiko të dhënat e plota të regjistrimit në ditar</p>
          </div>

          {/* Content */}
          <div className="p-6 max-h-[calc(100vh-200px)] overflow-y-auto">
            {/* Main Info */}
            <div className="grid grid-cols-2 gap-6 mb-8">
              <div>
                <div className="flex items-center gap-2 text-grey-goose mb-2">
                  <FileText className="h-4 w-4" />
                  <span className="text-sm">Numri i Dokumentit</span>
                </div>
                <p className="text-lg font-medium text-bluish-grey">{entry.documentNumber}</p>
              </div>

              <div>
                <div className="flex items-center gap-2 text-grey-goose mb-2">
                  <Calendar className="h-4 w-4" />
                  <span className="text-sm">Data e Postimit</span>
                </div>
                <p className="text-lg font-medium text-bluish-grey">
                  {format(new Date(entry.postingDate), 'dd/MM/yyyy')}
                </p>
              </div>

              {entry.location && (
                <div>
                  <div className="flex items-center gap-2 text-grey-goose mb-2">
                    <MapPin className="h-4 w-4" />
                    <span className="text-sm">Vendndodhja</span>
                  </div>
                  <p className="text-lg font-medium text-bluish-grey">{entry.location.name}</p>
                </div>
              )}

              <div>
                <div className="flex items-center gap-2 text-grey-goose mb-2">
                  <DollarSign className="h-4 w-4" />
                  <span className="text-sm">Totali</span>
                </div>
                <p className="text-lg font-medium text-bluish-grey">
                  {entry.totalDebit.toFixed(2)}
                </p>
              </div>
            </div>

            {entry.description && (
              <div className="mb-8">
                <h3 className="text-sm font-medium text-grey-goose mb-2">Përshkrimi</h3>
                <p className="text-bluish-grey">{entry.description}</p>
              </div>
            )}

            {/* Items Table */}
            <div>
              <h3 className="text-sm font-medium text-grey-goose mb-4">Artikujt</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-mercury/40">
                  <thead>
                    <tr className="bg-sage/5">
                      <th className="px-4 py-3 text-left text-xs font-medium text-grey-goose">Llogaria</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-grey-goose">Përshkrimi</th>
                      <th className="px-4 py-3 text-right text-xs font-medium text-grey-goose">Debit</th>
                      <th className="px-4 py-3 text-right text-xs font-medium text-grey-goose">Kredit</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-mercury/40">
                    {entry.items.map((item) => (
                      <tr key={item.id} className="hover:bg-sage/5">
                        <td className="px-4 py-3">
                          <span className="text-sm text-bluish-grey">
                            {item.account ? `${item.account.code} - ${item.account.name}` : '-'}
                          </span>
                        </td>
                        <td className="px-4 py-3">
                          <span className="text-sm text-grey-goose">{item.description || '-'}</span>
                        </td>
                        <td className="px-4 py-3 text-right">
                          <span className="text-sm text-bluish-grey">{item.debitAmount.toFixed(2)}</span>
                        </td>
                        <td className="px-4 py-3 text-right">
                          <span className="text-sm text-bluish-grey">{item.creditAmount.toFixed(2)}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="bg-sage/5">
                      <td colSpan={2} className="px-4 py-3 text-sm font-medium text-bluish-grey">
                        Totali
                      </td>
                      <td className="px-4 py-3 text-right text-sm font-medium text-bluish-grey">
                        {entry.totalDebit.toFixed(2)}
                      </td>
                      <td className="px-4 py-3 text-right text-sm font-medium text-bluish-grey">
                        {entry.totalCredit.toFixed(2)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ViewJournalEntryModal;
