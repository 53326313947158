import React, { useState, useEffect, useCallback } from 'react';
import { AddButton } from '../../../../../components/buttons';
import CustomTable from '../../../../../components/table-components/table';
import Loading from '../../../../../components/loader';
import { getAssets, deleteAsset } from '../../../../../api/accounting';
import StatisticCard from '../../../../inTime/StatisticCard';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faBoxes, faTags, faPrint } from '@fortawesome/free-solid-svg-icons';

function AssetsList() {
  const [assetsList, setAssetsList] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [totalValue, setTotalValue] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [uniqueAssetTypes, setUniqueAssetTypes] = useState(0);

  // Fetch the list of assets from the API
  const getAssetsList = useCallback(async () => {
    try {
      const response = await getAssets();

      // Check if the response and required data exist
      const assets = response?.data?.assets || [];
      const totalValue = response?.data?.totalValue || 0;
      const totalQuantity = response?.data?.totalQuantity || 0;
      const uniqueAssetTypes = response?.data?.uniqueAssetTypes || 0;
      
      setAssetsList(assets);
      setTotalCount(assets.length);

      // Set aggregated data
      setTotalValue(totalValue);
      setTotalQuantity(totalQuantity);
      setUniqueAssetTypes(uniqueAssetTypes);

    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, []);

  // Fetch assets on initial render and when dependencies change
  useEffect(() => {
    getAssetsList();
  }, [getAssetsList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  // Handle asset deletion
  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await deleteAsset(id);
      setAssetsList((prevState) => prevState.filter((asset) => asset.id !== id));
      setTotalCount(totalCount - 1);
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  // Prepare the data for the bar chart
  const assetChartData = assetsList.map((asset) => ({
    name: asset.name,
    value: asset.value,
  }));

  return (
    <div className='p-10'>
      {/* Page Title */}
      <h1 className="text-3xl font-semibold text-hazel-green mb-6">Your Assets</h1> {/* Increased margin bottom */}
  
      {/* Statistic Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <StatisticCard
          title="Total Asset Value"
          value={`$${totalValue.toLocaleString()}`}
          Icon={faMoneyBillWave}
        //   bgColor="bg-white"
        //   textColor="text-hazel-green"
            bgColor="bg-hazel-green"  
            textColor="text-white"
        />
        <StatisticCard
          title="Total Quantity of Assets"
          value={totalQuantity}
          Icon={faBoxes}
          bgColor="bg-white"
          textColor="text-hazel-green"
        />
        <StatisticCard
          title="Unique Asset Types"
          value={uniqueAssetTypes}
          Icon={faTags}
          bgColor="bg-white"
          textColor="text-hazel-green"
        />
      </div>
  
      {/* Action Buttons */}
      <div className="flex justify-between items-center mb-6"> {/* Increased margin bottom */}
        <div className="flex items-center space-x-4">
          <AddButton entity='assets' link='/accounting/assets/add-asset' title='New Asset' />
          <AddButton entity='assets' title='Print Assets' />
        </div>
      </div>
  
      {/* Assets Table */}
      <CustomTable
        rows={assetsList}
        columns={[
          {
            title: 'Name',
            renderValue: (item) => item.name,
          },
          {
            title: 'Value',
            renderValue: (item) => `$${item.value.toFixed(2)}`,
          },
          {
            title: 'Date Acquired',
            renderValue: (item) => new Date(item.dateAcquired).toLocaleDateString(),
          },
        ]}
        entity='assets'
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={true}
        editLink='/assets'
        onDelete={handleDelete}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
  
      {/* Assets Bar Graph */}
      <div className="mt-10"> {/* Increased top margin */}
        <h2 className="text-xl font-semibold text-hazel-green mb-4">Assets Value Chart</h2>
        <div className="shadow-lg bg-white rounded-lg p-6 border border-gray-200">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={assetChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#90a68c" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
  
}

export default AssetsList;
