import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FileText, Calendar, ArrowRight, Download, ChevronDown, Loader2, ChevronLeft, ChevronRight } from 'lucide-react';
import { getSalesJournal, exportSalesJournal } from '../../../../api/salesJournal';
import { Resizable } from 're-resizable';
import { Button } from '../../../../components/shadCN/Button.tsx';
import { getCompany } from '../../../../api/company';


// import * as XLSX from 'xlsx';
import XLSX from 'xlsx-js-style';

interface JournalData {
  nr: string;
  date: string;
  invoiceNumber: string;
  buyerName: string;
  buyerFiscalNumber: string;
  buyerVatNumber: string;
  exemptSalesNoCredit: number;
  foreignServiceSales: number;
  domesticReverseChargeSales: number;
  otherExemptSalesWithCredit: number;
  totalExemptSalesWithCredit: number;
  exports: number;
  taxableSales18: number;
  debitNoteIssued18: number;
  badDebtAccepted18: number;
  vatIncreaseAdjustments18: number;
  reverseChargePurchases18: number;
  totalVat18: number;
  taxableSales8: number;
  debitNoteIssued8: number;
  badDebtAccepted8: number;
  vatIncreaseAdjustments8: number;
  totalVat8: number;
  totalDeductibleVat: number;
}

const SalesJournalPage = () => {
  const [showReport, setShowReport] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [selectedMonth, setSelectedMonth] = useState('1');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [journalData, setJournalData] = useState<any>(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [companyData, setCompanyData] = useState<any>(null);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await getCompany();
        console.log("company", response)
        // Access the company data from the response message
        setCompanyData(response.data.message);
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };
    fetchCompanyData();
  }, []);


  const years = Array.from({ length: 5 }, (_, i) => (new Date().getFullYear() - i).toString());
  const months = [
    'Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor',
    'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'
  ];

  useEffect(() => {
    if (showReport) {
      fetchJournalData();
    }
  }, [currentPage]);

  const fetchJournalData = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getSalesJournal(
        parseInt(selectedYear), 
        parseInt(selectedMonth),
        currentPage,
        pageSize
      );
      setJournalData(data);
      setShowReport(true);
    } catch (err: any) {
      setError(err.message || 'Failed to fetch journal data');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateReport = () => {
    fetchJournalData();
  };

  const handleExport = async (format: 'excel' | 'pdf' | 'csv') => {
    try {
      setExportLoading(true);
      const data = await exportSalesJournal(
        parseInt(selectedYear),
        parseInt(selectedMonth),
        format
      );
      // Handle the export data based on format
      console.log('Export successful:', data);
    } catch (err: any) {
      setError(err.message || 'Failed to export journal');
    } finally {
      setExportLoading(false);
    }
  };

  const handleExcelExport = () => {
    try {
      setExportLoading(true);
  
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet([]);
  
      // Modern color palette
      const colors = {
        headerBg: "61805B",     // hazel-green for headers
        headerText: "FFFFFF",    // white for header text
        lightRowBg: "F0F3EF",   // seashell for light rows
        darkRowBg: "E3E7E7",    // mercury for dark rows
        lightRowText: "61805B", // hazel-green for light row text
        darkRowText: "7C8B93",  // bluish-grey for dark row text
        border: "B0C3CC",       // heather for borders
        title: "61805B",        // hazel-green for titles
      };
  
  
      const getRowStyle = (isAltRow = false) => ({
        font: {
          name: "Arial",
          size: 11,
          color: { rgb: isAltRow ? colors.lightRowText : colors.darkRowText },
          bold: false
        },
        alignment: {
          horizontal: "center",
          vertical: "center"
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { rgb: isAltRow ? colors.lightRowBg : colors.darkRowBg }
        },
        border: {
          top: { style: "thin", color: { rgb: colors.border } },
          bottom: { style: "thin", color: { rgb: colors.border } },
          left: { style: "thin", color: { rgb: colors.border } },
          right: { style: "thin", color: { rgb: colors.border } }
        }
      });

      const companyHeaderStyle = {
        font: {
          name: "Arial",
          bold: true,
          size: 14,
          color: { rgb: colors.companyTextColor }
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
          wrapText: true
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { rgb: colors.companyHeaderBg }
        },
        border: {
          top: { style: "thin", color: { rgb: colors.borderColor } },
          bottom: { style: "thin", color: { rgb: colors.borderColor } },
          left: { style: "thin", color: { rgb: colors.borderColor } },
          right: { style: "thin", color: { rgb: colors.borderColor } }
        }
      };

      const companyInfoStyle = {
        font: {
          name: "Arial",
          size: 11,
          color: { rgb: "61805B" }  // hazel-green
        },
        alignment: {
          horizontal: "center",
          vertical: "center"
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { rgb: "F0F3EF" }  // seashell
        },
        border: {
          top: { style: "thin", color: { rgb: "B0C3CC" } },
          bottom: { style: "thin", color: { rgb: "B0C3CC" } },
          left: { style: "thin", color: { rgb: "B0C3CC" } },
          right: { style: "thin", color: { rgb: "B0C3CC" } }
        }
      };
  
      
      const titleStyle = {
        font: {
          name: "Arial",
          bold: true,
          size: 14,
          color: { rgb: "61805B" }  // hazel-green
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
          wrapText: true
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { rgb: "F0F3EF" }  // seashell
        },
        border: {
          top: { style: "thin", color: { rgb: "B0C3CC" } },
          bottom: { style: "thin", color: { rgb: "B0C3CC" } },
          left: { style: "thin", color: { rgb: "B0C3CC" } },
          right: { style: "thin", color: { rgb: "B0C3CC" } }
        }
      };
      
      // Headers styling with increased height and better visibility
      const headerStyle = {
        font: {
          name: "Arial",
          bold: true,
          size: 11,
          color: { rgb: "FFFFFF" }
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
          wrapText: true
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { rgb: colors.headerBg }
        },
        border: {
          top: { style: "thin", color: { rgb: colors.border } },
          bottom: { style: "thin", color: { rgb: colors.border } },
          left: { style: "thin", color: { rgb: colors.border } },
          right: { style: "thin", color: { rgb: colors.border } }
        }
      };
  
      // Company info section
      const companyInfo = [
        [{ 
          v: companyData?.name || 'Kompania',
          s: titleStyle
        }],
        [{ 
          v: `NIPT: ${companyData?.vatNo || '-'} | Nr. Regjistrimi: ${companyData?.registrationNo || '-'}`,
          s: companyInfoStyle
        }],
        [{ 
          v: [
            companyData?.address,
            companyData?.addressLine2,
            companyData?.city,
            companyData?.postalCode,
            companyData?.country
          ].filter(Boolean).join(', ') || 'Adresa: -',
          s: companyInfoStyle
        }],
        [{ 
          v: `Tel: ${companyData?.phone || '-'} | Email: ${companyData?.email || '-'}`,
          s: companyInfoStyle
        }],
        [{ v: '', s: companyInfoStyle }], // Spacing
        [{ 
          v: `LIBRI I SHITJEVE - ${months[parseInt(selectedMonth) - 1].toUpperCase()} ${selectedYear}`,
          s: {
            ...titleStyle,
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { rgb: "90A68C" }  // sage
            },
            font: {
              ...titleStyle.font,
              color: { rgb: "FFFFFF" }  // white text for title
            }
          }
        }],
        [{ v: '', s: companyInfoStyle }] // Spacing
      ];
  
  
      // Headers with better height and visibility
      const headers = [
        [
          { v: 'Nr.', s: headerStyle },
          { v: 'Fatura', s: headerStyle },
          { v: 'Blerësi', s: headerStyle },
          { v: 'Shitjet e liruara nga TVSH', s: headerStyle },
          { v: '', s: headerStyle },
          { v: '', s: headerStyle },
          { v: '', s: headerStyle },
          { v: '', s: headerStyle },
          { v: 'Shitjet e tatueshme me normën 18%', s: headerStyle },
          { v: '', s: headerStyle },
          { v: '', s: headerStyle },
          { v: '', s: headerStyle },
          { v: '', s: headerStyle },
          { v: 'Shitjet e tatueshme me normën 8%', s: headerStyle },
          { v: '', s: headerStyle },
          { v: '', s: headerStyle },
          { v: '', s: headerStyle },
          { v: 'Totali i TVSH-së', s: headerStyle }
        ],
        [
          { v: '', s: headerStyle },
          { v: 'Data', s: headerStyle },
          { v: 'Emri i blerësit', s: headerStyle },
          { v: 'Pa të drejtë kreditimi', s: headerStyle },
          { v: 'Jashtë vendit', s: headerStyle },
          { v: 'Të liruara', s: headerStyle },
          { v: 'Totali', s: headerStyle },
          { v: 'Eksportet', s: headerStyle },
          { v: 'Baza', s: headerStyle },
          { v: 'Nota debitore', s: headerStyle },
          { v: 'Borxhi i keq', s: headerStyle },
          { v: 'Rregullimi', s: headerStyle },
          { v: 'TVSH 18%', s: headerStyle },
          { v: 'Baza', s: headerStyle },
          { v: 'Nota debitore', s: headerStyle },
          { v: 'Borxhi i keq', s: headerStyle },
          { v: 'TVSH 8%', s: headerStyle },
          { v: 'Totali', s: headerStyle }
        ]
      ];
  
      // Transform data with alternating row colors
      const data = journalData?.entries?.map((entry: any, index: number) => {
      const rowStyle = getRowStyle(index % 2 === 0);

  
        return [
          { v: entry.nr, s: rowStyle },
          { v: entry.date, s: rowStyle },
          { v: entry.buyerName, s: rowStyle },
          { v: entry.exemptSalesNoCredit || 0, s: rowStyle },
          { v: entry.foreignServiceSales || 0, s: rowStyle },
          { v: entry.domesticReverseChargeSales || 0, s: rowStyle },
          { v: entry.otherExemptSalesWithCredit || 0, s: rowStyle },
          { v: entry.totalExemptSalesWithCredit || 0, s: rowStyle },
          { v: entry.exports || 0, s: rowStyle },
          { v: entry.taxableSales18 || 0, s: rowStyle },
          { v: entry.debitNoteIssued18 || 0, s: rowStyle },
          { v: entry.badDebtAccepted18 || 0, s: rowStyle },
          { v: entry.vatIncreaseAdjustments18 || 0, s: rowStyle },
          { v: entry.totalVat18 || 0, s: rowStyle },
          { v: entry.taxableSales8 || 0, s: rowStyle },
          { v: entry.debitNoteIssued8 || 0, s: rowStyle },
          { v: entry.badDebtAccepted8 || 0, s: rowStyle },
          { v: entry.vatIncreaseAdjustments8 || 0, s: rowStyle },
          { v: entry.totalVat8 || 0, s: rowStyle }
        ];
      });
  
      // Add totals row with styling
      const totalsRow = [
        { v: 'Totali', s: headerStyle },
        { v: '', s: headerStyle },
        { v: '', s: headerStyle },
        { v: journalData?.totals?.exemptSalesNoCredit || 0, s: headerStyle },
        { v: journalData?.totals?.foreignServiceSales || 0, s: headerStyle },
        { v: journalData?.totals?.domesticReverseChargeSales || 0, s: headerStyle },
        { v: journalData?.totals?.otherExemptSalesWithCredit || 0, s: headerStyle },
        { v: journalData?.totals?.totalExemptSalesWithCredit || 0, s: headerStyle },
        { v: journalData?.totals?.exports || 0, s: headerStyle },
        { v: journalData?.totals?.taxableSales18 || 0, s: headerStyle },
        { v: journalData?.totals?.debitNoteIssued18 || 0, s: headerStyle },
        { v: journalData?.totals?.badDebtAccepted18 || 0, s: headerStyle },
        { v: journalData?.totals?.vatIncreaseAdjustments18 || 0, s: headerStyle },
        { v: journalData?.totals?.reverseChargePurchases18 || 0, s: headerStyle },
        { v: journalData?.totals?.totalVat18 || 0, s: headerStyle },
        { v: journalData?.totals?.taxableSales8 || 0, s: headerStyle },
        { v: journalData?.totals?.debitNoteIssued8 || 0, s: headerStyle },
        { v: journalData?.totals?.badDebtAccepted8 || 0, s: headerStyle },
        { v: journalData?.totals?.vatIncreaseAdjustments8 || 0, s: headerStyle },
        { v: journalData?.totals?.totalVat8 || 0, s: headerStyle },
        { v: journalData?.totals?.totalDeductibleVat || 0, s: headerStyle }
      ];
  
      // Add all content to worksheet
      XLSX.utils.sheet_add_aoa(ws, companyInfo, { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(ws, headers, { origin: 'A8' });
      XLSX.utils.sheet_add_aoa(ws, data, { origin: 'A10' });
      XLSX.utils.sheet_add_aoa(ws, [totalsRow], { origin: `A${10 + (data?.length || 0)}` });
  
      // Set column widths
      ws['!cols'] = [
        { width: 8 },    // Nr
        { width: 15 },   // Data
        { width: 35 },   // Emri i blerësit
        { width: 18 },   // Pa të drejtë kreditimi
        { width: 18 },   // Jashtë vendit
        { width: 18 },   // Të liruara
        { width: 18 },   // Totali
        { width: 18 },   // Eksportet
        { width: 18 },   // Baza 18%
        { width: 18 },   // Nota debitore 18%
        { width: 18 },   // Borxhi i keq 18%
        { width: 18 },   // Rregullimi 18%
        { width: 18 },   // TVSH 18%
        { width: 18 },   // Baza 8%
        { width: 18 },   // Nota debitore 8%
        { width: 18 },   // Borxhi i keq 8%
        { width: 18 },   // TVSH 8%
        { width: 20 }    // Totali
      ];
  
      // Set row heights
      ws['!rows'] = [
        { hpt: 45 },   // Company name
        { hpt: 35 },   // VAT and Registration
        { hpt: 35 },   // Address
        { hpt: 35 },   // Contact
        { hpt: 20 },   // Spacing
        { hpt: 45 },   // Title
        { hpt: 20 },   // Spacing
        { hpt: 50 },   // Header 1
        { hpt: 50 },   // Header 2
        ...Array(data?.length || 0).fill({ hpt: 30 }), // Data rows
        { hpt: 40 }    // Total row
      ];
  
      // Add merged cells
      ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 17 } }, // Company name
        { s: { r: 1, c: 0 }, e: { r: 1, c: 17 } }, // VAT and Registration
        { s: { r: 2, c: 0 }, e: { r: 2, c: 17 } }, // Address
        { s: { r: 3, c: 0 }, e: { r: 3, c: 17 } }, // Contact
        { s: { r: 5, c: 0 }, e: { r: 5, c: 17 } }, // Title
        // Header group merges
        { s: { r: 7, c: 3 }, e: { r: 7, c: 7 } },  // Shitjet e liruara
        { s: { r: 7, c: 8 }, e: { r: 7, c: 12 } }, // Shitjet 18%
        { s: { r: 7, c: 13 }, e: { r: 7, c: 16 } } // Shitjet 8%
      ];

  
      // Add the worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Libri i Shitjeve');
  
      // Generate Excel file
      XLSX.writeFile(wb, `Libri_i_Shitjeve_${selectedYear}_${months[parseInt(selectedMonth) - 1]}.xlsx`);
  
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    } finally {
      setExportLoading(false);
    }
  };


  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header Section */}
      <div className="bg-white/80 backdrop-blur-sm border-b border-mercury sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2 text-sm">
              <span className="text-grey-goose hover:text-bluish-grey transition-colors">Tatimet</span>
              <ChevronDown className="w-4 h-4 text-grey-goose" />
              <span className="font-medium text-hazel-green">Libri i Shitjeve</span>
            </div>
            <div className="text-xs text-grey-goose bg-white px-3 py-1.5 rounded-full shadow-sm">
              Periudha: {months[parseInt(selectedMonth) - 1]} {selectedYear}
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">
        {/* Form Card */}
        <div className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 mb-8 overflow-hidden">
          {/* Form Header */}
          <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-8">
            <div className="max-w-2xl mx-auto text-center">
              <h1 className="text-2xl font-semibold text-bluish-grey mb-3">Libri i Shitjeve</h1>
              <p className="text-grey-goose">
                Gjeneroni raportin e librit të shitjeve duke përzgjedhur periudhën kohore
              </p>
            </div>
          </div>

          {/* Form Content */}
          <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-xl mx-auto mb-8">
              {/* Year Select */}
              <div className="relative group">
                <label className="inline-flex items-center text-sm font-medium text-bluish-grey mb-2 group-hover:text-hazel-green transition-colors">
                  <Calendar className="w-4 h-4 mr-2 opacity-70" />
                  <span>Viti</span>
                </label>
                <div className="relative">
                  <select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    className="w-full p-3 bg-seashell border border-mercury rounded-xl appearance-none text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 hover:border-hazel-green/30 transition-colors"
                  >
                    {years.map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-goose w-4 h-4 pointer-events-none" />
                </div>
              </div>

              {/* Month Select */}
              <div className="relative group">
                <label className="inline-flex items-center text-sm font-medium text-bluish-grey mb-2 group-hover:text-hazel-green transition-colors">
                  <Calendar className="w-4 h-4 mr-2 opacity-70" />
                  <span>Muaji</span>
                </label>
                <div className="relative">
                  <select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    className="w-full p-3 bg-seashell border border-mercury rounded-xl appearance-none text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 hover:border-hazel-green/30 transition-colors"
                  >
                    {months.map((month, index) => (
                      <option key={month} value={index + 1}>{month}</option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-goose w-4 h-4 pointer-events-none" />
                </div>
              </div>
            </div>

            {/* Generate Button */}
            <div className="flex justify-center">
              <button
                onClick={handleGenerateReport}
                className="group px-6 py-3 bg-gradient-to-r from-hazel-green to-sage text-white rounded-xl hover:from-sage hover:to-hazel-green transition-all duration-300 flex items-center space-x-3 shadow-lg shadow-hazel-green/20 hover:shadow-hazel-green/30"
              >
                <FileText className="w-5 h-5" />
                <span>Gjenero Raportin</span>
                <ArrowRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
              </button>
            </div>
          </div>
        </div>

        {/* Report Section */}
        <AnimatePresence mode="wait">
          {showReport && (
            <motion.div
              key="report"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 overflow-hidden"
            >
              {/* Report Header */}
              <div className="bg-gradient-to-r from-hazel-green/10 to-transparent p-6 border-b border-mercury/50 flex justify-between items-center">
                <h2 className="text-lg font-semibold text-bluish-grey">
                  Libri i Shitjeve - {months[parseInt(selectedMonth) - 1]} {selectedYear}
                </h2>
                <button className="inline-flex items-center px-4 py-2 bg-seashell text-hazel-green rounded-lg hover:bg-mercury transition-colors duration-200 gap-2">
                  <Download className="w-4 h-4" />
                  <span>Eksporto</span>
                </button>
              </div>

              {/* Report Content */}
              {loading ? (
                <div className="p-8 flex justify-center">
                  <Loader2 className="w-8 h-8 animate-spin text-hazel-green" />
                </div>
              ) : error ? (
                <div className="p-8 text-center text-red-500">
                  {error}
                </div>
              ) : journalData ? (
                <div className="p-8">
                  {/* Journal Table */}
                  <div className="mt-8">
                    <div className="overflow-x-auto rounded-lg border border-mercury">
                      <div className="min-w-full inline-block align-middle">
                        <div className="overflow-hidden">
                          <div className="min-w-full overflow-x-auto shadow-sm relative" style={{ maxHeight: 'calc(100vh - 300px)' }}>
                            <table className="min-w-full divide-y divide-mercury table-fixed">
                              <thead className="sticky top-0 bg-white/95 backdrop-blur-sm z-10">
                                <tr className="transition-colors">
                                  {/* Fixed columns with enhanced shadow */}
                                  <th className="sticky left-0 bg-white/95 backdrop-blur-sm p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury z-20 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                    <Resizable
                                      defaultSize={{ width: 70, height: '100%' }}
                                      minWidth={70}
                                      maxWidth={200}
                                      enable={{ right: true }}
                                    >
                                      <div>Nr.</div>
                                    </Resizable>
                                  </th>
                                  <th className="sticky left-[70px] bg-white/95 backdrop-blur-sm p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury z-20 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                    <Resizable
                                      defaultSize={{ width: 120, height: '100%' }}
                                      minWidth={120}
                                      maxWidth={250}
                                      enable={{ right: true }}
                                    >
                                      <div>Data</div>
                                    </Resizable>
                                  </th>
                                  <th className="sticky left-[200px] bg-white/95 backdrop-blur-sm p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury z-20 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Numri i faturës</div>
                                    </Resizable>
                                  </th>
                                  <th className="sticky left-[350px] bg-white/95 backdrop-blur-sm p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury z-20 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                    <Resizable
                                      defaultSize={{ width: 200, height: '100%' }}
                                      minWidth={200}
                                      maxWidth={350}
                                      enable={{ right: true }}
                                    >
                                      <div>Emri i blerësit</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Numri Fiskal i blerësit</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Numri i TVSH-së së blerësit</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Shitjet e liruara pa të drejtë kreditimi</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Shitjet e shërbimeve jashtë vendit</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Shitjet brenda vendit me ngarkesë të kundërt të TVSH-së</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Shitjet tjera të liruara me të drejtë kreditimi</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Totali i shitjeve të liruara me të drejtë kreditimi</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Eksportet</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Shitjet e tatueshme (18%)</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Nota debitore e lëshuar, nota kreditore e pranuar (18%)</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Fatura e borxhit të keq e pranuar (18%)</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Rregullimet për të rritur TVSH-në (18%)</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Blerjet që i nënshtrohen ngarkesës së kundërt (18%)</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Totali i TVSH-së së llogaritur me normën 18%</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Shitjet e tatueshme (8%)</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Nota debitore e lëshuar, nota kreditore e pranuar (8%)</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Fatura e borxhit të keq e pranuar (8%)</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Rregullimet për të rritur TVSH-në / Ngarkesa e kundërt për Energjinë me normë 8%</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Totali i TVSH-së së llogaritur me normën 8%</div>
                                    </Resizable>
                                  </th>
                                  <th className="p-4 text-right text-sm font-semibold text-bluish-grey border-b border-mercury">
                                    <Resizable
                                      defaultSize={{ width: 150, height: '100%' }}
                                      minWidth={150}
                                      maxWidth={300}
                                      enable={{ right: true }}
                                    >
                                      <div>Totali i TVSH-së së zbritshme me 18% dhe 8%</div>
                                    </Resizable>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-mercury bg-white">
                                {journalData?.entries?.map((entry: any) => (
                                  <tr key={entry.nr} className="hover:bg-seashell/50 transition-colors duration-150">
                                    {/* Fixed columns with enhanced shadow */}
                                    <td className="sticky left-0 bg-white p-4 text-sm text-bluish-grey border-b border-mercury whitespace-nowrap z-10 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                      {entry.nr}
                                    </td>
                                    <td className="sticky left-[70px] bg-white p-4 text-sm text-bluish-grey border-b border-mercury whitespace-nowrap z-10 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                      {entry.date ? new Date(entry.date).toLocaleDateString() : ''}
                                    </td>
                                    <td className="sticky left-[200px] bg-white p-4 text-sm text-bluish-grey border-b border-mercury whitespace-nowrap z-10 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                      {entry.invoiceNumber}
                                    </td>
                                    <td className="sticky left-[350px] bg-white p-4 text-sm text-bluish-grey border-b border-mercury whitespace-nowrap z-10 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                      {entry.buyerName}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury">
                                      {entry.buyerFiscalNumber}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury">
                                      {entry.buyerVatNumber}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.exemptSalesNoCredit || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.foreignServiceSales || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.domesticReverseChargeSales || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.otherExemptSalesWithCredit || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.totalExemptSalesWithCredit || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.exports || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.taxableSales18 || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.debitNoteIssued18 || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.badDebtAccepted18 || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.vatIncreaseAdjustments18 || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.reverseChargePurchases18 || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.totalVat18 || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.taxableSales8 || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.debitNoteIssued8 || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.badDebtAccepted8 || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.vatIncreaseAdjustments8 || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.totalVat8 || 0).toFixed(2)}
                                    </td>
                                    <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                      {(entry.totalDeductibleVat || 0).toFixed(2)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr className="bg-seashell/50">
                                  <td colSpan={4} className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury">
                                    Totali
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.exemptSalesNoCredit || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.foreignServiceSales || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.domesticReverseChargeSales || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.otherExemptSalesWithCredit || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.totalExemptSalesWithCredit || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.exports || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.taxableSales18 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.debitNoteIssued18 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.badDebtAccepted18 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.vatIncreaseAdjustments18 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.reverseChargePurchases18 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.totalVat18 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.taxableSales8 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.debitNoteIssued8 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.badDebtAccepted8 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.vatIncreaseAdjustments8 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.totalVat8 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                    {(journalData?.totals?.totalDeductibleVat || 0).toFixed(2)}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Export Buttons */}
                    <div className="mt-8 flex justify-end space-x-4">
                      <button
                        onClick={() => handleExcelExport()}
                        disabled={exportLoading}
                        className="flex items-center space-x-2 px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 disabled:opacity-50"
                      >
                        {exportLoading ? (
                          <Loader2 className="w-4 h-4 animate-spin" />
                        ) : (
                          <Download className="w-4 h-4" />
                        )}
                        <span>Excel</span>
                      </button>
                      <button
                        onClick={() => handleExport('pdf')}
                        disabled={exportLoading}
                        className="flex items-center space-x-2 px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 disabled:opacity-50"
                      >
                        {exportLoading ? (
                          <Loader2 className="w-4 h-4 animate-spin" />
                        ) : (
                          <Download className="w-4 h-4" />
                        )}
                        <span>PDF</span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </motion.div>
          )}
        </AnimatePresence>

        {/* Empty State */}
        <AnimatePresence mode="wait">
          {!showReport && (
            <motion.div
              key="empty-state"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="bg-gradient-to-b from-white to-seashell rounded-2xl shadow-sm p-16 text-center relative overflow-hidden"
            >
              {/* Background Pattern */}
              <div className="absolute inset-0 opacity-5">
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,#90a68c_1px,transparent_1px)] bg-[length:24px_24px]"></div>
              </div>

              {/* Content */}
              <div className="relative z-10">
                {/* Icon Container */}
                <div className="mb-6 relative">
                  <div className="w-20 h-20 bg-seashell rounded-full flex items-center justify-center mx-auto">
                    <FileText className="w-10 h-10 text-hazel-green" />
                  </div>
                </div>

                {/* Text Content */}
                <div className="max-w-md mx-auto">
                  <h3 className="text-xl font-semibold text-bluish-grey mb-3">
                    Nuk ka raport të gjeneruar
                  </h3>
                  <p className="text-grey-goose mb-6 leading-relaxed">
                    Për të gjeneruar librin e shitjeve, ju lutem përzgjidhni vitin dhe muajin në formën më lart.
                  </p>

                  {/* Steps */}
                  <div className="space-y-3 text-left bg-white/50 rounded-xl p-4 backdrop-blur-sm">
                    <div className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <span className="text-sm text-hazel-green">1</span>
                      </div>
                      <div>
                        <p className="text-sm text-bluish-grey font-medium">Zgjidhni periudhën kohore</p>
                        <p className="text-xs text-grey-goose">Përzgjidhni vitin dhe muajin për të cilin dëshironi të gjeneroni raportin</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <span className="text-sm text-hazel-green">2</span>
                      </div>
                      <div>
                        <p className="text-sm text-bluish-grey font-medium">Gjeneroni raportin</p>
                        <p className="text-xs text-grey-goose">Klikoni butonin "Gjenero Raportin" për të shfaqur librin e shitjeve</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Decorative Elements */}
                <div className="absolute bottom-0 left-0 w-32 h-32 bg-hazel-green/5 rounded-tr-full"></div>
                <div className="absolute top-0 right-0 w-24 h-24 bg-dawn-pink/10 rounded-bl-full"></div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Enhanced Pagination Section - Only show when journal is generated */}
        {showReport && journalData && (
          <div className="sticky bottom-0 left-0 right-0 bg-white border-t border-mercury shadow-[0_-2px_5px_rgba(0,0,0,0.05)] backdrop-blur-sm">
            <div className="max-w-full mx-auto px-6 py-4">
              <div className="flex items-center justify-between">
                {/* Pagination Info */}
                <div className="flex items-center space-x-2">
                  <span className="text-sm font-medium text-bluish-grey">
                    Faqja {currentPage} nga {journalData?.pagination?.totalPages || 1}
                  </span>
                  <span className="text-sm text-grey-goose">
                    ({((currentPage - 1) * 5) + 1}-{Math.min(currentPage * 5, journalData?.pagination?.totalItems)} nga {journalData?.pagination?.totalItems} rezultate)
                  </span>
                </div>

                {/* Navigation Buttons */}
                <div className="flex items-center space-x-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setCurrentPage(1)}
                    disabled={currentPage === 1 || loading}
                    className="h-9 px-3 text-bluish-grey hover:text-hazel-green hover:border-hazel-green/30 disabled:opacity-50"
                  >
                    <ChevronLeft className="w-4 h-4 mr-1" />
                    E para
                  </Button>
                  
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                    disabled={currentPage === 1 || loading}
                    className="h-9 px-4 text-bluish-grey hover:text-hazel-green hover:border-hazel-green/30 disabled:opacity-50"
                  >
                    Para
                  </Button>

                  {/* Current Page Indicator */}
                  <div className="px-4 h-9 flex items-center justify-center min-w-[4rem] bg-seashell rounded-md border border-mercury">
                    <span className="text-sm font-medium text-bluish-grey">{currentPage}</span>
                  </div>

                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setCurrentPage(p => p + 1)}
                    disabled={currentPage === journalData?.pagination?.totalPages || loading}
                    className="h-9 px-4 text-bluish-grey hover:text-hazel-green hover:border-hazel-green/30 disabled:opacity-50"
                  >
                    Pas
                  </Button>

                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setCurrentPage(journalData?.pagination?.totalPages || 1)}
                    disabled={currentPage === journalData?.pagination?.totalPages || loading}
                    className="h-9 px-3 text-bluish-grey hover:text-hazel-green hover:border-hazel-green/30 disabled:opacity-50"
                  >
                    E fundit
                    <ChevronRight className="w-4 h-4 ml-1" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesJournalPage;
