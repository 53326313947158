import React, { useEffect, useState } from 'react';
import BarOrderAccordion from './BarOrderAccordion'; 
import { getUnpaidBarOrders, updateBarOrderStatus, getAllBarItems } from '../api/restaurantInterface';

const BarSidebar = ({ show, onClose }) => {
  const [barOrders, setBarOrders] = useState([]);
  const [allBarItems, setAllBarItems] = useState([]);

  useEffect(() => {
    const fetchBarOrders = async () => {
      const response = await getUnpaidBarOrders();
      const ordersWithItems = response.data.map(order => ({
        ...order,
        BarOrderProduct: order.BarOrderProduct || [],
      }));
      setBarOrders(ordersWithItems);
    };

    const fetchBarItems = async () => {
      const response = await getAllBarItems();
      setAllBarItems(response.data);
    };

    fetchBarOrders();
    fetchBarItems();
  }, []);

  const handleBarItemDrop = (orderId, barItem, barItemIdToRemove) => {
    setBarOrders(prevOrders =>
      prevOrders.map(order => {
        if (order.id === orderId) {
          if (barItem) {
            return { ...order, BarOrderProduct: [...order.BarOrderProduct, barItem] };
          } else {
            return { ...order, BarOrderProduct: order.BarOrderProduct.filter(item => item.uniqueId !== barItemIdToRemove) };
          }
        }
        return order;
      })
    );
  };

  const handlePay = async orderId => {
    await updateBarOrderStatus(orderId, 'paid');
    setBarOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
  };

  return (
    <div className={`fixed top-0 right-0 w-64 h-full bg-white shadow-lg transform ${show ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}>
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-bold">Bar Orders</h2>
        <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1={18} y1={6} x2={6} y2={18} />
            <line x1={6} y1={6} x2={18} y2={18} />
          </svg>
        </button>
      </div>
      <div className="overflow-y-auto h-[calc(100%-56px)] p-4">
        {barOrders.map(order => (
          <BarOrderAccordion
            key={order.id}
            order={order}
            onBarItemDrop={handleBarItemDrop}
            onPay={handlePay}
          />
        ))}
      </div>
    </div>
  );
};

export default BarSidebar;
