import React from "react";
import { IconClipboardList, IconShoppingCart, IconCurrencyDollar } from '@tabler/icons-react';

export default function DefaultSalesPage() {
    return (
        <div className="flex flex-col items-center bg-seashell p-4 py-2">
            <div className="max-w-4xl w-full text-center mt-6">
                <h2 className="text-3xl font-bold text-hazel-green mb-4">Welcome to the Sales Section</h2>
                <p className="text-lg text-bluish-grey mb-8">
                    Select an option from the navigation to view and manage your sales records.
                </p>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="bg-white rounded-lg shadow-lg p-6 border border-mercury transition-transform transform hover:scale-105 hover:shadow-xl">
                        <IconShoppingCart className="h-12 w-12 mx-auto text-hazel-green mb-4" />
                        <h3 className="text-xl font-semibold text-hazel-green">Manage Transactions</h3>
                        <p className="text-grey-goose mt-2">
                            Easily view and process all your sales transactions in one place.
                        </p>
                    </div>
                    <div className="bg-white rounded-lg shadow-lg p-6 border border-mercury transition-transform transform hover:scale-105 hover:shadow-xl">
                        <IconClipboardList className="h-12 w-12 mx-auto text-hazel-green mb-4" />
                        <h3 className="text-xl font-semibold text-hazel-green">View Invoices</h3>
                        <p className="text-grey-goose mt-2">
                            Keep track of your invoices and ensure timely payments from customers.
                        </p>
                    </div>
                    <div className="bg-white rounded-lg shadow-lg p-6 border border-mercury transition-transform transform hover:scale-105 hover:shadow-xl">
                        <IconCurrencyDollar className="h-12 w-12 mx-auto text-hazel-green mb-4" />
                        <h3 className="text-xl font-semibold text-hazel-green">Analyze Sales</h3>
                        <p className="text-grey-goose mt-2">
                            Gain insights into your sales performance with our analytics tools.
                        </p>
                    </div>
                </div>


            </div>
        </div>
    );
}
