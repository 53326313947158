import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  X, 
  ChevronRight, 
  ChevronLeft, 
  Play,
  CheckCircle2,
  Clock,
  BookOpen,
  Download,
  Share2,
  Bookmark,
  ExternalLink,
  PlayCircle,
  CheckCircle,
  Lock,
  Video,
  FileText
} from 'lucide-react';
import { Button } from '../shadCN/Button.tsx';

const TutorialViewer = ({ tutorial, onClose }) => {
  const [currentSection, setCurrentSection] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [currentChapter, setCurrentChapter] = useState(0);

  const nextSection = () => {
    if (currentSection < tutorial.chapters?.[currentChapter]?.sections?.length - 1) {
      setCurrentSection(prev => prev + 1);
    }
  };

  const prevSection = () => {
    if (currentSection > 0) {
      setCurrentSection(prev => prev - 1);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-center justify-center p-4"
    >
      <motion.div
      initial={{ scale: 0.95, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.95, opacity: 0 }}
      className="bg-white/95 backdrop-blur-sm rounded-2xl shadow-xl w-full max-w-6xl max-h-[90vh] overflow-hidden relative"
      >
      {/* Enhanced Header with gradient and blur effect */}
      <div className="p-8 bg-gradient-to-r from-sage/90 to-hazel-green/90 backdrop-blur-md flex items-center justify-between">
        <div className="flex items-center space-x-6">
        <div className="p-3 bg-white/10 rounded-xl">
          <BookOpen className="w-8 h-8 text-white" />
        </div>
        <div>
          <h2 className="text-2xl font-bold text-white">{tutorial.title}</h2>
          <div className="flex items-center space-x-6 text-white/90 mt-2">
          <span className="flex items-center px-3 py-1 rounded-full bg-white/10">
            <Clock className="w-4 h-4 mr-2" />
            {tutorial.duration}
          </span>
          <span className="flex items-center px-3 py-1 rounded-full bg-white/10">
            <CheckCircle2 className="w-4 h-4 mr-2" />
            {tutorial.completedSections}/{tutorial.totalSections} Sections
          </span>
          </div>
        </div>
        </div>
        <Button
        variant="ghost"
        size="icon"
        onClick={onClose}
        className="text-white hover:bg-white/20 rounded-full p-2 h-auto"
        >
        <X className="w-6 h-6" />
        </Button>
      </div>

        <div className="grid grid-cols-12 h-[calc(90vh-7rem)]">
          {/* Enhanced Sidebar with better visual hierarchy */}
          <div className="col-span-3 border-r border-mercury/30 bg-seashell/50 backdrop-blur-sm overflow-y-auto">
          <div className="p-6">
            <div className="flex items-center justify-between mb-6">
            <h3 className="font-semibold text-hazel-green text-lg">Course Content</h3>
            <span className="text-sm bg-sage/10 text-sage px-3 py-1 rounded-full">
              {tutorial.completedSections}/{tutorial.totalSections}
            </span>
            </div>
              
                <div className="space-y-6">
                {tutorial.chapters?.map((chapter, chapterIndex) => (
                  <div key={chapter.id} className="space-y-3">
                  <div className="flex items-center justify-between text-sm font-medium text-bluish-grey">
                    <span className="bg-mercury/50 px-3 py-1 rounded-full text-xs">Chapter {chapterIndex + 1}</span>
                    <span className="text-xs">{chapter.duration}</span>
                  </div>
                  <h4 className="font-medium text-hazel-green text-base">{chapter.title}</h4>
                  
                  <div className="space-y-2">
                      {chapter.sections?.map((section, sectionIndex) => (
                        <motion.button
                          key={section.id}
                          whileHover={{ x: 4 }}
                          onClick={() => {
                            if (!section.locked) {
                              setCurrentChapter(chapterIndex);
                              setCurrentSection(sectionIndex);
                            }
                          }}
                            className={`w-full text-left p-4 rounded-xl flex items-center space-x-4 transition-all duration-200 ${
                            currentChapter === chapterIndex && currentSection === sectionIndex
                              ? 'bg-sage/15 text-sage shadow-sm'
                              : section.locked
                              ? 'opacity-50 cursor-not-allowed'
                              : 'hover:bg-mercury/30 text-bluish-grey hover:text-hazel-green'
                            }`}
                          >
                            <div className="flex-shrink-0">
                            {section.completed ? (
                              <div className="p-2 bg-sage/10 rounded-lg">
                              <CheckCircle className="w-5 h-5 text-sage" />
                              </div>
                            ) : section.locked ? (
                              <div className="p-2 bg-mercury/30 rounded-lg">
                              <Lock className="w-5 h-5" />
                              </div>
                            ) : section.type === 'video' ? (
                              <div className="p-2 bg-tealish-blue/10 rounded-lg">
                              <Video className="w-5 h-5 text-tealish-blue" />
                              </div>
                            ) : (
                              <div className="p-2 bg-hazel-green/10 rounded-lg">
                              <FileText className="w-5 h-5 text-hazel-green" />
                              </div>
                            )}
                          </div>
                          <div className="flex-grow min-w-0">
                            <p className="truncate">{section.title}</p>
                            <div className="flex items-center text-xs space-x-2">
                              <span>{section.duration}</span>
                              {section.locked && (
                                <span className="text-watermelon">Premium</span>
                              )}
                            </div>
                          </div>
                        </motion.button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Main Content Area */}
            <div className="col-span-9 overflow-y-auto bg-white">
            <div className="p-8">
              <AnimatePresence mode="wait">
              <motion.div
                key={`${currentChapter}-${currentSection}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="space-y-8"
              >
                {/* Enhanced Video Section */}
                {tutorial.chapters?.[currentChapter]?.sections?.[currentSection]?.videoUrl && (
                <div className="relative aspect-video rounded-2xl overflow-hidden bg-bluish-grey/5 shadow-lg">
                  {!isVideoPlaying ? (
                  <div
                    onClick={() => setIsVideoPlaying(true)}
                    className="absolute inset-0 flex items-center justify-center cursor-pointer group"
                  >
                    <motion.div
                    whileHover={{ scale: 1.1 }}
                    className="w-20 h-20 rounded-full bg-sage/90 backdrop-blur-sm flex items-center justify-center shadow-lg"
                    >
                    <PlayCircle className="w-12 h-12 text-white" />
                    </motion.div>
                    <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                        </div>
                      ) : (
                        <iframe
                          src={tutorial.chapters[currentChapter].sections[currentSection].videoUrl}
                          className="w-full h-full"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      )}
                    </div>
                  )}

                  {/* Enhanced Content Section */}
                  <div className="prose max-w-none">
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="text-xl font-semibold text-hazel-green m-0">
                        {tutorial.chapters?.[currentChapter]?.sections?.[currentSection]?.title}
                      </h3>
                      {tutorial.chapters?.[currentChapter]?.sections?.[currentSection]?.completed && (
                        <div className="flex items-center text-sage">
                          <CheckCircle className="w-5 h-5 mr-2" />
                          <span>Completed</span>
                        </div>
                      )}
                    </div>
                    <div 
                      className="text-bluish-grey mt-4"
                      dangerouslySetInnerHTML={{ 
                        __html: tutorial.chapters?.[currentChapter]?.sections?.[currentSection]?.content 
                      }}
                    />
                  </div>

                  {/* Navigation Buttons */}
                  <div className="flex items-center justify-between pt-6 border-t border-mercury">
                    <Button
                      variant="outline"
                      onClick={prevSection}
                      disabled={currentSection === 0}
                    >
                      <ChevronLeft className="w-4 h-4 mr-2" />
                      Previous Section
                    </Button>
                    <Button
                      variant="default"
                      onClick={nextSection}
                      disabled={currentSection === tutorial.chapters?.[currentChapter]?.sections?.length - 1}
                    >
                      Next Section
                      <ChevronRight className="w-4 h-4 ml-2" />
                    </Button>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default TutorialViewer; 