import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Save } from 'lucide-react';
import { createAccount, getAccountingClassByType, getAccountingClasses, getAllAccounts } from '../../api/accountingPlan';

interface Account {
  id: string;
  code: string;
  name: string;
  description?: string;
  nature: 'DEBIT' | 'CREDIT';
  classId?: string;
  parentId?: string;
  isActive?: boolean;
  class?: AccountClass;
  parent?: Account;
  _original?: any;
}

interface AccountClass {
  id: string;
  code: string;
  name: string;
  type: 'ASSET' | 'LIABILITY' | 'EQUITY' | 'REVENUE' | 'EXPENSE';
  description?: string;
}

interface AccountFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  account?: Account | null;
  viewOnly?: boolean;
  onSuccess?: () => void;
}

const AccountFormModal: React.FC<AccountFormModalProps> = ({ 
  isOpen, 
  onClose, 
  account = null,
  viewOnly = false,
  onSuccess
}) => {
  const [formData, setFormData] = useState({
    code: account?.code || '',
    name: account?.name || '',
    description: account?.description || '',
    nature: account?.nature || 'DEBIT',
    classId: account?.classId || '',
    parentId: account?.parentId || '',
    isActive: account?.isActive ?? true
  });

  const [accountingClasses, setAccountingClasses] = useState<AccountClass[]>([]);
  const [parentAccounts, setParentAccounts] = useState<Account[]>([]);
  const [error, setError] = useState<string | null>(null);

  // Load accounting classes and parent accounts when component mounts
  useEffect(() => {
    const loadData = async () => {
      try {
        const [classesResponse, accountsResponse] = await Promise.all([
          getAccountingClasses(),
          getAllAccounts()
        ]);
        
        setAccountingClasses(classesResponse.data.message);
        setParentAccounts(accountsResponse.data.message);
        console.log(classesResponse.data);
        console.log(accountsResponse.data);
      } catch (error: any) {
        setError('Failed to load data');
        console.error('Error loading data:', error);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (account) {
      setFormData({
        code: account.code || '',
        name: account.name || '',
        description: account.description || '',
        nature: account.nature || 'DEBIT',
        classId: account.classId || '',
        parentId: account.parentId || '',
        isActive: account.isActive ?? true
      });
    }
  }, [account]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    // Validate required fields
    if (!formData.code || !formData.name || !formData.classId) {
      setError('Kodi, emri dhe klasa janë të detyrueshme');
      return;
    }

    try {
      // Create submission data
      const submissionData = {
        code: formData.code,
        name: formData.name,
        description: formData.description || undefined,
        nature: formData.nature as 'DEBIT' | 'CREDIT',
        classId: formData.classId,
        parentId: formData.parentId || undefined,
        isActive: formData.isActive,
        createdBy: 'current-user-id'
      };

      const response = await createAccount(submissionData);

      if (response.data.statusCode === 200) {
        onSuccess?.();
        onClose();
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;
      if (errorMessage.includes('already exists')) {
        setError(errorMessage);
      } else {
        setError('Ndodhi një gabim gjatë krijimit të kontos');
        console.error('Error creating account:', error);
      }
    }
  };

  const modalVariants = {
    hidden: {
      opacity: 0,
      scale: 0.95,
      y: 20
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      y: 20,
      transition: {
        duration: 0.2
      }
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-bluish-grey/20 backdrop-blur-sm z-50 flex items-center justify-center"
            onClick={onClose}
          >
            {/* Modal */}
            <motion.div
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="w-full max-w-4xl bg-white rounded-2xl shadow-xl overflow-hidden relative"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Header */}
              <div className="px-8 py-5 border-b border-mercury bg-gradient-to-r from-seashell to-white">
                <div className="flex justify-between items-center">
                  <div>
                    <h2 className="text-xl font-semibold text-bluish-grey">
                      {viewOnly ? 'Detajet e Kontos' : account ? 'Modifiko Konton' : 'Shto një Konto të re'}
                    </h2>
                    {!viewOnly && (
                      <p className="mt-1 text-grey-goose text-sm">
                        {account ? 'Modifikoni të dhënat e kontos' : 'Plotësoni të dhënat për të krijuar një konto të re'}
                      </p>
                    )}
                  </div>
                  <button
                    onClick={onClose}
                    className="p-2 hover:bg-mercury/50 rounded-lg transition-colors duration-200"
                  >
                    <X className="h-5 w-5 text-grey-goose" />
                  </button>
                </div>
              </div>

              {/* Form */}
              <form onSubmit={handleSubmit} className="p-8">
                {error && (
                  <div className="mt-4 p-3 bg-red-50 border border-red-200 text-red-700 rounded-lg">
                    {error}
                  </div>
                )}
                <div className="grid grid-cols-2 gap-6">
                  <div className="space-y-6">
                    {/* Code */}
                    <div className="form-group">
                      <label className="block text-sm font-medium text-bluish-grey mb-2">
                        Kodi i Kontos
                      </label>
                      <input
                        type="text"
                        name="code"
                        placeholder="P.sh. 1000, 1100, 2400, etj."
                        value={formData.code}
                        onChange={handleChange}
                        disabled={viewOnly}
                        className="w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey 
                                 placeholder:text-grey-goose/75 
                                 focus:border-hazel-green focus:ring-1 focus:ring-hazel-green
                                 disabled:bg-seashell disabled:cursor-not-allowed
                                 transition-colors duration-200"
                      />
                    </div>

                    {/* Name */}
                    <div className="form-group">
                      <label className="block text-sm font-medium text-bluish-grey mb-2">
                        Emri i Kontos
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="P.sh. Paraja në Arkë, Llogaritë e Arkëtueshme, etj."
                        value={formData.name}
                        onChange={handleChange}
                        disabled={viewOnly}
                        className="w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey 
                                 placeholder:text-grey-goose/75
                                 focus:border-hazel-green focus:ring-1 focus:ring-hazel-green
                                 disabled:bg-seashell disabled:cursor-not-allowed
                                 transition-colors duration-200"
                      />
                    </div>

                    {/* Description */}
                    <div className="form-group">
                      <label className="block text-sm font-medium text-bluish-grey mb-2">
                        Përshkrimi
                      </label>
                      <textarea
                        name="description"
                        placeholder="Shkruaj ndonjë përshkrim nëse është e nevojshme"
                        value={formData.description}
                        onChange={handleChange}
                        disabled={viewOnly}
                        rows={viewOnly ? 2 : 3}
                        className="w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey 
                                 placeholder:text-grey-goose/75
                                 focus:border-hazel-green focus:ring-1 focus:ring-hazel-green
                                 disabled:bg-seashell disabled:cursor-not-allowed
                                 transition-colors duration-200
                                 resize-none"
                      />
                    </div>
                  </div>

                  <div className="space-y-6">
                    {/* Nature */}
                    <div className="form-group">
                      <label className="block text-sm font-medium text-bluish-grey mb-2">
                        Natyra e Kontos
                      </label>
                      <select
                        name="nature"
                        value={formData.nature}
                        onChange={handleChange}
                        disabled={viewOnly}
                        className="w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey 
                                 focus:border-hazel-green focus:ring-1 focus:ring-hazel-green
                                 disabled:bg-seashell disabled:cursor-not-allowed
                                 transition-colors duration-200"
                      >
                        <option value="DEBIT">Debit</option>
                        <option value="CREDIT">Kredit</option>
                      </select>
                    </div>

                    {/* Class */}
                    <div className="form-group">
                      <label className="block text-sm font-medium text-bluish-grey mb-2">
                        Klasa e Kontos
                      </label>
                      <select
                        name="classId"
                        value={formData.classId}
                        onChange={handleChange}
                        disabled={viewOnly}
                        className="w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey 
                                 focus:border-hazel-green focus:ring-1 focus:ring-hazel-green
                                 disabled:bg-seashell disabled:cursor-not-allowed
                                 transition-colors duration-200"
                      >
                        <option value="">Zgjidhni klasën e kontos</option>
                        {accountingClasses.map((accountClass) => (
                          <option key={accountClass.id} value={accountClass.id}>
                            {accountClass.code} - {accountClass.name} ({accountClass.type})
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Parent Account */}
                    <div className="form-group">
                      <label className="block text-sm font-medium text-bluish-grey mb-2">
                        Konto Analitike
                      </label>
                      <select
                        name="parentId"
                        value={formData.parentId}
                        onChange={handleChange}
                        disabled={viewOnly}
                        className="w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey 
                                 focus:border-hazel-green focus:ring-1 focus:ring-hazel-green
                                 disabled:bg-seashell disabled:cursor-not-allowed
                                 transition-colors duration-200"
                      >
                        <option value="">Zgjidhni konton analitike</option>
                        {parentAccounts.map((parentAccount) => (
                          <option key={parentAccount.id} value={parentAccount.id}>
                            {parentAccount.code} - {parentAccount.name} 
                            {parentAccount.class && ` (${parentAccount.class.type})`}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Active */}
                    <div className="form-group">
                      <label className="block text-sm font-medium text-bluish-grey mb-2">
                        Aktive
                      </label>
                      <select
                        name="isActive"
                        value={formData.isActive}
                        onChange={handleChange}
                        disabled={viewOnly}
                        className="w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey 
                                 focus:border-hazel-green focus:ring-1 focus:ring-hazel-green
                                 disabled:bg-seashell disabled:cursor-not-allowed
                                 transition-colors duration-200"
                      >
                        <option value={true}>Po</option>
                        <option value={false}>Jo</option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* Actions */}
                {!viewOnly && (
                  <div className="flex justify-end gap-3 mt-6 pt-6 border-t border-mercury">
                    <button
                      type="button"
                      onClick={onClose}
                      className="px-6 py-2.5 border border-mercury rounded-lg text-grey-goose 
                               hover:bg-seashell transition-colors duration-200"
                    >
                      Anulo
                    </button>
                    <button
                      type="submit"
                      className="px-6 py-2.5 bg-hazel-green text-white rounded-lg 
                               hover:bg-forest transition-colors duration-200
                               flex items-center gap-2"
                    >
                      <span>{account ? 'Ruaj Ndryshimet' : 'Ruaj'}</span>
                      {!viewOnly && <Save className="h-4 w-4" />}
                    </button>
                  </div>
                )}
              </form>
            </motion.div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default AccountFormModal;
