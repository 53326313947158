import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, FileText, Calendar, Calculator, ArrowRight } from 'lucide-react';
import { generateVatReport } from '../../../../api/vatReport';

interface VatFormData {
  year: string;
  month: string;
  creditAmount: string;
  formNumber: string;
}

interface VatReportItem {
  id: string;
  description: string;
  amount: number;
  reference?: string;
}

const VatFormPage: React.FC = () => {
  // Form data state
  const [formData, setFormData] = useState<VatFormData>({
    year: '2025',
    month: 'Janar',
    creditAmount: '0.00',
    formNumber: '1'
  });

  // Report visibility state
  const [showReport, setShowReport] = useState(false);

  // Sales items state
  const [salesItems, setSalesItems] = useState<VatReportItem[]>([
    { id: '9', description: 'Shitjet e liruara pa të drejtë kreditimi', amount: 0 },
    { id: '10', description: 'Shitjet e liruara me të drejtë kreditimi', amount: 0 },
    { id: '11', description: 'Eksportet', amount: 0 },
    { id: '12', description: 'Shitjet e tatueshme me normën 18%', amount: 0 },
    { id: '14', description: 'Shitjet e tatueshme me normën 8%', amount: 0 },
    { id: '16', description: 'Nota debitore e lëshuar, nota kreditore e pranuar me normë 18%', amount: 0 },
    { id: '18', description: 'Nota debitore e lëshuar, nota kreditore e pranuar me normë 8%', amount: 0 },
    { id: '20', description: 'Fatura e borxhit të keq e pranuar, inkasimi i borxhit të keq me normë 18%', amount: 0 },
    { id: '22', description: 'Fatura e borxhit të keq e pranuar, inkasimi i borxhit të keq me normë 8%', amount: 0 },
    { id: '24', description: 'Rregullimet për të ritur TVSH-në me normë 18%', amount: 0 },
    { id: '26', description: 'Rregullimet për të ritur TVSH-në me normë 8%', amount: 0 },
    { id: '28', description: 'Blerjet që i nënshtrohen ngarkesës së kundërt 18%', amount: 0 }
  ]);

  // Purchase items state
  const [purchaseItems, setPurchaseItems] = useState<VatReportItem[]>([
    { id: '31', description: 'Blerjet, Importet pa TVSH', amount: 0 },
    { id: '32', description: 'Blerjet,Importet investive pa TVSH', amount: 0 },
    { id: '33', description: 'Blerjet, Importet me TVSH jo të zbritshme', amount: 0 },
    { id: '34', description: 'Blerjet, Importet investive me TVSH jo të zbritshme', amount: 0 },
    { id: '35', description: 'Importet me normë 18%', amount: 0 },
    { id: '37', description: 'Importet me normë 8%', amount: 0 },
    { id: '39', description: 'Importet investive me normë 18%', amount: 0 },
    { id: '41', description: 'Importet investive me normë 8%', amount: 0 },
    { id: '43', description: 'Blerjet vendore me normë 18%', amount: 0 },
    { id: '45', description: 'Blerjet vendore me normë 8%', amount: 0 },
    { id: '47', description: 'Blerjet investive vendore me normë 18%', amount: 0 },
    { id: '49', description: 'Blerjet investive vendore me normë 8%', amount: 0 },
    { id: '51', description: 'Blerjet nga fermerët vendor (aplikimi i Normës së sheshtë 8%)', amount: 0 },
    { id: '53', description: 'Nota debitore e pranuar, nota kreditore e lëshuar me normë 18%', amount: 0 },
    { id: '55', description: 'Nota debitore e pranuar, nota kreditore e lëshuar me normë 8%', amount: 0 },
    { id: '57', description: 'Fatura e borxhit të keq e lëshuar, pagesa e borxhit të keq me normë 18%', amount: 0 },
    { id: '59', description: 'Fatura e borxhit të keq e lëshuar, pagesa e borxhit të keq me normë 8%', amount: 0 },
    { id: '61', description: 'Rregullimet për të ulur TVSH-në me normë 18%', amount: 0 },
    { id: '63', description: 'Rregullimet për të ulur TVSH-në me normë 8%', amount: 0 },
    { id: '65', description: 'E drejta e kreditimit të TVSH-së në lidhje me ngarkesën e kundërt me normë 18%', amount: 0 }
  ]);

  // Calculation items state
  const [calculationItems, setCalculationItems] = useState<VatReportItem[]>([
    { id: '68', description: 'Kreditimi i TVSH-së i bartur nga periudha paraprake', amount: 0 },
    { id: '69', description: 'Balanca kreditore e mbetur 69 = [(67+68)-58]>0', amount: 0 },
    { id: '70', description: 'Kërkesa për rimbursim', amount: 0 },
    { id: '71', description: 'Kreditimi i TVSH-së për periudhën pasuese 71 = (69-70)', amount: 0 },
    { id: '72', description: 'TVSH për pagesë 72 = [(30-67-68)>0', amount: 0 }
  ]);

  // Constants
  const months = [
    'Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor',
    'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'
  ];

  const years = ['2024', '2025', '2026'];

  const handleInputChange = (field: keyof VatFormData, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleGenerateReport = async () => {
    try {
      const monthIndex = months.indexOf(formData.month) + 1;
      const report = await generateVatReport(
        parseInt(formData.year),
        monthIndex,
      );

      // Update sales items
      const updatedSalesItems = salesItems.map(item => ({
        ...item,
        amount: report.salesItems.find(s => s.itemId === item.id)?.amount || 0
      }));

      // Update purchase items
      const updatedPurchaseItems = purchaseItems.map(item => ({
        ...item,
        amount: report.purchaseItems.find(p => p.itemId === item.id)?.amount || 0
      }));

      // Update calculation items
      const updatedCalculationItems = calculationItems.map(item => ({
        ...item,
        amount: report.calculations.find(c => c.itemId === item.id)?.amount || 0
      }));

      // Update state
      setSalesItems(updatedSalesItems);
      setPurchaseItems(updatedPurchaseItems);
      setCalculationItems(updatedCalculationItems);

      console.log(updatedSalesItems, updatedPurchaseItems, updatedCalculationItems)
      setShowReport(true);
    } catch (error) {
      // Handle error
      console.error('Failed to generate report:', error);
    }
  };

  const handleAmountChange = (type: 'sales' | 'purchases', id: string, value: string) => {
    if (type === 'sales') {
      const updatedSalesItems = salesItems.map(item => {
        if (item.id === id) {
          return { ...item, amount: parseFloat(value) };
        }
        return item;
      });
      // Update salesItems state
    } else {
      const updatedPurchaseItems = purchaseItems.map(item => {
        if (item.id === id) {
          return { ...item, amount: parseFloat(value) };
        }
        return item;
      });
      // Update purchaseItems state
    }
  };

  const getTVSHRate = (id: string) => {
    // Implement logic to get TVSH rate based on id
    return 0.18; // Default rate
  };

  const calculateTVSH = (amount: number, rate: number) => {
    return amount * rate;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header Section */}
      <div className="bg-white/80 backdrop-blur-sm border-b border-mercury sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2 text-sm">
              <span className="text-grey-goose hover:text-bluish-grey transition-colors">Tatimet</span>
              <ChevronDown className="w-4 h-4 text-grey-goose" />
              <span className="font-medium text-hazel-green">Formulari i TVSH-së</span>
            </div>
            <div className="text-xs text-grey-goose bg-white px-3 py-1.5 rounded-full shadow-sm">
              Periudha: {formData.month} {formData.year}
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">
        {/* Form Card */}
        <div className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 mb-8 overflow-hidden">
          {/* Form Header */}
          <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-8">
            <div className="max-w-2xl mx-auto text-center">
              <h1 className="text-2xl font-semibold text-bluish-grey mb-3">Formulari i TVSH-së</h1>
              <p className="text-grey-goose">
                Gjeneroni raportin e TVSH-së duke plotësuar të dhënat e kërkuara më poshtë
              </p>
            </div>
          </div>

          {/* Form Content */}
          <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              {/* Year Select */}
              <div className="relative group">
                <label className="inline-flex items-center text-sm font-medium text-bluish-grey mb-2 group-hover:text-hazel-green transition-colors">
                  <Calendar className="w-4 h-4 mr-2 opacity-70" />
                  <span>Viti</span>
                </label>
                <div className="relative">
                  <select
                    value={formData.year}
                    onChange={(e) => handleInputChange('year', e.target.value)}
                    className="w-full p-3 bg-seashell border border-mercury rounded-xl appearance-none text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 hover:border-hazel-green/30 transition-colors"
                  >
                    {years.map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-goose w-4 h-4 pointer-events-none" />
                </div>
              </div>

              {/* Month Select */}
              <div className="relative group">
                <label className="inline-flex items-center text-sm font-medium text-bluish-grey mb-2 group-hover:text-hazel-green transition-colors">
                  <Calendar className="w-4 h-4 mr-2 opacity-70" />
                  <span>Muaji</span>
                </label>
                <div className="relative">
                  <select
                    value={formData.month}
                    onChange={(e) => handleInputChange('month', e.target.value)}
                    className="w-full p-3 bg-seashell border border-mercury rounded-xl appearance-none text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 hover:border-hazel-green/30 transition-colors"
                  >
                    {months.map(month => (
                      <option key={month} value={month}>{month}</option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-goose w-4 h-4 pointer-events-none" />
                </div>
              </div>

              {/* Credit Amount Input */}
              <div className="relative group">
                <label className="inline-flex items-center text-sm font-medium text-bluish-grey mb-2 group-hover:text-hazel-green transition-colors">
                  <Calculator className="w-4 h-4 mr-2 opacity-70" />
                  <span>Kreditimi i bartur nga muaji paraprak</span>
                </label>
                <input
                  type="number"
                  value={formData.creditAmount}
                  onChange={(e) => handleInputChange('creditAmount', e.target.value)}
                  className="w-full p-3 bg-seashell border border-mercury rounded-xl text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 hover:border-hazel-green/30 transition-colors"
                  placeholder="0.00"
                />
              </div>
            </div>

            {/* Generate Button */}
            <div className="flex justify-center">
              <button
                onClick={handleGenerateReport}
                className="group px-6 py-3 bg-gradient-to-r from-hazel-green to-sage text-white rounded-xl hover:from-sage hover:to-hazel-green transition-all duration-300 flex items-center space-x-3 shadow-lg shadow-hazel-green/20 hover:shadow-hazel-green/30"
              >
                <FileText className="w-5 h-5" />
                <span>Gjenero Raportin</span>
                <ArrowRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
              </button>
            </div>
          </div>
        </div>

        {/* Report Section */}
        <AnimatePresence mode="wait">
          {showReport && (
            <motion.div
              key="report"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 overflow-hidden"
            >
              {/* Report Header */}
              <div className="bg-gradient-to-r from-hazel-green/10 to-transparent p-8 border-b border-mercury/50">
                <div className="text-center">
                  <div className="inline-flex items-center space-x-2 bg-white/80 px-4 py-2 rounded-full shadow-sm mb-4">
                    <span className="text-grey-goose">Periudha:</span>
                    <span className="text-bluish-grey font-medium">{formData.month}</span>
                    <span className="text-grey-goose">/</span>
                    <span className="text-bluish-grey font-medium">{formData.year}</span>
                  </div>
                  <h2 className="text-xl font-semibold text-bluish-grey mb-2">
                    TV - FORMULARI I DEKLARIMIT TË TVSH
                  </h2>
                  <p className="text-sm text-grey-goose">
                    Enis Gjocaj | 121212121212 | Luan Haradinaj, deçan, 51000
                  </p>
                </div>
              </div>

              {/* Report Content */}
              <div className="p-8">
                {/* Sales and Purchases Grid */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                  {/* Sales Section */}
                  <div className="space-y-6">
                    <h3 className="text-lg font-medium text-bluish-grey flex items-center">
                      <span className="w-8 h-8 rounded-full bg-hazel-green/10 flex items-center justify-center mr-3">
                        <span className="text-sm text-hazel-green">S</span>
                      </span>
                      Shitjet
                    </h3>
                    <div className="grid grid-cols-2 gap-4">
                      {/* Sales Amounts */}
                      <div className="space-y-2">
                        {salesItems.map((item) => (
                          <motion.div
                            key={item.id}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="flex items-center justify-between p-2 hover:bg-seashell rounded-lg transition-colors"
                          >
                            <div className="flex items-center gap-2">
                              <span className="text-xs text-grey-goose">[{parseInt(item.id) + 1}]</span>
                              <span className="text-sm text-bluish-grey">{item.description}</span>
                            </div>
                            <span className="text-sm font-medium text-bluish-grey">{item.amount}</span>
                          </motion.div>
                        ))}
                      </div>
                      {/* Sales TVSH Calculations */}
                      <div className="space-y-2">
                        <div className="text-sm font-medium text-bluish-grey mb-2 px-2">TVSH e llogaritur</div>
                        {salesItems.map((item) => (
                          <motion.div
                            key={`tvsh-${item.id}`}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="flex items-center justify-between p-2 hover:bg-seashell rounded-lg transition-colors"
                          >
                            <span className="text-xs text-grey-goose">[{parseInt(item.id) + 1}]</span>
                            <span className="text-sm font-medium text-bluish-grey">{calculateTVSH(item.amount, getTVSHRate(item.id))}</span>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* Purchases Section */}
                  <div className="space-y-6">
                    <h3 className="text-lg font-medium text-bluish-grey flex items-center">
                      <span className="w-8 h-8 rounded-full bg-sage/10 flex items-center justify-center mr-3">
                        <span className="text-sm text-sage">B</span>
                      </span>
                      Blerjet
                    </h3>
                    <div className="grid grid-cols-2 gap-4">
                      {/* Purchase Amounts */}
                      <div className="space-y-2">
                        {purchaseItems.map((item) => (
                          <motion.div
                            key={item.id}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="flex items-center justify-between p-2 hover:bg-seashell rounded-lg transition-colors"
                          >
                            <div className="flex items-center gap-2">
                              <span className="text-xs text-grey-goose">[{parseInt(item.id) + 1}]</span>
                              <span className="text-sm text-bluish-grey">{item.description}</span>
                            </div>
                            <span className="text-sm font-medium text-bluish-grey">{item.amount}</span>
                          </motion.div>
                        ))}
                      </div>
                      {/* Purchase TVSH Calculations */}
                      <div className="space-y-2">
                        <div className="text-sm font-medium text-bluish-grey mb-2 px-2">TVSH e zbritshme</div>
                        {purchaseItems.map((item) => (
                          <motion.div
                            key={`tvsh-${item.id}`}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="flex items-center justify-between p-2 hover:bg-seashell rounded-lg transition-colors"
                          >
                            <span className="text-xs text-grey-goose">[{parseInt(item.id) + 1}]</span>
                            <span className="text-sm font-medium text-bluish-grey">{calculateTVSH(item.amount, getTVSHRate(item.id))}</span>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Calculation Section */}
                <div className="mt-8 pt-8 border-t border-mercury/50">
                  <h3 className="text-lg font-medium text-bluish-grey flex items-center mb-6">
                    <span className="w-8 h-8 rounded-full bg-bluish-grey/10 flex items-center justify-center mr-3">
                      <Calculator className="w-4 h-4 text-bluish-grey" />
                    </span>
                    Kalkulimi
                  </h3>
                  {calculationItems.map((item) => (
                    <motion.div
                      key={item.id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="flex items-center justify-between p-2 hover:bg-seashell rounded-lg"
                    >
                      <div className="flex items-center gap-2">
                        <span className="text-xs text-grey-goose">[{item.id}]</span>
                        <span className="text-sm text-bluish-grey">{item.description}</span>
                      </div>
                      <span className="text-sm font-medium text-bluish-grey">
                        {item.amount.toFixed(2)}
                      </span>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Empty State - Show when report is not generated */}
        <AnimatePresence mode="wait">
          {!showReport && (
            <motion.div
              key="empty-state"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="bg-gradient-to-b from-white to-seashell rounded-2xl shadow-sm p-16 text-center relative overflow-hidden"
            >
              {/* Background Pattern */}
              <div className="absolute inset-0 opacity-5">
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,#90a68c_1px,transparent_1px)] bg-[length:24px_24px]"></div>
              </div>

              {/* Content */}
              <div className="relative z-10">
                {/* Icon Container */}
                <div className="mb-6 relative">
                  <div className="w-20 h-20 bg-seashell rounded-full flex items-center justify-center mx-auto">
                    <FileText className="w-10 h-10 text-hazel-green" />
                  </div>
                  <div className="absolute -top-1 -right-1 w-6 h-6 bg-dawn-pink rounded-full flex items-center justify-center">
                    <span className="text-watermelon text-lg font-semibold">!</span>
                  </div>
                </div>

                {/* Text Content */}
                <div className="max-w-md mx-auto">
                  <h3 className="text-xl font-semibold text-bluish-grey mb-3">
                    Nuk ka raport të gjeneruar
                  </h3>
                  <p className="text-grey-goose mb-6 leading-relaxed">
                    Për të gjeneruar raportin e TVSH-së, ju lutem plotësoni të dhënat në formën më lart.
                  </p>

                  {/* Steps */}
                  <div className="space-y-3 text-left bg-white/50 rounded-xl p-4 backdrop-blur-sm">
                    <div className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <span className="text-sm text-hazel-green">1</span>
                      </div>
                      <div>
                        <p className="text-sm text-bluish-grey font-medium">Zgjidhni vitin dhe muajin</p>
                        <p className="text-xs text-grey-goose">Përzgjidhni periudhën për të cilën dëshironi të gjeneroni raportin</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <span className="text-sm text-hazel-green">2</span>
                      </div>
                      <div>
                        <p className="text-sm text-bluish-grey font-medium">Plotësoni kreditimin e bartur</p>
                        <p className="text-xs text-grey-goose">Shënoni shumën e kreditimit të bartur nga periudha paraprake</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <span className="text-sm text-hazel-green">3</span>
                      </div>
                      <div>
                        <p className="text-sm text-bluish-grey font-medium">Gjeneroni raportin</p>
                        <p className="text-xs text-grey-goose">Klikoni butonin "Gjenero Raportin" për të shfaqur raportin e TVSH-së</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Decorative Elements */}
              <div className="absolute bottom-0 left-0 w-32 h-32 bg-hazel-green/5 rounded-tr-full"></div>
              <div className="absolute top-0 right-0 w-24 h-24 bg-dawn-pink/10 rounded-bl-full"></div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default VatFormPage;
