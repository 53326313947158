import api from './api';

// Sales
export const createSale = async (data) => {
  console.log('Creating sale with data:', data);
  try {
    const response = await api.post('/api/sale', data);
    console.log('Sale creation response:', response);
    
    // Check response structure
    if (response?.data?.statusCode === 201 && response?.data?.message) {
      return response.data;
    }
    
    throw new Error('Invalid response format from server');
  } catch (error) {
    console.error('Error creating sale:', error);
    const errorMessage = error.response?.data?.message || error.message || 'Failed to create sale';
    throw new Error(errorMessage);
  }
};

export const getSales = (filters) => api.get('/api/sale', { params: filters });
export const getSaleById = (id) => api.get(`/api/sale/${id}`);
export const updateSale = async (id, data) => {
  console.log('Updating sale with data:', data);
  try {
    const response = await api.patch(`/api/sale/${id}`, data);
    console.log('Sale update response:', response);
    return response;
  } catch (error) {
    console.error('Error updating sale:', error);
    throw error;
  }
};
export const deleteSale = (id) => api.delete(`/api/sale/${id}`);

// Returns
// export const createSaleReturn = (saleId, data) => api.post(`/api/sale/${saleId}/return`, data);
// export const getSaleReturns = (saleId) => api.get(`/api/sale/${saleId}/returns`);

// Document Number
export const getNextDocumentNumber = async () => {
  try {
    const response = await api.get('/api/sale/next-document-number');
    console.log('Document number API response:', response);
    
    // Check if response has the expected structure
    if (response?.data?.statusCode === 200 && response?.data?.message?.documentNumber) {
      return {
        data: {
          message: response.data.message
        }
      };
    }
    
    // Log unexpected response format
    console.error('Unexpected response format:', response);
    throw new Error('Invalid response format from server');
  } catch (error) {
    console.error('Error getting next document number:', error);
    const errorMessage = error.response?.data?.message || error.message || 'Failed to get document number';
    throw new Error(errorMessage);
  }
};

// Get next return document number
export const getNextReturnDocumentNumber = async () => {
  try {
    const response = await api.get('/api/sale/next-return-document-number');
    console.log('Return document number API response:', response);
    
    if (response?.data?.statusCode === 200 && response?.data?.message?.documentNumber) {
      return {
        data: {
          message: response.data.message
        }
      };
    }
    
    console.error('Unexpected response format:', response);
    throw new Error('Invalid response format from server');
  } catch (error) {
    console.error('Error getting next return document number:', error);
    const errorMessage = error.response?.data?.message || error.message || 'Failed to get return document number';
    throw new Error(errorMessage);
  }
};

// Get sale details for return
export const getSaleForReturn = async (documentNumber) => {
  try {
    console.log('Getting sale for return:', documentNumber);
    if (!documentNumber) {
      throw new Error('Document number is required');
    }
    
    const response = await api.get(`/api/sale/for-return/${encodeURIComponent(documentNumber)}`);
    console.log('Sale for return response:', response);
    
    // Normalize the response to match purchase return format
    if (response?.data?.data?.message) {
      // If we get nested data.data.message structure
      return {
        data: {
          message: response.data.data.message
        }
      };
    } else if (response?.data?.message) {
      // If we get data.message structure
      return response;
    }
    
    throw new Error('Invalid response format from server');
  } catch (error) {
    console.error('Error getting sale for return:', error);
    throw error;
  }
};

// Create sale return
export const createSaleReturn = async (data) => {
  try {
    const response = await api.post(`/api/sale/return`, {
      documentNumber: data.documentNumber,
      originalSaleDocumentNumber: data.originalSaleDocumentNumber,
      issueDate: data.issueDate,
      dueDate: data.dueDate,
      description: data.description,
      locationId: data.locationId,
      clientId: data.clientId,
      items: data.items
    });
    return response.data;
  } catch (error) {
    console.error('Error creating sale return:', error);
    throw error;
  }
};

// Get sale returns
export const getSaleReturns = async (saleId) => {
  try {
    const response = await api.get(`/api/sale/${saleId}/returns`);
    return response.data;
  } catch (error) {
    console.error('Error getting sale returns:', error);
    throw error;
  }
};
