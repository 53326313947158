import api from './api';


export const getAvailableProducts = () => {
  return api.get('/api/products/avaliable-products');
};

export const getProducts = (rowsPerPage, currentPage, searchText) =>
  api.get(
    `/api/products?limit=${rowsPerPage}&page=${currentPage}&searchText=${searchText}`
);

export const createProduct = (values) => api.post(`/api/products`, values);

export const getProduct = (id) => api.get(`/api/products/${id}`);

export const updateProduct = (id, values) =>
  api.patch(`/api/products/${id}`, values);

export const deleteProduct = (id) => api.delete(`/api/products/${id}`);

// export const getAvailableProducts = () => {
//   return api.get('/api/products/avaliable-products');
// };




// export const getProducts = (rowsPerPage, currentPage, searchText) =>
//   api.get(
//     `/api/products?limit=${rowsPerPage}&page=${currentPage}&searchText=${searchText}`
// );

// export const createProduct = (values) => api.post(`/api/products`, values);

// export const getProduct = (id) => api.get(`/api/products/${id}`);

// export const updateProduct = (id, values) =>
//   api.patch(`/api/products/${id}`, values);

// export const deleteProduct = (id) => api.delete(`/api/products/${id}`);
