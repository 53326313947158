import React from 'react';
import { motion } from 'framer-motion';
import { FileText, BookOpen, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';

const MainReportPage = () => {
  const reports = [
    {
      title: 'Libri Kryesor',
      description: 'Shikoni dhe menaxhoni librin kryesor të kontabilitetit',
      icon: BookOpen,
      link: '/accounting/reports/main-book',
      color: 'from-tealish-blue to-sage',
    },
    {
      title: 'Raportet Financiare',
      description: 'Gjeneroni dhe shikoni raportet financiare',
      icon: FileText,
      link: '/reports/financial',
      color: 'from-hazel-green to-sage',
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header Section */}
      <div className="bg-white/80 backdrop-blur-sm border-b border-mercury sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2 text-sm">
              <span className="text-grey-goose hover:text-bluish-grey transition-colors">Raportet</span>
              <ChevronDown className="w-4 h-4 text-grey-goose" />
              <span className="font-medium text-hazel-green">Menaxhimi i Raporteve</span>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">
        {/* Welcome Section */}
        <div className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 mb-8 overflow-hidden">
          <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-8">
            <div className="max-w-2xl">
              <h1 className="text-2xl font-semibold text-bluish-grey mb-3">Raportet dhe Librat</h1>
              <p className="text-grey-goose">
                Menaxhoni dhe gjeneroni raporte të ndryshme financiare dhe kontabël. Kjo përfshin librin kryesor
                dhe raporte të tjera të rëndësishme për biznesin tuaj.
              </p>
            </div>
          </div>

          {/* Reports Grid */}
          <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {reports.map((report) => (
                <Link
                  key={report.title}
                  to={report.link}
                  className="group relative overflow-hidden"
                >
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    className="bg-white rounded-xl border border-mercury/50 p-6 shadow-sm hover:shadow-md transition-all duration-200"
                  >
                    <div className="flex items-start gap-4">
                      <div className={`w-12 h-12 rounded-lg bg-gradient-to-r ${report.color} flex items-center justify-center shadow-lg`}>
                        <report.icon className="w-6 h-6 text-white" />
                      </div>
                      <div>
                        <h3 className="text-lg font-medium text-bluish-grey mb-2 group-hover:text-hazel-green transition-colors">
                          {report.title}
                        </h3>
                        <p className="text-grey-goose text-sm">
                          {report.description}
                        </p>
                      </div>
                    </div>
                  </motion.div>
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Quick Actions */}
        <div className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 overflow-hidden">
          <div className="bg-gradient-to-r from-tealish-blue/10 to-transparent border-b border-mercury/50 p-6">
            <h2 className="text-lg font-medium text-bluish-grey">Veprime të Shpejta</h2>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <button className="flex items-center gap-3 px-4 py-3 bg-seashell text-bluish-grey rounded-xl hover:bg-mercury transition-colors">
                <FileText className="w-5 h-5" />
                <span>Gjenero Raport të Ri</span>
              </button>
              <button className="flex items-center gap-3 px-4 py-3 bg-seashell text-bluish-grey rounded-xl hover:bg-mercury transition-colors">
                <BookOpen className="w-5 h-5" />
                <span>Shiko Librin Kryesor</span>
              </button>
              <button className="flex items-center gap-3 px-4 py-3 bg-seashell text-bluish-grey rounded-xl hover:bg-mercury transition-colors">
                <FileText className="w-5 h-5" />
                <span>Eksporto të Dhënat</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainReportPage;
