import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../../components/custom-input';
import SubmitButton from '../../components/buttons/submit-button';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { ReactTyped } from "react-typed";
import { ReleaseModal } from "../../components/ReleaseModal.tsx";
import OrderPasscodeModal from '../../components/OrderPasscodeModal';
import EntryOrderForm from './EntryOrderForm';

import {
    IconBuildingSkyscraper,
    IconKeyOff,
    IconChefHat,
    IconWaterpolo,
    IconTruckDelivery

} from '@tabler/icons-react';


function EntryPage() {

    const [appVersion, setAppVersion] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isElectron, setIsElectron] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [showOrderForm, setShowOrderForm] = useState(false);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        // Check if running in Electon
        if (window.electron && window.electron.getVersion) {
          window.electron.getVersion().then((version) => {
            setAppVersion(version);
            setIsElectron(true);
          }).catch((error) => {
            console.error('Failed to get app version:', error);
            setIsElectron(false);
          });
        } else {
          console.warn('Not running in Electron environment');
          setIsElectron(false);
        }
    }, []);
      
    const navigate = useNavigate();
    const [page, setpage] = useState("");

    const handleStateChange = () => {
        setpage("page1");
    }

    const baseUrl = window.location.origin;
    const imagePath = baseUrl + '/images/lake-side-logo.png';

    const handleNavigate = (icon) => {
        navigate(`/passcode/${icon}`);
    }
    
    const handleButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('Button clicked');
        setIsModalOpen(true);
    };

    const handleOrderClick = (e) => {
        e.preventDefault();
        setShowOrderModal(true);
    };

    const handleOrderAuthenticated = (response) => {
        setUserData(response.data.data.user);
        setShowOrderForm(true);
        setShowOrderModal(false); // Cclosing the midal
    };

    //this cannot go more wreong

    return (
        <>
            <div className='flex flex-col gap-6 items-center justify-center bg-hazel-green bg-loginBgImage bg-no-repeat bg-center bg-cover min-h-screen p-4 sm:p-6'>
                {showOrderForm ? (
                    <div className="w-full">
                        <button 
                            onClick={() => setShowOrderForm(false)}
                            className="fixed top-4 left-4 z-10 px-4 py-2 bg-white rounded-lg shadow-md text-hazel-green hover:text-sage transition-colors"
                        >
                            ← Back
                        </button>
                        <EntryOrderForm userData={userData} />
                    </div>
                ) : (
                    <>
                        <div className='bg-white rounded-lg w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-2xl shadow-md px-4 sm:px-6 md:px-10 pt-8 sm:pt-10 pb-6 sm:pb-8'>
                            <div className="flex flex-col items-center justify-center gap-3 sm:gap-4">
                                <h3 className='text-hazel-green text-2xl sm:text-3xl md:text-4xl font-bold'>
                                    Lake Side{" "}
                                    <ReactTyped
                                        strings={["Hotel", "Restaurant", "Pool"]}
                                        typeSpeed={150}
                                        loop
                                        backSpeed={30}
                                        cursorChar=" ~"
                                        showCursor={true}
                                    />
                                </h3>
                                <p className='text-hazel-green font-light italic'>
                                    Welcome
                                </p>
                                <img src={imagePath} alt="Lake Side Logo" className="w-36 sm:w-48 md:w-60 lg:w-72 mt-3 sm:mt-4 md:mt-6"/>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-2xl shadow-md px-4 sm:px-6 md:px-10 py-6 sm:py-8">
                            <div className="flex flex-wrap justify-center gap-6 sm:gap-8 md:gap-12">
                                <Link to="/login" className="flex flex-col items-center justify-center gap-1 sm:gap-2 cursor-pointer">
                                    <div className="rounded-full bg-hazel-green flex items-center justify-center p-2 sm:p-3 md:p-4 lg:p-5">
                                        <IconKeyOff className="w-4 h-4 sm:w-6 sm:h-6" color="white" />
                                    </div>
                                    <h3 className='text-hazel-green text-xs sm:text-sm md:text-md font-semibold'>Login</h3>
                                </Link>
                                <Link to="/passcode/hotel" className="flex flex-col items-center justify-center gap-1 sm:gap-2 cursor-pointer">
                                    <div className="rounded-full bg-hazel-green flex items-center justify-center p-2 sm:p-3 md:p-4 lg:p-5">
                                        <IconBuildingSkyscraper className="w-4 h-4 sm:w-6 sm:h-6" color="white" />
                                    </div>
                                    <h3 className='text-hazel-green text-xs sm:text-sm md:text-md font-semibold'>Hotel</h3>
                                </Link>
                                <Link to="/passcode/restaurant" className="flex flex-col items-center justify-center gap-1 sm:gap-2 cursor-pointer">
                                    <div className="rounded-full bg-hazel-green flex items-center justify-center p-2 sm:p-3 md:p-4 lg:p-5">
                                        <IconChefHat className="w-4 h-4 sm:w-6 sm:h-6" color="white" />
                                    </div>
                                    <h3 className='text-hazel-green text-xs sm:text-sm md:text-md font-semibold'>Restaurant</h3>
                                </Link>
                                <Link to="/passcode/pool" className="flex flex-col items-center justify-center gap-1 sm:gap-2 cursor-pointer">
                                    <div className="rounded-full bg-hazel-green flex items-center justify-center p-2 sm:p-3 md:p-4 lg:p-5">
                                        <IconWaterpolo className="w-4 h-4 sm:w-6 sm:h-6" color="white" />
                                    </div>
                                    <h3 className='text-hazel-green text-xs sm:text-sm md:text-md font-semibold'>Pool</h3>
                                </Link>
                                <div
                                    onClick={handleOrderClick}
                                    className="flex flex-col items-center justify-center gap-1 sm:gap-2 cursor-pointer"
                                >
                                    <div className="rounded-full bg-hazel-green flex items-center justify-center p-2 sm:p-3 md:p-4 lg:p-5">
                                        <IconTruckDelivery className="w-4 h-4 sm:w-6 sm:h-6" color="white" />
                                    </div>
                                    <h3 className='text-hazel-green text-xs sm:text-sm md:text-md font-semibold'>Order</h3>
                                </div>
                            </div>
                        </div>

                        <button onClick={handleButtonClick} className="fixed bottom-4 right-4 bg-white px-3 sm:px-4 py-1 sm:py-2 rounded shadow-md">
                            <p className="text-hazel-green text-xs sm:text-sm md:text-md font-semibold">
                                Version: {isElectron ? appVersion : "0.1.0"}
                            </p>
                        </button>
                    </>
                )}
            </div>

            <ReleaseModal
                isOpen={isModalOpen}
                onClose={() => {
                    console.log('Modal closed');
                    setIsModalOpen(false);
                }}
                version={isElectron ? appVersion : "0.1.0"}
                isElectron={isElectron}
            />

            <OrderPasscodeModal 
                isOpen={showOrderModal}
                onClose={() => setShowOrderModal(false)}
                onAuthenticated={handleOrderAuthenticated}
            />
        </>
    );  
}

export default EntryPage;