import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowLeft, Plus, Settings, Upload, X, Save } from 'lucide-react';
import { motion } from 'framer-motion';
import { getCompany } from '../../../api/company';
import { getClients } from '../../../api/accounting';
import { getArticles } from '../../../api/accountingArticle';
import { createSale, getNextDocumentNumber } from '../../../api/sale';
import { toast } from 'react-toastify';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue, SelectGroup, SelectLabel } from '../../../components/shadCN/Select.tsx';
import { Label } from '../../../components/shadCN/Label.tsx';
import { Button } from '../../../components/shadCN/Button.tsx';
import ClientModal from '../../../components/ClientModal';

interface Article {
  id: string;
  articleId?: string;
  name: string;
  unit: string;
  quantity: number;
  priceWithoutVAT: number;
  vatAmount: number;
  totalWithVAT: number;
  vatRate?: number;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Client {
  id: string;
  name: string;
  fiscalNumber: string;
  address?: string;
  email?: string;
  phone?: string;
}

interface AccountingArticle {
  id: string;
  code: string;
  name: string;
  description?: string;
  purchasePrice?: number;
  sellingPrice?: number;
  unit: {
    id: string;
    name: string;
  };
  vatRate: {
    id: string;
    rate: number;
    percentage: number;
  };
}

const AddProductSalePage = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.user);
  const [documentNumber, setDocumentNumber] = useState('');
  const [issueDate, setIssueDate] = useState(new Date().toISOString().split('T')[0]);
  const [dueDate, setDueDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedBuyer, setSelectedBuyer] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [description, setDescription] = useState('');
  const [articles, setArticles] = useState<Article[]>([]);
  const [accountingArticles, setAccountingArticles] = useState<AccountingArticle[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState('');
  const [saleStatus, setSaleStatus] = useState('DRAFT');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch document number first to handle any errors
        console.log('Fetching document number...');
        const docNumberRes = await getNextDocumentNumber();
        console.log('Document number response:', docNumberRes);
        
        const docNumber = docNumberRes?.data?.message?.documentNumber;
        console.log('Setting document number to:', docNumber);
        
        if (!docNumber) {
          console.error('No document number received:', docNumberRes);
          toast.error('Failed to get document number');
          return; // Stop loading if we can't get a document number
        }
        
        setDocumentNumber(docNumber);
        
        // Fetch other data
        const [clientsData, companyRes, articlesData] = await Promise.all([
          getClients(),
          getCompany(),
          getArticles()
        ]);
        
        // Handle clients
        console.log('Raw clients response:', clientsData);
        const clientsList = clientsData?.data || [];
        console.log('Processed clients list:', clientsList);
        if (!clientsList.length) {
          console.warn('No clients found');
        }
        setClients(clientsList);
        
        // Handle locations
        const companyLocations = companyRes?.data?.message?.locations || [];
        if (!companyLocations.length) {
          console.warn('No locations found');
        }
        setLocations(Array.isArray(companyLocations) ? companyLocations : []);
        
        // Handle articles
        const articles = articlesData?.data?.message || [];
        if (!articles.length) {
          console.warn('No articles found');
        }
        console.log('Articles with VAT rates:', articles.map(a => ({
          name: a.name,
          vatRate: a.vatRate,
          sellingPrice: a.sellingPrice
        })));
        setAccountingArticles(articles);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error instanceof Error) {
          const errorMessage = error.message.toLowerCase();
          // Filter out "Sale not found" errors
          if (!errorMessage.includes('sale not found')) {
            toast.error(error.message);
          }
        } else {
          toast.error('Error loading data');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleClientSelect = (clientId: string) => {
    console.log('Selected client ID:', clientId);
    setSelectedBuyer(clientId);
    const selectedClient = clients.find(client => client.id === clientId);
    console.log('Selected client:', selectedClient);
  };

  const handleClientSuccess = async (newClient: any) => {
    setIsClientModalOpen(false);
    // Refresh clients list
    try {
      const clientsRes = await getClients();
      setClients(clientsRes.data);
      // Select the newly created client
      setSelectedBuyer(newClient.id);
      toast.success('Klienti u shtua me sukses');
    } catch (error) {
      console.error('Error refreshing clients:', error);
      toast.error('Failed to refresh clients list');
    }
  };

  const renderClientOption = (client: Client) => {
    return (
      <div className="flex flex-col">
        <span className="font-medium">{client.name}</span>
        <span className="text-sm text-gray-500">
          {client.fiscalNumber && `Nr. Fiskal: ${client.fiscalNumber}`}
          {client.address && ` • ${client.address}`}
        </span>
      </div>
    );
  };

  // Render select options only if data is available
  const renderLocationOptions = () => {
    if (isLoading) {
      return <option value="">Loading locations...</option>;
    }
    
    if (!locations.length) {
      return <option value="">No locations available</option>;
    }

    return [
      <option key="default" value="">Zgjidhni lokacionin</option>,
      ...locations.map(location => (
        <option key={location.id} value={location.id}>{location.name}</option>
      ))
    ];
  };

  const renderClientOptions = () => {
    if (isLoading) {
      return <option value="">Loading clients...</option>;
    }
    
    if (!clients.length) {
      return <option value="">No clients available</option>;
    }

    return [
      <option key="default" value="">Zgjidhni blerësin</option>,
      ...clients.map(client => (
        <option key={client.id} value={client.id}>
          {client.name} {client.fiscalNumber ? `(${client.fiscalNumber})` : ''}
        </option>
      ))
    ];
  };

  const handleArticleSelect = (articleId: string, selectedValue: string) => {
    console.log('Selecting article:', { articleId, selectedValue });
    const selectedArticle = accountingArticles.find(a => a.id === selectedValue);
    if (selectedArticle) {
      console.log('Found selected article:', selectedArticle);
      setArticles(articles.map(article => {
        if (article.id === articleId) {
          const vatRate = selectedArticle.vatRate?.percentage || 0;
          const priceWithoutVAT = selectedArticle.sellingPrice || 0;
          const vatAmount = (priceWithoutVAT * vatRate) / 100;
          
          const updatedArticle = {
            ...article,
            articleId: selectedArticle.id,
            name: selectedArticle.name,
            unit: selectedArticle.unit.name,
            priceWithoutVAT,
            vatAmount,
            vatRate,
            totalWithVAT: priceWithoutVAT + vatAmount
          };
          console.log('Updated article:', updatedArticle);
          return updatedArticle;
        }
        return article;
      }));
    } else {
      console.error('Could not find article with id:', selectedValue);
    }
  };

  const handleArticleChange = (id: string, field: keyof Article, value: any) => {
    setArticles(articles.map(article => {
      if (article.id === id) {
        const selectedAccountingArticle = accountingArticles.find(a => a.id === article.articleId);
        console.log('Found accounting article:', selectedAccountingArticle);
        
        if (!selectedAccountingArticle) {
          console.log('No accounting article found for:', article.name);
          return article;
        }

        // Get VAT rate from percentage field
        const vatRatePercentage = selectedAccountingArticle.vatRate?.percentage || 0;
        console.log('VAT rate percentage for calculation:', vatRatePercentage);
        
        const updatedArticle = { ...article, [field]: value };

        if (field === 'priceWithoutVAT' || field === 'quantity') {
          console.log('Updating price or quantity:', {
            field,
            value,
            currentArticle: article,
            updatedArticle
          });

          const { vatAmount, totalWithVAT } = calculateArticleTotals(updatedArticle, vatRatePercentage);
          const finalArticle = {
            ...updatedArticle,
            vatAmount,
            totalWithVAT
          };

          console.log('Final updated article:', finalArticle);
          return finalArticle;
        }

        return updatedArticle;
      }
      return article;
    }));
  };

  const handleRemoveArticle = (id: string) => {
    setArticles(articles.filter(article => article.id !== id));
  };

  const handleAddArticle = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault(); // Prevent form submission
    }
    const newArticle: Article = {
      id: crypto.randomUUID(),
      name: '',
      unit: '',
      quantity: 1,
      priceWithoutVAT: 0,
      vatAmount: 0,
      totalWithVAT: 0,
    };
    setArticles([...articles, newArticle]);
  };

  const calculateArticleTotals = (article: Article, vatRate: number) => {
    console.log('Calculate totals input:', {
      article,
      vatRate
    });

    const quantity = article.quantity || 0;
    const priceWithoutVAT = article.priceWithoutVAT || 0;
    
    const vatAmount = (priceWithoutVAT * vatRate) / 100;
    const totalWithVAT = priceWithoutVAT * (1 + vatRate / 100);

    const result = {
      vatAmount,
      totalWithVAT
    };

    console.log('Calculate totals result:', result);
    return result;
  };

  const handleSubmit = async () => {
    try {
      if (!user?.id) {
        toast.error('You must be logged in to create a sale');
        return;
      }

      // Validate required fields
      if (!selectedBuyer || !selectedLocation || articles.length === 0) {
        toast.error('Please fill in all required fields');
        return;
      }

      // Validate articles
      for (const article of articles) {
        if (!article.articleId || !article.quantity || !article.priceWithoutVAT) {
          toast.error('Please complete all article details');
          return;
        }
      }

      const saleData = {
        documentNumber,
        issueDate: new Date(issueDate).toISOString(),
        dueDate: new Date(dueDate).toISOString(),
        clientId: selectedBuyer,
        locationId: selectedLocation,
        description,
        type: 'PRODUCT',
        status: saleStatus,
        items: articles.map(article => ({
          articleId: article.articleId,
          quantity: article.quantity,
          priceWithoutVAT: article.priceWithoutVAT,
          vatRate: article.vatRate || 0
        })),
        attachments: [],
        createdBy: user.id,
        adminUserId: user.id
      };

      setIsLoading(true);
      const response = await createSale(saleData);
      
      if (response?.message) {
        toast.success('Sale created successfully');
        navigate('/accounting/sales/v2');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error: any) {
      console.error('Error creating sale:', error);
      toast.error(error.message || 'Error creating sale');
    } finally {
      setIsLoading(false);
    }
  };

  // Totals calculation
  const totalWithoutVAT = articles.reduce((sum, article) => sum + article.priceWithoutVAT, 0);
  const totalVAT = articles.reduce((sum, article) => sum + article.vatAmount, 0);
  const totalWithVAT = articles.reduce((sum, article) => sum + article.totalWithVAT, 0);

  return (
    <div className="min-h-screen bg-ghost-white">
      {/* Header */}
      <div className="bg-white border-b border-mercury/50">
        <div className="px-8 py-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate(-1)}
                className="p-2 text-bluish-grey hover:bg-seashell rounded-xl transition-colors"
              >
                <ArrowLeft className="w-5 h-5" />
              </motion.button>
              <div>
                <h1 className="text-2xl font-semibold text-bluish-grey">Shto shitje të re</h1>
                <p className="text-sm text-grey-goose">Plotësoni të dhënat e shitjes së re</p>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <Button
                onClick={handleSubmit}
                disabled={isLoading}
                className="bg-hazel-green hover:bg-opacity-90 text-white px-6 py-2.5 rounded-xl flex items-center gap-2 disabled:opacity-50"
              >
                {isLoading ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
                    Duke ruajtur...
                  </>
                ) : (
                  <>
                    <Save className="w-5 h-5" />
                    Ruaj
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
          {/* Document Details */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Detajet për Shitje Produkti</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i Dokumentit
                  </label>
                  <input
                    type="text"
                    value={documentNumber}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                  <p className="mt-1 text-sm text-grey-goose">
                    Emri i dokumentit është automatikisht i gjeneruar në bazë të formatit.
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Data e lëshimit
                  </label>
                  <input
                    type="date"
                    value={issueDate}
                    onChange={(e) => setIssueDate(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Data e skadimit
                  </label>
                  <input
                    type="date"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Statusi
                  </label>
                  <Select
                    value={saleStatus}
                    onValueChange={setSaleStatus}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                    >
                      <SelectValue className="text-bluish-grey">
                        {saleStatus === 'DRAFT' ? (
                          <div className="flex items-center gap-2">
                            <span className="w-2 h-2 rounded-full bg-yellow-400"></span>
                            <span>Draft</span>
                          </div>
                        ) : (
                          <div className="flex items-center gap-2">
                            <span className="w-2 h-2 rounded-full bg-green-500"></span>
                            <span>Completed</span>
                          </div>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden"
                      sideOffset={5}
                    >
                      <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                        <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                          Zgjidhni Statusin
                        </h3>
                      </div>
                      <SelectItem
                        value="DRAFT"
                        className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50"
                      >
                        <div className="flex items-center gap-2">
                          <span className="w-2 h-2 rounded-full bg-yellow-400"></span>
                          <span className="text-sm font-medium">Draft</span>
                        </div>
                      </SelectItem>
                      <SelectItem
                        value="COMPLETED"
                        className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer"
                      >
                        <div className="flex items-center gap-2">
                          <span className="w-2 h-2 rounded-full bg-green-500"></span>
                          <span className="text-sm font-medium">Completed</span>
                        </div>
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="relative mb-4">
                  <label htmlFor="buyer" className="mb-2 block text-sm font-medium text-slate-600">
                    Blerësi
                  </label>
                  <div className="flex gap-2 items-start">
                    <div className="flex-1">
                      <Select
                        value={selectedBuyer}
                        onValueChange={handleClientSelect}
                      >
                        <SelectTrigger 
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                        >
                          <SelectValue 
                            placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni blerësin"}
                            className="text-bluish-grey"
                          >
                            {selectedBuyer && (
                              <span className="text-hazel-green">
                                {clients.find(c => c.id === selectedBuyer)?.name}
                              </span>
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent 
                          className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden"
                          sideOffset={5}
                        >
                          <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                            <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                              Zgjidhni Blerësin
                            </h3>
                          </div>
                          {clients.length === 0 ? (
                            <div className="py-3 px-4 text-sm text-gray-500">
                              Nuk ka blerës të regjistruar
                            </div>
                          ) : (
                            clients.map(client => (
                              <SelectItem
                                key={client.id}
                                value={client.id}
                                textValue={client.name}
                                className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                              >
                                <div className="flex flex-col gap-1">
                                  <div className="flex flex-col">
                                    <span className="text-sm sm:text-base font-medium text-hazel-green">
                                      {client.name}
                                    </span>
                                    {client.fiscalNumber && (
                                      <span className="text-xs sm:text-sm text-bluish-grey">
                                        Nr. Fiskal: {client.fiscalNumber}
                                      </span>
                                    )}
                                  </div>
                                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 text-xs sm:text-sm">
                                    {client.address && (
                                      <div className="flex items-center gap-1 text-bluish-grey">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                          <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                        </svg>
                                        <span>{client.address}</span>
                                      </div>
                                    )}
                                    {client.phone && (
                                      <div className="flex items-center gap-1 text-bluish-grey">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                          <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                        </svg>
                                        <span>{client.phone}</span>
                                      </div>
                                    )}
                                    {client.email && (
                                      <div className="flex items-center gap-1 text-bluish-grey">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                        </svg>
                                        <span>{client.email}</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </SelectItem>
                            ))
                          )}
                        </SelectContent>
                      </Select>
                    </div>
                    <Button
                      type="button"
                      onClick={() => setIsClientModalOpen(true)}
                      className="min-w-[40px] h-10 flex items-center justify-center bg-sage/10 hover:bg-sage/20 text-sage rounded-md"
                    >
                      <Plus className="w-5 h-5" />
                    </Button>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Lokacioni
                  </label>
                  <Select
                    value={selectedLocation}
                    onValueChange={setSelectedLocation}
                    disabled={isLoading}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                      disabled={isLoading}
                    >
                      <SelectValue 
                        placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni lokacionin"}
                        className="text-bluish-grey"
                      >
                        {selectedLocation && (
                          <span className="text-hazel-green">
                            {locations.find(l => l.id === selectedLocation)?.name}
                          </span>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden"
                      sideOffset={5}
                    >
                      <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                        <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                          Zgjidhni Lokacionin
                        </h3>
                      </div>
                      {locations.map(location => (
                        <SelectItem
                          key={location.id}
                          value={location.id}
                          textValue={location.name}
                          className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm sm:text-base font-medium text-hazel-green">
                              {location.name}
                            </span>
                            {location.address && (
                              <span className="text-xs sm:text-sm text-bluish-grey">
                                {location.address}
                              </span>
                            )}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="sm:col-span-2">
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Përshkrimi
                  </label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={3}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                    placeholder="Shtoni një përshkrim për këtë shitje..."
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Articles */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4 flex justify-between items-center">
              <h2 className="text-lg font-semibold text-hazel-green">Artikujt</h2>
              <button
                type="button"
                onClick={handleAddArticle}
                className="inline-flex items-center gap-x-2 px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                         hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
              >
                <Plus className="h-5 w-5" />
                Shto Artikull
              </button>
            </div>
            <div className="p-6">
              <div className="space-y-4">
                {articles.length === 0 ? (
                  <div className="flex flex-col items-center justify-center py-8">
                    <div className="w-16 h-16 bg-gradient-to-br from-sage/20 to-hazel-green/10 rounded-lg flex items-center justify-center mb-4">
                      <Plus className="h-8 w-8 text-hazel-green" />
                    </div>
                    <h3 className="text-base font-medium text-bluish-grey mb-1">Nuk ka artikuj</h3>
                    <p className="text-sm text-grey-goose mb-4">Shtoni artikuj për të vazhduar me shitjen</p>
                    <button
                      type="button"
                      onClick={handleAddArticle}
                      className="inline-flex items-center gap-x-2 px-4 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-lg 
                               hover:from-sage hover:to-hazel-green transition-all duration-300 shadow-sm"
                    >
                      <Plus className="h-5 w-5" />
                      Shto Artikull
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="grid grid-cols-12 gap-4 items-center mb-2 text-sm font-medium text-bluish-grey">
                      <div className="col-span-3">Emri i artikullit</div>
                      <div className="col-span-2">Njësia</div>
                      <div className="col-span-1">Sasia</div>
                      <div className="col-span-2">Çmimi pa TVSH</div>
                      <div className="col-span-2">TVSH</div>
                      <div className="col-span-1">Totali</div>
                      <div className="col-span-1"></div>
                    </div>
                    {articles.map((article, index) => (
                      <div key={article.id} className="grid grid-cols-12 gap-4 items-center">
                        <div className="col-span-3">
                          <Select
                            value={article.articleId || ''}
                            onValueChange={(value) => handleArticleSelect(article.id, value)}
                          >
                            <SelectTrigger 
                              className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                            >
                              <SelectValue 
                                placeholder="Zgjidhni artikullin"
                                className="text-bluish-grey"
                              >
                                {article.name || "Zgjidhni artikullin"}
                              </SelectValue>
                            </SelectTrigger>
                            <SelectContent 
                              className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden max-h-[300px]"
                              position="popper"
                              sideOffset={5}
                            >
                              <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                                <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                                  Zgjidhni Artikullin
                                </h3>
                              </div>
                              {(accountingArticles || []).map(item => (
                                <SelectItem
                                  key={item.id}
                                  value={item.id}
                                  textValue={item.name}
                                  className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                                >
                                  <div className="flex flex-col gap-1">
                                    <div className="flex flex-col">
                                      <span className="text-sm sm:text-base font-medium text-hazel-green">
                                        {item.name}
                                      </span>
                                      <span className="text-xs sm:text-sm text-bluish-grey">
                                        Kodi: {item.code}
                                      </span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-1 text-xs sm:text-sm">
                                      <div className="flex items-center gap-1 text-bluish-grey">
                                        <span>Çmimi: {item.sellingPrice?.toFixed(2)} €</span>
                                      </div>
                                      <div className="flex items-center gap-1 text-bluish-grey">
                                        <span>TVSH: {item.vatRate?.percentage || 0}%</span>
                                      </div>
                                      {item.description && (
                                        <div className="col-span-2 text-xs text-grey-goose">
                                          {item.description}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="col-span-2">
                          <input
                            type="text"
                            value={article.unit}
                            onChange={(e) => handleArticleChange(article.id, 'unit', e.target.value)}
                            placeholder="Njësia"
                            className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                          />
                        </div>
                        <div className="col-span-1">
                          <input
                            type="number"
                            value={article.quantity}
                            onChange={(e) => handleArticleChange(article.id, 'quantity', parseFloat(e.target.value))}
                            placeholder="Sasia"
                            className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                          />
                        </div>
                        <div className="col-span-2">
                          <input
                            type="number"
                            value={article.priceWithoutVAT}
                            onChange={(e) => handleArticleChange(article.id, 'priceWithoutVAT', parseFloat(e.target.value))}
                            placeholder="Çmimi pa TVSH"
                            className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                          />
                        </div>
                        <div className="col-span-2">
                          <input
                            type="number"
                            value={article.vatAmount}
                            disabled
                            placeholder="TVSH"
                            className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                          />
                        </div>
                        <div className="col-span-1">
                          <input
                            type="number"
                            value={article.totalWithVAT}
                            disabled
                            placeholder="Totali"
                            className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm"
                          />
                        </div>
                        <div className="col-span-1">
                          <button
                            type="button"
                            onClick={() => handleRemoveArticle(article.id)}
                            className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-watermelon/10 text-watermelon hover:bg-watermelon/20 transition-colors"
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Totals */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Totalet</h2>
            </div>
            <div className="p-6">
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-bluish-grey">Totali pa TVSH:</span>
                  <span className="font-medium">{totalWithoutVAT.toFixed(2)} €</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-bluish-grey">TVSH:</span>
                  <span className="font-medium">{totalVAT.toFixed(2)} €</span>
                </div>
                <div className="flex justify-between items-center text-lg font-semibold text-hazel-green">
                  <span>Totali me TVSH:</span>
                  <span>{totalWithVAT.toFixed(2)} €</span>
                </div>
              </div>
            </div>
          </div>

          {/* Attachments */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Bashkëngjitjet</h2>
            </div>
            <div className="p-6">
              <div className="flex items-center justify-center w-full">
                <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-mercury border-dashed rounded-lg cursor-pointer bg-seashell/30 hover:bg-seashell/50">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <Upload className="w-8 h-8 mb-3 text-bluish-grey" />
                    <p className="mb-2 text-sm text-bluish-grey">
                      <span className="font-medium">Kliko për të ngarkuar</span> ose tërhiq dhe lësho
                    </p>
                    <p className="text-xs text-grey-goose">PDF, PNG, JPG deri në 10MB</p>
                  </div>
                  <input type="file" className="hidden" multiple accept=".pdf,.png,.jpg,.jpeg" />
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* Client Modal */}
      <ClientModal
        isOpen={isClientModalOpen}
        onClose={() => setIsClientModalOpen(false)}
        onSuccess={handleClientSuccess}
      />
    </div>
  );
};

export default AddProductSalePage;
