import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { getExpenseAccounts, getIncomeAccounts, getSubCategories } from '../../api/account';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnyARecord } from 'dns';
import { createItemOrService } from '../../api/itemOrService';

import {useNavigate }from "react-router-dom"

interface Account {
  id: number;
  name: string;
}

interface ItemFormProps {
  isOpen: boolean;
  onClose: () => void;
  onAddItem: (itemData: any) => Promise<void>; 
}

type TabType = 'basic' | 'categories';

enum ItemCategory {
    GOOD = 'GOOD',
    SERVICE = 'SERVICE'
  }
  
  // Enum for the different types of items
  enum ItemType {
    ROOM_RESERVATION = 'ROOM_RESERVATION',
    RESTAURANT_RESERVATION = 'RESTAURANT_RESERVATION',
    POOL_RESERVATION = 'POOL_RESERVATION',
    ORDER_SERVICE = 'ORDER_SERVICE',
    WEEDING_VENUE_RESERVATION = 'WEEDING-VENUE_RESERVATION',
    CONFERENCE_ROOM_RESERVATION = 'CONFERENCE-ROOM_RESERVATION'
  }

export default function ItemForm({ isOpen, onClose , onAddItem}: ItemFormProps) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<TabType>('basic');
  const [type, setType] = useState<'Goods' | 'Service'>('Goods');
  const [loading, setLoading] = useState(false);
  
  // State for accounts
  const [incomeAccounts, setIncomeAccounts] = useState<Account[]>([]);
  const [expenseAccounts, setExpenseAccounts] = useState<Account[]>([]);
  
  // Initialize formData with a specific category value
  const [formData, setFormData] = useState({
    name: '',
    unit: '',
    sellingPrice: '',
    costPrice: '',
    salesDescription: '',
    purchaseDescription: '',
    category: ItemCategory.GOOD, // Set a default value from the enum
    itemType: ItemType.ORDER_SERVICE,
    incomeAccountId: '',
    expenseAccountId: ''
  });
useEffect(() => {
  const fetchAccounts = async () => {
    try {
      const incomeResponse = await getIncomeAccounts();
      const expenseResponse = await getExpenseAccounts();

      console.log("INCOME ACC", incomeResponse);
      console.log("Expense ACC", expenseResponse);

      setIncomeAccounts(incomeResponse.data);
      setExpenseAccounts(expenseResponse.data);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  fetchAccounts();
}, []);



  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    
    try {
        const payload = {
            ...formData,
            sellingPrice: parseFloat(formData.sellingPrice),
            costPrice: parseFloat(formData.costPrice),
            incomeAccountId: formData.incomeAccountId ? parseInt(formData.incomeAccountId) : null, // Ensure this is set correctly
            expenseAccountId: formData.expenseAccountId ? parseInt(formData.expenseAccountId) : null,
        };
        
        console.log("payload", payload);
      // Call the API to create the item or service
      await createItemOrService(payload);
      await onAddItem(payload); 
      toast.success("Item created successfully!");
      onClose(); // Close the modal after successful creation
      navigate("/accounting/items/");
    } catch (error) {
      console.error("Failed to create item:", error);
      toast.error("Failed to create item. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const tabs = [
    { id: 'basic', label: 'Basic Information' },
    { id: 'categories', label: 'Categories & Accounts' }
  ];

  const renderBasicInfo = () => (
    <div className="space-y-6">
      {/* Type Selection */}
      <div className="space-y-2">
        <label className="block text-watermelon text-sm mb-1.5">
          Type<span className="text-watermelon">*</span>
        </label>
        <div className="flex gap-4">
          {['Goods', 'Service'].map((option) => (
            <label key={option} className="flex items-center gap-2">
              <input
                type="radio"
                checked={type === option}
                onChange={() => setType(option as 'Goods' | 'Service')}
                className="text-hazel-green"
              />
              <span className="text-bluish-grey">{option}</span>
            </label>
          ))}
        </div>
      </div>

      {/* Basic Information */}
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block text-watermelon text-sm mb-1.5">
            Name<span className="text-watermelon">*</span>
          </label>
          <input
            type="text"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow"
            required
          />
        </div>
        <div>
          <label className="block text-watermelon text-sm mb-1.5">
            Unit<span className="text-watermelon">*</span>
          </label>
          <select
            value={formData.unit}
            onChange={(e) => setFormData({ ...formData, unit: e.target.value })}
            className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow bg-white"
            required
          >
            <option value="">Select Unit</option>
            <option value="pcs">Pieces</option>
            <option value="kg">Kilograms</option>
            <option value="g">Grams</option>
            <option value="l">Liters</option>
            <option value="night">Night</option>
            <option value="reservation">Reservation</option>
            <option value="t">Ton</option>
            <option value="1 unit">1 Unit</option>
            <option value="hrs">Hours</option>
          </select>
        </div>
      </div>

      {/* Sales and Purchase Information */}
      <div className="grid grid-cols-2 gap-6">
        {/* Sales Information */}
        <div className="space-y-4">
          <h3 className="text-sm font-medium text-bluish-grey uppercase tracking-wide mb-3 pb-2 border-b border-mercury">
            Sales Information
          </h3>
          <div className="space-y-4">
            <div>
              <label className="block text-watermelon text-sm mb-1.5">
                Selling Price<span className="text-watermelon">*</span>
              </label>
              <div className="flex">
                <span className="px-3 py-2.5 bg-tealish-blue border border-r-0 border-mercury rounded-l-md">€</span>
                <input
                  type="number"
                  value={formData.sellingPrice}
                  onChange={(e) => setFormData({ ...formData, sellingPrice: e.target.value })}
                  className="flex-1 p-2.5 border border-mercury rounded-r-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-watermelon text-sm mb-1.5">
                Description
              </label>
              <textarea
                value={formData.salesDescription}
                onChange={(e) => setFormData({ ...formData, salesDescription: e.target.value })}
                className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                rows={3}
              />
            </div>
          </div>
        </div>

        {/* Purchase Information */}
        <div className="space-y-4">
          <h3 className="text-sm font-medium text-bluish-grey uppercase tracking-wide mb-3 pb-2 border-b border-mercury">
            Purchase Information
          </h3>
          <div className="space-y-4">
            <div>
              <label className="block text-watermelon text-sm mb-1.5">
                Cost Price<span className="text-watermelon">*</span>
              </label>
              <div className="flex">
                <span className="px-3 py-2.5 bg-tealish-blue border border-r-0 border-mercury rounded-l-md">€</span>
                <input
                  type="number"
                  value={formData.costPrice}
                  onChange={(e) => setFormData({ ...formData, costPrice: e.target.value })}
                  className="flex-1 p-2.5 border border-mercury rounded-r-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-watermelon text-sm mb-1.5">
                Description
              </label>
              <textarea
                value={formData.purchaseDescription}
                onChange={(e) => setFormData({ ...formData, purchaseDescription: e.target.value })}
                className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                rows={3}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderCategoriesAndAccounts = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        {/* Item Type and Category */}
        <div className="space-y-4">
          <div>
            <label className="block text-watermelon text-sm mb-1.5">
              Item Type<span className="text-watermelon">*</span>
            </label>
            <select
              value={formData.itemType}
              onChange={(e) => setFormData({ ...formData, itemType: e.target.value as any })}
              className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow bg-white"
              required
            >
              {Object.values(ItemType).map((type) => (
                <option key={type} value={type}>
                  {type.replace('_', ' ').toLowerCase().replace(/\b\w/g, l => l.toUpperCase())}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-watermelon text-sm mb-1.5">
              Category<span className="text-watermelon">*</span>
            </label>
            <select
              value={formData.category}
              onChange={(e) => setFormData({ ...formData, category: e.target.value as ItemCategory })} // Cast to ItemCategory
              className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow bg-white"
              required
            >
              {Object.values(ItemCategory).map((category) => (
                <option key={category} value={category}>
                  {category.charAt(0) + category.slice(1).toLowerCase()}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Account Selection */}
        <div className="space-y-4">
          <div>
            <label className="block text-watermelon text-sm mb-1.5">
              Income Account
            </label>
            <select
              value={formData.incomeAccountId}
              onChange={(e) => setFormData({ ...formData, incomeAccountId: e.target.value })}
              className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow bg-white"
            >
              <option value="">Select Income Account</option>
              {incomeAccounts.map((account) => (
                <option key={account.id} value={account.id}>{account.name}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-watermelon text-sm mb-1.5">
              Expense Account
            </label>
            <select
              value={formData.expenseAccountId}
              onChange={(e) => setFormData({ ...formData, expenseAccountId: e.target.value })}
              className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow bg-white"
            >
              <option value="">Select Expense Account</option>
              {expenseAccounts.map((account) => (
                <option key={account.id} value={account.id}>{account.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        transition={{ type: "spring", duration: 0.3 }}
        className="bg-white rounded-lg w-full max-w-4xl shadow-xl max-h-[90vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center px-6 py-4 border-b border-mercury bg-seashell rounded-t-lg">
          <h2 className="text-xl font-semibold text-bluish-grey">New Item</h2>
          <button 
            onClick={onClose}
            className="text-silver hover:text-bluish-grey transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        {/* Tabs */}
        <div className="border-b border-mercury">
          <div className="flex px-6">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id as TabType)}
                className={`px-6 py-3 text-sm font-medium transition-colors relative ${
                  activeTab === tab.id
                    ? 'text-hazel-green'
                    : 'text-bluish-grey hover:text-hazel-green'
                }`}
              >
                {tab.label}
                {activeTab === tab.id && (
                  <motion.div
                    layoutId="activeTab"
                    className="absolute bottom-0 left-0 right-0 h-0.5 bg-hazel-green"
                    initial={false}
                    transition={{ type: "spring", stiffness: 500, damping: 30 }}
                  />
                )}
              </button>
            ))}
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          {activeTab === 'basic' ? renderBasicInfo() : renderCategoriesAndAccounts()}

          <div className="flex justify-end space-x-3 mt-6 pt-4 border-t border-mercury">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm text-bluish-grey hover:bg-seashell rounded-md transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 text-sm ${loading ? 'bg-gray-400' : 'bg-hazel-green hover:bg-sage'} text-white rounded-md transition-colors`}
              disabled={loading}
            >
              {loading ? 'Creating...' : 'Create Item'}
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
}
