import api from "./api"; // Assuming api is configured for your base URL and Axios

// Create a new budget
export const createBudget = (budgetData) => api.post("/api/budgets", budgetData);

// Get all budgets
export const getBudgets = () => api.get("/api/budgets");

// Get a budget by ID
export const getBudgetById = (id) => api.get(`/api/budgets/${id}`);

// Update a budget
export const updateBudget = (id, budgetData) => api.put(`/api/budgets/${id}`, budgetData);

// Delete a budget
export const deleteBudget = (id) => api.delete(`/api/budgets/${id}`);
