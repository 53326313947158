import React, { useState } from 'react';
import { RefreshCw, Save, AlertCircle } from 'lucide-react';

interface StatusChangeProps {
  roomNumber: string;
  currentStatus: string;
}

const STATUS_OPTIONS = [
  { value: 'BLOCKED', label: 'Blocked' },
  { value: 'IN_MAINTENANCE', label: 'In Maintenance' },
  { value: 'MAINTENANCE_COMPLETE', label: 'Maintenance Complete' },
  { value: 'READY', label: 'Ready for Use' },
];

export default function StatusChange({
  roomNumber,
  currentStatus,
}: StatusChangeProps) {
  const [selectedStatus, setSelectedStatus] = useState(currentStatus);
  const [notes, setNotes] = useState('');

  const handleStatusChange = async (e: React.FormEvent) => {
    e.preventDefault();
    // Implement status change logic here
    console.log('Status changed:', { roomNumber, status: selectedStatus, notes });
  };

  return (
    <div className="space-y-6">
      {/* Status Change Form */}
      <form onSubmit={handleStatusChange} className="space-y-6">
        <div className="bg-seashell rounded-lg p-4">
          <h3 className="text-lg font-semibold text-hazel-green mb-4">Update Room Status</h3>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-bluish-grey mb-2">
                Current Status
              </label>
              <div className="px-3 py-2 bg-tealish-blue rounded-md border border-grey-goose text-hazel-green font-medium">
                {currentStatus}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-bluish-grey mb-2">
                New Status
              </label>
              <select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                className="w-full px-3 py-2 rounded-md border border-grey-goose focus:ring-2 focus:ring-hazel-green focus:border-hazel-green"
              >
                {STATUS_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-bluish-grey mb-2">
                Status Change Notes
              </label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                rows={4}
                className="w-full px-3 py-2 rounded-md border border-grey-goose focus:ring-2 focus:ring-hazel-green focus:border-hazel-green"
                placeholder="Enter notes about the status change..."
              />
            </div>
          </div>
        </div>

        {/* Warning Message */}
        <div className="flex items-center gap-2 text-sm text-bluish-grey bg-seashell p-3 rounded-md">
          <AlertCircle size={16} className="text-watermelon" />
          <span>
            Changing the room status will update the room's availability and maintenance records.
          </span>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => setSelectedStatus(currentStatus)}
            className="px-4 py-2 text-sm bg-bluish-grey text-white rounded-md hover:bg-opacity-90 transition-colors inline-flex items-center gap-2"
          >
            <RefreshCw size={16} />
            Reset
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm bg-hazel-green text-white rounded-md hover:bg-sage transition-colors inline-flex items-center gap-2"
          >
            <Save size={16} />
            Update Status
          </button>
        </div>
      </form>
    </div>
  );
}