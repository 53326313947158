import React from 'react';

interface CustomInputProps {
  type: string;
  name: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  showError?: boolean;
  errorMessage?: string;
}

const CustomInput: React.FC<CustomInputProps> = ({
  type,
  name,
  label,
  value,
  onChange,
  onBlur,
  showError,
  errorMessage,
}) => {
  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-sm font-medium text-hazel-green mb-1">
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={`block w-full px-4 py-3 rounded-lg border ${
          showError ? 'border-red-500' : 'border-gray-300'
        } focus:ring-2 focus:ring-hazel-green focus:border-hazel-green transition-colors`}
      />
      {showError && (
        <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
      )}
    </div>
  );
}

export default CustomInput;