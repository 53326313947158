import React from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../../components/custom-input';
import { SubmitButton, CancelButton } from '../../components/buttons';
import { changePassword } from '../../api/users';

function ChangePassword() {
  const { user } = useSelector((state) => state.user);

  return (
    <div className='w-full mt-2'>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        }}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string()
            .min(8, 'Password must be at least 8 characters.')
            .required('Password is required*')
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
              'Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character.'
            ),
          newPassword: Yup.string()
            .min(8, 'Password must be at least 8 characters.')
            .required('Password is required*')
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
              'Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character.'
            ),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords should match!')
            .required('Confirm Password is required*')
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            await changePassword(user.id, values);
            setSubmitting(false);
            resetForm();
          } catch (err) {
            setErrors({
              submit: err?.response?.data?.message || err.message
            });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm
        }) => (
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-2 gap-x-6 gap-y-3'>
              <CustomInput
                type='password'
                name='currentPassword'
                label='Current Password'
                value={values.currentPassword}
                onChange={handleChange('currentPassword')}
                onBlur={handleBlur}
                showError={errors.currentPassword && touched.currentPassword}
                errorMessage={errors.currentPassword}
              />
              <div />
              <CustomInput
                type='password'
                name='newPassword'
                label='New Password'
                value={values.newPassword}
                onChange={handleChange('newPassword')}
                onBlur={handleBlur}
                showError={errors.newPassword && touched.newPassword}
                errorMessage={errors.newPassword}
              />
              <CustomInput
                type='password'
                name='confirmPassword'
                label='Confirm Password'
                value={values.confirmPassword}
                onChange={handleChange('confirmPassword')}
                onBlur={handleBlur}
                showError={errors.confirmPassword && touched.confirmPassword}
                errorMessage={errors.confirmPassword}
              />
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
            </div>
            <div className='flex justify-end items-center w-full mt-4 text-sm'>
              <CancelButton onCancel={() => resetForm()} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/6'
                title='Update password'
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ChangePassword;
