import api from './api';

// Company Info
export const getCompany = async () => {
  const response = await api.get('/api/company');
  return response;
};

export const updateCompany = async (data) => {
  const response = await api.put('/api/company', data);
  return response;
};

// Bank Accounts
export const createBankAccount = async (data) => {
  return await api.post('/api/company/bank-accounts', data);
};

export const getBankAccounts = async (companyId) => {
  return await api.get(`/api/company/bank-accounts/${companyId}`);
};

export const updateBankAccount = async (id, data) => {
  return await api.patch(`/api/company/bank-accounts/${id}`, data);
};

export const deleteBankAccount = async (id) => {
  return await api.delete(`/api/company/bank-accounts/${id}`);
};

// Fiscal Years
export const createFiscalYear = (data) => {
  // Convert string dates to Date objects
  const formattedData = {
    ...data,
    startDate: new Date(data.startDate),
    endDate: new Date(data.endDate)
  };
  return api.post('/api/company/fiscal-years', formattedData);
};

export const getFiscalYears = (companyId) => api.get(`/api/company/fiscal-years/${companyId}`);
export const updateFiscalYear = (id, data) => api.patch(`/api/company/fiscal-years/${id}`, data);
export const deleteFiscalYear = (id) => api.delete(`/api/company/fiscal-years/${id}`);

// Locations
export const createLocation = (data) => api.post('/api/company/locations', data);
export const getLocations = (companyId) => api.get(`/api/company/locations/${companyId}`);
export const updateLocation = (id, data) => api.patch(`/api/company/locations/${id}`, data);
export const deleteLocation = (id) => api.delete(`/api/company/locations/${id}`);
