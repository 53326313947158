import React from 'react';
import { format } from 'date-fns';
import { X, Package, Calendar, User, MapPin, FileText, DollarSign, Truck } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface PurchaseDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  purchase: any;
}

const PurchaseDetailsModal: React.FC<PurchaseDetailsModalProps> = ({ isOpen, onClose, purchase }) => {
  if (!purchase) return null;

  const DetailCard = ({ icon: Icon, label, value }: { icon: any, label: string, value: any }) => (
    <div className="flex items-center p-4 bg-seashell rounded-xl hover:bg-tealish-blue transition-colors duration-200">
      <Icon className="w-5 h-5 text-hazel-green mr-3" />
      <div>
        <p className="text-xs font-medium text-bluish-grey mb-1">{label}</p>
        <p className="text-sm font-semibold text-hazel-green">{value}</p>
      </div>
    </div>
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black/30 backdrop-blur-sm">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="relative w-full max-w-5xl p-1 mx-4 bg-gradient-to-br from-sage/20 to-hazel-green/20 rounded-2xl shadow-xl"
          >
            <div className="bg-white rounded-xl overflow-hidden">
              {/* Header */}
              <div className="relative px-6 py-4 bg-gradient-to-r from-seashell to-white border-b border-grey-goose/30">
                <h2 className="text-2xl font-semibold text-hazel-green">Purchase Details</h2>
                <button
                  onClick={onClose}
                  className="absolute right-4 top-4 p-2 rounded-full hover:bg-seashell transition-colors"
                >
                  <X className="w-6 h-6 text-bluish-grey" />
                </button>
              </div>

              {/* Content */}
              <div className="p-6">
                {/* Basic Info Grid */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                  <DetailCard
                    icon={FileText}
                    label="Document Number"
                    value={purchase.documentNumber}
                  />
                  <DetailCard
                    icon={Calendar}
                    label="Issue Date"
                    value={format(new Date(purchase.issueDate), 'dd/MM/yyyy')}
                  />
                  <DetailCard
                    icon={Calendar}
                    label="Due Date"
                    value={format(new Date(purchase.dueDate), 'dd/MM/yyyy')}
                  />
                  <DetailCard
                    icon={Truck}
                    label="Supplier"
                    value={purchase.supplier?.businessName || 'N/A'}
                  />
                  <DetailCard
                    icon={MapPin}
                    label="Location"
                    value={purchase.location?.name || 'N/A'}
                  />
                  <DetailCard
                    icon={Package}
                    label="Status"
                    value={purchase.status}
                  />
                </div>

                {/* Items Table */}
                <div className="mb-8">
                  <h3 className="text-lg font-semibold text-hazel-green mb-4">Items</h3>
                  <div className="overflow-x-auto">
                    <table className="min-w-full bg-white rounded-xl border border-grey-goose/30">
                      <thead>
                        <tr className="bg-seashell">
                          <th className="px-4 py-3 text-left text-sm font-semibold text-bluish-grey">Article</th>
                          <th className="px-4 py-3 text-right text-sm font-semibold text-bluish-grey">Quantity</th>
                          <th className="px-4 py-3 text-right text-sm font-semibold text-bluish-grey">Price</th>
                          <th className="px-4 py-3 text-right text-sm font-semibold text-bluish-grey">VAT Rate</th>
                          <th className="px-4 py-3 text-right text-sm font-semibold text-bluish-grey">VAT Amount</th>
                          <th className="px-4 py-3 text-right text-sm font-semibold text-bluish-grey">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchase.items.map((item: any) => (
                          <tr key={item.id} className="border-t border-grey-goose/30">
                            <td className="px-4 py-3 text-sm text-hazel-green">{item.article.name}</td>
                            <td className="px-4 py-3 text-sm text-right text-hazel-green">{item.quantity}</td>
                            <td className="px-4 py-3 text-sm text-right text-hazel-green">{item.priceWithoutVAT}</td>
                            <td className="px-4 py-3 text-sm text-right text-hazel-green">{item.vatRate}%</td>
                            <td className="px-4 py-3 text-sm text-right text-hazel-green">{item.vatAmount}</td>
                            <td className="px-4 py-3 text-sm text-right text-hazel-green">{item.totalWithVAT}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Totals */}
                <div className="flex justify-end space-x-8">
                  <DetailCard
                    icon={DollarSign}
                    label="Total without VAT"
                    value={purchase.totalWithoutVAT}
                  />
                  <DetailCard
                    icon={DollarSign}
                    label="Total VAT"
                    value={purchase.totalVAT}
                  />
                  <DetailCard
                    icon={DollarSign}
                    label="Total with VAT"
                    value={purchase.totalWithVAT}
                  />
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default PurchaseDetailsModal;
