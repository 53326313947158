import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, Settings, Upload } from 'lucide-react';

import { getCompany } from '../../../api/company';
import { getSuppliers } from '../../../api/accounting';
import { getArticles } from '../../../api/accountingArticle';
import { createPurchase, getNextExpenseDocumentNumber } from '../../../api/purchase';
import { toast } from 'react-toastify';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '../../../components/shadCN/Select.tsx';
import { Button } from '../../../components/shadCN/Button.tsx';
import SupplierModal from '../../../components/SupplierModal';

interface Article {
  id: string;
  articleId?: string;
  name: string;
  unit: string;
  quantity: number;
  priceWithoutVAT: number;
  vatAmount: number;
  totalWithVAT: number;
  vatRate: number;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Supplier {
  id: string;
  name: string;
  fiscalNumber: string;
  address?: string;
  email?: string;
  phone?: string;
}

interface AccountingArticle {
  id: string;
  code: string;
  name: string;
  description?: string;
  purchasePrice?: number;
  sellingPrice?: number;
  unit: {
    id: string;
    name: string;
  };
  vatRate: {
    id: string;
    rate: number;
    percentage: number;
  };
}

const AddExpensePage = () => {
  const navigate = useNavigate();
  const [documentNumber, setDocumentNumber] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [issueDate, setIssueDate] = useState(new Date().toISOString().split('T')[0]);
  const [expiryDate, setExpiryDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [articles, setArticles] = useState<Article[]>([]);
  const [accountingArticles, setAccountingArticles] = useState<AccountingArticle[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch document number first
        console.log('Fetching expense document number...');
        const docNumberRes = await getNextExpenseDocumentNumber();
        console.log('Document number response:', docNumberRes);
        
        const docNumber = docNumberRes?.data?.message?.documentNumber;
        console.log('Setting document number to:', docNumber);
        
        if (!docNumber) {
          console.error('No document number received:', docNumberRes);
          toast.error('Failed to get document number');
          return;
        }
        
        setDocumentNumber(docNumber);
        
        // Fetch other data in parallel
        const [companyRes, suppliersRes, articlesData] = await Promise.all([
          getCompany(),
          getSuppliers(),
          getArticles()
        ]);

        // Handle locations
        const companyLocations = companyRes?.data?.message?.locations || [];
        setLocations(Array.isArray(companyLocations) ? companyLocations : []);

        // Handle suppliers
        const suppliersList = suppliersRes?.data || [];
        setSuppliers(suppliersList);

        // Handle articles
        const articles = articlesData?.data?.message || [];
        setAccountingArticles(articles);

      } catch (error: any) {
        console.error('Error fetching data:', error);
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch initial data';
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  const handleAddArticle = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    const newArticle: Article = {
      id: crypto.randomUUID(),
      name: '',
      unit: '',
      quantity: 1,
      priceWithoutVAT: 0,
      vatAmount: 0,
      totalWithVAT: 0,
      vatRate: 18,
    };
    setArticles([...articles, newArticle]);
  };

  const handleArticleSelect = (articleId: string, selectedValue: string) => {
    const selectedArticle = accountingArticles.find(a => a.id === selectedValue);
    if (selectedArticle) {
      setArticles(articles.map(article => {
        if (article.id === articleId) {
          const vatRate = selectedArticle.vatRate?.percentage || 0;
          const priceWithoutVAT = selectedArticle.purchasePrice || 0;
          const quantity = article.quantity || 1;
          const amount = priceWithoutVAT * quantity;
          const vatAmount = Number((amount * vatRate / 100).toFixed(2));
          const totalWithVAT = Number((amount + vatAmount).toFixed(2));
          
          return {
            ...article,
            articleId: selectedArticle.id,
            name: selectedArticle.name,
            unit: selectedArticle.unit.name,
            priceWithoutVAT,
            vatRate,
            vatAmount,
            totalWithVAT
          };
        }
        return article;
      }));
    }
  };

  const handleArticleChange = (articleId: string, field: string, value: string) => {
    setArticles(articles.map(article => {
      if (article.id === articleId) {
        const updatedArticle = { ...article, [field]: value };
        const quantity = Number(updatedArticle.quantity) || 0;
        const priceWithoutVAT = Number(updatedArticle.priceWithoutVAT) || 0;
        const vatRate = Number(updatedArticle.vatRate) || 0;
        
        const amount = quantity * priceWithoutVAT;
        const vatAmount = Number((amount * vatRate / 100).toFixed(2));
        const totalWithVAT = Number((amount + vatAmount).toFixed(2));

        return {
          ...updatedArticle,
          vatAmount,
          totalWithVAT
        };
      }
      return article;
    }));
  };

  const calculateTotals = () => {
    let totalWithoutVAT = 0;
    let totalVAT = 0;

    articles.forEach((article) => {
      const quantity = Number(article.quantity) || 0;
      const price = Number(article.priceWithoutVAT) || 0;
      const vatRate = Number(article.vatRate) || 0;

      const lineTotal = quantity * price;
      totalWithoutVAT += lineTotal;
      totalVAT += Number((lineTotal * vatRate / 100).toFixed(2));
    });

    return {
      totalWithoutVAT: Number(totalWithoutVAT.toFixed(2)),
      totalVAT: Number(totalVAT.toFixed(2)),
      totalWithVAT: Number((totalWithoutVAT + totalVAT).toFixed(2)),
    };
  };

  const calculateVatGroups = () => {
    const vatGroups: { [key: string]: number } = {};

    articles.forEach((article) => {
      const quantity = Number(article.quantity) || 0;
      const price = Number(article.priceWithoutVAT) || 0;
      const vatRate = Number(article.vatRate) || 0;

      const lineTotal = quantity * price;
      const vatAmount = Number((lineTotal * vatRate / 100).toFixed(2));

      if (vatRate in vatGroups) {
        vatGroups[vatRate] += vatAmount;
      } else {
        vatGroups[vatRate] = vatAmount;
      }
    });

    return vatGroups;
  };

  const handleSupplierSelect = (supplierId: string) => {
    setSelectedSupplier(supplierId);
  };
  
  const handleSupplierSuccess = async (newSupplier: any) => {
    setIsSupplierModalOpen(false);
    try {
      const suppliersRes = await getSuppliers();
      setSuppliers(Array.isArray(suppliersRes.data) ? suppliersRes.data : []);
      setSelectedSupplier(newSupplier.id);
      toast.success('Furnitori u shtua me sukses');
    } catch (error) {
      console.error('Error refreshing suppliers:', error);
      toast.error('Failed to refresh suppliers list');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      // Validate required fields
      if (!selectedSupplier || !articles.length || !issueDate || !expiryDate) {
        toast.error('Please fill in all required fields');
        return;
      }

      // Format dates to ISO string format
      const formattedIssueDate = new Date(issueDate).toISOString();
      const formattedDueDate = new Date(expiryDate).toISOString();

      // Calculate totals
      const totals = calculateTotals();

      // Format articles data
      const formattedArticles = articles.map(article => ({
        articleId: article.articleId,
        quantity: Number(article.quantity),
        priceWithoutVAT: Number(article.priceWithoutVAT),
        vatRate: Number(article.vatRate),
        vatAmount: Number(article.vatAmount),
        totalWithVAT: Number(article.totalWithVAT)
      }));

      const expenseData = {
        type: 'EXPENSE',
        documentNumber: documentNumber,
        issueDate: formattedIssueDate,
        dueDate: formattedDueDate,
        supplierId: selectedSupplier,
        locationId: selectedLocation || undefined,
        description: description,
        totalWithoutVAT: totals.totalWithoutVAT,
        totalVAT: totals.totalVAT,
        totalWithVAT: totals.totalWithVAT,
        items: formattedArticles,
        status: 'DRAFT'
      };

      await createPurchase(expenseData);
      toast.success('Expense created successfully');
      navigate('/administration/accounting/expenses');
      
    } catch (error) {
      console.error('Error creating expense:', error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Failed to create expense');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/accounting/purchases')}
                className="inline-flex items-center gap-x-2 text-bluish-grey hover:text-hazel-green transition-colors"
              >
                <ArrowLeft className="h-5 w-5" />
                Kthehu
              </button>
            </div>
            <h1 className="text-lg font-semibold text-hazel-green">Shto Shpenzim</h1>
            <div className="flex items-center gap-x-4">
              <button
                onClick={handleSubmit}
                className="inline-flex items-center gap-x-2 px-6 py-2.5 text-sm text-white bg-hazel-green hover:bg-hazel-green/90 rounded-lg transition-colors"
              >
                Ruaj
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Document Details */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Detajet për Shpenzim</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i Dokumentit
                  </label>
                  <input
                    type="text"
                    value={documentNumber}
                    disabled
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                  />
                  <p className="mt-1 text-sm text-grey-goose">
                    Emri i dokumentit është automatikisht i gjeneruar në bazë të formatit.
                  </p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Numri i faturës
                  </label>
                  <input
                    type="text"
                    value={invoiceNumber}
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                    className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green"
                    placeholder="Numri i faturës së pranuar nga furnitori"
                  />
                  <p className="mt-1 text-sm text-grey-goose">
                    Numri i faturës së pranuar nga furnitori.
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-2">
                      Data e lëshimit
                    </label>
                    <input
                      type="date"
                      value={issueDate}
                      onChange={(e) => setIssueDate(e.target.value)}
                      className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-bluish-grey mb-2">
                      Data e skadimit
                    </label>
                    <input
                      type="date"
                      value={expiryDate}
                      onChange={(e) => setExpiryDate(e.target.value)}
                      className="block w-full rounded-lg border border-mercury px-4 py-2.5 text-bluish-grey shadow-sm focus:border-hazel-green focus:ring-1 focus:ring-hazel-green hover:border-hazel-green/50 transition-colors"
                    />
                  </div>
                </div>
                {/* Supplier Select */}
                <div className="relative mb-4">
                  <label htmlFor="supplier" className="mb-2 block text-sm font-medium text-slate-600">
                    Furnitori
                  </label>
                  <div className="flex gap-2 items-start">
                    <div className="flex-1">
                      <Select
                        value={selectedSupplier}
                        onValueChange={handleSupplierSelect}
                        disabled={isLoading}
                      >
                        <SelectTrigger 
                          className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                          disabled={isLoading}
                        >
                          <SelectValue 
                            placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni furnitorin"}
                            className="text-bluish-grey"
                          >
                            {selectedSupplier && (
                              <span className="text-hazel-green">
                                {suppliers.find(s => s.id === selectedSupplier)?.businessName || suppliers.find(s => s.id === selectedSupplier)?.name}
                              </span>
                            )}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent 
                          className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                          sideOffset={5}
                          position="popper"
                          align="start"
                        >
                          <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                            <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                              Zgjidhni Furnitorin
                            </h3>
                          </div>
                          {suppliers.map(supplier => (
                            <SelectItem
                              key={supplier.id}
                              value={supplier.id}
                              textValue={supplier.businessName || supplier.name}
                              className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                            >
                              {/* Hidden span for the select field display */}
                              <span className="hidden">
                                {supplier.businessName || supplier.name}
                              </span>
                              
                              {/* Detailed content for dropdown */}
                              <div className="flex flex-col gap-1">
                                <div className="flex items-center justify-between">
                                  <div className="flex flex-col">
                                    <span className="text-sm sm:text-base font-medium text-hazel-green">
                                      {supplier.businessName || supplier.name}
                                    </span>
                                    {supplier.tradeName && supplier.tradeName !== supplier.businessName && (
                                      <span className="text-xs sm:text-sm text-bluish-grey">
                                        Trading as: {supplier.tradeName}
                                      </span>
                                    )}
                                  </div>
                                  {supplier.businessType && (
                                    <span className="px-2 py-0.5 rounded-full text-xs sm:text-sm font-medium bg-sage/10 text-sage">
                                      {supplier.businessType}
                                    </span>
                                  )}
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 text-xs sm:text-sm">
                                  {supplier.fiscalNumber && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm3 1h6v4H7V5zm8 8v2h1v-2h-1zm-2-6H7v4h6V7zm2 4h3v-2h-3v2zm-2-6h3v2H7V5zm4 8h1v2h-1v-2zm-1-4h1v2h-1v-2z" clipRule="evenodd" />
                                      </svg>
                                      <span>Nr. Fiskal: {supplier.fiscalNumber}</span>
                                    </div>
                                  )}
                                  {supplier.address && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                      </svg>
                                      <span>{supplier.address}</span>
                                    </div>
                                  )}
                                  {supplier.phone && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                      </svg>
                                      <span>{supplier.phone}</span>
                                    </div>
                                  )}
                                  {supplier.email && (
                                    <div className="flex items-center gap-1 text-bluish-grey">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                      </svg>
                                      <span>{supplier.email}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <Button
                      type="button"
                      variant="outline"
                      size="icon"
                      onClick={() => setIsSupplierModalOpen(true)}
                      className="flex-shrink-0 h-10 w-10 rounded-lg hover:bg-gray-100"
                    >
                      <Plus className="h-5 w-5" />
                    </Button>
                  </div>
                </div>
                {/* Location Select */}
                <div className="mb-4">
                  <label htmlFor="location" className="mb-2 block text-sm font-medium text-slate-600">
                    Lokacioni
                  </label>
                  <Select
                    value={selectedLocation}
                    onValueChange={setSelectedLocation}
                    disabled={isLoading}
                  >
                    <SelectTrigger 
                      className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                      disabled={isLoading}
                    >
                      <SelectValue 
                        placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni lokacionin"}
                        className="text-bluish-grey"
                      >
                        {selectedLocation && (
                          <span className="text-hazel-green">
                            {locations.find(l => l.id === selectedLocation)?.name}
                          </span>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent 
                      className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                      sideOffset={5}
                      position="popper"
                      align="start"
                    >
                      {locations.map(location => (
                        <SelectItem
                          key={location.id}
                          value={location.id}
                          textValue={location.name}
                          className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm sm:text-base font-medium text-hazel-green">
                              {location.name}
                            </span>
                            {location.address && (
                              <span className="text-xs sm:text-sm text-bluish-grey">
                                {location.address}
                              </span>
                            )}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>

          {/* Articles */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Artikujt</h2>
              <p className="text-sm text-grey-goose">Artikujt e këtij shpenzimi.</p>
            </div>
            <div className="p-6">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-mercury">
                  <thead>
                    <tr className="bg-gradient-to-r from-sage/5 to-transparent">
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Artikulli</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Njësi Matëse</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Sasia</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Çmimi për Njësi</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Norma e TVSH-së</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Shuma</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Shuma Totale</th>
                      <th className="py-3 px-4 text-left text-xs font-medium text-hazel-green uppercase tracking-wider">Veprime</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-mercury/40">
                    {articles.length === 0 ? (
                      <tr>
                        <td colSpan={8} className="px-4 py-8 text-center text-sm text-bluish-grey">
                          Nuk ka artikuj. Klikoni butonin "Shto artikull të ri" për të shtuar.
                        </td>
                      </tr>
                    ) : (
                      articles.map((article) => (
                        <tr key={article.id}>
                          <td className="px-4 py-4">
                            <Select
                              value={article.articleId || ''}
                              onValueChange={(value) => handleArticleSelect(article.id, value)}
                              disabled={isLoading}
                            >
                              <SelectTrigger 
                                className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                                disabled={isLoading}
                              >
                                <SelectValue 
                                  placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni artikullin"}
                                  className="text-bluish-grey"
                                >
                                  {article.articleId && (
                                    <span className="text-hazel-green">
                                      {accountingArticles.find(a => a.id === article.articleId)?.name}
                                    </span>
                                  )}
                                </SelectValue>
                              </SelectTrigger>
                              <SelectContent 
                                className="bg-white rounded-md shadow-lg border border-mercury/50 overflow-hidden w-[500px]"
                                sideOffset={5}
                                position="popper"
                                align="start"
                              >
                                <div className="py-2 px-3 sticky top-0 bg-white border-b border-mercury/50 z-10">
                                  <h3 className="text-xs sm:text-sm font-medium text-bluish-grey">
                                    Zgjidhni Artikullin
                                  </h3>
                                </div>
                                {accountingArticles.map(item => (
                                  <SelectItem
                                    key={item.id}
                                    value={item.id}
                                    textValue={item.name}
                                    className="px-4 py-3 focus:bg-sage/10 hover:bg-sage/10 cursor-pointer border-b border-mercury/50 last:border-b-0"
                                  >
                                    <div className="flex flex-col gap-1">
                                      <div className="flex items-center justify-between">
                                        <span className="text-sm sm:text-base font-medium text-hazel-green">
                                          {item.name}
                                        </span>
                                        <span className="px-2 py-0.5 rounded-full text-xs sm:text-sm font-medium bg-sage/10 text-sage">
                                          {item.code}
                                        </span>
                                      </div>
                                      {item.description && (
                                        <span className="text-xs sm:text-sm text-bluish-grey">
                                          {item.description}
                                        </span>
                                      )}
                                      <div className="grid grid-cols-2 gap-1 text-xs sm:text-sm text-bluish-grey">
                                        <div className="flex items-center gap-1">
                                          <span>Njësia: {item.unit.name}</span>
                                        </div>
                                        <div className="flex items-center gap-1">
                                          <span>TVSH: {item.vatRate.percentage}%</span>
                                        </div>
                                      </div>
                                    </div>
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="text"
                              value={article.unit}
                              disabled
                              className="w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                              placeholder="Njësia"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              value={article.quantity}
                              onChange={(e) => handleArticleChange(article.id, 'quantity', e.target.value)}
                              className="w-full rounded-lg border border-mercury px-3 py-2 text-sm"
                              placeholder="0.00"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              value={article.priceWithoutVAT}
                              onChange={(e) => handleArticleChange(article.id, 'priceWithoutVAT', e.target.value)}
                              className="w-full rounded-lg border border-mercury px-3 py-2 text-sm"
                              placeholder="0.00"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              value={article.vatRate}
                              disabled
                              className="w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              disabled
                              value={article.vatAmount}
                              className="w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <input
                              type="number"
                              disabled
                              value={article.totalWithVAT}
                              className="w-full rounded-lg bg-seashell/50 border border-mercury px-3 py-2 text-sm"
                            />
                          </td>
                          <td className="px-4 py-4">
                            <button 
                              onClick={() => {
                                setArticles(articles.filter(a => a.id !== article.id));
                              }}
                              className="text-red-500 hover:text-red-700 transition-colors"
                            >
                              Fshij
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <div className="mt-4">
                <button
                  onClick={handleAddArticle}
                  className="inline-flex items-center gap-x-2 px-4 py-2 text-sm text-bluish-grey hover:text-hazel-green transition-colors"
                >
                  <Plus className="h-4 w-4" />
                  Shto artikull të ri
                </button>
              </div>
            </div>
          </div>

          {/* Totals */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Totali</h2>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-4 gap-4">
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Lloji
                  </label>
                  <input
                    type="text"
                    disabled
                    value="0.00"
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Vlera pa TVSH
                  </label>
                  <input
                    type="text"
                    disabled
                    value={calculateTotals().totalWithoutVAT}
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Shuma Totale e TVSH-së
                  </label>
                  <input
                    type="text"
                    disabled
                    value={calculateTotals().totalVAT}
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-bluish-grey mb-2">
                    Vlera me TVSH
                  </label>
                  <input
                    type="text"
                    disabled
                    value={calculateTotals().totalWithVAT}
                    className="block w-full rounded-lg bg-seashell/50 border border-mercury px-4 py-2.5 text-bluish-grey"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Attachments */}
          <div className="bg-white/95 backdrop-blur-sm shadow-sm rounded-lg border border-mercury/50 overflow-hidden">
            <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-4">
              <h2 className="text-lg font-semibold text-hazel-green">Bashkëngjit dokument (attachment)</h2>
            </div>
            <div className="p-6">
              <div className="border-2 border-dashed border-mercury rounded-lg p-8">
                <div className="flex flex-col items-center justify-center gap-2">
                  <Upload className="h-8 w-8 text-grey-goose" />
                  <p className="text-sm text-bluish-grey">Mund vendosni dokument këtu (drag and drop)</p>
                  <p className="text-xs text-grey-goose">
                    Mund të ngarkoni dokument të vetëm, apo edhe grup dokumentesh.
                  </p>
                </div>
              </div>
              <p className="mt-2 text-xs text-grey-goose">
                Shto një dokument (attachment), psh. faturën orgjinaie te shpenzimit, faturën e pranuar nga furnitori.
              </p>
            </div>
          </div>
        </div>
      </div>
      <SupplierModal
        isOpen={isSupplierModalOpen}
        onClose={() => setIsSupplierModalOpen(false)}
        onSuccess={handleSupplierSuccess}
      />
    </div>
  );
};

export default AddExpensePage;
