// category.api.js
import api from './api';

// Add a new category
export const addCategory = (data) => api.post('/api/categories', data);

// Update an existing category
export const updateCategory = (id, data) => api.put(`/api/categories/${id}`, data);

// Delete a category
export const deleteCategory = (id) => api.delete(`/api/categories/${id}`);

// Get all categories
export const getCategories = () => api.get('/api/categories');

// Get a category by ID
export const getCategoryById = (id) => api.get(`/api/categories/${id}`);
