import axios from 'axios';

const api = axios.create({
  // baseURL: 'https://api-staff.lakesideks.com', 
  baseURL: 'http://localhost:3001'
});




export const setAuthorizationHeader = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};



export default api;
