import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FileText, Calendar, ArrowRight, Download, ChevronDown, Loader2, ChevronLeft, ChevronRight } from 'lucide-react';
import { getPurchaseJournal, exportPurchaseJournal } from '../../../../api/purchaseJournal';
import { Resizable } from 're-resizable';
import { Button } from '../../../../components/shadCN/Button.tsx';
import XLSX from 'xlsx-js-style';
import { getCompany } from '../../../../api/company.js';

interface JournalData {
  nr: string;
  date: string;
  invoiceNumber: string;
  sellerName: string;
  sellerFiscalNumber: string;
  sellerVatNumber: string;
  exemptPurchasesNoCredit: number;
  foreignServicePurchases: number;
  domesticReverseChargePurchases: number;
  otherExemptPurchasesWithCredit: number;
  totalExemptPurchasesWithCredit: number;
  imports: number;
  taxablePurchases18: number;
  debitNoteReceived18: number;
  badDebtIssued18: number;
  vatIncreaseAdjustments18: number;
  reverseChargePurchases18: number;
  totalVat18: number;
  taxablePurchases8: number;
  debitNoteReceived8: number;
  badDebtIssued8: number;
  vatIncreaseAdjustments8: number;
  totalVat8: number;
  totalDeductibleVat: number;
}

const PurchaseJournalPage = () => {
  const [showReport, setShowReport] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [selectedMonth, setSelectedMonth] = useState('1');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [journalData, setJournalData] = useState<any>(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [companyData, setCompanyData] = useState<any>(null);

  const years = Array.from({ length: 5 }, (_, i) => (new Date().getFullYear() - i).toString());
  const months = [
    'Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor',
    'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'
  ];

  useEffect(() => {
    if (showReport) {
      fetchJournalData();
    }
  }, [currentPage]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await getCompany();
        setCompanyData(response.message);
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };
    fetchCompanyData();
  }, []);

  const fetchJournalData = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getPurchaseJournal(
        parseInt(selectedYear), 
        parseInt(selectedMonth),
        currentPage,
        5
      );
      setJournalData(data);
      setShowReport(true);
    } catch (err: any) {
      setError(err.message || 'Failed to fetch journal data');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateReport = () => {
    fetchJournalData();
  };

  const handleExport = async (format: 'excel' | 'pdf' | 'csv') => {
    try {
      setExportLoading(true);
      const data = await exportPurchaseJournal(
        parseInt(selectedYear),
        parseInt(selectedMonth),
        format
      );
      // Handle the export data based on format
      console.log('Export successful:', data);
    } catch (err: any) {
      setError(err.message || 'Failed to export journal');
    } finally {
      setExportLoading(false);
    }
  };

  const handleExcelExport = () => {
    try {
      setExportLoading(true);
      
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet([]);
      
      // Colors from tailwind config
      const colors = {
        headerBg: "61805B",     // hazel-green
        headerText: "FFFFFF",    // white
        lightRowBg: "F0F3EF",   // seashell
        darkRowBg: "E3E7E7",    // mercury
        lightRowText: "61805B", // hazel-green
        darkRowText: "7C8B93",  // bluish-grey
        border: "B0C3CC",       // heather
        title: "90A68C",        // sage for title background
      };

      const companyHeaderStyle = {
        font: { name: "Arial", bold: true, size: 14 },
        alignment: { horizontal: "left", vertical: "center" },
        fill: { type: "pattern", pattern: "solid", fgColor: { rgb: "FFFFFF" } }
      };
  
      const companyInfoStyle = {
        font: { name: "Arial", size: 11 },
        alignment: { horizontal: "left", vertical: "center" }
      };
  
      const titleStyle = {
        font: { name: "Arial", bold: true, size: 14, color: { rgb: colors.headerText } },
        alignment: { horizontal: "center", vertical: "center" },
        fill: { type: "pattern", pattern: "solid", fgColor: { rgb: colors.title } }
      };
  
      const headerStyle = {
        font: { name: "Arial", bold: true, size: 11, color: { rgb: colors.headerText } },
        alignment: { horizontal: "center", vertical: "center", wrapText: true },
        fill: { type: "pattern", pattern: "solid", fgColor: { rgb: colors.headerBg } },
        border: {
          top: { style: "thin", color: { rgb: colors.border } },
          bottom: { style: "thin", color: { rgb: colors.border } },
          left: { style: "thin", color: { rgb: colors.border } },
          right: { style: "thin", color: { rgb: colors.border } }
        }
      };
      
      const getRowStyle = (isAltRow = false) => ({
        font: {
          name: "Arial",
          size: 11,
          color: { rgb: isAltRow ? colors.lightRowText : colors.darkRowText },
          bold: false
        },
        alignment: {
          horizontal: "center",
          vertical: "center"
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { rgb: isAltRow ? colors.lightRowBg : colors.darkRowBg }
        },
        border: {
          top: { style: "thin", color: { rgb: colors.border } },
          bottom: { style: "thin", color: { rgb: colors.border } },
          left: { style: "thin", color: { rgb: colors.border } },
          right: { style: "thin", color: { rgb: colors.border } }
        }
      });


  



      // Company info section
      const companyInfo = [
        [{ v: companyData?.name || '', s: companyHeaderStyle }],
        [{ v: `NIPT: ${companyData?.registrationNo || '-'} | NUIS: ${companyData?.vatNo || '-'}`, s: companyInfoStyle }],
        [{ 
          v: [
            companyData?.address,
            companyData?.city,
            companyData?.postalCode,
            companyData?.country
          ].filter(Boolean).join(', ') || 'Adresa: -',
          s: companyInfoStyle 
        }],
        [{ 
          v: `Tel: ${companyData?.phone || '-'} | Email: ${companyData?.email || '-'}`,
          s: companyInfoStyle 
        }],
        [{ v: '', s: companyInfoStyle }], // Spacing
        [{ 
          v: `LIBRI I BLERJEVE - ${months[parseInt(selectedMonth) - 1].toUpperCase()} ${selectedYear}`,
          s: {
            ...companyHeaderStyle,
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { rgb: colors.title }
            },
            font: {
              ...companyHeaderStyle.font,
              color: { rgb: colors.headerText }
            }
          }
        }],
        [{ v: '', s: companyInfoStyle }] // Spacing before table headers
      ];

      ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 26 } }, // Company name
        { s: { r: 1, c: 0 }, e: { r: 1, c: 26 } }, // NIPT/NUIS
        { s: { r: 2, c: 0 }, e: { r: 2, c: 26 } }, // Address
        { s: { r: 3, c: 0 }, e: { r: 3, c: 26 } }, // Contact
        { s: { r: 4, c: 0 }, e: { r: 4, c: 26 } }, // Spacing
        { s: { r: 5, c: 0 }, e: { r: 5, c: 26 } }, // Title
        { s: { r: 6, c: 0 }, e: { r: 6, c: 26 } }  // Spacing
      ];


      // Headers for the purchase journal
      const headers = [
        [
          { v: 'Nr.', s: headerStyle },
          { v: 'Data', s: headerStyle },
          { v: 'Numri i faturës', s: headerStyle },
          { v: 'Shitësi', s: headerStyle },
          { v: 'Numri Fiskal i shitësit', s: headerStyle },
          { v: 'Numri i TVSH-së së shitësit', s: headerStyle },
          { v: 'Blerjet pa TVSH', s: headerStyle },
          { v: 'Blerjet me TVSH të pazbritshme', s: headerStyle },
          { v: 'Blerjet me TVSH 18%', s: headerStyle },
          { v: 'Nota debitore 18%', s: headerStyle },
          { v: 'Borxhi i keq 18%', s: headerStyle },
          { v: 'Rregullimi i TVSH-së 18%', s: headerStyle },
          { v: 'Ngarkesa e kundërt 18%', s: headerStyle },
          { v: 'TVSH 18%', s: headerStyle },
          { v: 'Blerjet me TVSH 8%', s: headerStyle },
          { v: 'Nota debitore 8%', s: headerStyle },
          { v: 'Borxhi i keq 8%', s: headerStyle },
          { v: 'Rregullimi i TVSH-së 8%', s: headerStyle },
          { v: 'TVSH 8%', s: headerStyle },
          { v: 'Totali i TVSH-së së zbritshme', s: headerStyle }
        ]
      ];

      // Transform data with alternating row colors
      const data = journalData?.entries?.map((entry: any, index: number) => {
        const rowStyle = getRowStyle(index % 2 === 0);
        const textStyle = { ...rowStyle, alignment: { horizontal: "left" } };
        const numberStyle = { ...rowStyle, numFmt: '#,##0.00' };
  
        return [
          { v: entry.nr || '', s: textStyle },
          { v: entry.date ? new Date(entry.date).toLocaleDateString() : '', s: textStyle },
          { v: entry.invoiceNumber || '', s: textStyle },
          { v: entry.sellerName || '', s: textStyle },
          { v: entry.sellerFiscalNumber || '', s: textStyle },
          { v: entry.sellerVatNumber || '', s: textStyle },
          { v: Number(entry.exemptPurchasesNoCredit || 0), s: numberStyle },
          { v: Number(entry.foreignServicePurchases || 0), s: numberStyle },
          { v: Number(entry.taxablePurchases18 || 0), s: numberStyle },
          { v: Number(entry.debitNoteReceived18 || 0), s: numberStyle },
          { v: Number(entry.badDebtIssued18 || 0), s: numberStyle },
          { v: Number(entry.vatIncreaseAdjustments18 || 0), s: numberStyle },
          { v: Number(entry.reverseChargePurchases18 || 0), s: numberStyle },
          { v: Number(entry.totalVat18 || 0), s: numberStyle },
          { v: Number(entry.taxablePurchases8 || 0), s: numberStyle },
          { v: Number(entry.debitNoteReceived8 || 0), s: numberStyle },
          { v: Number(entry.badDebtIssued8 || 0), s: numberStyle },
          { v: Number(entry.vatIncreaseAdjustments8 || 0), s: numberStyle },
          { v: Number(entry.totalVat8 || 0), s: numberStyle },
          { v: Number(entry.totalDeductibleVat || 0), s: numberStyle }
        ];
      });
  

      // Add totals row
      // Add totals row with proper number formatting
    const totalsRow = [
      { v: 'Totali', s: headerStyle },
      { v: '', s: headerStyle },
      { v: '', s: headerStyle },
      { v: '', s: headerStyle },
      { v: '', s: headerStyle },
      { v: '', s: headerStyle },
      { v: Number(journalData?.totals?.exemptPurchasesNoCredit || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.foreignServicePurchases || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.taxablePurchases18 || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.debitNoteReceived18 || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.badDebtIssued18 || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.vatIncreaseAdjustments18 || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.reverseChargePurchases18 || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.totalVat18 || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.taxablePurchases8 || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.debitNoteReceived8 || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.badDebtIssued8 || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.vatIncreaseAdjustments8 || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.totalVat8 || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } },
      { v: Number(journalData?.totals?.totalDeductibleVat || 0).toFixed(2), s: { ...headerStyle, numFmt: '#,##0.00' } }
    ];

      // Set column widths
      ws['!cols'] = [
        { width: 8 },      // Nr
        { width: 15 },     // Data
        { width: 15 },     // Numri i faturës
        { width: 35 },     // Shitësi
        { width: 15 },     // Numri Fiskal
        { width: 15 },     // TVSH
        { width: 18 },     // Blerjet pa TVSH
        { width: 18 },     // Blerjet me TVSH të pazbritshme
        { width: 18 },     // Blerjet me TVSH 18%
        { width: 18 },     // Nota debitore 18%
        { width: 18 },     // Borxhi i keq 18%
        { width: 18 },     // Rregullimi i TVSH-së 18%
        { width: 18 },     // Ngarkesa e kundërt 18%
        { width: 18 },     // TVSH 18%
        { width: 18 },     // Blerjet me TVSH 8%
        { width: 18 },     // Nota debitore 8%
        { width: 18 },     // Borxhi i keq 8%
        { width: 18 },     // Rregullimi i TVSH-së 8%
        { width: 18 },     // TVSH 8%
        { width: 18 }      // Totali i TVSH-së së zbritshme
      ];

      // Add content to worksheet in the correct order
      XLSX.utils.sheet_add_aoa(ws, companyInfo, { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(ws, headers, { origin: 'A8' });
      XLSX.utils.sheet_add_aoa(ws, data, { origin: 'A9' });
      XLSX.utils.sheet_add_aoa(ws, [totalsRow], { origin: `A${9 + (data?.length || 0)}` });

      ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 19 } }, // Company name
        { s: { r: 1, c: 0 }, e: { r: 1, c: 19 } }, // NIPT/NUIS
        { s: { r: 2, c: 0 }, e: { r: 2, c: 19 } }, // Address
        { s: { r: 3, c: 0 }, e: { r: 3, c: 19 } }, // Contact
        { s: { r: 4, c: 0 }, e: { r: 4, c: 19 } }, // Spacing
        { s: { r: 5, c: 0 }, e: { r: 5, c: 19 } }, // Title
        { s: { r: 6, c: 0 }, e: { r: 6, c: 19 } }  // Spacing
      ];
      
      // Add the worksheet to workbook and save
      XLSX.utils.book_append_sheet(wb, ws, 'Purchase Journal');
      XLSX.writeFile(wb, `Purchase_Journal_${selectedMonth}_${selectedYear}.xlsx`);

    } catch (error) {
      console.error('Error exporting to Excel:', error);
    } finally {
      setExportLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header Section */}
      <div className="bg-white/80 backdrop-blur-sm border-b border-mercury sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2 text-sm">
              <span className="text-grey-goose hover:text-bluish-grey transition-colors">Tatimet</span>
              <ChevronDown className="w-4 h-4 text-grey-goose" />
              <span className="font-medium text-hazel-green">Libri i Blerjeve</span>
            </div>
            <div className="text-xs text-grey-goose bg-white px-3 py-1.5 rounded-full shadow-sm">
              Periudha: {months[parseInt(selectedMonth) - 1]} {selectedYear}
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">
        {/* Form Card */}
        <div className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 mb-8 overflow-hidden">
          {/* Form Header */}
          <div className="bg-gradient-to-r from-hazel-green/10 to-transparent border-b border-mercury/50 p-8">
            <div className="max-w-2xl mx-auto text-center">
              <h1 className="text-2xl font-semibold text-bluish-grey mb-3">Libri i Blerjeve</h1>
              <p className="text-grey-goose">
                Gjeneroni raportin e librit të blerjeve duke përzgjedhur periudhën kohore
              </p>
            </div>
          </div>

          {/* Form Content */}
          <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-xl mx-auto mb-8">
              {/* Year Select */}
              <div className="relative group">
                <label className="inline-flex items-center text-sm font-medium text-bluish-grey mb-2 group-hover:text-hazel-green transition-colors">
                  <Calendar className="w-4 h-4 mr-2 opacity-70" />
                  <span>Viti</span>
                </label>
                <div className="relative">
                  <select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    className="w-full p-3 bg-seashell border border-mercury rounded-xl appearance-none text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 hover:border-hazel-green/30 transition-colors"
                  >
                    {years.map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-goose w-4 h-4 pointer-events-none" />
                </div>
              </div>

              {/* Month Select */}
              <div className="relative group">
                <label className="inline-flex items-center text-sm font-medium text-bluish-grey mb-2 group-hover:text-hazel-green transition-colors">
                  <Calendar className="w-4 h-4 mr-2 opacity-70" />
                  <span>Muaji</span>
                </label>
                <div className="relative">
                  <select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    className="w-full p-3 bg-seashell border border-mercury rounded-xl appearance-none text-bluish-grey focus:outline-none focus:ring-2 focus:ring-hazel-green/20 hover:border-hazel-green/30 transition-colors"
                  >
                    {months.map((month, index) => (
                      <option key={index + 1} value={index + 1}>{month}</option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-goose w-4 h-4 pointer-events-none" />
                </div>
              </div>
            </div>

            {/* Generate Button */}
            <div className="flex justify-center">
              <button
                onClick={handleGenerateReport}
                disabled={loading}
                className="inline-flex items-center space-x-2 px-6 py-3 bg-hazel-green text-white rounded-xl hover:bg-hazel-green/90 focus:outline-none focus:ring-2 focus:ring-hazel-green/20 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              >
                <FileText className="w-5 h-5" />
                <span>Gjenero Raportin</span>
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>
          </div>

          
        </div>
        {/* Report Content */}
           {/* Report Section */}
           <AnimatePresence mode="wait">
          {showReport && (
            <motion.div
              key="report"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              className="bg-white/95 backdrop-blur rounded-2xl shadow-lg border border-mercury/50 overflow-hidden mt-8"
            >
              {/* Report Header */}
              <div className="bg-gradient-to-r from-hazel-green/10 to-transparent p-6 border-b border-mercury/50 flex justify-between items-center">
                <h2 className="text-lg font-semibold text-bluish-grey">
                  Libri i Blerjeve - {months[parseInt(selectedMonth) - 1]} {selectedYear}
                </h2>
                <button 
                  onClick={handleExcelExport}
                  className="inline-flex items-center px-4 py-2 bg-seashell text-hazel-green rounded-lg hover:bg-mercury transition-colors duration-200 gap-2"
                >
                  <Download className="w-4 h-4" />
                  <span>Eksporto</span>
                </button>
              </div>
            <div>
            {loading ? (
              <div className="flex items-center justify-center p-12">
                <Loader2 className="w-8 h-8 text-hazel-green animate-spin" />
              </div>
            ) : error ? (
              <div className="p-8 text-center text-red-500">
                {error}
              </div>
            ) : showReport && journalData ? (
              <div className="p-8">
                {/* Journal Table */}
                <div className="mt-8">
                  <div className="overflow-x-auto rounded-lg border border-mercury">
                      <div className="min-w-full inline-block align-middle">
                        <div className="overflow-hidden">
                          <div className="min-w-full overflow-x-auto shadow-sm relative" style={{ maxHeight: 'calc(100vh - 300px)' }}>
                            <table className="min-w-full divide-y divide-mercury table-fixed">
                            <thead className="sticky top-0 bg-white/95 backdrop-blur-sm z-10">
                              <tr className="transition-colors">
                                {/* Fixed columns with enhanced shadow */}
                                <th className="sticky left-0 bg-white/95 backdrop-blur-sm p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury z-20 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                  <Resizable
                                    defaultSize={{ width: 70, height: '100%' }}
                                    minWidth={70}
                                    maxWidth={200}
                                    enable={{ right: true }}
                                  >
                                    <div>Nr.</div>
                                  </Resizable>
                                </th>
                                <th className="sticky left-[70px] bg-white/95 backdrop-blur-sm p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury z-20 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                  <Resizable
                                    defaultSize={{ width: 120, height: '100%' }}
                                    minWidth={120}
                                    maxWidth={250}
                                    enable={{ right: true }}
                                  >
                                    <div>Data</div>
                                  </Resizable>
                                </th>
                                <th className="sticky left-[200px] bg-white/95 backdrop-blur-sm p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury z-20 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Numri i faturës</div>
                                  </Resizable>
                                </th>
                                <th className="sticky left-[350px] bg-white/95 backdrop-blur-sm p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury z-20 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                  <Resizable
                                    defaultSize={{ width: 200, height: '100%' }}
                                    minWidth={200}
                                    maxWidth={350}
                                    enable={{ right: true }}
                                  >
                                    <div>Emri i shitësit</div>
                                  </Resizable>
                                </th>
                                {/* Scrollable columns */}
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Numri Fiskal i shitësit</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Numri i TVSH-së së shitësit</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Blerjet dhe importet pa TVSH</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Blerjet dhe importet investive pa TVSH</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Blerjet dhe importet me TVSH jo të zbritshme</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Blerjet dhe importet investive me TVSH jo të zbritshme</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Importet</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Importet investive</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Blerjet vendore</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Blerjet investive vendore</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Nota debitore e pranuar, nota kreditore e lëshuar</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Fatura e borxhit të keq e lëshuar</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Rregullimet për të ulur TVSH-në për pagesë</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>E drejta e kreditimit të TVSH-së në lidhje me Ngarkesën e Kundërt</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Totali i TVSH-së së zbritshme me 18%</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Importet</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Importet investive</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Blerjet vendore</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Blerjet investive vendore</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Blerjet nga fermerët (aplikimi i normës së sheshtë)</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Nota debitore e pranuar, nota kreditore e lëshuar</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Fatura e borxhit të keq e lëshuar</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Rregullimet për të ulur TVSH-në / Ngarkesa e kundërt për Energjinë me normë 8%</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Totali i TVSH-së së zbritshme me 8%</div>
                                  </Resizable>
                                </th>
                                <th className="p-4 text-left text-sm font-semibold text-bluish-grey border-b border-mercury">
                                  <Resizable
                                    defaultSize={{ width: 150, height: '100%' }}
                                    minWidth={150}
                                    maxWidth={300}
                                    enable={{ right: true }}
                                  >
                                    <div>Totali i TVSH-së së zbritshme me 18% dhe 8%</div>
                                  </Resizable>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-mercury bg-white">
                              {journalData?.entries?.map((entry: any) => (
                                <tr key={entry.nr} className="hover:bg-seashell/50 transition-colors duration-150">
                                  {/* Fixed columns with enhanced shadow */}
                                  <td className="sticky left-0 bg-white p-4 text-sm text-bluish-grey border-b border-mercury whitespace-nowrap z-10 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                    {entry.nr}
                                  </td>
                                  <td className="sticky left-[70px] bg-white p-4 text-sm text-bluish-grey border-b border-mercury whitespace-nowrap z-10 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                    {entry.date ? new Date(entry.date).toLocaleDateString() : ''}
                                  </td>
                                  <td className="sticky left-[200px] bg-white p-4 text-sm text-bluish-grey border-b border-mercury whitespace-nowrap z-10 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                    {entry.invoiceNumber}
                                  </td>
                                  <td className="sticky left-[350px] bg-white p-4 text-sm text-bluish-grey border-b border-mercury whitespace-nowrap z-10 shadow-[2px_0_5px_rgba(0,0,0,0.05)] transition-all duration-200">
                                    {entry.sellerName}
                                  </td>
                                  {/* Scrollable columns */}
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury">
                                    {entry.sellerFiscalNumber}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury">
                                    {entry.sellerVatNumber}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.exemptPurchasesNoCredit || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.foreignServicePurchases || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.taxablePurchases18 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.debitNoteReceived18 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.badDebtIssued18 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.vatIncreaseAdjustments18 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.reverseChargePurchases18 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.totalVat18 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.taxablePurchases8 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.debitNoteReceived8 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.badDebtIssued8 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.vatIncreaseAdjustments8 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.totalVat8 || 0).toFixed(2)}
                                  </td>
                                  <td className="p-4 text-sm text-bluish-grey border-b border-mercury text-right">
                                    {(entry.totalDeductibleVat || 0).toFixed(2)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr className="bg-seashell/50">
                                <td colSpan={5} className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury">
                                  Totali
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.exemptPurchasesNoCredit || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.foreignServicePurchases || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.taxablePurchases18 || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.debitNoteReceived18 || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.badDebtIssued18 || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.vatIncreaseAdjustments18 || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.reverseChargePurchases18 || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.totalVat18 || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.taxablePurchases8 || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.debitNoteReceived8 || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.badDebtIssued8 || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.vatIncreaseAdjustments8 || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.totalVat8 || 0).toFixed(2)}
                                </td>
                                <td className="p-4 text-sm font-medium text-bluish-grey border-t-2 border-mercury text-right">
                                  {(journalData?.totals?.totalDeductibleVat || 0).toFixed(2)}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                

                {/* Export Buttons */}
                <div className="mt-8 flex justify-end space-x-4">
                  <button
                    onClick={() => handleExcelExport()}
                    disabled={exportLoading}
                    className="flex items-center space-x-2 px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 disabled:opacity-50"
                  >
                    {exportLoading ? (
                      <Loader2 className="w-4 h-4 animate-spin" />
                    ) : (
                      <Download className="w-4 h-4" />
                    )}
                    <span>Excel</span>
                  </button>
                  <button
                    onClick={() => handleExport('pdf')}
                    disabled={exportLoading}
                    className="flex items-center space-x-2 px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 disabled:opacity-50"
                  >
                    {exportLoading ? (
                      <Loader2 className="w-4 h-4 animate-spin" />
                    ) : (
                      <Download className="w-4 h-4" />
                    )}
                    <span>PDF</span>
                  </button>
                </div>    
              </div>
            ) : null}
            </div> 
            </motion.div>
          )}
          </AnimatePresence>

          <AnimatePresence mode="wait">
          {!showReport && (
            <motion.div
              key="empty-state"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="bg-gradient-to-b from-white to-seashell rounded-2xl shadow-sm p-16 text-center relative overflow-hidden"
            >
              {/* Background Pattern */}
              <div className="absolute inset-0 opacity-5">
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,#90a68c_1px,transparent_1px)] bg-[length:24px_24px]"></div>
              </div>

              {/* Content */}
              <div className="relative z-10">
                {/* Icon Container */}
                <div className="mb-6 relative">
                  <div className="w-20 h-20 bg-seashell rounded-full flex items-center justify-center mx-auto">
                    <FileText className="w-10 h-10 text-hazel-green" />
                  </div>
                </div>

                {/* Text Content */}
                <div className="max-w-md mx-auto">
                  <h3 className="text-xl font-semibold text-bluish-grey mb-3">
                    Nuk ka raport të gjeneruar
                  </h3>
                  <p className="text-grey-goose mb-6 leading-relaxed">
                    Për të gjeneruar librin e shitjeve, ju lutem përzgjidhni vitin dhe muajin në formën më lart.
                  </p>

                  {/* Steps */}
                  <div className="space-y-3 text-left bg-white/50 rounded-xl p-4 backdrop-blur-sm">
                    <div className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <span className="text-sm text-hazel-green">1</span>
                      </div>
                      <div>
                        <p className="text-sm text-bluish-grey font-medium">Zgjidhni periudhën kohore</p>
                        <p className="text-xs text-grey-goose">Përzgjidhni vitin dhe muajin për të cilin dëshironi të gjeneroni raportin</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-3">
                      <div className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <span className="text-sm text-hazel-green">2</span>
                      </div>
                      <div>
                        <p className="text-sm text-bluish-grey font-medium">Gjeneroni raportin</p>
                        <p className="text-xs text-grey-goose">Klikoni butonin "Gjenero Raportin" për të shfaqur librin e shitjeve</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Decorative Elements */}
                <div className="absolute bottom-0 left-0 w-32 h-32 bg-hazel-green/5 rounded-tr-full"></div>
                <div className="absolute top-0 right-0 w-24 h-24 bg-dawn-pink/10 rounded-bl-full"></div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Enhanced Pagination Section - Only show when journal is generated */}
      {showReport && journalData && (
        <div className="sticky bottom-0 left-0 right-0 bg-white border-t border-mercury shadow-[0_-2px_5px_rgba(0,0,0,0.05)] backdrop-blur-sm">
          <div className="max-w-full mx-auto px-6 py-4">
            <div className="flex items-center justify-between">
              {/* Pagination Info */}
              <div className="flex items-center space-x-2">
                <span className="text-sm font-medium text-bluish-grey">
                  Faqja {currentPage} nga {journalData?.pagination?.totalPages || 1}
                </span>
                <span className="text-sm text-grey-goose">
                  ({((currentPage - 1) * 5) + 1}-{Math.min(currentPage * 5, journalData?.pagination?.totalItems)} nga {journalData?.pagination?.totalItems} rezultate)
                </span>
              </div>

              {/* Navigation Buttons */}
              <div className="flex items-center space-x-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setCurrentPage(1)}
                  disabled={currentPage === 1 || loading}
                  className="h-9 px-3 text-bluish-grey hover:text-hazel-green hover:border-hazel-green/30 disabled:opacity-50"
                >
                  <ChevronLeft className="w-4 h-4 mr-1" />
                  E para
                </Button>
                
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                  disabled={currentPage === 1 || loading}
                  className="h-9 px-4 text-bluish-grey hover:text-hazel-green hover:border-hazel-green/30 disabled:opacity-50"
                >
                  Para
                </Button>

                {/* Current Page Indicator */}
                <div className="px-4 h-9 flex items-center justify-center min-w-[4rem] bg-seashell rounded-md border border-mercury">
                  <span className="text-sm font-medium text-bluish-grey">{currentPage}</span>
                </div>

                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setCurrentPage(p => p + 1)}
                  disabled={currentPage === journalData?.pagination?.totalPages || loading}
                  className="h-9 px-4 text-bluish-grey hover:text-hazel-green hover:border-hazel-green/30 disabled:opacity-50"
                >
                  Pas
                </Button>

                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setCurrentPage(journalData?.pagination?.totalPages || 1)}
                  disabled={currentPage === journalData?.pagination?.totalPages || loading}
                  className="h-9 px-3 text-bluish-grey hover:text-hazel-green hover:border-hazel-green/30 disabled:opacity-50"
                >
                  E fundit
                  <ChevronRight className="w-4 h-4 ml-1" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseJournalPage;
