import React, { useEffect, useState } from 'react';
import { ArrowLeft, Plus, Upload, Trash2, AlertCircle, Check } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '../../../components/shadCN/Select.tsx';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { getCompany } from '../../../api/company';
import { getSuppliers } from '../../../api/accounting';
import { getArticles } from '../../../api/accountingArticle';
import { getAllAccounts } from '../../../api/accountingPlan';
import { getJournalEntryById, updateJournalEntry } from '../../../api/journal';
import { cn } from '../../../lib/utils.ts';

interface DebitCredit {
  id: string;
  analyticalAccount: string;
  accountCode: string;
  accountName: string;
  debit: number;
  credit: number;
  entity: string;
  description?: string;
  address?: string;
}

interface Location {
  id: string;
  name: string;
  address?: string;
}

interface Account {
  id: string;
  code: string;
  name: string;
}

enum JournalEntryStatus {
  DRAFT = 'DRAFT',
  POSTED = 'POSTED',
  CANCELLED = 'CANCELLED'
}

const statusLabels = {
  [JournalEntryStatus.DRAFT]: 'Draft',
  [JournalEntryStatus.POSTED]: 'Posted',
  [JournalEntryStatus.CANCELLED]: 'Cancelled'
};

const statusColors = {
  [JournalEntryStatus.DRAFT]: 'bg-yellow-100 text-yellow-800',
  [JournalEntryStatus.POSTED]: 'bg-green-100 text-green-800',
  [JournalEntryStatus.CANCELLED]: 'bg-red-100 text-red-800'
};

const EditJournalEntryPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [entries, setEntries] = useState<DebitCredit[]>([]);
  const [description, setDescription] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [date, setDate] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState<Location[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [difference, setDifference] = useState(0);
  const [status, setStatus] = useState<JournalEntryStatus>(JournalEntryStatus.DRAFT);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [companyRes, accountsRes, journalRes] = await Promise.all([
          getCompany(),
          getAllAccounts(),
          getJournalEntryById(id)
        ]);
        
        const journalEntry = journalRes.data.message;
        
        // Set journal entry data
        setDocumentNumber(journalEntry.documentNumber);
        setDescription(journalEntry.description || '');
        setDate(new Date(journalEntry.postingDate).toISOString().split('T')[0]);
        setSelectedLocation(journalEntry.locationId || '');
        setStatus(journalEntry.status || JournalEntryStatus.DRAFT);
        
        // Transform items to entries format
        const transformedEntries = journalEntry.items.map(item => ({
          id: Math.random().toString(36).substr(2, 9),
          analyticalAccount: item.accountId,
          accountCode: item.account?.code || '',
          accountName: item.account?.name || '',
          debit: item.debitAmount || 0,
          credit: item.creditAmount || 0,
          entity: '',
          description: item.description || ''
        }));
        setEntries(transformedEntries);
        
        // Set totals
        setTotalDebit(journalEntry.totalDebit);
        setTotalCredit(journalEntry.totalCredit);

        // Handle locations
        const companyLocations = companyRes?.data?.message?.locations || [];
        setLocations(Array.isArray(companyLocations) ? companyLocations : []);

        // Handle accounts
        setAccounts(accountsRes.data.message || []);

      } catch (error: any) {
        console.error('Error fetching data:', error);
        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch data';
        toast.error(errorMessage);
        navigate('/accounting/journal-entries');
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, navigate]);

  useEffect(() => {
    // Calculate difference whenever debit or credit changes
    const diff = totalDebit - totalCredit;
    setDifference(diff);
  }, [totalDebit, totalCredit]);

  const handleAddRow = () => {
    setEntries([
      ...entries,
      {
        id: Math.random().toString(36).substr(2, 9),
        analyticalAccount: '',
        accountCode: '',
        accountName: '',
        debit: 0,
        credit: 0,
        entity: '',
        description: ''
      }
    ]);
  };

  const handleRemoveRow = (id: string) => {
    setEntries(entries.filter((entry) => entry.id !== id));
  };

  const handleAccountChange = (entryId: string, accountId: string) => {
    setEntries(entries.map(entry => {
      if (entry.id === entryId) {
        const selectedAccount = accounts.find(acc => acc.id === accountId);
        return { 
          ...entry, 
          analyticalAccount: accountId,
          accountCode: selectedAccount?.code || '',
          accountName: selectedAccount?.name || ''
        };
      }
      return entry;
    }));
  };

  const handleDebitCreditChange = (entryId: string, field: 'debit' | 'credit', value: number) => {
    const updatedEntries = entries.map(entry => {
      if (entry.id === entryId) {
        return {
          ...entry,
          [field]: value,
          [field === 'debit' ? 'credit' : 'debit']: 0
        };
      }
      return entry;
    });
    
    setEntries(updatedEntries);
    setTotalDebit(updatedEntries.reduce((sum, entry) => sum + (entry.debit || 0), 0));
    setTotalCredit(updatedEntries.reduce((sum, entry) => sum + (entry.credit || 0), 0));
  };

  const handleSubmit = async () => {
    try {
      // Validate entries
      if (entries.length === 0) {
        toast.error('Ju lutem shtoni të paktën një regjistrim');
        return;
      }

      if (totalDebit !== totalCredit) {
        toast.error('Totali i debit duhet të jetë i barabartë me totalin e kreditit');
        return;
      }

      if (!date) {
        toast.error('Ju lutem zgjidhni një datë');
        return;
      }

      // Prepare items
      const items = entries.map(entry => ({
        accountId: entry.analyticalAccount,
        description: entry.description,
        debitAmount: entry.debit || 0,
        creditAmount: entry.credit || 0
      }));

      // Prepare attachments if any
      const attachmentData = attachments.map(file => ({
        filename: file.name,
        url: 'temp-url' // TODO: Implement file upload
      }));

      const journalData = {
        documentNumber,
        postingDate: new Date(date),
        description,
        locationId: selectedLocation || undefined,
        totalDebit,
        totalCredit,
        items,
        attachments: attachmentData,
        status
      };

      setIsLoading(true);
      await updateJournalEntry(id, journalData);
      toast.success('Regjistrimi u përditësua me sukses');
      navigate('/accounting/journal-entries');
    } catch (error: any) {
      console.error('Error updating journal entry:', error);
      toast.error(error.response?.data?.message || 'Dështoi përditësimi i regjistrimit');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-tealish-blue via-seashell to-seashell/30">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white/90 backdrop-blur-sm border-b border-mercury shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/accounting/journal-entries')}
                className="inline-flex items-center gap-x-2 text-bluish-grey hover:text-hazel-green transition-colors"
              >
                <ArrowLeft className="h-5 w-5" />
                Kthehu
              </button>
            </div>
            <h1 className="text-lg font-semibold text-hazel-green">Ndrysho Hyrjen në Ditar</h1>
            <div className="flex items-center gap-x-4">
              <button
                onClick={handleSubmit}
                disabled={isLoading || totalDebit !== totalCredit}
                className={cn(
                  "px-4 py-2 rounded-lg font-medium inline-flex items-center gap-2",
                  "bg-hazel-green text-white hover:bg-hazel-green/90 transition-colors",
                  "disabled:opacity-50 disabled:cursor-not-allowed"
                )}
              >
                {isLoading ? (
                  <>
                    <span className="animate-spin">⏳</span>
                    <span>Duke ruajtur...</span>
                  </>
                ) : (
                  <>
                    <Check className="h-4 w-4" />
                    <span>Ruaj</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Document Info */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white/80 backdrop-blur-sm rounded-xl shadow-sm border border-mercury/40 p-6"
          >
            <h2 className="text-lg font-medium text-bluish-grey mb-4">Hyrjet në Ditar</h2>
            <p className="text-grey-goose text-sm mb-6">
              Këtu regjistrohen Hyrjet në Ditar, Hyrjet Manuale, Fletët Kontabël, Urdhëresat Financiare apo Hyrjet e Dyfishta (double entries).
            </p>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                  Emri i dokumentit
                </label>
                <input
                  type="text"
                  value={documentNumber}
                  disabled
                  className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey bg-mercury/20"
                />
                <p className="mt-1.5 text-xs text-grey-goose">
                  Emri i dokumentit është automatikisht i gjeneruar në bazë të formatit.
                </p>
              </div>
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                  Data e Hyrjes në Ditar
                </label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey
                           focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                           hover:border-hazel-green/50 transition-all"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                  Lokacioni
                </label>
                <Select
                  value={selectedLocation || null}
                  onValueChange={setSelectedLocation}
                  disabled={isLoading}
                >
                  <SelectTrigger 
                    className="w-full h-10 px-3 py-2 text-sm bg-white border border-mercury/50 rounded-md hover:border-sage focus:border-sage focus:ring-1 focus:ring-sage transition-colors"
                    disabled={isLoading}
                  >
                    <SelectValue 
                      placeholder={isLoading ? "Duke ngarkuar..." : "Zgjidhni lokacionin"}
                      className="text-bluish-grey"
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="none">Asnjë</SelectItem>
                    {locations.map((location) => (
                      <SelectItem key={location.id} value={location.id}>
                        {location.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                  Statusi
                </label>
                <Select
                  value={status}
                  onValueChange={(value) => setStatus(value as JournalEntryStatus)}
                >
                  <SelectTrigger className="w-full h-10 bg-white border-mercury hover:border-hazel-green/50 focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all">
                    <SelectValue>
                      <div className={cn(
                        "inline-flex items-center px-2 py-1 rounded-md text-xs font-medium",
                        statusColors[status]
                      )}>
                        {statusLabels[status]}
                      </div>
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(JournalEntryStatus).map((statusValue) => (
                      <SelectItem 
                        key={statusValue} 
                        value={statusValue}
                        className="flex items-center gap-2 py-2 px-3 hover:bg-seashell focus:bg-seashell cursor-pointer"
                      >
                        <div className={cn(
                          "px-2 py-1 rounded-md text-xs font-medium",
                          statusColors[statusValue]
                        )}>
                          {statusLabels[statusValue]}
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>

            {/* Description */}
            <div className="mt-6">
              <label className="block text-sm font-medium text-bluish-grey mb-1.5">
                Përshkrimi
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                className="w-full rounded-lg border border-mercury/50 py-2.5 px-4 text-sm text-bluish-grey
                         focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green
                         hover:border-hazel-green/50 transition-all resize-none"
                placeholder="Shkruani një përshkrim..."
              />
            </div>
          </motion.div>

          {/* Entries */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="bg-white/80 backdrop-blur-sm rounded-xl shadow-sm border border-mercury/40 p-6"
          >
            <div className="flex justify-between items-center mb-6">
              <div>
                <h2 className="text-lg font-medium text-bluish-grey">Artikujt</h2>
                <p className="text-grey-goose text-sm">
                  Shtoni artikujt për këtë hyrje në ditar
                </p>
              </div>
              <button
                onClick={handleAddRow}
                className="px-4 py-2 bg-sage/10 text-hazel-green rounded-lg hover:bg-sage/20
                         flex items-center gap-2 transition-colors"
              >
                <Plus className="h-4 w-4" />
                Shto rresht
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-mercury/40">
                    <th className="py-3 text-left text-xs font-medium text-grey-goose uppercase tracking-wider">Llogaria</th>
                    <th className="py-3 text-left text-xs font-medium text-grey-goose uppercase tracking-wider">Përshkrimi</th>
                    <th className="py-3 text-right text-xs font-medium text-grey-goose uppercase tracking-wider">Debit</th>
                    <th className="py-3 text-right text-xs font-medium text-grey-goose uppercase tracking-wider">Kredit</th>
                    <th className="py-3 w-10"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-mercury/40">
                  {entries.map((entry) => (
                    <tr key={entry.id} className="group">
                      <td className="py-3 pr-4">
                        <Select
                          value={entry.analyticalAccount}
                          onValueChange={(value) => handleAccountChange(entry.id, value)}
                        >
                          <SelectTrigger className="w-[300px] bg-white border-mercury hover:border-hazel-green/50 focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all">
                            <SelectValue placeholder="Zgjidhni llogarinë" className="text-bluish-grey">
                              {entry.accountCode && entry.accountName 
                                ? `${entry.accountCode} - ${entry.accountName}`
                                : "Zgjidhni llogarinë"
                              }
                            </SelectValue>
                          </SelectTrigger>
                          <SelectContent className="max-h-[300px] bg-white border border-mercury/50 shadow-lg">
                            {accounts.map((account) => (
                              <SelectItem 
                                key={account.id} 
                                value={account.id}
                                className="flex items-center gap-2 py-2.5 px-3 hover:bg-seashell focus:bg-seashell cursor-pointer text-bluish-grey hover:text-hazel-green transition-colors"
                              >
                                <div className="flex items-center gap-2">
                                  <span className="font-medium min-w-[80px] text-hazel-green">{account.code}</span>
                                  <span className="text-gray-400">-</span>
                                  <span className="text-bluish-grey">{account.name}</span>
                                </div>
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </td>
                      <td className="py-3 pr-4">
                        <input
                          type="text"
                          value={entry.description || ''}
                          onChange={(e) =>
                            setEntries(entries.map(item =>
                              item.id === entry.id
                                ? { ...item, description: e.target.value }
                                : item
                            ))
                          }
                          placeholder="Përshkrimi..."
                          className="w-full px-3 py-1.5 border border-mercury rounded-lg text-bluish-grey text-sm
                                   focus:outline-none focus:border-hazel-green transition-colors"
                        />
                      </td>
                      <td className="py-3 pr-4">
                        <input
                          type="number"
                          value={entry.debit || ''}
                          onChange={(e) => handleDebitCreditChange(entry.id, 'debit', parseFloat(e.target.value) || 0)}
                          placeholder="0.00"
                          className="w-full px-3 py-1.5 border border-mercury rounded-lg text-bluish-grey text-sm
                                   focus:outline-none focus:border-hazel-green transition-colors text-right"
                        />
                      </td>
                      <td className="py-3 pr-4">
                        <input
                          type="number"
                          value={entry.credit || ''}
                          onChange={(e) => handleDebitCreditChange(entry.id, 'credit', parseFloat(e.target.value) || 0)}
                          placeholder="0.00"
                          className="w-full px-3 py-1.5 border border-mercury rounded-lg text-bluish-grey text-sm
                                   focus:outline-none focus:border-hazel-green transition-colors text-right"
                        />
                      </td>
                      <td className="py-3 w-10">
                        <button
                          onClick={() => handleRemoveRow(entry.id)}
                          className="opacity-0 group-hover:opacity-100 p-1.5 text-red-400 hover:text-red-500 
                                   hover:bg-red-50 rounded-lg transition-all"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="border-t border-mercury/40">
                  <tr>
                    <td colSpan={2} className="py-3 text-sm font-medium text-bluish-grey">
                      Totali
                    </td>
                    <td className="py-3 pr-4 text-right text-sm font-medium text-bluish-grey">
                      {totalDebit.toFixed(2)}
                    </td>
                    <td className="py-3 pr-4 text-right text-sm font-medium text-bluish-grey">
                      {totalCredit.toFixed(2)}
                    </td>
                    <td className="w-10"></td>
                  </tr>
                  {Math.abs(difference) > 0.01 && (
                    <tr>
                      <td colSpan={5} className="py-2">
                        <div className="bg-red-50 text-red-600 px-4 py-2 rounded-lg flex items-center gap-2">
                          <AlertCircle className="h-4 w-4" />
                          <span className="text-sm">
                            Diferenca: {difference.toFixed(2)}
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tfoot>
              </table>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default EditJournalEntryPage;
