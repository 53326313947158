import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, RotateCw, X, Save, ArrowLeft } from 'lucide-react';
import { getAllAccounts } from '../../api/accountingPlan';
import { getVatRates, getUnits } from '../../api/accountingArticle';
import { toast } from 'react-hot-toast';

interface Account {
  id: string;
  code: string;
  name: string;
}

interface VatRate {
  id: string;
  name: string;
  percentage: number;
}

interface Unit {
  id: string;
  name: string;
}

interface DeviceFormModalProps {
  onBack: () => void;
  onClose: () => void;
  articleType: string;
  onSubmit: (data: any) => void;
  editData?: any;
  loading?: boolean;
}

const DeviceFormModal: React.FC<DeviceFormModalProps> = ({
  onBack,
  onClose,
  articleType,
  onSubmit,
  editData,
  loading
}) => {
  const [formData, setFormData] = useState({
    emri: editData?.name || '',
    kodi: editData?.code || '',
    njesiaMatese: editData?.unitId || '',
    normaTvsh: editData?.vatRateId || '',
    status: editData?.status || 'ACTIVE',
    type: articleType || 'FIXED_ASSET',
    accounts: {
      blerje: editData?.purchaseAccount ? { 
        id: editData.purchaseAccount.id,
        code: editData.purchaseAccount.code, 
        name: editData.purchaseAccount.name 
      } : {
        id: '', // Will be set when accounts are loaded
        code: '6002',
        name: 'Shpenzimet e zyrës'
      },
      shitje: editData?.salesAccount ? { 
        id: editData.salesAccount.id,
        code: editData.salesAccount.code, 
        name: editData.salesAccount.name 
      } : {
        id: '', // Will be set when accounts are loaded
        code: '4100',
        name: 'Te hyrat nga shërbimet'
      }
    },
    description: editData?.description || '',
    price: editData?.price || 0,
  });

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [vatRates, setVatRates] = useState<VatRate[]>([]);
  const [units, setUnits] = useState<Unit[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [accountsRes, vatRatesRes, unitsRes] = await Promise.all([
          getAllAccounts(),
          getVatRates(),
          getUnits()
        ]);
        
        setAccounts(accountsRes.data.message);
        setVatRates(vatRatesRes.data.message);
        setUnits(unitsRes.data.message);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Dështoi ngarkimi i të dhënave');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.emri || !formData.kodi || !formData.njesiaMatese || !formData.normaTvsh) {
      toast.error('Ju lutem plotësoni të gjitha fushat e detyrueshme');
      return;
    }

    if (!formData.accounts.blerje.id || !formData.accounts.shitje.id) {
      toast.error('Ju lutem zgjidhni llogaritë e blerjes dhe shitjes');
      return;
    }

    const submitData = {
      article: {
        name: formData.emri,
        code: formData.kodi,
        unitId: formData.njesiaMatese,
        vatRateId: formData.normaTvsh,
        status: formData.status,
        type: formData.type,
        purchaseAccountId: formData.accounts.blerje.id,
        salesAccountId: formData.accounts.shitje.id,
        description: formData.description || '',
        price: parseFloat(formData.price?.toString() || '0')
      }
    };

    onSubmit(submitData);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-bluish-grey/20 backdrop-blur-sm overflow-hidden"
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        transition={{ type: "spring", duration: 0.5 }}
        className="bg-white rounded-2xl shadow-2xl w-full max-w-4xl max-h-[90vh] flex flex-col overflow-hidden border border-grey-goose/30"
      >
        {/* Fixed Header */}
        <div className="flex items-center justify-between px-8 py-6 bg-white border-b border-grey-goose/30">
          <div className="flex items-center gap-4">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={onBack}
              type="button"
              className="p-2 text-bluish-grey hover:bg-seashell rounded-xl transition-colors"
            >
              <ArrowLeft className="w-5 h-5" />
            </motion.button>
            <div>
              <h2 className="text-2xl font-semibold text-bluish-grey">Shto Pajisje të re</h2>
              <p className="text-sm text-grey-goose mt-1">Plotësoni të dhënat e pajisjes së re</p>
            </div>
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClose}
            type="button"
            className="p-2 text-grey-goose hover:text-bluish-grey hover:bg-seashell rounded-xl transition-colors"
          >
            <X className="w-5 h-5" />
          </motion.button>
        </div>

        {/* Form with all content */}
        <form onSubmit={handleSubmit} className="flex flex-col flex-1 overflow-hidden">
          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto">
            <div className="p-8 space-y-8">
              {/* Main Form Section */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Left Column */}
                <div className="space-y-6">
                  <motion.div
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.1 }}
                    className="space-y-2"
                  >
                    <label className="block text-sm font-medium text-bluish-grey">Emri</label>
                    <input
                      type="text"
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50"
                      placeholder="Emri i pajisjes"
                      value={formData.emri}
                      onChange={(e) => setFormData({ ...formData, emri: e.target.value })}
                    />
                  </motion.div>

                  <motion.div
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="space-y-2"
                  >
                    <label className="block text-sm font-medium text-bluish-grey">Tipi i artikullit</label>
                    <input
                      type="text"
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl bg-seashell cursor-not-allowed text-bluish-grey"
                      value={articleType}
                      disabled
                    />
                  </motion.div>

                  <motion.div
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.3 }}
                    className="space-y-2"
                  >
                    <label className="block text-sm font-medium text-bluish-grey">Kodi</label>
                    <input
                      type="text"
                      className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50"
                      placeholder="Kodi i pajisjes"
                      value={formData.kodi}
                      onChange={(e) => setFormData({ ...formData, kodi: e.target.value })}
                    />
                  </motion.div>
                </div>

                {/* Right Column */}
                <div className="space-y-6">
                  <motion.div
                    initial={{ x: 20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.1 }}
                    className="space-y-2"
                  >
                    <label className="block text-sm font-medium text-bluish-grey">Njësia matëse</label>
                    <div className="flex gap-2">
                      <select
                        className="flex-1 px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50"
                        value={formData.njesiaMatese}
                        onChange={(e) => setFormData(prev => ({ ...prev, njesiaMatese: e.target.value }))}
                        required
                      >
                        <option value="">Zgjedh njësinë matëse</option>
                        {units.map((unit) => (
                          <option key={unit.id} value={unit.id}>
                            {unit.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </motion.div>

                  <motion.div
                    initial={{ x: 20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="space-y-2"
                  >
                    <label className="block text-sm font-medium text-bluish-grey">Norma e TVSh-së</label>
                    <div className="flex gap-2">
                      <select
                        className="flex-1 px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50"
                        value={formData.normaTvsh}
                        onChange={(e) => setFormData(prev => ({ ...prev, normaTvsh: e.target.value }))}
                        required
                      >
                        <option value="">Zgjedh normën e TVSh-së</option>
                        {vatRates.map((rate) => (
                          <option key={rate.id} value={rate.id}>
                            {rate.name} ({rate.percentage}%)
                          </option>
                        ))}
                      </select>
                    </div>
                  </motion.div>

                  <motion.div
                    initial={{ x: 20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.3 }}
                    className="space-y-2"
                  >
                    <label className="block text-sm font-medium text-bluish-grey">Statusi</label>
                    <div className="flex items-center space-x-2">
                      <div 
                        className={`relative inline-block w-12 h-6 transition duration-200 ease-in-out rounded-full cursor-pointer ${
                          formData.status === 'ACTIVE' ? 'bg-hazel-green' : 'bg-grey-goose/30'
                        }`}
                      >
                        <div
                          className={`absolute w-5 h-5 transition-all duration-200 transform bg-white rounded-full top-0.5 ${
                            formData.status === 'ACTIVE' ? 'right-0.5' : 'left-0.5'
                          }`}
                        />
                        <input
                          type="checkbox"
                          checked={formData.status === 'ACTIVE'}
                          onChange={(e) => setFormData({ ...formData, status: e.target.checked ? 'ACTIVE' : 'INACTIVE' })}
                          className="absolute w-full h-full opacity-0 cursor-pointer"
                        />
                      </div>
                      <span className="text-sm text-bluish-grey">
                        {formData.status === 'ACTIVE' ? 'Aktiv' : 'Joaktiv'}
                      </span>
                    </div>
                  </motion.div>
                </div>
              </div>

              {/* Accounts Section */}
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="bg-seashell rounded-2xl p-8 space-y-6"
              >
                <div className="flex items-center gap-3">
                  <h3 className="text-lg font-semibold text-gray-900">Kontot</h3>
                  <span className="px-3 py-1 text-xs font-medium bg-hazel-green/10 text-hazel-green rounded-full">
                    Rekomanduar
                  </span>
                </div>

                <div className="grid gap-6">
                  {/* Purchase Account */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">Llogaria e blerjes</label>
                    <div className="flex gap-2">
                      <select
                        value={formData.accounts.blerje.id}
                        onChange={(e) => {
                          const selectedAccount = accounts.find(acc => acc.id === e.target.value);
                          setFormData(prev => ({
                            ...prev,
                            accounts: {
                              ...prev.accounts,
                              blerje: selectedAccount ? {
                                id: selectedAccount.id,
                                code: selectedAccount.code,
                                name: selectedAccount.name
                              } : prev.accounts.blerje
                            }
                          }));
                        }}
                        className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50"
                      >
                        <option value="">Zgjedh llogarinë e blerjes</option>
                        {accounts.map((account) => (
                          <option key={account.id} value={account.id}>
                            {account.code} - {account.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Sales Account */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-bluish-grey">Llogaria e shitjes</label>
                    <div className="flex gap-2">
                      <select
                        value={formData.accounts.shitje.id}
                        onChange={(e) => {
                          const selectedAccount = accounts.find(acc => acc.id === e.target.value);
                          setFormData(prev => ({
                            ...prev,
                            accounts: {
                              ...prev.accounts,
                              shitje: selectedAccount ? {
                                id: selectedAccount.id,
                                code: selectedAccount.code,
                                name: selectedAccount.name
                              } : prev.accounts.shitje
                            }
                          }));
                        }}
                        className="w-full px-4 py-3 border border-grey-goose/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-hazel-green/20 focus:border-hazel-green transition-all bg-white hover:border-hazel-green/50"
                      >
                        <option value="">Zgjedh llogarinë e shitjes</option>
                        {accounts.map((account) => (
                          <option key={account.id} value={account.id}>
                            {account.code} - {account.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>

          {/* Fixed Footer */}
          <div className="px-8 py-6 bg-white border-t border-grey-goose/30">
            <div className="flex justify-end gap-4">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="button"
                onClick={onClose}
                className="px-6 py-3 text-bluish-grey hover:bg-seashell rounded-xl transition-colors font-medium"
              >
                Anulo
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="submit"
                disabled={loading || isLoading}
                className="px-6 py-3 bg-hazel-green text-white rounded-xl hover:bg-opacity-90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 font-medium shadow-lg shadow-hazel-green/20"
              >
                {loading || isLoading ? (
                  <>
                    <RotateCw className="w-5 h-5 animate-spin" />
                    Duke ruajtur...
                  </>
                ) : (
                  <>
                    <Save className="w-5 h-5" />
                    Ruaj Pajisjen
                  </>
                )}
              </motion.button>
            </div>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default DeviceFormModal;
