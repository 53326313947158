// import React from 'react';
// import { useState, useEffect } from 'react';


// const images = [
//   '/images/pool1.jpg',
//   '/images/pool5.jpg',
//   '/images/pool3.jpg'
// ];


// const CurrentConditions = () => {

//   const [currentIndex, setCurrentIndex] = useState(0);


//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 3000);

//     return () => clearInterval(intervalId);
//   }, []);

//   const now = new Date();
//   const hours = now.getHours();
  
//   // Determine if the pool is open or closed
//   const isOpen = hours >= 9 && hours < 17;
//   const statusText = isOpen ? 'Pool is Open' : 'Pool is Closed';

//   return (
//     //  <div className="relative w-full max-w-3xl mx-auto">
//     //   <div className="relative w-full max-w-3xl mx-auto overflow-hidden">
//     //   <img 
//     //     src={images[currentIndex]} 
//     //     alt="Slideshow" 
//     //     className="w-full h-full object-contain inset-0 transition-opacity duration-1000"
//     //   />
//     // </div>
//     //   <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-2xl font-bold">
//     //     {statusText}
//     //   </div>
//     // </div>
//     <div className="relative w-full max-w-3xl h-80 mx-auto">
//   <div className="relative w-full h-full overflow-hidden">
//     <img 
//       src={images[currentIndex]} 
//       alt="Slideshow" 
//       className="absolute top-0 left-0 w-full h-full object-contain transition-opacity duration-1000"
//     />
//   </div>
//   <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-2xl font-bold">
//     {statusText}
//   </div>
// </div>


//   );
// };

// export default CurrentConditions;


import React, { useState, useEffect } from 'react';

const images = [
  '/images/pool1.jpg',
  '/images/pool5.jpg',
  '/images/pool3.jpg'
];

const CurrentConditions = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState((currentIndex + 1) % images.length);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setNextIndex((newIndex + 1) % images.length);
        return newIndex;
      });
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  const now = new Date();
  const hours = now.getHours();
  
  // Determine if the pool is open or closed
  const isOpen = hours >= 9 && hours < 17;
  const statusText = isOpen ? 'Pool is Open' : 'Pool is Closed';

  return (
    <div className="relative w-full max-w-3xl h-90 mx-auto overflow-hidden">
      {/* Current Image */}
      <img 
        src={images[currentIndex]} 
        alt="Slideshow" 
        className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-1000 transform translate-x-0"
        style={{ transition: 'transform 1s ease-in-out' }}
      />
      {/* Next Image */}
      <img 
        src={images[nextIndex]} 
        alt="Slideshow" 
        className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-1000 transform translate-x-full"
        style={{ transition: 'transform 1s ease-in-out' }}
      />
      <div className={`absolute inset-0 flex items-center justify-center text-white text-2xl font-bold transition-opacity duration-1000 ${isOpen ? 'bg-transparent' : 'bg-black bg-opacity-50'}`}>
        {statusText}
      </div>
    </div>
  );
};

export default CurrentConditions;

