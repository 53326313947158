import api from "./api";

// Fetch all bars
export const getBars = () => api.get('/api/bar/bars');

// Fetch a single bar by ID
export const getBarById = (id) => api.get(`/api/bar/bars/${id}`);

// Create a new bar
export const createBar = (values) => api.post('/api/bar/bars', values);

// Update an existing bar
export const updateBar = (id, values) => api.patch(`/api/bar/bars/${id}`, values);

// Delete a bar
export const deleteBar = (id) => api.delete(`/api/bar/bars/${id}`);
