import React from 'react';
import {
  Building2,
  Phone,
  Mail,
  Hash,
  Calendar,
  User,
  MapPin,
  Package,
  DollarSign,
  Calculator,
  CreditCard,
  Receipt,
  FileText
} from 'lucide-react';

interface DocumentTemplateProps {
  type: 'sale' | 'purchase' | 'offer';
  data: any;
}

const formatDate = (date: string | Date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const formatCurrency = (amount: number) => {
  if (!amount) return '$0.00';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(amount);
};

const DocumentTemplate: React.FC<DocumentTemplateProps> = ({ type, data }) => {
  if (!data) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="w-10 h-10 border-3 border-hazel-green/20 border-t-hazel-green rounded-full animate-spin" />
      </div>
    );
  }

  // Get the reference data based on type
  const reference = data.reference || {};
  
  const documentTitle = type === 'sale' ? 'INVOICE' : type === 'purchase' ? 'PURCHASE ORDER' : 'QUOTATION';
  const documentNumber = data.documentNumber || reference.documentNumber || 'N/A';
  const client = reference.client;
  const supplier = reference.supplier;
  const items = reference.items || [];
  const totals = {
    subtotal: reference.totalWithoutVAT || 0,
    vat: reference.totalVAT || 0,
    total: reference.totalWithVAT || 0
  };

  return (
    <div className="bg-white rounded-xl shadow-lg">
      <div className="p-5 bg-seashell rounded-t-xl">
        <div className="flex justify-between items-start gap-6">
          {/* Document Info */}
          <div className="space-y-3">
            <div className="inline-flex items-center gap-2 px-3 py-2 bg-hazel-green/10 rounded-lg">
              <FileText className="w-4 h-4 text-hazel-green" />
              <h1 className="text-xl font-bold text-gray-900">{documentTitle}</h1>
            </div>
            <div className="space-y-1.5">
              <div className="flex items-center gap-2 text-gray-600 bg-white px-3 py-2 rounded-lg shadow-sm">
                <Hash className="w-4 h-4 text-hazel-green" />
                <div className="text-sm">
                  <span className="font-medium text-gray-900">Document No:</span>
                  <span className="ml-2 text-bluish-grey">{documentNumber}</span>
                </div>
              </div>
              <div className="flex items-center gap-2 text-gray-600 bg-white px-3 py-2 rounded-lg shadow-sm">
                <Calendar className="w-4 h-4 text-hazel-green" />
                <div className="text-sm">
                  <span className="font-medium text-gray-900">Date:</span>
                  <span className="ml-2 text-bluish-grey">{formatDate(reference.issueDate || data.createdAt)}</span>
                </div>
              </div>
              {reference.dueDate && (
                <div className="flex items-center gap-2 text-gray-600 bg-white px-3 py-2 rounded-lg shadow-sm">
                  <Calendar className="w-4 h-4 text-hazel-green" />
                  <div className="text-sm">
                    <span className="font-medium text-gray-900">Due Date:</span>
                    <span className="ml-2 text-bluish-grey">{formatDate(reference.dueDate)}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Company Info */}
          <div className="text-right">
            <div className="inline-flex flex-col items-end gap-3">
              <div className="flex items-center gap-2 px-3 py-2 bg-white rounded-lg shadow-sm">
                <Building2 className="w-4 h-4 text-hazel-green" />
                <span className="text-sm font-semibold text-gray-900">Lakeside Resort</span>
              </div>
              <div className="space-y-1.5 bg-white px-3 py-2 rounded-lg shadow-sm">
                <p className="flex items-center justify-end gap-2 text-bluish-grey text-sm">
                  <span>123 Resort Street</span>
                  <MapPin className="w-4 h-4 text-hazel-green" />
                </p>
                <p className="flex items-center justify-end gap-2 text-bluish-grey text-sm">
                  <span>+1 234 567 890</span>
                  <Phone className="w-4 h-4 text-hazel-green" />
                </p>
                <p className="flex items-center justify-end gap-2 text-bluish-grey text-sm">
                  <span>info@lakeside.com</span>
                  <Mail className="w-4 h-4 text-hazel-green" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Client/Supplier Info */}
      <div className="p-5 border-b">
        <div className="flex items-center gap-2 mb-3">
          <User className="w-4 h-4 text-hazel-green" />
          <h2 className="text-lg font-semibold text-gray-900">
            {type === 'purchase' ? 'Supplier Information' : 'Client Information'}
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <p className="text-sm text-gray-600">
              <span className="font-medium">Name:</span>
              <span className="ml-2">{(type === 'purchase' ? supplier?.name : client?.name) || 'N/A'}</span>
            </p>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Address:</span>
              <span className="ml-2">{(type === 'purchase' ? supplier?.address : client?.address) || 'N/A'}</span>
            </p>
          </div>
          <div className="space-y-2">
            <p className="text-sm text-gray-600">
              <span className="font-medium">Phone:</span>
              <span className="ml-2">{(type === 'purchase' ? supplier?.phone : client?.phone) || 'N/A'}</span>
            </p>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Email:</span>
              <span className="ml-2">{(type === 'purchase' ? supplier?.email : client?.email) || 'N/A'}</span>
            </p>
          </div>
        </div>
      </div>

      {/* Items */}
      <div className="p-5">
        <div className="flex items-center gap-2 mb-4">
          <Package className="w-4 h-4 text-hazel-green" />
          <h2 className="text-lg font-semibold text-gray-900">Items</h2>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-seashell">
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-900">Item</th>
                <th className="px-4 py-2 text-right text-sm font-medium text-gray-900">Quantity</th>
                <th className="px-4 py-2 text-right text-sm font-medium text-gray-900">Unit</th>
                <th className="px-4 py-2 text-right text-sm font-medium text-gray-900">Price</th>
                <th className="px-4 py-2 text-right text-sm font-medium text-gray-900">VAT %</th>
                <th className="px-4 py-2 text-right text-sm font-medium text-gray-900">Total</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item: any, index: number) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2 text-sm text-gray-900">{item.article?.name || 'N/A'}</td>
                  <td className="px-4 py-2 text-sm text-right">{item.quantity || 0}</td>
                  <td className="px-4 py-2 text-sm text-right">{item.unit || item.article?.unit?.name || 'N/A'}</td>
                  <td className="px-4 py-2 text-sm text-right">{formatCurrency(item.priceWithoutVAT || 0)}</td>
                  <td className="px-4 py-2 text-sm text-right">{item.vatRate || 0}%</td>
                  <td className="px-4 py-2 text-sm text-right">{formatCurrency(item.totalWithVAT || 0)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Totals */}
      <div className="p-5 bg-seashell rounded-b-xl">
        <div className="flex justify-end">
          <div className="w-64 space-y-2">
            <div className="flex justify-between items-center text-sm">
              <span className="font-medium text-gray-600">Subtotal:</span>
              <span>{formatCurrency(totals.subtotal)}</span>
            </div>
            <div className="flex justify-between items-center text-sm">
              <span className="font-medium text-gray-600">VAT:</span>
              <span>{formatCurrency(totals.vat)}</span>
            </div>
            <div className="flex justify-between items-center text-sm font-bold">
              <span>Total:</span>
              <span>{formatCurrency(totals.total)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentTemplate;
