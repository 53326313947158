export enum DocumentStatus {
  DRAFT = 'DRAFT',
  FINAL = 'FINAL',
  ARCHIVED = 'ARCHIVED',
  DUPLICATE = 'DUPLICATE'
}

export enum DocumentType {
  SALE = 'SALE',
  PURCHASE = 'PURCHASE',
  OFFER = 'OFFER',
  RETURN = 'RETURN',
  OTHER = 'OTHER'
}

export interface Document {
  id: string;
  name: string;
  description?: string;
  type: DocumentType;
  status: DocumentStatus;
  fileUrl: string;
  fileSize: number;
  fileType: string;
  documentNumber?: string;
  folderId?: string;
  locationId?: string;
  referenceType?: string;
  referenceId?: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  folder?: {
    id: string;
    name: string;
  };
}
