import React from "react";

import Header from "../common/Header";
import Footer from "../common/Footer";

import AddReservation from "../home/reservation/AddReservation";


function AddReservationPage() {
    return (
        <>
            <Header />
            <div className="my-24">
                <AddReservation />
            </div>
            <Footer />
        </>
    );
}

export default AddReservationPage;