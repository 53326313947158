import React from 'react';
import { IconBedFilled } from '@tabler/icons-react';


import RoomTypeForm from '../room-types/RoomTypeForm';
import PageTitle from '../../../../components/page-title';


function AddRoomType() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconBedFilled className='text-hazel-green mr-2' size={22} />}
        title='Room Type'
      />
      <RoomTypeForm />
    </div>
  );
}

export default AddRoomType;
