import React from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { checkUserHasPermission } from '../helpers/user-helper';

const AuthorizationGuard = (props) => {
  const { action, entity, children } = props;
  const { userPermissions, userRole } = useAuth();

  const poolsEntity =
    entity === 'pools'
      ? [`${action}_outdoor_${entity}`, `${action}_indoor_${entity}`]
      : null;

  if (userPermissions) {
    if (
      !checkUserHasPermission(
        userRole,
        userPermissions,
        poolsEntity ? poolsEntity : [`${action}_${entity}`]
      )
    ) {
      return (
        <div className='border border-gray-200 rounded-xl shadow-lg px-3 mt-5'>
          <p className='text-sage font-bold py-10 px-8'>
            You don&apos;t have permission to access this page
          </p>
        </div>
      );
    }
    return <>{children}</>;
  }
  return null;
};

AuthorizationGuard.propTypes = {
  children: PropTypes.node
};

export default AuthorizationGuard;
