import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const SimpleDateTimePicker = ({ invoiceData, handleChange }) => {
  const [selectedDate, setSelectedDate] = useState(invoiceData.date);

  // Handle date change
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    // Pass the new date to the parent component
    handleChange({ target: { name: 'date', value: newDate } });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label="Select Date & Time"
        value={selectedDate}
        onChange={handleDateChange}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </LocalizationProvider>
  );
};

export default SimpleDateTimePicker;
