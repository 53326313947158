import React from 'react';

const InvoiceModal = ({ title, titleSize, setShowModal, children }) => {
  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative my-6 mx-auto max-w-3xl w-full h-full'>
          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            <div className='flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t'>
              <h3
                className={`text-hazel-green ${
                  titleSize ? titleSize : 'text-3xl'
                }  font-semibold mt-2`}
              >
                {title}
              </h3>
              <button
                className='p-1 ml-auto bg-transparent border-0 float-right leading-none font-semibold outline-none focus:outline-none'
                onClick={() => setShowModal(false)}
              >
                <span className='bg-transparent text-hazel-green h-6 w-6 text-2xl block outline-none focus:outline-none'>
                  ×
                </span>
              </button>
            </div>
            <div className='relative p-6 flex-auto h-full'>
              <div className='h-full'>{children}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  );
};

export default InvoiceModal;
