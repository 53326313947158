import api from './api';

export const getRoomTypes = () => api.get(`/api/room-types`);

export const createRoomType = (values) => api.post(`/api/room-types`, values);

export const getRoomType = (id) => api.get(`/api/room-types/${id}`);

export const updateRoomType = (id, values) =>
  api.patch(`/api/room-types/${id}`, values);

export const deleteRoomType = (id) => api.delete(`/api/room-types/${id}`);

export const createRoom = (values) => api.post(`/api/rooms`, values);

export const getRoom = (id) => api.get(`/api/rooms/${id}`);

export const updateRoom = (id, values) => api.patch(`/api/rooms/${id}`, values);

export const deleteRoom = (id) => api.delete(`/api/rooms/${id}`);

export const getAllRooms = () => api.get(`/api/rooms/all-rooms`);

export const getPaginatedRooms = (page, pageSize) => api.get(`/api/rooms?page=${page}&pageSize=${pageSize}`);

export const fileUpload = (values) => api.post(`/api/media/upload`, values);
