import React, { useState, useEffect } from "react";
import { ShoppingCart, ShoppingBag, BarChart2, TrendingUp, TrendingDown, Minus, ArrowUpCircle, ArrowDownCircle, MinusCircle } from 'lucide-react';
import { Line } from "react-chartjs-2";
import { getDashboardMetrics } from "../../../../api/accountingDashboard";
import { useLocation } from "../../../../hooks/useLocation.ts";

interface ActionCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const ActionCard: React.FC<ActionCardProps> = ({ icon, title, description }) => (
  <div className="flex items-center p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer">
    <div className="mr-4">
      {icon}
    </div>
    <div>
      <h3 className="text-sm font-medium text-bluish-grey">{title}</h3>
      <p className="text-xs text-grey-goose">{description}</p>
    </div>
  </div>
);

const MetricCard: React.FC<{ 
  title: string; 
  amount: string; 
  trend?: string;
  subtitle?: string;
}> = ({ title, amount, trend, subtitle }) => {
  const getTrendIcon = () => {
    switch(trend) {
      case 'up':
        return <TrendingUp className="w-5 h-5 text-hazel-green" />;
      case 'down':
        return <TrendingDown className="w-5 h-5 text-watermelon" />;
      default:
        return <Minus className="w-5 h-5 text-grey-goose" />;
    }
  };

  return (
    <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
      <div className="flex justify-between items-start mb-2">
        <div>
          <h2 className="text-sm font-medium text-bluish-grey">{title}</h2>
          {subtitle && <p className="text-xs text-grey-goose mt-1">{subtitle}</p>}
        </div>
        {trend && (
          <div className="flex items-center gap-2">
            {getTrendIcon()}
          </div>
        )}
      </div>
      <div className="mt-4">
        <p className="text-2xl font-semibold text-hazel-green">{amount}</p>
      </div>
    </div>
  );
};

const TransactionTable: React.FC<{ transactions: any[] }> = ({ transactions }) => {
  const getStatusIcon = (type: string) => {
    switch(type) {
      case 'Sale':
        return <ArrowUpCircle className="w-4 h-4 text-hazel-green" />;
      case 'Purchase':
        return <ArrowDownCircle className="w-4 h-4 text-watermelon" />;
      default:
        return <MinusCircle className="w-4 h-4 text-grey-goose" />;
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full">
        <thead>
          <tr className="border-b border-seashell">
            <th className="py-3 px-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Date</th>
            <th className="py-3 px-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Type</th>
            <th className="py-3 px-4 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">Description</th>
            <th className="py-3 px-4 text-right text-xs font-medium text-bluish-grey uppercase tracking-wider">Amount</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-seashell">
          {transactions?.map(transaction => (
            <tr key={transaction.id} className="hover:bg-tealish-blue transition-colors">
              <td className="py-4 px-4 text-sm text-bluish-grey">
                {new Date(transaction.date).toLocaleDateString()}
              </td>
              <td className="py-4 px-4">
                <div className="flex items-center gap-2">
                  {getStatusIcon(transaction.type)}
                  <span className="text-sm text-bluish-grey">{transaction.type}</span>
                </div>
              </td>
              <td className="py-4 px-4 text-sm text-bluish-grey">{transaction.description}</td>
              <td className="py-4 px-4 text-sm text-right font-medium">
                <span className={transaction.type === 'Sale' ? 'text-hazel-green' : 'text-watermelon'}>
                  {`${transaction.amount.toFixed(2)} €`}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default function AccountingDashboard() {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState('month');
  const { selectedLocation } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        if (!selectedLocation?.id) {
          console.log('No location selected, skipping API call');
          return;
        }

        console.log('Fetching data for location:', selectedLocation.id);
        const data = await getDashboardMetrics(selectedLocation.id, selectedPeriod);
        console.log('Received dashboard data:', data);
        setMetrics(data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedLocation, selectedPeriod]);

  const chartData = {
    labels: metrics?.timeSeriesData?.map(d => d.date) || [],
    datasets: [
      {
        label: 'Shitjet',
        data: metrics?.timeSeriesData?.map(d => d.sales) || [],
        borderColor: '#61805b',
        backgroundColor: 'rgba(97, 128, 91, 0.1)',
        fill: true,
      },
      {
        label: 'Shpenzimet',
        data: metrics?.timeSeriesData?.map(d => d.expenses) || [],
        borderColor: '#EC5252',
        backgroundColor: 'rgba(236, 82, 82, 0.1)',
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="min-h-screen bg-seashell">
      {/* Welcome Banner */}
      <div className="bg-white shadow-sm mb-6">
        <div className="max-w-7xl mx-auto px-4 py-6">
          <div className="flex items-center gap-2 mb-2">
            <span className="text-2xl">👋</span>
            <h1 className="text-xl font-medium text-bluish-grey">Mirësevini sërish!</h1>
          </div>
          <p className="text-grey-goose">Këtu janë disa analiza mbi biznesin tuaj.</p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4">
        {/* Period Selector */}
        <div className="flex items-center gap-4 mb-8">
          <div className="flex bg-white rounded-lg p-1 shadow-sm">
            {['day', 'week', 'month', 'year'].map((period) => (
              <button
                key={period}
                onClick={() => setSelectedPeriod(period)}
                className={`px-6 py-2 rounded-md text-sm font-medium transition-colors ${
                  selectedPeriod === period
                    ? 'bg-hazel-green text-white'
                    : 'text-bluish-grey hover:bg-seashell'
                }`}
              >
                {period.charAt(0).toUpperCase() + period.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {/* Metrics Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <MetricCard 
            title="Gross Profit"
            subtitle="Total earnings before expenses"
            amount={`${metrics?.metrics?.profit?.gross?.toFixed(2) || '0.00'} €`}
            trend={metrics?.metrics?.profit?.grossTrend}
          />
          <MetricCard 
            title="Net Profit"
            subtitle="Total earnings after expenses"
            amount={`${metrics?.metrics?.profit?.net?.toFixed(2) || '0.00'} €`}
            trend={metrics?.metrics?.profit?.netTrend}
          />
          <MetricCard 
            title="Average Sale"
            subtitle="Average transaction value"
            amount={`${metrics?.metrics?.sales?.averageValue?.toFixed(2) || '0.00'} €`}
          />
          <MetricCard 
            title="Profit Margin"
            subtitle="Percentage of revenue"
            amount={`${metrics?.metrics?.profit?.margin?.toFixed(1) || '0.0'}%`}
          />
        </div>

        {/* Chart Section */}
        <div className="bg-white rounded-lg p-6 shadow-sm mb-8">
          <h2 className="text-lg font-medium text-bluish-grey mb-6">Revenue Overview</h2>
          {loading ? (
            <div className="h-64 flex items-center justify-center">
              <span className="text-grey-goose">Duke u ngarkuar...</span>
            </div>
          ) : (
            <Line data={chartData} options={chartOptions} />
          )}
        </div>

        {/* Recent Transactions */}
        <div className="bg-white rounded-lg p-6 shadow-sm mb-8">
          <h2 className="text-lg font-medium text-bluish-grey mb-6">Recent Transactions</h2>
          <TransactionTable transactions={metrics?.recentTransactions || []} />
        </div>

        {/* Quick Actions */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <ActionCard
            icon={<ShoppingCart className="w-6 h-6 text-hazel-green" />}
            title="Shitjet e Reja"
            description="Shiko shitjet e fundit dhe trendet"
          />
          <ActionCard
            icon={<ShoppingBag className="w-6 h-6 text-hazel-green" />}
            title="Blerjet e Reja"
            description="Shiko blerjet e fundit dhe trendet"
          />
          <ActionCard
            icon={<BarChart2 className="w-6 h-6 text-hazel-green" />}
            title="Raportet"
            description="Gjenero raporte të detajuara"
          />
        </div>
      </div>
    </div>
  );
}