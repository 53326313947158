
import React from 'react'

const Top = () => {
    return (
        <header className="flex items-center space-x-2 mb-3">
          <h1 className="text-lg font-semibold text-hazel-green">Calculator</h1>
        </header>
      );
}

export default Top