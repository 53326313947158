import { Link } from "react-router-dom";
import { footerContact, footerItem, socialIcons } from "../../../components/data/Data";
import Newsletter from "../home/Newsletter";

function Footer() {
  return (
    // <>
    //   <Newsletter />
    //   <div
    //     className="container-fluid bg-dark text-light footer wow fadeIn"
    //     data-wow-delay="0.1s"
    //   >
    //     <div className="container pb-5">
    //       <div className="row g-5">
    //         <div className="col-md-6 col-lg-4">
    //           <div className="bg-[#FEA116] rounded p-4">
    //             <Link to="/">
    //               <h1 className="text-white text-uppercase mb-3">Hotelier</h1>
    //             </Link>
    //             <p className="text-white mb-0">
    //               Build a professional website for your hotel business and grab
    //               the attention of new visitors upon your site’s launch.
    //             </p>
    //           </div>
    //         </div>
    //         <div className="col-md-6 col-lg-3">
    //           <h6 className="section-title text-start text-[#FEA116] text-uppercase mb-4">
    //             Contact
    //           </h6>
    //           {footerContact.map((val, index) => (
    //             <p className="mb-2" key={index}>
    //               {val.icon} {val.name}
    //             </p>
    //           ))}
    //           <div className="d-flex pt-2">
    //             {socialIcons.slice(0, 4).map((val, index) => (
    //               <a className="btn btn-outline-light btn-social" href="">
    //                 {val.icon}
    //               </a>
    //             ))}
    //           </div>
    //         </div>
    //         <div className="col-lg-5 col-md-12">
    //           <div className="row gy-5 g-4">
    //             {footerItem.map((section, sectionIndex) => (
    //               <div className="col-md-6" key={sectionIndex}>
    //                 <h6 className="section-title text-start text-[#FEA116] text-uppercase mb-4">
    //                   {section.header}
    //                 </h6>
    //                 {section.UnitItem.map((item, itemIndex) => (
    //                   <a className="btn btn-link" href="" key={itemIndex}>
    //                     {item.name}
    //                   </a>
    //                 ))}
    //               </div>
    //             ))}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>

    <>
    <div className="bg-gray-800 text-white py-12">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          
          {/* About Section */}
          <div className="bg-gray-900 rounded-lg p-6">
            <Link to="/">
              <h1 className="text-[#FEA116] text-2xl font-bold mb-3 uppercase">
                Hotel
              </h1>
            </Link>
            <p className="text-gray-400">
              Lake Side hotel 
            </p>
          </div>
  
          {/* Contact Section */}
          <div>
            <h6 className="text-[#FEA116] text-lg font-semibold mb-4 uppercase">
              Contact
            </h6>
            {footerContact.map((val, index) => (
              <p className="mb-2 flex items-center text-gray-400" key={index}>
                {val.icon} {val.name}
              </p>
            ))}
            <div className="flex space-x-3 mt-4">
              {socialIcons.slice(0, 4).map((val, index) => (
                <a
                  className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-3 transition duration-300 ease-in-out"
                  href=""
                  key={index}
                >
                  {val.icon}
                </a>
              ))}
            </div>
          </div>
  
          {/* Links Section */}
          <div className="space-y-6">
            {footerItem.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                <h6 className="text-[#FEA116] text-lg font-semibold mb-4 uppercase">
                  {section.header}
                </h6>
                {section.UnitItem.map((item, itemIndex) => (
                  <a
                    className="block text-gray-400 hover:text-gray-300 transition duration-300 ease-in-out mb-2"
                    href=""
                    key={itemIndex}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            ))}
          </div>
  
        </div>
      </div>
    </div>
  </>
  

  );
}


export default Footer;