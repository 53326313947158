
import React, { useState, useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useDrop } from 'react-dnd';
import { getAllBarItems, getAllEnvironments, getTablesByEnvironmentId, getBars } from '../../api/restaurantInterface';
import { createBarOrder } from '../../api/restaurantInterface';
import SelectInput from '../../components/select-input';
import CustomInput from '../../components/custom-input';
import Loading from '../../components/loader';
import { CancelButton, SubmitButton } from '../../components/buttons';
import BarCard from './BarCard';

function RestaurantBarOrderForm({ isEdit, barOrder, selectedBarItems, setSelectedBarItems, handleBarItemDrop }) {
  const [droppedBarItems, setDroppedBarItems] = useState(selectedBarItems);
  const [loading, setLoading] = useState(false);
  const [environments, setEnvironments] = useState([]);
  const [tables, setTables] = useState([]);
  const [bars, setBars] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const [selectedBar, setSelectedBar] = useState('');
  const navigate = useNavigate();

  // Fetch environments
  useEffect(() => {
    const fetchEnvironments = async () => {
      try {
        const response = await getAllEnvironments();
        setEnvironments(response.data);
      } catch (err) {
        console.error('Failed to fetch environments:', err);
      }
    };

    fetchEnvironments();
  }, []);

  // Fetch tables
  useEffect(() => {
    const fetchTables = async (environmentId) => {
      try {
        if (!environmentId) {
          setTables([]);
          return;
        }
        const response = await getTablesByEnvironmentId(environmentId);
        setTables(response.data);
      } catch (err) {
        console.error('Failed to fetch tables:', err);
      }
    };

    if (selectedEnvironment) {
      fetchTables(selectedEnvironment);
    }
  }, [selectedEnvironment]);

  // Fetch bars
  useEffect(() => {
    const fetchBars = async () => {
      try {
        const response = await getBars();
        setBars(response.data.map(bar => ({
          id: bar.id,
          name: bar.name,
        })));
      } catch (err) {
        console.error('Failed to fetch bars:', err);
      }
    };

    fetchBars();
  }, []);

  // Calculate total price
  useEffect(() => {
    console.log('Dropped Bar Items:', droppedBarItems);
    const calculatedTotalPrice = (droppedBarItems || []).reduce((total, item) => total + item.price, 0);
    setTotalPrice(calculatedTotalPrice);
  }, [droppedBarItems]);

  // Handle drag and drop
  const [{ isOver }, drop] = useDrop({
    accept: 'barProduct', // Update to match the drag type in BarProductSidebar
    drop: (item) => {
      const newItem = { ...item, uniqueId: uuidv4() };
      setDroppedBarItems((prevItems) => [...prevItems, newItem]);
      handleBarItemDrop(newItem);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const initialValues = {
    tableId: '',
    environmentId: '',
    barId: '', // Add barId to initial values
  };

  const validationSchema = Yup.object().shape({
    tableId: Yup.string().required('Table ID is required*'),
    environmentId: Yup.string().required('Environment is required*'),
    barId: Yup.string().required('Bar ID is required*'), // Add barId to validation
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setLoading(true);
    try {
      const barOrderData = {
        tableId: parseInt(values.tableId),
        totalPrice: totalPrice,
        environmentId: parseInt(values.environmentId),
        barId: values.barId, // Include barId in the data
        status: 'unpaid',
        barItems: droppedBarItems.map(item => ({
          barItemId: item.id,
          quantity: 1, // Modify if necessary
        })),
      };
      await createBarOrder(barOrderData);
      navigate('/bar-orders');
    } catch (err) {
      setErrors({ submit: err?.response?.data });
      console.error('Submission error:', err);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <div className='z-2 border border-gray-200 rounded-xl shadow-lg mt-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className='bg-gray-100 px-7 py-5'>
            <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
              <SelectInput
                name='environmentId'
                value={values.environmentId}
                label='Environment'
                options={environments.map((env) => ({
                  id: env.id,
                  title: env.name,
                }))}
                onChange={(e) => {
                  setFieldValue('environmentId', e.target.value);
                  setSelectedEnvironment(e.target.value);
                }}
                onBlur={handleBlur}
                showError={errors.environmentId && touched.environmentId}
                errorMessage={errors.environmentId}
              />
              <SelectInput
                name='tableId'
                value={values.tableId}
                label='Table'
                options={tables.map((table) => ({
                  id: table.id,
                  title: `Table ${table.number}`,
                }))}
                onChange={(e) => setFieldValue('tableId', e.target.value)}
                onBlur={handleBlur}
                showError={errors.tableId && touched.tableId}
                errorMessage={errors.tableId}
                disabled={!values.environmentId}
              />
              <SelectInput
                name='barId'
                value={values.barId}
                label='Bar'
                options={bars.map((bar) => ({
                  id: bar.id,
                  title: bar.name,
                }))}
                onChange={(e) => setFieldValue('barId', e.target.value)}
                onBlur={handleBlur}
                showError={errors.barId && touched.barId}
                errorMessage={errors.barId}
              />
              <div
                ref={drop}
                className={`relative border-2 border-dashed ${
                  isOver ? 'border-green-400 bg-green-100' : 'border-gray-300 bg-white'
                } p-4 rounded-lg min-h-[200px]`}
              >
                <p className='text-gray-600'>
                  {isOver ? 'Release to drop' : 'Drag bar items here'}
                </p>
                <div className='p-1 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 gap-y-0.5 gap-x-1 mt-4'>
                  {(droppedBarItems || []).map((item) => (
                    <div key={item.uniqueId} className='transform scale-50'>
                      <BarCard
                        name={item.name}
                        imageUrl={item.imageUrl}
                        description={item.description}
                        price={item.price}
                      />
                      <button
                        type='button'
                        onClick={() => setDroppedBarItems(items => items.filter(i => i.uniqueId !== item.uniqueId))}
                        className='absolute top-0 right-0 p-1 text-red-600 hover:text-red-800'
                      >
                        ❌
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <CustomInput
                type='number'
                name='totalPrice'
                label='Total Price'
                value={totalPrice}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.totalPrice && touched.totalPrice}
                errorMessage={errors.totalPrice}
                disabled
              />
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
            </div>
            <div className='z-2 flex justify-end items-center w-full mt-4 text-sm'>
              <CancelButton onCancel={() => navigate('/bar-orders')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/2 md:w-1/6'
                title={`${isEdit ? 'Edit Bar Order' : 'Create Bar Order'}`}
              />
            </div>
            {errors.submit && <p className='text-error'>{errors.submit}</p>}
          </form>
        )}
      </Formik>
    </div>
  );
}

export default RestaurantBarOrderForm;
